.maxWidthBuildPackage {
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 1280px;
  width: 95%;
  margin: 0 auto;
  padding-bottom: 20px;
}

.maxWidthBuildPackageTitle {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.maxWidthBuildPackageTitle h3 {
  color: #104172;
  font-size: 24px;
  font-weight: 700;
}

.maxWidthBuildPackageTitle p {
  font-size: 14px;
}
/* Grid.css */

.grid-item {
  background-color: #fff;
  border: 1px solid #ccc;
  text-align: center;
  overflow: hidden;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 10px;
}

.grid-item-info {
  width: 60%;
  display: flex;
  flex-direction: column;
  gap: 40px;
  justify-content: space-between;
  padding: 15px 15px 0px 5px;
}

.grid-item-info-text {
  display: flex;
  flex-direction: column;
  gap: 20px;
  text-align: start;
}

.grid-item-info-title {
  font-size: 18px;
  font-weight: 700;
  color: #00afe8;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
}

.grid-item-icons {
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-size: 14px;
}

.grid-item-icon {
  display: flex;
  gap: 10px;
  align-items: center;
}

.grid-item-icon span {
  color: #00afe8;
  font-weight: 700;
}

.grid-item-content {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: 10px;
}

.grid-item-content-img {
  width: 70%;
}

.grid-item-content-img img {
  width: 100%;
  height: 300px;
  object-fit: cover;
  border-radius: 0px 0px 20px 0px;
}

.grid-item-buttons-text {
  display: flex;
  flex-direction: column;
  font-size: 18px;
}

.grid-item-info-buttons {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.grid-item-info-button {
  width: 100%;
  cursor: pointer;
  background-color: #00afe8;
  padding: 10px 15px;
  color: #fff;
  border-radius: 5px;
  font-weight: 600;
  font-size: 14px;
  text-overflow: ellipsis;
  transition: all 0.3s ease;
}

.grid-item-info-button:hover {
  background-color: #15c4ff;
}

.grid-item-info-button:nth-child(2) {
  width: 60%;
  cursor: pointer;
  background-color: #104172;
  padding: 10px 15px;
  color: #fff;
  border-radius: 5px;
  font-weight: 600;
  font-size: 14px;
  text-overflow: ellipsis;
  transition: all 0.3s ease;
}

.grid-item-info-button:nth-child(2):hover {
  background-color: #16599d;
}

.grid-item-buttons {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  padding: 0 5px;
}

.grid-item-button {
  width: 100%;
  cursor: pointer;
  border: 1px solid #ccc;
  border-bottom: 0;
  padding: 10px 15px;
  color: #333;
  border-radius: 5px 5px 0 0;
  font-weight: 400;
  font-size: 14px;
}

.grid-item-button.active {
  width: 100%;
  cursor: pointer;
  background-color: #00afe8;
  padding: 10px 15px;
  color: #fff;
  border-radius: 5px 5px 0 0;
  font-weight: 600;
  font-size: 14px;
}

.grid-item-buttons-text p {
  border-top: 3px solid #00afe8;
  padding: 15px;
  font-size: 14px;
  font-weight: 300;
  color: #333;
  text-align: start;
}

.container-data-price-package-medic{
  display: flex;
  gap: 10px;
}

@media (max-width: 870px) {
  .grid-container {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 500px) {
  .grid-item-content{
    flex-direction: column;
  }

  .grid-item-content-img {
    width: 100%;
  }

  .grid-item-content-img img{
    border-radius: 0px 0px 0px 0px;
  }
}
