.content-search-ambulance{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    box-shadow: 0px 3px 10px -1px rgba(0,0,0,0.1);
    padding: 1.5rem;
    border-radius: 10px;
}

.content-legend-ambulance{
    display: flex;
    align-items: center;
}

.content-legend-ambulance h3{
    color: var(--bluePrimary);
    font-size: 18px;
}

.camp-ambulance{
    display: flex;
    justify-content: space-around;
    align-items: center;
    border: 1px solid #D9D9D9;
    padding: 8px;
    border-radius: 6px;
    transition: .5s ease-in-out;

}


.search-ambulance{
    width: 100%;
    background-color: var(--bluePrimary);
    padding: 7px;
    border-radius: 6px;
    border:none;
    transition: .5s ease-in-out;
}

.search-ambulance:hover , .camp-ambulance:hover{
    transform: scale(1.03);
    cursor: pointer;
}