

.contain-data-appointment h2 {
    color: var(--bluePrimary);
    font-size: 24px;
}

.list-data-appointment{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.list-data-appointment .camp {
    display: flex;
    flex-direction: column;
    gap: .4rem;
}

.list-data-appointment .camp label{
    color: var(--bluePrimary);
}

.list-data-appointment .camp input{
    padding: 10px;
    border-radius: 6px;
    border: 1px solid rgb(198, 198, 198);
    color: rgb(114, 114, 114);
}