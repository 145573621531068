.container-select-destination{
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    margin-top: 10px;
}

.container-select-destination select{
    padding-inline: 10px;
    margin-top: 10px;
    cursor: pointer;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    height: 32px;
    border-radius: 8px;
    border: 1px solid #D9D9D9;
    background: url('../../../../ui/assets/iconsSVG/ArrowDown2.svg') no-repeat;
    background-position: right 10px center;
    font-family: "Montserrat" !important;
    font-size: 12px;
    font-weight: 400;
}