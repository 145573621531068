.contentCartCheckoutInfo {
  width: 100%;
}

.modalQuestion {
  display: flex;
  flex-direction: column;
  gap: 1rem;


  img {
      width: 100px;
      margin: 0 auto;
  }

  div {
      display: flex;
      justify-content: space-between;
      gap: .5rem;
  }

  p {
      font-size: 20px;
      font-weight: 600;
      text-align: center;
  }

  button {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
      background-color: var(--bluePrimary);
      color: var(--white);
      padding: 13px 20px;
      border-radius: 8px;
      cursor: pointer;
      text-align: center;
      width: 100%;
      max-width: 400px;
      font-weight: 600;
      font-size: 16px;
      transition: all 0.3s ease;
  }

  button:hover {
      background-color: var(--blueTertiary);
  }

  ul {
      list-style: none;
      display: flex;
      flex-direction: column;
      gap: .5rem;
  }

  li {
      display: flex;
      align-items: center;
      font-weight: 600;
      gap: 5px;
  }

  span {
      font-weight: 400;
  }

  


}

.note{
  font-size: 12px !important;
  font-weight: 400 !important;
  text-align: start !important;
}


.cartCheckoutSection {
  width: 95%;
  margin: 0 auto;
  display: grid;
  max-width: 1280px;
  place-items: center;
}

.cartCheckout {
  width: 100%;
  max-width: 1280px;
  display: flex;
  justify-content: space-between;
  gap: 50px;
  margin: 20px 0px 40px 0px;
}

.itemSectionAll {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

@media (max-width: 1350px) {
  .itemSectionAll {
    overflow: scroll;
  }
}


.cart {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.cart h2 {
  font-size: 28px;
  font-weight: 700;
  color: var(--bluePrimary);
}

.cartItems {
  display: flex;
  flex-direction: column;
  gap: 60px;
  padding-top: 20px;
}

.cartItemsItem {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.cartItemsItem h3 {
  font-size: 18px;
  font-weight: 600;
  color: var(--bluePrimary);
  padding-bottom: 8px;
  border-bottom: 2px solid var(--bluePrimary);
}

.cartItemsItemOne {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.toggleButton {
  background-color: var(--bluePrimary);
  color: var(--white);
  border: none;
  width: fit-content;
  border-radius: 10px;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.totalPrice {
  background-color: var(--bluePrimary);
  padding: 10px;
  color: var(--white);
  display: flex;
  justify-content: flex-start;
  border-radius: 8px;
}

.totalPriceText {
  display: flex;
  flex-direction: column;
  gap: 3px;
  font-size: 14px;
}

.totalPriceText span {
  font-weight: 700;
  font-size: 18px;
}

.checkout {
  padding-top: 30px;
  width: 70%;
}

.checkoutContent {
  position: sticky;
  top: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  min-width: 100px;
  width: 100%;
  padding: 20px;
  border: 1px solid var(--blueWhiteTableThead);
  border-radius: 8px;
  background-color: var(--white);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.042);
}

.checkoutTitle {
  font-size: 18px;
  color: var(--bluePrimary);
  font-weight: 600;
}

.checkoutList {
  width: 100%;
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.checkoutListItem {
  display: flex;
  justify-content: space-between;
  color: var(--bluePrimary);
}

.checkoutListItem p {
  font-weight: 600;
}

.checkoutListPrice {
  margin: 0;
  color: #2c3e50;
}

.checkoutListPriceTicket {
  margin: 0;
  color: #2c3e50;
  background-color: var(--blueWhiteTableThead);
  padding: 5px 10px;
  border-radius: 7px;
}

.checkoutActions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  margin-top: 10px;
  border-top: 1px solid var(--blueWhiteTableThead);
  padding-top: 20px;
}

.checkoutCheckboxWrapper {
  width: 100%;
  display: flex;
  align-items: center;
}

.checkoutCheckboxWrapper input {
  width: 15px;
  height: 15px;
  border-radius: 5px;
}

.checkoutCheckboxLabel {
  margin-left: 8px;
  font-size: 14px;
  color: var(--bluePrimary);
}

.checkoutButton {
  width: fit-content;
  padding: 10px 30px;
  background-color: var(--bluePrimary);
  border: none;
  border-radius: 8px;
  color: var(--white);
  font-size: 14px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.checkoutButton:hover {
  background-color: var(--blueTertiary);
}

.itemAppointment {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 13px;
  border: 1px solid var(--blueWhiteTableThead);
  border-radius: 10px;
  padding: 15px;
}

.itemFlight {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 13px;
  border: 1px solid var(--blueWhiteTableThead);
  border-radius: 10px;
  padding: 15px;
}

.itemHotel {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  border: 1px solid var(--blueWhiteTableThead);
  border-radius: 10px;
  padding: 15px;
}

.itemHotelContent {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 13px;
}

.itemHeader {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.itemHeaderIconText {
  display: flex;
  align-items: center;
  gap: 10px;
}

.itemImage {
  padding-left: 20px;
  border-left: 2px solid var(--bluePrimary);
  height: 100%;
  margin-left: 10px;
}

.itemDate {
  font-size: 14px;
  font-weight: 600;
  color: var(--bluePrimary);
}
.itemHeaderText {
  display: flex;
  flex-direction: column;
  gap: 2px;
  color: var(--bluePrimary);
  font-size: 14px;
  font-weight: 500;
}

.itemHeaderText h4 {
  font-size: 18px;
}

.icon {
  font-size: 18px;
  background-color: var(--bluePrimary);
  color: var(--white);
  min-width: 30px;
  min-height: 30px;
  border-radius: 6px;
  display: grid;
  place-items: center;
}

.itemDetails {
  display: flex;
  justify-content: space-between;
  gap: 50px;
  align-items: flex-end;
}

.itemDetailsHotel {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.itemDetailsHotel ul li {
  list-style: none;
}

.image {
  width: 200px;
  border-radius: 8px;
  overflow: hidden;
}

.image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.itemBaggage {
  display: flex;
  align-items: flex-end;
  gap: 10px;
  font-size: 20px;
  color: var(--bluePrimary);
}

.luggageList {
  display: flex;
  gap: 10px;
  list-style: none;
}

.detailsButton {
  font-size: 14px;
  color: var(--bluePrimary);
  text-decoration: underline;
  cursor: pointer;
}

.detailsButtonHotel {
  background-color: var(--bluePrimary);
  padding: 8px 10px;
  border-radius: 6px;
  color: var(--white);
  font-size: 14px;
}

.itemLinks {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 10px;
  align-items: center;
}

.itemLinks p {
  color: var(--blackGray);
  font-weight: 400;
  display: flex;
  flex-direction: column;
  gap: 3px;
}

.itemLinks p span {
  color: var(--bluePrimary);
  font-weight: 600;
}

.airlineLogo {
  width: 120px;
  height: 20px;
}

.airlineLogo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.itemDetailsFlights {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
}

.itemDetailsFlights1 {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: 20px;
  height: 100%;
  border-right: 2px solid var(--bluePrimary);
  padding-right: 30px;
}

.itemDetailsFlights2 {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: 20px;
  height: 100%;
  padding-left: 30px;
}

.itemDetailsFlights2Any {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  height: 100%;
  padding-left: 30px;
  color: var(--bluePrimary);
}

.itemDetailsFlightsColumn2 {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  height: 100%;
}

.itemDetailsFlightsColumn1 {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  gap: 10px;
  width: 100%;
}

.itemDetailsFlightsColumn1Footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
}

.itemDetailsFlightsColumn1FooterPlace b {
  color: var(--bluePrimary);
}

.luggageIcon {
  display: flex;
  gap: 10px;
  color: var(--bluePrimary);
  font-size: 18px;
  align-items: center;
  font-weight: 500;
}

.typeFlight {
  display: flex;
  flex-direction: column;
  color: var(--bluePrimary);
  font-weight: 700;
}

.typeFlight strong {
  font-weight: 500;
}


.checkoutButtonDisabled {
  opacity: 0.5;
  cursor: not-allowed;
  transition: all 0.3s ease;
}

.containerSearch {
  width: 100%;
  display: flex;
  justify-content: start;
  overflow-x: scroll;
  scrollbar-width: none;
}

.containerSearch::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}


@media screen and (max-width: 1110px) {
  .cartCheckout {
    width: 100%;
    max-width: 1280px;
    display: flex;
    flex-direction: column-reverse;
    gap: 20px;
    margin: 0px 0px 30px 0px;
  }

  .checkout {
    padding-top: 0px;
    margin-bottom: 30px;
    width: 100%;
  }
}

@media screen and (max-width: 450px) {
  .checkoutListItem {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: var(--bluePrimary);
  }
}

