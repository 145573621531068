@import url('https://fonts.googleapis.com/css2?family=Anton&display=swap');

.countdown-container {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
  margin: 0.5rem;
}

.countdown-container>form {
  padding: 0.5rem;
}

.countdown-container>form>label {
  margin-right: 1rem;
}

.expired-notice {
  text-align: center;
  padding: 2rem;
  border: 1px solid #ebebeb;
  border-radius: 0.25rem;
  margin: 0.5rem;
  background-color: #ececec;
  box-shadow: 10px 10px #cfc9c9;
}

.congratulations-lanz {
  font-size: 2.5rem;
  font-weight: bold;
  color: rgb(6, 154, 6);
}

.congratulations-lanz-p {
  font-size: 1.5rem;
  color: white;
  font-weight: 500;
}

.show-counter {
  padding: 0 0 6rem 4rem;
  position: absolute;
  z-index: 999999;
  width: 100%;
  height: 100vh;
  background-color: var(--bluePrimary);
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  justify-content: end;
}

.cooming-soon {
  color: white;
  font-size: 60px;
  font-family: 'Anton', sans-serif !important;
  letter-spacing: 5px;
}

.cooming-soon-p {
  color: white;
  margin-bottom: 2rem;
  margin-top: -0.6rem;
}

.show-counter video {
  position: fixed;
  min-width: 100%;
  min-height: 100%;
  top: 50%;
  left: 50%;
  z-index: -1;
  transform: translateX(-50%) translateY(-50%);
}



.show-counter .countdown-link {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 2.25rem;
  line-height: 1.75rem;
  padding: 0.5rem;
  color: white;
  text-decoration: none;
}

.show-counter .countdown {
  line-height: 1.25rem;
  padding: 0 0.75rem 0 0.75rem;
  align-items: center;
  display: flex;
  gap: 3px
}

.countdown p {
  background-color: white;
  padding: 1.5rem 1rem;
  border-radius: 10px;
  color: var(--bluePrimary);
}

.show-counter .countdown.danger {
  color: #ff0000;
}

.show-counter .countdown>p {
  margin: 0;
}

.show-counter .countdown>span {
  text-transform: uppercase;
  font-size: 1.5rem;
  line-height: 2rem;
}

@media only screen and (max-width: 700px) {
  .show-counter {
    padding: 0 0 2rem 1rem;
  }

  .cooming-soon {

    font-size: 50px;

  }


  .countdown p {
    padding: 1rem 0.5rem;
    border-radius: 10px;
    font-size: 25px;
  }
}


@media only screen and (max-width: 500px) {
  .show-counter .countdown>span {
    font-size: 1rem;
    line-height: 1rem;
  }

  .show-counter h1 {
    margin-top: 15rem;
  }

  .cooming-soon-p {
    font-size: 14px;
  }

  .cooming-soon {
    font-size: 40px;
  }

  .show-counter .countdown-link {
    font-size: 1.5rem;
  }

  .countdown p {

    padding: 0.5rem 0.5rem;
    border-radius: 4px;
    font-size: 18px;

  }

  .show-counter {
    justify-content: center;
    align-items: center;
  }
}

@media only screen and (max-width: 380px) {

  .show-counter {
    padding: 0;
  }

  .countdown p {

    padding: 0.5rem 0.5rem;
    border-radius: 4px;
    font-size: 15px;

  }

  .cooming-soon-p {
    font-size: 12px;
  }

  .cooming-soon {
    font-size: 35px;
  }
}