.pqrsFrequentQuestions {
  width: 50%;
  display: flex;
  flex-direction: column;
  transition: all ease 200ms;
}

.pqrsFrequentQuestions .pqrsFrequentQuestionsTitle {
  font-size: 500;
  color: var(--black);
  text-align: left;
  font-family: 'Montserrat', sans-serif;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 20px;
}

.pqrsFrequentQuestions .pqrsFrequentQuestionsWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.pqrsFrequentQuestions .pqrsFrequentQuestionsWrapper .pqrsFaq {
  border-top: solid 2px var(--greyPrimary);
  background-color: var(--white);
  width: 100%;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  transition: all ease 200ms;
  padding: 12px 5px;
  gap: 15px;
}

.pqrsFrequentQuestions .pqrsFrequentQuestionsWrapper .pqrsFaq:hover {
  transition: all ease 200ms;
  background-color: rgba(0, 0, 0, 0.05);
}

.pqrsFrequentQuestions .pqrsFrequentQuestionsWrapper .pqrsFaq .pqrsFaqRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.pqrsFrequentQuestions .pqrsFrequentQuestionsWrapper .pqrsFaq .pqrsFaqRow .pqrsIcon {
  width: 20px;
  height: 20px;
  background-color: green;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}

.pqrsFrequentQuestions .pqrsFrequentQuestionsWrapper .pqrsFaq .pqrsFaqRow .pqrsTitle {
  width: 100%;
  color: var(--black);
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  font-weight: 5 00;
}

.fade-in {
  opacity: 0;
  animation: fadeIn 2s forwards;
}

.fade-out {
  opacity: 1;
  animation: fadeOut 2s forwards;
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  to {
    opacity: 0;
  }
}



@media screen and (max-width: 860px) {
  .pqrsFrequentQuestions {
    width: 100%;
    transition: all ease 200ms;
  }
}

.pqrsContent {
  
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease;
}

.pqrsContent p {
  font-size: 14px;
  color: #4c4c4c;

}

