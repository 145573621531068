.grid_container_doc {
    display: grid;
    grid-gap: 15px;
    grid-template-columns: repeat(3, 1fr);
  }

  .grid_container_doc_step{
    display: grid;
    grid-gap: 15px;
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 1150px) {
    .grid_container_doc {
      grid-template-columns: repeat(3, 1fr);
    }

    .grid_container_doc_step{
      grid-template-columns: repeat(3, 1fr);
    }
  }
  
  @media (max-width: 900px) {
    .grid_container_doc {
      grid-template-columns: repeat(2, 1fr);
    }

    .grid_container_doc_step{
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  @media (max-width: 570px) {
    .grid_container_doc {
      grid-template-columns: repeat(1, 1fr);
    }

    .grid_container_doc_step{
      grid-template-columns: repeat(1, 1fr);
    }
  }