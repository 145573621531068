.methodPayContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 95%;
    max-width: 1280px;
    margin: 0 auto;
    margin-top: 20px;
    padding-bottom: 40px;
}

.methodPayContainer p {
    color: var(--bluePrimary);
}

.methodPayHeader {
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding-bottom: 10px;
    border-bottom: 2px solid var(--bluePrimary);
}

.methodPayHeader h3 {
    font-size: 24px;
    font-weight: 600;
    color: var(--bluePrimary);
}

.methodPayHeader p {
    font-size: 14px;
    font-weight: 400;
    color: var(--bluePrimary);
}


.methodPayContain {
    display: flex;
    justify-content: space-between;
    gap: 50px;
    margin-bottom: 30px;
}
@media (max-width: 600px) {
    .methodPayContain {
        flex-direction: column;
        justify-content: flex-start; 
    }
}

.methodPayContainQuestions {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.methodPayContainInfo {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.methodPayContainQuestionsContain h5 {
    width: 100%;
    font-size: 18px;
    color: var(--bluePrimary);
    padding: 0px !important;
    padding-bottom: 8px !important;
    border-bottom: 1px solid var(--bluePrimary);
}

.methodPayContainerTermsContain {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.methodPayContainerTermsContain h5 {
    width: 100%;
    font-size: 18px;
    color: var(--bluePrimary);
    padding: 0px !important;
    padding-bottom: 8px !important;
    border-bottom: 1px solid var(--bluePrimary);
}

.methodPayContainTermsContain {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.methodTerms {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.methodTerms h6 {
    font-size: 18px;
    font-weight: 500;
    color: var(--bluePrimary);
}

.methodTerms p {
    font-size: 16px;
    font-weight: 400;
    color: var(--bluePrimary);
}


.methodPayImageContain {
    position: sticky;
    top: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.securityPay {
    border: 1px solid var(--blueWhiteTableThead);
    padding: 20px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    gap: 20px;
    color: var(--bluePrimary);
}

.methodPayImageContainImage {
    width: 300px;
    margin: 0 auto;
    margin-top: 30px;
}

.methodPayContainInfoContain {
    border: 1px solid var(--blueWhiteTableThead);
    width: 100%;
    border-radius: 8px;
    color: var(--bluePrimary);
}

.methodPayContainInfoContainPadding {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    color: var(--bluePrimary);
    padding: 20px;
}

.methodPayContainInfoContainPadding h3 {
    font-weight: 600;
    font-size: 20px;
    padding-bottom: 8px;
}




.infoContainItem {
    display: flex;
    flex-direction: column;
    gap: 3px;
    color: var(--bluePrimary);
}

.infoContainItem label {
    font-size: 16px;
    font-weight: 400;
}

.infoContainItem p {
    font-size: 18px;
    font-weight: 600;

}








.itemDetailsFlights {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
    border-top: 1px solid var(--blueWhiteTableThead);
    padding-top: 20px;
    margin-top: 20px;
}

.itemDetailsFlights1 {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    gap: 20px;
    height: 100%;
    border-right: 2px solid var(--bluePrimary);
    padding-right: 30px;
}

.itemDetailsFlightsColumn1 {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    gap: 10px;
    width: 100%;
}

.typeFlight {
    display: flex;
    flex-direction: column;
    color: var(--bluePrimary);
    font-weight: 700;
}

.typeFlight strong {
    font-weight: 500;
}

.airlineLogo {
    width: 70px;
    height: 20px;
}

.airlineLogo img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.itemDetailsFlightsColumn1Footer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 30px;
}

.itemDetailsFlightsColumn1FooterPlace b {
    color: var(--bluePrimary);
}

.itemDetailsFlights2Any {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    height: 100%;
    padding-left: 30px;
    color: var(--bluePrimary);
}






.itemDetailsFlights2 {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    gap: 20px;
    height: 100%;
    padding-left: 30px;
}





.methodValues {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.methodValuesContaint {
    display: flex;
    flex-direction: column;
    gap: 20px;
    border-top: 1px solid var(--blueWhiteTableThead);
    padding-top: 20px;
}

.methodValuesItem {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    align-items: center;
}

.methodValuesItem span {
    font-weight: 600;
}

.methodValuesItemTotal {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    align-items: center;
    border-top: 1px solid var(--blueWhiteTableThead);
    padding-top: 20px;
}

.methodValuesItemTotal span {
    font-weight: 600;
}


.methodValuesButtons {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    align-items: center;
}


.methodValuesLabel {
    display: flex;
    gap: 10px;
    align-items: center;
    font-size: 14px;
}

.methodValuesLabel label {
    color: #104172;
    font-size: 14px;
    cursor: pointer;
    user-select: none;
    font-weight: 500;
    text-wrap: nowrap;
}


.methodPayButton {
    width: fit-content;
    background-color: var(--bluePrimary);
    font-family: inherit;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 14px;
    border-radius: 6px;
    color: #fff;
    transition: all 0.3s ease;
}

.methodPayButton:hover {
    background-color: var(--bluePrimary);
}

.methodPayButton.disabled {
    background-color: var(--bluePrimary);
    pointer-events: none;
    opacity: 0.6;
}




.uiCheckbox {
    --primary-color: #104172;
    --secondary-color: #fff;
    --primary-hover-color: #4096ff;
    /* checkbox */
    --checkbox-diameter: 20px;
    --checkbox-border-radius: 5px;
    --checkbox-border-color: #d9d9d9;
    --checkbox-border-width: 1px;
    --checkbox-border-style: solid;
    /* checkmark */
    --checkmark-size: 1.2;
}

.uiCheckbox,
.uiCheckbox *,
.uiCheckbox *::before,
.uiCheckbox *::after {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.uiCheckbox {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: var(--checkbox-diameter);
    height: var(--checkbox-diameter);
    min-width: var(--checkbox-diameter);
    min-height: var(--checkbox-diameter);
    border-radius: var(--checkbox-border-radius);
    background: var(--secondary-color);
    border: var(--checkbox-border-width) var(--checkbox-border-style) var(--checkbox-border-color);
    -webkit-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    cursor: pointer;
    position: relative;
}

.uiCheckbox::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    -webkit-box-shadow: 0 0 0 calc(var(--checkbox-diameter) / 2.5) var(--primary-color);
    box-shadow: 0 0 0 calc(var(--checkbox-diameter) / 2.5) var(--primary-color);
    border-radius: inherit;
    opacity: 0;
    -webkit-transition: all 0.5s cubic-bezier(0.12, 0.4, 0.29, 1.46);
    -o-transition: all 0.5s cubic-bezier(0.12, 0.4, 0.29, 1.46);
    transition: all 0.5s cubic-bezier(0.12, 0.4, 0.29, 1.46);
}

.uiCheckbox::before {
    top: 40%;
    left: 50%;
    content: "";
    position: absolute;
    width: 4px;
    height: 7px;
    border-right: 2px solid var(--secondary-color);
    border-bottom: 2px solid var(--secondary-color);
    -webkit-transform: translate(-50%, -50%) rotate(45deg) scale(0);
    -ms-transform: translate(-50%, -50%) rotate(45deg) scale(0);
    transform: translate(-50%, -50%) rotate(45deg) scale(0);
    opacity: 0;
    -webkit-transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6),
        opacity 0.1s;
    -o-transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
    transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
}

/* actions */

.uiCheckbox:hover {
    border-color: var(--primary-color);
}

.uiCheckbox:checked {
    background: var(--primary-color);
    border-color: transparent;
}

.uiCheckbox:checked::before {
    opacity: 1;
    -webkit-transform: translate(-50%, -50%) rotate(45deg) scale(var(--checkmark-size));
    -ms-transform: translate(-50%, -50%) rotate(45deg) scale(var(--checkmark-size));
    transform: translate(-50%, -50%) rotate(45deg) scale(var(--checkmark-size));
    -webkit-transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
    -o-transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
    transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
}

.uiCheckbox:active:not(:checked)::after {
    -webkit-transition: none;
    -o-transition: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    transition: none;
    opacity: 1;
}

.detailPayFlight h3 {
    color: var(--bluePrimary);
    font-size: 18px;
}

.detailPayFlightHeader {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-bottom: 30px;
}

.detailPayFlightHeaderImage {
    width: 100px;
    height: 100px;
    padding: 3px;
    border-radius: 8px;
    box-shadow: var(--shawdowPrimary);
}

.detailPayFlightHeaderImage img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.detailPayFlightInfo {
    display: flex;
    flex-direction: column;
    gap: 2px;
}

.detailPayFlightInfo h2 {
    font-size: 24px;
    color: var(--bluePrimary);
}

.detailPayFlightName {
    display: flex;
    gap: 5px;
    align-items: center;
    color: var(--bluePrimary);
}

.detailPayFlightName p {
    font-size: 18px;
}




.contentStatePay {
    display: flex;
    gap: 20px;
    padding: 1rem;
    align-items: center;
    background-color: var(--greySecundary);
    border-radius: 8px 8px 0px 0px;
    color: var(--white);
}

.contentStatePay h3 {
    font-size: 18px;
    display: flex;
    align-items: center;
}

.contentStatePay p {
    display: flex;
    gap: 6px;
    color: var(--white);
}

.contentStatePay p div {
    height: 20px;
    width: 20px;
    background-color: var(--greenPrimary);
    border-radius: 50%;
    color: var(--white);
}

.contentStatePayMessage {
    color: var(--white);
    display: flex;

    gap: 20px;
}

.contentStatePayMessage p {
    color: var(--white);
    padding-top: 3px;
}


.APPROVED {
    background-color: var(--greenOffert);
  }
  
  .PENDING {
    background-color: var(--orangePrimary);
  }
  
  .REJECTED {
    background-color: var(--redSecondary);
  }



@media screen and (max-width: 500px) {
    .methodPayButton {
        width: 100%;
    }
}

@media screen and (max-width: 550px) {
    .itemDetailsFlights {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-bottom: 10px;
        gap: 20px;
    }

    .itemDetailsFlights2 {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        gap: 20px;
        height: 100%;
        padding-left: 0px;
    }

    .itemDetailsFlights1 {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        gap: 20px;
        height: 100%;
        border: none;
        padding-right: 0px;
    }
}