.pqrsPrimaryScreen {
  display: flex;
  flex-direction: column;
  width: 95%;
  margin: auto;
  max-width: 1280px;
}

.pqrsPrimaryScreen .pqrsPrimaryScreenRow {
  display: flex;
  gap: 50px;
  justify-content: space-between;
  transition: all ease 200ms;
  margin-bottom: 60px;
}

@media screen and (max-width: 860px) {
  .pqrsPrimaryScreen .pqrsPrimaryScreenRow {
    flex-direction: column;
    transition: all ease 200ms;
  }
}
