
.tableContainer{
    width: 40%;
}
.containerResultPayment{
    margin: 0px 5%;
}

.paragraph{
    margin: 10px 0px;
}

.paragraphTable{
margin: 25px 0px;
}

.paragraphTableList{
  
border: 3px double black;}
.paragraphTable ul{margin-left: 20px;}

