.modalPurchase {
  display: grid;
  grid-template-rows: auto;
  background-color: rgb(255, 255, 255);
  margin: 12% 20% 0px 20%;
  height: 40em;
  box-shadow: -4px 4px 4px rgba(0, 0, 0, 0.25) !important;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.purchaseSummary {
  display: grid;
  grid-template-rows: 40% 15% 15% 15% 15%;
  width: 100%;
  height: 100%;
}

.succes {
  height: 14em;
  align-items: center;
  margin-left: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.succes img {
  height: 100%;
  object-fit: cover;
}

.wellezySucces {
  height: 4em;
  display: flex;
  align-items: center;
  margin-left: 5px;
}
.wellezySucces img {
  height: 100%;
  object-fit: cover;
}
.footerSucces {
  height: 100%;
  background-color: #004274;

  border-radius: 0px 0px 10px 10px;
  -webkit-border-radius: 0px 0px 10px 10px;
  -moz-border-radius: 0px 0px 10px 10px;
  -ms-border-radius: 0px 0px 10px 10px;
  -o-border-radius: 0px 0px 10px 10px;
}
.codeReserve {
  background-color: #8698a54a;
}
.itemSucces,
.itemsCodeReserve P {
  display: flex;
  justify-content: center;
  align-items: center;
}
.itemsCodeReserve p {
  margin-bottom: 8px;
}
