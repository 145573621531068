.p-traveler-sura{
    font-size: 14px !important;
    font-weight: 700 !important;
    color: #0032A6 !important;
    font-family: "Montserrat" !important;
}

.container-inputs-sura{
    display: flex;
    justify-content: space-between;
    align-items: start;
    margin-block: 10px;
    padding-block: 10px;
}

.container-inputs-sura > div{
    width: 31%;
}

.p-data-contact{
    font-size: 14px !important;
    font-weight: 500 !important;
    color: black !important;
    font-family: "Montserrat" !important;
}

.hr-passengers-sura{
    border: 1px solid #0032A6;
    margin-block: 20px;
}

.data-picker-voucher-sura{
    border: 1px solid #D9D9D9;
    width: 100%;
    height: 32px;
    border-radius: 8px;
    text-align: left;
    cursor: pointer;
    padding-inline: 10px;
    background: url('../../../SuraDataPassengersDetail/SuraEditFormQuote/Assets/svg/IconCalendar.svg') no-repeat;
    background-position: right 10px center;
}

.validate-gender-sura{
    border: 1px solid red !important;
    transition: 0.3s;
}

.input-gender-sura{
    display: flex;
    flex-direction: column;
}

.input-gender-sura label{
    margin-bottom: 10px;
}

.input-gender-sura select{
    cursor: pointer;
    appearance: none;
    background: url('../../SuraEditFormQuote/Assets/svg/IconArrowDown.svg') no-repeat;
    background-position: right 10px center;
    width: 100%;
    height: 32px;
    border: 1px solid #D9D9D9;
    border-radius: 8px;
    padding: 5px;
    font-size: 12px;
    font-weight: 400;
}

.input-validate-sura input {
    border: 1px solid red !important;
    transition: 0.3s;
}

.validate-input-sura{
    color: red;
    font-size: 10px;
    font-family: "Montserrat" !important;
    font-weight: 400;
    margin-top: 5px;
}

@media screen and (max-width: 600px){
    .container-inputs-sura{
        flex-direction: column;
    }

    .container-inputs-sura > div{
        width: 100%;
        margin-block: 10px;
    }
}