.container-header-odontology{
    background-image: url(https://www.legaltoday.com/wp-content/uploads/2020/09/ODONTOLOGO.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
    height: 700px;
   
}
.container-header-farmacia {
    background-image: url(https://aperturasfarmaciasv.com/wp-content/uploads/2022/01/farmacia_iluminacion.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
    height: 700px;
}
.container-header-nurses {
    background-image: url(https://selecciones.com.mx/wp-content/uploads/2019/09/secretos-de-enfermeras.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
    height: 700px;
}

.container-header-restaurants{
    background-image: url(https://cloudfront-us-east-1.images.arcpublishing.com/elespectador/2K7FHLJP75FGRO2LKQMCFKFKWA.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
    height: 700px;
}

.container-header-translators{
    background-image: url(https://www.lebloo.com/wp-content/uploads/2022/01/Blog-Lebloo-im%C3%A1genes-tipo-banner-1400x700.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
    height: 700px;
}

.container-header-gimnasios {
    background-image: url(https://www.altonivel.com.mx/wp-content/uploads/2017/10/gimnasio-caminadora.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
    height: 700px;
}

.container-header-spa {
    background-image: url(https://image-tc.galaxy.tf/wijpeg-3ka6q9zjnwjz467zcajqc2wvy/watercircuit3-optimized.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
    height: 700px;
}
.container-header-ambulancias {
    background-image: url(https://bogota.gov.co/sites/default/files/styles/1050px/public/2022-03/ambulancias-secretaria-de-salud.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
    height: 700px;
}
.container-header-estetica {
  background-image: url(https://images.pexels.com/photos/853427/pexels-photo-853427.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  height: 700px;
}

.container-header-casas {
  background-image: url(https://casatona.com/wp-content/uploads/2022/07/IMG_0154-1024x683.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  height: 700px;
}
.container-header-hoteles {
  background-image: url(https://www.hotelcosmoscali.com/uploads/cms_apps/imagenes/COSMOS_FACHADA_DIA_L3A9238.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  height: 700px;
}
.container-header-turismo {
  background-image: url(https://images.squarespace-cdn.com/content/v1/5c4df9934eddecd2894708b5/1645321007408-J9RO6D95HBWA34ML0W85/processed_20180808_105929%281%29-01.jpeg?format=1000w);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  height: 700px;
}
.container-header-conductores {
  background-image: url(https://as01.epimg.net/betech/imagenes/2016/08/05/portada/1470404239_397824_1470404473_noticia_normal.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  height: 700px;
}

.header-services {
 position: absolute;
 height: 100%;
 width: 100%;
 top: 0;
 background-color: rgba(0, 0, 0, 0.654);
}
.principal-text{
    position: absolute;
    top:40%;
    left: 20%;
}
.principal-text h1{
    font-size: 70px;
    font-family: 'Inter';
    color: white;
    font-weight: 500;
    
}

.principal-text p {
    text-align: center;
    font-size: 30px;
    color: white;
    
}

.container-cards-services {

    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 1rem;
    margin-top: 5rem;
    
}


.contain-button-services{
  margin: 10px;

}
.contain-button-services input{
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  background-color: #004274;
  color: white;
  font-weight: 600;
  text-align: center;
  border: none;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: .5s ease-in-out;
}
.contain-button-services input:hover{
  transform: scale(1.06);
}
/* ---------CARDS ODONTOLOGY ----------- */


.card-odontology {
    margin: 10px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    width: 300px;
  }
  .card-header-odontology img {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
  .card-body-odontology {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 20px;
    min-height: 250px;
  }
  
  .tag-odontology {
    border-radius: 5px;
    font-size: 10px;
    max-height: 24px;
    max-width: 280px;
    overflow: scroll;
    margin: 0;
    color: #fff;
    padding: 2px 10px;
    text-transform: uppercase;
    cursor: pointer;
  }
 
  .tag-pink-odontology {
    background-color: #004274;
  }
  
  .card-body-odontology p {
    font-size: 13px;
    margin: 0 0 20px;
  }
  .user-odontology {
    display: flex;
    margin-top: auto;
  }
  
  .user-odontology img {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    margin-right: 10px;
  }
  .user-info-odontology h5 {
    margin: 0;
  }
  .user-info-odontology small {
    color: #545d7a;
  }

  .description-odontology {
    height: 100px;
    text-align: justify;
    overflow: scroll;
  }


/* ---------CARD FARMACIA---------------- */


.card-farmacia {
    margin: 10px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    width: 300px;
  }
  .card-header-farmacia img {
    width: 100%;
    height: 200px;
    object-fit: contain;
    
  }
  .card-body-farmacia {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 20px;
    min-height: 150px;
  }
  
  .tag-farmacia {
    background: #cccccc;
    border-radius: 50px;
    font-size: 12px;
    margin: 0;
    color: #fff;
    padding: 2px 10px;
    text-transform: uppercase;
    cursor: pointer;
  }
 
  .tag-pink-farmacia {
    background-color: #004274;
  }
  
  .card-body-farmacia p {
    font-size: 13px;
    margin: 0 0 40px;
  }
  .user-farmacia {
    display: flex;
    margin-top: auto;
  }
  
  .user-farmacia img {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    margin-right: 10px;
  }
  .user-info-farmacia h5 {
    margin: 0;
  }
  .user-info-farmacia small {
    color: #545d7a;
  }

  .description-farmacia {
    height: 100px;
    overflow: scroll;
  }
  
  


/* --------CARDS ENFERMERAS-------------- */



.nurses-nothing{
    min-height: 300px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}
.nurses-nothing img {
    height: 100px;
    width: 100px;
}


/* -------CARDS RESTAURANTS------------ */

.card-restaurant {
    margin: 10px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    width: 300px;
  }
  .card-header-restaurant img {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
  .card-body-restaurant {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 20px;
    min-height: 250px;
  }
  
  .tag-restaurant {
    background: #cccccc;
    border-radius: 50px;
    font-size: 12px;
    margin: 0;
    color: #fff;
    padding: 2px 10px;
    text-transform: uppercase;
    cursor: pointer;
  }
 
  .tag-pink-restaurant {
    background-color: #004274;
  }
  
  .card-body-restaurant p {
    font-size: 13px;
    margin: 0 0 40px;
  }
  .user-restaurant {
    display: flex;
    margin-top: auto;
  }
  
  .user-restaurant img {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    margin-right: 10px;
  }
  .user-info-restaurant h5 {
    margin: 0;
  }
  .user-info-restaurant small {
    color: #545d7a;
  }

  .description-restaurant {
    height: 100px;
    overflow: scroll;
  }



  /* -------CARDS TRANSLATOR----------- */


  .translator-nothing{
    min-height: 300px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  
.translator-nothing img {
    height: 100px;
    width: 100px;
}




/* -------CARDS GIMNASIOS---------- */




.card-gimnasios {
    margin: 10px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    width: 300px;
  }
  .card-header-gimnasios img {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
  .card-body-gimnasios {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 20px;
    min-height: 250px;
  }
  
  .tag-gimnasios {
    background: #cccccc;
    border-radius: 50px;
    font-size: 12px;
    margin: 0;
    color: #fff;
    padding: 2px 10px;
    text-transform: uppercase;
    cursor: pointer;
  }
 
  .tag-pink-gimnasios {
    background-color: #004274;
  }
  
  .card-body-gimnasios p {
    font-size: 13px;
    max-height: 100px;
    overflow: scroll;
    margin: 0 0 10px;
  }
  .user-gimnasios {
    display: flex;
    margin-top: auto;
  }
  
  .user-gimnasios img {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    margin-right: 10px;
  }
  .user-info-gimnasios h5 {
    margin: 0;
  }
  .user-info-gimnasios small {
    color: #545d7a;
  }

  .description-gimnasios {
    max-height: 100px;
    overflow: scroll;
  }



  /* -------CARDS SPA---------- */


  .card-spa {
    margin: 10px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    width: 300px;
  }
  .card-header-spa img {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
  .card-body-spa {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 20px;
    min-height: 250px;
  }
  
  .tag-spa {
    background: #cccccc;
    border-radius: 50px;
    font-size: 12px;
    margin: 0;
    color: #fff;
    padding: 2px 10px;
    text-transform: uppercase;
    cursor: pointer;
  }
 
  .tag-pink-spa {
    background-color: #004274;
  }
  
  .card-body-spa p {
    font-size: 13px;
    max-height: 100px;
    overflow: scroll;
    margin: 0 0 10px;
  }
  .user-spa {
    display: flex;
    margin-top: auto;
  }
  
  .user-spa img {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    margin-right: 10px;
  }
  .user-info-spa h5 {
    margin: 0;
  }
  .user-info-spa small {
    color: #545d7a;
  }

  .description-spa {
    max-height: 100px;
    overflow: scroll;
  }



  /* --------CARDS RENTA DE AUTOS---------- */



  .card-rentaAuto {
    margin: 10px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    width: 300px;
  }
  .card-header-rentaAuto img {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
  .card-body-rentaAuto {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 20px;
    min-height: 250px;
  }
  
  .tag-rentaAuto {
    background: #cccccc;
    border-radius: 50px;
    font-size: 12px;
    margin: 0;
    color: #fff;
    padding: 2px 10px;
    text-transform: uppercase;
    cursor: pointer;
  }
 
  .tag-pink-rentaAuto {
    background-color: #004274;
  }
  
  .card-body-rentaAuto p {
    font-size: 13px;
    max-height: 100px;
    overflow: scroll;
    margin: 0 0 10px;
  }
  .user-rentaAuto {
    display: flex;
    margin-top: auto;
  }
  
  .user-rentaAuto img {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    margin-right: 10px;
  }
  .user-info-rentaAuto h5 {
    margin: 0;
  }
  .user-info-rentaAuto small {
    color: #545d7a;
  }

  .description-rentaAuto {
    max-height: 100px;
    overflow: scroll;
  }



  /* ---------CARDS AMBULANCIAS------------- */



  .card-ambulancia {
    margin: 10px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    width: 300px;
  }
  .card-header-ambulancia img {
    width: 100%;
    height: 200px;
    object-fit: contain;
  }
  .card-body-ambulancia {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 20px;
    min-height: 180px;
  }
  
  .tag-ambulancia {
    background: #cccccc;
    border-radius: 50px;
    font-size: 12px;
    margin: 0;
    color: #fff;
    padding: 2px 10px;
    text-transform: uppercase;
    cursor: pointer;
  }
 
  .tag-pink-ambulancia {
    background-color: #004274;
  }
  
  .card-body-ambulancia p {
    font-size: 13px;
    max-height: 100px;
    overflow: scroll;
    margin: 0 0 10px;
  }
  .user-ambulancia {
    display: flex;
    margin-top: auto;
  }
  
  .user-ambulancia img {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    margin-right: 10px;
  }
  .user-info-ambulancia h5 {
    margin: 0;
  }
  .user-info-ambulancia small {
    color: #545d7a;
  }

 

  /* ---------CARDS ESTICA ---------- */



  .card-estetica {
    margin: 10px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    width: 300px;
  }
  .card-header-estetica img {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
  .card-body-estetica {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 20px;
    min-height: 250px;
  }
  
  .tag-estetica {
    background: #cccccc;
    border-radius: 50px;
    font-size: 12px;
    margin: 0;
    color: #fff;
    padding: 2px 10px;
    text-transform: uppercase;
    cursor: pointer;
  }
 
  .tag-pink-estetica {
    background-color: #004274;
  }
  
  .card-body-estetica p {
    font-size: 13px;
    margin: 0 0 40px;
  }
  .user-estetica {
    display: flex;
    margin-top: auto;
  }
  
  .user-estetica img {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    margin-right: 10px;
  }
  .user-info-estetica h5 {
    margin: 0;
  }
  .user-info-estetica small {
    color: #545d7a;
  }

  .description-estetica {
    height: 100px;
    overflow: scroll;
  }


  /* --------CARDS CASAS DESCANSO-------- */

  .card-casas {
    margin: 10px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    width: 300px;
  }
  .card-header-casas img {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
  .card-body-casas {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 20px;
    min-height: 250px;
  }
  
  .tag-casas {
    background: #cccccc;
    border-radius: 50px;
    font-size: 12px;
    margin: 0;
    color: #fff;
    padding: 2px 10px;
    text-transform: uppercase;
    cursor: pointer;
  }
 
  .tag-pink-casas {
    background-color: #004274;
  }
  
  .card-body-casas p {
    font-size: 13px;
    margin: 0 0 40px;
  }
  .user-casas {
    display: flex;
    margin-top: auto;
  }
  
  .user-casas img {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    margin-right: 10px;
  }
  .user-info-casas h5 {
    margin: 0;
  }
  .user-info-casas small {
    color: #545d7a;
  }

  .description-casas {
    height: 100px;
    overflow: scroll;
  }



  /* ------CARDS HOTELES--------- */



  .card-hoteles {
    margin: 10px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    width: 300px;
  }
  .card-header-hoteles img {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
  .card-body-hoteles {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 20px;
    min-height: 250px;
  }
  
  .tag-hoteles {
    background: #cccccc;
    border-radius: 50px;
    font-size: 12px;
    margin: 0;
    color: #fff;
    padding: 2px 10px;
    text-transform: uppercase;
    cursor: pointer;
  }
 
  .tag-pink-hoteles {
    background-color: #004274;
  }
  
  .card-body-hoteles p {
    font-size: 13px;
    margin: 0 0 40px;
  }
  .user-hoteles {
    display: flex;
    margin-top: auto;
  }
  
  .user-hoteles img {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    margin-right: 10px;
  }
  .user-info-hoteles h5 {
    margin: 0;
  }
  .user-info-hoteles small {
    color: #545d7a;
  }

  .description-hoteles {
    height: 100px;
    overflow: scroll;
  }


  /* -------CARDS TURISMO--------- */

  .card-turismo {
    margin: 10px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    width: 300px;
  }
  .card-header-turismo img {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
  .card-body-turismo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 20px;
    min-height: 250px;
  }
  
  .tag-turismo {
    background: #cccccc;
    border-radius: 50px;
    font-size: 12px;
    margin: 0;
    color: #fff;
    padding: 2px 10px;
    text-transform: uppercase;
    cursor: pointer;
  }
 
  .tag-pink-turismo {
    background-color: #004274;
  }
  
  .card-body-turismo p {
    font-size: 13px;
    margin: 0 0 20px;
  }
  .user-turismo {
    display: flex;
    margin-top: auto;
  }
  
  .user-turismo img {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    margin-right: 10px;
  }
  .user-info-turismo h5 {
    margin: 0;
  }
  .user-info-turismo small {
    color: #545d7a;
  }
  .contain-tag-turismo{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 5px;
  }

  .description-turismo {
    height: 100px;
    overflow: scroll;
  }


  /* --------CARDS CONDUCTORES--------- */



  .card-conductores {
    margin: 10px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    width: 300px;
    
  }
  .card-header-conductores img {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
  .card-body-conductores {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 20px;
    min-height: 250px;
  }
  
  .tag-conductores {
    background: #cccccc;
    border-radius: 50px;
    font-size: 12px;
    margin: 0;
    color: #fff;
    padding: 2px 10px;
    text-transform: uppercase;
    cursor: pointer;
  }
 
  .tag-pink-conductores {
    background-color: #004274;
  }
  
  .card-body-conductores p {
    font-size: 13px;
    margin: 0 0 20px;
  }
  .user-conductores {
    display: flex;
    margin-top: auto;
  }
  
  .user-conductores img {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    margin-right: 10px;
  }
  .user-info-conductores h5 {
    margin: 0;
  }
  .user-info-conductores small {
    color: #545d7a;
  }
  .contain-tag-conductores{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 5px;
  }

  .description-conductores {
    height: 100px;
    overflow: scroll;
  }


  /* ----------PAGINADO-------------- */

  .paginado-services {
    display: flex;
    justify-content: center;
    gap: 5px;
    align-items: center;
    margin-top: 3rem;
    padding: 1px;
    
  }

 
  .current-pag{
    font-size: 16px;
    font-weight: 600;
  }
 

  .paginado-services img{
   width: 40px;
   height: 40px;
  }