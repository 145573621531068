.carousel {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 100px;
  overflow: hidden;
}

.textWrapper {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.text {
  position: absolute;
  width: 100%;
  opacity: 0;
  transform: translateY(100%);
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
  color: var(--white);
  font-size: 18px;
  font-weight: 600;
  text-align: center;
}

.active {
  opacity: 1;
  transform: translateY(0);
}

.fadeOut {
  opacity: 0;
  transform: translateY(-100%);
}

.buttons {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  align-items: end;
  gap: 5px;
}

.button {
  width: 15px;
  height: 15px;
  border: none;
  background-color: var(--white);
  cursor: pointer;
  transition: background-color 0.3s ease;
  border-radius: 50%;
  transition: all 0.3s ease;
  opacity: 0.5;
}

.button:hover {
  background-color: var(--blueWhiteTable);
}

.activeButton {
  opacity: 1;
  background-color: var(--white);
  width: 20px;
  height: 20px;
}

@media screen and (max-width: 860px) {
  .carousel {
    position: relative;
    z-index: 9999999;
  }

  .buttons {
    position: relative;
    z-index: 9999999;
  }
}