.pqrsFooterButton{
  margin-top: 20px;
  background-color: var(--bluePrimary);
  display: flex;
  flex-direction: row;
  width: 45%;
  padding: 20px;
  border-radius: 20px;
  align-items: center;
  justify-content: space-between;
  transition: all ease 200ms;
  cursor: pointer;
}

.pqrsFooterButton:hover{
  transition: all ease 200ms;
  transform: translateY(-4px);
  -webkit-box-shadow: 0px 5px 5px 3px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0px 5px 5px 3px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 5px 5px 3px rgba(0, 0, 0, 0.25);
}

.pqrsFooterButton .pqrsFooterButtonIcon{
  width: 10%;
  min-width: 40px;
  max-width: 40px;
  height: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}

.pqrsFooterButton .pqrsFooterButtonColumn{
  display: flex;
  flex-direction: column;
  width: 90%;
}


.pqrsFooterButton .pqrsFooterButtonTitle{
  color: var(--white);
  text-align: left;
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  font-weight: bolder;
}

.pqrsFooterButton .pqrsFooterButtonSubtitle{
  color: var(--white);
  text-align: left;
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  font-weight: 400;
}


@media screen and (max-width: 860px) {
  .pqrsFooterButton {
    width: 100%;
    transition: all ease 200ms;
  }
}