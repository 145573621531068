.procedureCategoryDetailsMainn {
    max-width: 1080px;
    margin: 80px auto;
    
}

/*Main one*/
.procedureCategoryDetailsMain_one{
    background-image: url(./Img/Travel.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}
.content-Info-Travel{
    color: #004274;
    font-size: 20px;
    position: absolute;
    left: 30%;
    text-align: center;
    top: 10px;
}

.procedureCategoryDetailsMain-one_text {
    width: 50%;
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.procedureCategoryDetailsMain-one_text h2 {
    font-family: 'Inter';
    font-size: 50px;
    font-weight: 200;
    text-transform: capitalize;
}

.procedureCategoryDetailsMain-one-text_procedure {
    margin-bottom: 20px;
}

.procedureCategoryDetailsMain-one-text-procedure_description {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin: 10px 0;
}

.procedureCategoryDetailsMain-one-text-procedure_description p {
    width: 90%;
    font-family: 'Inter';
    font-size: 20px;
    font-weight: 200;
}

.procedureCategoryDetailsMain-one_time {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.procedureCategoryDetailsMain-one-time_description {
    margin-right: 120px;
}

.procedureCategoryDetailsMain-one-time_description p {
    font-family: 'Inter';
    font-size: 30px;
    font-weight: 200;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.procedureCategoryDetailsMain-one_img {
    width: 44%;
    height: 100%;
}

.procedureCategoryDetailsMain-one_img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

/*Main Two*/
.procedureCategoryDetailsMain_two {
    margin-top: 30px;
    min-height: 212px;
    display: flex;
    justify-content: space-between;
}

.procedureCategoryDetailsMain-two_anesthesia {
    width: 50%;
    height: 212px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.procedureCategoryDetailsMain-two-anesthesia_img {
    width: 100%;
    height: 212px;
}

.procedureCategoryDetailsMain-two-anesthesia_img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.procedureCategoryDetailsMain-two-anesthesia_text {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.procedureCategoryDetailsMain-two-anesthesia_text_ {
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.procedureCategoryDetailsMain-two-anesthesia-text_title {
    width: 100%;
    margin-bottom: 5px;
}

.procedureCategoryDetailsMain-two-anesthesia-text_title h2 {
    font-family: 'Inter';
    font-size: 50px;
    font-weight: 200;
    text-transform: capitalize;
    color: white;
}

.procedureCategoryDetailsMain-two-anesthesia-text_description {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.procedureCategoryDetailsMain-two-anesthesia-text_description p {
    width: 90%;
    font-family: 'Inter';
    font-size: 20px;
    font-weight: 200;
    color: white;
}

.procedureCategoryDetailsMain-two_effects {
    width: 44%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.procedureCategoryDetailsMain-two-effects_title h2 {
    font-family: 'Inter';
    font-size: 40px;
    font-weight: 200;
    text-transform: capitalize;
    text-align: end;
    line-height: 40px;
}

.procedureCategoryDetailsMain-two-effects_title h2 span {
    font-size: 50px;
    display: block;
}

.procedureCategoryDetailsMain-two-effects_description {
    margin-top: 30px;
}

.procedureCategoryDetailsMain-two-effects_description p {
    font-family: 'Inter';
    font-size: 20px;
    font-weight: 200;
}

.procedureCategoryDetailsMain-two-effects_description p::first-letter {
    text-transform: uppercase;
}

/*Main three*/
.procedureCategoryDetailsMain_three {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
}

.procedureCategoryDetailsMain-three_results {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.procedureCategoryDetailsMain-three-results_text {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-bottom: 40px;
}

.procedureCategoryDetailsMain-three-results-text_title {
    width: 100%;
    margin-bottom: 5px;
}

.procedureCategoryDetailsMain-three-results-text_title h2 {
    font-family: 'Inter';
    font-size: 40px;
    font-weight: 200;
    text-transform: capitalize;
}

.procedureCategoryDetailsMain-three-results-text_description {
    width: 90%;
}

.procedureCategoryDetailsMain-three-results-text_description p {
    font-family: 'Inter';
    font-size: 20px;
    font-weight: 200;
}

.procedureCategoryDetailsMain-three-results-text_description p::first-letter {
    text-transform: uppercase;
}

.procedureCategoryDetailsMain-three-results_imgs {
    width: 100%;
    height: 212px;
    display: flex;
    justify-content: space-between;
}

.procedureCategoryDetailsMain-three-results_img {
    width: 31%;
    height: 100%;
}

.procedureCategoryDetailsMain-three-results_img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.procedureCategoryDetailsMain-three_recuperation {
    width: 44%;
}

.procedureCategoryDetailsMain-three-recuperation_title h2 {
    font-family: 'Inter';
    font-size: 40px;
    font-weight: 200;
    text-transform: capitalize;
}

.procedureCategoryDetailsMain-three-recuperation_description {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
}

.procedureCategoryDetailsMain-three-recuperation_description ul {
    width: 90%;
}

.procedureCategoryDetailsMain-three-recuperation_description ul li {
    font-family: 'Inter';
    font-size: 20px;
    font-weight: 200;
    margin: 10px 0;
}

.procedureCategoryDetailsMain-three-recuperation_description ul li::first-letter {
    text-transform: uppercase;
}

.titleClinics div h2 {
    color: white;
    font-family: 'Oswald';
    font-size: 70px;
    line-height: 100px;
   
    
}
.background-contained-clinics .div-clinics {
    text-align: end;
}

.contained-Clinics .background-contained-clinics .paragraph p {
    font-family: 'Inter';
    color: white;
    font-size: 20px;
    font-weight: 200;
    margin: 10px 0;
}
.background-contained-clinics .paragraph{
    width: 80%;
    margin: 5rem auto;
}

.subTittleClinics label {
    color: white;
    font-family: 'Inter';
    padding: 15px;
    font-size: 35px;
    
}

.tittleClinics .subTitleClinics {
    font-size: 30px;
}

.procedureCatergoryDetailsHeader_text {
    color: #004274;
    font-family: 'Inter';
    font-size: 30px;
}



.contained-Clinics {
    background-image: url("https://pbs.twimg.com/media/ELmHWGsX0AAyRbZ.jpg");
    min-height: 400px;
    margin-bottom: 4rem;
    border-radius: 5px;
}


.background-contained-clinics{
    background-color: rgba(0, 0, 0, 0.686);
    min-height: 400px;
    width: 100%;
    border-radius: 5px;
}

@media screen and (max-width: 1600px) {
    .procedureCategoryDetailsMainn {
        width: 80%;
    }
}

@media screen and (max-width: 1250px) {
    .procedureCategoryDetailsMainn {
        width: 90%;
    }
}

@media screen and (max-width: 1100px) {
    .procedureCategoryDetailsMainn {
        width: 100%;
    }
    .background-contained-clinics{
        border-radius: 0;
    }
    .contained-Clinics{
        border-radius: 0;
    }
}

@media screen and (max-width: 700px) {

    /*Main One*/
    .procedureCategoryDetailsMainn {
        margin-top: 30px;
    }
    .titleClinics div h2 {
        font-size: 60px;
    }
    .content-Info-Travel h1 {
        font-size: 30px;
    }

    

    .procedureCategoryDetailsMain-one_text {
        display: block;
        width: 90%;
        height: 50%;
    }

    .procedureCategoryDetailsMain-one_img {
        display: none;
    }

    /*Main Two*/
    .procedureCategoryDetailsMain_two {
        min-height: 410px;
        flex-direction: column;
        align-items: center;
    }

    .procedureCategoryDetailsMain-two_anesthesia {
        width: 100%;
        margin-bottom: 20px;
    }

    .procedureCategoryDetailsMain-two_effects {
        width: 90%;
    }

    /*Main three*/
    .procedureCategoryDetailsMain_three {
        flex-direction: column;
        align-items: center;
    }

    .procedureCategoryDetailsMain-three_results {
        width: 100%;
        margin-bottom: 30px;
    }

    .procedureCategoryDetailsMain-three_recuperation {
        width: 90%;
    }
}

@media screen and (max-width: 500px) {
    

    .procedureCategoryDetailsMain-two-effects_title h2 {
        font-size: 35px;
    }
}

.container-form-all {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    padding: 0;
    background-color: #004274;
}

.form-Allies-img{
    width: 50%;
}
.form-Allies-img img{
    
    height: 100%;
    width: 100%;
}
.formAllies-form-container{
    width: 50%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
}

.form-allies {
    width: 340px;
    min-height: 520px;
    background: #e6e6e6;
    border-radius: 8px;
    box-shadow: 0 0 40px -10px #000;
    padding: 20px 30px;
    max-width: 500px;
    box-sizing: border-box;
    font-family: 'Montserrat', sans-serif;
    position: relative
}

.form-allies h2 {
    margin: 10px 0;
    padding-bottom: 10px;
    color: #78788c;
    border-bottom: 3px solid #78788c
}

.contain-input-form input {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    background: none;
    outline: none;
    resize: none;
    border: 0;
    font-family: 'Montserrat', sans-serif;
    transition: all .3s;
    border-bottom: 2px solid #bebed2
}

.form-allies input:focus {
    border-bottom: 2px solid #78788c
}

.form-allies p:before {
    content: attr(type);
    display: block;
    margin: 28px 0 0;
    font-size: 14px;
    color: #5a5a5a
}

.form-allies button {
    float: right;
    padding: 8px 12px;
    margin-top: 8px;
    font-family: 'Montserrat', sans-serif;
    border: 2px solid #78788c;
    background: 0;
    color: #5a5a6e;
    cursor: pointer;
    transition: all .3s
}

.form-allies button:hover {
    background: #78788c;
    color: #fff
}
.contain-input-form {
    height: 287px;
    overflow: scroll;
}

.content-select-form{
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

.container-terminos{
    display: flex;
    gap: 5px;
    flex-wrap: wrap;
    
    margin: 1rem 0;
}
.container-terminos a {
    color: #78788c;
}
.content-select-form select {
        margin-top: 5px;
        border: none;
        padding: 5px;
        border-radius: 5px;
    
}
.container-scrolling{
    margin: 10px 0;
    display: flex;
    justify-content: space-between;
    
}
.container-scrolling img {
    width: 30px;
}

.footer-form  {
    content: 'Hi';
    position: absolute;
    bottom: -21px;
    right: 8px;
    background: #50505a;
    color: #fff;
    
    width: 320px;
    padding: 16px 4px 16px 0;
    border-radius: 6px;
    font-size: 13px;
    box-shadow: 10px 10px 40px -14px #000
}

.form-allies span {
    margin: 0 5px 0 15px
}



.titleClinics,
.subTitleClinics {
    margin: 20px;
}

.mapWellezy {
    position: relative;
}


@media screen and (max-width:800px){
    .container-form-all{
        display: flex;
        padding: 2rem 0;
        background-image: url(./Img/travelForm.jpeg);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
    }
    .formAllies-form-container{
        width: 100%;
        margin: 0 auto;
    }
    .form-Allies-img{
        display: none;
    }
    .form-allies{
        width: 80%;
    }
    .content-Info-Travel{
        left:10%
    }
    .titleClinics div h2 {
        font-size: 50px;
    }
    .subTittleClinics label {
       
        font-size: 30px;
        
    }
    .contained-Clinics .background-contained-clinics .paragraph p{
        font-size: 15px;
    }
}

@media screen and (max-width:500px){
    .titleClinics div h2 {
        font-size: 45px;
    }
    .subTittleClinics label {
       
        font-size: 20px;
        
    }
    .contained-Clinics .background-contained-clinics .paragraph p{
        font-size: 13px;
    }
}

@media screen and (max-width:420px) {
    .content-Info-Travel{
        font-size: 15px;
    }
    .content-Info-Travel h1 {
        font-size: 20px;
    }
    .footer-form{
        display: none;
    }
    .form-allies{
        width: 90%;
    }
}