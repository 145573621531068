.colorPrimary{
    background-color: var(--bluePrimary);
    border-radius: var(--roundedPrimary);
    height: var(--spacingPrimary);
    box-shadow: var(--shawdowPrimary);
}

.colorSecundary{
    background-color: var(--blueSecundary);
    border-radius: var(--roundedPrimary);
    height: var(--spacingPrimary);
    box-shadow: var(--shawdowPrimary);
}

.yellowPrimary{
    background-color: var(--yellowPrimary);
    border-radius: var(--roundedPrimary);
    height: var(--spacingPrimary);
    box-shadow: var(--shawdowPrimary);
}

.colorPrimaryLight{
    background-color: var(--blueLight);
    border-radius: var(--roundedPrimary);
    height: var(--spacingPrimary);
    box-shadow: var(--shawdowPrimary);
}

.colorGreyPrimary{
    background-color: var(--greyPrimary);
    border-radius: var(--roundedPrimary);
    height: var(--spacingPrimary);
    box-shadow: var(--shawdowPrimary);
}

.colorGreySecundary{
    background-color: var(--greySecundary);
    border-radius: var(--roundedPrimary);
    height: var(--spacingPrimary);
    box-shadow: var(--shawdowPrimary);
}

.colorWhite{
    background-color: var(--white);
    border-radius: var(--roundedPrimary);
    height: var(--spacingPrimary);
    box-shadow: var(--shawdowPrimary);
}

.colorBlack{
    background-color: var(--black);
    border-radius: var(--roundedPrimary);
    height: var(--spacingPrimary);
    box-shadow: var(--shawdowPrimary);
}

.colorTransparentModal{
    background-color: var(--transparentModal);
    border-radius: var(--roundedPrimary);
    height: var(--spacingPrimary);
    box-shadow: var(--shawdowPrimary);
}

.colorGreenPrimary{
    background-color: var(--greenPrimary);
    border-radius: var(--roundedPrimary);
    height: var(--spacingPrimary);
    box-shadow: var(--shawdowPrimary);
}

.colorRedPrimary{
    background-color: var(--redPrimary);
    border-radius: var(--roundedPrimary);
    height: var(--spacingPrimary);
    box-shadow: var(--shawdowPrimary);
}
