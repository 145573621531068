.controlsContainer {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
}

.controlBtnMicro {
    background-color: var(--blueTertiary);
    color: var(--white);
    border-radius: 50%;
    display: grid;
    place-items: center;
    width: 50px;
    height: 50px;
    cursor: pointer;
    font-size: 25px;
    transition: all 0.3s ease;
}

.controlBtnMicro:hover {
    opacity: 0.9;
}

.controlBtnWebCam {
    background-color: var(--blueTertiary);
    color: var(--white);
    border-radius: 50%;
    display: grid;
    place-items: center;
    width: 50px;
    height: 50px;
    cursor: pointer;
    font-size: 20px;
    transition: all 0.3s ease;
}

.controlBtnWebCam:hover {
    opacity: 0.9;
}

.controlBtnExit {
    background-color: var(--redPrimary);
    color: var(--white);
    border-radius: 50%;
    display: grid;
    place-items: center;
    width: 50px;
    height: 50px;
    cursor: pointer;
    font-size: 20px;
    padding-right: 2px;
    padding-bottom: 2px;
    transition: all 0.3s ease;
}

.controlBtnExit:hover {
    opacity: 0.9;
}


.controlIconExit {
    rotate: 135deg;
}

.videoBorderRadius {
    border-radius: 10px;
}