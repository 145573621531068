.carouselContainerHotel {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.carouselWrapper {
  width: 100%;
  overflow: hidden;
}

.carouselContentHotel {
  display: flex;
  transition: transform 0.5s ease-in-out;
  padding-bottom: 15px;
}

.carouselButton {
  background-color: var(--bluePrimary);
  color: var(--white);
  border: none;
  width: 40px;
  height: 40px;
  cursor: pointer;
  font-size: 28px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carouselButton.disabled {
  opacity: 0.4;
  pointer-events: stroke;
}

.carouselButtonLeft {
  left: 0px; /* Ajusta el espacio a la izquierda */
}

.carouselButtonRight {
  right: 0px; /* Ajusta el espacio a la derecha */
}

.carouselButton:focus {
  outline: none;
}

.cardHotelDestac {
  flex: 0 0 calc(100% / 4 - 10px);
  max-width: calc(100% / 4 - 10px);
  margin: 0 5px;
  display: flex;
  flex-direction: column;
  border: 1px solid var(--greyPrimary);
  border-radius: 15px;
  box-shadow: 0px 4px 4px 0px var(--shadow);
}

@media (max-width: 1024px) {
  .cardHotelDestac {
    flex: 0 0 calc(100% / 3 - 10px);
    max-width: calc(100% / 3 - 10px);
  }
}

@media (max-width: 767px) {
  .cardHotelDestac {
    flex: 0 0 calc(100% / 2 - 10px);
    max-width: calc(100% / 2 - 10px);
  }
}

@media (max-width: 600px) {
  .cardHotelDestac {
    flex: 0 0 calc(100% - 5px);
    max-width: calc(100% - 5px);
    margin: 0 2.5px;
  }
}

.cardHotelDestacImg {
  width: 100%;
  height: 300px;
  max-height: 300px;
  min-height: 300px;
}

.cardContentDestac {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
  border-radius: 10px;
  padding: 15px;
}

.cardContentDestacDescription {
  display: flex;
  gap: 3px;
  align-items: center;
}

.cardContentDestac h3 {
  color: var(--bluePrimary);
  font-size: 18px;
}

.cardContentDestac p {
  font-size: 12px;
}

.cardHotelDestacImg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 15px 15px 0 0;
}

.cardContentInfo {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: 10px;
}

.underlineEffect {
  position: relative;
  display: inline-block;
  text-wrap: nowrap;
  font-size: 14px;
  color: var(--bluePrimary);
  cursor: pointer;
}

.cardContentDestacDescriptionStars{
  color: var(--bluePrimary);
}

.underlineEffect::after {
  content: "";
  position: absolute;
  width: 0;
  height: 1px;
  background-color: var(--bluePrimary);
  bottom: 0;
  left: 0;
  transition: width 0.5s ease-in-out;
}

.underlineEffect:hover::after {
  width: 100%;
}
