.grid_container_doc {
    display: grid;
    grid-gap: 15px;
    grid-template-columns: repeat(1, 1fr);
  }
  
  @media (max-width: 870px) {
    .grid_container_doc {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  @media (max-width: 570px) {
    .grid_container_doc {
      grid-template-columns: repeat(1, 1fr);
    }
  }