.cardRecoveryTour {
    width: 200px;
    font-family: 'Inter';
    text-align: center;
    border-radius: 20px !important;
    box-shadow: none !important;
    background-color: #00427400 !important;
}
.button_cardRecoveryTour {
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-color: rgba(240, 248, 255, 0);
    border: none;
    cursor: pointer;
}
.div_mediaRecoveryTour img {
    width: 200px;
    height: 200px;
    border-radius: 20px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
}
.actionHeart_recoveryTour {
    position: absolute;
    top: 0;
    right: 0;
}
.cardContent_recoveryTour {
    color: white;
    padding: 10px !important;
}
.title {
    font-family: 'Inter' !important;
    font-size: 18px !important;
    font-weight: 400 !important;
    margin-bottom: 0 !important;
}
.description {
    font-family: 'Inter' !important;
    font-size: 14px !important;
    font-weight: 200 !important;
}
.cardAction_start {
    padding: 2px !important;
    width: 100% !important;
    justify-content: center !important;
}

@media screen and (max-width: 1000px) {
    .cardRecoveryTour {
        width: 190px;
    }
    .div_mediaRecoveryTour img {
        width: 190px;
        height: 190px;
    }
}