@import url('https://fonts.googleapis.com/css2?family=Phudu:wght@700&display=swap');

.contain-banner-about-us {
    position: relative;
    height: 90vh;
    width: 100vw;
}

.contain-banner-about-us img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.content-info-cards-main{
    width: 90%;
    margin: 4rem auto;
    display: flex;
    justify-content: space-between;
}

.contain-banner-about-us div {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    display: flex;
    justify-content: end;
    align-items: center;
    padding: 1rem;
    background: rgb(0, 0, 0);
    background: linear-gradient(90deg, rgba(0, 0, 0, 1) 0%, rgba(255, 255, 255, 0.10686281348476889) 29%, rgba(0, 0, 0, 1) 91%);
}

.contain-banner-about-us section {
    width: 400px;
    margin-right: 6rem;
}

.contain-banner-about-us section h1 {
    color: white;
    font-size: 55px;
    margin-bottom: 1rem;
    font-family: 'Phudu', cursive !important;
}

.contain-banner-about-us section p {
    text-align: justify;
    color: white;
}

.about-us-pharrase-final{
    width: 90%;
    margin: 2rem auto;
    color: rgb(81, 81, 81);
}

.about-us-pharrase-final p{
    text-align: justify;
}

@media screen and (max-width:1150px){
    .content-info-cards-main{
        width: 95%;
        margin: 2rem auto;
        display: flex;
        flex-direction: column;
    }
}

@media screen and (max-width:600px) {
    .contain-banner-about-us div{
        align-items: center;
    }
    .contain-banner-about-us section {
        margin: 0 auto;
    }

    .contain-banner-about-us section h1 {
        font-size: 40px;
    }

    .contain-banner-about-us section p {
        font-size: 13px;
    }
    .about-us-pharrase-final{
        width: 90%;
    }
    .about-us-pharrase-final p {
        font-size: 13px;
    }
}