.codeContainer {
    position: relative;
    background-color: #2d2d2d;
    border-radius: var(--roundedPrimary);
    padding: 20px;
    margin-top: 20px;
    box-shadow: var(--shawdowPrimary);
  }
  
  .copyButton {
    position: absolute;
    right: 20px;
    top: 20px;
    background-color: var(--bluePrimary);
    color: white;
    border: none;
    padding: 8px 12px;
    border-radius: var(--roundedPrimary);
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
    display: flex;
    align-items: center;
    gap: 8px;
  }
  
  .copyButton:hover {
    background-color: var(--blueSecundary);
  }
  
  .copyButton:active {
    background-color: var(--blueLight);
    transform: scale(0.95);
  }