.tooltipContainer {
  display: flex;
  position: relative;
  cursor: pointer;
}

.tooltip {
  position: absolute;
  background-color: var(--blueTertiaryTransparent);
  color: var(--white);
  padding: 8px 15px;
  border-radius: 5px;
  white-space: nowrap;
  z-index: 99999;
  font-family: 14px;
  font-weight: 500;
  transition: opacity 0.3s ease, transform 0.3s ease;
  opacity: 0;
  pointer-events: none; /* Prevents interaction when not visible */
}

.tooltip::before {
  content: "";
  position: absolute;
  border-style: solid;
  transition: opacity 0.3s ease, transform 0.3s ease;
}

/* Top position */
.tooltip.top {
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%) translateY(-10px);
}

.tooltip.top::before {
  border-width: 6px 6px 0 6px;
  border-color: var(--blueTertiaryTransparent) transparent transparent transparent;
  bottom: -6px;
  left: 50%;
  transform: translateX(-50%);
}

/* Bottom position */
.tooltip.bottom {
  top: 100%;
  left: 50%;
  transform: translateX(-50%) translateY(10px);
}

.tooltip.bottom::before {
  border-width: 0 6px 6px 6px;
  border-color: transparent transparent var(--blueTertiaryTransparent) transparent;
  top: -6px;
  left: 50%;
  transform: translateX(-50%);
}

/* Left position */
.tooltip.left {
  right: 100%;
  top: 50%;
  transform: translateY(-50%) translateX(-10px);
}

.tooltip.left::before {
  border-width: 6px 0 6px 6px;
  border-color: transparent transparent transparent var(--blueTertiaryTransparent);
  right: -6px;
  top: 50%;
  transform: translateY(-50%);
}

/* Right position */
.tooltip.right {
  left: 100%;
  top: 50%;
  transform: translateY(-50%) translateX(10px);
}

.tooltip.right::before {
  border-width: 6px 6px 6px 0;
  border-color: transparent var(--blueTertiaryTransparent) transparent transparent;
  left: -6px;
  top: 50%;
  transform: translateY(-50%);
}

.tooltipVisible {
  opacity: 1;
  pointer-events: auto;
  transform: translate(0);
}
