.cartItemsItem {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.cartItemsItem h3 {
  font-size: 18px;
  font-weight: 600;
  color: var(--bluePrimary);
  padding-bottom: 8px;
  border-bottom: 2px solid var(--bluePrimary);
}

.cartItemsItemOne {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.itemAppointment {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 13px;
  border: 1px solid var(--blueWhiteTableThead);
  border-radius: 10px;
  padding: 15px;
}

.itemHeader {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.itemHeaderClinic {
  margin-top: 20px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.itemHeaderIconText {
  display: flex;
  align-items: center;
  gap: 10px;
}

.icon {
  font-size: 18px;
  background-color: var(--bluePrimary);
  color: var(--white);
  min-width: 30px;
  min-height: 30px;
  border-radius: 6px;
  display: grid;
  place-items: center;
}

.itemHeaderText {
  display: flex;
  flex-direction: column;
  gap: 2px;
  color: var(--bluePrimary);
  font-size: 14px;
  font-weight: 500;
}

.itemHeaderText h4 {
  font-size: 18px;
}

.itemDetails {
  display: flex;
  justify-content: space-between;
  gap: 50px;
  align-items: flex-end;
}

.itemDetails>p {
  font-size: var(--sizeTextPrimary);
}

.detailsButton {
  font-size: 14px;
  color: var(--bluePrimary);
  text-decoration: underline;
  cursor: pointer;
}

.itemLinks {
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  gap: 10px;
  align-items: start;
}

.itemLinks p {
  width: 100%;
  color: var(--blackGray);
  font-weight: 400;
  display: flex;
  flex-direction: column;
  gap: 3px;
}

.itemLinks p span {
  color: var(--bluePrimary);
  font-weight: 600;
}

.totalPrice {
  width: 100%;
  background-color: var(--bluePrimary);
  padding: 10px;
  color: var(--white);
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  border-radius: 8px;
}

.totalPriceText {
  display: flex;
  flex-direction: column;
  gap: 3px;
  font-size: 14px;
}

.totalPriceText span {
  font-weight: 700;
  font-size: 18px;
}

.containerAddProcedure {
  display: flex;
  gap: 10px;
}

.btnAddProcedure {
  background-color: var(--bluePrimary);
  color: var(--white);
  padding: 5px 10px;
  border-radius: 50px;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.containerInputsAddProcedure {
  display: flex;
  gap: 10px;
  margin-block: 10px;
  align-items: end;
}

.containerInputsAddProcedure input {
  border: 1px solid var(--greyPrimary);
  padding: 7px 10px;
  border-radius: var(--roundedPrimary);
}

.containerInputsAddProcedure button {
  background-color: var(--bluePrimary);
  color: var(--white);
  padding: 3px 10px;
  border-radius: var(--roundedPrimary);
  font-size: 14px;
  cursor: pointer;
  height: 32px;
}

.containerAllProcedures {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.containerAddedProcedures {
  display: flex;
  gap: 10px;
  align-items: center;
}

.containerAddedProcedures button {
  cursor: pointer;
}

.containerInputsAddProcedures {
  display: flex;
  flex-direction: column;
  align-items: start;
}

.buttonsActions {
  display: flex;
  justify-content: end;
  gap: 10px;
}

.linkSTD {
  width: fit-content;
  color: var(--white);
  background-color: var(--bluePrimary);
  padding: 10px 20px;
  border-radius: 8px;
  font-weight: 700;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 16px;
}

.linkSTD:hover {
  background-color: var(--blueTertiary);
}


.helpSTD {
  width: fit-content;
  color: var(--blackGray);
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 16px;
}

.helpSTD:hover {
  background-color: var(--blueWhiteTable);
}


.actionsButtons {
  display: flex;
  gap: 10px;
}

.actionsInputs {
  display: flex;
  gap: 10px;
  width: 100%;
}

@media screen and (max-width: 450px) {
  .buttonsActions {
    display: flex;
    flex-direction: column;
    align-items: end;
    justify-content: space-between;
    gap: 10px;
  }

  .containerAddProcedure {
    width: 100%;
  }

  .actionsButtons {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .actionsInputs {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
  }
}



@media screen and (max-width: 600px) {
  .containerInputsAddProcedure {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    margin-block: 10px;
  }

  .containerAddProcedure {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}