.input-document{
    height: 32px;
    border: 1px solid #D9D9D9;
    border-radius: 8px;
    display: flex;
    align-items: center;
    padding: 5px;
}

.input-document select{
    padding-right: 20px;
}

.input-document-label{
    font-family: "Montserrat" !important;
    font-size: 12px;
    font-weight: 400;
}

.input-document input, .input-document select{
    border: none;
    font-family: "Montserrat" !important;
    font-size: 12px;
    font-weight: 400;
    height: 100%;
}

.select-document{
    height: 100%;
    width: 28%;
    border-right: 0.5px solid #0072B9;
}

.input-document-container{
    height: 100%;
    width: 62%;
    border-left: 0.5px solid #0072B9;
    padding-left: 10px;
}

.select-document select{
    cursor: pointer;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: url('../../../../../ui/assets/iconsSVG/ArrowDown2.svg') no-repeat;
    background-position: right 10px center;
    width: 100%;
}

.input-document-validate{
    border: 1px solid red;
}

.span-document-error{
    color: red;
    font-size: 10px;
    font-family: "Montserrat" !important;
    font-weight: 400;
    margin-top: 5px;
}