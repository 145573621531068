.flightsDateGoing_container {
    width: 100%;
}
.flightsDateGoing {
    padding: 15px;
    border-radius: 20px;
}
.flightsDateGoing_ {
    display: flex;
    align-items: center;
}
.error-day-fligth{
    margin: 0.5rem auto;
    padding: .8rem;
    border-radius: 3px;
    width: 100%;
}

.rdrMonthAndYearPickers select{
    text-transform: capitalize;
}

.camp-enter-calendar{
    display: flex;
    align-items: center;
    width: 96%;
    margin: .4rem auto;
    justify-content: space-between;
    border: rgb(208, 208, 208) solid 1px;
    border-radius: 5px;
    padding: .5rem;
}

.camp-enter-calendar div{
    display: flex;
    flex-direction: column;
    gap: .4rem;
}

.camp-enter-calendar div label{
    font-size: 16px;
}

.camp-enter-calendar div p{
    font-size: 14px;
}



.error-day-fligth p{

    color: rgb(168, 34, 34);
}
.flightsDateGoing_date {
    width: 100%;
    height: 90px;
    background-color: white;
    border-radius: 20px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.35) !important;
    display: flex;
    padding: 5px;
    justify-content: center;
    margin: 10px 5px;
}
.flightsDateGoing_date_ {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.flightsDateGoing_date h2 {
    font-family: 'Inter';
    font-size: 16px;
    text-transform: capitalize;
    color: #004274;
    width: 55%;
    line-height: 28px;
}
.flightsDateGoing_date p {
    font-family: 'Inter';
    font-size: 16px;
}
.dateRange div {
    border-radius: 20px !important;
}
.rdrDateDisplayWrapper {
    display: none;
}
.flightDate_searcher {
    width: 300px;
    height: 90px;
    background-color: white;
    border-radius: 20px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.35) !important;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin: 0 20px;
    border: none;
}
.dateRangeOnly div {
    border-radius: 20px !important;
}

.rdrSelected {
    color: #004274 !important;
}
.rdrDay{
    color: #004274 !important;
}
.rdrEndEdge {
    background-color: #004274 !important;
}

.rdrStartEdge {
    background-color: #004274 !important;
}

.rdrDayToday .rdrDayNumber span:after {
    background-color: #004274 !important;
}

.rdrInRange {
    background-color: #4d618b8a!important;
}

.contain-btn-select-f{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 1rem;
}

.contain-btn-select-f button {
    border: none;
    padding: 1rem;
    font-size: 16px;
    background-color: #004274;
    color: white;
    font-weight: 600;
    cursor: pointer;
    border-radius: 8px;
    transition: .3s ease-in-out;
}

.contain-btn-select-f button:hover {
    background-color: #00599d;
}

@media screen and (max-width: 750px) {
    .flightsDateGoing {
        left: -55px;
    }
    .rdrMonths {
        flex-direction: column;
        align-items: center;
    }
    .rdrCalendarWrapper, .rdrDateRangeWrapper {
        width: 100%;
    }
    .rdrMonth {
        width: 80%;
    }
}
@media screen and (max-width: 600px) {
    .flightsDateGoing {
        top: -90px
    }
}
@media screen and (max-width: 500px) {
    .flightsDateGoing_date {
        margin: 10px 5px;
    }
    .flightDate_searcher {
        margin: 0 5px;
    }
}