.content-pay-success{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: .5rem;
}

.content-pay-success h3{
    color: green;
    font-weight: 300;
}

.content-pay-success h4{
    font-weight: 300;
    margin-top: 2rem;
}

.content-pay-success .thanks-received{
    font-weight: bold;
}

.content-pay-success .order-id{
    font-weight: 300;
}

.content-pay-success .information-invoices{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    width: 100%;
    border-radius: 10px;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.33);
-moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.33);
box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.33);
}

.content-pay-success .column-info-order{
    width: 45%;
    display: flex;
    flex-direction: column;
    gap: .5rem;
}

.content-pay-success .column-info-order p{
    font-size: 14px;
    text-align: center;
}

.content-pay-success .column-info-order p span{
    font-weight: bold;
}

@media screen and (max-width:500px){
    .content-pay-success .information-invoices{
       flex-direction: column;
       align-items: baseline;
    }
    
    .content-pay-success .column-info-order{
        width: 100%;
    }
    
    .content-pay-success .column-info-order p{
        font-size: 14px;
        text-align: start;
}

}