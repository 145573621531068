.containerFlight {
  width: 100%;
  max-width: 1280px;
  display: flex;
  gap: 20px;
  min-height: 100vh;
  margin: 0 auto 2rem auto;
  padding-top: 0px;
  position: relative;
  margin-bottom: 30px;
}

@media screen and (max-width: 1110px) {
  .containerFlight {
    gap: 10px;
  }
}

@media screen and (max-width: 770px) {
  .containerFlight {
    flex-direction: column;
  }
}

.containerFlight .errosSection {
  background-color: #ffffff;
  width: 60%;
  -webkit-box-shadow: 10px 10px 29px -13px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 29px -13px rgba(0, 0, 0, 0.75);
  box-shadow: 10px 10px 29px -13px rgba(0, 0, 0, 0.75);
  position: relative;
  margin: auto;
  top: 30%;
  transform: translateY(-70%);
  padding: 25px;
  border-radius: 20px;
  border: solid 2px #ffffff;
  display: flex;
  align-items: center;
}

.containerFlight .errosSectionIcon {
  width: 150px;
  height: 150px;
  align-items: center;
  justify-content: center;
  display: flex;
}

.containerFlight .errosSectionIconText {
  margin-top: 20px;
  text-align: center;
  color: #dedede;
  text-transform: uppercase;
  font-size: 24px;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
}

.columnCards {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 0px;
}

.containerFlight .column {
  display: flex;
  flex-direction: column;
  justify-content: start;
  width: 100%;
  gap: 30px;
}

.containerFlight .column h4 {
  font-size: 23px;
}
