.headerContainerWrap {
  width: 100%;
  height: 69px;
  position: relative;
  background-color: #104172;
  padding: 0 10px;
}

.headerContainer {
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  display: flex;
  height: 100%;
  justify-content: space-between;
  align-items: center;
}

.headerContainerLeft {
  display: flex;
  align-items: center;
}

.headerMenu {
  transition: all ease 200ms;
  cursor: pointer;
  display: none;
}

.headerLogo {
  height: 45px;
  overflow: hidden;
}

.headerLogoImg {
  height: 100%;
}

.headerContainerRight {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
}

.headerContainerRight .navbarTextContainer {
  transition: all ease 200ms;
  display: flex;
  flex-direction: row;
  margin-right: 10px;
}

.headerContainerRight .navBarOption {
  height: 30px;
  align-items: center;
  display: flex;
  padding: 0 10px;
  cursor: pointer;
}
.headerContainerRight .navBarOptionActive {
  border-bottom: solid #ffffff 1px;
}

.headerContainerRight .navBarOption .navBarOptionText {
  color: #ffffff;
  font-size: 15px;
  font-weight: 400;
  font-family: "Montserrat", sans-serif;
}


.navBarOption {
  position: relative;
  display: inline-block;
}

.navBarOption::after {
  content: "";
  position: absolute;
  width: 0;
  height: 1px; /* altura del underline */
  background-color: #fff; /* color del underline */
  bottom: 0;
  left: 0;
  transition: width 0.5s ease-in-out; /* tiempo de transición */
}

.navBarOption:hover::after {
  width: 100%;
}



.location {
  display: flex;
  align-items: center;
  padding-right: 15px;
  border-right: solid #ffffff 1px;
  margin-right: 10px;
}

.iconLocation {
  color: #ffffff;
}

.selectLocation {
  background-color: transparent;
  color: #ffffff;
  border: none;
  outline: none;
  font-size: 15px;
  font-weight: 400;
  font-family: "Montserrat", sans-serif;
}

@media screen and (max-width: 2100px) {
  .headerContainerRight .navBarOption {
    font-size: 18px;
  }
}

@media screen and (max-width: 1600px) {
  .headerContainerRight .navBarOption {
    font-size: 14px;
  }
}

@media screen and (max-width: 1100px) {
  .headerContainer {
    width: 99%;
  }

  .headerContainerRight .navBarOption {
    font-size: 12px;
  }
}

@media screen and (max-width: 880px) {
  .headerMenu {
    display: block;
  }
  .navbarTextContainer {
    background-color: red;
    display: none !important;
  }
}

@media screen and (max-width: 768px) {
  .headerContainerRight {
    display: none;
  }

  .headerContainerLeft {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    justify-content: space-between;
    width: 100%;
    padding: 0px;
  }
}
