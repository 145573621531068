.header {
  background-image: url("./Img/FondoWebNew.png");
  width: 100%;
  height: 500px;
  display: flex;
  background-position: center;
  background-size: cover;
  /* flex-direction: column; */
  justify-content: center;
  /* align-items: center; */
  position: relative;
}
.header_questions {
  text-align: center;
  padding-top: 200px;
  color: white;
  font-family: "Inter", sans-serif;
}
.questions_h1 {
  font-size: 60px;
}
.questions_p {
  font-size: 14px;
  font-weight: 600;
  margin-top: 5px;
  margin-bottom: 20px;
}

/*New header*/

.headerSearch_location {
  padding: 0;
  width: 20%;
  height: 100%;
  background-color: white;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  position: relative;
}
.headerSearch_location_ {
  width: 90%;
  height: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.headerSearch_locationDroped {
  position: absolute;
  bottom: -120px;
  background-color: white;
  width: 100%;
  height: 127px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
  border-radius: 0 0px 10px 10px;
}
.headerSearch_locationDropedButton {
  width: 100%;
  height: 35px;
  margin: 5px 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: none;
  border-bottom: 1px solid rgba(0, 66, 116, 0.2);
  cursor: pointer;
  background-color: white;
}

.headerSearch_text {
  font-family: "Inter";
  margin-left: 5px;
  font-weight: 300;
  font-size: 14px;
}
.procedureSearch_search {
  width: 55%;
  height: 100%;
  border-radius: 10px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.headerSearch_language {
  padding: 0;
  width: 20%;
  height: 100%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  position: relative;
}
.headerSearch_language_ {
  width: 90%;
  height: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.headerSearch_cart {
  cursor: pointer;
  background-color: var(--bluePrimary);
  font-family: inherit;
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 2px 20px;
  height: 36px;
  line-height: 2.5em;
  position: relative;
  overflow: hidden;
  border: 2px solid var(--bluePrimary);
  transition: color 0.5s;
  z-index: 1;
  font-size: 12px;
  border-radius: 9px;
  font-weight: 700;
  color: white;
}

.headerSearch_cart:before {
  content: "";
  position: absolute;
  z-index: -1;
  background: white;
  color: var(--bluePrimary);
  height: 550px;
  width: 1500px;
  border-radius: 50%;
}

.headerSearch_cart:hover {
  color: var(--bluePrimary);
}

.headerSearch_cart:before {
  top: 100%;
  left: 100%;
  transition: all 0.7s;
}

.headerSearch_cart:hover:before {
  top: -100px;
  left: -200px;
}

.headerSearch_cart:active:before {
  background: white;
  transition: background 0s;
}

/*Hover*/
.headerSearch_locationDropedButton:hover {
  background-color: rgba(0, 66, 116, 0.2);
  border-radius: 10px;
}

@media screen and (max-width: 720px) {
  .headerSearch {
    width: 90%;
    display: flex;
    flex-flow: wrap;
    justify-content: space-between;
  }
  .headerSearch_location,
  .headerSearch_language {
    width: 30%;
    margin: 5px 0;
  }
  .procedureSearch_search {
    width: 65%;
  }
}
@media screen and (max-width: 580px) {
  .headerSearch {
    flex-direction: row;
    justify-content: center;
  }
  .headerSearch_location,
  .headerSearch_language,
  .procedureSearch_search {
    width: 99%;
  }
  .headerSearch_location {
    order: 1;
  }
  .procedureSearch_search {
    order: 3;
  }
  .headerSearch_language {
    order: 2;
  }
}

/* @media screen and (max-width: 730px) {
 
  .header_questions {
    width: 95%;
    text-align: center;
    padding-top: 140px;
  }
  .questions_h1 {
    font-size: 50px;
  }
  .questions_p {
    font-size: 12px;
  }
  .input_questions {
    width: 100%;
    box-sizing: border-box;
    font-size: 14px;
    padding: 27px 0;
  }
} */

/* @media screen and (max-width: 780px) {
  .headerSearch {
      width: 80%;
      height: 50px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: absolute;
      bottom: 70px;
      align-items: center;
      justify-content: center;
      flex-direction: column;
  }

  .procedureSearch_search {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
    margin-bottom: 10px;
}



  .headerSearch_location {
      width: 60%;
      height: 100%;
      background-color: white;
      border-radius: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
      cursor: pointer;
      position: relative;
      margin-bottom: 10px;
      padding: 6px;
  }

  .headerSearch_language {
      width: 60%;
      height: 100%;
      background-color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 10px;
      box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
      position: relative;
  }


} */
