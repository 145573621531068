.browseHomeColumn {
    width: 100%; 
    max-width: 1280px;
}

.divBanner {
    width: 100%; 
    height: 600px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    position: relative;
    /* Añadido */
}

.bannerImg {
    width: 100%;    
    height: 600px; 
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    border-radius: 20px;
} 

.bannerImg {
    display: none;
}

@media (min-width: 850px) {
    .bannerImg-desktop {
        display: block;
    }
}

@media (max-width: 850px) {
    .bannerImg-mobile {
        display: block;
    }
}

@media (max-width: 900px) {
    .hide-on-small {
        display: none;
    }
}

.textOverlay {
    position: absolute;
    top: 31px;
    left: 100px;
    color: white;
    z-index: 1;
    color: #104172;  
}

@media (max-width: 490px) {
    .textOverlay {
        max-width: 86%;
    }
}

@media (max-width: 900px) {
    .textOverlay {
        left: 20px;
    }
}

.textOverlay h1 {
    margin: 0;
    padding: 0;
    font-size: 24px;
}

.textOverlayTitle {
    color: white;  
    display: flex;
    flex-direction: column; 
}

.textOverlayTitle h2 {
    font-size: 75px;
    font-weight: 800;
    margin: 0; 
}

.textOverlayTitle h3 {
    font-size: 27px;
    font-weight: 800;
    margin: 0; 
}

.titleOverlay1 {
    margin-bottom: -1.9rem !important;  
}

.titleOverlay2 {
    margin-bottom: -1.1rem !important;  
}


@media (max-width: 562px) {
    .textOverlayTitle { 
    }

    .textOverlayTitle h2 {
        font-size: 27px;
        margin-bottom: 1px !important;
    }

    .textOverlayTop h4 {
        font-size: 14px;
    }
}

.textOverlayTop {
    display: flex;
    flex-direction: column;
    gap: 7px;
} 
.contentMidOverlay{
    background-color: white;
    color: #104172 !important;
    margin-left: -100px;
    max-width: 432px;
    margin-top: .7rem;
    padding: 12px;
    padding-right: 20px !important;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    font-weight: 600;
    font-size: 21px;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}

.textOverlayTop h4 {
    font-size: 19px;
}

.contentMidText {
    margin-top: 1.8rem;
    color: black;
}

.textContent1 {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
}

.textContent1 div {
    display: flex;
    align-items: flex-start;
    gap: 5px;
}

.textContent1 p {
    font-size: 18px;
    font-weight: 450;
}

.textContent1 span {
    font-size: 18px;
    font-weight: 600;
}

.contentMidText2 {
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-top: 1.2rem;
    color: black;
}

.contentMidText2 p {
    font-size: 18px;
    font-weight: 450;
}

.divIccons {
    display: flex;
    align-items: center;
    width: 340px;
    margin-top: 2.5rem;  
    justify-content: center;
}

@media (max-width: 551px) {
    .divIccons {
        width: auto;
        margin-right: 4rem;
    }
}
.doctoDivHome{
    display: flex !important;
    flex-direction: column !important; 
} 

.iconDivHome{ 
    font-size: 16px !important;
    color: white !important;
    fill: white !important; 
}    
.divIccons p{
    color: white;
    font-weight: 600;
}
.doctoresDivHome{
    width: 63px !important;
}
.iconSumHome{
    font-size: 25px !important;
    color: white !important;
}

.mainIconsDiv {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.mainIconsDiv button {
    width: 330px;
    height: 45px;
    border-radius: 8px;
    color: white;
    background-color: #104172;
    padding: 8px;
    font-weight: 700;
    cursor: pointer;
    font-size: 19px;
    margin-bottom: 3.2rem;
}
.mainIconsDiv button a{
    color: white;
}

.mainIconsDiv p {
    font-size: 18px;
    font-weight: 450;
    color: white;
}