.pqrsOptionsTabs {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  margin-bottom: 40px;
  align-items: center;
}

.pqrsOptionsTabs .pqrsTab {
  cursor: pointer;
  margin-right: 30px;
  padding-bottom: 8px;
  font-weight: 300;
  position: relative;
}

.pqrsOptionsTabs .pqrsTabOn::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -2px;
  height: 3px;
  border-radius: 10px;
  width: 100%;
  background-color: var(--blueSecundary);
  animation: load 0.5s ease-in-out forwards;
}

.pqrsOptionsTabs .pqrsTabOff::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -2px;
  height: 3px;
  border-radius: 10px;
  width: 100%;
  background-color: var(--greyPrimary);
}

@keyframes load {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}
