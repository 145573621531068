.card-plans{
    padding-top: 3rem;
    padding-bottom: 7rem;
}

.loading{
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}