.container-edit-form-quote{
    margin-top: 1px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: white;
    border-radius: 0px 0px 23px 23px;
    color: black;
    padding-block: 10px;
    padding-inline: 20px;
    border: 1px solid #0032A6;
    box-shadow: 0px 4px 4px 0px #00000040;
}

.container-form-complet-edit-sura{
    margin-block: 20px;
    transition: 0.5s;
    display: flex !important;
    justify-content: space-around;
}

.container-patter-edit-quote{
    box-shadow: 0px 4px 4px 0px #00000040;
    border-radius: 23px;
    padding: 20px;
}

.btn-quote-again-sura{
    display: flex;
    justify-content: center;
    align-items: center;
}

.btn-quote-again-sura button {
    width: 20%;
    background-color: #0032A6;
    color: white;
    font-size: 13px;
    font-weight: 300;
    font-family: "Montserrat" !important;
    padding-inline: 20px;
    padding-block: 5px;
    border-radius: 8px;
    cursor: pointer;
    transition: 0.3s;
    border: 1px solid #0032A6;
    height: 32px;
    margin-block: 20px;
}

.btn-quote-again-sura button:hover{
    background-color: white;
    color: #0032A6;
    border: 1px solid #0032A6;
    transition: 0.3s;
}

.container-form-complet-edit-sura > div{
    width: 30%;
}

.container-form-complet-edit-sura input{
    width: 100%;
}

.container-edit-form-quote p{
    font-family: "Montserrat" !important;
    font-weight: 200;
    font-size: 16px;
}

.container-edit-form-quote span{
    font-family: "Montserrat" !important;
    font-weight: 700;
    font-size: 16px;
}

.container-edit-form-quote button{
    font-family: "Montserrat" !important;
    font-weight: 700;
    font-size: 16px;
    color: #0032A6;
    background-color: white;
    border: 1px solid #0032A6;
    cursor: pointer;
    padding-inline: 10px;
    padding-right: 25px;
    padding-block: 5px;
    border-radius: 8px;
    transition: 0.3s;
    background: url("./svg/IconArrowDown.svg") no-repeat;
    background-position: right 10px center;
}

.container-edit-form-quote button:hover{
    background-color: #0032A6;
    color: white;
    transition: 0.3s;
}

.container-btn-n-i-l-a > div{
    display: flex;
    align-items: center;
    width: 100%;
    margin-block: 10px ;
}

.container-btn-n-i-l-a button{
    background-color: #0032A6;
    color: white;
    width: 50%;
    padding-block: 5px;
    height: 32px;
    cursor: pointer;
    border: 1px solid #0032A6;
    transition: 0.3s;
}

.container-btn-n-i-l-a button:hover{
    background-color: white;
    border: 1px solid #0032A6;
    color: #0032A6;
    transition: 0.3s;
}

.btn-left-sura{
    border-radius: 8px 0px 0px 8px;
    margin-right: 0.5px;
}

.btn-right-sura{
    border-radius: 0px 8px 8px 0px;
    margin-left: 0.5px;
}

.detail-form-sura{
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.detail-form-sura p{
    margin-inline: 10px;
}

.btn-selected{
    background-color: white;
    color: #0032A6;
    border: 1px solid #0032A6;
    transition: 0.3s;
}

.btn-add-less-sura{
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-block: 10px;
    width: 100%;
}

@media screen and (max-width: 600px) {
    .detail-form-sura{
        display: none;
    }

    .container-edit-form-quote p{
        display: none;
    }

    .container-form-complet-edit-sura{
        flex-direction: column;
    }

    .container-form-complet-edit-sura > div{
        width: 100%;
    }

    .btn-quote-again-sura button {
        width: 50%;
    }
}