.containModal {
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  width: 100%;
  height: 100%;
  background-color: var(--transparentModal);
  opacity: 0;
  pointer-events: none;
  z-index: 99999;
  display: grid;
  place-items: center;
  transition: all 0.3s ease;
}

.containModal.active {
  opacity: 1;
  pointer-events: all;
}

.contentModal {
  width: 100%;
  max-width: 500px;
  border-radius: 10px;
  box-shadow: var(--shawdowPrimary);
  padding: 20px;
  background-color: var(--white);
  opacity: 0;
  pointer-events: none;
  position: relative;
  transform: translateY(-30px);
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.contentModal.active {
  opacity: 1;
  pointer-events: all;
  transform: translateY(0px);
}

.contentModalButtonClose {
  cursor: pointer;
  position: absolute;
  top: 8px;
  right: 8px;
  color: var(--bluePrimary);
  outline: none;
}

.iconSuccess {
  background-color: var(--blueWhiteTableThead);
  color: var(--greenPrimary);
  width: 70px;
  height: 70px;
  border-radius: 50%;
  display: grid;
  place-items: center;
}

.contentText {
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 10px;
  color: var(--blackGray);
}

.contentText h2 {
  font-size: 26px;
  font-weight: 700;
}

.contentText p {
  font-size: 16px;
  font-weight: 500;
}

.buttonLinks {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  width: 100%;
}

.linkCreatePackage {
  background-color: var(--bluePrimary);
  padding: 10px 20px;
  border-radius: 8px;
  color: var(--white);
  transition: all 0.3s ease;
  box-shadow: var(--shawdowPrimary);
}

.linkCreatePackage:hover {
    opacity: 0.9;
}

.linkFinish {
  background-color: var(--bluePrimary);
  padding: 10px 20px;
  border-radius: 8px;
  color: var(--white);
}
