.searchBuildPackage {
  width: 100%;
  background-color: #104172;
  padding: 50px 0px;
}

.searchBuildPackageContent {
  width: 95%;
  max-width: 1280px;
  margin: 0 auto;
  background-color: #fff;
  border-radius: 20px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.searchBuildPackageContentOptions {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

@media (max-width: 1050px) {
  .searchBuildPackageContentOption {
    flex: 1 1 48%;
  }
  .searchBuildPackageContentOptions {
    justify-content: start;
  }
}

@media (max-width: 600px) {
  .searchBuildPackageContentOption {
    flex: 1 1 100%;
  }
  .searchBuildPackageContentOptions {
    justify-content: start;
  }
}

.searchBuildPackageContentOption {
  display: flex;
  gap: 10px;
  align-items: center;
  font-size: 12px;
  padding: 6px 13px 6px 7px;
  color: #636363;
  cursor: pointer;
  font-weight: 500;
  text-align: start;
}

.searchBuildPackageContentOption.active {
  display: flex;
  gap: 10px;
  align-items: center;
  font-size: 12px;
  padding: 6px 13px 6px 7px;
  color: #104172;
  font-weight: 500;
  cursor: pointer;
  text-align: start;
}

.searchBuildPackageContentInputsButton {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

@media (max-width: 1050px) {
  .searchBuildPackageContentInputsButton {
    flex-wrap: wrap;
  }
}

.searchBuildPackageContentInputs {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

@media (max-width: 850px) {
  .searchBuildPackageContentInputs {
    flex-wrap: wrap;
  }
}

.searchBuildPackageContentInput {
  width: 100%;
  border: 1px solid var(--greyPrimary);
  padding: 7px 10px;
  display: flex;
  justify-content: space-between;
  gap: 10px;
  align-items: center;
  border-radius: 10px;
  transition: all 0.3s ease;
  cursor: pointer;
  position: relative;
}


/* .searchBuildPackageContentInput:hover {
  border: 1px solid #104172c4;
} */

.searchBuildPackageContentInputContent {
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 3px;
}

.searchBuildPackageContentInputContent p {
  font-size: 14px;
  white-space: nowrap;
}

.searchBuildPackageContentInputContent input {
  width: 100%;
  background-color: transparent;
  border: 0;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  color: var(--bluePrimary);
}

.container-btns-continue-buy{
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.container-btns-continue-buy button{
  cursor: pointer;
  background-color: var(--bluePrimary);
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 8px 15px;
  position: relative;
  overflow: hidden;
  border: 2px solid var(--bluePrimary);
  transition: color 0.5s;
  z-index: 1;
  font-size: var(--sizeTextPrimary);
  border-radius: 9px;
  font-weight: 600;
  color: white;
  width: 100%;
  justify-content: center;
}

.container-btns-continue-buy button::before{
  content: "";
  position: absolute;
  z-index: -1;
  background: white;
  color: var(--bluePrimary);
  height: 550px;
  width: 1500px;
  border-radius: 50%;
}

.container-btns-continue-buy button:hover{
  color: var(--bluePrimary);
}

.container-btns-continue-buy button:before{
  top: 100%;
  left: 100%;
  transition: all 0.7s;
}

.container-btns-continue-buy button:hover:before{
  top: -100px;
  left: -200px;
}

.container-btns-continue-buy button:active:before{
  background: var(--white);
  transition: background 0s;
}

.searchBuildPackageContentInputContent input::placeholder {
  color: var(--bluePrimary);
  font-size: 14px;
  font-weight: 500;
}

.input-with-placeholder-special::placeholder {
  font-weight: 400;
}

.inputColorBlue::placeholder {
  font-weight: 500 !important;
  color: var(--bluePrimary) !important;
}

.inputColorBlueGray::placeholder {
  font-weight: 400 !important;
  color: var(--greySecundary) !important;
}


.searchBuildPackageContentButtonSearch {
  cursor: pointer;
  background-color: var(--bluePrimary);
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 8px 15px;
  position: relative;
  overflow: hidden;
  border: 2px solid var(--bluePrimary);
  transition: color 0.5s;
  z-index: 1;
  font-size: 18px;
  border-radius: 9px;
  font-weight: 600;
  color: white;
}

.searchBuildPackageContentSections{
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

@media (max-width: 1050px) {
  .searchBuildPackageContentButtonSearch {
    width: 100%;
    justify-content: center;
  }
}

.searchBuildPackageContentButtonSearch:before {
  content: "";
  position: absolute;
  z-index: -1;
  background: white;
  color: var(--bluePrimary);
  height: 550px;
  width: 1500px;
  border-radius: 50%;
}

.searchBuildPackageContentButtonSearch:hover {
  color: var(--bluePrimary);
}

.searchBuildPackageContentButtonSearch:before {
  top: 100%;
  left: 100%;
  transition: all 0.7s;
}

.searchBuildPackageContentButtonSearch:hover:before {
  top: -100px;
  left: -200px;
}

.searchBuildPackageContentButtonSearch:active:before {
  background: var(--white);
  transition: background 0s;
}

.contentElemetsSearchInputBuildPackage {
  display: flex;
  flex-direction: column;
  max-height: 200px; 
  overflow-y: auto;   
  list-style: none;
  font-size: var(--sizeTextPrimary);
  text-transform: uppercase;
  width: 100%; 
}

.contentElemetsSearchInputBuildPackage li {
  padding: 0px 10px;
  width: 100%;
  padding-block: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  text-transform: capitalize;
  border-radius: var(--roundedPrimary);
}

.contentElemetsSearchInputBuildPackage li span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  max-width: 100%;
}

.contentElemetsSearchInputBuildPackage li:hover {
  background-color: var(--greyPrimary);
  border-radius: var(--roundedPrimary);
}

.list-not-results{
  cursor: default !important;
}

.list-not-results:hover{
  background-color: transparent !important;
}

.content-inputs-destiny-origin-build-package{
  display: flex;
  width: 100%;
  gap: 3px;
  padding: 7px 10px;
  border: 1px solid var(--greyPrimary);
  border-radius: var(--roundedPrimary);
  transition: all 0.3s ease;
}

/* .content-inputs-destiny-origin-build-package:hover{
  border: 1px solid #104172c4;
} */

.font-style-p-search-build-package{
  font-size: var(--sizeTextPrimary);
}

.campo-translate-search-build-package{
  border: 1px solid var(--greyPrimary);
  border-radius: var(--roundedPrimary);
  padding: 7px 10px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.campo-translate-search-build-package:hover{
  border: 1px solid #104172c4;
}

.content-hour-build-package{
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border: 1px solid var(--greyPrimary);
  top: 57px;
  padding: 20px;
}

.content-hour-build-package > div{
  width: 100%;
  text-align: center;
  overflow: none;
}