.flightPassengerManagement {
    display: block;
    width: 100%;
    padding: 20px;
}

.flightPassengerManagementTitle {
    font-size: 20px;
    color: #004274;
    font-weight: bolder;
    font-family: 'Montserrat', sans-serif;
    text-transform: uppercase;
    margin-bottom: 10px;
}

.flightPassengerManagementError {
    font-size: 12px;
    font-weight: 400;
    color: #ff8585;
    font-family: 'Inter', sans-serif;
    margin-bottom: 15px;
}

.flightPassengerManagementGroup {
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-bottom: 10px;
}

.flightPassengerManagementGroupLeft {
    display: block;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.flightPassengerManagementGroupName {
    font-size: 14px;
    font-weight: 600;
    color: #004274;
    font-family: 'Inter', sans-serif;
    display: flex;
}

.flightPassengerManagementGroupDescriptionPassenger {
    display: flex;
    font-size: 12px;
    font-weight: 600;
    color: #004274;
    font-family: 'Inter', sans-serif;
    opacity: 0.5;
}

.flightPassengerManagementGroupRigth {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    border: 1px solid #00427485;
    border-radius: 5px;
    overflow: hidden;
}

.flightPassengerManagementGroupRigthBtn {
    transition: all ease 200ms;
    background-color: #004274;
    cursor: pointer;
    height: 32px;
    width: 35px;
    align-items: center;
    display: flex;
    justify-content: center;
}

.flightPassengerManagementGroupRigthBtn:hover {
    background-color: #013157;
}

.flightPassengerManagementGroupRigthBtnText {
    color: #FFFFFF;
    font-size: 20px;
    font-weight: 600;
    font-family: 'Montserrat', sans-serif;
}

.flightPassengerManagementGroupRigthValue {
    color: #000000;
    font-size: 18px;
    font-weight: 600;
    font-family: 'Montserrat', sans-serif;
    text-transform: uppercase;
}

.flightPassengerManagementBtn {
    transition: all ease 200ms;
    background-color: #004274;
    border-radius: 20px;
    padding: 15px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25) !important;
    cursor: pointer;
}

.flightPassengerManagementBtn:hover {
    scale: 1.01;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25) !important;
}

.flightPassengerManagementBtnText {
    text-align: center;
    color: #FFFFFF;
    font-size: 18px;
    font-weight: 600;
    font-family: 'Montserrat', sans-serif;
    text-transform: uppercase;
}