.header-travel {
  height: 60vh;
  overflow: hidden;
  position: relative;
}

/* ==============styles del carousel header ====================*/

.slides {
  width: 500%;
  height: 60vh;
  display: flex;
}
.slides-letters {
  display: flex;
  width: 500%;
}

.slides input {
  display: none;
}

.slide {
  width: 20%;
  transition: 2s;
}
.slide-le {
  width: 20%;
  transition: 2s;
}
.slide-le img {
  width: 100%;
}

.slide img {
  width: 100%;
  height: 60vh;
  object-fit: cover;
  object-position: center;
}

/*css for manual slide navigation*/

.navigation-manual {
  position: absolute;
  display: flex;
  top: 50%;
  align-items: center;
  flex-direction: column;
  right: 10px;
  justify-content: center;
  gap: 5px;
  z-index: 9;
}

.manual-btn {
  border: 2px solid #40d3dc;
  padding: 5px;
  border-radius: 10px;
  cursor: pointer;
  transition: 1s;
}

.manual-btn:hover {
  background: #40d3dc;
}

#radio1:checked ~ .first {
  margin-left: 0;
}

#radio2:checked ~ .first {
  margin-left: -20%;
}

/*css for automatic navigation*/

.navigation-auto {
  position: absolute;
  display: flex;
  width: 800px;
  justify-content: center;
  margin-top: 460px;
}

.navigation-auto div {
  border: 2px solid #40d3dc;
  padding: 5px;
  border-radius: 10px;
  transition: 1s;
}

.navigation-auto div:not(:last-child) {
  margin-right: 40px;
}

#radio1:checked ~ .navigation-auto .auto-btn1 {
  background: #40d3dc;
}

#radio2:checked ~ .navigation-auto .auto-btn2 {
  background: #40d3dc;
}

.efect-black {
  position: absolute;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  height: 60vh;
  top: 0;
  background-color: rgba(0, 0, 0, 0.58);
}

.contain-items-header {
  width: 40%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  color: #ffff;
}

.for-person {
  margin-top: 6px;
}

.for-person p {
  font-size: 20px;
}

.contain-items-header li {
  color: #ffff;
  font-size: 20px;
  font-weight: 600;
}

.image-letters {
  width: 400px;
}

.layout-titles {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin: 0;
}

.layout-titles h3 {
  font-size: 35px;
  text-align: center;
  color: #ffff;
  text-transform: uppercase;
}

.layout-titles span {
  height: 7px;
  background-color: #ffff;
  border-radius: 20px;
  width: 50%;
}

.layout-sub-titles {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin: 1rem 0;
}

.layout-sub-titles span {
  height: 7px;
  background-color: #ffff;
  border-radius: 20px;
  width: 50%;
}

.layout-sub-titles h3 {
  font-size: 30px;
  color: #ffff;
  text-transform: uppercase;
}

.price-packages {
  font-size: 60px;
  color: #ffff;
  text-transform: uppercase;
  font-weight: 600;
  margin: 1rem auto auto auto;
}

.form-find-t {
  position: relative;
  min-height: 60px;
}

.services-travel {
  width: 100%;
  max-width: 1280px;
}

.services-travel img {
  width: 120px;
}

.services-travel p {
  text-align: center;
  border-bottom: none;
  font-size: 10px;
  color: #000000;
  font-weight: normal !important;
}

.video-youtube {
  width: 80%;
  margin: 8rem auto 8rem auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffff;
  padding: 1rem;
  border-radius: 10px;
  -webkit-box-shadow: 16px 17px 32px -11px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 16px 17px 32px -11px rgba(0, 0, 0, 0.3);
  box-shadow: 16px 17px 32px -11px rgba(0, 0, 0, 0.3);
}

.contain-video {
  width: 80%;
  height: 520px;
}

.conoce-travel {
  width: 80%;
  margin: 8rem auto 8rem auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
.conoce-travel h3 {
  color: #004274;
  font-weight: 600;
  font-size: 25px;
  text-align: center;
  height: 80px;
}

.section-conoce-travel,
.section-conoce-travel-b {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 45%;
}

.section-conoce-travel div,
.section-conoce-travel-b div {
  position: relative;
}

.layout-conoce {
  width: 100%;
}
.slider_container {
  display: flex;
  align-items: center;
  width: 100%;
}
.slider_ {
  display: flex;
  justify-content: center;
  align-items: baseline;
  height: 100%;
}

.grid_cardHomeTurism {
  margin: 10px 10px !important;
}

.section-conoce-travel p,
.section-conoce-travel-b-p {
  position: absolute;
  bottom: 10px;
  display: inline-block;
  left: 20px;
  color: #ffff;
  font-weight: 600;
}
.section-conoce-travel img {
  width: 100%;
  height: 350px;
  filter: brightness(70%);

  object-fit: cover;
}

.control-carousel {
  height: 20px;
  background-color: #004274;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  color: white;
}

.control-carousel-p {
  user-select: none;
}

.section-conoce-travel-b img {
  width: 250px;
  height: 350px;
  object-fit: cover;
  object-position: right;
  filter: brightness(70%);
}

.lugares-top,
.hoteles-top {
  width: 80%;
  margin: 8rem auto 8rem auto;
}
.lugares-top h3,
.hoteles-top h3 {
  margin-left: 2rem;
  font-weight: 200;
  color: #004274;
  font-size: 25px;
}

.lugares-top h3 b,
.hoteles-top h3 b {
  text-transform: uppercase;
}

.line-top {
  width: 100%;
  height: 6px;
  background-color: #004274;
  border-radius: 10px;
  margin: 1rem 0;
}

.download-app {
  width: 80%;
  margin: 8rem auto 8rem auto;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
}

.download-app img {
  width: 50%;
  min-width: 200px;
  object-fit: cover;
  object-position: center;
}
.contain-lugares {
  width: 250px;
}
.contain-lugares h4 {
  font-size: 23px;
}
.contain-lugares img {
  object-fit: cover;
  object-position: center;
  width: 100%;
  filter: brightness(60%);
  height: 250px;
  border-radius: 5px;
}

.layout-lugares {
  position: relative;
}
.contain-stars {
  width: 100%;
  display: flex;
  justify-content: start;
  gap: 10px;
  margin: 0.5rem 0 1rem 0;
}
.contain-stars img {
  width: 25px;
  height: 25px;
}
.layout-lugares p {
  position: absolute;
  bottom: 10px;
  right: 10px;
  color: #ffff;
  font-weight: 600;
}

.lugares-text {
  text-align: justify;
  color: rgb(81, 81, 81);
  font-size: 14px;
}

.contain-icons-h ul {
  display: flex;
  justify-content: space-around;
  gap: 1rem;
  list-style: none;
}

.contain-icons-h img {
  width: 50px;
}

.list-carrousel-verticales {
  list-style: none;
}

@media screen and (max-width: 1100px) {
  .lugares-top,
  .hoteles-top,
  .conoce-travel,
  .contain-video,
  .services-travel,
  .download-app {
    width: 99%;
  }
  .line-top {
    width: 95%;
  }
}

@media screen and (max-width: 650px) {
  .header-travel,
  .slides,
  .slide img {
    height: 100vh;
  }

  .efect-black {
    height: 100vh;
    justify-content: center;
  }

  .contain-items-header {
    width: 100%;
  }
  .conoce-travel {
    gap: 2rem;
  }

  .section-conoce-travel,
  .section-conoce-travel-b {
    width: 100%;
  }

  .conoce-travel h3 {
    height: auto;
    margin-bottom: 1rem;
  }
  .form-find-t {
    min-height: 100px;
  }
}

@media screen and (max-width: 500px) {
  .download-app {
    gap: 2rem;
  }

  .download-app img {
    width: 80%;
  }
  .layout-conoce div {
    width: 90%;
  }
  .section-conoce-travel div {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .section-conoce-travel img {
    width: 90%;
    margin: 0 auto;
  }

  .video-youtube {
    padding: 0.5rem;
  }
  .contain-video {
    width: 90%;
    height: 300px;
  }
}


@media screen and (max-width: 700px) {
  .services-travel {
    width: 100%;
    max-width: 1280px;
    padding: 0;
  }
}



