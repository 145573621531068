.contact-insurance{
    width: 95%;
    margin: 40px auto;
    max-width: 1280px;
}


.layout-secure{
    width: 95%;
    margin: 2rem auto;
    max-width: 1280px;
    display: flex;
    justify-content: space-between;
}