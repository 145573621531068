.modal-card-detail {
  position: fixed;
  background-color: var(--transparentModal);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 1;
  transition: all 0.2s ease;
  padding: 30px;
}

.modal-card-detail.close {
  position: fixed;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  pointer-events: none;
}

.content-modal-card-detail {
  display: flex;
  flex-direction: column;
  gap: 15px;
  background-color: var(--white);
  width: 100%;
  max-width: 680px;
  max-height: 600px;
  overflow-y: scroll;
  border-radius: 12px;
  padding: 20px;
  padding-bottom: 0;
  position: relative;
  opacity: 1;
  transition: all 0.3s ease;
  transform: translateY(0px);
  z-index: 99999;
}

.content-modal-card-detail.close {
  background-color: var(--white);
  width: 100%;
  width: 100%;
  max-width: 680px;
  max-height: 600px;
  overflow-y: scroll;
  border-radius: 10px;
  padding: 20px;
  padding-bottom: 0;
  position: relative;
  opacity: 0;
  transition: all 0.3s ease;
  transform: translateY(-100px);
}

.modal-card-detail-close {
  position: absolute;
  right: 7px;
  top: 7px;
  cursor: pointer;
  z-index: 999999;
}

.content-modal-card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 30px;
}

.content-modal-card-title {
  width: 100%;
  padding: 5px 0 15px 0;
  border-bottom: 1px solid var(--greyPrimary);
  color: var(--bluePrimary);
  text-align: start;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.content-modal-card-title p {
  color: var(--blackGray);
  font-size: 14px;
}

.content-modal-card-title p span {
  color: var(--bluePrimary);
  font-weight: 600;
}

.content-modal-card-title h2 {
  font-size: 18px !important;
}

.list-content-modal-card {
  width: 100%;
  font-size: 14px;
  list-style: none;
}

.label-content-modal-card {
  color: var(--greySecundary) !important;
  font-weight: 400 !important;
  font-size: 14px !important;
}

.value-content-modal-card {
  color: var(--blackGray) !important;
  font-weight: 500 !important;
  font-size: 14px !important;
}

.content-modal-card li {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 10px;
}

.price-content-modal-card {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: space-between;
  padding: 20px 0;
  border-bottom: 1px solid var(--greyPrimary);
  border-top: 1px solid var(--greyPrimary);
}

.price-content-modal-card span {
  color: var(--bluePrimary) !important;
  font-size: 18px;
}

.price-content-modal-card-description {
  color: var(--greySecundary);
  padding-bottom: 10px;
  font-size: 14px;
}