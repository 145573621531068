.requiredSession{
  width: 90%;
  height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.requiredSessionIcon{
  width: 120px;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.requiredSessionTitle{
margin-top: 10px;
margin-bottom: 30px;
  color: var(--bluePrimary);
  font-size: 22px;
  font-family: 'Montserrat', sans-serif;
  font-weight: bolder;
  text-align: center;
}

.requiredSessionPannel{
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.requiredSessionBtn{
  background-color: var(--bluePrimary);
  width: 40%;
  padding: 10px 20px;
  border-radius: 8px;
  cursor: pointer;
  transition: all ease 200ms;
}

.requiredSessionBtn:hover{
  -webkit-box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.75);
  transform: translateY(-4px);
}

.requiredSessionBtnText{
  text-align: center;
  color: var(--white);
  font-size: 14px;
  font-family: 'Montserrat', sans-serif;
  font-weight: bolder;
  text-transform: capitalize;
}

@media screen and (max-width: 600px) {
  .requiredSessionPannel {
      width: 100%;
      flex-direction: column;
  }
}