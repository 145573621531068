.contain-all-results-global {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 74%;
}

.main-content-result-hotel {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.main-content-resulta-filter-hotels {
  width: 100%;
  display: flex;
  gap: 20px;
  justify-content: space-between;
  margin: 0 auto;
}

@media screen and (max-width: 1340px) {
  .main-content-resulta-filter-hotels {
      width: 95%;
    }
}

@media screen and (max-width: 1000px) {
    .main-content-resulta-filter-hotels {
        flex-direction: column;
      }
  }

@media screen and (max-width: 1100px) {
  .contain-all-results-global {
    width: 68%;
  }
}

@media screen and (max-width: 800px) {
  .contain-all-results-global {
    width: 100%;
  }
}
