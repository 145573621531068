.header-fligths {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  margin: 1rem auto;
  justify-content: center;
  gap: 20px;
  background-color: #004274;
  padding: 50px 0px;
}

.header-transport {
  width: 85%;
  display: flex;
  justify-content: space-between;
  margin: 2rem auto;
}

.content-search-fli {
  width: 95%;
  max-width: 1280px;
}

.content-search-fli > div {
  padding: 20px;
}

.content-banner {
  width: 70%;
  border-radius: 15px;
  position: relative;
}

.content-banner img {
  width: 100%;
  border-radius: 15px;
  object-fit: cover;
  height: 100%;
}

.bg-black-fly {
  position: absolute;
  width: 100%;
  top: 0;
  border-radius: 15px;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.501);
  display: flex;
  justify-content: flex-end;
  padding: 1rem;
  align-items: flex-end;
}

.content-letters-fly {
  width: 50%;
  text-align: center;
}

.content-letters-fly img {
  width: 80%;
}

.content-letters-fly p {
  color: white;
  font-size: 18px;
  letter-spacing: 1.6px;
  text-align: center;
}

.option-traveling {
  width: 80%;
  margin: 2rem auto;
  display: flex;
  justify-content: space-around;
}

.card-option-traveling {
  background-color: #004274;
  border-radius: 100px;
  display: flex;
  justify-content: space-around;
  color: white;
  height: 200px;
  overflow: hidden;
  width: 45%;
  max-width: 800px;
}

.card-option-traveling-info {
  width: 60%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  z-index: 9;
  margin-left: 10%;
  justify-content: center;
}

.card-option-traveling-info h3 {
  font-size: 25px;
}

.card-option-traveling-info p {
  font-weight: 300;
  font-size: 14px;
  color: rgb(242, 242, 242);
}

.card-option-traveling-info button {
  width: 100%;
  background-color: white;
  font-weight: 600;
  font-size: 16px;
  color: #004274;
  cursor: pointer;
  padding: 6px;
  border-radius: 15px;
}

.efect-black-f-iframe {
  position: absolute;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.412);
  top: 0;
  padding-left: 5%;
  padding-right: 5%;
}

.card-option-traveling-img .img1 {
  position: absolute;
  width: 180px;
  left: 10%;
  filter: drop-shadow(4px 4px 8px #4c4c4c);
}

.card-option-traveling-img .img2 {
  position: absolute;
  width: 150px;
  left: 52%;
  filter: drop-shadow(4px 4px 8px #4c4c4c);
}

@media screen and (max-width: 1100px) {
  .card-option-traveling-img .img1 {
    left: 0;
  }
}

@media screen and (max-width: 900px) {
  .option-traveling {
    flex-direction: column;
    gap: 1rem;
  }

  .card-option-traveling {
    width: 100%;
    height: 200px;
  }

  .card-option-traveling-img {
    width: 20%;
  }

  .bg-black-fly {
    justify-content: center;
    align-items: center;
  }

  .card-option-traveling-img .img1 {
    width: 180px;
    left: 0;
  }

  .card-option-traveling-img .img2 {
    width: 150px;
    left: 0;
  }
}

@media screen and (max-width: 856px) {
  .header-fligths {
    flex-direction: column;
    gap: 1rem;
  }

  .content-banner {
    width: 100%;
  }

  .card-option-traveling {
    border-radius: 70px;
  }
}

@media screen and (max-width: 600px) {
  .card-option-traveling-img {
    width: 30%;
  }
}

@media screen and (max-width: 400px) {
  .card-option-traveling {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    height: auto;
    border-radius: 20px;
  }

  .card-option-traveling-img {
    width: 100%;
    height: auto;
    text-align: center;
  }

  .card-option-traveling-img .img1 {
    display: none;
  }

  .card-option-traveling-img .img2 {
    display: none;
  }

  .card-option-traveling-info {
    width: 100%;
    margin-left: 8%;
  }

  .content-letters-fly p {
    font-size: 12px;
  }
}

.places-destacs {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
}

.places-destacs h2 {
  color: #004274;
  font-size: 24px;
  font-weight: 700;
}

.places-destacs p {
  font-size: 14px;
}

.cards-places-destacs {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
}

.select-cities {
  background-color: #fff;
  border-radius: 7px;
  border: 1px solid #ededed;
  height: fit-content;
  cursor: pointer;
  padding-right: 5px;
  overflow: hidden;
}

.select-cities select {
  width: 100%;
  height: 100%;
  border: none;
  background-color: none;
  outline: none;
  padding: 10px;
  cursor: pointer;
}

.select-cities select option {
  padding: 10px;
}

.card-place-destac {
  width: 100%;
  min-width: 300px;
  max-width: 320px;
  display: flex;
  flex-direction: column;
  border: 1px solid #ededed;
  border-radius: 15px;
  box-shadow: 0px 4px 4px 0px #00000008;
}

.card-place-destac-img {
  width: 100%;
  height: 300px;
}

.card-content-destac {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-radius: 10px;
  padding: 15px;
}

.card-content-destac h3 {
  color: #004274;
  font-size: 18px;
}

.card-place-destac img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 15px 15px 0 0;
}

.card-content-info {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 10px;
}

.card-content-info p {
  font-size: 14px !important;
}

.card-content-info h4 {
  font-size: 14px !important;
}

.underline-effect {
  position: relative;
  display: inline-block;
  text-wrap: nowrap;
  font-size: 14px;
}

.underline-effect::after {
  content: "";
  position: absolute;
  width: 0;
  height: 1px; /* altura del underline */
  background-color: #004274; /* color del underline */
  bottom: 0;
  left: 0;
  transition: width 0.5s ease-in-out; /* tiempo de transición */
}

.underline-effect:hover::after {
  width: 100%;
}

@media screen and (max-width: 1300px) {
  .cards-places-destacs {
    width: 100%;
    overflow-x: scroll;
    display: flex;
    justify-content: space-between;
    gap: 10px;
  }
}

@media screen and (max-width: 600px) {
  .places-destacs {
    flex-direction: column;
    align-items: start;
  }

  .select-cities {
    margin-top: 10px;
  }
}
