.div_choferAvatar {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: -35px;
}
.div_carChoferImg {
    width: 235px;
    height: 150px;
    border-radius: 20px;
    box-shadow: -4px 4px 4px rgba(0, 0, 0, 0.25) !important;
}
.div_carChoferImg img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 20px;
}
.buttonChoferQuestion {
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-color: rgba(240, 248, 255, 0);
    border: none;
    cursor: pointer;
}
.card_chofer {
    border-radius: 20px !important;
    box-shadow: -4px 4px 4px rgba(0, 0, 0, 0.25) !important;
}
.div_textsChofer p {
    font-family: 'Inter';
}
.action_choferStars {
    width: 100%;
    justify-content: center;
}