.accordion-detail-form-hotel {
  background-color: var(--bluePrimary);
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  cursor: pointer;
  color: white;
  padding: 0.8rem 1.4rem;
}

.form-persons-pay-flight .camp-form-person-hotel .calendar-date-form-passenger {
  position: absolute;
  top: 60px;
  left: 20px;
  border-radius: 10px;
  padding: 1rem;
  background-color: var(--white);
  width: fit-content;
  border: 1px solid var(--greyPrimary);
  max-height: 600px;
  overflow-y: scroll;
  border-radius: 12px;
  z-index: 2;
  opacity: 1;
  transition: all .5s ease;
}

.form-persons-pay-flight .camp-form-person-hotel .calendar-date-form-passenger-close {
  position: absolute;
  opacity: 0;
  z-index: -2;
  transition: all .5s ease;
}


.accordion-detail-form-hotel div {
  width: 95%;
}

.accordion-detail-form-hotel div p {
  font-weight: 300;
  width: 95%;
  font-size: 13px;
  color: rgb(239, 238, 238);
}

.circle-icon-accordion {
  border-radius: 50%;
  padding: 0.2rem;
  border: solid 2px white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-persons-pay-flight {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1rem;
  position: relative;
  z-index: auto;
  transition: all 0.3s ease;
  height: auto;
  padding-left: 8px;
}

.form-persons-pay-flight-close {
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: -1;
  height: 0px;
  opacity: 0;
  padding-left: 8px;
}

.form-persons-pay-flight-close>div {
  display: none;
  flex-direction: column;
  position: relative;
  z-index: -1;
}

.form-persons-pay-flight .camp-form-person-hotel select {
  background-color: transparent;
  position: relative;
  width: 100%;
  padding: 0.8rem 1rem;
  border-radius: 8px;
  border: solid 2px var(--greyPrimary);
}

.form-persons-pay-flight .camp-form-person-hotel {
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
  position: relative;
}



.form-persons-pay-flight .camp-form-person-hotel .modal-countries {
  width: 100%;
  position: absolute;
  background-color: white;
  border-radius: 5px;
  top: 46px;
  box-shadow: var(--shawdowPrimary);
  z-index: 9;
  border: 1px solid var(--greyPrimary);
  overflow: hidden;
}

.form-persons-pay-flight .camp-form-person-hotel .modal-countries h4 {
  color: var(--bluePrimary);
  background-color: var(--blueWhiteTable);
  border-bottom: 1px solid var(--greyPrimary);
  text-align: left;
  padding: 0.6rem;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  font-size: 12px;
  font-weight: 500;
}

.form-persons-pay-flight .camp-form-person-hotel .modal-countries ul {
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  color: var(--bluePrimary);
  max-height: 100px;
  overflow: scroll;
}

.form-persons-pay-flight .camp-form-person-hotel .modal-countries ul li {
  font-size: 12px;
  color: var(--bluePrimary);
  transition: 0.3s ease;
  padding: 0.5rem;
}

.form-persons-pay-flight .camp-form-person-hotel .modal-countries ul li:hover {
  background-color: var(--blueWhiteTable);
  cursor: pointer;
}

.form-persons-pay-flight .camp-form-person-hotel p {
  font-size: 12px;

  display: flex;
  align-items: center;
}

.form-persons-pay-flight .camp-form-person-hotel-doc {
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
}

.form-persons-pay-flight .camp-form-person-hotel-doc label {
  color: var(--bluePrimary);
  font-size: 12px;
  font-weight: 500;
}

.form-persons-pay-flight .camp-form-person-hotel-doc p {
  font-size: 12px;
  padding-top: 3px;
  display: flex;
  align-items: center;
}

.contain-inputs-selecteds {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 10px;
}

.form-persons-pay-flight .camp-form-person-hotel-doc .contain-inputs-selecteds input {
  width: 100%;
  padding: 0.7rem;
  border-radius: 5px;
  font-size: 12px;
  border: solid 1px var(--greyPrimary);
}

.form-persons-pay-flight .camp-form-person-hotel-doc .contain-inputs-selecteds .select-type-doc {
  background-color: transparent;
  position: relative;
  width: 100%;
  padding: 0.7rem;
  border-radius: 5px;
  border: solid 1px var(--greyPrimary);
}

.form-persons-pay-hotel .camp-form-person-hotel-doc .contain-inputs-selecteds .select-type-doc .modal-select-type-doc {
  width: 290px;
  position: absolute;
  top: 48px;
  left: 0px;
  background-color: white;
  padding: 15px;
  border-radius: 8px;
  border: 1px solid var(--greyPrimary);
}

.form-persons-pay-hotel .camp-form-person-hotel-doc .contain-inputs-selecteds .select-type-doc .modal-select-type-doc ul {
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.form-persons-pay-hotel .camp-form-person-hotel-doc .contain-inputs-selecteds .select-type-doc .modal-select-type-doc ul li {
  user-select: none;
  color: var(--bluePrimary);
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
  transition: all 0.3s ease;
}

.form-persons-pay-hotel .camp-form-person-hotel-doc .contain-inputs-selecteds .select-type-doc .modal-select-type-doc ul li:hover {
  color: var(--bluePrimary);
}

.form-persons-pay-flight .camp-form-person-hotel-doc .contain-inputs-selecteds .select-type-doc .select-onclick-modal-type {
  align-items: center;
  display: flex;
  width: 100%;
  justify-content: space-between;
  cursor: pointer;
  height: 100%;
}

.error-birth input {
  border-color: var(--redSecondary) !important;
}

.form-persons-pay-flight .camp-form-person-hotel-doc .contain-inputs-selecteds .select-type-doc .select-onclick-modal-type span {
  font-size: 11px;
  font-weight: 400;
  color: var(--bluePrimary);
}

.form-persons-pay-flight .camp-form-person-hotel label {
  color: var(--bluePrimary);
  font-size: 12px;
  font-weight: 500;
}

.form-persons-pay-flight .camp-form-person-hotel input {
  padding: 0.7rem;
  border-radius: 5px;
  border: solid 1px var(--greyPrimary);
}

.form-persons-pay-flight .camp-form-person-hotel p {
  font-size: 12px;
  padding-top: 3px;
  display: flex;
  align-items: center;
}

.btn-save-info-passenger {
  margin-top: 10px;
  width: fit-content;
  background-color: var(--bluePrimary);
  padding: 0.6rem 3rem;
  border-radius: 5px;
  font-weight: 400;
  font-size: 14px;
  cursor: pointer;
  transition: 0.3s ease;
  color: var(--white);
}

.btn-save-info-passenger:hover {
  background-color: var(--blueTertiary);
}

.err-hotel-form-person {
  width: 100%;
  background-color: var(--redPrimary);
  padding: 0.8rem;
  border-radius: 5px;
  color: var(--bluePrimary);
  border: 1px solid var(--greyPrimary);
  font-weight: 400;
  font-size: 14px;
  text-align: center;
}

.select-type-doc {
  position: relative;
}

.modal-select-type-doc-flight {
  width: 100%;
  position: absolute;
  top: 43px;
  left: 0px;
  background-color: var(--white);
  border-radius: 5px;
  border: 1px solid var(--greyPrimary);
  z-index: 10;
  box-shadow: var(--shawdowPrimary);
  overflow: hidden;
}

.modal-select-type-doc-flight ul {
  list-style: none;
  display: flex;
  flex-direction: column;
}

.modal-select-type-doc-flight li {
  user-select: none;
  color: var(--bluePrimary);
  font-size: 12px;
  font-weight: 400;
  cursor: pointer;
  transition: all 0.3s ease;
  padding: 8px 10px;
}

.modal-select-type-doc-flight li:hover {
  background-color: var(--blueWhiteTable);
}