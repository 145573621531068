.container-voucher-terrawind{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.container-voucher-terrawind p{
    font-family: "Montserrat" !important;
}

.voucher-terrawind{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50%;
    height: 100%;
    padding: 20px;
    border-radius: 25px;
    box-shadow: 0px 4px 4px 0px #00000040;
}

.voucher-terrawind-error{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80%;
    height: 100%;
    padding: 20px;
    margin-block: 100px;
    border-radius: 25px;
    box-shadow: 0px 4px 4px 0px #00000040;
}

.voucher-terrawind-error p{
    color: #104172;
    font-size: 16px;
    font-weight: 900;
    font-family: "Montserrat" !important;
}

.title-generate-voucher{
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 50%;
}

.title-generate-voucher p{
    font-size: 16px;
    font-weight: 900;
    color: #6EC301;
}

.title-generate-voucher span{
    margin-inline: 10px;
}

.product-price-terrawind{
    margin-block: 20px ;
}

.product-price-terrawind p{
    text-align: center;
    font-size: 12px;
    font-weight: 400;
}

.product-price-terrawind span{
    font-size: 12px;
    font-weight: 900;
}

.container-table-terrawind{
    width: 90%;
}

.table-voucher-terrawind{
    border-collapse: collapse;
    width: 100%;
    border-radius: 8px;
    overflow-x: auto;
}

.table-voucher-terrawind thead{
    background-color: #104172;
    color: white;
}

.table-voucher-terrawind th{
    border: 1px solid #104172;
    padding-block: 10px;
    text-align: center;
    font-size: 12px;
    font-weight: 900;
    font-family: "Montserrat" !important;
}

.table-voucher-terrawind td{
    border: 1px solid #D9D9D9;
    padding: 10px;
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    font-family: "Montserrat" !important;
}

.btn-download-terrawind{
    border-radius: 8px;
    background-color: #104172;
    color: white;
    padding-block: 5px;
    padding-inline: 15px;
    font-family: "Montserrat" !important;
    font-size: 12px;
    font-weight: 900;
    width: 130px;
    cursor: pointer;
}

.btn-buy-again{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70%;
    margin-block: 20px;
}

.btn-buy-again button{
    width: 200px;
}

.loading-generate-voucher{
    width: 100%;
    padding: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.title-p-pay-rejected-terrawind{
    color: red !important;
    display: flex;
    align-items: center;
}

.title-p-pay-rejected-terrawind span{
    margin-inline: 10px;
}

.details-fail-pay{
    margin-top: 10px;
    text-align: center;
}

.details-fail-pay p{
    font-weight: 400;
    font-size: 12px;
    margin-block: 5px;
}

.details-fail-pay span{
    font-weight: 700;
}

.title-p-pay-process-terrawind{
    color: #FF8800 !important;
    display: flex;
    align-items: center;
}

.title-p-pay-process-terrawind span{
    margin-inline: 10px;
    display: flex;
    align-items: center;
}