.content-detail-pack-health {
  margin: 2rem auto;
  display: flex;
  flex-direction: column;
  max-width: 1280px;
  width: 95%;
}

.content-form-detail-health {
  width: 29%;
}

.width-form-detail {
  width: 100% !important;
}

.data-list {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.data-list p {
  margin-left: 10px;
}
.scrollable-div {
  overflow-x: scroll;
  display: flex;
  width: 100%;
  border-bottom: 5px solid var(--blueLight);
  align-items: end;
  padding: 0;
  margin-top: 20px;
  scrollbar-width: none; /* Firefox */
}

.scrollable-div::-webkit-scrollbar {
  width: 0px; /* Safari y Chrome */
  background: transparent;
}
.content-icons-share-health {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  padding: 1rem;
  border-radius: 13px;
  border: 1px solid #d9d9d9;
  margin-bottom: 1rem;
}

.content-icons-share-health h2 {
  color: var(--bluePrimary);
}

.content-info-detail-health {
  width: 100%;
  border-radius: 13px;
  border: 1px solid #d9d9d9;
  display: flex;
  gap: 1rem;
  flex-direction: column;
}

.first-section-detail-health {
  padding: 0.7rem;
  display: flex; 
  gap: 1rem;
  align-items: flex-start;
}

.first-section-detail-health img {
  width: 25%;
  object-fit: cover;
  border-radius: 6px;
}

.first-section-detail-health h2 {
  color: var(--blueLight); 
  font-size: 23px;
  text-transform: uppercase;
  font-weight: 650;
}

.first-section-detail-health h3 {
  color: var(--blueLight); 
  font-size: 20px; 
  font-weight: 650;
}


.second-section-detail-health {
  display: flex;
  flex-direction: column;
  gap: 0.7rem;
  padding: 0.5rem;
}

.second-section-detail-health-items {
  display: flex;
  gap: 20px;
  padding: 0 10px;
}

.second-section-detail-health-item {
  width: 50%;
}

.second-section-detail-health ul {
  display: flex;
  flex-direction: column;
  list-style: none;
  gap: 0.6rem;
}
.second-section-detail-health ul li {
  font-size: 14px;
}

.second-section-detail-health h3 {
  font-size: 17px;
  color: var(--bluePrimary);
  margin-top: 1rem;
}

.second-section-detail-health div {
  width: 100%;
  margin-top: 1rem;
  position: relative;
}

.second-section-detail-health div img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 13px;
}

.second-section-detail-health div h2 {
  position: absolute;
  color: white;
  top: 60px;
  width: 50%;
  left: 20px;
}

.three-section-detail-health {
  width: 100%;
}

.three-section-detail-health h2 {
  color: var(--bluePrimary);
  text-align: center;
}

.three-section-detail-health .table-content button {
  width: fit-content;
  height: 50px;
  overflow: scroll;
  padding: 0.5rem 1rem;
  border-radius: 0.6rem;
  border: 1px solid #d9d9d9;
  background: #fff;
  color: var(--bluePrimary);
  margin-bottom: 0.3rem;
  cursor: pointer;
}

.content-info-selected-health {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 2rem auto;
  padding-inline: 2rem;
}

.container-price-discount-revent {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.table-price-discount-revent {
  width: 70%;
}

.content-info-selected-health th {
  background-color: var(--bluePrimary);
  color: white;
  padding: 1rem;
  border-radius: 5px;
}
.content-info-selected-health td {
  padding: 1rem;
  border-radius: 5px;
  color: rgb(82, 82, 82);
  border: 1px solid rgb(167, 167, 167);
}

.content-info-selected-health li{
  font-size: 12px;
}

.btns-detail-health {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
}

.btns-detail-health button {
  padding: 0.5rem;
  width: 100%;
  background-color: var(--bluePrimary);
  color: white;
  cursor: pointer;
  font-size: 18px;
  font-weight: 600;
  border-radius: 6px;
}

.content-map-detail-health {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1rem;
  width: 100%;
}

.content-map-detail-health h2 {
  font-size: 24px;
  text-transform: uppercase;
  color: var(--bluePrimary);
}

.button-pay-disabled {
  background: var(--blueLight);
  width: 100%;
  padding: 0.5rem;
  border-radius: 6px;
  color: white;
  text-align: center;
  font-size: 18px;
  font-weight: 600;
}

@media screen and (max-width: 1100px) {
  .content-detail-pack-health {
    width: 95%;
  }
}

@media screen and (max-width: 900px) {
  .content-form-detail-health {
    width: 100%;
  }
}

@media screen and (max-width: 600px) {
  .first-section-detail-health img {
    width: 35%;
    object-fit: cover;
    border-radius: 6px;
  }

  .first-section-detail-health h2 {
    width: 64%;
    font-size: 20px;
  }

  .second-section-detail-health-items {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 0 10px;
  }

  .second-section-detail-health-item {
    width: 100%;
  }
}

@media screen and (max-width: 450px) {
  .second-section-detail-health div h2 {
    width: 80%;
  }
  .first-section-detail-health h2 {
    font-size: 16px;
  }
  .second-section-detail-health ul li {
    font-size: 13px;
  }
  .second-section-detail-health p {
    font-size: 13px;
  }
  .three-section-detail-health h2 {
    color: var(--bluePrimary);
    font-size: 17px;
  }
  .btns-detail-health button {
    width: 100%;
    font-size: 14px;
  }
}

.formMedicalPackage{
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.topReservePackage{
  color: #104172;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: .5rem;
}

.topReservePackage svg{
  font-size: 31px;
  cursor: pointer;
}
.topReservePackage p{
  font-size: 19px;
  font-weight: 600;
}
.InputDivPackage{
  display: flex;
  flex-direction: column; 
  width: 100%;
  margin-bottom: 1.1rem;
}
.InputDivPackage p{
  color: #104172;
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 5px;
}
.InputDivPackage input{
  width: 100%;
  border: 1px solid #d9d9d9;
  padding: 0.8rem 1rem;
  border-radius: 8px;
}

.divCheckPay{
  margin-top: 1rem;
  width: 100%; 
}
.divCheckPay button{
  background-color: #104172;
  color: white;
  width: 100%;
  font-weight: 500;
  font-size: 18px;
  padding: 0.8rem;
  border-radius: 8px;
  cursor: pointer;
  gap: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}



.detailPayFlight h3 {
  color: var(--bluePrimary);
  font-size: 18px;
}

.methodPayContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 95%;
  max-width: 1280px;
  margin: 0 auto;
  margin-top: 20px;
  padding-bottom: 40px;
}

.methodPayContainer p {
  color: var(--bluePrimary); 
}

.methodPayHeader {
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding-bottom: 10px;
  border-bottom: 2px solid var(--bluePrimary);
}

.methodPayHeader h3 {
  font-size: 24px;
  font-weight: 600;
  color: var(--bluePrimary);
}

.methodPayHeader p {
  font-size: 14px;
  font-weight: 400;
  color: var(--bluePrimary);
}
.methodPayContainInfo {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.methodPayImageContain {
  position: sticky;
  top: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.methodPayContainInfoContain {
  border: 1px solid var(--blueWhiteTableThead);
  width: 100%;
  border-radius: 8px;
  color: var(--bluePrimary);
}

.contentStatePay {
  display: flex;
  gap: 20px;
  padding: 1rem;
  align-items: center;
  background-color: var(--greySecundary);
  border-radius: 8px 8px 0px 0px;
  color: var(--white);
}

.contentStatePay h3 {
  font-size: 18px;
  display: flex;
  align-items: center;
}

.contentStatePay p {
  display: flex;
  gap: 6px;
  color: var(--white);
}

.contentStatePay p div {
  height: 20px;
  width: 20px;
  background-color: var(--greenPrimary);
  border-radius: 50%;
  color: var(--white);
}

.contentStatePayMessage {
  color: var(--white);
  display: flex;

  gap: 20px;
}

.contentStatePayMessage p {
  color: var(--white);
  padding-top: 3px;
}

.methodPayContainInfoContainPadding {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  color: var(--bluePrimary);
  padding: 20px;
}
.detailPayFlightHeader {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 30px;
}
.detailPayFlightHeaderImage {
  width: 100px;
  height: 100px;
  padding: 3px;
  border-radius: 8px;
  box-shadow: var(--shawdowPrimary);
}

.detailPayFlightHeaderImage img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.methodPayContainInfoContainPadding h3 {
  font-weight: 600;
  font-size: 20px;
  padding-bottom: 8px;
}
.methodValuesContaint {
  display: flex;
  flex-direction: column;
  gap: 20px;
  border-top: 1px solid var(--blueWhiteTableThead);
  padding-top: 20px;
}

.methodValuesItem {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  align-items: center;
}

.methodValuesItem span {
  font-weight: 600;
}

.methodValuesItemTotal {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  align-items: center;
  border-top: 1px solid var(--blueWhiteTableThead);
  padding-top: 20px;
}

.methodValuesItemTotal span {
  font-weight: 600;
}

.securityPay {
  border: 1px solid var(--blueWhiteTableThead);
  padding: 20px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 20px;
  color: var(--bluePrimary);
}
.APPROVED {
  background-color: var(--greenOffert);
}

.PENDING {
  background-color: var(--orangePrimary);
}

.REJECTED {
  background-color: var(--redSecondary);
}
.methodPayContain {
  display: flex;
  justify-content: space-between;
  gap: 50px;
  margin-bottom: 30px;
}
.itemDetailsFlights {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
  border-top: 1px solid var(--blueWhiteTableThead);
  padding-top: 20px;
  margin-top: 20px;
}
.itemDetailsFlights1 {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: 20px;
  height: 100%;
  border-right: 2px solid var(--bluePrimary);
  padding-right: 30px;
}
.methodPayContainQuestions {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.detailPayFlightInfo {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.detailPayFlightInfo h2 {
  font-size: 24px;
  color: var(--bluePrimary);
}
.detailPayFlightName {
  display: flex;
  gap: 5px;
  align-items: center;
  color: var(--bluePrimary);
}

.detailPayFlightName p {
  font-size: 18px;
}
.itemDetailsFlights2 {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: 20px;
  height: 100%;
  padding-left: 30px;
}
.itemDetailsFlightsColumn1 {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  gap: 10px;
  width: 100%;
}
.typeFlight {
  display: flex;
  flex-direction: column;
  color: var(--bluePrimary);
  font-weight: 700;
}

.typeFlight strong {
  font-weight: 500;
}
@media screen and (max-width: 550px) {
  .itemDetailsFlights {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding-bottom: 10px;
      gap: 20px;
  }

  .itemDetailsFlights2 {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      gap: 20px;
      height: 100%;
      padding-left: 0px;
  }

  .itemDetailsFlights1 {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      gap: 20px;
      height: 100%;
      border: none;
      padding-right: 0px;
  }
}
.itemDetailsFlightsColumn1 {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  gap: 10px;
  width: 100%;
}
.typeFlight {
  display: flex;
  flex-direction: column;
  color: var(--bluePrimary);
  font-weight: 700;
}

.typeFlight strong {
  font-weight: 500;
}
.custumFileUploadDialog{
  display: flex;
  align-items: center;
  align-content: center;
}
.formItemTextDate{
  display: flex;
  flex-direction: column;

}
.formItemTextDateInput{
  display: flex;
  flex-direction: column;
  gap: 6px;
} 

.bottomStdHealt{
  display: flex;
  flex-direction: column;
}
.none900 {
  display: flex;
}

@media (max-width: 899px) {
  .none900 {
      display: none;
  }
}
.show900 {
  display: none;
}

@media (max-width: 899px) {
  .show900 {
      display: flex;
  }
}