.btn-double-booking-flow{
    width: 100%;
    display: flex;
    gap: 2%;
}

.btn-one-booking-flow {
    width: 100%;
}

.btn-one-booking-flow button{
    width: fit-content;
    border-radius: 6px;
    background-color: var(--bluePrimary);
    color: white;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    padding: 1rem 3rem;
    transition: .5s ease-in-out;
    cursor: pointer;
}

.btn-double-booking-flow button:nth-child(1){
    width:fit-content;
    border-radius: 6px;
    background-color: var(--bluePrimary);
    color: white;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    padding: 1rem 3rem;
    transition: .5s ease-in-out;
    cursor: pointer;
}

.btn-double-booking-flow button:nth-child(2){
    width:fit-content;
    border-radius: 6px;
    text-align: center;
    background-color: var(--bluePrimary);
    font-size: 18px;
    font-weight: 600;
    color: white;
    padding: 1rem 3rem;
    transition: .5s ease-in-out;
    cursor: pointer;

}

.form-incomplete-camp{
    font-size: 18px;
    font-weight: 600;
    color: rgba(173, 13, 13, 0.652);
    width: 100%;
}

@media screen and (max-width:1100px) {
    .btn-double-booking-flow,.btn-one-booking-flow,.form-incomplete-camp{
        width: 95%;
    }
}

@media screen and (max-width:465px){
    .btn-double-booking-flow button:nth-child(1){
        width: 29%;
        border-radius: 6px;
        background-color: var(--bluePrimary);
        color: white;
        text-align: center;
        font-size: 18px;
        font-weight: 600;
        padding: 1rem;
        transition: .5s ease-in-out;
    }
    
    .btn-double-booking-flow button:nth-child(2){
        width: 69%;
        border-radius: 6px;
        text-align: center;
        background-color: var(--bluePrimary);
        font-size: 18px;
        font-weight: 600;
        color: white;
        padding: 1rem;
        transition: .5s ease-in-out;
    
    }
    
}