.modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: var(--transparentModal);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    opacity: 1;
    pointer-events: all;
    transition: all 0.3s ease;
  }

  .modal.close {
    opacity: 0;
    pointer-events: none;
    transition: all 0.3s ease;
  }
  
  .modalContent {
    width: 100%;
    background: var(--white);
    padding: 20px;
    border-radius: 8px;
    position: relative;
    max-width: 760px;
    transform: translateY(0px);
    opacity: 1;
    pointer-events: all;
    transition: all 0.3s ease;
    box-shadow: var(--shawdowPrimary);
  }

  .modalContent.close {
    transform: translateY(-30px);
    opacity: 0;
    pointer-events: none;
  }
  
  .closeButton {
    position: absolute;
    top: 10px;
    right: 10px;
    border: none;
    background: none;
    font-size: 18px;
    cursor: pointer;
    background-color: var(--white);
    border-radius: 8px;
    display: grid;
    place-items: center;
    padding: 3px;
  }

  .contentModal {
    display: flex;
    justify-content: space-between;
    gap: 20px;
  }

  .contentModalText {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .contentModalForm {
    display: flex;
    justify-content: space-between;
    gap: 10px;
  }

  .contentInput {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 3px;
  }

  .contentInput label {
    font-size: 14px;
    font-weight: 600;
    color: var(--bluePrimary);
  }

  .contentInput input {
    height: 100%;
    border: 1px solid var(--greyPrimary);
    padding: 5px;
    border-radius: 8px;
  }

  .modalImage {
    width: 400px;
  }

  .modalImage img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
  }

  
  .cardsBuys {
    display: flex;
    gap: 20px;
    justify-content: space-between;
    align-items: center;
}

.cardBuy {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    box-shadow: var(--shawdowPrimary);
    color: var(--bluePrimary);
}

.contentPadding {
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
}

.cardBuyImage {
    width: 100%;
    height: 100%;
}

.cardBuyImage img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 10px 10px 0px 0px;
}

.cardBuyContent {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    gap: 20px;
}

.cardBuyContentText {
    display: flex;
    flex-direction: column;
    gap: 10px;
    font-size: 14px;
    font-weight: 600;
}

.cardBuyContentText h3 {
    color: var(--bluePrimary);
}

.cardBuyContentDescrip {
    font-size: 14px;
    font-weight: 500;
    color: var(--blackGray);
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.cardBuyContentDescrip h4 {
    font-weight: 600;
    font-size: 18px;
    color: var(--bluePrimary);
}

.cardIconAlert {
    font-size: 20px;
    color: var(--bluePrimary);
    cursor: pointer;
}

.addBuy {
    background-color: var(--bluePrimary);
    padding: 10px 20px;
    border-radius: 8px;
    color: var(--white);
    cursor: pointer;
}