.contain-services-include {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  margin: 1rem auto;
}

.servicios-include-dropdown {
  background-color: #004274;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 1rem;
  cursor: pointer;
}

.contain-dorpdowns {
  width: 49%;
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
}

.contain-dorpdowns ul {
  list-style: none;
  position: relative;
  width: 100%;
  max-height: 230px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 15px;
  overflow: scroll;
  padding: 15px;
  background-color: rgb(213, 213, 213);
  scrollbar-width: thin !important;
  border-radius: 10px;
}

.contain-dorpdowns ::-webkit-scrollbar {
  height: 20px;
}

.contain-dorpdowns ::-webkit-scrollbar-track {
  background: #444;
  border-radius: 30px;
}

.contain-dorpdowns ::-webkit-scrollbar-thumb {
  background: #ccc;
  width: 70px;
  border-radius: 30px;
}

.contain-dorpdowns ::-webkit-scrollbar-thumb:hover {
  background: white;
}

.contain-dorpdowns ul li {
  display: flex;
  align-items: center;
}

.contain-checkin {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.servicios-include-dropdown h3 {
  color: white;
}

.check-in-out {
  width: 25%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.check-in-out p {
  margin-top: 1rem;
  font-size: 14px;
  color: #616161;
}

.check-in-out span {
  font-size: 18px;
  color: #004274;
  font-weight: 500;
  border-radius: 10px;
  width: fit-content;
}

.contain-stars-hotel {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
}

.stars-description {
  display: flex;
  align-items: center;
  gap: 10px;
}

.stars-description span {
  color: white;
  font-weight: 700;
  background-color: rgb(38, 191, 140);
  width: 50px;
  text-align: center;
  border-radius: 6px;
  padding: 2px 0;
  height: 100%;
  font-size: 14px;
}

.stars-description p {
  color: rgb(38, 191, 140);
  font-weight: 700;
  font-size: 18px;
}

@media screen and (max-width: 1100px) {
  .contain-services-include {
    width: 99%;
  }
}

@media screen and (max-width: 600px) {
  .contain-services-include {
    flex-direction: column;
    gap: 1rem;
  }
  .contain-dorpdowns,
  .contain-checkin {
    width: 100%;
  }

  .servicios-include-dropdown {
    padding: 0.6rem;
  }

  .servicios-include-dropdown h3 {
    font-size: 15px;
  }

  .check-in-out span {
    padding: 0.6rem;
    font-size: 13px;
  }

  .check-in-out p {
    font-size: 14px;
  }
}

.menu {
  display: flex;
  justify-content: space-around;
  border-bottom: 2px solid #eeeeee;
  margin-bottom: 20px;
}

.menu-option {
  width: 100%;
  padding: 20px 0;
  cursor: pointer;
  position: relative;
  transition: color 0.3s ease;
  color: #616161;
  font-size: 14px;
  font-weight: 400;
  transition: all 0.3s ease;
  text-align: center;
}

.menu-option.selected {
  color: #2f6aa6;
  font-weight: 500;
}

.menu-option.selected::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -2px;
  width: 100%;
  height: 2px;
  background-color: #2f6aa6;
  animation: borderExpand 0.5s ease;
}

@keyframes borderExpand {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

@media screen and (max-width: 500px) {
  .menu {
    display: flex;
    flex-direction: column;
  }

  .contain-checkin {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
  }

  .check-in-out {
    width: 100%;
  }
}