.contain-contact-wellezy{
    background-color: rgb(233, 233, 233);
    width: 100%;
    min-height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.contain-contact-wellezy h3 {
    margin-bottom: 2rem;
    font-size: 20px;
}

.contain-contact-flex{
    max-width: 1070px;
    width: 95%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
}

.individual-contact {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap:5px;
}

.individual-contact p {
    width: 100px;
    font-size: 15px;
    font-weight: 600;
    color: black;
    text-align: center;
}