.flightsHeaderFind {
    /* margin: 70px 0 100px 0; */
    width: 100%;
    display: flex;
    justify-content: center;
}

.flightsHeaderFind_content {
    width: 80%;
    height: 140px;
    background-color: white;
    border-radius: 30px;
    box-shadow: -4px 4px 4px rgba(0, 0, 0, 0.25) !important;
    display: flex;
    justify-content: center;
    align-items: center;
}

.flightsHeaderFind_content_iframe {
    margin-top: 100px;
    width: 100%;
    min-height: 240px;
    background-color: white;
    border-radius: 30px;
    box-shadow: -4px 4px 4px rgba(0, 0, 0, 0.25) !important;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 30px;
}




.flightsHeaderFind_content_ {
    width: 95%;
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.flightsHeaderFind_top {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.flightsHeaderFind_logo {
    display: flex;
}

.flightsHeaderFind_logo h3 {
    font-family: 'Inter';
    font-size: 20px;
    color: #004274;
    font-weight: 600;
}

.flightsHeaderFind_checkBox {
    display: flex;
    
}



.flightsHeaderFind_checkBoxOne {
    display: flex;
    align-items: center;
    margin: 0 10px;
}

.flightsHeaderFind_checkBoxOne p {
    font-family: 'Inter';
    font-size: 18px;
    color: #004274;
    margin-left: 5px;
    font-weight: 200;
    width: max-content;
}

.flightsHeaderFind_bottom {
    width: 100%;
}

.flightsHeaderFind_bottom_ {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
}

.flightsHeaderFind_buttonSearch {
    width: 17%;
    height: 60px;
    border: 1px solid #004274;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.flightsHeaderFind_buttonSearchTitle {
    font-family: 'Inter';
    color: #004274;
    font-size: 16px;
    font-weight: 600;
}

.flightsHeaderFind_buttonSearchDescription {
    font-family: 'Inter';
    font-size: 16px;
    font-weight: 600; 
    color: #004274;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.flight_searchCitiesOrigin,
.flight_searchCitiesDestinity {
    width: 100%;
    max-height: 390px;
    border-radius: 20px;
    background-color: white;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.35) !important;
    z-index: 1;
}

.flight_dateFind {
    width: 17%;
    height: 60px;
    border: 1px solid #004274;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}


/* ESTILOS QUE ESTABAN EN LINEA MUDADOS */

.airplane {
    margin-right: 5px;
}

.flightsHeaderFind_buttonSearch_div {
    width: 90%;
    height: 70%;
    display: flex;
    justify-Content: space-between;
    align-Items: center;
}

.flightsHeaderFind_buttonSearch_div_div {
    width: 82%;
    height: 100%;
    display: flex;
    flex-Direction: column;
    justify-Content: space-between;

}

.flightsHeaderFind_buttonSearch_div_div-bro {
    width: 17%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.flight_dateFind_div {
    width: 90%;
    height: 70%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.flight_dateFind_div_div {
    width: 82%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.flight_dateFind_div_div_bro {
    width: 17%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.flight_dateFindNone_div {
    width: 90%;
    height: 70%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.flight_dateFindNone_div_div {
    width: 82%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.flight_dateFindNone_div_div_bro {
    width: 17%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.flight_passengersFind_div{
    width: 90%; height: 70%; display: flex; justify-content: space-between; align-items: center;
}

.flight_passengersFind_div_div {
    width: 82%; 
    height: 100%; 
    display: flex; 
    flex-direction: column; 
    justify-content: space-between;
}

.flight_passengersFind_div_div_bro{
    width: 17%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}





/* selected flight_dateNone */
.flight_dateFindNone {
    width: 47.5%;
    margin-top: 1rem;
    height: 60px;
    border: 1px solid #004274;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: .3;
}

.flight_passengersFind {
    width: 17%;
    height: 60px;
    border: 1px solid #004274;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: relative;
}

.flight_searcherFind {
    width: 100%;
    padding: .6rem;
    margin-top: 1rem;
    border-radius: 20px;
    border: 1px solid #004274;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: white;
    position: relative !important;
}

.flight_searcherFindDisable {
    opacity: 0.5;
}

.flight_searcherFindDisable:hover {
    cursor: default;
}


.flightsHeaderFind_buttonPassenger {
    font-family: 'Inter';
    font-size: 16px;
    font-weight: 200;
    color: #004274;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}


@media screen and (max-width: 1140px) {
    .flightsHeaderFind_content {
        width: 90%;
    }
}

@media screen and (max-width: 1100px) {
    .flightsHeaderFind_content {
        width: 99%;
    }
}

@media screen and (max-width: 920px) {
    .flightsHeaderFind_content {
        width: 90%;
        height: 280px;
    }

    .flightsHeaderFind_bottom_ {
        width: 100%;
        display: flex;
        flex-flow: wrap;
        padding: 10px 0;
    }

    .flightsHeaderFind_buttonSearch,
    .flight_dateFind,
    .flight_dateFindNone,
    .flight_passengersFind,
    .flight_searcherFind {
        width: 100%;
        margin: 8px 0;
    }
}

@media screen and (max-width: 720px) {
    .flightsHeaderFind {
        margin-top: 120px;
    }
    .flightsHeaderFind_checkBox {
        flex-direction: column;
    }
}

@media screen and (max-width: 580px) {
    .flightsHeaderFind_content {
        width: 99%;
        height: 340px;
    }

    .flightsHeaderFind {
        margin-top: 200px;
    }

    .flightsHeaderFind_top {
        flex-direction: column;
    }

    .flightsHeaderFind_logo {
        width: 100%;
        margin-bottom: 10px;
    }

    .flightsHeaderFind_checkBox {
        width: 100%;
    }

    .flightsHeaderFind_checkBoxOne {
        margin: 0;
        margin-right: 20px;
    }
    .flightsHeaderFind_checkBoxOne p {
        font-size: 15px;
       
    }
    .flightsDateGoing_date p{
        font-size: 12px !important;
    }

    .icons-cale svg {
        width: 18px !important;
    }
    
}
