.rangeFilterSelect {
    display: flex;
    flex-direction: column;
}

.double-range-slider {
    position: relative;
    width: 100%;
    padding: 20px 0 0 0;
}

.double-range-slider .thumb {
    position: absolute;
    pointer-events: none;
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    height: 0;
    outline: none;
    z-index: 999;
    background-color: #fff;
}

.sliderRange {
    position: relative;
    height: 5px;
    width: 100%;
}

.slider__track,
.slider__range {
    position: absolute;
    height: 3px;
    border-radius: 5px;
}

.slider__track {
    width: 100%;
    background-color: #ddd;
    z-index: 1;
}

.slider__linea {
    width: 100%;
    background-color: #ddd;
    z-index: 1;
}


.slider__range {
    background-color: #3b5998;
    z-index: 2;
}

.slider__left-value,
.slider__right-value {
    position: absolute;
    top: 25px;
    color: #000;
    font-size: 12px;
}

.slider__left-value {
    left: 0;
}

.slider__right-value {
    right: 0;
}

.slider__labels {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    justify-content: space-between;
    margin-top: 15px;
}

.slider__label {
    font-size: 12px;
    font-weight: 600;
    color: #000;
}


.thumb.active {
  z-index: 9999 !important;
}
