.voucher-container{
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.form-voucher-container{
    width: 95%;
    max-width: 1280px;
    margin: 2rem auto;
    display: flex;
    justify-content: center;
}

.container-data{
    width: 29%;
}

.form-passangers-container{
    width: 69%;
}

.form-passangers{
    box-shadow: 0px 4px 4px 0px #00000040;
    border-radius: 25px;
    padding-inline: 30px;
    padding-block: 40px;
    width: 100%;
}

.title-form p{
    font-family: "Montserrat" !important;
    font-size: 24px;
    font-weight: 700;
    line-height: 29px;
    color: #0072B9;
    margin-bottom: 20px;
}

.input-date-simple input{
    cursor: pointer;
    appearance: none;
    background: url('../../../ui/assets/iconsSVG/IconCalendar4.svg') no-repeat;
    background-position: right 10px center;
}

.div-emergency-cotact{
    border: none;
    border-top: 1px solid #0072B9;
    background-color: #0072B9;
    margin-block: 50px;
}

.container-data{
    box-shadow: 0px 4px 4px 0px #00000040;
    border-radius: 25px;
    padding-inline: 30px;
    padding-block: 40px;
}

.container-detail-buy{
    margin-top: 50px;
}

.title-detail-buy{
    text-align: center;
}

.detail-buy{
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.detail-buy p{
    font-size: 12px;
    font-weight: 400;
    font-family: "Montserrat" !important;
}

.btns-vouche{
    display: flex;
    justify-content: space-between;
    margin-top: 20px;

}

.btn-pay{
    width: 29%;
}

.btn-frequent-questions{
    width: 69%;
}

.btn-frequent-questions button, .btn-pay button{
    text-align: center;
    width: 100%;
    height: 50px;
    border-radius: 8px;
    background-color: #0072B9;
    color: white;
    font-size: 24px;
    font-weight: 700;
    font-family: "Montserrat" !important;
    cursor: pointer;
    transition: 0.3s;
}

.btn-frequent-questions button{
    background-color: #0072b9dc;
    cursor: not-allowed;
}

.btn-pay button:hover{
    box-shadow: 0px 4px 4px 0px #00000040;
    transition: 0.3s;
}

.btn-pay button:active{
    box-shadow: none;
    transition: 0.3s;
}

.header-voucher-container{
    width: 95%;
    max-width: 1280px;
    margin: 1rem auto;
    margin-block: 70px;
    display: flex;
    justify-content: center;
}

.header-voucher-container > div{
    width: 100%;
    background-color: #0072B9;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 23px;
    height: 100px;
    padding-inline: 50px;
    box-shadow: 0px 4px 4px 0px #00000040;
}

.header-voucher-container > div > p{
    font-size: 32px;
    font-weight: 700;
    font-family: "Montserrat" !important;
}

.detail-buy-title p{
    color: #0072B9;
}

.detail-buy-title-main p{
    color: #104172;
}

.loading-form-passengers{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 69%;
}

@media screen and (max-width: 1200px) {
    .voucher-container{
        flex-direction: column-reverse;
    }
    .container-data{
        width: 100%;
    }
    
    .form-passangers-container{
        width: 100%;
    }
    .title-pay{
        display: none;
    }
    .header-voucher-container > div{
        display: flex;
        justify-content: center;
    }

    .loading-form-passengers{
        width: 100%;
        height: 200px;
    }
}

.validate-data-passengers{
    border: 2px solid red !important;
    border-radius: 8px;
    height: 32px;
    display: flex;
    align-items: center;
}

.validate-data-passengers p{
    font-size: 12px;
    font-weight: 400;
    font-family: "Montserrat" !important;
}

.container-validates{
    width: 100%;
}

.containe-check-voucher{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display:flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 2;
}

.box-circle-progress{
    width: 300px;
    height: 200px;
    border-radius: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 4px 4px 0px #00000040;
    background-color: white;
}