.maxWidthBuildPackage {
  display: flex;
  flex-direction: column;
  max-width: 1280px;
  width: 100%;
  padding-bottom: 20px;
}

.maxWidthBuildPackageTitle {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.maxWidthBuildPackageTitle h3 {
  color: var(--blueSecundary);
  font-size: 24px;
  font-weight: 700;
}

.maxWidthBuildPackageTitle p {
  font-size: 14px;
}
/* Grid.css */

.grid-container-doc {
  display: grid;
  grid-gap: 15px;
  grid-template-columns: repeat(4, 1fr);
}

.grid-item {
  background-color: #fff;
  border: 1px solid #ccc;
  text-align: center;
  overflow: hidden;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.grid-item-info {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 40px;
  justify-content: space-between;
  padding: 15px 15px 0px 5px;
}

.grid-item-info-text {
  display: flex;
  flex-direction: column;
  gap: 20px;
  text-align: start;
}

.grid-item-info-title {
  font-size: 18px;
  font-weight: 700;
  color: #00afe8;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
}

.grid-item-icons {
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-size: 14px;
}

.grid-item-icon {
  display: flex;
  gap: 10px;
  align-items: center;
}

.grid-item-icon span {
  color: #00afe8;
  font-weight: 700;
}

.grid-item-content-doc {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
}

.grid-item-content-img-doc {
  width: 100%;
  height: 300px;
}

.grid-item-content-img-doc img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0px;
}

.grid-item-buttons-text {
  display: flex;
  flex-direction: column;
  font-size: 18px;
}

.grid-item-info-buttons {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  text-align: start;
}

.grid-item-info-button {
  width: 100%;
  cursor: pointer;
  background-color: #00afe8;
  padding: 10px 15px;
  color: #fff;
  border-radius: 5px;
  font-weight: 600;
  font-size: 14px;
  text-overflow: ellipsis;
  transition: all 0.3s ease;
}

.grid-item-info-button:hover {
  background-color: #15c4ff;
}

.grid-item-info-button:nth-child(2) {
  width: 60%;
  cursor: pointer;
  background-color: var(--blueSecundary);
  padding: 10px 15px;
  color: #fff;
  border-radius: 5px;
  font-weight: 600;
  font-size: 14px;
  text-overflow: ellipsis;
  transition: all 0.3s ease;
}

.grid-item-info-button:nth-child(2):hover {
  background-color: #16599d;
}

.grid-item-buttons {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  padding: 0 5px;
}

.grid-item-button {
  width: 100%;
  cursor: pointer;
  border: 1px solid #ccc;
  border-bottom: 0;
  padding: 10px 15px;
  color: #333;
  border-radius: 5px 5px 0 0;
  font-weight: 400;
  font-size: 14px;
}

.grid-item-button.active {
  width: 100%;
  cursor: pointer;
  background-color: #00afe8;
  padding: 10px 15px;
  color: #fff;
  border-radius: 5px 5px 0 0;
  font-weight: 600;
  font-size: 14px;
}

.grid-item-buttons-text p {
  border-top: 3px solid #00afe8;
  padding: 15px;
  font-size: 14px;
  font-weight: 300;
  color: #333;
  text-align: start;
}
@media (max-width: 1000px) {
  .grid-container-doc {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 870px) {
  .grid-container-doc {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 570px) {
  .grid-container-doc {
    grid-template-columns: repeat(1, 1fr);
  }
}

.itemSectionAll {
  display: grid;
  grid-gap: 15px;
  grid-template-columns: repeat(5, 1fr);
}

@media (max-width: 1350px) {
  .itemSectionAll {
    overflow: scroll;
  }
}


@media (max-width: 1000px) {
  .itemSectionAll {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (max-width: 870px) {
  .itemSectionAll {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 670px) {
  .itemSectionAll {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 470px) {
  .itemSectionAll {
    grid-template-columns: repeat(1, 1fr);
  }
}

.itemSection {
  display: flex;
  flex-direction: column;
  gap: 15px;
  border: 3px solid var(--blueSecundary);
  border-radius: 20px;
  position: relative;
  padding: 10px 20px 20px 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
}

.itemSection h2 {
  position: absolute;
  top: 5px;
  left: 15px;
  font-size: 50px;
  color: var(--blueSecundary);
  font-weight: 900;
  line-height: 0.9;
}

.itemNumberTitle {
  text-align: center;
  display: flex;
  align-items: flex-end;
  gap: 10px;
  padding: 8px 0px 0px 40px;
}

.itemNumberTitle h3 {
  font-weight: 800;
  font-size: 18px;
  color: var(--blueSecundary);
}

.itemContent {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.itemContent h5 {
  font-size: 14px;
  padding: 0 !important;
  font-weight: 700;
  color: var(--blueSecundary);
}

.itemSection p {
  font-size: 14px;
}
.itemContent p {
  font-size: 14px;
}

.itemArrow {
  width: 100%;
  position: absolute;
  bottom: -45px;
  right: 0px;
  display: flex;
  justify-content: center;
}

.grid-item-stars {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 20px;
  font-weight: 700;
}

.grid-item-info-doc {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  text-align: start;
}

.grid-item-info-text-doc {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.grid-item-info-doc-card {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
}

.grid-item-link {
  color: #00afe8;
  text-decoration: underline;
  font-size: 14px;
}

.grid-item-icon-doc {
  font-size: 14px;
  display: flex;
  align-items: center;
}

.filter-item-doc {
  margin-top: 25px;
  border: 1px solid #ccc;
  padding: 10px 20px;
  border-radius: 10px;
  width: fit-content;
  display: flex;
  align-items: center;
  gap: 10px;
}
