.about-hotel-detail{
    width: 100%;
    margin: 2rem auto;
}

.about-hotel-detail h3{
    color: var(--bluePrimary);
    border-bottom: solid 4px var(--bluePrimary);
    font-size: 20px;
    margin-bottom: 2rem;
    padding: 1rem;
}

.contain-information-about-hotel p {
    line-height: 21px;
    letter-spacing: .8px;
    text-align: justify;
}
.contain-information-about-hotel p:last-child{
    margin-top: 1rem;
}


@media screen and (max-width:1100px) {
    .about-hotel-detail{
        width: 99%;
    }
}


@media screen and (max-width:600px){
    .contain-information-about-hotel p{
        line-height: 16px;
        letter-spacing: .6px;
        font-size: 13px;
    }
}