.btn-next-prev{
    width: 100%;
    padding: 1rem;
    border: 2px var(--bluePrimary) solid;
    margin-bottom: 2px;
    border-radius: 10px;
    cursor: pointer;
    color: var(--bluePrimary);
    font-weight: 600;
    font-size: 16px;
    transition: .5s ease-in-out;
}

.btn-next-prev:hover{
    transform: scale(1.02);
    background-color: var(--bluePrimary);
    color: white;
    border: none;
}

@media screen and (max-width:800px){
    .layout-one-t{
        width: 100% !important;
    }
}