.itemDetailDoc {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  width: 100%;
  margin-top: 50px;
}

.itemDetailDocInfo {
  width: 60%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.itemDetailDocInfoSectionDoc {
  display: flex;
  gap: 20px;
  border-bottom: 2px solid #00afe8;
  padding-bottom: 20px;
}

.itemDetailDocInfoSectionDocImg {
  width: 250px;
  height: 250px;
  min-width: 250px;
  border-radius: 10px;
  overflow: hidden;
}

.itemDetailDocInfoSectionDocName {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.itemDetailDocInfoSectionDocNameInfo {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.itemDetailDocInfoSectionDocNameInfo h3 {
  font-size: 24px;
  font-weight: 700;
  color: #00afe8;
}

.itemDetailDocInfoSectionDocNameInfo p {
  font-size: 14px;
}

.itemDetailDocInfoSectionDocInfo {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  gap: 10px;
}

.itemDetailDocInfoSectionDocInfoIcon {
  display: flex;
  gap: 6px;
  align-items: center;
  font-size: 14px;
}

.itemDetailDocInfoSectionDocInfoButtons {
  display: flex;
  justify-content: space-between;
  gap: 15px;
}

.itemDetailDocInfoSectionDocInfoButton {
  width: 100%;
  background-color: #00afe8;
  color: #fff;
  font-weight: 700;
  font-size: 14px;
  padding: 10px 15px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 25px;
  cursor: pointer;
}

.itemDetailDocInfoSectionDocInfoButton:nth-child(2) {
  width: 100%;
  background-color: #fff;
  border: 2px solid #00afe8;
  color: #00afe8;
  font-weight: 700;
  font-size: 14px;
  padding: 10px 15px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 25px;
  cursor: pointer;
}

.menuItemDetail {
  list-style-type: none;
  padding: 0;
  display: flex;
  justify-content: start;
  gap: 10px;
}

.menuItemDetail li a {
  color: #333;
  text-decoration: none;
  padding: 5px 20px;
  display: block;
  font-size: 14px;
  transition: all 0.2s ease;
}

.menuItemDetail li a:hover {
  color: #00afe8;
}

.itemDetailDocInfoSectionDocConsult {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
}

.itemDetailDocInfoSectionDocProcess {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
}

.itemDetailDocInfoSectionDocProcess h3 {
  color: #fff;
  font-size: 18px;
  font-weight: 700;
}

.itemDetailDocInfoSectionDocConsultName {
  background-color: #00afe8;
  padding: 10px 10px 10px 15px;
  border-radius: 8px;
}

.itemDetailDocInfoSectionDocConsultName h3 {
  color: #fff;
  font-size: 18px;
  font-weight: 700;
}

.itemDetailDocInfoSectionDocConsult h3 {
  color: #fff;
  font-size: 18px;
  font-weight: 700;
}

.itemDetailDocInfoSectionDocConsultItems {
  list-style-type: none;
  display: flex;
  gap: 10px;
  margin: 10px 0;
}

.itemDetailDocInfoSectionDocConsultItemsButton {
  border: 1px solid #dadada;
  padding: 8px 20px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  font-weight: 700;
  color: #333;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2 ease;
}

.itemDetailDocInfoSectionDocConsultItemsButton.active {
  background-color: #00afe8;
  color: #fff;
}

.itemDetailDocInfoSectionDocInfoIcons {
  display: flex;
  gap: 10px;
  align-items: center;
  margin: 10px 0;
  font-size: 14px;
}

.itemDetailDocInfoSectionDocConsultLabel {
  font-size: 18px;
  font-weight: 600;
  color: #00afe8;
  margin-top: 10px;
}

.itemDetailDocInfoSectionDocProcessIcons {
  display: grid;
  grid-gap: 15px;
  grid-template-columns: repeat(3, 1fr);
  margin: 10px 0;
  font-size: 14px;
}

.itemDetailDocInfoSectionDocInfoProcessIcon {
  width: fit-content;
  display: flex;
  gap: 6px;
  align-items: center;
  font-size: 14px;
}

.itemDetailDocInfoSectionDocAbout {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding-bottom: 20px;
  border-bottom: 2px solid #00afe8;
}

.itemDetailDocInfoSectionDocAboutDesc {
  font-size: 14px;
  margin-left: 10px;
}

.itemDetailDocInfoSectionDocAbout p {
  font-size: 14px;
  margin-left: 10px;
}

.itemDetailDocInfoSectionDocAbout label {
  margin-left: 10px;
}

.itemDetailDocInfoSectionDocShowMore {
  display: flex;
  gap: 8px;
  align-items: center;
  margin: 0 auto;
  margin-top: 5px;
  cursor: pointer;
}

.itemDetailDocInfoSectionDocComment {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.itemDetailDocInfoSectionDocCommentCount {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.itemDetailDocInfoSectionDocCommentCountItemOne {
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.itemDetailDocInfoSectionDocCommentCountItemOne h5 {
  font-size: 18px;
  font-weight: 600;
  color: #00afe8;
  margin-top: 20px;
  padding: 0px !important;
}

.itemDetailDocInfoSectionDocCommentCountButton {
  border: 1px solid #dadada;
  padding: 10px 15px;
  border-radius: 8px;
  height: fit-content;
  margin-right: 10px;
  font-size: 14px;
  font-weight: 700;
  color: #00afe8;
  cursor: pointer;
  transition: all 0.3s ease;
}

.itemDetailDocInfoSectionDocCommentCountButton:hover {
  background-color: #00aee80c;
}

.itemDetailDocInfoSectionDocCommentCountItemOneCommentsButtonB {
  border: 1px solid #dadada;
  padding: 10px 15px;
  border-radius: 8px;
  margin-right: 10px;
  font-size: 14px;
  font-weight: 700;
  color: #00afe8;
  cursor: pointer;
  transition: all 0.3s ease;
}

.itemDetailDocInfoSectionDocCommentCountItemOneCommentsButtonB:hover {
  background-color: #00aee80c;
}

.itemDetailDocInfoSectionDocCommentCountItemOneCommentsButton {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}
.itemDetailDocInfoSectionDocCommentCountItemOneComments {
  width: 100%;
  background-color: #f4f4f4;
  padding: 10px;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.itemDetailDocInfoSectionDocCommentCountItemOneComments input {
  width: 100%;
  background-color: transparent;
  border: none;
  font-size: 14px;
  font-weight: 400;
}

.itemDetailDocInfoSectionDocCommentUser {
  display: flex;
  gap: 20px;
  margin-top: 30px;
  padding-bottom: 30px;
  border-bottom: 2px solid #00afe8;
}

.itemDetailDocInfoSectionDocCommentUserInfo {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.itemDetailDocInfoSectionDocCommentUserInfo p {
  font-size: 14px;
}

.itemDetailDocInfoSectionDocCommentUserImage {
  width: 60px;
  height: 60px;
  min-width: 60px;
  min-height: 60px;
  border-radius: 50%;
  overflow: hidden;
}

.itemDetailDocInfoSectionDocCommentUserImage img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.itemDetailDocInfoSectionDocCommentUserInfoName h3 {
  font-size: 18px;
  font-weight: 500;
}

.itemDetailDocInfoSectionDocCommentUserInfoName p {
  font-size: 14px;
  font-weight: 300;
}

.itemDetailDocInfoSectionDocCommentUserInfoComment {
  font-size: 14px;
}

.itemDetailDocInfoSectionDocCommentUserInfoCommentView {
  background-color: #f4f4f4;
  padding: 15px 20px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  border-radius: 8px;
}

.itemDetailDocInfoSectionDocCommentUserInfoCommentView h3 {
  font-size: 18px;
  font-weight: 500;
}

.itemDetailDocInfoSectionDocCommentUserInfoCommentView p {
  font-size: 14px;
  font-weight: 300;
}

.itemDetailDocCalendarSection {
  width: 40%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.itemDetailDocCalendar {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-bottom: 20px;
  height: fit-content;
}

.itemDetailDocCalendarTitle {
  background-color: #00afe8;
  color: #fff;
  padding: 10px 10px 10px 15px;
  border-radius: 8px;
}
.itemDetailDocCalendarList {
  list-style: none;
  display: flex;
  gap: 30px;
  border-bottom: 1px solid #00afe8;
}

.itemDetailDocCalendarListButton {
  display: flex;
  gap: 10px;
  align-items: center;
  color: #333;
  cursor: pointer;
  height: 100%;
  border-bottom: 3px solid #fff;
  padding-bottom: 10px;
}

.itemDetailDocCalendarListButton.active {
  display: flex;
  gap: 10px;
  align-items: center;
  color: #333;
  border-bottom: 3px solid #00afe8;
  padding-bottom: 10px;
  height: 100%;
}

.itemDetailDocCalendarSelect {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.itemDetailDocCalendarDayHour {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.itemDetailDocCalendarDays {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  font-size: 14px;
}

.itemDetailDocCalendarDays span {
  font-size: 12px;
  font-weight: 300;
}

.itemDetailDocCalendarHour {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  font-size: 14px;
}

.itemDetailDocCalendarHourNow {
  width: 100%;
  background-color: #ededed;
  padding: 6px 10px;
  border-radius: 5px;
  text-align: center;
}

@media (max-width: 1000px) {
  .itemDetailDoc {
    flex-direction: column-reverse;
  }

  .itemDetailDocInfo {
    width: 100%;
  }

  .itemDetailDocCalendarSection {
    width: 100%;
  }
}

@media (max-width: 715px) {
  .itemDetailDocInfoSectionDocInfoButtons {
    flex-direction: column;
  }
}

@media (max-width: 550px) {
  .itemDetailDocInfoSectionDoc {
    flex-direction: column;
  }

  .itemDetailDocInfoSectionDocImg {
    width: 100%;
    height: 350px;
  }

  .menuItemDetail {
    flex-wrap: wrap;
  }

  .itemDetailDocCalendarDays {
    flex-wrap: wrap;
  }

  .itemDetailDocCalendarHour {
    flex-wrap: wrap;
  }

  .itemDetailDocCalendarHourNow {
    width: fit-content;
  }

  .itemDetailDocInfoSectionDocProcessIcons {
    grid-template-columns: repeat(2, 1fr);
  }

  .itemDetailDocInfoSectionDocCommentCount {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .itemDetailDocInfoSectionDocCommentCountItemOne {
    width: 100%;
  }

  .itemDetailDocInfoSectionDocConsultItems {
    flex-wrap: wrap;
  }
}

@media (max-width: 350px) {
  .itemDetailDocInfoSectionDocProcessIcons {
    grid-template-columns: repeat(1, 1fr);
  }
}
