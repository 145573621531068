.homePrimarySection {
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.homePrimarySectionLeft {
  transition: all ease 200ms;
  background-color: #104172;
  width: 60%;
  height: 100%;
  overflow: hidden;
  position: relative;
}

.homePrimarySectionLeftInfo {
  width: 100%;
  height: 100%;
  padding: 80px 60px 100px 60px;
  display: flex;
  flex-direction: column;
  position: absolute;
  transition: all ease 200ms;
}

.homePrimarySectionLeftInfoText {
  color: var(--white);
  font-size: 40px;
  font-weight: bolder;
  font-family: 'Montserrat', sans-serif;
  width: 60%;
  text-shadow: 2px 2px 6px rgba(0, 0, 0, 0.75);
}

.homePrimarySectionLeftInfoBtn {
  margin-top: 20px;
  height: 40px;
  min-height: 40px;
  width: 120px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--bluePrimary);
  transition: all ease 200ms;
  cursor: pointer;
}

.homePrimarySectionLeftInfoBtnText {
  color: var(--white);
  text-align: center;
  font-size: 14px;
  font-weight: bolder;
  font-family: 'Montserrat', sans-serif;
  transition: all ease 200ms;
}

.homePrimarySectionLeftInfoBtn:hover {
  background: var(--white);
  border: solid 2px var(--bluePrimary);
}
.homePrimarySectionLeftInfoBtn:hover .homePrimarySectionLeftInfoBtnText{
  color: var(--bluePrimary);
}

.homePrimarySectionLeftInfoBtn:active {
  transform: translateY(2px);
}


.homePrimarySectionImg {
  transition: all ease 200ms;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.homePrimarySectionLeft:hover .homePrimarySectionImg {
  /* -webkit-transform:scale(1.1);
    transform:scale(1.1); */
}

.homePrimarySectionRight {
  transition: all ease 200ms;
  background-color: #104172;
  width: 40%;
}

.homePrimarySectionRightCardContainer {
  transition: all ease 200ms;
  flex-wrap: wrap;
  gap: 1.5rem;
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  align-content: center;
  margin: 0 25% 0 5%;
}

.homePrimarySectionRightCard {
  transition: all ease 200ms;
  width: 45%;
  height: 120px;
  max-height: 120px;
  cursor: pointer;
  overflow: hidden;
  filter: grayscale(80%);
}

.homePrimarySectionRightCardOn {
  filter: grayscale(0%);
}

.homePrimarySectionRightCardImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.homePrimarySectionRightCard:hover {
  -webkit-box-shadow: -2px 10px 14px -3px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -2px 10px 14px -3px rgba(0, 0, 0, 0.75);
  box-shadow: -2px 10px 14px -3px rgba(0, 0, 0, 0.75);
  scale: 1.01;
  transform: translateY(-2px);
}

@media screen and (max-width: 1100px) {
  .homePrimarySectionRightCardContainer {
    margin: 0 5% 0 5%;
  }

  .homePrimarySectionLeftInfo {
    padding: 60px 40px 50px 40px;
  }
}


@media screen and (max-width: 920px) {
  .homePrimarySectionLeftInfoText {
    width: 90%;
  }
}



@media screen and (max-width: 768px) {
  .homePrimarySectionRight {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: transparent;
  }

  .homePrimarySectionLeftInfo{
    display: none;
  }

  .homePrimarySectionLeft {
    width: 100%;
  }

  .homePrimarySectionImg {
    opacity: 0.25;
  }
}


