@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    padding-right: 0 !important;
}

.customScroll::-webkit-scrollbar {
    display: unset !important;
    width: 8px;
    height: 10px;
  }
  
.customScroll::-webkit-scrollbar-track {
    background: #f3f4f6;
    border-radius: 4px;
}

.customScroll::-webkit-scrollbar-thumb {
    background-color: #004274;
    border-radius: 4px;
    border: 2px solid #f3f4f6;
}

.customScroll::-webkit-scrollbar-thumb:hover {
    background-color: #185897;
}