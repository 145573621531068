.contain-card-contact {
    max-width: 1280px;
    width: 95%;
    margin: 3rem auto 2rem auto;
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    flex-wrap: wrap;
}




/* -----------------------CARDS SMALL -------------------------*/
.contain-card-contact .card-small {
    width: 32%;
    display: flex;
    flex-direction: column;
    padding: 1rem 1rem 1rem 1.5rem;
    justify-content: space-between;
    border-radius: 16px;
    box-shadow: 0px 4px 4px 0px #00000040;
    height: 254px;
}


.contain-card-contact .card-small .heade {
    display: flex;
    align-items: center;
    gap: 10px;
}

.contain-card-contact .card-small .heade h2 {
    font-size: 24px;
    font-weight: 700;
    color: var(--bluePrimary);
}

.contain-card-contact .card-small .heade img {
    width: 27px;
    height: 27px;
}


.contain-card-contact .card-small .core {
    text-align: justify;
    font-size: 12px;
}

.contain-card-contact .card-small .foo {
    color: white;
    height: 46px;
    background-color: var(--bluePrimary);
    border-radius: 8px;
    cursor: pointer;
    text-align: center;
    font-size: 12px;
    margin: 0 auto;
    font-weight: 700;
    width: 100%;
    max-width: 170px;
    border: 2px solid var(--bluePrimary);
    transition: color 0.5s;
    line-height: 2.5em;
    position: relative;
    overflow: hidden;
    z-index: 1;
    scrollbar-width: none;
}

.contain-card-contact .card-small .foo::-webkit-scrollbar {
    width: 0px;
    background: transparent;
}


.contain-card-contact .card-small .foo:before {
    content: "";
    position: absolute;
    z-index: -1;
    background: white;
    color: var(--bluePrimary);
    height: 550px;
    width: 1500px;
    border-radius: 50%;
}

.contain-card-contact .card-small .foo:hover {
    color: var(--bluePrimary);
}

.contain-card-contact .card-small .foo:before {
    top: 100%;
    left: 100%;
    transition: all 0.7s;
}

.contain-card-contact .card-small .foo:hover:before {
    top: -100px;
    left: -200px;
}

.contain-card-contact .card-small .foo:active:before {
    background: white;
    transition: background 0s;
}




/* -------------------- CARD BIG ------------------------ */

.contain-card-contact .card-big {
    width: 66%;
    display: flex;
    border-radius: 16px;
    background-color: var(--bluePrimary);
    box-shadow: 0px 4px 4px 0px #00000040;
    height: 254px;
}


.contain-card-contact .card-big .infor {
    width: 49%;
    padding: 3rem 1rem;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    gap: 1rem;
}

.contain-card-contact .card-big .infor h2 {
    font-size: 26px;
    font-weight: 900;
    color: white;
}

.contain-card-contact .card-big .infor p {
    font-size: 12px;
    overflow: auto;
    color: white;
    scrollbar-width: none;
}

.contain-card-contact .card-big .infor p::-webkit-scrollbar {
    width: 0px;
    background: transparent;
}

.contain-card-contact .card-big img {
    width: 49%;
    height: 312px;
    object-fit: cover;
    max-width: 328px;
    margin-top: -58px;
    margin-left: auto;
    margin-right: auto;
}



/* ----------------------- OFERTAS EMAIL ---------------------- */


.offerst-mail {
    width: 95%;
    max-width: 700px;
    margin: 7rem auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: .5rem;
}



.offerst-mail .cont-input div h2 {
    font-size: 24px;
    font-weight: 700;
    color: var(--bluePrimary);
    position: absolute;
    width: 404px;
    top: -33px
}

.offerst-mail .cont-input {
    display: flex;
    align-items: center;
    width: 100%;
    gap: .5rem;
}

.offerst-mail .cont-input div {
    display: flex;
    flex-direction: column;
    position: relative;
    gap: .5rem;
    max-width: 451px;
    width: 100%;
}

.offerst-mail .cont-input input {
    width: 100%;
    border: 1px solid #D9D9D9;
    height: 46px;
    padding: .4rem;
    font-size: 12px;
    border-radius: 8px;
}

.offerst-mail .cont-input button {
    background-color: var(--bluePrimary);
    height: 46px;
    color: white;
    border-radius: 8px;
    font-size: 12px;
    font-weight: 700;
    max-width: 170px;
    width: 100%;
    cursor: pointer;
    transition: color 0.5s;
    line-height: 2.5em;
    position: relative;
    border: 2px solid var(--bluePrimary);
    overflow: hidden;
    z-index: 1;
}

.offerst-mail .cont-input button:before {
    content: "";
    position: absolute;
    z-index: -1;
    background: white;
    color: var(--bluePrimary);
    height: 550px;
    width: 1500px;
    border-radius: 50%;
}

.offerst-mail .cont-input button:hover {
    color: var(--bluePrimary);
}

.offerst-mail .cont-input button:before {
    top: 100%;
    left: 100%;
    transition: all 0.7s;
}

.offerst-mail .cont-input button:hover:before {
    top: -100px;
    left: -200px;
}

.offerst-mail .cont-input button:active:before {
    background: white;
    transition: background 0s;
}



.offerst-mail .cont-input img {
    width: 45px;
    height: 35px;
}



.offerst-mail .cont-input div p {
    font-size: 12px;
    width: 450px;
    position: absolute;
    top: 51px
}

.offerst-mail .cont-input div p b {
    color: var(--bluePrimary);
}

/* --------------------- RESPONSIVE ---------------------- */


@media screen and (max-width: 840px) {
    .contain-card-contact .card-small {
        width: 48%;
    }

    .contain-card-contact .card-big {
        width: 100%;
    }

}

@media screen and (max-width: 530px) {
    .offerst-mail .cont-input div h2 {
        width: 90vw;
        left: -50px;
    }

    .offerst-mail .cont-input div p {
        width: 90vw;
        left: -50px;
    }
}

@media screen and (max-width: 500px) {
    .contain-card-contact {
        gap: 1.5rem;
    }

    .contain-card-contact .card-small {
        width: 100%;
        height: fit-content;
        gap: 2rem;
    }

    .contain-card-contact .card-big {
        height: fit-content;
    }

    .contain-card-contact .card-big img {
        display: none;
    }

    .contain-card-contact .card-big .infor {
        width: 100%;
    }
}

@media screen and (max-width: 430px) {
    .offerst-mail .cont-input div h2 {
        font-size: 19px;
    }
}