.flightSelectedDetail {
  width: 100%;
  max-width: 1280px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.content-search-fligth-result {
  height: fit-content;
}

.searchMenuScrolled {
  width: 100%;
  transition: all 0.3s ease;
  background-color: #fff;
}
.searchMenuScrolled.active {
  width: 100%;
  box-shadow: 0px 30px 30px rgb(0 0 0 / 2%);
  position: sticky;
  top: 0;
  z-index: 9999;
  border-bottom: 1px solid #d9d9d9;
  padding: 1px 0px 15px 0px;
}

.searchMenuScrolled.hidden {
  width: 100%;
  box-shadow: 0px 30px 30px rgb(0 0 0 / 2%);
  position: sticky;
  top: -120px;
  z-index: 9999;
  border-bottom: 1px solid #d9d9d9;
  padding: 1px 0px 15px 0px;
}

.searchMenuScrolledMaxWidth {
  width: 95%;
  max-width: 1280px;
  margin: 1rem auto;
  transition: all 0.3s ease;
}

.buttonArrowSearchScrolled {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  rotate: 180deg;
  transition: all 0.3s ease;
  cursor: pointer;
  color: #104172;
}

.buttonArrowSearchScrolled.active {
  rotate: 0deg;
}

@media screen and (max-width: 900px) {
  .searchMenuScrolled.hidden {
    width: 100%;
    box-shadow: 0px 30px 30px rgb(0 0 0 / 2%);
    position: sticky;
    top: -270px;
    z-index: 9999;
    border-bottom: 1px solid #d9d9d9;
    padding: 1px 0px 15px 0px;
  }
}

@media screen and (max-width: 550px) {
  .searchMenuScrolled.hidden {
    width: 100%;
    box-shadow: 0px 30px 30px rgb(0 0 0 / 2%);
    position: sticky;
    top: -390px;
    z-index: 9999;
    border-bottom: 1px solid #d9d9d9;
    padding: 1px 0px 15px 0px;
  }
}

@media screen and (max-width: 471px) {
  .searchMenuScrolled.hidden {
    width: 100%;
    box-shadow: 0px 30px 30px rgb(0 0 0 / 2%);
    position: sticky;
    top: -425px;
    z-index: 9999;
    border-bottom: 1px solid #d9d9d9;
    padding: 1px 0px 15px 0px;
  }
}

@media screen and (max-width: 244px) {
  .searchMenuScrolled.hidden {
    width: 100%;
    box-shadow: 0px 30px 30px rgb(0 0 0 / 2%);
    position: sticky;
    top: -440px;
    z-index: 9999;
    border-bottom: 1px solid #d9d9d9;
    padding: 1px 0px 15px 0px;
  }
}
