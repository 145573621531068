.content-shop-wellezy{
    width: 80%;
    margin: 2rem auto;
}

.content-shop-wellezy .banner {
    width: 100%;
    height: 500px;
    border-radius: 10px;
}

.content-shop-wellezy .banner img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
    border-radius: 10px;
}

.content-shop-wellezy .title{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: center;
    align-items: center;
    margin: 3rem 0;
}

.content-shop-wellezy .title p{
    color: var(--bluePrimary);
}

.content-shop-wellezy .title p span{
    text-transform: uppercase;
    font-weight: 700;
}


.content-cards{
    display: grid;
    text-align: center;
    grid-template-columns: repeat(auto-fit,minmax(200px, 9fr));;
    grid-column-gap: 0px;
    grid-row-gap: 30px;
    margin: 5rem 0;
    justify-items: center;
}



.content-cards .icons{
    padding: 1rem 2rem;
    border-radius: 50%;
    border: solid 1px #D9D9D9;
    transition: .5s ease-in-out;
    display: flex;
    flex-direction: column;
    max-width: 150px;
    overflow: hidden;
    text-align: center;
    align-items: center;
    color: var(--bluePrimary);
}

.content-cards .icons:hover{
    cursor: pointer;
    transform: scale(1.03);
    background-color: var(--bluePrimary);
    color: #ffffff;
}

.content-cards .icons:hover svg{
    fill: #ffffff !important;
}

@media screen and (max-width: 1100px) {
    .content-shop-wellezy {
        width: 98%;
    }
    
}