.contain-paginate-hotels {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin: 2rem auto;
}

.contain-paginate-hotels-buttons {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin: auto;
}

.contain-button-current {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #004274;
  color: white;
  border-color: white;
  font-size: 20px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.contain-button-current.active {
  background-color: white;
  color: #004274;
}

.contain-button-next {
  color: #00427474;
  display: flex;
  align-items: center;
  position: relative;
}

.contain-button-next.active {
  cursor: pointer;
  color: #004274;
}

.contain-button-prev.active {
  cursor: pointer;
  color: #004274;
}

.contain-button-prev {
  color: #00427474;
  display: flex;
  align-items: center;
  position: relative;
}

.contain-button-skip {
  padding: 0 10px;
  height: 40px;
  border-radius: 5px;
  border: 2px solid #004274;
  color: #004274;
  font-size: 20px;
  cursor: pointer;
}
