.inputSearchEspecialties{
    cursor: pointer;
}

.containerInputSearch{
    position: relative;
}

.containerInputSearch > div{
    top: 35px !important;
}

.containerInputSearch > div > ul > li{
    padding-block: 10px !important;
}

.containerInputSearch > div > ul > li > span{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    max-width: 100%;
    color: var(--black);
    font-weight: 400;
}