.flight-going-airline .scaleName{
    margin-right: 14% !important;
}
.name-flight-going-airline {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.name-flight-going-airline p {
    font-family: 'Montserrat', sans-serif;
    margin-left: 5px;
    text-align: center;
    cursor: pointer; 
}
.scaleNameHover:hover{
    color: #104172;
   
}
.itemFontSegmentDate{
    font-size: 28px;
    font-family: 'Inter' !important;
    font-weight: 200;
}
.scaleName{
    font-family: 'Inter' !important;
    font-weight: 300;
}