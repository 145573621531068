.initialRoomPX {
  background-color: #24344c;
  height: 100vh;
  width: 100vw;
  position: relative;
}


/****************/


.formNamePX {
  display: flex;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.25);
  padding: 40px;
  width: 60%;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.formNamePX .formNamePXTitlePX {
  width: 100%;
  font-size: 32px;
  color: var(--white);
  font-family: 'Montserrat', sans-serif;
  font-weight: bolder;
  text-align: center;
  margin-bottom: 20px;
}

.formNamePX .formNamePXSubtitlePX {
  text-align: center;
  width: 100%;
  font-size: 14px;
  color: var(--white);
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  margin-bottom: 20px;
}

.formNamePX .formNamePXSubtitleBtnPX {
  margin-top: 40px;
  padding: 10px 0px;
  width: 60%;
  border-radius: 12px;
  cursor: pointer;
  transition: all ease 200ms;
  background-color: var(--bluePrimary);
}

.formNamePX .formNamePXSubtitleBtnTextPX {
  text-align: center;
  width: 100%;
  font-size: 14px;
  color: var(--white);
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
}