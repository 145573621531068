.layoutMax {
  width: 95%;
  max-width: 1280px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.detailTour {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 50px;
}

.containImgPrincipalHotel {
  width: 100%;
  height: 100%;
  height: 400px;
  display: flex;
  justify-content: space-between;
  gap: 10px;
  cursor: pointer;
  margin-bottom: 15px;
}

.sectionImgBig {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.sectionImg1 {
  width: 50%;
  height: 100%;
  border-radius: 10px;
  overflow: hidden;
}

.sectionImg2 {
  width: 50%;
  height: 100%;
  border-radius: 10px;
  overflow: hidden;
}

.sectionImgLayoutFor {
  width: 35%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.sectionImgLayout1 {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  overflow: hidden;
}

.sectionImgLayout1 {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  overflow: hidden;
}

.imageHotelDetail {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.containTitleHotel {
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding-top: 20px;
}

.title {
  display: flex;
  flex-direction: column;
  gap: 3px;
  color: var(--bluePrimary);
}

.title h2 {
  font-size: 26px;
  font-weight: 700;
  text-transform: uppercase;
}

.title span {
  font-size: 18px;
  font-weight: 500;
}

.informationTour {
  display: flex;
  justify-content: space-between;
  gap: 30px;
}

.information {
  width: 100%;
  font-weight: 400;
}

.information p {
  color: var(--blackGray);
}

.informationImage {
  width: 100%;
  display: flex;
  align-items: flex-start;
}

.informationImage img {
  width: 500px;
  height: 500px;
  border-radius: 10px;
  box-shadow: var(--shawdowPrimary);
  object-fit: cover;
}

.html h4{
  font-weight: lighter !important;
  font-size: 16px !important;
  color: var(--blackGray);
}

.html ul li{
  font-weight: 400 !important;
  font-size: 16px !important;
  list-style: none;
  color: var(--blackGray);
}

.informationContent {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.information h2 {
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
  color: var(--bluePrimary);
}

.information span {
  font-size: 18px;
  font-weight: 500;
  color: var(--blackGray);
}

.imagesGallery {
  width: 100%;
}

.city {
  font-size: 14px;
  color: var(--blackGray);
}

.items {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  color: var(--bluePrimary);
}

.item {
  display: flex;
  gap: 10px;
  align-items: center;
}

.itemContent p {
  color: var(--blackGray);
  font-weight: 500;
}

@media screen and (max-width: 1100px) {
  .containImgPrincipalHotel {
    width: 99%;
  }
}

@media screen and (max-width: 600px) {
  .containImgPrincipalHotel {
    height: 100%;
    flex-direction: column;
  }
  .sectionImgBig,
  .sectionImgLayoutFor {
    width: 100%;
  }

  .sectionImg1 {
    width: 50%;
    height: auto;
    border-radius: 10px;
    overflow: hidden;
  }

  .sectionImg2 {
    width: 50%;
    height: auto;
    border-radius: 10px;
    overflow: hidden;
  }

  .sectionImgLayout1 {
    width: 100%;
    height: auto;
    border-radius: 10px;
    overflow: hidden;
  }

  .sectionImgLayout1 {
    width: 100%;
    height: auto;
    border-radius: 10px;
    overflow: hidden;
  }
}


