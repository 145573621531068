.container{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: start;
}

.containerBorder{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: start;
    border-right: 1px solid var(--bluePrimary);
    padding-right: 20px;
}

.title{
    font-weight: bold;
    color: var(--bluePrimary);
    font-size: var(--sizeTextPrimary);
    transform: skew(-20deg);
    text-wrap: nowrap;
}

.data{
    margin-top: 5px;
    font-size: var(--sizeTextSecundary);
    transform: skew(-20deg);
    text-wrap: nowrap;
}