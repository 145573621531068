.calendar-button{
    cursor: pointer;
    text-align: left;
    cursor: pointer;
    height: 32px;
    background: url("../../../../ui/assets/iconsSVG/IconCalendar4.svg") no-repeat;
    background-position: right 10px center;
}

.place-date-day{
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-block: 10px;
}
