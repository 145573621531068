.contain-banner-specialist{
    max-width: 1280px;
    width: 100%;
    margin: 2rem auto;
    position: relative;
}

.contain-banner-specialist img {
    width: 100%;
    height: 600px;
    object-fit: cover;
    border-radius: 10px;
}

.effect-black-specialist{
    position: absolute;
    border-radius: 10px;
    top: 0px;
    width: 100%;
    height:99%;
    background-color: rgba(0, 0, 0, 0.238);
    display: flex;
    justify-content: end;
    align-items: end;
}

.text-Banner-specialist{
    text-align: center;
    margin-bottom: 6rem;
    margin-right: 3rem;
    width: 50%;
    max-width: 400px;
    color: white;
}

.text-Banner-specialist img {
    width: 100%;
    height: auto;
}