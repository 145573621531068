.contentView {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 40px;
    margin-top: 20px;
}

.contentTitle {
    display: flex;
    align-items: center;
    gap: 20px;
    color: var(--bluePrimary);
}

.contentTitleText {
    font-size: 28px;
    font-weight: 800;
}

.contentTitleText p {
    font-size: 24px;
    font-weight: 600;
}

.contentInputs {
    display: flex;
    gap: 20px;
    align-items: flex-end;
    justify-content: space-between;
}

.contentInput {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
}

.contentInput label {
    font-size: 18px;
    font-weight: 600;
    color: var(--bluePrimary);
}

.contentInput input {
    padding: 10px;
    border: 1px solid var(--greyPrimary);
    border-radius: 8px;
    outline: none;
}

.buttonSearch {
    width: 50%;
    background-color: var(--bluePrimary);
    padding: 10px 20px;
    border-radius: 8px;
    color: var(--white);
}

.finalBuy {
    background-color: var(--blueWhiteTableThead);
    padding: 20px;
    width: 100%;
    display: grid;
    place-items: center;
    margin-bottom: 50px;
}

.finalBuyMax {
    max-width: 1280px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.finalBuyMax h2 {
    color: var(--bluePrimary);
    font-size: 18px;
}

.infoFinalBuy {
    display: flex;
    justify-content: space-between;
    gap: 50px;
    align-items: center;
    padding-left: 30px;
    border-left: 2px solid var(--bluePrimary);
}

.infoFinalBuyDescrip {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.infoFinalBuyDescrip p {
    font-size: 14px;
    color: var(--blackGray);
    font-weight: 500;
}

.infoFinalBuyDescrip h2 {
    color: var(--bluePrimary);
    font-size: 18px;
    font-weight: 700;
}

.buttonFinalBuy {
    background-color: var(--bluePrimary);
    padding: 10px 50px;
    border-radius: 8px;
    color: var(--white);
    cursor: pointer;
}