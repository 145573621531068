.card-global-search{
    display: flex;
    border-radius: 10px;
    background-color: white;
    -webkit-box-shadow: 5px 5px 14px -6px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 5px 5px 14px -6px rgba(0, 0, 0, 0.75);
    box-shadow: 5px 5px 14px -6px rgba(0, 0, 0, 0.75);
    width: 100%;
}


.img-card-global{
    width: 30%;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    max-height: 270px;
    object-fit: cover;
    object-position: top;
}

.card-global-info{
    width: 70%;
    padding: .6rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.dir-card-global{
    color: rgb(116, 116, 116);
    font-size: 12px;
    margin-bottom: 1rem;
}

.card-global-categories{
    display: flex;
    flex-direction: column;
}

.card-global-categories button{
    background-color: #004274;
    padding: .7rem;
    font-size: 18px;
    color: white;
    font-weight: 700;
    border-radius: 10px;
    cursor: pointer;
    width: 100%;
}

.content-stars-card-global{

    display: flex;
    gap: 3px;
    margin: 1rem 0;
}


.content-stars-card-global li {
    list-style: circle !important;
}

@media screen and (max-width:600px) {
    .card-global-search{
        flex-direction: column;
    }
    .img-card-global{
        width: 100%;
    }
    .card-global-info{
        width: 100%;
    }
}