.passengerDetails {
    position: absolute;
    width: 300px;
    height: 300px;
    background-color: white;
    border-radius: 20px;
    box-shadow: -4px 4px 4px rgba(0, 0, 0, 0.25) !important;
    top: 0px;
    left: -100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 4;
}
.passengerDetails-s{
    position: absolute;
    width: 300px;
    height: 300px;
    background-color: white;
    border-radius: 20px;
    box-shadow: -4px 4px 4px rgba(0, 0, 0, 0.25) !important;
    top: 0px;
    left: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 4;
}
.passengerDetailsValidateOne {
    position: absolute;
    width: 300px;
    height: 360px;
    background-color: white;
    border-radius: 20px;
    box-shadow: -4px 4px 4px rgba(0, 0, 0, 0.25) !important;
    top: 0px;
    left: 0px;;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 4;
}
.passengerDetailsValidateTwo{
    position: absolute;
    width: 300px;
    height: 405px;
    background-color: white;
    border-radius: 20px;
    box-shadow: -4px 4px 4px rgba(0, 0, 0, 0.25) !important;
    top: 0px;
    left: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 4;
}


.alertPassengerDetails{
    color: red;
    margin-bottom: 5px;
    font-family: 'Inter';
    font-size: 14px;
}


.passengerDetails_container {
    width: 80%;
}
.passengerDetails h2 {
    font-family: 'Oswald';
    font-size: 28px;
    color: #004274;
    text-transform: uppercase;
    margin-top: 20px;
    margin-bottom: 5px;
}
.principalDetails_p {
    font-family: 'Inter';
    font-size: 20px;
    text-transform: capitalize;
    font-weight: 500;
    color: black;
}
.sencondaryDetails_p {
    font-family: 'Inter';
    font-size: 9px;
    text-transform: capitalize;
    font-weight: 600;
    opacity: .5;
    color: black;
}
.principalDetails_miniButtons {
    width: 35px;
    height: 32px;
    background-color: #004274;
    border-radius: 10px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: none;
}
.principalDetails_miniButtons p {
    font-family: 'Inter';
    font-weight: 600;
    color: white;
    font-size: 20px;
}
.passagersDetails_ {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
}
.passengerDetails_miniButtons {
    display: flex;
    align-items: center;
    width: 120px;
    justify-content: space-between;
}
.passagersDetails_numButton p {
    font-family: 'Inter';
    font-size: 20px;
    font-weight: 600;
    color: black;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.passengerDetails_class {
    display: flex;
    width: 100%;
    align-items: center;
    height: 40px;
}
.passengerDetails_class div {
    display: flex;
    align-items: center;
}
.passengerDetails_class p {
    font-family: 'Inter';
    font-size: 20px;
    text-transform: capitalize;
    font-weight: 400;
    margin-left: 10px;
}
.passengerDetails_button {
    margin-top: 10px;
}
.passengerDetails_button button {
    width: 100%;
    height: 50px;
    background-color: #004274;
    border: none;
    border-radius: 20px;
    box-shadow: -4px 4px 4px rgba(0, 0, 0, 0.25) !important;
    color: white;
    font-family: 'Inter';
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 600;
    cursor: pointer;
}

@media screen and (max-width: 900px) {
    .passengerDetails {
        right: 0px;
    }
}
@media screen and (max-width: 600px) {
    .passengerDetails {
        top: 110px;
    }
}