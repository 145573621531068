.content-all-result-torism {
  width: 95%;
  margin: 2rem auto;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.layout-result-filter {
  display: flex;
  justify-content: space-between;
}

.phrace-result-search,
.title-result-search {
  color: var(--bluePrimary);
}

.all-result-search-tourism {
  width: 70%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.card-pack-interest-r {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  border-radius: 13px;
  border: 1px solid #d9d9d9;
}

.card-pack-interest-r .part-one-r {
  display: flex;
  justify-content: space-between;
  height: 220px;
}

.part-one-r .content-img-interest-r {
  width: 49.5%;
  height: 220px;
  border-radius: 1.3rem;
}

.part-one-r .content-img-interest-r img {
  width: 100%;
  border-bottom-right-radius: 1.3rem;
  border-top-left-radius: 1.3rem;
  border-bottom-left-radius: 0;
  border-top-right-radius: 0;
  object-fit: cover;
  height: 100%;
}

.part-one-r .content-info-r {
  width: 49.5%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  height: 220px;
  overflow: scroll;
  justify-content: space-between;
}

.part-one-r .content-info-r h3 {
  font-size: 16px;
}

.part-one-r .content-info-r .content-icons-r {
  display: flex;
  align-items: center;
}

.part-one-r .content-info-r .content-btns-r {
  display: flex;
  justify-content: space-between;
}

.part-one-r .content-info-r .content-btns-r button {
  width: 49.5%;
  padding: 0.5rem 1rem;
  color: white;
  font-weight: 600;
  font-size: 15px;
  border-radius: 8px;
  cursor: pointer;
}

.part-one-r .content-info-r .content-btns-r button:nth-child(1) {
  background-color: #00afe8;
}

.part-one-r .content-info-r .content-btns-r button:nth-child(2) {
  background-color: var(--bluePrimary);
}

.card-pack-interest-r .part-two-r {
  display: flex;
  justify-content: space-between;
  border-bottom: 8px solid var(--bluePrimary);
}

.card-pack-interest-r .part-two-r button {
  width: 32%;
  padding: 0.5rem 1rem;
  border-radius: 0.6rem;
  border: 1px solid #d9d9d9;
  background: #fff;
  color: var(--bluePrimary);
  margin-bottom: 0.3rem;
  cursor: pointer;
}

.select-interest {
  background-color: var(--bluePrimary) !important;
  color: white !important;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  margin-bottom: 0 !important;
  border: none;
}

.card-pack-interest-r .part-three-r {
  padding: 0.5rem;
}

@media screen and (max-width: 900px) {
  .layout-result-filter {
    display: flex;
    flex-direction: column-reverse;
    gap: 1rem;
  }
  .content-buttons-cotizar {
    width: 100%;
  }
  .all-result-search-tourism {
    width: 100% !important;
  }
  .width-form {
    width: 100% !important;
  }
  .card-pack-interest-r .part-one-r {
    height: auto;
    flex-direction: column;
  }
  .part-one-r .content-info-r {
    width: 100%;
    height: auto;
  }
  .part-one-r .content-img-interest-r {
    width: 100%;
    height: auto;
  }
  .part-one-r .content-img-interest-r img {
    width: 100%;
    border-bottom-right-radius: 0;
    border-top-left-radius: 1.3rem;
    border-bottom-left-radius: 0;
    border-top-right-radius: 1.3rem;
    object-fit: cover;
    height: 100%;
  }
}
