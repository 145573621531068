.contain-filter-aside-hotels {
  display: flex;
  flex-direction: column;
}

.container-filter-price {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.filter-hotels {
  padding-bottom: 10px;
  border-bottom: 1px solid #e0e0e0;
  color: #004274;
}

.input-filtrado-price {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}

.inputs-filter {
  width: 100%;
  flex-direction: column;
}

.ModalChangeCloseHotelButton {
    display: none;
  }

@media screen and (max-width: 1000px) {
  .container-filter-price {
    display: flex;
    flex-direction: column;
    gap: 15px;
    background-color: white;
    width: 100%;
    max-width: 680px;
    border-radius: 12px;
    padding: 20px;
    position: relative;
    opacity: 1;
    transition: all 0.3s ease;
    transform: translateY(0px);
    z-index: 99999;
  }

  .container-filter-price.close {
    background-color: white;
    width: 100%;
    width: 100%;
    max-width: 680px;
    border-radius: 10px;
    padding: 20px;
    position: relative;
    opacity: 0;
    transition: all 0.3s ease;
    transform: translateY(-100px);
  }

  .ModalChangeCloseHotelButton {
    position: absolute;
    right: 7px;
    top: 7px;
    cursor: pointer;
    z-index: 999999;
    background: #fff;
    padding: 5px;
    display: block;
  }
}


