.contentLastSearch {
  width: 95%;
  margin: 40px auto 40px auto;
  max-width: 1280px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.contentLastSearch .lastDetacadosTitle {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.contentLastSearch .lastDetacadosTitle h2 {
  color: var(--bluePrimary);
  font-size: 24px;
  font-weight: 700;
}

.contentLastSearch .lastDetacadosTitle p {
  font-size: 14px;
}

.contentLastSearch .allCardLastSearch {
  width: 100%;
}

.contentLastSearch .allCardLastSearch .slickPrev:before,
.contentLastSearch .allCardLastSearch .slickNext:before {
  color: rgb(255, 255, 255) !important;
  font-size: 40px !important;
}

.contentLastSearch .allCardLastSearch .slickNext {
  right: 50px !important;
}

.contentLastSearch .allCardLastSearch .slickPrev {
  left: 20px !important;
}

.contentLastSearch .allCardLastSearch .slickTrack {
  margin: 0rem 0;
}

.contentLastSearch .allCardLastSearch .cardLastSearch {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  border: 1px solid var(--greyPrimary);
  border-radius: 15px;
  box-shadow: 0px 4px 4px 0px var(--shadow);
}

.contentLastSearch .allCardLastSearch .cardLastSearch .cardLastImg {
  width: 100%;
  height: 230px;
}

.contentLastSearch .allCardLastSearch .cardLastSearch .cardLastImg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 15px 15px 0 0;
}

.contentLastSearch .allCardLastSearch .cardLastSearch .cardContentLast {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
  border-radius: 10px;
  padding: 15px;
}

.contentLastSearch .allCardLastSearch .cardLastSearch .cardContentLast h4 {
  width: fit-content;
  font-size: 16px;
  opacity: 0.5;
  color: var(--bluePrimary);
  font-weight: bold;
}

.contentLastSearch .allCardLastSearch .cardLastSearch .cardContentLast h3 {
  color: var(--bluePrimary);
  margin: 0.4rem 0;
  font-size: 20px;
  font-weight: 700;
  text-transform: uppercase;
  text-wrap: nowrap;
  overflow: hidden;
}

.contentLastSearch .allCardLastSearch .cardLastSearch .cardContentLast p {
  font-size: 12px;
  text-wrap: nowrap;
  font-weight: 600;
  color: var(--black);
  overflow: hidden;
}

.imgSearchSkeleton {
  width: 100%;
  height: 100%;
  object-fit: cover;
  background: linear-gradient(
    to left,
    var(--white) 25%,
    var(--greyPrimary) 50%,
    var(--white) 75%
  );
  background-size: 600% 100%;
  animation: skeleton-loading 2s infinite linear;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Contenedor principal del slider */
.sliderContainerSearch {
  position: relative;
  overflow: hidden;
  width: 100%;
}

/* Contenedor que agrupa las tarjetas */
.sliderSearch {
  display: flex;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;
  scrollbar-width: none;
}

.sliderSearch::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}

/* Estilo de las tarjetas */
.slideSearch {
  scroll-snap-align: start;
  flex-shrink: 0;
  box-sizing: border-box;
  padding: 10px;
}

/* Botones de navegación */
.sliderSearchButton {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: var(--bluePrimary);
  color: var(--white);
  border-radius: 50%;
  border: none;
  width: 35px;
  height: 35px;
  cursor: pointer;
  z-index: 10;
  display: none;
  transition: opacity 0.3s;
}

.sliderSearchButton.disabled {
  opacity: 0.3;
  cursor: not-allowed;
}

.prev {
  left: 0px;
}

.next {
  right: 0px;
}

/* Estilo de las tarjetas (ejemplo) */
.slideSearch {
  border-radius: 10px;
}

/* Estilos responsivos */
@media (min-width: 601px) {
  .slideSearch {
    width: 50%; /* Muestra 2 tarjetas en pantallas de 601px a 800px */
  }
}

@media (min-width: 801px) {
  .slideSearch {
    width: 33.33%; /* Muestra 3 tarjetas en pantallas mayores de 800px */
  }
}

@media (max-width: 600px) {
  .slideSearch {
    width: 100%; /* Muestra 1 tarjeta en pantallas menores o iguales a 600px */
  }
  .sliderSearchButton {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
