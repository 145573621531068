.need-information-tourism{
    width: 100%;
    
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    box-shadow: 0px 4px 4px 0px #00000040;
    border-radius: 19px;
    padding: 1rem 2rem;
}


.need-information-tourism .column-A{
    width: 80%;
    display: flex;
    align-items: center;
    gap: 1rem;
}

.need-information-tourism .column-A img{
    width: 60px;
    height: 60px;
}
.need-information-tourism .column-A .info h3{
    font-size: 24px;
    font-weight: 700;
    color: #00AFE8;
}

.need-information-tourism .column-A .info p{
    font-size: 12px;
}

.need-information-tourism .column-B{
    width: fit-content;
}

.need-information-tourism .column-B button{
    height: 46px;
    border-radius: 8px;
    background-color: #00AFE8;
    color: white;
    font-size: 12px;
    cursor: pointer;
    font-weight: 700;
    padding: 0;
    min-width: 150px;
    text-wrap: nowrap;
    border: 1px solid #00AFE8 !important;
}

.column-B button {
    cursor: pointer;
  background-color: var(--bluePrimary);
  font-family: inherit;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 2px 15px;
  line-height: 2.5em;
  position: relative;
  overflow: hidden;
  border: 2px solid var(--bluePrimary);
  transition: color 0.5s;
  z-index: 1;
  font-size: 18px;
  border-radius: 9px;
  font-weight: 600;
  color: white;
}

.column-B button:before {
  content: "";
  position: absolute;
  z-index: -1;
  background: white;
  color: var(--bluePrimary);
  height: 550px;
  width: 1500px;
  border-radius: 50%;
}

.column-B button:hover {
  color: var(--bluePrimary);
}

.column-B button:before {
  top: 100%;
  left: 100%;
  transition: all 0.7s;
}

.column-B button:hover:before {
  top: -100px;
  left: -200px;
}

.column-B button:active:before {
  background: white;
  transition: background 0s;
}


@media screen and (max-width:600px) {

    .need-information-tourism{
        flex-direction: column;
    }

    .need-information-tourism .column-A{
        width: 100%;
    }
    .need-information-tourism .column-B{
        width: 100%;
    }
    
    .need-information-tourism .column-B button{
        width: 100%;
        padding: 1rem 1rem;
        text-wrap: nowrap;
    }
    .need-information-tourism .column-A .info h3{
        font-size: 20px;
        margin-bottom: .5rem;
    }
    .need-information-tourism .column-A .info p{
        font-size: 10px;
    }
}


@media screen and (max-width: 350px) {
    .need-information-tourism .column-A{
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 1rem;
    }
}

