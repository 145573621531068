.grid-container-doc {
  display: grid;
  grid-gap: 15px;
  width: 100%;
  margin: auto;
  grid-template-columns: repeat(4, 1fr);
  max-width: 1280px;
  padding-bottom: 20px;
}

.contain-allcards-specialist {
  max-width: 1280px;
  width: 95%;
  margin: 2rem auto;
  display: flex;
  flex-wrap: wrap;
  row-gap: 1rem;
  column-gap: 1.3%;
}

.card-specialist {
  width: 280px;
}

.card-specialist img {
  width: 100%;
  border-radius: 10px;
  object-fit: cover;
  object-position: top;
  height: 200px;
  -webkit-box-shadow: 0px 0px 9px -3px rgba(0, 0, 0, 0.37);
  -moz-box-shadow: 0px 0px 9px -3px rgba(0, 0, 0, 0.37);
  box-shadow: 0px 0px 9px -3px rgba(0, 0, 0, 0.37);
}

.contain-info-card-specialist {
  display: flex;
  flex-direction: column;
}

.info-card-specialist-a {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  width: 100%;
}

.info-card-specialist-a-main {
  width: 80%;
}

.info-card-specialist-a-main h3 {
  font-size: 14px;
}

.info-card-specialist-a-child {
  width: 38px;
}

.info-card-specialist-a-child span {
  font-weight: 700;
  font-size: 14px;
}

.info-card-specialist-b {
  display: flex;
  position: relative;
  justify-content: space-between;
}

.info-card-specialist-b-main {
  width: fit-content;
  display: flex;
  gap: 5px;
  align-items: center;
}

.info-card-specialist-b-main p {
  font-size: 9px;
  color: rgb(132, 132, 132);
}

.info-card-specialist-b-child {
  width: fit-content;
}

.info-card-specialist-b-child span {
  color: #004274;
  cursor: pointer;
  font-weight: 700;
}

.effect-hover-car-specialist {
  position: absolute;
  top: -180px;
  width: 300px;
  padding: 0.4rem;
  border-radius: 15px;
  background-color: rgb(247, 247, 247);
  -webkit-box-shadow: 0px 0px 54px -19px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 54px -19px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 54px -19px rgba(0, 0, 0, 0.75);
  z-index: 9;
}

.list-specialist {
  list-style: none;
}

.effect-hover-car-specialist h3 {
  font-size: 16px;
  color: #004274;
}

.effect-hover-car-specialist h4 {
  color: #004274;
  font-size: 15px;
  margin-top: 1rem;
}

.effect-hover-car-specialist li {
  font-size: 12px;
  font-weight: 700;
}

.cont-email-specialist p {
  font-size: 12px;
  font-weight: 700;
}
.description p{
  overflow: scroll;
  scrollbar-width: none; /* Firefox */
}
.description::-webkit-scrollbar {
  width: 0px; /* Safari y Chrome */
  background: transparent;
}