.content-specialties-turism-health {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.content-specialties-turism-health h2 {
    font-size: 24px;
    font-weight: 700;
    color: #00AFE8;
    text-transform: uppercase;
}


.content-specialties-turism-health .content-cards-specialidades {
    display: flex;
    flex-wrap: wrap;
    gap: 2%;
    row-gap: 1rem;
}

.content-specialties-turism-health .content-cards-specialidades .card {
    width: 32%;
    border: 1px solid #D9D9D9;
    border-radius: 6px;
    padding: .5rem 1rem;
    display: flex;
    gap: 10px;
    align-items: center;
    color: #00AFE8;
    transition: all 0.3s ease;
}

.card-element div {
    border: 1px solid #D9D9D9;
    border-radius: 8px;
    padding: .5rem 1rem;
    display: flex;
    gap: 18px;
    align-items: center;
    color: #00AFE8;
    transition: all 0.3s ease;
    margin-bottom: 15px !important;
    width: 97%;
    min-height: 66px;
}

.card-element p {
    color: black;
    font-size: 14px;
    font-weight: 450;
} 
.card-element span svg {
    font-size: 34px;
}

.content-specialties-turism-health .content-cards-specialidades .card:hover {
    width: 32%;
    border: 1px solid #D9D9D9;
    border-radius: 6px;
    padding: .5rem 1rem;
    display: flex;
    gap: 10px;
    align-items: center;
    color: #00AFE8;
}

.content-specialties-turism-health .content-cards-specialidades .card span {
    font-size: 14px;
    font-weight: 600;
}


.content-specialties-turism-health .content-cards-specialidades .card img {
    width: 36px;
    height: 36px;
}


@media screen and (max-width:800px) {
    .content-specialties-turism-health .content-cards-specialidades .card {
        width: 48%;
    }
}

@media screen and (max-width:500px) {
    .content-specialties-turism-health .content-cards-specialidades .card {
        width: 100%;
    }
}