.containButtonSelectedDate {
    padding: 10px;
    display: flex;
    justify-content: flex-end;
}

.containButtonSelectedDate button {
    background-color: var(--bluePrimary);
    padding: 8px 20px;
    border-radius: 8px;
    color: var(--white);
    cursor: pointer;
    font-weight: 500;
    transition: all 0.3s ease;
}

.containButtonSelectedDate button:hover {
    background-color: var(--blueTertiary);
}

.dateInputWithPicker {
    position: relative;
    display: inline-block;
    width: 100%;
}

.dateInput {
    width: 100%;
    padding: 10px 40px 10px 10px;
    border: 1px solid #ccc !important;
    border-radius: 8px;
    font-size: 16px;
    outline: none;
}

.calendarContainer {
    position: absolute;
    top: calc(100% + 8px);
    left: 0;
    z-index: 10;
    background: white;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    overflow: hidden;
    animation: fadeIn 0.3s ease-in-out;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.inputWrapper {
    position: relative;
    display: flex;
    align-items: center;
}

.inputWrapper .icon {
    position: absolute;
    right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--blueLightTransparent);
    cursor: pointer;
    font-size: 16px;
    height: 100%;
    transition: color 0.3s ease;
}

.errorInput {
    border: 1px solid var(--redPrimary) !important;
    border-radius: 8px;
}