.modalNoFoundIcon {
    width: 100px;
    height: 100px;
    display: grid;
    place-items: center;
    font-size: 50px;
    background-color: var(--blueWhiteTableThead);
    color: var(--redPrimary);
    border-radius: 10px;
}

.modalNoFound {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;

  h3 {
    color: var(--bluePrimary);
    font-size: 18px;
  }

  p {
    color: var(--blackGray);
    font-size: 14px;
    font-weight: 500;
    text-align: center;
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    background-color: var(--bluePrimary);
    color: var(--white);
    padding: 13px 20px;
    border-radius: 8px;
    cursor: pointer;
    text-align: center;
    width: 100%;
    max-width: 400px;
    font-weight: 600;
    font-size: 16px;
    transition: all 0.3s ease;
  }

  button:hover {
    background-color: var(--blueTertiary);
  }
}