.contain-cart-reserve {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.contain-cart-reserve h2 {
  color: var(--bluePrimary);
  font-size: 18px;
}

.table-wrapper {
  overflow: hidden;
  border-radius: 10px;
  border: 1px solid var(--greyPrimary);
}

.fl-table {
  font-size: 14px;
  font-weight: 400;
  border: none;
  border-collapse: collapse;
  width: 100%;
  max-width: 100%;
  background-color: var(--blueWhiteTable);
}

.fl-table thead:first-child {
  background-color: var(--blueWhiteTableThead);
  border-bottom: 1px solid var(--greyPrimary);
}

.fl-table td,
.fl-table th {
  text-align: center;
  padding: 10px;
  border-right: 1px solid var(--greyPrimary);
}

.fl-table td {
  font-size: 12px;
  padding: 20px;
}

.fl-table thead th {
  padding: 20px;
}

.reserve-button {
  background-color: var(--bluePrimary);
  color: var(--white);
  font-weight: 500;
  font-size: 18px;
  padding: 10px 30px;
  border-radius: 7px;
  width: 285px;
  min-width: fit-content;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
}

.reserve-button:hover {
  background-color: var(--bluePrimary);
}

.hidde-accommodation {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70px;
}

.show-accommodation {
  display: none;
}

.show-accommodations {
  display: none;
}

@media screen and (max-width: 1100px) {
  .contain-cart-reserve {
    width: 99%;
  }
}

@media (max-width: 767px) {
  .fl-table td {
    font-size: 11px;
  }
  .reserve-button {
    font-size: 14px;
  }

  .hidde-accommodation {
    display: none;
    height: 100%;
  }

  .show-accommodation {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 70px;
  }

  .table-wrapper {
    overflow: scroll;
  }

  .show-accommodations {
    display: none;
  }
}

@media screen and (max-width: 500px) {
  .reserve-button {
    width: 100%;
  }
}
