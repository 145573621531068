.number-travel{
    margin-top: 40px;
    margin-bottom: 20px;
}

.number-travel p{
    font-family: "Montserrat" !important;
    font-size: 14px;
    font-weight: 700;
    line-height: 17px;
    color: #0072B9;
}

.inputs-data{
    display: flex;
    justify-content: space-around;
}

.inputs-data > div{
    width: 30%;
    margin-top: 20px;
}

.data-picker-voucher{
    border: 1px solid #D9D9D9;
    width: 100%;
    height: 32px;
    border-radius: 8px;
    text-align: left;
    cursor: pointer;
    padding-inline: 10px;
    background: url('../../../../ui/assets/iconsSVG/IconCalendar4.svg') no-repeat;
    background-position: right 10px center;
}

.input-gender{
    display: flex;
    flex-direction: column;
}

.input-gender select{
    cursor: pointer;
    appearance: none;
    background: url('../../../../ui/assets/iconsSVG/ArrowDown2.svg') no-repeat;
    background-position: right 10px center;
    width: 100%;
    height: 32px;
    border: 1px solid #D9D9D9;
    border-radius: 8px;
    padding: 5px;
    font-size: 12px;
    font-weight: 400;
}

.input-data-date{
    height: 32px;
    border: 1px solid #D9D9D9;
    border-radius: 8px;
    padding: 5px;
    font-size: 12px;
    font-weight: 400;
}

.react-datepicker-wrapper{
    width: 100%;
    margin-top: 6px;
}

.react-datepicker-wrapper input{
    cursor: pointer;
    width: 100%;
    background: url('../../../../ui/assets/iconsSVG/IconCalendar4.svg') no-repeat;
    background-position: right 10px center;
}

.validate-input-voucher input{
    border: 1px solid var(--redPrimary) !important;
    transition: 0.3s;
}

.validate-input-voucher select{
    border: 1px solid var(--redPrimary) !important;
    transition: 0.3s;
}

.validate-gender{
    border: 1px solid var(--redPrimary) !important;
    transition: 0.3s;
}

.conatiner-input-date{
    display: flex;
    flex-direction: column;
}

.conatiner-input-date label{
    margin-bottom: 10px;
}