.errorMeetPX {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.errorMeetTitlePX {
  margin-top: 40px;
  text-align: center;
  color: var(--white);
  font-size: 80px;
  font-weight: bolder;
  font-family: 'Montserrat', sans-serif;
}

.errorMeetDescriptionPX {
  margin-top: 10px;
  text-align: center;
  color: var(--white);
  font-size: 20px;
  font-weight: bolder;
  font-family: 'Montserrat', sans-serif;
  text-transform: uppercase;
}

.errorMeetContactPX {
  margin-top: 10px;
  text-align: center;
  color: var(--white);
  font-size: 14px;
  font-weight: 400;
  font-family: 'Montserrat', sans-serif;
  text-transform: uppercase;
}

.errorMeetPX .errorMeetContainerPX {
  height: 75vh;
  position: relative;
}

.errorMeetPX .astronautPX {
  width: 60px;
  position: absolute;
  top: 10%;
  right: 10%;
  animation: move 10s linear infinite;
}

.errorMeetPX .planetPX {
  height: 100%;
}

.errorMeetPX img {
  width: 100%;
  height: 100%;
}

@keyframes move {
  0% {
    left: 0;
    top: 50%;
    transform: rotate(0deg);
  }
  25% {
    left: 50%;
    top: 0;
  }
  50% {
    left: 100%;
    top: 50%;
    transform: rotate(360deg);
  }
  75% {
    left: 50%;
    top: 80%;
  }
  100% {
    left: 0;
    top: 50%;
    transform: rotate(0deg);
  }
}