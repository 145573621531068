.containModal {
    display: grid;
    place-items: center;
    transition: all 0.3s ease;
}


.contentModal {
    position: relative;
    transition: all 0.3s ease;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
}


.contentModalButtonClose {
    cursor: pointer;
    position: absolute;
    top: 8px;
    right: 8px;
    color: var(--bluePrimary);
    outline: none;
}

.iconSuccess {
    background-color: var(--blueWhiteTableThead);
    color: var(--greenPrimary);
    width: 70px;
    height: 70px;
    border-radius: 50%;
    display: grid;
    place-items: center;
}

.contentText {
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 10px;
    color: var(--blackGray);
}

.contentText h2 {
    font-size: 26px;
    font-weight: 700;
}

.contentText p {
    font-size: 16px;
    font-weight: 500;
}

.buttonLinks {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    width: 100%;
}

.linkCreatePackage {
    background-color: var(--bluePrimary);
    padding: 10px 20px;
    border-radius: 8px;
    color: var(--white);
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    gap: 10px;
    box-shadow: var(--shawdowPrimary);
}

.linkCreatePackage:hover {
    opacity: 0.9;
}

.linkFinish {
    background-color: var(--bluePrimary);
    padding: 10px 20px;
    border-radius: 8px;
    color: var(--white);
}