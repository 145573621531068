.content-banner-flow{
    width: 50%;
    display: flex;
    justify-content: space-between;
}

.contain-info-doctor-flow{
    display: flex;
    align-items: start;
    width: 100%;
}

.content-img-doctor-flow {
    width: 100%;
    max-height: 300px;
}

.content-img-doctor-flow img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
    border-radius: 20px;
}

.content-info-doctor-detail-flow{
    display: flex;
    flex-direction: column;
    gap: .5rem;
}

.content-info-doctor-detail-flow ul{
    list-style: none;
    display: flex;
    gap: 6px;
    margin-top: 1rem;
}

.content-info-doctor-detail-flow h2{
    color: var(--bluePrimary);
    font-size: 24px;
}

.content-info-doctor-detail-flow p{
    font-size: 14px;
}

.contain-close-doctor-flow{
    display: flex;
    align-items: center;
    gap: 2px;
    color: var(--bluePrimary);
    cursor: pointer;
    height: fit-content;
}


@media screen and (max-width:650px) {
    .content-banner-flow{
        width: 100%;
    }
    .content-info-doctor-detail-flow{
        margin-left: 0;
    }
    .contain-close-doctor-flow{
        width: 15%;
    }
}

@media screen and (max-width:450px){
    .content-info-doctor-detail-flow h2{
        font-size: 25px;
        text-align: center;
    }
    .contain-close-doctor-flow span{
        font-size: 11px;
    }
}