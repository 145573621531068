.layout_specialist {
    display: flex;
    justify-content: space-between;
    width: 95%;
    max-width: 1280px;
    margin: 0 auto;
    margin-top: 2rem;
    gap: 1rem;
}


.maxWidthBuildPackage {
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-width: 1280px;
    width: 100%;
    margin: 0 auto;
    padding-bottom: 20px;
}

.filters {
    width: 200px;
    min-width: 200px;
    max-width: 200px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    transition: all .5s ease;
}

.results {
    width: 100%;
}


.filterBtn {
    display: none;
}

.filterBtn button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    background-color: var(--bluePrimary);
    color: var(--white);
    padding: 13px 20px;
    border-radius: 8px;
    cursor: pointer;
    text-align: center;
    font-weight: 600;
    font-size: 16px;
    transition: all 0.3s ease;
}

@media screen and (max-width:1000px) {
    .layout_specialist {
        flex-direction: column;
        gap: 1rem;
    }

    .filters {
        display: none;
    }

    .filterBtn {
        display: block;
        transition: all .5s ease;

    }
}


@media screen and (max-width: 640px) {
    .filterBtn button{
        width: 100%;
    }
}