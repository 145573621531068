.contained {
    width: 90%;
    margin: 0% 5% 0% 5%;
}

.title-card-shoppin {
    margin: 2rem auto;
    font-size: 30px;

}

.layout-shoppin-cart {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

}

.contain-all-Items {
    width: 60%;

}

.contain-all-resumen {
    width: 20%;
    background-color: #CDEFFA;
    padding: 2rem;
    border-radius: 3px;
    min-height: 450px;
    position: relative;
}

.contain-resumen-cart {
    display: flex;
    justify-content: end;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    color: black;
}

.contain-resumen-cart h4 span {
    border: black 1px solid;
    padding: 3px;
}

.contain-all-resumen h3 {
    color: black;
    text-align: center;
}

.contain-button-delete {
    display: flex;
    justify-content: space-between;
}

.elementsShopping {


    margin: 8% 5% 0% 5%;
}

.bodyElementsShopping {
    display: grid;
    grid-template-columns: 25% 40% 30%;
    align-items: center;
}

.contain-img-cart img {
    width: 70px;
    height: 50px;
    border-radius: 5px;
}

.button-delete-Cart {
    border: none;
    border-radius: 4px;
    background-color: rgb(180, 21, 21);
    color: white;
    padding: 3px;
}

.line {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 5%;

    width: 100%;
    height: 0px;
    left: 55px;
    top: 445px;

    opacity: 0.2;
    /* Black */

    border: 2px solid #000000;

}

/* ------------------Form Pay-------------------------------- */

.form-card-pay {
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;

}

.campos-cards input {
    border: none;
    border-radius: 5px;
    padding: 5px;
    width: 96%;
    background-color: #ffffff86;
    -webkit-box-shadow: 0px 30px 34px -28px rgba(0, 0, 0, 1);
    -moz-box-shadow: 0px 30px 34px -28px rgba(0, 0, 0, 1);
    box-shadow: 0px 30px 34px -28px rgba(0, 0, 0, 1);

}

.form-card-pay button {
    border: none;
    background-color: #8698A6;
    color: white;
    font-weight: 600;
    letter-spacing: 1px;
    border-radius: 10px;
    padding: 10px;
    transition: .5s ease-in-out;
    cursor: pointer;
}

.form-card-pay button:hover {
    background-color: #566169;
}


.campos-card-double {
    display: flex;
    justify-content: space-between;

}

.campos-card-double input {
    width: 40%;
    border: none;
    border-radius: 5px;
    padding: 5px;
    background-color: #ffffff86;
    -webkit-box-shadow: 0px 30px 34px -28px rgba(0, 0, 0, 1);
    -moz-box-shadow: 0px 30px 34px -28px rgba(0, 0, 0, 1);
    box-shadow: 0px 30px 34px -28px rgba(0, 0, 0, 1);
}

.button-Paypal {
    margin-top: 1rem;
    border-radius: 10px;
    border: none;
    background-color: white;
}

.button-Paypal img {
    width: 40px;
    height: 40px;
    border-radius: 10px;
    cursor: pointer;

}

.contain-method-pay {

    display: flex;
    gap: 5px;
    justify-content: space-around;
    flex-wrap: wrap;
}

/* ------------Card tarjeta Visa ------------------------- */


.card-visa {
    width: 285px;
    height: 190px;
    -webkit-perspective: 600px;
    -moz-perspective: 600px;
    perspective: 600px;
    position: absolute;
    top: 60px;
    right: 40px;
}

.card__part {
    box-shadow: 1px 1px #aaa3a3;
    top: 0;
    position: absolute;
    z-index: 1000;
    left: 0;
    display: inline-block;
    width: 285px;
    height: 190px;
    background: rgb(245, 246, 246);
    /* Old browsers */
    background: -moz-linear-gradient(top, rgba(245, 246, 246, 1) 0%, rgba(219, 220, 226, 1) 0%, rgba(221, 223, 227, 1) 17%, rgba(184, 186, 198, 1) 49%, rgba(221, 223, 227, 1) 87%, rgba(221, 223, 227, 1) 100%, rgba(245, 246, 246, 1) 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(top, rgba(245, 246, 246, 1) 0%, rgba(219, 220, 226, 1) 0%, rgba(221, 223, 227, 1) 17%, rgba(184, 186, 198, 1) 49%, rgba(221, 223, 227, 1) 87%, rgba(221, 223, 227, 1) 100%, rgba(245, 246, 246, 1) 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, rgba(245, 246, 246, 1) 0%, rgba(219, 220, 226, 1) 0%, rgba(221, 223, 227, 1) 17%, rgba(184, 186, 198, 1) 49%, rgba(221, 223, 227, 1) 87%, rgba(221, 223, 227, 1) 100%, rgba(245, 246, 246, 1) 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f5f6f6', endColorstr='#f5f6f6', GradientType=0);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: 8px;

    -webkit-transition: all .5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    -moz-transition: all .5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    -ms-transition: all .5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    -o-transition: all .5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    transition: all .5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
}

.card__front {
    padding: 18px;
    -webkit-transform: rotateY(0);
    -moz-transform: rotateY(0);
}

.card__back {
    padding: 18px 0;
    -webkit-transform: rotateY(-180deg);
    -moz-transform: rotateY(-180deg);
}

.card__black-line {
    margin-top: 5px;
    height: 38px;
    background-color: #303030;
}

.card__logo {
    height: 60px;
}

.card__front-logo {
    position: absolute;
    top: 18px;
    right: 18px;
}

.card__square {
    border-radius: 5px;
    height: 60px;
}

.card_numer {
    display: block;
    width: 100%;
    word-spacing: 4px;
    font-size: 20px;
    letter-spacing: 2px;
    color: black;
    text-align: center;
    margin-bottom: 20px;
    margin-top: 20px;
}

.card__space-75 {
    width: 75%;
    float: left;
}

.card__space-25 {
    width: 25%;
    float: left;
}

.card__label {
    font-size: 10px;
    text-transform: uppercase;
    color: black;
    letter-spacing: 1px;
}

.card__info {
    margin-bottom: 0;
    margin-top: 5px;
    font-size: 16px;
    line-height: 18px;
    color: black;
    letter-spacing: 1px;
    text-transform: uppercase;
}

.card__back-content {
    padding: 15px 15px 0;
}

.card__secret--last {
    color: #303030;
    text-align: right;
    margin: 0;
    font-size: 14px;
}

.card__secret {
    padding: 5px 12px;
    background-color: #fff;
    position: relative;
}

.card__secret:before {
    content: '';
    position: absolute;
    top: -3px;
    left: -3px;
    height: calc(100% + 6px);
    width: calc(100% - 42px);
    border-radius: 4px;
    background: repeating-linear-gradient(45deg, #ededed, #ededed 5px, #f9f9f9 5px, #f9f9f9 10px);
}

.card__back-logo {
    position: absolute;
    bottom: 15px;
    right: 15px;
}

.card__back-square {
    position: absolute;
    bottom: 15px;
    left: 15px;
}

.card:hover .card__front {
    -webkit-transform: rotateY(180deg);
    -moz-transform: rotateY(180deg);

}

.card:hover .card__back {
    -webkit-transform: rotateY(0deg);
    -moz-transform: rotateY(0deg);
}



/* -----------------Tarjeta masterCard---------------- */

.card__part_master {
    box-shadow: 1px 1px #aaa3a3;
    top: 0;
    position: absolute;
    z-index: 1000;
    left: 0;
    display: inline-block;
    width: 285px;
    height: 190px;
    background: rgb(252, 234, 187);
    /* Old browsers */
    background: -moz-linear-gradient(top, rgba(252, 234, 187, 1) 0%, rgba(252, 205, 77, 1) 34%, rgba(252, 205, 77, 1) 60%, rgba(251, 223, 147, 1) 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(top, rgba(252, 234, 187, 1) 0%, rgba(252, 205, 77, 1) 34%, rgba(252, 205, 77, 1) 60%, rgba(251, 223, 147, 1) 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, rgba(252, 234, 187, 1) 0%, rgba(252, 205, 77, 1) 34%, rgba(252, 205, 77, 1) 60%, rgba(251, 223, 147, 1) 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fceabb', endColorstr='#fbdf93', GradientType=0);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: 8px;

    -webkit-transition: all .5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    -moz-transition: all .5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    -ms-transition: all .5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    -o-transition: all .5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    transition: all .5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
}