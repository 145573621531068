.content-state-stepsT {
  width: 95%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
}

.content-state-stepsT span {
    color: #fff;
    width: 50px;
    min-width: 50px;
    max-width: 50px;
    min-height: 50px;
    max-height: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #e3e3e3;
    display: flex;
    align-items: center;
    justify-content: center;
}

.content-state-stepsT div {
  height: 6px;
  width: 100%;
  background-color: #e3e3e3;
}
