.content-send-vouchers{
    display: flex;
    flex-direction: column;
    gap: 1.8rem;
    margin-top: 2rem;
}

.content-send-vouchers .titles-vouchers h2{
    color: var(--bluePrimary);
    font-size: 18px;
}

.content-send-vouchers .titles-vouchers p{
    color: rgb(75, 75, 75);
    font-size: 12px;
}

.form-send-vouchers{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.form-send-vouchers .camp-form-send-vouchers{
    display: flex;
    flex-direction: column;
    gap: .5rem;
}

.form-send-vouchers .camp-form-send-vouchers label{
    color: var(--bluePrimary);
    font-size: 14px;
    font-weight: bold;
}

.form-send-vouchers .camp-form-send-vouchers input{
    padding: .8rem 1rem;
    border-radius: 8px;
    border: solid 1px #D9D9D9;
}