.roomCard {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1.5px solid var(--greyPrimary);
}
.roomCard_ {
    padding: 15px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}
.roomCard_roomOne {
    width: 100%;
    height: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.roomCard_roomOne p {
    font-family: 'Inter';
    font-size: 16px;
    font-weight: 400;
    color: rgba(0, 0, 0, .5);
}
.roomCard_roomOneCloseButton {
    cursor: pointer;
}
.roomCard_roomPeople {
    width: 100%;
    gap: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 10px;
}
.roomCard_roomPerson {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: start;
    gap: 10px;
}
.roomCard_roomPerson_ h4 {
    font-family: 'Inter';
    color: var(--bluePrimary);
    font-size: 20px;
    font-weight: 600;
}
.roomCard_roomPerson_ p {
    font-family: 'Inter';
    font-size: 13px;
    color: rgba(0, 0, 0, .5);
}
.roomCard_roomPersonButtons {
    width: 148px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
    border: 1px solid var(--blueTransparent);
    border-radius: 5px;
    gap: 20px;
}
.roomCard_roomPersonButtons p {
    margin: 0 15px;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
    font-family: 'Inter';
    font-size: 16px;
}
.roomCard_roomPersonButtons button {
    height: 35px;
    width: 35px;
    border: none;

    background-color: var(--bluePrimary);
    color: white;
    font-family: 'Inter';
    font-weight: 600;
    cursor: pointer;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
}


.contain-age-childrens{
    display: flex;
    flex-direction: column;
}

.contain-age-childrens h3{
    color: var(--bluePrimary);
}

.contain-age-childrens h4 {
    font-size: 13px;
    font-weight: 400;
    color: rgb(138, 138, 138);
    font-family: Arial, Helvetica, sans-serif;
}

.contain-all-ages-children{
    width: 100%;
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
}

.contain-buttons-age-children{
    width: 142px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
    border: 1px solid var(--blueTransparent);
    border-radius: 5px;
    gap: 20px;
}

.contain-all-children {
    width:  100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;
}

.contain-age-childrens button{
    height: 100%;
    width: 35px;
    border: none;
    background-color: var(--bluePrimary);
    color: white;
    font-family: 'Inter';
    font-weight: 600;
    cursor: pointer;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
}