.container {
    position: relative;
  }
  
  .inputWrapper {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  
  .label {
    font-size: 16px;
    color: var(--bluePrimary);
    font-weight: 500;
  }
  
  .errorInput {
    border: 1px solid var(--redPrimary);
  }
  
  .errorLabel {
    color: var(--redPrimary);
  }
  
  
  .inputIcon {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    width: 100%;
    padding-right: 8px;
    border-radius: 5px;
    border: 1px solid var(--greyPrimary);
    font-size: 14px;
    color: var(--blackGray);
    min-height: 35px !important;
    padding-left: 5px !important;
  }

  .inputIcon.errorInput {
    border: 1px solid var(--redPrimary);
  }

  .inputIcon input {
    border: none !important;
  }
  .dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    margin-top: 5px;
    background-color: #fff;
    border: 1px solid var(--greyPrimary);
    box-shadow: var(--shawdowPrimary);
    border-radius: 5px;
    z-index: 10;
    max-height: 200px;
    overflow-y: auto;
    font-size: 14px;
  }
  
  .dropdownInputWrapper {
    display: flex;
    align-items: center;
    padding: 10px;
  }
  
  .dropdownLabel {
    font-size: 14px;
    margin-right: 10px;
    color: var(--bluePrimary);
  }
  
  .dropdownInput {
    width: 100%;
    padding: 8px;
    font-size: 14px;
    border: 1px solid var(--greyPrimary);
    border-radius: 4px;
  }
  
  .dropdownList {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .dropdownItem {
    padding: 10px;
    cursor: pointer;
    transition: background-color 0.3s;
    font-size: 14px;
  }
  
  .dropdownItem:hover {
    background-color: var(--blueWhiteTableThead);
  }
  
  .loader {
    color: var(--blueLightTransparent);
  }