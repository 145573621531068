.grid_nurseQuestion {
    position: absolute;
    left: 120px;
    top: 285px;
    background-color: #004274;
    border-radius: 20px;
    box-shadow: -4px 4px 4px rgba(0, 0, 0, 0.25);
    width: 80% !important;
    max-height: 570px;
    overflow: hidden;
    overflow-y: auto;
}
.css-919fip-MuiGrid-root>.MuiGrid-item {
    padding: 10px !important
}
.CardNurse {
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
}
.Arrows {
    width: 100%;
    margin-bottom: 10px;
}

@media screen and (max-width: 1000px) {
    .grid_nurseQuestion {
        width: 100% !important;
        top: 280px;
        left: 16px;
    }
}
@media screen and (max-width: 700px) {
    .grid_nurseQuestion {
        top: 300px;
    }
}