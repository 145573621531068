.btn-select-method-to-pay{
    background-color: #104172;
    color: white;
    width: 135px;
    height: 30px;
    border-radius: 5px;
    font-size: 16px;
    font-weight: 700;
    font-family: "Montserrat" !important;
    cursor: pointer;
}

.btn-select-method-to-pay:hover{
    box-shadow: 0px 4px 4px 0px #00000040;
    transition: 0.3s;

}

.btn-select-method-to-pay-selected{
    width: 135px;
    height: 30px;
    background-color: white;
    border: 1px solid #6EC301;
    color: #6EC301;
    border-radius: 5px;
    font-size: 16px;
    font-weight: 700;
    font-family: "Montserrat" !important;
}

.btn-delete-method-pay{
    cursor: pointer;
}

.btn-delete-method-pay p{
    font-size: 16px;
    font-weight: 300;
    color: #104172;
    font-family: "Montserrat" !important;
    margin-inline: 5px;
}

.p-number-card-encryption{
    text-align: center;
    width: 200px;
}

.card-method-pay{
    height: 100px;
}

.unique-cell-method-card{
    border: 1px solid #D9D9D9;
    margin-block: 10px;
    border-radius: 7px;

}

.first-cell-method-card{
    margin-top: 10px;
    border-right: 1px solid #D9D9D9;
    border-left: 1px solid #D9D9D9;
    border-top: 1px solid #D9D9D9;
    border-bottom: 0.5px solid #D9D9D9 ;
    border-radius: 7px 7px 0px 0px;
}

.cell-method-card{
    border-right: 1px solid #D9D9D9;
    border-left: 1px solid #D9D9D9;
    border-top: 0.5px solid #D9D9D9;
    border-bottom: 0.5px solid #D9D9D9 ;
}

.last-cell-method-card{
    border-right: 1px solid #D9D9D9;
    border-left: 1px solid #D9D9D9;
    border-top: 0.5px solid #D9D9D9;
    border-bottom: 1px solid #D9D9D9 ;
    border-radius: 0px 0px 7px 7px;
    margin-bottom: 10px;
}

.empty-method-pay{
    margin-block: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 7px ;
    border: 1px dashed #D9D9D9;
    height: 100px;
}

.empty-method-pay p{
    font-size: 14px;
    font-weight: 300;
    font-family: "Montserrat" !important;
}

.title-mehtho-to-pay p{
    color: #104172;
    font-size: 18px;
    font-weight: 700;
    font-family: "Montserrat" !important;

}

.btn-add-method-to-pay{
    margin-block: 20px ;
    display: flex;
    justify-content: space-between;
    border: 1px solid #D9D9D9;
    height: 70px;
    border-radius: 7px;
    padding-inline: 20px ;
}

.btn-add-method-to-pay:hover{
    box-shadow: 0px 4px 4px 0px #00000040;
    transition: 0.3s;
}

.btn-add-method-to-pay p{
    font-size: 11px;
    font-weight: 300;
    font-family: "Montserrat" !important;
}

.icon-card-p-add{
    display: flex;
    justify-content: center;
    align-items: center;
}

.icon-card-p-add p{
    font-size: 12px;
    font-weight: 700;
    font-family: "Montserrat" !important;
    color: #104172;
    margin-inline: 10px;
}

.container-modal-method-pay{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display:flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 2;
}

.modal-loading-method-pay{
    background-color: white;
    border-radius: 25px;
    width: 300px;
    height: 200px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    box-shadow: 0px 4px 4px 0px #00000040;
}

.modal-loading-method-pay p{
    color: #104172;
    font-size: 18px;
    font-weight: 900;
    font-family: "Montserrat" !important;
}

.btn-button-accept-error-method-pay{
    background-color: #104172;
    color: white;
    font-size: 12px;
    font-weight: 900;
    font-family: "Montserrat" !important;
    padding-block: 5px;
    padding-inline: 20px;
    border-radius: 5px;
    cursor: pointer;
}

.btn-button-accept-error-method-pay:hover{
    box-shadow: 0px 4px 4px 0px #00000040;
    transition: 0.3s;
}

@media screen and (max-width: 850px) {
    .delete-method-pay{
        display: none;
    }
}

@media screen and (max-width: 650px) {
    .icons-method-pay{
        display: none;
    }
    
}


@media screen and (max-width: 600px){
    .icon-delethe-pay{
        margin-inline: 20px;
    }

    .card-method-pay{
        flex-direction: column;
        height: 150px
    }

    .delete-method-pay{
        display: block;
    }

    .btn-delete-method-pay{
        border: 1px solid #104172;
        display: flex;
        align-items: center;
        justify-content: space-around;
        border-radius: 5px;
        padding-block: 3px;
    }
}