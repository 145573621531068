.img-pack-results {
    padding: 0;
    border-bottom-left-radius: 15px;
    border-top-left-radius: 15px;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.contain-all-pack-results {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin: 1rem;
    width: 68%;
}

.contain-layout-results-pack {
    display: flex;
    justify-content: space-between;
    width: 90%;
    margin: 0 auto;
}

.contain-search-pack {
    width: 30%;
    -webkit-box-shadow: 3px 3px 5px 0px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 3px 3px 5px 0px rgba(0, 0, 0, 0.15);
    box-shadow: 3px 3px 5px 0px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    margin-bottom: 2rem;
    padding: 1rem;
    height: fit-content;
}

.search-packs-mobile {
    display: none;
}

.description-card-packs-results{
    max-height: 80px;
    overflow: scroll;
    color: rgb(136, 136, 136);
}

.city-pack-result{
    font-weight: 700;
}

@media screen and (max-width:1100px) {
    .contain-layout-results-pack {
        width: 99%;
    }
}


@media screen and (max-width:850px) {
    .contain-search-pack {
        display: none;
    }

    .contain-all-pack-results {
        width: 100%;
        margin: 1rem 0;
    }

    .search-packs-mobile {
        display: block;
        width: 95%;
        margin: 0 auto;
    }

    .contain-icon-search {
        -webkit-box-shadow: 3px 3px 5px 0px rgba(0, 0, 0, 0.15);
        -moz-box-shadow: 3px 3px 5px 0px rgba(0, 0, 0, 0.15);
        box-shadow: 3px 3px 5px 0px rgba(0, 0, 0, 0.15);
        padding: .4rem;
        border-radius: 5px;
        cursor: pointer;
        display: flex;
        width: fit-content;
    }

    .contain-search-pack-mobile {
        width: 100%;
        -webkit-box-shadow: 3px 3px 5px 0px rgba(0, 0, 0, 0.15);
        -moz-box-shadow: 3px 3px 5px 0px rgba(0, 0, 0, 0.15);
        box-shadow: 3px 3px 5px 0px rgba(0, 0, 0, 0.15);
        border-radius: 10px;
        margin-bottom: 2rem;
        margin-top: 2rem;
        padding: 1rem;
        height: fit-content;
    }
}