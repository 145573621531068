.content-pay-pending-flight {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: .5rem;
    -webkit-box-shadow: -1px 0px 7px 2px rgba(0,0,0,0.22);
-moz-box-shadow: -1px 0px 7px 2px rgba(0,0,0,0.22);
box-shadow: -1px 0px 7px 2px rgba(0,0,0,0.22);
border-radius: 5px;
padding: 1rem;
}
.content-pay-pending-flight p{
    text-align: justify;
    margin-bottom: 1rem;
}
.content-pay-pending-flight .letter-green{
    color: rgb(170, 99, 17);
    display: flex;
    align-items: center;
    margin: 1rem;
}
.content-pay-pending-flight table {
    border: 2px solid rgb(174, 174, 174);
    width: 100%; /* Ancho de la tabla */
    border-radius: 3px;
    margin-bottom: 2rem;
  }

  /* Estilo para las celdas */
  .content-pay-pending-flight table th {
     /* Bordes sólidos de 1px */
    padding: 8px; /* Espaciado interno de las celdas */
    text-align: center; /* Alineación del texto en las celdas */
    border-radius: 3px;
    background-color: var(--bluePrimary);
    color: white;
  }
  
  .content-pay-pending-flight table td {
    background-color: rgb(234, 234, 234); /* Bordes sólidos de 1px */
    padding: 8px; /* Espaciado interno de las celdas */
    text-align: center; /* Alineación del texto en las celdas */
    border-radius: 3px;
  }


  @media screen and (max-width:500px) {
    .content-pay-pending-flight table th {
        font-size: 14px;
     }
     
     .content-pay-pending-flight table td {
        font-size: 12px;
     }
  }