.containerPassengersInfo{
    width: 100% !important;
    height: 500px;
}
.inputsAdult{
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.inputAdult{
    margin: 15px 20px 0px 0px;
    display: grid;
    grid-template-rows: 20% 80% ;
}
.inputAdult label{
    margin: 0px 0px 80px 0px;
}
.itemSelected{
  height: 40%;
}
.flight_dateUser{
    width: 200px;
    height: 60px;
    background-color: white;
    border-radius: 20px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.35) !important;
    margin: 10px 0px 35px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .flight_dateUser h2{
    font-family: 'Oswald';
    font-size: 20px;
    text-transform: uppercase;
    color: #004274;
    width: 55%;
    line-height: 28px;
  }
  
  .flight_dateUser-Item{
    display: grid;
    grid-template-rows: 30% 50% ;
  }
 


  /*
*-------------CALENDAR-------------------- 
*/


.CalendarChekout {
  background-color: white;
  border-radius: 20px;
  position: absolute;
  top: 22%;   
  left: 45%;
  right: 250px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.35) !important;
  width: 42em;
  
}
.CalendarChekout_ {
  display: flex;
  align-items: center;
}

.CalendarChekout_date {
  width: 100%;
  height: 90px;
  background-color: white;
  border-radius: 20px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.35) !important;
  display: flex;
  justify-content: center;
  margin: 10px 20px;
}

.CalendarChekout_date h2 {
  font-family: 'Oswald';
  font-size: 16px;
  text-transform: capitalize;
  color: #004274;
  width: 55%;
  line-height: 28px;
}

.CalendarChekout_date_ {
  width: 80%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media screen and (max-width: 2300px) {
  .CalendarChekout {
     
      top: 60%; 
      left: 4%;
      right: 250px;
      
  }
}

@media screen and (max-width: 1850px) {
  .CalendarChekout {
     
      top: 55%; 
      left: 4%;
      right: 250px;
      
  }
}

@media screen and (max-width: 1550px) {
  .CalendarChekout {
      top: 52.5%;
      left: 4%;
      right: 250px;
  }
}

@media screen and (max-width: 1350px) {
  .CalendarChekout {
      top: 51.5%;
      left: 4%;
      right: 250px;
  }
}

@media screen and (max-width: 1100px) {
  .CalendarChekout {
      top: 58%;
      left: 4%;
      right: 250px;
  }
}



@media screen and (max-width: 750px) {
  .CalendarChekout {
      top: 82%;
      left: 8%;
      right: 8%;
      width: auto;   
  }
}