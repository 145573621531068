.dropdown-personalize-find-clinics{
    position: absolute;
    top: 60px;
    border-radius: 10px;
    -webkit-box-shadow: 0px 0px 9px -3px rgba(0,0,0,0.37);
    -moz-box-shadow: 0px 0px 9px -3px rgba(0,0,0,0.37);
    box-shadow: 0px 0px 9px -3px rgba(0,0,0,0.37);
    padding: 1rem ;
    background-color: white;
}

.dropdown-personalize-find-clinics input{
    border-radius: 10px;
    border: none;
    padding: .5rem;
    -webkit-box-shadow: 0px 0px 5px -3px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 5px -3px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 5px -3px rgba(0, 0, 0, 0.75);
}

.dropdown-personalize-find-clinics span{
    width: 100%;
    margin-top: .5rem;
    background-color: white;
    border-radius: 10px;
    border: 2px solid #004274;
    color: #004274;
    font-weight: 700;
    padding: .6rem;
    font-size: 15px;
    cursor: pointer;
    transition: .5s ease-in-out;
    display: flex;
    justify-content: center;
}

.dropdown-personalize-find-clinics span:hover{
    background-color: #004274;
    color: white;
    border-color: white;
}