.fonSizeWellezySmall {
    font-size: var(--sizeTextTertiary) !important;
}

.fonSizeWellezyMedium {
    font-size: var(--sizeTextSecundary) !important;
}

.fonSizeWellezyLarge {
    font-size: var(--sizeTextPrimary) !important;
}

.colorWellezyWhite {
    color: var(--white) !important;
}

.colorWellezyBlack {
    color: var(--black) !important;
}