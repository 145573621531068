.contentDetail {
    width: 95%;
    max-width: 1280px;
    margin: 1rem auto;
}

.img_doc {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
}

.cont_description {
    margin-left: 10px;
}

.cont_description p {
    margin-left: 0 !important;
    font-size: 14px !important;
    margin-bottom: 15px !important;
    color: var(--black) !important;
    line-height: normal !important;
    -webkit-font-smoothing: auto !important;
}

.cont_description span {
    font-size: 14px !important;
    margin-bottom: 15px !important;
    margin-left: 0 !important;
    line-height: normal !important;
}



/* ---------------------------CALENDAR CITAS-------------------------------- */

.calendar {
    transition: all .5s ease;
    height: 136px;
    overflow: hidden;
    width: 100%;
    position: relative;
}

.calendar .day {
    width: 96%;
    display: flex;
    flex-direction: column;
}

.calendar .day h3 {
    font-size: 12px;
    color: var(--blueLight);
    font-weight: 700;
    width: 100%;
    margin: 0;
    overflow: hidden;
    text-align: center;
}

.calendar .day p {
    font-size: 9px;
    text-wrap: nowrap;
    overflow: hidden;
    margin-bottom: .6rem;
    text-align: center;
}

.calendar .day ul {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    list-style: none;
    padding: 0;
}

.calendar .day ul li {
    padding: .1rem .3rem;
    border-radius: 5px;
    background-color: var(--greyPrimary);
    text-align: center;
    font-size: 12px;
    transition: 0.5s ease;
}

.calendar .day ul li:hover {
    background-color: var(--greySecundary);
    cursor: pointer;
}


.nextArrow {
    right: 4px !important;
    top: 9px !important;
    position: absolute;
    color:var(--blueLight);
    cursor: pointer;
    z-index:999;
}

.prevArrow {
    left: 4px !important;
    top: 9px !important;
    position: absolute;
    color:var(--blueLight);
    cursor:pointer;
    z-index:999;
}

.img_specialist_skeleton {
    width: 100%;
    object-fit: cover;
    height: 250px;
    max-height: 307px;
    background: linear-gradient(to left, var(--white) 25%, var(--greyPrimary) 50%, var(--white) 75%);
    background-size: 600% 100%;
    animation: skeleton-loading 2s infinite linear;
    display: flex;
    border-top-right-radius: 16px;
    border-top-left-radius: 16px;
    justify-content: center;
    align-items: center;
}


@keyframes skeleton-loading {
    0% {
        background-position: 100% 0;
    }

    100% {
        background-position: -100% 0;
    }
}