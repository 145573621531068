.contain-detail-specialist .slick-next {
    right: 0 !important;
    top: 15px !important;
}

.contain-detail-specialist .slick-prev {
    left: 0 !important;
    top: 15px !important;
}
.contain-detail-specialist {
    overflow: scroll;
    scrollbar-width: none;
  }
  
  .contain-detail-specialist::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }
.contProcedimientosDetailSpecialist {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding-bottom: 20px;
    border-bottom: 2px solid #00afe8; 
}

.contProcedimientosDetailSpecialist p {
    font-size: 14px;
    margin-left: 10px;
}

.contProcedimientosDetailSpecialist label {
    margin-left: 10px;
}
.itemSpecialtyDet{
    display: flex; 
    height: auto;
    gap: 3px;
    margin-top: 10px;
}
.itemSpecialtyDet svg{
    font-size: 25px !important;
}
.itemSpecialtyDet span{
    width: 30px !important; 
}
.topDetailSpecialist{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end; 
    margin-bottom: -1rem;
}
.topDetailSpecialist svg{
    color: #00afe8;
    font-size: 25px;
    cursor: pointer;
}

.noAgendaDiv{
   color: #104172;
   margin-top: -1.5rem;
   margin-bottom: -1.5rem;
}
.noAgendaDiv p{
    margin: 0;
    font-weight: 600;
    font-size: 14px;
}