.pqrsJoinUsContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  border: solid 1px var(--bluePrimary);
  border-radius: 20px;
  padding: 20px;
  margin: 40px auto;
}

.pqrsJoinUsContainer .pqrsJoinUsContainerText {
  color: var(--bluePrimary);
  width: 50%;
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  font-weight: 400;
}

.pqrsJoinUsContainer .pqrsJoinUsContainerBtn {
  background-color: var(--bluePrimary);
  width: 40%;
  padding: 12px;
  display: flex;
  align-items: center;
  border-radius: 12px;
  cursor: pointer;
  transition: all ease 200ms;
}

.pqrsJoinUsContainer .pqrsJoinUsContainerBtn:hover {
  transition: all ease 200ms;
  transform: translateY(-4px);
  -webkit-box-shadow: 0px 5px 5px 3px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 5px 5px 3px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 5px 5px 3px rgba(0, 0, 0, 0.75);
}

.pqrsJoinUsContainer .pqrsJoinUsContainerBtn .pqrsJoinUsContainerBtnText {
  color: var(--white);
  text-align: center;
  width: 100%;
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  font-weight: 400;
}