.contain_filters{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.disable{
    opacity:0.5;
    pointer-events: none;
    cursor: not-allowed;
}

.contain_filters h2{
    font-size: 16px;
    font-weight: 700;
    color: var(--bluePrimary);
}

.contain_filters button{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    background-color: var(--bluePrimary);
    color: var(--white);
    padding: 10px 20px;
    border-radius: 8px;
    cursor: pointer;
    text-align: center;
    font-weight: 600;
    font-size: 14px;
    transition: all 0.3s ease;
}

.contain_filters .line{
    height: 1px;
    width:100%;
    background-color: var(--greyPrimary);
}


.contain_filters .filters{
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: .6rem;
    border-bottom: 1px solid var(--greyPrimary);
    padding-bottom: .6rem;
}

.contain_filters .subtitles{
    font-size: 12px;
    color: var(--bluePrimary);
}

.contain_filters .filters input{
    border-radius: 6px;
    border: 1px solid var(--greyPrimary);
    padding: .5rem;
    font-size: 12px;
}

.contain_filters .filters select{
    border-radius: 6px;
    border: 1px solid var(--greyPrimary);
    padding: .5rem;
    font-size: 12px;
    appearance: none;
    background: url(https://cdn-icons-png.freepik.com/512/10017/10017916.png);
    background-repeat: no-repeat;
    background-size: contain;
    cursor: pointer;
    background-position: right;
}