.content-state-steps{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 30px;
}


.content-state-steps span {
    color: #fff;
    width: 50px;
    min-width: 50px;
    max-width: 50px;
    min-height: 50px;
    max-height: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #e3e3e3;
    display: flex;
    align-items: center;
    justify-content: center;
}

.content-state-steps div {
  height: 6px;
  width: 100%;
  background-color: #e3e3e3;
}
