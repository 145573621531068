.up-img-r {
  z-index: 6;
  width: 47%;
  object-fit: cover;
}

.contain-buscador-transport-r {
  margin: 8rem auto;
  width: 80%;
  height: 760px;
  padding: 1rem;
  box-shadow: 11px 10px 26px 0px rgba(0, 0, 0, 0.28);
  border-radius: 15px;
  display: flex;
  justify-content: space-around;
}

.img-ws-car {
  width: 200px;
}

.campo-radio input {
  width: auto;
}

.content-banner-rentcar {
  width: 80%;
}

.content-banner-rentcar img {
  width: 100%;
  height: 100%;
  border-radius: 22px;
  object-fit: cover;
  object-position: top;
}

.content-buscador-rent-car {
  width: 30%;
  margin-right: 2rem;
  box-shadow: 0px 4px 4px 0px #00000040;
  padding: 2rem;
  border-radius: 19px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media screen and (max-width: 920px) {
  .content-buscador-rent-car {
    width: 45%;
  }

  .content-banner-rentcar {
    width: 50%;
  }
}

.header-rent-car {
  display: flex;
  margin: 2rem auto;
  width: 100%;
  max-width: 1280px;
}
@media screen and (max-width: 800px) {
  .header-rent-car {
    flex-direction: column-reverse;
    gap: 1rem;
  }

  .content-banner-rentcar {
    width: 100%;
  }

  .content-buscador-rent-car {
    width: 100%;
    margin-left: 0px !important;
  }
}

@media screen and (max-width: 1100px) {
  .contain-buscador-transport-r {
    width: 99%;
  }
}

@media screen and (max-width: 600px) {
  .contain-buscador-transport-r {
    flex-direction: column;
    height: auto !important;
    margin: 2rem 0;
  }
}
