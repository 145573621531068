.browseHomeColumnSideLeft {
  transition: all ease 200ms;
  padding-right: 3%;
}

.titleBrowseNeed {
  color: white;
  font-size: 30px;
  margin-bottom: 1rem;
}

.browseHomeColumnSideLeftTitle {
  font-size: 18px;
  font-weight: 800;
  color: #00afe8;
  text-transform: uppercase;
}
.browseHomeColumnSideLeftForm {
  z-index: 99;
  position: relative;
  padding: 1rem;
  width: 100%;
  border: 1px solid #D9D9D9;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.browseHomeColumnSideLeftFormColumns {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: space-between;
}

.browseHomeColumnSideLeftFormColumnsGroup {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  position: relative;
}

.browseHomeColumnSideLeftFormColumnsGroup2 {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  position: relative;
  padding: 9px;
  border-radius: 8px;
  border: 1.3px solid #D9D9D9;
}

/* ----- tabs init ---- */
.browseHomeTabOpen {
  background-color: #ffffff;
  border-bottom: none;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.browseHomeTabLeftClose {
  background-color: #ededed;
  border-top-left-radius: 10px;
  opacity: 0.5;
}

.browseHomeTabRightClose {
  background-color: #ededed;
  border-top-right-radius: 10px;
  opacity: 0.5;
}

/* .tabBorder {
    border-right: solid 1px #00AFE8;
} */

/* ----- tabs end ---- */

.browseHomeColumnSideLeftFormColumnsText {
  display: flex;
  width: 100%;
  gap: 5px;
  flex-direction: column;
}
.browseHomeColumnSideLeftFormColumnsText2 {
  display: flex;
  width: 100%; 
  gap: 1px;
  flex-direction: column;
}
.browseHomeColumnSideLeftFormColumnsTextTitle {
  font-size: 14px;
  text-align: left;
  font-weight: bolder;
  font-family: "Montserrat", sans-serif;
  color: #00afe8;
}

.browseHomeColumnSideLeftFormColumnsTextValue {
  margin-top: 5px;
  font-size: 12px;
  text-align: left;
  font-weight: 400;
  font-family: "Montserrat", sans-serif;
  color: #000000;
}

.browseHomeColumnSideLeftFormColumnsBtn {
  width: 12%;
  height: 30px;
  align-items: center;
  justify-content: center;
  display: flex;
  cursor: pointer;
}
.browseHomeColumnSideLeftFormColumnsBtn2 {
  width: 12%;
  height: 30px;
  align-items: center;
  justify-content: center;
  display: flex;
  cursor: pointer;
  color: #00AFE8;
}
.browseHomeColumnSideLeftFormBtn2 {
  transition: all ease 200ms;
  width: 100%;
  border-radius: 6px;
  margin: auto;
  background: #00afe8;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 6px;
  min-height: 56.3px;
}

.browseHomeColumnSideLeftFormBtn {
  transition: all ease 200ms;
  width: 100%;
  border-radius: 6px;
  margin: auto;
  background: #00afe8;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 6px;
}

.browseHomeColumnSideLeftFormBtnDisabled {
  transition: all ease 200ms;
  width: 100%;
  border-radius: 6px;
  margin: auto;
  background: #00aee874;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 6px;
}

.browseHomeColumnSideLeftFormBtnDisabled2 {
  transition: all ease 200ms;
  width: 100%;
  border-radius: 6px;
  margin: auto;
  background: #00aee874;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 6px;
  min-height: 56.3px;
}

.browseHomeColumnSideLeftFormBtn:hover {
    background: #08bffb;
}
.browseHomeColumnSideLeftFormBtn2:hover {
  background: #08bffb;
}
.browseHomeColumnSideLeftFormBtnText {
  color: #ffffff;
  font-size: 18px;
}

.browseHomeColumnSideLeftFormBtnIcon {
  margin-left: 10px;
}

.browseHomeColumnSideLeftFormListContainer {
  transition: all ease 200ms;
  overflow: hidden;
  overflow-y: scroll;
  height: fit-content;
  max-height: 200px;
  width: 100%;
  margin: auto;
  position: absolute;
  top: 55px;
  background-color: #ffffff;
  border: 1px solid #d2d2d2;
  z-index: 999;
  border-radius: 5px;
}
.browseHomeColumnSideLeftFormListContainer2 {
  transition: all ease 200ms;
  overflow: hidden;
  overflow-y: scroll;
  height: fit-content;
  max-height: 200px;
  width: 100%;
  margin: auto;
  position: absolute;
  top: 55px;
  background-color: #ffffff;
  border: 1px solid #d2d2d2;
  z-index: 999;
  border-radius: 5px;
  margin-left: -9px;
}

.browseHomeColumnSideLeftFormListOption {
  padding: 5px 10px;
  cursor: pointer;
  transition: all ease 200ms;
  border-bottom: 1px solid #ededed;
}

.browseHomeColumnSideLeftFormListOption:hover {
  background: rgba(0, 0, 0, 0.052);
}

.browseHomeColumnSideLeftFormListOptionText {
  font-size: 12px;
  text-align: left;
  font-weight: 400;
  font-family: "Montserrat", sans-serif;
  color: #000000;
}

/* ------------------------------------------*/
.browseHomeColumnSideRight {
  width: 40%;
  transition: all ease 200ms;
  padding-left: 3%;
}

.browseHomeColumnSideRightTitle {
  font-size: 26px;
  text-align: left;
  font-weight: bolder;
  font-family: "Montserrat", sans-serif;
  color: #00afe8;
}

.browseHomeColumnSideRightSubTitle {
  margin-top: 5px;
  font-size: 14px;
  text-align: left;
  font-weight: 400;
  font-family: "Montserrat", sans-serif;
  color: #000000;
}

.browseHomeColumnSideRightBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #00afe8;
  padding: 5px;
  border-radius: 12px;
  cursor: pointer;
}

.iconStoreSad {
  display: block;
  transition: all ease 200ms;
}

.iconStoreHappy {
  display: none;
  transition: all ease 200ms;
}

.browseHomeColumnSideRightBtn:hover .iconStoreHappy {
  transition: all ease 200ms;
  display: block;
}

.browseHomeColumnSideRightBtn:hover .iconStoreSad {
  transition: all ease 200ms;
  display: none;
}

.browseHomeColumnSideRightBtn:hover {
  -webkit-box-shadow: 10px 10px 29px -13px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 29px -13px rgba(0, 0, 0, 0.75);
  box-shadow: 10px 10px 29px -13px rgba(0, 0, 0, 0.75);
}

.browseHomeColumnSideRightBtn:active {
  transform: translateY(2px);
  -webkit-box-shadow: inset 0px 10px 22px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: inset 0px 10px 22px 0px rgba(0, 0, 0, 0.75);
  box-shadow: inset 0px 10px 22px 0px rgba(0, 0, 0, 0.75);
}

.browseHomeColumnSideRightBtnIcon {
  margin-right: 10px;
}

.browseHomeColumnSideRightBtnText {
  color: #ffffff;
  font-size: 18px;
  font-weight: bolder;
  font-family: "Montserrat", sans-serif;
}

@media screen and (max-width: 1270px) {
  .browseHomeColumnSideLeftTitle {
    font-size: 21px;
  }
}

@media screen and (max-width: 860px) {
  .browseHomeColumnSideLeft {
    width: 100%;
    padding-bottom: 40px;
    margin-bottom: 40px;
    padding-right: 0;
    padding: 0 20px;
    border-right: none;
  }

  .browseHomeColumnSideRight {
    padding-left: 0;
    padding: 0 20px;
    width: 100%;
  }

  .browseHomeColumnSideRightTitle {
    text-align: center;
  }

  .browseHomeColumnSideRightSubTitle {
    text-align: center;
    margin: 10px 0;
  }
  .contain-home-browse {
    flex-direction: column;
    gap: 2rem;
  }

  .browseHomeColumnContainer {
    width: 100%;
  }
  .contain-search-fligth-banner {
    width: 100%;
  }
  .logo-home-banner {
    display: none;
  }
  .effect-bacground {
    justify-content: center;
    align-items: center;
  }
}

@media screen and (max-width: 530px) {
  .browseHomeColumnContainer {
    margin-left: 5px;
  }

  .browseHomeColumnSideLeftFormColumns {
    flex-direction: column;
    gap: 0.7rem;
  }

  .browseHomeColumnSideLeftFormColumnsGroup {
    width: 100%;
  }

  .tabBorder {
    border: none;
  }
}


.filterInput {
    padding: 7px 0px;
    border: none;
}
.noResults {
    padding: 8px;
    font-size: 14px;
}




.top-tourism-health{ 
  display: flex;
  flex-direction: column; 
}
.top1-tourism{
  width: 100%;
  margin-bottom: 10px;
}
.browseHomeColumnSideLeftTitleTop {
  font-size: 24px;
  font-weight: 800;
  color: #00afe8;
  text-transform: uppercase;
}
@media screen and (max-width: 1270px) {
  .browseHomeColumnSideLeftTitleTop {
    font-size: 23px;
  }
}
.banner-bottom-medicalpackage{
  width: 100%;
  margin-top: 1.8rem;
  margin-bottom: .4rem;
}
.banner-bottom-medicalpackage img{
  width: 100%;
  border-radius: 13px;
}