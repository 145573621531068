.modal-quo {
    position: fixed;
    z-index: 99999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.731);
    overflow: scroll;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.contain-close-modal {
    padding: 1rem;
    display: flex;
    justify-content: end;

}

.contain-close-modal button {
    color: white;
    font-weight: 700;
    font-size: 25px;
    cursor: pointer;
}

.form-make-quo-specialist {
    width: 95%;
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 10px;
    margin: 1rem auto;
    padding: 1rem;
}

.form-make-quo-specialist legend {
    font-size: 20px;
    font-weight: 700;
    color: #004274;
}

.form-make-quo-specialist p {
    color: rgb(124, 124, 124);
    margin: 1rem 0;
}

.campo-make-quo {
    display: flex;
    flex-direction: column;
    gap: .4rem;
}

.campo-make-quo label {
    margin-top: 1rem;
}

.campo-make-quo input {
    border-radius: 10px;
    border: none;
    padding: .5rem;
    -webkit-box-shadow: 0px 0px 5px -3px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 5px -3px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 5px -3px rgba(0, 0, 0, 0.75);
}

.campo-make-quo textarea{
    border-radius: 10px;
    border: none;
    padding: .5rem;
    -webkit-box-shadow: 0px 0px 5px -3px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 5px -3px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 5px -3px rgba(0, 0, 0, 0.75);
}

.form-make-quo-specialist button{
    width: 100%;
    background-color: white;
    color: #004274;
    border-radius: 10px;
    border: 2px solid #004274;
    cursor: pointer;
    transition: .5s ease-in-out;
    margin-top: 1rem;
    padding: 1rem;
    font-size: 20px;
    font-weight: 700;
}

.form-make-quo-specialist button:hover{
    background-color: #004274;
    border-color: white;
    color: white;
    
}

.error-message-specialist{
    background-color: rgb(212, 3, 3);
    
    text-align: center;
    border-radius: 10px;
    margin-top: 1rem;
}

.error-message-specialist p {
    color: white;
    font-weight: 700;
}

.success-screen-specialist{
    background-color: white;
    border-radius: 10px;
    width: 95%;
    margin: 1rem auto;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
}