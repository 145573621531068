.cardRegisterNow {
    width: 95%;
    height: 23rem;
    background-color: #104172;
    transition: all 0.9s ease;
    position: relative;
    border-radius: 2rem;
    overflow: hidden;
    cursor: pointer;
    margin: 0 auto;
}

.cardRegisterNowActive {
    width: 98%;
    background-color: #104172;
    transition: all 0.9s ease;
    position: relative;
    border-radius: 2rem;
    overflow: hidden;
    margin: 0 auto;
}


.cardRegisterNowContainer {
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: #104172CC;
    z-index: 999;
    transition: all 0.9s ease;
    cursor: pointer;
}

.cardRegisterNowContainerActive {
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: #10417200;
    z-index: 888;
    transition: all 0.9s ease;
}

.cardRegisterNowImg {
    position: absolute;
    right: 0;
    width: 100%;
    height: 100%;
    transition: all 0.9s ease;
    clip-path: polygon(0 0, 100% 0%, 100% 100%, 0 100%);
}

.cardRegisterNowImgActive {
    position: absolute;
    right: 0;
    width: 60%;
    height: 100%;
    transition: all 0.9s ease;
    clip-path: polygon(0 0, 100% 0%, 100% 100%, 38% 100%);
}

.cardRegisterNowImg img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.cardRegisterNowImgActive img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: right;
}



.cardRegisterContentActive {
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
    padding-right: 0;
    transition: all 0.9s ease;
    color: #ffffff;z-index: 999;
    width: 50%;
    padding-bottom: 60px;
}

.cardRegisterContent {
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
    padding-right: 0;
    transition: all 0.9s ease;
    color: #ffffff;z-index: 999;
    width: 80%;
    padding-bottom: 60px;
}

.cardRegisterNowTitle {
    font-size: 2.2rem;
    font-weight:800;
    text-transform: uppercase;
}

.cardRegisterNowTitle span{
    font-weight: 400;
    display: block;
    font-size: 25px;
}
.cardRegisterNowTitleActive span{
    font-weight: 400;
    display: block;
    font-size: 25px;
}

.cardRegisterNowDescription {
    font-size: 1rem;
    font-weight:200;
}


.cardRegisterNowTitleActive {
    font-size: 2.2rem;
    text-transform: uppercase;
    font-weight:800;
}



.cardRegisterNowDescriptionActive {
    font-size: 1rem;
    font-weight:200;
}


.cardRegisterNowButton {
    width: 100%;
    background-color: #57ADE0;
    color: #ffffff;
    font-weight: 900;
    font-size: 14px;
    cursor: pointer;
    transition: all 0.9s ease;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}

.cardRegisterNowButton:hover{
    text-decoration: underline;
}


.cardRegisterNowButtonContain {
    width: 100%;
    height: 65px;
    position: absolute;
    bottom: 20px;
    display: flex;
    justify-content: center;
    z-index: 9999;
}


.cardRegisterNowButtonLink {
    width: 100%;
    display: flex;
    color: #ffffff;
    font-weight: 900;
    font-size: 20px;
    cursor: pointer;
    transition: all 0.9s ease;
    position: relative;
    height: 100%;
}

.cardRegisterNowClip {
    position: absolute;
    left: -4px;
    background-color: #57ADE0;
    width: 55px;
    height: 100%;
    display: none;
    /* clip-path: polygon(1% 0%, 19% 7%, 30% 13%, 37% 19%, 43% 26%, 47% 34%, 49% 43%, 49% 52%, 48% 62%, 45% 71%, 39% 79%, 31% 86%, 19% 93%, 0% 100%, 100% 100%, 100% 0%); */
    /* clip-path: polygon(1% 0%, 19% 6%, 30% 11%, 39% 17%, 46% 24%, 51% 33%, 53% 42%, 54% 52%, 53% 62%, 50% 71%, 43% 80%, 32% 88%, 20% 94%, 0% 100%, 100% 100%, 100% 0%); */
}

.cardRegisterNowClipBorder {
    background-color: #57ADE0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    font-weight: 900;
    cursor: pointer;
    transition: all 0.9s ease;
    font-size: 20px;
}



.underline-effect-card {
    position: relative;
    display: inline-block;
  }
  
  .underline-effect-card::after {
    content: "";
    position: absolute;
    width: 0;
    height: 2px; /* altura del underline */
    background-color: #fff; /* color del underline */
    bottom: 0;
    left: 0;
    transition: width 0.5s ease-in-out; /* tiempo de transición */
  }
  
  .underline-effect-card:hover::after {
    width: 100%;
  }

/* .cardRegisterNowClipBorder:hover{
    text-decoration: underline;
} */

@media screen and (max-width: 850px) {
    .cardRegisterNow {
        /* width: 100%; */
        height: 20rem;
        background-color: #104172;
        transition: all 0.9s ease;
        position: relative;
        border-radius: 2rem;
        overflow: hidden;
    }

    .cardRegisterNowTitle{
        font-size: 1.5rem;
    }
}

/* @media screen and (max-width: 700px) {
    .cardRegisterNow {
        width: 100%;
        height: 20rem;
        background-color: #104172;
        transition: all 0.5s ease;
        position: relative;
        border-radius: 2rem;
        overflow: hidden;
    }
} */


@media screen and (max-width: 600px) {
    .cardRegisterNowButtonLink{
        width: 100%;
    }
    .cardRegisterNowTitleActive, .cardRegisterNowTitle{
        font-size: 1.5rem;
    }
    .cardRegisterNowTitle span{
        
        font-size: 1rem;
    }
    .cardRegisterNowTitleActive span{
      
        font-size: 1rem;
    }
    .cardRegisterNowClipBorder {
        font-size: 16px;
    }

    .cardRegisterNowButton {
        font-size: 12px;
    }
}