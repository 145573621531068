.form-find-b {
  margin-top: 60px;
}

/* New header*/
.headerSearch {
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 35px;
  padding: 0 20px;
}
.headerSearch_location {
  width: 20%;
  background-color: white;
  border-radius: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  padding: 1rem;
  position: relative;
  height: 35px;
}
.headerSearch_location_ {
  width: 90%;
  height: 90%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.headerSearch_locationDroped {
  position: absolute;
  bottom: -120px;
  background-color: white;
  width: 100%;
  height: 127px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  z-index: 99999;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
  border-radius: 0 0px 10px 10px;
}
.headerSearch_locationDropedButton {
  width: 100%;
  height: 35px;
  margin: 5px 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: none;
  border-bottom: 1px solid rgba(0, 66, 116, 0.2);
  cursor: pointer;
  background-color: white;
}

.headerSearch_text {
  font-family: "Inter";
  margin-left: 5px;
  font-weight: 600;
  font-size: 18px;
  color: #004274;
}
.procedureSearch_search {
  width: 55%;
  height: 35px;
  border-radius: 26px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.headerSearch_language {
  width: 20%;
  padding: 1rem;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 26px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  position: relative;
}
.headerSearch_language_ {
  width: 90%;
  height: 90%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

/*Hover*/
.headerSearch_locationDropedButton:hover {
  background-color: rgba(0, 66, 116, 0.2);
  border-radius: 10px;
}

@media screen and (max-width: 1140px) {
  .headerSearch {
    width: 90%;
  }
}
@media screen and (max-width: 1100px) {
  .headerSearch {
    width: 99%;
  }
}
@media screen and (max-width: 720px) {
  .headerSearch {
    width: 90%;
    display: flex;
    flex-flow: wrap;
    justify-content: space-between;
  }
  .headerSearch_location,
  .headerSearch_language {
    width: 30%;
    margin: 5px 0;
  }
  .procedureSearch_search {
    width: 65%;
  }
}
@media screen and (max-width: 580px) {
  .headerSearch {
    flex-direction: row;
    justify-content: center;
  }
  .headerSearch_location,
  .headerSearch_language,
  .procedureSearch_search {
    width: 99%;
  }
  .headerSearch_location {
    order: 1;
  }
  .procedureSearch_search {
    order: 3;
  }
  .headerSearch_language {
    order: 2;
  }
}
