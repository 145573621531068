.contain-info-cards-about {
    width: 55%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}



.content-img-about-us {
    width: 39%;
    height: max-content;
}

.content-img-about-us img {
    width: 100%;
    height: 100%;
}

.conten-card-info-about {
    display: flex;
    align-items: flex-start;
    gap: 8px;
}

.card-about-us h2 {
    margin-bottom: 1rem;
    font-size: 18px;
}

.card-about-us p {
    color: #717478;
    font-size: 15px;
}


@media screen and (max-width:1150px) {
    .contain-info-cards-about {
        width: 100%;
        gap: 2rem;
        justify-content: center;
    }

    .content-img-about-us {
        display: none;
    }
}

@media screen and (max-width:900px) {
    .contain-info-cards-about {
        width: 95%
    }

    .card-about-us {
        width: 100%;
    }
}

@media screen and (max-width:500px) {
    .card-about-us p {
        font-size: 12px;
    }

    .card-about-us h2 {
        font-size: 16px;
    }

    .content-img-about-us {
        display: block;
        width: 90%;
        margin: 2rem auto 0 auto;
    }
}