.appointmentSection {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 40px;
}

.appointmentItems {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.appointment {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
}

.appointment h3 {
  padding-bottom: 5px;
  border-bottom: 2px solid var(--bluePrimary);
  color: var(--bluePrimary);
  font-size: 18px;
}

.total {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 30%;
}

.total h3 {
  padding-bottom: 5px;
  border-bottom: 2px solid var(--bluePrimary);
  color: var(--bluePrimary);
  font-size: 18px;
}




.itemAppointment {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 13px;
  border: 1px solid var(--blueWhiteTableThead);
  border-radius: 10px;
  padding: 15px;
}

.itemHeader {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.itemHeaderIconText {
  display: flex;
  align-items: center;
  gap: 10px;
}

.itemImage {
  padding-left: 20px;
  border-left: 2px solid var(--bluePrimary);
  height: 100%;
  margin-left: 10px;
}

.itemDate {
  font-size: 14px;
  font-weight: 600;
  color: var(--bluePrimary);
}
.itemHeaderText {
  display: flex;
  flex-direction: column;
  gap: 2px;
  color: var(--bluePrimary);
  font-size: 14px;
  font-weight: 500;
}

.itemHeaderText h4 {
  font-size: 18px;
}

.icon {
  font-size: 18px;
  background-color: var(--bluePrimary);
  color: var(--white);
  width: 30px;
  height: 30px;
  border-radius: 6px;
  display: grid;
  place-items: center;
}

.itemDetails {
  display: flex;
  justify-content: space-between;
  gap: 50px;
  align-items: flex-end;
}

.itemBaggage {
  display: flex;
  align-items: flex-end;
  gap: 10px;
  font-size: 20px;
  color: var(--bluePrimary);
}

.luggageList {
  display: flex;
  gap: 10px;
  align-items: flex-end;
  list-style: none;
}

.detailsButton {
  font-size: 14px;
  color: var(--bluePrimary);
  text-decoration: underline;
  cursor: pointer;
}

.itemLinks {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 10px;
  align-items: center;
}

.itemLinks p {
  color: var(--blackGray);
  font-weight: 400;
  display: flex;
  flex-direction: column;
  gap: 3px;
}

.itemLinks p  span {
  color: var(--bluePrimary);
  font-weight: 600;
}

.priceButton {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.price {
  font-size: 18px;
  font-weight: 700;
  color: var(--bluePrimary);
}

.payButton {
  background-color: var(--bluePrimary);
  border-radius: 7px;
  padding: 8px 15px;
  color: var(--white);
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
}

.checkPayButton {
  display: flex;
  gap: 10px;
  align-items: center;
}

.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.container {
  display: block;
  position: relative;
  cursor: pointer;
  font-size: 20px;
  user-select: none;
}





















.filters {
  width: 30%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.filters h3 {
  padding-bottom: 5px;
  border-bottom: 2px solid var(--bluePrimary);
  color: var(--bluePrimary);
  font-size: 18px;
}

.filterButtons {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}

.buttonFilter {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 14px;
  color: var(--blackGray);
  cursor: pointer;
  padding: 10px;
  border-radius: 8px;
  width: 100%;
  font-weight: 400;
}

.buttonFilter.activeFilter {
  background-color: var(--blueWhiteTableThead);
}

.buttonFilterDelete {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 14px;
  font-weight: 600;
  color: var(--bluePrimarys);
  background-color: var(--blueWhiteTableThead);
  cursor: pointer;
  padding: 10px;
  border-radius: 8px;
  width: 100%;
  text-wrap: nowrap;
}

.noItems {
  height: 100%;
  display: grid;
  place-items: center;
  gap: 30px;
  color: var(--bluePrimary);
  font-size: 18px;
  font-weight: 500;
}

.message {
  display: flex;
  align-items: center;
  gap: 10px;
}

.buy {
  background-color: var(--bluePrimary);
  border-radius: 6px;
  color: var(--white);
  padding: 10px 20px;
  transition: all 0.3s ease;
  font-size: 14px;
}

.buy:hover {
  background-color: var(--blueTertiary);
}

.dateInput {
  border: 1px solid var(--greyPrimary);
  border-radius: 4px;
  padding: 8px;
  font-size: 16px;
  width: 100%;
  max-width: 200px;
  margin-top: 5px;
  box-shadow: var(--shawdowPrimary);
}

.dateInput:focus {
  border-color: var(--bluePrimary);
  outline: none;
}


.noItems {
  height: 100%;
  display: grid;
  place-items: center;
  gap: 30px;
  color: var(--bluePrimary);
  font-size: 18px;
  font-weight: 500;
}

.message {
  display: flex;
  align-items: center;
  gap: 10px;
}

.link {
  background-color: var(--bluePrimary);
  border-radius: 6px;
  color: var(--white);
  padding: 10px 20px;
  transition: all 0.3s ease;
  font-size: 14px;
}

.link:hover {
  background-color: var(--blueTertiary);
}

@media screen and (max-width: 550px) {
  .itemLinks {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 10px;
    align-items: flex-start;
    flex-direction: column;
  }
}


@media screen and (max-width: 1000px) {
  

  .appointmentSection {
    flex-direction: column-reverse;
  }

  .filters {
    width: 100%;
    overflow-x: scroll;
  }

  .filterButtons {
    flex-direction: row;
    overflow-x: scroll;
  }

  .buttonFilter {
    text-wrap: nowrap;
  }
}