.gridNotResults {
  display: flex;
  flex-direction: column;
  gap: 15px;
  justify-content: center;
  align-items: center;
  padding-bottom: 30px;
}

.gridNotResults h2 {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 24px;
  font-weight: 600;
  color: var(--bluePrimary);
}

.grid_container_doc {
  display: grid;
  grid-gap: 15px;
  grid-template-columns: repeat(3, 1fr);
}


.returnSearch {
  background-color: var(--bluePrimary);
  color: var(--white);
  padding: 8px 15px;
  border-radius: 8px;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.returnSearch:hover {
  background-color: var(--blueTertiary);
}

@media (max-width: 1000px) {
  .grid_container_doc {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 870px) {
  .grid_container_doc {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 570px) {
  .grid_container_doc {
    width: 100%;
    grid-template-columns: repeat(1, 1fr);
  }
}