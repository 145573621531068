.form-search-universal {
  width: 100%;
  position: relative;
  background-color: white;
  border-radius: 26px;
}
.contain-input-search {
  width: 100%;
  height: 35px;
}
.contain-input-search input {
  padding: 18px;
  border: none;
  border-radius: 26px;
  width: 90%;
  height: 35px;
}

.button_submitSearch {
  width: 10%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.button_submitSearch:hover {
  cursor: pointer;
}
.div_resoults {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 2rem;

  align-items: center;
}
.resoults {
  background-color: white;
  width: 35%;
  max-height: 200px;
  overflow: hidden;
  overflow-y: auto;
  border: 1px solid white;
  border-radius: 0 0 10px 10px;
}

.resoult {
  all: unset;
  cursor: pointer;
  width: 100%;
  display: flex !important;
  font-family: "Inter";
  font-weight: 300;
  padding: 10px 10px;
  text-decoration: none;
  text-align: left;
  color: black;
  border-bottom: 1px solid rgba(0, 66, 116, 0.2);
  align-items: center;
}
.resoult-div_img {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}
.resoult-div_img img {
  width: 100%;
  border-radius: 50%;
}
.resoult:hover {
  background-color: rgba(0, 66, 116, 0.1);
  transition: all 0.3s ease-out;
}

@media screen and (max-width: 1200px) {
  .searchFormControl {
    width: 100%;
  }
  .resoults {
    width: 45%;
  }
}

@media screen and (max-width: 730px) {
  .searchFormControl {
    width: 90%;
  }
  .resoults {
    width: 85%;
  }
  .searchTextField {
    box-sizing: border-box;
    font-size: 14px;
    padding: 27px 0;
  }
  .css-e4w4as-MuiFormLabel-root-MuiInputLabel-root {
    font-size: 14px !important;
  }
  .resoults {
    font-size: 13px;
    z-index: 3 !important;
  }
}
@media screen and (max-width: 720px) {
  .button_submitSearch {
    width: 15%;
  }
}
