.container-all-passengers-sura{
    padding-inline: 110px;
}

.container-header-sura{
    height: 102px;
    padding: 20px;
    background-color: #0032A6;
    box-shadow: 0px 4px 4px 0px #00000040;
    border-radius: 23px 23px 0px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.container-header-sura p{
    color: white;
    font-size: 24px;
    font-weight: 700;
    font-family: "Montserrat" !important;
}

.container-data-passengers{
    display: flex;
    justify-content: space-between;
    align-items: start;
    margin-block: 20px !important;
    width: 100%;
}

.container-form-passengers-sura{
    width: 64%;
    box-shadow: 0px 4px 4px 0px #00000040;
    border-radius: 25px;
    padding: 40px !important;
}

.container-data-description-sura{
    width: 34%;
    box-shadow: 0px 4px 4px 0px #00000040;
    border-radius: 25px;
    padding: 40px !important;
}

.container-data-description-sura p{
    color: #0032A6;
    font-size: 24px;
    font-weight: 700;
    text-align: center;
    font-family: "Montserrat" !important;
}

.container-data-description-sura li{
    padding-block: 5px;
    font-size: 16px;
    font-weight: 300;
    font-family: "Montserrat" !important;
    list-style: none;
}

.container-data-description-sura li::before{
    content: "• ";
    color: #01AEC8;
    font-size: 30px;
}

.p-include p{
    margin-top: 35px;
    font-size: 16px !important;
    font-weight: 600 !important;
    text-align: left !important;
    color: black !important;
}

.container-price-sura{
    border: 1px solid #0032A6;
    border-radius: 8px;
    padding-block: 10px;
    margin-block: 30px;
}

.container-price-sura p{
    font-weight: 400 !important;
    color: black !important;
}

.btn-quote-sura{
    display: flex;
    justify-content: center;
    align-items: center;
}

.btn-quote-sura button{
    width: 50%;
    background-color: #0032A6;
    color: white;
    font-size: 16px;
    font-weight: 400;
    font-family: "Montserrat" !important;
    padding-inline: 20px;
    padding-block: 5px;
    border-radius: 8px;
    cursor: pointer;
    transition: 0.3s;
    border: 1px solid #0032A6;
    height: 40px;
}

.btn-quote-sura button:hover{
    background-color: white;
    color: #0032A6;
    border: 1px solid #0032A6;
    transition: 0.3s;
}

.see-more-venefit-sura{
    text-decoration: underline !important;
    font-size: 14px !important;
    text-align: left !important;
    cursor: pointer;
}

.container-adviser-sura{
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    margin-block: 10px ;
}

.container-adviser-sura p{
    font-size: 16px !important;
    font-weight: 300 !important;
    font-family: "Montserrat" !important;
    color: black !important;
    text-align: left;
}

/* swicht */


.switch-sura {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 34px;
}

.switch-sura input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  background-color: #ccc;
  transition: 0.3s;
  border-radius: 30px;
  height: 20px;
  width: 38px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 3px;
  bottom: 2px;
  background-color: white;
  border-radius: 50%;
  transition: .4s;
}

input:checked + .slider {
  background-color: #0032A6;
}

input:checked + .slider:before {
  transform: translateX(16.5px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

/* End wicth */

.input-adviser-sura{
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.input-adviser-sura input{
    border: 1px solid #D9D9D9;
    border-radius: 8px;
    width: 100%;
    height: 32px;
    padding-inline: 10px;
    transition: 0.3s;
    font-family: "Montserrat" !important;
    font-size: 13px !important;
    font-weight: 300 !important;
}

.container-terms-conditions{
    display: flex;
    justify-content: space-around;
    margin-block: 20px;
}

.p-terms-conditions-sura{
    text-decoration: underline !important;
    color: #0032A6 !important;
    font-size: 14px !important;
    font-weight: 300 !important;
    font-family: "Montserrat" !important;
    cursor: pointer;
}

.p-authorization-sura{
    font-size: 14px !important;
    font-weight: 300 !important;
    color: black !important;
}

.container-p-terms-sura p{
    text-align: left;
}

/* checkbox style */

.checkbox-container {
    display: flex;
    align-items: start;
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    font-size: 22px;
    user-select: none;
}

.checkbox-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.custom-checkbox {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #eee;
    border-radius: 3px;
}

.checkbox-container:hover input ~ .custom-checkbox {
    background-color: #D9D9D9;
}

.checkbox-container input:checked ~ .custom-checkbox {
    background-color: #0032A6;
}

.custom-checkbox:after {
    content: "";
    position: absolute;
    display: none;
}

.checkbox-container input:checked ~ .custom-checkbox:after {
    display: block;
}

.checkbox-container .custom-checkbox:after {
    left: 6px;
    top: 2px;
    width: 5px;
    height: 9px;
    border: solid white;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
}

/* end checkbox */

@media screen and (max-width: 1120px) {
    .container-form-passengers-sura{
        width: 100%;
    }
    
    .container-data-description-sura{
        width: 100%;
    }

    .container-data-passengers{
        flex-direction: column;
    }
}

@media screen and (max-width: 1050px){
    .container-all-passengers-sura{
        padding-inline: 50px;
    }
}

@media screen and (max-width: 750px){
    .container-all-passengers-sura{
        padding-inline: 0px;
    }
}

@media screen and (max-width: 600px){

}