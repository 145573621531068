@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");

* {
  margin: 0;
  padding: 0;
  font-family: "Montserrat", sans-serif !important;
}

:root {
  /*propuesta de azul primario : #0D3878*/
  --bluePrimary: #004274;
  --blueSecundary: #104172;
  --blueTertiary: #185897;
  --blueTertiaryTransparent: #185797bb;
  --blueWhiteTable: #f8fbff;
  --blueWhiteTableThead: #f2f4ff;
  --blueTransparent:#10417280;
  --blueLightTransparent:#00AFE8CC;
  --yellowPrimary: #ffd902;
  --orangePrimary: #ffab51;
  --blueLight: #17aee6;
  --greyPrimary: #d9d9d9;
  --greySecundary: #8d8d8d;
  --white: #ffffff;
  --black: #000000;
  --blackGray: #2a2a2a;
  --transparentModal: #00000073;
  --greenPrimary: #50AE42;
  --greenOffert: #3BCE5C;
  --greenSecundary: #4a9e3bb2;
  --greenTransparent:#2472102d;
  --redPrimary: #FF5151;
  --redSecondary: #ff7474;
  --redSecondaryTransparent: #ff8a8a30;
  --roundedPrimary: 8px;
  --spacingPrimary: 40px;
  --shawdowPrimary: 0 4px 8px rgba(0, 0, 0, 0.1);
  --red:red;
  --sizeTextPrimary: 14px;
  --sizeTextSecundary: 12px;
  --sizeTextTertiary: 10px;

}

/* --------------CARGA WELLEZY-------------------- */

.app {
  position: relative;
}

.carga-wellezy {
  position: absolute;
  width: 100%;
  height: 100vh;
  background-color: var(--bluePrimary);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100000;
  transition: all 0.5s ease-in-out;
}

.carga-wellezy img {
  width: 400px;
}

button {
  border: none;
  background-color: transparent;
}

a {
  text-decoration: none;
}

.w-full {
  width: 100%;
}

.border-red-primary {
  color: var(--redPrimary);
}

.text-red-primary {
  color: var(--redPrimary);
}

.text-gray-secondary {
  color: var(--greySecundary);
}

.font-weight-400 {
  font-weight: 400;
}

.font-weight-500 {
  font-weight: 500;
}