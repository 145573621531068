.flightModal{
    position: absolute;
    box-shadow: -4px 4px 4px rgba(0, 0, 0, 0.25) !important;
    border-radius: 12px;
    top: 20px;
    right: 6%;
    left: 8%;
    width: auto;
}
.flightModal_{
    position: relative;
 
   background-color: white;
    border-radius: 12px;
    height: 100%;
    display: grid;
    grid-template-rows: 6% 94%;
 }

.flightModal_border-location{
    font-family: 'Oswald';
    margin: 12px 0px 12px 0px;
    display: grid;
    grid-template-columns:  auto auto auto;
}
.flightModal_border-location-two{
    font-family: 'Oswald';
    margin: 12px 0px 12px 0px;
    display: grid;
    grid-template-columns:  auto auto;
  
}
.flightModal_border-location-one{
    font-family: 'Oswald';
    margin: 12px 0px 12px 0px;
    display: grid;
    grid-template-columns:  auto; 
}
.cityAndAirport{
    margin-top: 15px;
    
}

 .flightModal_Title{
    margin: 0px 0px 0px 2%;
    font-size: 22px;
}
.flightModal_border{
    border: 2px solid rgba(0, 0, 0, 0.234);
    margin: 5px 2% 0px 2%;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    display: flex;
    flex-direction: row;
    background-color: white;
    color: black; 
}
.itemScalaHeaderAndBody{
    display: grid;
    grid-row: 50 50%;
    width: 100%;
    
}
.itemScalaHeaderAndBody-space{
    margin:5% 0% 0px 12% ;
}

.itemScala{
    height: 100%;
    display: grid;
    grid-template-columns: 50% 50%;
    align-items: center;
    justify-content: center;
}
.itemScala_{
    display: grid;
    grid-template-rows: 20% 20% 20% 20% 20%;
    align-items: center;
    justify-content: center;
}
.itemScala_ p, .itemScala_center p{
    text-align: center;
}
.itemScala_center{
    display: grid;
    grid-template-rows:50% 50%;
    align-items: center;
    justify-content: center;
}
.itemScalaInformationFlight{
    width: 40%;
    display: grid;
    grid-template-rows: 33% 33% 33%;
    justify-content: center;
    align-items: center;
}
.itemScalaInformationFlight_item{
    margin-bottom: 6%;
    
}
.itemScalaInformationFlight_item .itemScalaInformationFlight_item_tittle{
    font-family: 'inter';
    font-size: 18px;
    font-weight: 300;
}
.itemScalaInformationFlight_item  .itemScalaInformationFlight_item_SubTittle{
    font-family: 'inter';
    font-size: 16px;
    font-weight: 600;
}

.itemScalaHeaderAndBody_ .FlightTime_ svg{
    width: 100%;
    transform: rotate(90deg);
    height: 25px;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
}
.headerModalFlight{
    margin-left: 20px;
    margin-top: 20px;
}
.screenSelectedModal{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #004274;
    color:white;
    margin: 5px 1% 10px 1% ;
    border-radius:  8px;
    -webkit-border-radius:  8px;
    -moz-border-radius:  8px;
    -ms-border-radius:  8px;
    -o-border-radius:  8px;
    border: 2px solid rgba(0, 0, 0, 0.234);
}

.itemScalaHeaderAndBody_{
    display: flex;
    justify-content: center;
    align-items: center;
}

.cardFlightScala{
    
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0.234);
    background-color: #004274;
    color: white;
    margin-left: 5%;
   
}

.cardFlightScala h3{
   text-align: center;
    width: 80%;
    margin: 0px 10% 0px 10%;
}

@media screen and (max-width: 1300px) {
    .tittleChekoutMdodal{
        font-size: 12px;
    }
}

@media screen and (max-width: 900px) {
    .flightModal_border{
      
        flex-direction: column;
    }
    .itemScalaInformationFlight{
        width: 100%;
    }
    .ScalaInformationFlight-element{
text-align: center;
    }
}

@media screen and (max-width: 500px) {
    .flightModal{
        
        top: 0px;
        width: auto;
    }
    .cityAndAirport{
        margin-top: 20px;
    }

 .flightModal_border{
   
    margin: 0px;
    height: auto;
}

}