.itemSectionAll {
  width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 20px;
    scroll-behavior: smooth;
}

.stepToStepHome {
  width: fit-content;
}

.stepToStep {
  width: 100%;
  display: flex;
  gap: 20px;
}

.itemSection {
  display: flex;
  gap: 20px;
  align-items: center;
  padding: 20px;
  width: 100%;
  padding-left: 30px;
  height: 60px;
  transform: skew(15deg);
  background-color: var(--blueWhiteTableThead);
  color: var(--blackGray);
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.3s ease;
  color: var(--bluePrimary);
}

.itemSection:hover {
  background-color: var(--greyPrimary);
}

.itemSection.active {
  display: flex;
  gap: 20px;
  align-items: center;
  padding: 20px;
  padding-left: 30px;
  width: 100%;
  height: 60px;
  transform: skew(15deg);
  background-color: var(--blueTertiary);
  color: var(--white);
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.itemSection.completed {
  background-color: var(--blueTertiaryTransparent);
  color: var(--white);
}

.itemSection.active:hover {
  background-color: var(--bluePrimary);
}

.itemText {
  transform: skew(-20deg);
}


.itemSectionHome {
  display: flex;
  gap: 20px;
  align-items: center;
  padding: 20px;
  width: 100%;
  padding: 0px 30px;
  height: 60px;
  transform: skew(15deg);
  background-color: var(--blueWhiteTableThead);
  color: var(--blackGray);
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.3s ease;
  color: var(--bluePrimary);
}

.itemSectionHome:hover {
  background-color: var(--bluePrimary);
  color: var(--white);
}