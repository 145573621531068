.content-treatment-time{
    width: 100%;
    margin-bottom: 15px;
}

.contain-checkbox-treatment{
    display: flex;
    gap: 2rem;
    margin-top: 1rem;
}

.content-treatment-time h2{
    font-size: 30px;
    color: var(--bluePrimary);
}


@media screen and (max-width:1100px) {
    .content-treatment-time{
        width: 95%;
    }
}