.AlertInfoContentModal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(2, 22, 36, 0.22);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999;
  transition: all 0.3s ease;
  padding: 30px;
}

.prueba {
  transition: all 0.3s ease;
}

.AlertInfoContentModal.close {
  opacity: 0;
  pointer-events: none;
}

.AlertInfoContentModalContent {
  background-color: white;
  width: 100%;
  max-width: 480px;
  border-radius: 12px;
  padding: 18px;
  position: relative;
  opacity: 1;
  transition: all 0.3s ease;
  transform: translateY(0px);
  z-index: 99999;
  text-align: center;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.174);
}

.AlertInfoContentModalContent.close {
  opacity: 0;
  transform: translateY(-100px);
}

.AlertInfoContentModalContentClose {
  position: absolute;
  right: 7px;
  top: 7px;
  cursor: pointer;
  z-index: 999999;
}

.close-disabled {
  width: 20px !important;
  height: 20px !important;
}

.AlertInfoContentModalContentTitle {
  font-size: 2rem;
  font-weight: 600;
  color: #002845be;
}

.AlertInfoContentModalContentDesc p {
  font-size: 1.5rem !important;
  color: #02162493 !important;
}

.AlertInfoContentModalContentInfo {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 30px 10px;
}

.iconAlertModalDevelop {
  width: 100px;
  height: 100px;
  padding: 10px;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  background-color: #ff5b5b24;
}

.iconLinkp {
  height: 100%;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.colorBlue {
  color: #104172 !important;
}

/* disabledModal.css */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-50px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
    transform: translateY(0px);
  }
  to {
    opacity: 0;
    transform: translateY(-50px);
  }
}

@keyframes fadeInBackground {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOutBackground {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.AlertInfoContentModalContent {
  animation-duration: 0.3s;
  animation-fill-mode: both;
}

.AlertInfoContentModal {
  animation-duration: 0.3s;
  animation-fill-mode: both;
}

.AlertInfoContentModal.fadeInBackground {
  animation-name: fadeInBackground;
}

.AlertInfoContentModal.fadeOutBackground {
  animation-duration: 0.3s;
  animation-name: fadeOutBackground;
}

.AlertInfoContentModalContent.fadeIn {
  animation-name: fadeIn;
}

.AlertInfoContentModalContent.fadeOut {
  animation-duration: 0.3s;
  animation-name: fadeOut;
}

.close {
  display: none;
}
