.form-pay-pack-health{
    width: 100%;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.form-pay-pack-health legend{
    font-size: 24px;
    font-weight: 700;
    color: var(--bluePrimary);
    margin-bottom: 1rem;
    text-align: center;
}

.form-pay-pack-health div{
    display: flex;
    flex-direction: column;
}

.form-pay-pack-health div label{
    font-size: 12px;
    font-weight: 700;
    color: var(--bluePrimary);
}

.form-pay-pack-health div input{
    padding: 0.8rem 1rem;
    border-radius: 8px;
    border: solid 1px #d9d9d9;
}

.form-pay-pack-health div textarea{
    padding: 0.8rem 1rem;
    border-radius: 8px;
    border: solid 1px #d9d9d9;
}

.form-pay-pack-health button{
    border: none;
    background-color: var(--bluePrimary);
    color: white;
    font-weight: 700;
    font-size: 16px;
    padding: .8rem;
    cursor: pointer;
    width: 100%;
    border-radius: 8px;
}

.form-pay-pack-health .terms{
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 8px;
}

.form-pay-pack-health .terms label{
    font-size: 12px;
    cursor: pointer;
    color: #979797;
}

.input-validate{
    border: 1px solid red;
}