.changeLanguageContainer{
    min-height: 50px;
    width: 50px;
    margin: 0 10px;
}

.changeLanguageCurrentLanguage{
    height: 50px;
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.changeLanguageCircle{
    width: 32px;
    height: 32px;
    border-radius: 16px;
    background-color: #FFF;
    overflow: hidden;
    cursor: pointer;
    z-index: 9999;
}
.changeLanguageCircleFlag{
    width: 100%;
    height: 100%;
} 

.changeLanguageList{
    position: absolute;
    width: 50px;
    display: block;
    z-index: 9999;
}

.changeLanguageContainerOption{
    transition: all ease 200ms;
    width: 32px;
    height: 32px;
    border-radius: 16px;
    margin: 5px 7.5px;
    -webkit-box-shadow: -2px 10px 14px -3px rgba(0,0,0,0.75);
    -moz-box-shadow: -2px 10px 14px -3px rgba(0,0,0,0.75);
    box-shadow: -2px 10px 14px -3px rgba(0,0,0,0.75);
}

.notAvailable{
    filter: grayscale(100%);
}

.changeLanguageContainerOption:hover{
    scale: 1.1;
}