.connect-miles {
  background-color: var(--bluePrimary);
  padding: 2rem 1rem;
  width: 100%;
  border-radius: 15px;
  display: flex;
  justify-content: space-between;
  margin: 2rem 0;
}

.connect-miles p {
  width: 50%;
  overflow-y: scroll;
  color: var(--white);
  font-weight: 300;
  font-size: 14px;
}

.content-button-acions-hotel-reserve {
  display: flex;
  justify-content: space-between;
}

.btn-pay-hotel {
  width: 100%;
  border: none;
  background-color: var(--bluePrimary);
  padding: 1rem;
  color: var(--white);
  border-radius: 15px;
  font-weight: bold;
  font-size: 17px;
  cursor: pointer;
  transition: 0.5s ease-in-out;
}

.btn-pay-hotel:hover {
  transform: scale(1.03);
}

.content-state-pay-state {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.content-state-pay {
  display: flex;
  padding: 1rem;
  gap: 10px;
  align-items: center;
  border-radius: 8px;
  color: var(--white);
}

.content-state-pay.APPROVED {
  background-color: var(--greenSecundary);
}

.content-state-pay.PENDING {
  background-color: var(--yellowPrimary);
}

.content-state-pay.REJECTED {
  background-color: var(--redPrimary);
}

.content-state-pay-message {
  display: flex;
  gap: 5px;
  align-items: center;
}

.content-state-pay h3 {
  margin-right: 5px;
  font-size: 18px;
}

.content-state-pay p {
  display: flex;
  gap: 6px;
}

.content-state-pay p div {
  height: 20px;
  width: 20px;
  background-color: var(--greenPrimary);
  border-radius: 50%;
}

@media screen and (max-width: 500px) {
  .connect-miles p {
    font-size: 13px;
    text-align: center;
    width: 100%;
  }

  .connect-miles {
    flex-direction: column;
    gap: 10px;
    width: 95% !important;
  }
}

@media screen and (max-width: 900px) {
  .content-state-pay-state {
    width: 100%;
  }
}

.contain-img-principal-button-hotel-pay {
  display: flex;
  flex-direction: column;
}

.contain-img-principal-hotel-pay {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  cursor: pointer;
  margin-bottom: 15px;
}

.contain-img-principal-hotel-button-pay {
  width: 50%;
  background-color: var(--bluePrimary);
  padding: 10px;
  border-radius: 8px;
  cursor: pointer;
  color: var(--white);
  font-size: 14px;
}

.section-img-big-pay {
  width: 100%;
}

.section-img-1-pay {
  width: 100%;
  height: 100%;
  max-height: 230px;
  min-height: 230px;
  border-radius: 10px;
  overflow: hidden;
}

.section-img-layout-for-pay {
  width: 100%;
  height: 100%;
  display: flex;
  gap: 10px;
}

.section-img-layout-1-pay {
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
  min-height: 230px;
}

.section-img-layout-2-pay {
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
  min-height: 230px;
}

@keyframes skeleton-loading-pay {
  0% {
    background-position: 100% 0;
  }
  100% {
    background-position: -100% 0;
  }
}

.img-hotel-skeleton-pay {
  width: 100%;
  height: 100%;
  min-height: 230px;
  object-fit: cover;
  background: linear-gradient(
    to left,
    var(--white) 25%,
    var(--greyPrimary) 50%,
    var(--white) 75%
  );
  background-size: 600% 100%;
  animation: skeleton-loading-pay 2s infinite linear;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 500px) {
  .section-img-layout-for-pay {
    display: none;
  }
}
