.route-page,
.contenedor-principal-resultados,
.content-search-trns {
    width: 95%;
    max-width: 1280px;
    margin: 1rem auto 3rem auto;
    display: flex;
    justify-content: space-between;
    gap: 20px;
}

.content-search-trns {
    display: none;
}


.route-page :first-child span {
    color: rgb(127, 210, 255);
    text-decoration: underline;
}

.route-page h3 {
    font-size: 20px;
    font-weight: 400;
    color: rgb(37, 37, 37);
}

.route-page h3 a {

    font-weight: 400;
    color: rgb(37, 37, 37);
}


.route-page {
    display: flex;
    justify-content: space-between;
}

.filter-order {
    display: flex;
    align-items: center;
    gap: 2px;
}

.filter-order div {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 5px;
    border-radius: 10px;
    border: 1px solid rgb(88, 88, 88);
}

.filter-order span {
    font-size: 12px;
    margin-right: 15px;
    color: rgb(88, 88, 88);
}

.filter-order p {
    font-size: 14px;
    font-weight: 600;
    color: var(--bluePrimary);
    text-transform: uppercase;
}






/* -------------------------- ASIDE --------------------------------- */

.filters-container-transfers {
    width: 200px;
    min-width: 160px;
    max-width: 200px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.filters-container form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.buscador-mobile {
    width: 100%;
    display: none;
}

.buscador-mobile form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.filters-box {
    display: flex;
    border-radius: 15px;
    flex-direction: column;
    gap: 1rem;
}

.filters-box h2 {
    font-size: 16px;
    font-weight: 700;
    color: var(--bluePrimary);
    padding-bottom: .5rem;
}

.filters-box h3 {
    color: var(--bluePrimary);
    font-size: 16px;
    font-weight: 700;
}

.stars-filter {
    display: flex !important;
    flex-direction: row !important;
    gap: 5px;
}

.container-filter-price-transport {
    display: flex;
    flex-direction: column;
    gap: 8px;
    border-top: 1px solid var(--greyPrimary);
    padding-top: 1rem;
}

.input-filtrado-price {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

.input-filtrado-price div {
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 100%;
}

.input-filtrado-price h3 {
    color: var(--bluePrimary);
}

.input-filtrado-price div label {
    color: var(--bluePrimary);
    text-align: start;
    font-size: 10px;
}

.input-filtrado-price div input {
    border: 1px solid #0000001A;
    border-radius: 5px;
    padding: 10px;
    height: 33px;

}

.aplicar-filtro {
    width: 100% !important;
    margin: .5rem 0;
}

.top-m {
    margin-top: 1rem;
    font-size: 13px;
}

.aplicar-filtro button {
    width: 100%;
    padding: 10px;
    background-color: var(--bluePrimary);
    color: var(--white);
    border-radius: 5px;
    font-size: 12px;
    border: none;
    text-align: center;
    cursor: pointer;
}

.input-filtrado-passenger {
    display: flex;
    flex-direction: column;
    margin-top: .5rem;
    gap:8px;
}

.input-filtrado-passenger label {
    color: var(--bluePrimary);
    text-align: start;
    font-size: 10px;
}


.input-filtrado-passenger .div-input-pass {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.content-number-passenger {
    display: flex;
    justify-content: center;
}

.content-number-passenger input {
    border: none;
    margin: 1rem 0;
    width: 60px;
    text-align: center;
    color: var(--bluePrimary);
    font-size: 12px;
}

/* --------------- CARD TRASLADOS --------------------------- */


.content-all-card-t {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}



.img-transport-skeleton {
    width: 100%;
    height: 100%;
    min-height: 280px;
    object-fit: cover;
    background: linear-gradient(to left, #ffffff 25%, #f0f0f0 50%, #ffffff 75%);
    background-size: 600% 100%;
    animation: skeleton-loading 2s infinite linear;
    display: flex;
    justify-content: center;
    align-items: center;
}
.img-transport-skeleton-cards {
    width: 100%;
    height: 100%;
    min-height: 200px;
    object-fit: cover;
    background: linear-gradient(to left, #ffffff 25%, #f0f0f0 50%, #ffffff 75%);
    background-size: 600% 100%;
    animation: skeleton-loading 2s infinite linear;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
}

.img-transport-skeleton2 {
    width: 100%;
    height: 100%;
    min-height: 500px;
    object-fit: cover;
    background: linear-gradient(to left, #ffffff 25%, #f0f0f0 50%, #ffffff 75%);
    background-size: 600% 100%;
    animation: skeleton-loading 2s infinite linear;
    display: flex;
    justify-content: center;
    align-items: center;
}

.card-traslado {
    display: flex;
    max-height: 230px;
    justify-content: space-around;
    width: 100%;
    border: 1px solid #10417233;
    border-radius: 15px;
    position: relative;
}

.mini-modal{
    position: absolute;
    top: 0;
    left: 0;
    border-top-left-radius: 15px;
    border-bottom-right-radius: 10px;
    background-color: var(--bluePrimary);
    color: var(--white);
    font-weight: 700;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: .5rem;
}

.layout-card-traslado {
    width: 33.333%;
}

.layout-card-traslado-second {
    width: 66.666%;
    display: flex;
    justify-content: space-around;
    padding: 2rem 1rem;
}

.content-info {
    width: 70%;
    display: flex;
    gap: 1rem;
    flex-direction: column;
    justify-content: space-around;
}


.content-info h3 {
    color: var(--bluePrimary);
    font-size: 22px;
    font-weight: 600;
    text-transform: uppercase;
}

.content-img img {
    width: 100%;
}

.content-img {
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;

}

.price-card {
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.content-information-p {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1rem;
}

.information-car p {
    font-size: 12px;
    color: var(--bluePrimary);
}

.cuantity-p {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
    margin: 1rem 0;
}

.icons-cuantity {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 3px;
}

.icons-cuantity span {
    font-size: 12px;
    font-weight: 500;
    color: var(--bluePrimary);
}

.icons-cuantity img {
    width: 22px;
    height: 22px;
}

.price-content,
.more-details {
    margin-left: 10px;
}

.price-content span {
    color: var(--bluePrimary);
    font-size: 18px;
    text-decoration: line-through;
}

.price-content h3 {
    font-size: 22px;
    font-weight: 600;
    color: var(--bluePrimary);
}

.price-content p {
    margin-top: 1rem;
    font-size: 12px;
    color: var(--bluePrimary);
}


.content-reserva {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: .5rem;
}

.content-reserva button {
    background-color: var(--bluePrimary);
    color: var(--white);
    padding: 13px;
    border-radius: 5px;
    font-size: 14px;
    font-weight: bold;
    width: 100%;
    border: none;
    cursor: pointer;
    transition: all .5s ease;
}

.content-reserva button:hover{
    background-color: var(--blueTertiary);
}

.more-details p {
    font-size: 14px;
    text-align: end;
    color: var(--bluePrimary);
    cursor: pointer;
}

.hamburger-menu-filters {
    display: none;
    justify-content: flex-start;
    width: 100%;
}

.route-page h3 {
    font-size: 15px;
}

.filter-order p {
    font-size: 15px;
}

.hamburger-menu-filters span {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    font-size: 18px;
    width: 100%;
    background-color: var(--bluePrimary);
    color: var(--white);
    padding: 1rem;
    border-radius: 10px;
    gap: 5px;
}

.btn-reset-fil-trans{
    background-color: var(--bluePrimary);
    display: flex;
    padding: 13px 1rem;
    border-radius: 8px;
    cursor: pointer;
    width: 100%;
    color: var(--white);
    font-size:16px;
    font-weight: 600;
    justify-content: center;
    align-items: center;
}



/* ---------------------------- DETAIL CARD MODAL  ------------------------- */


.content-all-info-detail-transport{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
}


.content-all-info-detail-transport h2{
    font-size: 18px;
    color: var(--bluePrimary);
}

.content-all-info-detail-transport .code-reser{
    font-size: 14px;
    color:rgb(42, 42, 42);
}


.content-all-info-detail-transport .code-reser span{
    color: var(--bluePrimary);
    font-weight: 700;
}

.content-all-info-detail-transport .line-separation-transport{
    width: 100%;
    height: 1px;
    background-color: #d2d2d2;
}

.content-all-info-detail-transport .info-detail-transport{
    display: flex;
    flex-direction: column;
    gap: .4rem;
}

.content-all-info-detail-transport .info-detail-transport .camp-detail{
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    flex-wrap: wrap;
}

.content-all-info-detail-transport .info-detail-transport .camp-detail h3{
    color: #757575;
    font-size: 14px;
    display:flex;
    align-items: center;
    font-weight: 400;
}

.content-all-info-detail-transport .info-detail-transport .camp-detail span{
    font-size: 14px;
    font-weight: 500;
    color: #2a2a2a;
}

.note-trans{
    width: 100%;
}

.note-trans p{
    font-size: 14px;
    color: rgb(117, 117, 117);
}


/* ------------------ RESPONSIVE ---------------------- */

.range-passenger {
    height: 4px;
    width: 100%;
    background-color: var(--blueTransparent);
    border-radius: 50px;
    appearance: none;
}

/* .range-passenger::-moz-range-thumb {
    width: 15px;
    height: 15px;
    background-color: var(--bluePrimary);
    border-radius: 50%;
    border: 0;
    color: var(--bluePrimary);
} */


.range-passenger[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    /* Eliminar el estilo predeterminado */
    width: 18px;
    /* Ancho de la bolita */
    height: 18px;
    /* Alto de la bolita */
    background: var(--bluePrimary);
    /* Color de la bolita */
    cursor: pointer;
    /* Cursor cuando se pasa por encima */
    border-radius: 50%;
    /* Hacer la bolita redonda */
}



/* Estilo para navegadores Mozilla (Firefox) */
.range-passenger[type="range"]::-moz-range-thumb {
    width: 18px;
    /* Ancho de la bolita */
    height: 18px;
    /* Alto de la bolita */
    background: var(--bluePrimary);
    /* Color de la bolita */
    cursor: pointer;
    /* Cursor cuando se pasa por encima */
    border-radius: 50%;
    /* Hacer la bolita redonda */
}



/* Estilo para navegadores IE */
.range-passenger[type="range"]::-ms-thumb {
    width: 18px;
    /* Ancho de la bolita */
    height: 18px;
    /* Alto de la bolita */
    background: var(--bluePrimary);
    /* Color de la bolita */
    cursor: pointer;
    /* Cursor cuando se pasa por encima */
    border-radius: 50%;
    /* Hacer la bolita redonda */
}




/* input[type="range"]::-moz-range-progress {
    background-color: var(--bluePrimary);
} */



input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}


@keyframes skeleton-loading {
    0% {
        background-position: 100% 0;
    }

    100% {
        background-position: -100% 0;
    }
}


.css-1aznpnh-MuiSlider-root{
    width: 90% !important;
    margin: 0 auto 1rem auto !important;
}

/* .css-eg0mwd-MuiSlider-thumb{
    width: 18px !important;
    height: 18px !important;
} */





/* .css-1eoe787-MuiSlider-markLabel, .css-yafthl-MuiSlider-markLabel{
    font-size: 12px !important;
    font-weight: 600 !important;
    color: var(--bluePrimary) !important;
} */


/* .css-1eoe787-MuiSlider-markLabel[data-index="0"] {
    left: 10% !important;
}

.css-1eoe787-MuiSlider-markLabel[data-index="1"] {
    left: 85% !important;
}
.css-yafthl-MuiSlider-markLabel[data-index="0"]{
    left: 10% !important;
}
.css-yafthl-MuiSlider-markLabel[data-index="1"]{
    left: 85% !important;
} */

@media screen and (max-width:1100px) {
    .price-content span {
        font-size: 16px;
    }

    .price-content h3 {
        font-size: 20px;
    }

    .information-car p {
        font-size: 12px;
    }

    .content-info h3 {
        font-size: 20px;
    }

    .content-info {
        width: 65%;
    }

    .price-card {
        width: 35%;
    }
}


@media screen and (max-width:900px) {
    .contenedor-principal-resultados {
        flex-direction: column;
        justify-content: start;
        gap: 1rem;
        align-items: center;
    }

    .filters-container {
        width: 100%;
    }

    .content-all-card-t {
        width: 100%;
    }

    .filters-container {
        display: none;
    }

    .buscador-mobile {
        width: 100% !important;
    }

    .buscador-mobile form {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }

    .hamburger-menu-filters {
        display: flex;
        justify-content: space-between;
    }

    .buscador-mobile {
        display: block;
    }

    .display-none-t {
        display: none !important;
    }

    .content-search-trns {
        display: flex;
    }
    .css-1aznpnh-MuiSlider-root{
        width: 98% !important;
        margin: 0 auto 1rem auto !important;
    }
    /* .css-1eoe787-MuiSlider-markLabel[data-index="0"] {
        left: 14% !important;
    }
    
    .css-1eoe787-MuiSlider-markLabel[data-index="1"] {
        left: 88% !important;
    }
    .css-yafthl-MuiSlider-markLabel[data-index="0"]{
        left: 14% !important;
    }
    
    .css-yafthl-MuiSlider-markLabel[data-index="1"]{
        left: 88% !important;
    } */
}


@media screen and (max-width:590px) {
    .card-traslado {
        flex-direction: column;
        max-height: none;
    }

    .layout-card-traslado-second {
        width: 100%;
    }

    .icon-card-t {
        width: 18px !important;
    }

    .content-all-card-t {
        width: 100%;
        margin-top: 2rem;
    }

    .content-info {
        width: 100%;
    }

    .price-card {
        width: 50%;
    }

    .content-reserva {
        justify-content: flex-start;
    }

    .content-img {
        padding: 0;
    }

    .layout-card-traslado {
        width: 50%;
        margin: 0 auto;
    }
}

@media screen and (max-width:430px) {
    .route-page {
        flex-direction: column;
        width: 95%;
        gap: 1rem;
        margin: 1rem auto;
    }

    .price-content span {
        font-size: 14px;
    }

    .price-content h3 {
        font-size: 16px;
    }

    .information-car p {
        font-size: 10px;
    }

    .content-info h3 {
        font-size: 16px;
    }

    .price-content p {

        font-size: 10px;

    }

    .content-reserva button {
        padding: .5rem;
        border-radius: 5px;
        font-size: 11px;

    }

}

