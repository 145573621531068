.contain-form-make-reservation {
  margin: 2rem auto;
  width: 80%;
}

.contain-hotel-checkout {
  width: 100%;
}

.contain-hotel-pay {
  width: 95%;
  max-width: 1280px;
  margin: 2rem auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.list-modal-preserve-modal{
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.conten-modal-prereserve{
  font-size: 16px;
  color: var(--bluePrimary);
}

.conten-modal-prereserve{
  font-size: 16px;
  color: var(--bluePrimary);
}

.btn-reserve-hotel-modal-prereserve{
  width: fit-content;
  min-width: fit-content;
  cursor: pointer;
  background-color: var(--bluePrimary);
  font-family: inherit;
  display: flex;
  align-items: center;
  padding: 0px 15px;
  line-height: 2.5em;
  position: relative;
  overflow: hidden;
  border: 2px solid var(--bluePrimary);
  transition: color 0.5s;
  z-index: 1;
  font-size: var(--sizeTextPrimary);
  border-radius: var(--roundedPrimary);
  font-weight: 600;
  color: var(--white);
}

.btn-reserve-hotel-modal-prereserve:before{
  content: "";
  position: absolute;
  z-index: -1;
  background: var(--white);
  color: var(--bluePrimary);
  height: 550px;
  width: 1500px;
  border-radius: 50%;
}

.btn-reserve-hotel-modal-prereserve:hover{
  color: var(--bluePrimary);
}

.btn-reserve-hotel-modal-prereserve:before{
  top: 100%;
  left: 100%;
  transition: all 0.7s;
}

.btn-reserve-hotel-modal-prereserve:hover:before{
  top: -100px;
  left: -200px;
}

.btn-reserve-hotel-modal-prereserve:active:before{
  background: white;
  transition: background 0s;
}

.layout-hotel-pay {
  width: 100%;
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.main-hotel-pay {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  color: var(--bluePrimary);
}

.main-hotel-title-pay {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.main-hotel-title-pay h2 {
  font-size: 24px;
  color: var(--bluePrimary);
}

.main-hotel-title-pay p {
  font-size: 18px;
}

.main-hotel-pay h3 {
  font-size: 18px;
}

.main-hotel-pay h4 {
  font-size: 14px;
}

.return-url {
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: space-between;
  color: var(--bluePrimary);
  font-weight: 700;
  width: 100%;
  margin-bottom: 1rem;
}

.return-url div {
  display: flex;
  gap: 5px;
  align-items: center;
  color: var(--bluePrimary);
  cursor: pointer;
}

.aside-hotel-pay {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: fit-content;
  justify-content: space-between;
  gap: 2rem;
  border: solid 1px var(--greyPrimary);
  padding: 20px;
  border-radius: 10px;
}

.aside-hotel-pay-detail {
  display: flex;
  flex-direction: column;
  gap: 3rem;
}

.content-button-reservation-hotel {
  display: flex;
  flex-direction: column;
  gap: 0.7rem;
  width: 100%;
}

.content-button-reservation-hotel button {
  padding: 0.8rem;
  color: white;
  font-weight: 500;
  font-size: 18px;
  background-color: var(--bluePrimary);
  border-radius: 8px;
  cursor: pointer;
  border: none;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  transition: all 0.3s ease;
}

.content-button-reservation-hotel button:hover {
  background-color: var(--bluePrimary);
}

.content-button-reservation-hotel p {
  width: 100%;
  color: var(--black);
  font-weight: 300;
  font-size: 18px;
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.content-button-reservation-hotel p span {
  color: var(--bluePrimary);
  font-weight: bold;
}

.detail-reservation-result {
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 100%;
  gap: 1rem;
  padding-bottom: 10px;
}

.detail-reservation-result h2 {
  color: var(--bluePrimary);
  text-align: center;
  font-size: 22px;
  font-weight: 700;
}

.detail-reservation-result h3 {
  color: var(--bluePrimary);
  text-align: center;
  font-size: 18px;
  font-weight: 600;
}

.detail-reservation-result p {
  text-align: center;
  font-weight: bold;
  margin-bottom: 2rem;
  font-size: 13px;
}

.detail-reservation-result ul {
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.detail-reservation-result ul li {
  display: flex;
  align-items: center;
  gap: 1rem;
  font-size: 14px;
}

.detail-reservation-result ul li span {
  font-weight: 500;
  color: var(--bluePrimary);
  font-size: 14px;
}

@media screen and (max-width:600px) {
  .return-url {
    width: 95%;
  }
}

@media screen and (max-width: 1100px) {
  .contain-form-make-reservation {
    width: 99%;
  }
  .contain-hotel-pay {
    width: 99%;
  }
}

@media screen and (max-width: 900px) {
  .layout-hotel-pay {
    flex-direction: column;
    gap: 1rem;
    width: 97%;
  }
  .aside-hotel-pay {
    width: 100%;
  }
  .main-hotel-pay {
    width: 100%;
  }
}
