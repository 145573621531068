.contain-shop-wellezy {
    position: relative;
    width: 100%;
    background-color: var(--bluePrimary);
}

.bg-shopping {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.contain-clouds {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.position-cloud{
    display: flex;
    margin-left: 1rem;
    justify-content: space-around;
}

.position-cloud2{
    display: flex;
    justify-content: space-between;
    margin-right: 4rem;
}

.position-cloud3{
    display: flex;
    margin-left: 1rem;
    justify-content: space-evenly;
}

.clouds {
    width: 80px;
    height: 80px;
}

.contain-info-shopping {
    width: 100%;
}

.contain-img-shop {
    display: flex;
    align-items: center;
    gap: 5px;
}

.cart-home-shop {
    height: 420px;

}

.tp-img{
    margin-top: auto;
}

.shop-logo-home {
    width: 80px;
    height: 80px;
}

.info-letters-shop {
    color: white;
    display: flex;
    flex-direction: column;
    width: 400px;
}
.info-letters-shop .explore{
    font-weight: 300;
    font-size: 13px;
    margin-bottom: .5rem;
}
.info-letters-shop p {
    text-align: justify;
    margin-top: 10px;
    font-weight: 200;
    font-size: 14px;
}

.info-letters-shop h2 {
    display: flex;
    line-height: 65px;
    flex-direction: column;
    text-transform: uppercase;
}

.info-letters-shop h2 span {
    font-size: 80px;
    font-weight: 900;
    margin-top: 0;
}

.info-letters-shop button {
    color: var(--bluePrimary);
    background-color: white;
    padding: .6rem 3rem;
    border-radius: 15px;
    font-size: 26px;
    font-weight: 700;
    cursor: pointer;
    margin: 1rem auto;
    transition: .3s all ease-in;
    width: fit-content;
}

.info-letters-shop button:hover{
    color: white;
    background-color: #003055;
    border: solid 2px white;
    transform: scale(1.04);
}


@media screen and (max-width:1000px) {
    .contain-info-shopping {
        flex-direction: column;
    }

    .cart-home-shop {
        height: 200px;
    }

    .contain-img-shop {
        justify-content: end;
    }

    .info-letters-shop h2 {
        display: flex;
        line-height: 47px;
        flex-direction: column;
    }

    .info-letters-shop h2 span {
        font-size: 60px;
        font-weight: 900;
        margin-top: 0;
    }

    .info-letters-shop button {
        border: 2px white solid;
        font-size: 20px;
    }
}

@media screen and (max-width:615px) {
    .info-letters-shop {
        width: 250px;
    }


}

@media screen and (max-width:450px) {
    .cart-home-shop {
        height: 180px;
    }

    .shop-logo-home {
        width: 50px;
        height: 50px;
    }
}