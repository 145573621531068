.highlightText {
  color: var(--yellowPrimary) !important;
}
.profileScreen {
  width: 100%;
  margin: 0 auto;
}
.profileScreenContent {
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  padding: 45px 10px 45px 10px;
  display: flex;
  flex-direction: column;
  gap: 45px;
}
.containerLoadingSimle {
  width: 100%;
  display: flex;
  height: 50px;
  justify-content: center;
  align-items: center;
}

.profileScreen_Header {
  text-transform: uppercase;
  color: var(--bluePrimary);
  margin-left: 8px;
}

.profileScreen_Container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 40px;
}

.profileScreen_Container .profileScreen_ContainerSideLeft {
  width: 20%;
}

.profileScreen_Container .profileScreen_ContainerSideCenter {
  width: 40%;
}

.profileScreen_Container .profileScreen_ContainerSideRight {
  width: 55%;
  justify-content: space-between;
  gap: 50px;
}

.profileScreen_Container .profileScreen_CardShadow {
  border-radius: 12px;
  background-color: var(--white);
  height: fit-content;
}

.profileScreen .profileScreen_ColumnContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.logOutButtonProfile {
  border-top: 1px solid var(--greyPrimary);
  padding-top: 30px;
  margin-top: 30px;
}

.profileScreen .bigContainer {
  width: 75%;
  display: flex;
  justify-content: space-between;
  gap: 50px;
}

/* ---------- ContainerSideLeft init----------*/
.profileScreen .optionProfile {
  cursor: pointer;
  display: flex;
  gap: 25px;
  align-items: center;
  justify-content: start;
}

.profileScreen .optionProfile .optionProfileImageContainer {
  height: 45px;
  width: 45px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background-color: var(--blueWhiteTableThead);
  box-shadow: var(--shawdowPrimary);
}

.profileScreen .optionProfile .optionProfileImageContainer.active {
  height: 45px;
  width: 45px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background-color: var(--blueLight);
  color: var(--white);
}

.optionIconColor {
  color: var(--blackGray);
}

.optionIconColor {
  color: var(--white);
}

.profileScreen .optionProfile {
  transition: all 0.3s ease;
}

.profileScreen .optionProfile .optionProfileText {
  font-size: 14px;
  font-weight: 400;
  transition: color 0.3s ease; /* Cambia solo el color */
  color: var(--blackGray);
  text-wrap: nowrap;
}

.profileScreen .optionProfile .optionProfileText.active {
  color: var(--blueLight);
  font-weight: 500;
}

/* Cambia el color del texto cuando el contenedor padre tiene hover */
.profileScreen .optionProfile:hover .optionProfileText {
  color: var(--blueLight);
}

/* ---------- ContainerSideLeft end----------*/
/* ---------- ContainerSideCenter init----------*/
.profileScreen .profileCard {
  display: flex;
  flex-direction: column;
}

.profileScreen .profileCardHeader {
  display: flex;
  align-items: flex-start;
  gap: 25px;
}

.profileScreen .profileCardHeaderAvatar {
  min-width: 200px;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  gap: 3px;
}

.profileScreen .profileCardHeaderAvatarWrapper {
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 20px;
}

.profileScreen .profileCardHeaderAvatarWrapperImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.profileScreen .profileCardHeaderBtn {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  position: absolute;
  bottom: 10%;
  right: 10%;
  cursor: pointer;
  background-color: var(--bluePrimary);
  align-items: center;
  justify-content: center;
  display: flex;
  cursor: pointer;
  transition: all ease 200ms;
}

.profileScreen .profileCardHeaderBtn:hover {
  transform: translateY(-4px);
  scale: 1.1;
}

.profileScreen .profileCardHeaderInfo {
  display: flex;
  flex-direction: column;
}

.profileScreen .profileCardHeaderInfoTextBig {
  color: var(--bluePrimary);
  font-size: 18px;
  font-weight: bolder;
  font-family: "Montserrat", sans-serif;
  display: flex;
  flex-direction: column;
  gap: 3px;
  border-bottom: 2px solid var(--bluePrimary);
  text-wrap: nowrap;
}

.profileScreen .profileCardHeaderInfoTextBig span {
  color: var(--bluePrimary);
  font-size: 14px;
  font-weight: 400;
  font-family: "Montserrat", sans-serif;
}

.profileScreen .profileCardHeaderInfoTextSmall {
  color: var(--white);
  background-color: var(--bluePrimary);
  padding: 5px 20px;
  font-size: 14px;
  font-weight: 500;
  font-family: "Montserrat", sans-serif;
  cursor: pointer;
  border-radius: 8px;
  display: grid;
  place-items: center;
}

.profileScreen .profileCardBody {
  border-top: var(--bluePrimary) solid 1px;
  padding: 10px 0;
  display: flex;
  flex-direction: column;
}

.profileScreen .profileCardBodyGroup {
  margin: 10px 0px;
}

.profileScreen .profileCardBodyGroupText {
  color: var(--blackGray);
  font-weight: 400;
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
}

.profileScreen .profileCardBodyGroupTextBig {
  font-weight: bolder !important;
  color: var(--bluePrimary);
}

.profileScreen .profileCardFooter {
  border-top: var(--bluePrimary) solid 1px;
  display: flex;
  justify-content: center;
}

.profileScreen .profileCardFooterBtn {
  margin-top: 5px;
  padding: 10px;
  cursor: pointer;
}

.profileScreen .profileCardFooterBtnText {
  color: var(--bluePrimary);
  font-size: 14px;
  font-weight: 400;
  font-family: "Montserrat", sans-serif;
  transition: all ease 200ms;
}

.profileScreen .profileCardFooterBtn:hover .profileCardFooterBtnText {
  font-weight: bolder;
}

/* ---------- ContainerSideCenter end----------*/
/* ---------- ContainerSideRight init----------*/
/* ---------- notifications init----------*/
.profileScreen .notificationCard {
  display: flex;
  flex-direction: column;
}

.profileScreen .notificationCardHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
}

.profileScreen .notificationCardHeaderIcon {
  margin-right: 5px;
}

.profileScreen .notificationCardHeaderTitle {
  color: var(--bluePrimary);
  margin-right: 8px;
  font-size: 18px;
  font-weight: bolder;
  font-family: "Montserrat", sans-serif;
  color: var(--bluePrimary);
}

.profileScreen .notificationCardHeaderInfo {
  font-size: 14px;
  font-weight: 400;
  font-family: "Montserrat", sans-serif;
  color: var(--black);
}

.profileScreen .notificationCardBody {
  display: flex;
  flex-direction: column;
}

.profileScreen .notificationCardBodyNotification {
  background-color: var(--white);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 1px;
  border-bottom: solid var(--greyPrimary) 1px;
  cursor: pointer;
}

.profileScreen .notificationCardBodyNotification:hover {
  background-color: var(--transparentModal);
}

.profileScreen .notificationCardBodyNotificationColumnLeft {
  display: flex;
  flex-direction: row;
  width: 80%;
  align-items: center;
}

.profileScreen .notificationCardBodyNotificationColumnLeftCheckbox {
  min-width: 25px;
  height: 30px;
  margin-right: 5px;
  align-items: center;
  justify-content: center;
  display: flex;
}

.profileScreen .notificationCardBodyNotificationColumnLeftCheckbox input {
  cursor: pointer;
}

.profileScreen .notificationCardBodyNotificationColumnLeftText {
  font-size: 14px;
  font-weight: 400;
  font-family: "Montserrat", sans-serif;
  color: var(--black);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.profileScreen .notificationCardBodyNotificationColumnRight {
  width: 20%;
  align-items: center;
  display: flex;
  justify-content: flex-end;
  font-size: 10px;
  font-weight: 400;
  font-family: "Montserrat", sans-serif;
  color: var(--black);
  text-align: right;
}

.profileScreen .notificationCardFooter {
  display: flex;
  justify-content: center;
  padding-top: 15px;
}

.profileScreen .notificationCardFooterBtn {
  padding: 5px;
  width: auto;
  cursor: pointer;
}

.profileScreen .notificationCardFooterBtnText {
  font-weight: 400;
  font-family: "Montserrat", sans-serif;
  color: var(--bluePrimary);
  padding: 0px 5px;
  border-bottom: var(--bluePrimary) solid 1px;
}

/* ---------- notifications end----------*/
/* ---------- config init----------*/
/* .profileScreen .configCard{} */

.profileScreen .configCardOption {
  display: flex;
  flex-direction: row;
  padding: 15px 0;
}

.profileScreen .configCardOptionBorder {
  border-bottom: var(--bluePrimary) solid 1px;
}

.profileScreen .configCardOptionColumnLeft {
  width: 85%;
  display: flex;
  flex-direction: column;
}

.profileScreen .configCardOptionColumnLeftName {
  font-weight: bolder;
  font-family: "Montserrat", sans-serif;
  color: var(--black);
  font-size: 16px;
}

.profileScreen .configCardOptionColumnLeftDescription {
  margin-top: 5px;
  font-weight: 400;
  font-family: "Montserrat", sans-serif;
  color: var(--black);
  font-size: 12px;
}

.profileScreen .configCardOptionColumnRight {
  width: 15%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

/* ---------- config end----------*/
/* ---------- cardList init----------*/
.profileScreen .listCard {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.profileScreen .listCardHeader {
  padding-bottom: 10px;
  display: flex;
  flex-direction: row;
}

.profileScreen .listCardHeaderText {
  font-weight: bolder;
  font-family: "Montserrat", sans-serif;
  color: var(--black);
  font-size: 16px;
}

.profileScreen .listCardBody {
  display: flex;
  flex-direction: column;
}

.profileScreen .listCardBodyElemt {
  width: 100%;
  margin-bottom: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.profileScreen .listCardBodyElemtText {
  font-weight: 400;
  width: 90%;
  font-family: "Montserrat", sans-serif;
  color: var(--black);
  font-size: 12px;
}

.profileScreen .listCardBodyElemtOptions {
  display: flex;
  flex-direction: row;
}

.profileScreen .listCardBodyElemtBtn {
  width: 25px;
  height: 25px;
  display: flex;
  margin-left: 2px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.profileScreen .listCardFooter {
  margin-top: 10px;
  display: flex;
  justify-content: center;
}

.profileScreen .listCardFooterBtn {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}

.profileScreen .listCardFooterBtnText {
  margin-left: 5px;
  font-weight: 400;
  font-family: "Montserrat", sans-serif;
  color: var(--bluePrimary);
  font-size: 12px;
}

/* ---------- cardList end----------*/
/* ---------- ContainerSideRight end----------*/
.profileScreen .buttonBigContainer {
  width: 80%;
  padding: 5px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.profileScreen .buttonBig {
  background: var(--bluePrimary);
  border-radius: 12px;
  justify-content: center;
  align-items: center;
  display: flex;
  padding: 15px 0;
  cursor: pointer;
}

.profileScreen .buttonBig:hover {
  transform: translateY(-4px);
}

.profileScreen .buttonBigOnly {
  width: 100%;
}

.profileScreen .buttonBigInGroup {
  width: 30%;
}

.profileScreen .buttonBigText {
  font-weight: bolder;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  color: var(--white);
}

.functionalityUnderDevelopment {
  width: 200px;
  position: absolute;
  top: 25%;
  left: 50%;
  transform: translate(-50% -50%);
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  border: var(--bluePrimary) solid 1px;
  border-radius: 20px;
  padding: 20px;
}

.functionalityUnderDevelopmentText {
  color: var(--bluePrimary);
  text-align: center;
  margin-top: 10px;
}

.profileScreen .arrayEmpty {
  padding: 15px 10px;
  width: 90%;
  margin: auto;
  border-radius: 12px;
  border: dashed 1px var(--greySecundary);
}

.profileScreen .arrayEmpty {
  color: var(--greySecundary);
  font-weight: 400;
  font-family: "Montserrat", sans-serif;
  font-size: 12px;
  text-align: center;
}

.profileScreen .modalStatus {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80%;
  margin: auto;
}

.profileScreen .modalStatusText {
  text-align: center;
  color: var(--black);
  font-weight: bolder;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
}

.btn-payment-cards {
  width: 100%;
  border-radius: 15px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  border: solid 2px var(--greyPrimary);
}

.btn-payment-cards .content-btn-payments-cards {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.btn-payment-cards .content-btn-payments-cards div {
  display: flex;
  align-items: center;
  gap: 0.6rem;
}

.btn-payment-cards .content-btn-payments-cards div h3 {
  font-size: 16px;
  color: var(--bluePrimary);
}

.btn-payment-cards .content-btn-payments-cards p {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: var(--black);
  font-weight: 300;
}

.btn-payment-cards .content-btn-payments-cards p svg {
  transform: rotate(-90deg);
}

@media screen and (max-width: 1100px) {
  .profileScreen_Container {
    width: 95%;
  }

  .profileScreen .buttonBigContainer {
    width: 99%;
  }
}

.profileScreen .sideMenuBtn {
  display: none;
  left: 10px;
  top: 10px;
  margin-right: -50px;
  min-width: 40px;
  max-width: 40px;
  min-height: 40px;
  max-height: 40px;
  border-radius: 20px;
  position: relative;
  z-index: 99999999999999999;
  background-color: var(--bluePrimary);
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 600px) {
  .profileScreen .bigContainer {
    flex-direction: column-reverse;
    width: 100%;
  }

  .profileScreen .buttonBigContainer {
    padding: 5px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
  }

  .profileScreen .buttonBigInGroup {
    width: 80%;
    margin: 10px auto;
  }
}

.containerProfileNotifications {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.containerProfileNotificationsHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.containerProfileNotificationsHeader h3 {
  font-size: 18px;
  font-weight: 700;
  color: var(--bluePrimary);
}

.containerProfileNotificationsHeader button {
  height: 100%;
  cursor: pointer;
}
.containerProfileNotificationsHeader button:hover {
  text-decoration: underline;
}

.containerProfileNotificationsItems {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.containerProfileNotificationsItem {
  display: flex;
  align-items: center;
  gap: 10px;
}

.containerProfileNotificationsItemIcon {
  padding: 5px;
  color: var(--white);
  display: grid;
  place-items: center;
  border-radius: 5px;
}

.containerProfileNotificationsItemContent {
  display: flex;
  flex-direction: column;
  gap: 3px;
  color: var(--bluePrimary);
  font-size: 14px;
  font-weight: 600;
}

.containerProfileNotificationsItemContent p {
  font-weight: 400;
  color: var(--blackGray);
  font-size: 12px;
}

.containerProfileNotificationsFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.containerProfileNotificationsFooter p {
  font-size: 14px;
}

@media screen and (max-width: 1100px) {
  .containerProfileNotificationsFooter {
    flex-direction: column;
  }

  .profileScreen .bigContainer {
    gap: 20px;
  }
}

@media screen and (max-width: 1000px) {
  .profileScreen .bigContainer {
    flex-direction: column;
    width: 100%;
    gap: 40px;
  }

  .profileScreen_Container {
    flex-direction: column;
    margin: 0 auto;
  }

  .profileScreen .profileScreen_ColumnContainer {
    flex-direction: row;
    overflow-x: scroll;
  }

  .profileScreen_Container .profileScreen_ContainerSideLeft {
    width: 100%;
  }
}

@media screen and (max-width: 700px) {
  .profileCardHeader {
    flex-direction: column;
  }

  .profileCardHeaderInfo {
    width: 100%;
  }

  .profileCardHeaderAvatar {
    min-width: 300px;
    min-height: 300px;
  }
}

@media screen and (max-width: 650px) {


  .profileScreen .bigContainer {
    width: 100%;
  }

  .profileScreen_ContainerSideLeft {
    width: 100%;
  }

  .profileScreen_Container .profileScreen_ContainerSideLeft {
    width: 100%;
  }



  .containerProfileNotificationsHeader {
    align-items: flex-start;
  }

  .containerProfileNotificationsFooter {
    align-items: flex-start;
  }
}

@media screen and (max-width: 400px) {
  .profileScreen .profileCardHeaderAvatar {
    width: 100%;
  }
}

@media screen and (max-width: 690px) {
  .profileScreen .profileCardHeader {
    flex-direction: column;
  }

  .profileScreen .profileCardHeaderInfo {
    width: 100%;
  }
}

@media screen and (max-width: 520px) {
  .containerProfileNotificationsHeader {
    flex-direction: column;
  }
}

@media screen and (max-width: 520px) {
  .profileScreen_Container {
    gap: 20px;
    flex-direction: column;
  }
}