.layout-turismHealth {
  width: 95%;
  max-width: 1280px;
  margin: 3rem auto;
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  display: flex;
  flex-direction: column; 
}

.column-Atourism {
  padding-top: 17px;
  width: 30%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.column-Btourism {
  width: 100%;
  display: flex;
  flex-direction: column; 
  
}

.layout-cards-tourism-health {
  width: 95%; 
  max-width: 1280px;
  margin: 1rem auto;
  display: flex;
  justify-content: space-between;
  gap: 1rem;
 
}

.layout-cards-tourism-health .column-x {
  width: 49%;
}

.layout-need-information-tourism-health {
  width: 95%;
  max-width: 1275px;
  margin: 2rem auto;
}

@media screen and (max-width: 1270px) {
  .column-Btourism {
    justify-content: flex-start;
  }
}

@media screen and (max-width: 1000px) { 
  .column-Btourism,
  .column-Atourism {
    width: 100%;
    gap: 4rem;
  }
}

@media screen and (max-width: 900px) {
  .layout-cards-tourism-health {
    flex-direction: column;
  }

  .layout-cards-tourism-health .column-x {
    width: 100%;
  }
}
