.btn-come-back-sura{
    margin: 20px;
}

.btn-come-back-sura button{
    width: 10%;
    height: 40px;
    border: none;
    border-radius: 8px;
    background-color: #0032A6;
    color: white;
    font-size: 14px;
    font-weight: 700;
    font-family: "Montserrat" !important;
    box-shadow: 0px 4px 4px 0px #00000040;
    cursor: pointer;
    transition: 0.3s;
}

.data-summary-pay-sura{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}

.title-data-summary-sura{
    color: #0032A6;
    font-size: 24px;
    font-weight: 700;
    font-family: "Montserrat" !important;
}

.data-basic-summary-sura div{
    display: flex;
    margin-block: 5px;
    align-items: center;
}

.data-basic-summary-sura p{
    margin-inline: 10px;
    font-size: 14px;
    font-weight: 400;
    font-family: "Montserrat" !important;
    color: black;
}

.data-pay-summary-sura p{
    font-size: 14px;
    font-weight: 400;
    font-family: "Montserrat" !important;
    color: black;
    margin-block: 10px;
}

.data-pay-summary-sura span{
    font-size: 14px;
    font-weight: 700;
    font-family: "Montserrat" !important;
    color: black;
    color: #0032A6;
    margin-inline: 10px;
}

.data-summray-style-sura{
    box-shadow: 0px 4px 4px 0px #00000040;
    border-radius: 23px;
    padding: 30px;
    width: 30%;
}

.hr-summary-pay-sura{
    border-bottom: 1px solid #0032A6;
    margin-block: 20px;
}

.p-price-total-sura{
    display: flex;
    justify-content: center;
    text-align: center;
    margin-block: 20px ;
}

.p-price-total-sura > div{
    box-shadow: 0px 4px 4px 0px #00000040;
    border-radius: 23px;
    padding: 30px;
    width: 30%;
}

.p-price-total-sura p{
    font-size: 24px;
    font-weight: 400;
    font-family: "Montserrat" !important;
    color: black;
}

.p-price-total-sura span{
    font-size: 24px;
    font-weight: 700;
    font-family: "Montserrat" !important;
    color: #0032A6;
    margin-inline: 10px;
}

.container-btn-buy-sura{
    display: flex;
    justify-content: center;
    margin-block: 20px;
}

.container-btn-buy-sura button{
    width: 30%;
    height: 40px;
    border: none;
    border-radius: 8px;
    background-color: #0032A6;
    color: white;
    font-size: 14px;
    font-weight: 400;
    font-family: "Montserrat" !important;
    box-shadow: 0px 4px 4px 0px #00000040;
    cursor: pointer;
    transition: 0.3s;
}

.container-btn-buy-sura button:hover{
    transition: 0.3s;
    background-color: white;
    color:  #0032A6;
    border: 1px solid #0032A6;
}