.container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 95%;
    max-width: 1280px;
    margin: 0 auto;
    margin-top: 40px;
}

.contain {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 20px;
    margin: 10px 0px 40px 0px;
}

.methodPayHeader {
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding-bottom: 10px;
    border-bottom: 2px solid var(--bluePrimary);
}

.methodPayHeader h3 {
    font-size: 24px;
    font-weight: 600;
    color: var(--bluePrimary);
}

.methodPayHeader p {
    font-size: 14px;
    font-weight: 400;
    color: var(--bluePrimary);
}

.methodPayContainerTermsContain {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 30px;
}

.methodPayContainerTermsContain h5 {
    width: 100%;
    font-size: 18px;
    color: var(--bluePrimary);
    padding: 0px !important;
    padding-bottom: 8px !important;
    border-bottom: 1px solid var(--bluePrimary);
}

.methodPayContainTermsContain {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.methodTerms {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.methodTerms h6 {
    font-size: 18px;
    font-weight: 500;
    color: var(--bluePrimary);
}

.methodTerms p {
    font-size: 16px;
    font-weight: 400;
    color: var(--bluePrimary);
}

.methodPayContainQuestionsContain {
    width: 100%;
}

.methodPayContainQuestionsContain h5 {
    width: 100%;
    font-size: 18px;
    color: var(--bluePrimary);
    padding: 0px !important;
    padding-bottom: 8px !important;
    border-bottom: 1px solid var(--bluePrimary);
}

.methodPayContainQuestionsContainCollapsible {
    margin-top: 20px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    row-gap: 2px;
}


@media screen and (max-width: 640px) {
    .methodPayContainQuestionsContainCollapsible {
        grid-template-columns: 1fr;
    }
}