.contain-filters-global {
    width: 100%;
    padding: 1rem .5rem;
    -webkit-box-shadow: 4px 7px 19px -10px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 4px 7px 19px -10px rgba(0, 0, 0, 0.75);
    box-shadow: 4px 7px 19px -10px rgba(0, 0, 0, 0.75);
    border-radius: 10px;
    background-color: white;
    z-index: 9999;
}

.contain-filters-global h2{
    font-size: 17px;
    color: var(--bluePrimary);
}

.contain-list-stars ul {
    list-style: none;
}

.contain-list-stars li {
    display: flex;
    gap: .5rem;
    align-items: center;
    color: var(--bluePrimary);
    transition: .5s ease-in-out;
}

.contain-list-stars li:hover{
    transform: scale(1.02);
}

input[type="radio"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 20px;
    height: 20px;
    min-width: 20px;
    min-height: 20px;
    border-radius: 50%;
    cursor: pointer;
    border: 2px solid var(--bluePrimary);

}

input[type="radio"]:checked {
    background-color: white !important;
    border: 4px solid var(--bluePrimary);
}