.myTravels .profileScreen_ContainerSideRight .optionFilter {
  display: flex;
  flex-direction: row;
  -webkit-box-shadow: 0px 10px 10px -5px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 10px 10px -5px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 10px 10px -5px rgba(0, 0, 0, 0.75);
  margin-bottom: 10px;
  width: 100%;
  padding: 5px 20px;
  border-radius: 12px;
  cursor: pointer;
  transition: all ease 200ms;
  align-items: center;
}

.myTravels .profileScreen_ContainerSideRight .optionFilter:hover {
  transform: translateY(-4px);
  transition: all ease 100ms;
}

.myTravels .profileScreen_ContainerSideRight .optionFilter:active {
  scale: 0.95;
  transition: all ease 200ms;
}

.myTravels .profileScreen_ContainerSideRight .optionFilterOn {
  background-color: var(--bluePrimary);
}

.myTravels .profileScreen_ContainerSideRight .optionFilterOff {
  background-color: var(--white);
}

.myTravels .profileScreen_ContainerSideRight .optionFilter .optionFilterIcon{
  width: 35px;
  height: 35px;
  display: flex;
  justify-items: center;
  justify-content: center;
}

.myTravels .profileScreen_ContainerSideRight .optionFilter .optionFilterText{
  margin-left: 10px;
  font-size: 14px;
  font-weight: 400;
  font-family: 'Montserrat', sans-serif;
}