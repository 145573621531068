.rowFiveColumnsTitle {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}

.rowFiveAirplan {
  display: flex;
  gap: 40px;
  align-items: center;
  justify-content: space-between;
}


.rowFiveAirplan .text-scales:hover{
  text-decoration: underline;
  cursor: pointer;
}

@media screen and (max-width: 1110px) {
  .rowFiveAirplan {
    gap: 20px;
  }
}

.rowFiveAirplanLoading {
  width: 100%;
  height: 2px;
  background-color: #ededed;
  border-radius: 100px;
  overflow: hidden;
}

.rowFiveAirplanLoadingBarra {
  width: 50%;
  height: 100%;
  background-color: #104172;
}

.rowFiveAirplanInfo {
  display: flex;
  justify-content: space-between;
  gap: 5px;
}

/* ****** info ******* */
.fiveColumnsContainerTitle {
  width: 100%;
  justify-content: space-between;
  display: flex;
  flex-direction: row;
}

.fiveColumnsContainerChild {
  width: 100%;
}

.fiveColumnsPrimaryColumn {
  align-items: center;
  justify-content: center;
  align-content: center;
  display: flex;
  position: relative;
}

.fiveColumnsColumnsFlight {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
}

.fiveColumnsColumnsTime {
  display: flex;
  justify-content: space-between;
}

.fiveColumnsPrimaryColumnRowTriple {
  align-items: center;
  justify-content: space-between;
  display: flex;
  gap: 20px;
}
 
@media screen and (max-width: 900px) {
  .fiveColumnsPrimaryColumnRowTriple {
    padding: 10px 0px;
    flex-direction: column;
  }
}
@media (max-width: 500px) {
  .fiveColumnsPrimaryColumnRowTriple {
      padding: 0 !important;
  }
}
/* ****** title ******* */
.titleCol {
  display: flex;
  flex-direction: row;
  color: #000;
}

.titleColText {
  display: flex;
  flex-direction: column;
  font-size: 12px;
}

.titleColTextBig {
  text-align: center;
}

.titleColTextSmall {
  font-size: 14px;
  text-align: center;
}

.titleColPackage {
}

.FiveColumnsIconWrap {
  margin-right: 10px;
  justify-content: center;
  align-items: center;
  display: flex;
  overflow: hidden;
  height: 50px;
  width: 50px;
}

.FiveColumnsIcon {
  height: 100%;
} 
.show500 {
  display: none !important;
}
@media (max-width: 500px) {
  .show500 {
      display: block !important; 
  }
}

.show500Badges {
  display: none !important;
}
@media (max-width: 500px) {
  .show500Badges {
      display: flex !important;  
  }
}