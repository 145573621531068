.content-how-contact {
  width: 100%;
}

.content-how-contact h2 {
  font-size: 24px;
  color: var(--bluePrimary);
  margin-bottom: 2rem;
}

.camp-phone-how-contact {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 1rem;
  border-radius: 6px;
  border: 1px solid rgb(213, 213, 213);
  color: rgb(100, 100, 100);
  margin-top: 1rem;
  width: 80%;
  margin-bottom: 1rem;
}

.contain-camp-select-how label {
  color: var(--bluePrimary);
  font-size: 20px;
}

.content-code-phone {
  position: relative;
  display: flex;
  align-items: center;
  gap: 4px;
  width: 10%;
  cursor: pointer;
}

.camp-phone-how-contact input {
  border: none;
  cursor: pointer;
  width: 90%;
}

@media screen and (max-width: 1100px) {
  .content-how-contact {
    width: 95%;
  }
}

@media screen and (max-width: 940px) {
  .content-code-phone {
    width: 20%;
  }
  .camp-phone-how-contact input {
    width: 80%;
  }
}

@media screen and (max-width: 500px) {
  .camp-phone-how-contact {
    width: 100%;
  }
  .content-code-phone {
    width: 25%;
  }
  .camp-phone-how-contact input {
    width: 75%;
  }
}
