.pqrsVideosContainer {
  width: '100%';
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
  padding-bottom: 20px;
}

.pqrsVideosContainer .pqrsVideosTitle {
  color: var(--bluePrimary);
  text-align: left;
  font-family: 'Montserrat', sans-serif;
  font-size: 20px;
  font-weight: bolder;
  margin-bottom: 10px;
}

.pqrsVideosContainer .pqrsVideosRow {
  display: flex;
  flex-direction: row;
  width: 100%;
  overflow-x: scroll;
}

.pqrsVideosContainer .pqrsVideosRow .pqrsVideosMultimedia {
  margin-right: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
}

.pqrsVideosContainer .pqrsVideosRow .pqrsVideosMultimedia .pqrsVideosMultimediaWrapperImg {
  border-radius: 20px;
  width: 200px;
  height: 110px;
  overflow: hidden;
}

.pqrsVideosContainer .pqrsVideosRow .pqrsVideosMultimedia .pqrsVideosMultimediaWrapperImg .pqrsVideosMultimediaImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.pqrsVideosContainer .pqrsVideosRow .pqrsVideosMultimedia .pqrsVideosMultimediaName {
  text-align: left;
  width: 100%;
  padding: 5px 10px;
  color: var(--black);
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  font-weight: 400;
}

.iframeVideoPqrs{
  border: none;
}