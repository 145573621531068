.modalQuestion {
    display: flex;
    flex-direction: column;
    gap: 1rem;


    img {
        width: 100px;
        margin: 0 auto;
    }

    div {
        display: flex;
        justify-content: space-between;
        gap: .5rem;
    }

    p {
        font-size: 20px;
        font-weight: 600;
        text-align: center;
    }

    button {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
        background-color: var(--bluePrimary);
        color: var(--white);
        padding: 13px 20px;
        border-radius: 8px;
        cursor: pointer;
        text-align: center;
        width: 100%;
        max-width: 400px;
        font-weight: 600;
        font-size: 16px;
        transition: all 0.3s ease;
    }

    button:hover {
        background-color: var(--blueTertiary);
    }

    ul {
        list-style: none;
        display: flex;
        flex-direction: column;
        gap: .5rem;
    }

    li {
        display: flex;
        align-items: center;
        font-weight: 600;
        gap: 5px;
    }

    span {
        font-weight: 400;
    }

    


}

.note{
    font-size: 12px !important;
    font-weight: 400 !important;
    text-align: start !important;
}