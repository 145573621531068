.services-travel {
  width: 100% !important;
  max-width: 1280px;
  margin: 2rem auto;
  display: flex;
  gap: 20px;
  align-items: center;
}

.slider_container {
  display: flex;
  align-items: center;
  width: 100%;
}
.slider_ {
  display: flex;
  justify-content: center;
  height: 100%;
  gap: 0.3rem;
}

.NoOverFlowH {
  overflow-x: scroll; /* Permite el desplazamiento horizontal */
  scrollbar-width: none; /* Firefox */
}

.NoOverFlowH::-webkit-scrollbar {
  width: 0px; /* Safari y Chrome */
  background: transparent; /* Opcional: hace que el fondo de la barra de desplazamiento sea transparente */
}

.grid_cardHomeTurism {
  margin: 10px 10px !important;
}

.buttonArrow {
  cursor: pointer;
}

.contain-icons-h ul {
  display: flex;
  justify-content: space-around;
  gap: 1rem;
  list-style: none;
}

.contain-icons-h img {
  width: 50px;
}

.list-carrousel-verticales {
  position: relative;
  list-style: none;
  text-align: center;
  min-width: max-content;
  transition: 0.5s ease-in-out;
}

.list-carrousel-verticales svg {
  width: 50px !important;
  height: 50px !important;
}

.list-carrousel-verticales p {
  font-size: 13px;
  color: black;
}

.list-carrousel-verticales a {
  text-decoration: none;
}

.iconLink {
  user-select: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

@media screen and (max-width: 600px) {
  .services-travel {
    width: 95% !important;
  }
}

.divPopoverButtons{ 
  display: flex;
  flex-direction: column;
  padding: 9px;
  background-color: #F0F0F0;
  margin-bottom: -5rem !important;
  align-items: center;
  justify-content: center;
  gap: .5rem;
  border-radius: 32px;
}
.divPopoverButtons span p{
  font-size: 8px;
  color: #00afe8;
  margin: 0;
  text-align: center;
  font-weight: 500;
} 
.divPopoverButtons span div{
  background-color: #00afe8;
  border-radius: 50%;
  padding: 3px;
}
.divPopoverButtons span{
  cursor: pointer;

}