.bigContainer.teleConsultation .profileScreen_ContainerSideCenter {
  width: 100% !important;
}

.teleConsultationContainer {
  background-color: #338fc2;
  width: 100%;
  height: 50%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.teleConsultationContainerCard {
  background-color: var(--white);
  padding: 20px;
  border-radius: 12px;
  width: 60%;
  display: flex;
  flex-direction: column;
  align-items: center;
  -webkit-box-shadow: 0px 10px 10px -5px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 10px 10px -5px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 10px 10px -5px rgba(0, 0, 0, 0.75);
}
.teleConsultationContainerCardTitle {
  font-size: 20px;
  font-weight: bolder;
  font-family: "Montserrat", sans-serif;
  color: var(--bluePrimary);
  text-align: center;
}
.teleConsultationContainerCardInputContainer {
  margin: 30px;
  height: 40px;
  width: 60%;
  border: 1px solid var(--bluePrimary);
  border-radius: 12px;
  overflow: hidden;
}
.teleConsultationContainerCardInput {
  background-color: var(--white);
  border: none;
  width: 100%;
  height: 100%;
  text-align: center;
}
.teleConsultationContainerCardBtn {
  background-color: var(--bluePrimary);
  border-radius: 12px;
  padding: 10px;
  width: 40%;
  cursor: pointer;
  transition: all ease 200ms;
  align-items: center;
  display: flex;
  justify-content: center;
}

.teleConsultationContainerCardBtn:hover {
  transition: all ease 200ms;
  transform: translateY(-4px);
  -webkit-box-shadow: 0px 10px 10px -5px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 10px 10px -5px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 10px 10px -5px rgba(0, 0, 0, 0.75);
}
.teleConsultationContainerCardBtnText {
  font-size: 12px;
  font-weight: bolder;
  font-family: "Montserrat", sans-serif;
  color: var(--white);
  text-align: center;
}
