
.MenuSideBarHome{
    position: relative;
}

.MenuSideBarHomeContainer{
    width: 100%;
    height: 90vh;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    overflow-y: scroll;
    padding-bottom: 10vh;
}

.MenuSideBarHomeOption{
    transition: all ease 200ms;
    display: flex;
    flex-direction: row;
    margin: 1px;
    padding: 10px;
    cursor: pointer;
    align-items: center;
}

.MenuSideBarHomeOption:hover{
    transform: translateY(-4px);
    background-color: rgba(0, 0,0, 0.15);
    border-bottom: solid 0.5px #17AEE6;
    -webkit-box-shadow: 10px 10px 29px -13px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 10px 10px 29px -13px rgba(0, 0, 0, 0.75);
    box-shadow: 10px 10px 29px -13px rgba(0, 0, 0, 0.75);
}

.MenuSideBarHomeOption:active{
    transform: translateY(4px);
    scale: 0.99;
}

.MenuSideBarHomeOption:hover .MenuSideBarHomeOptionText{
    color:#17AEE6;
    font-weight: 600;
}

.MenuSideBarHomeOptionIcon{
    width: 25px;
    height: 25px;
    margin-right: 5px;
    align-items: center;
    justify-content: center;
    display: flex;
}

.MenuSideBarHomeOptionText{
    font-size: 14px;
    color: #FFFFFF;
    font-weight: 400;
    font-family: 'Montserrat', sans-serif;
}

.MenuSideBarHomeFooter{
    background-color: rgba(0, 0,0, 0.25);
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 12px;
    display: flex;
    align-items: center;
}

.MenuSideBarHomeFooterText{
    font-size: 10px;
    color: #FFFFFF;
    font-weight: 400;
    font-family: 'Montserrat', sans-serif;
}