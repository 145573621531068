.allies-container-carrosel{
    margin: 4rem 0;
    overflow: hidden;
}
.alliesAirlineSlider {
    width: 100%;
    height: 160px;
    z-index: 2;
    margin-top: 30px;
    display: flex;
    justify-content: flex-end;
}
.alliesAirlineSlider_ {
    width: 100%;
    display: flex;
    z-index: 2;
    overflow: hidden;
}
.alliesAirlineSlider_cards {
    display: flex;
    cursor: pointer;
}
.alliesAirlineSlider_date {
    position: relative;
    display: flex;
    justify-content: center;
}
.alliesAirlineSlider_img {
    width: 240px;
    height: 230px;
    
    margin-left: 1px;
}
.alliesAirlineSlider_img img {
    width: 60%;
    height: 50%;
    object-fit: contain;
   
    
}

@media screen and (max-width: 900px) {
    .alliesAirlineSlider_ {
        width: 100%;
    }
}