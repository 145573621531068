.detail-pay-hotel {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.detail-pay-hotel .header-pay-hotel {
  display: flex;
  gap: 20px;
  align-items: center;
}

.detail-pay-hotel .header-pay-hotel img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  object-fit: cover;
  -webkit-box-shadow: var(--shawdowPrimary);
  -moz-box-shadow: var(--shawdowPrimary);
  box-shadow: var(--shawdowPrimary);
}

.content-detail-price-flight {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.content-detail-price-flight .sub-total p,
.content-detail-price-flight .sub-total span {
  color: var(--greySecundary);
}

.content-detail-price-flight .taxes p,
.content-detail-price-flight .taxes span {
  color: var(--greySecundary);
}

.detail-pay-hotel .header-pay-hotel .content-info {
  display: flex;
  width: 49%;
  flex-direction: column;
  gap: 0.5rem;
}

.detail-pay-hotel .header-pay-hotel .content-info h2 {
  font-size: 24px;
  font-weight: 700;
}

.detail-pay-hotel .header-pay-hotel .content-info div {
  display: flex;
  align-items: flex-start;
  gap: 0.4rem;
}

.detail-pay-hotel .header-pay-hotel .content-info div span {
  display: flex;
  align-items: center;
}

.detail-pay-hotel .header-pay-hotel .content-info div p {
  font-size: 13px;
  font-weight: 300;
}

.content-check-detail {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
}

.content-check-detail .text-from-to {
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
}

.content-check-detail .text-from-to span {
  font-size: 13px;
  color: var(--greySecundary);
}

.content-check-detail section {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.line-separation-detail-pay {
  width: 100%;
  height: 1px;
  background-color: var(--greyPrimary);
  margin: 1rem 0;
}

.pay-secure-hotel {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  border: solid 1px var(--greyPrimary);
  border-radius: 8px;
}

.pay-secure-hotel div {
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
}

.pay-secure-hotel div p {
  font-weight: bold;
  font-size: 17px;
  color: var(--bluePrimary);
}

.pay-secure-hotel div span {
  font-size: 13px;
  font-weight: 300;
}
