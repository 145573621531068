.content-detail-pack-new {
  max-width: 1280px;
  margin: 4rem auto;
  width: 90%;
  display: flex;
  gap: 3rem;
  flex-direction: column;
}

/* .ocultar-primer-parrafo p:first-of-type {
    display: none !important;
} */

.content-detail-pack-new header {
  display: flex;
  flex-direction: column;
}

.content-detail-pack-new header h1 {
  font-size: 24px;
  color: var(--bluePrimary);
  text-transform: uppercase;
  font-weight: 900;
}

.content-detail-pack-new header p {
  font-weight: 700;
  font-size: 20px;
}

.content-detail-pack-new main {
  display: flex;
  gap: 3rem;
  flex-direction: column;
  width: 100%;
}

.content-detail-pack-new main article {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 1rem;
}

.content-detail-pack-new main article .only-img {
  width: 66%;
  border-radius: 20px;
  max-height: 500px;
}

.content-detail-pack-new main article .content-slider {
  width: 66%;
  max-height: 500px;
  height: 100%;
}

.content-detail-pack-new main article .content-btn-actions-pack {
  border-radius: 20px;
  width: 32%;
  max-height: 500px;
  box-shadow: 0px 4px 4px 0px #00000040;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 2rem;
  justify-content: space-between;
}

/* .content-detail-pack-new main article .content-btn-actions-pack h2{
    font-weight: 700;
    font-size: 24px;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    background-color: var(--bluePrimary);
    width: 100%;
    color: white;
    padding: 2rem;
    display: flex;
    flex-direction: column;
} */

/* .content-detail-pack-new main article .content-btn-actions-pack h2 span{
    font-weight: 900;
    font-size: 48px;
} */

.camp-flex {
  display: flex;
  gap: 8px;
}
.content-detail-pack-new main article .content-btn-actions-pack .camp {
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
}

.content-detail-pack-new main article .content-btn-actions-pack .camp span {
  font-weight: 700;
  font-size: 18px;
  color: var(--bluePrimary);
}

.content-detail-pack-new main article .content-btn-actions-pack .camp h3 {
  font-weight: 400;
  font-size: 12px;
}

.content-detail-pack-new main article .content-btn-actions-pack .camp p {
  font-weight: 700;
  font-size: 20px;
  text-transform: uppercase;
}

.content-detail-pack-new
  main
  article
  .content-btn-actions-pack
  .btns-pack-news {
  padding: 0 2rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-bottom: 2rem;
}

.content-detail-pack-new
  main
  article
  .content-btn-actions-pack
  .btns-pack-news {
  border: 1px solid #d9d9d9;
  border-radius: 15px;
  box-shadow: 0px 4px 4px 0px #00000040;
  color: var(--bluePrimary);
  text-transform: uppercase;
  font-size: 24px;
  font-weight: 900;
  cursor: pointer;
  height: 55px;
  width: 100%;
}

.content-buttons-packs {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.btnCheckout {
  cursor: pointer;
  background-color: var(--bluePrimary);
  font-family: inherit;
  display: inline-block;
  height: 36px;
  line-height: 2.5em;
  position: relative;
  overflow: hidden;
  border: 2px solid var(--bluePrimary);
  transition: color 0.5s;
  z-index: 1;
  font-size: 14px;
  border-radius: 9px;
  font-weight: 700;
  color: white;
}

.btnCheckout:before {
  content: "";
  position: absolute;
  z-index: -1;
  background: white;
  color: var(--bluePrimary);
  height: 550px;
  width: 1500px;
  border-radius: 50%;
}

.btnCheckout:hover {
  color: var(--bluePrimary);
}

.btnCheckout:before {
  top: 100%;
  left: 100%;
  transition: all 0.7s;
}

.btnCheckout:hover:before {
  top: -100px;
  left: -200px;
}

.btnCheckout:active:before {
  background: white;
  transition: background 0s;
}

/* ASIDE */

.content-detail-pack-new main aside {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 3rem;
}

.content-detail-pack-new main aside .column-a {
  width: 63%;
  display: flex;
  margin: 0;
  flex-direction: column;
  gap: 3rem;
  justify-content: flex-start;
}

.content-detail-pack-new main aside .column-b {
  width: 36%;
  display: flex;
  margin: 0;
  flex-direction: column;
  gap: 3rem;
  justify-content: flex-start;
}

.content-detail-pack-new main aside .content-list-detail-pack {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.content-detail-pack-new main aside .content-list-detail-pack .titlet {
  text-transform: uppercase;
  color: var(--bluePrimary);
  font-size: 25px;
  font-weight: 900;
  height: auto;
}

.content-detail-pack-new main aside .content-list-detail-pack p {
  font-size: 14px;
}

.content-detail-pack-new main aside .content-list-detail-pack ul {
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  margin: 0;
  padding: 0;
  margin-left: 1rem;
  margin-top: 0.5rem;
}

.content-detail-pack-new main aside .content-list-detail-pack ul li {
  font-size: 14px;
}

.paragraphs-packages-images img {
  width: 100%;
  border-radius: 20px;
}

.hidden-img-carr {
  display: none;
}

.modal-list-images-pack {
  position: fixed;
  background-color: #021624b0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 1;
  transition: all 0.2s ease;
  padding: 30px;
}

.modal-list-images-pack.close {
  background-color: transparent;
  opacity: 0;
  pointer-events: none;
}

.content-list-images-pack {
  display: flex;
  flex-direction: column;
  gap: 15px;
  background-color: white;
  width: 100%;
  max-width: 680px;
  border-radius: 12px;
  padding: 20px;
  position: relative;
  opacity: 1;
  transition: all 0.3s ease;
  transform: translateY(0px);
  z-index: 99999;
}

.content-list-images-pack.close {
  opacity: 0;
  transition: all 0.3s ease;
  transform: translateY(-100px);
}

.content-list-images-pack-button-close {
    position: absolute;
    right: 7px;
    top: 7px;
    cursor: pointer;
    z-index: 999999;
    background: #fff;
    color: #004274;
    padding: 5px;
    border-radius: 50%;
    width: 30px;
    height: 30px;
  }

@media screen and (max-width: 800px) {
  .content-detail-pack-new main article {
    flex-direction: column;
  }

  .content-detail-pack-new main article .only-img {
    border-radius: 20px;
  }

  .content-detail-pack-new main article .content-slider {
    width: 100%;
  }

  .content-detail-pack-new main article .content-btn-actions-pack {
    width: 100%;
  }

  .content-detail-pack-new main aside .content-list-detail-pack .titlet {
    font-size: 25px;
  }

  .content-detail-pack-new main aside .column-a {
    width: 48%;
  }

  .content-detail-pack-new main aside .column-b {
    width: 48%;
  }
}

@media screen and (max-width: 650px) {
  /* 
    .content-detail-pack-new main article .content-btn-actions-pack h2{
        font-weight: 700;
        font-size: 20px;
    }
    
    .content-detail-pack-new main article .content-btn-actions-pack h2 span{
        font-weight: 900;
        font-size: 30px;
    }
    
    
    .content-detail-pack-new main article .content-btn-actions-pack .camp span{
        font-weight: 700;
        font-size: 18px;
    }
    
    .content-detail-pack-new main article .content-btn-actions-pack .camp h3{
        font-weight: 900;
        font-size: 30px;
    }
    
    .content-detail-pack-new main article .content-btn-actions-pack .camp p{
        font-weight: 700;
        font-size: 16px;
    }
    
    .content-detail-pack-new main article .content-btn-actions-pack .btns-pack-news button{
        font-size: 22px;
        height: 50px;
    } */
  /* .content-detail-pack-new header h1{
        font-size: 24px;
    }
     */
  .content-detail-pack-new main aside {
    flex-direction: column;
    gap: 2rem;
  }

  .content-detail-pack-new main aside .content-list-detail-pack .titlet {
    font-size: 16px;
  }

  .content-detail-pack-new header p {
    font-size: 16px;
  }

  .content-detail-pack-new main aside .column-a {
    width: 100%;
  }

  .content-detail-pack-new main aside .column-b {
    width: 100%;
  }
}
