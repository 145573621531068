

.cardContainerTooltip {
  position: absolute;
  top: 57px;
  left: 0px;
  z-index: 99999;
  border: 1px solid var(--greyPrimary);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.tooltipContainerShow {
  transform: translateY(0%);
  transition: all 0.6s;
  opacity: 1;
}

.tooltipContainerHidden {
  transform: translateY(100%);
  transition: all 0.3s;
  opacity: 0;
}

.tooltipContainerShowUp {
  transform: translateY(0%);
  transition: all 0.6s;
  opacity: 1;
}

.tooltipContainerHiddenUp {
  transform: translateY(-100%);
  transition: all 0.3s;
  opacity: 0;
}

.tooltipContainerShowDown {
  transform: translateY(0%);
  transition: all 0.6s;
  opacity: 1;
}

.tooltipContainerHiddenDown {
  transform: translateY(100%);
  transition: all 0.3s;
  opacity: 0;
}

.tooltipContainerShowRight {
  transform: translateX(0%);
  transition: all 0.6s;
  opacity: 1;
}

.tooltipContainerHiddenRight {
  transform: translateX(100%);
  transition: all 0.3s;
  opacity: 0;
}

.tooltipContainerShowLeft {
  transform: translateX(0%);
  transition: all 0.6s;
  opacity: 1;
}

.tooltipContainerHiddenLeft {
  transform: translateX(-100%);
  transition: all 0.3s;
  opacity: 0;
}

.tooltipContainerBtn {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.cardContainerTooltip {
  background: var(--white);
  border-radius: 8px;
  overflow: hidden;
  justify-content: center;
  display: flex;
  align-items: center;
  transition: all ease 200ms;
}
