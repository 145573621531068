.inputShareError fieldset{
    border: 1px solid var(--redPrimary) !important;
}

.inputShareError label{
    color: var(--redPrimary) !important;
}

.inputShareError p{
    color: var(--redPrimary) !important;
    font-size: var(--sizeTextSecundary) !important;
}