.content-succes-apointment {
  position: fixed;
  background-color: #021624b0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 1;
  transition: all 0.2s ease;
  padding: 30px;
}

.content-succes-apointment.close {
  position: fixed;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  pointer-events: none;
}

.contain-data-appointment {
    display: flex;
    flex-direction: column;
    gap: 15px;
    background-color: white;
    width: 100%;
    max-width: 680px;
    border-radius: 12px;
    padding: 20px;
    position: relative;
    opacity: 1;
    transition: all 0.3s ease;
    transform: translateY(0px);
    z-index: 99999;
}

.contain-data-appointment.close {
    background-color: white;
    width: 100%;
    width: 100%;
    max-width: 680px;
    border-radius: 10px;
    padding: 20px;
    position: relative;
    opacity: 0;
    transition: all 0.3s ease;
    transform: translateY(-100px);
  }

.contain-data-appointment h2 {
  color: var(--bluePrimary);
  font-size: 24px;
}

.list-data-appointment {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.list-data-appointment .camp {
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
}

.list-data-appointment .camp label {
  color: var(--bluePrimary);
}

.list-data-appointment .camp input {
  padding: 10px;
  border-radius: 6px;
  border: 1px solid rgb(198, 198, 198);
  color: rgb(114, 114, 114);
}
