.cartItemsItem {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.shortDescription{
  cursor: pointer;
}

.sectionData{
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.sectionData p, span{
  border: none !important;
  font-size: var(--sizeTextPrimary);
}

.cartItemsItem h3 {
  font-size: 18px;
  font-weight: 600;
  color: var(--bluePrimary);
  padding-bottom: 8px;
  border-bottom: 2px solid var(--bluePrimary);
}

.cartItemsItemOne {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.itemHotel {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  border: 1px solid var(--blueWhiteTableThead);
  border-radius: 10px;
  padding: 15px;
}

.itemHotelContent {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 13px;
}

.itemHeader {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.itemHeaderIconText {
  display: flex;
  align-items: center;
  gap: 10px;
}

.icon {
  font-size: 18px;
  background-color: var(--bluePrimary);
  color: var(--white);
  min-width: 30px;
  min-height: 30px;
  border-radius: 6px;
  display: grid;
  place-items: center;
}

.itemHeaderText {
  display: flex;
  flex-direction: column;
  gap: 2px;
  color: var(--bluePrimary);
  font-size: 14px;
  font-weight: 500;
}

.itemHeaderText h4 {
  font-size: 18px;
}

.itemDate {
  font-size: 14px;
  font-weight: 600;
  color: var(--bluePrimary);
}

.itemDetailsHotel {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.itemDetailsHotel ul li {
  list-style: none;
}

.itemLinks {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 10px;
  align-items: center;
}

.itemLinks p {
  color: var(--blackGray);
  font-weight: 400;
  display: flex;
  flex-direction: column;
  gap: 3px;
}

.itemLinks p span {
  color: var(--bluePrimary);
  font-weight: 600;
}

.detailsButtonHotel {
  background-color: var(--bluePrimary);
  padding: 8px 10px;
  border-radius: 6px;
  color: var(--white);
  font-size: 14px;
  cursor: pointer;
}

.image {
  width: 200px;
  border-radius: 8px;
  overflow: hidden;
}

.image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.totalPrice {
  background-color: var(--bluePrimary);
  padding: 10px;
  color: var(--white);
  display: flex;
  justify-content: flex-start;
  border-radius: 8px;
}

.totalPriceText {
  display: flex;
  flex-direction: column;
  gap: 3px;
  font-size: 14px;
}

.totalPriceText span {
  font-weight: 700;
  font-size: 18px;
}

@media screen and (max-width: 550px) {

  
  .itemLinks {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 10px;
    align-items: start;
  }

  .itemDetailsHotel {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: space-between;
    gap: 10px;
  }

  .itemHotel {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .image {
    width: 100%;
  }
}