.myMovements {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin-bottom: 6rem;
}

.myMovements h1 {
    font-size: 24px;
    font-weight: 600;
    color: var(--bluePrimary);
    padding-bottom: 3px;
    border-bottom: 2px solid var(--bluePrimary);
}
.containerGrayItinerary{
    background-color: #f6f5fad2;
    border-radius: 12px;
    padding: 8px;
    padding-top: 8px !important;
    padding-bottom: 20px !important;
}
.myMovements h2{
    font-size: 22px;
    font-weight: 600;
    color: var(--bluePrimary); 
}

.movementItems {
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.movementItem {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    align-items: center;
}

.details {
    font-weight: 600;
    font-size: 14px;
    color: var(--bluePrimary);
}

.details p {
    font-weight: 400;
    color: var(--blackGray);
}

.amount {
    font-size: 14px;
    color: var(--bluePrimary);
}

.noItems {
    height: 100%;
    display: grid;
    place-items: center;
    gap: 30px;
    color: var(--bluePrimary);
    font-size: 18px;
    font-weight: 500;
}

.message {
    display: flex;
    align-items: center;
    gap: 10px;
}

.buy {
    background-color: var(--bluePrimary);
    border-radius: 6px;
    color: var(--white);
    padding: 10px 20px;
    transition: all 0.3s ease;
    font-size: 14px;
}

.buy:hover {
    background-color: var(--blueTertiary);
}

@media screen and (max-width: 550px) {
    .movementItem {
        display: flex;
        justify-content: space-between;
        gap: 10px;
        align-items: flex-start;
        flex-direction: column;
    }
}
.itemPackageProfile{
    display: flex;
    align-items: center;
    gap: 10px;
    background-color: aqua;
    width: 100%;
}

.divBottomCardPacga{
    display: flex;
    flex-direction: column;
    cursor: pointer;
}

.divBottomCardPacga h3{
    font-size: 16px !important;
    font-weight: 650 !important;
    color: #104172;
}

.divBottomCardPacga span{
    font-size: 14px !important;
    font-weight: 500 !important;
    color: #104172;
}

.divBottomCardPacgaActive{
    display: flex;
    cursor: pointer;
    flex-direction: column;
}

.divBottomCardPacgaActive h3{
    font-size: 16px !important;
    font-weight: 650 !important;
    color: white;
}

.divBottomCardPacgaActive span{
    font-size: 14px !important;
    font-weight: 500 !important;
    color: white;
}

.divTopCardPacgaMain{
    display: flex;
    gap: 6px;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    border-radius: 10px; 
}

.divTopCardPacgaMainactive{
    display: flex;
    gap: 6px;
    align-items: center;
    cursor: pointer;
    justify-content: center;
    border-radius: 10px; 
    background-color: #104172;
    color: white !important;
}

/*estilos hotel*/

.mainHotelDiv{
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
}
.mainHotelDiv{
    padding-top: 0 !important;
    padding: 7px;
}
.imageHotelDiv img{
    border-radius: 8px;
}

.DataHotelDiv{
    display: flex;
    flex-direction: column;
    gap: 8px;
    color: #104172;
}

.topInfoHotel{
    display: flex;
    flex-direction: column;
    gap: -3px;
} 
.divInfoBottomHotel{
    display: flex;
    gap: 5px;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}

.topInfoHotel h3{
    font-size: 20px;
    font-weight: 600; 
}

.topInfoHotel h4{
    font-size: 17px;
    font-weight: 600; 
    margin-top: -5px;
} 

.divHotelData h4{
    color: black !important;
    font-size: 14px !important;
    font-weight: 600 !important;
}
 
.divHotelData p{
    color: #104172 !important;
    font-size: 13px !important;
    font-weight: 500 !important;
}
.starsHotelDivMain{
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    flex-direction: column;
    min-height: 145px;
}

@media (max-width: 900px) {
    .starsHotelDivMain {
        min-height: unset; 
    }
}

.bottonsDetailsHotel{
    display: flex;
    gap: 10px;
}

@media (max-width: 900px) {
    .bottonsDetailsHotel {
        margin-top: 13px; 
    }
}

.bottonsDetailsHotel button{
    background-color: #104172;
    color: white;
    font-size: 12px;
    font-weight: 500;
    padding: 8px;
    border-radius: 15px;
    padding-right: 10px !important;
    padding-left: 10px !important;
}
.starsHotelDiv{
    display: flex;
    align-items: center;
    gap: 5px;
}
.activeStarHotel{
    color: #FFD902;
    font-size: 18px;
}
.inactiveStarHotel{
    color: #ECECEC;
    font-size: 18px;
}

/*estilos clinica*/

.mainClinicDivPackage{
    display: flex;
    flex-direction: column;
    gap: 14px;
   color: #104172;
   width: 100%;
}

.topClinicDivPackage h3{
    font-size: 18px;
    font-weight: 600;
}
.topClinicDivPackage h4{
    font-size: 17px;
    font-weight: 550;
}
.descClinicPackage p{
    font-size: 15px;
    color: black !important;
    font-weight: 500; 
}

/*estilos especialista*/

.textDescript p{
    font-size: 15px;
    color: black !important;
    font-weight: 500; 
}
.textDescript{
    margin-bottom: 1rem;
}
.dataDesctip{
    display: flex;
    flex-direction: column; 
}
.descriptionSpecialist{
    margin-bottom: 1rem;
}
.dataDesctip p{
    font-size: 15px;
    color: black !important;
    font-weight: 500; 
}
.dataDesctip h3{
    font-size: 15px;
    color: #104172 !important;
    font-weight: 500; 
}
.bottomSpecialist{
    display: flex; 
    justify-content: space-between;
    align-items: center;
    width: 100%;
}
.bottomSpecialist button{
    background-color: #104172;
    color: white;
    border-radius: 16px;
    padding: 10px;
    display: flex;
    align-items: center;
    font-size: 15px;
    font-weight: 600;
}
.bottomSpecialist button svg{
    font-size: 30px !important;
}

/*vuelos*/


.subContainer1lightsDiv h4{
    font-size: 18px;
    color: #104172;
   
    font-weight: 600;
}
.subContainer2lightsDiv{
    display: flex;
    align-items: center;
    gap: 16px;
    justify-content: flex-start;
}
.subContainer2lightsDiv div{
    background-color: #ededed;
    color: black;
    padding: 3px;
    padding-left: 15px !important;
    padding-right: 15px !important;
}
.subContainer3{
    display: flex;
    gap: 6px;
    align-items: center;
}
.subContainer3 span{
    display: flex;
    justify-content: center;
    align-items: center;
}
.subContainer3 div{
    display: flex;
    flex-direction: column; 
}

.subContainer3 div p{
    font-size: 13px;
    color: #104172; 
    font-weight: 600;
}

.subContainer3 div h2{
    font-size: 14px;
    color: black; 
    font-weight: 600;
}

.subContainer3 div h4{
    font-size: 14px;
    color: #104172; 
    font-weight: 600;
}

.subContainer4{
    display: flex; 
    align-items: center;
    justify-content: center;
    padding-right: 15px;
    padding-left: 19px !important;
}

.lineFlight{
    width: 40%;
}
.itineraryLineFlight{
    width: 20%;
    padding-top: 15px;
}
.lineFlight div{
    width: 100%;
    border-top: 2px solid black;
}
.lineFlight div p{
    display: none;
}
.itineraryLineFlight{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.itineraryLineFlight svg{
    color: #104172;
    font-size: 28px;
    margin-bottom: -3px;
}

.itineraryLineFlight h3{
    font-size: 14px;
    color: #104172; 
    font-weight: 600; 
}


.itineraryLineFlight h2{
    font-size: 14px;
    color: #104172; 
    font-weight: 600;
    margin-bottom: -3px;
}

.itineraryLineFlight p{
    font-size: 12px;
    color: black; 
    margin-top: -4px;
    font-weight: 600;
}

.avoidSections{
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    min-height: 100px;
    text-align: center;
}

.avoidSections button{
    background-color: #104172;
    color: white;
    border-radius: 12px;
    padding: 10px;
}




.CloseUpItinerary{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1.5rem;
    cursor: pointer;
}

.CloseUpItinerary svg{
    color: #104172;
    font-size: 30px;
    margin-bottom: -10px;
}



.importantQuestionsStd{
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.importantQuestionsStd h4{
    font-size: 16px;
    color: #104172; 
    font-weight: 600;
    margin-bottom: 8px;
}
.aditionalInfoStd{
    display: flex;
    flex-direction: column; 
}
.aditionalInfoStd h4{
    font-size: 16px;
    color: #104172; 
    font-weight: 600;
    margin-bottom: 8px;
}
.aditionalInfoStd div{
    display: flex;
    gap: 5px;
    flex-direction: column; 
    height: 100%;
    justify-content: space-between;
}
.aditionalInfoStd div span{
    display: flex;
    gap: 5px;
    flex-direction: column;  
}

.aditionalInfoStd div p{
    font-size: 14px;
    color: #104172; 
    font-weight: 500; 
}
.answersQuestionsStd{
    display: flex;
    gap: 14px;
    flex-direction: column;
}
.answersQuestionsStd p{
    font-size: 14px;
    color: #104172; 
    font-weight: 500; 
}
.answersQuestionsStd span{
    padding-left: 10px !important;
    margin-top: -10px;
    font-size: 14px;
    color: #104172; 
    font-weight: 550; 
}

.divsProcedure{
    display: flex;
    flex-direction: column;
}
.divsProcedure p{
    font-size: 15px;
    color: #104172; 
    font-weight: 600; 
}



.aditionalInfoStdLocal{
    display: flex;
    flex-direction: column; 
}
.aditionalInfoStdLocal h4{
    font-size: 16px;
    color: #104172; 
    font-weight: 600;
    margin-bottom: 8px;
}
.aditionalInfoStdLocal div{
    display: flex;
    gap: 10px;
    flex-direction: column;  
}
.aditionalInfoStdLocal div span{
    display: flex;
    gap: 5px;
    flex-direction: column;  
}

.aditionalInfoStdLocal div p{
    font-size: 14px;
    color: #104172; 
    font-weight: 500; 
}
.ItineraryMainApi{
    width: 100%;
    display: flex;
    flex-direction: column;
}

.ItineraryMainApi2{ 
    background-color: #f6f5fad2;
    border-radius: 12px;
    padding: 8px;
    padding-top: 8px !important;
    padding-bottom: 20px !important;
}