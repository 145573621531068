.contain-packages-pay {
  background-color: var(--greyPrimary);
  min-height: 100vh;
  padding-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.layout-packages-pay {
  width: 90%;
  display: flex;
  justify-content: space-between;
}

.main-packages-pay {
  width: 60%;
}

.btn-pay-packages {
  width: 100%;
  padding: 1rem;
  text-align: center;
  font-weight: 600;
  color: white;
  background-color: var(--bluePrimary);
  border-radius: 5px;
  font-size: 17px;
  margin: 1rem 0;
  cursor: pointer;
}

.return-url {
  display: flex;
  align-items: center;
  color: var(--bluePrimary);
  font-weight: 700;
  width: 95%;
  max-width: 1280px;
  margin: 1rem auto;
}

.aside-packages-pay {
  width: 38%;
}

.congratulations-selected {
  padding: 1rem;
  border-radius: 8px;
  background-color: #8fe3c63b;
  border: 1px solid #8fe3c6;
  margin-top: 1rem;
  color: rgb(50, 50, 50);
}

.congratulations-selected p {
  font-weight: 600;
}

.title-aside-pay {
  font-size: 16px;
}

.accordion-pay-pack {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background-color: white;
  border: 1px solid rgb(215, 215, 215);
  border-radius: 8px;
  margin-top: 1rem;
  padding: 1rem;
  cursor: pointer;
}

.content-arrow-accordion {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.contain-payment-cards-pack {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background-color: white;
  border: 1px solid rgb(215, 215, 215);
  border-radius: 8px;
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding: 1rem;
}

@media screen and (max-width: 900px) {
  .layout-packages-pay {
    flex-direction: column-reverse;
    gap: 1rem;
    width: 97%;
  }
  .aside-packages-pay {
    width: 100%;
  }
  .main-packages-pay {
    width: 100%;
  }
}
