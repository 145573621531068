.content-tell-yourself{
    width: 100%;
    margin-bottom: 30px;
}

.select-country-package > div > div > div{
    border: 1px solid #d9d9d900 !important;
    background: url('./assets/svg/ArrowDownIcon.svg') no-repeat;
    background-position: right 10px center;
}

.select-country-package{
    width: 60%;
    margin-block: 1rem;
}

.content-tell-yourself h2{
    font-size: 24px;
    color: var(--bluePrimary);
    margin-bottom: 2rem;
}

@media screen and (max-width:1100px) {
    .content-tell-yourself{
        width: 95%;
    }
}