.loaderContainer {
  display: flex;
  width: 100%;
  justify-content: center;
  z-index: 9999999999;
}

.loaderContainerWrap {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #021624c2;
  z-index: 99999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.load {
  width: 100%;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 60px;
}

.load img {
  width: 100%;
  height: 100%;
}

.containerBalls {
  display: flex;
  justify-content: center;
  align-items: center;
}

.balls {
  margin: 60px 20px 12px 0px;
  width: 12px;
  height: 12px;
  background-color: #ffffff;
  animation: salto 0.5s alternate infinite;
  border-radius: 50%;
}

.balls:nth-child(2) {
  animation-delay: 0.18s;
}

.balls:nth-child(3) {
  animation-delay: 0.37s;
}

@keyframes salto {
  from {
    transform: scaleX(1.25);
  }

  to {
    transform: translateY(-50px) scaleX(1);
  }
}
