.recomendation-packs{
    width: 90%;
    max-width: 1290px;
    margin: 4rem auto 4rem auto;
}


.recomendation-packs .title-recomendation{
    font-size: 36px;
    font-weight: 900;
    color: var(--bluePrimary);
    text-transform: uppercase;
}

.recomendation-packs .paragrafh-recomendation{
    font-size: 12px;
}


.recomendation-packs .all-cards-recomendation{
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
    margin: 2rem 0;
}


.recomendation-packs .slick-prev:before, .recomendation-packs .slick-next:before{
    color: rgb(255, 255, 255) !important;
    font-size: 40px !important;
}


.recomendation-packs .slick-next{
    right: 70px !important;
}

.recomendation-packs .slick-prev{
    left: 20px !important;
}

.recomendation-packs .slick-track {
    margin: 1rem 0;
}



.content-cards-recomendation .card-packages-new {
    background-color: white;
    border-radius: 20px;
    box-shadow: 0px 4px 4px 0px #00000040;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1rem;
    max-width: 90%
  }
  
  
  .content-cards-recomendation .card-packages-new img {
    width: 100%;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    object-fit: cover;
    height: 300px;
  }
  
 
  
  .content-cards-recomendation .card-packages-new .description {
    padding: 0 .4rem;
    display: flex;
    flex-direction: column;
  }
  
  
  
  .content-cards-recomendation .card-packages-new .description h2 {
    color: #004274;
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    text-wrap: nowrap;
    overflow: auto;
  }
  

  
  .content-cards-recomendation .card-packages-new .description span {
    color: black;
    margin-top: .6rem;
    font-weight: 700;
    font-size: 12px;
  }
  
  
  .content-cards-recomendation .card-packages-new .description p {
    font-size: 12px;
    text-wrap: nowrap;
    overflow: auto;
  }
  
 
  
  .content-cards-recomendation .card-packages-new .price {
    margin-bottom: 1rem;
    padding: 0 1rem 0 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  

  
  .content-cards-recomendation .card-packages-new .price .back-price {
    background-color: #104172;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    color: white;
    padding: 13px;
    font-size: 13px;
    font-weight: 700;
  }
  
 
  
  .content-cards-recomendation .card-packages-new .price .see-more {
    color: #104172;
    font-size: 12px;
    font-weight: 700;
    cursor: pointer;
  }
  
  .content-cards-recomendation .card-packages-new .price .see-more:hover,.content-cards-recomendation .card-packages-new .price .see-more:hover {
    text-decoration: underline;
  }
  



@media screen and (max-width:650px){
    .recomendation-packs .title-recomendation{
        font-size: 27px;
    }
}

@media screen and (max-width:500px) {
    .card-news-tourims-health{
    width: 100%;
    max-width: none;
}
.recomendation-packs .slick-next{
    right: 20px !important;
}

.recomendation-packs .slick-prev{
    left: 0px !important;
}
}