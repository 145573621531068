.contain-form-passenger {
  margin-top: 2rem;
}

.contain-form-passenger form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.title-adult {
  color: rgb(50, 50, 50);
  margin-bottom: 1rem;
  margin-top: 1rem;
}

.text-responsability {
  margin: 1rem 0;
}

.camp-passenger-form {
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
  width: 70%;
}

.camp-passenger-form label {
  color: rgb(69, 69, 69);
  font-size: 13px;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-weight: 600;
}

.text-none-camp {
  font-size: 12px;
  color: rgb(165, 12, 12);
}

.border-top {
  border-top: 1px solid rgb(142, 142, 142);
}

.camp-passenger-form input {
  padding: 0.7rem;
  border: 1px solid rgb(142, 142, 142);
  border-radius: 3px;
  transition: 0.4s ease-in-out;
}

.camp-passenger-form input:focus {
  border-color: var(--bluePrimary);
  border-width: 2px;
  transform: scale(1.04);
}

/* selected form  */

.contain-selected-form-pay {
  position: relative;
  display: flex;
  gap: 0.5rem;
  justify-content: space-between;
  align-items: center;
  padding: 0.7rem;
  border: 1px solid rgb(142, 142, 142);
  border-radius: 3px;
  cursor: pointer;
}

.contain-selected-form-pay p {
  font-size: 13px;
}

.contain-options-selected {
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  border-radius: 10px;
  max-height: 200px;
  overflow: scroll;
  min-width: 50px;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.42);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.42);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.42);
  z-index: 3;
  background-color: white;
  top: 30px;
}

.contain-options-selected p {
  cursor: pointer;
}

.contain-documentation {
  display: flex;
  gap: 1rem;
}

.contain-date-natale {
  display: flex;
  gap: 0.5rem;
}

.contain-sex-selected {
  display: flex;
  gap: 1rem;
}

.sex-selected-pay {
  padding-top: 10px;
  align-items: center;
  display: flex;
  cursor: pointer;
  gap: 7px;
}

.sex-selected-pay p {
  font-size: 13px;
}

.sex-selected-pay div {
  width: 20px;
  height: 20px;
  border-radius: 50px;
  border: 1px solid rgb(142, 142, 142);
  display: flex;
  justify-content: center;
  align-items: center;
}

.sex-selected-pay div span {
  padding: 0.4rem;
  border: 1px solid rgb(142, 142, 142);
  border-radius: 10px;
}

@media screen and (max-width: 600px) {
  .camp-passenger-form label {
    font-size: 12px;
  }
  .sex-selected-pay {
    padding: 0.5rem;
  }
  .contain-selected-form-pay,
  .camp-passenger-form input {
    padding: 0.5rem;
  }
}
