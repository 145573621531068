.turismHeader {
    width: 100%;
    height: 700px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.turismHeader img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.turismHeader_info {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
}
.turismHeader-info_input {
    width: 50%;
    text-align: center;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.turismHeader_searchFormControl {
    width: 500px;
    border-radius: 20px;
    box-shadow: 0 5px 4px rgba(0, 0, 0, 0.25);
}
.turismHeader-info_text {
    width: 50%;
    text-align: right;
}
.turismHeader-info_text h1 {
    color: white;
    font-family: 'Oswald';
    font-size: 90px;
    margin-right: 100px;
    line-height: 100px
}
.div_results {
    position: absolute;
    top: 55px;
}
.results {
    width: 450px;
    max-height: 254px;
    background-color: white;
    border-radius: 0 0 20px 20px;
    overflow: hidden;
    overflow-y: auto;
    box-shadow: 0 5px 4px rgba(0, 0, 0, 0.25);
}
.dest {
    display: flex;
    width: 100%;
    height: 50px;
    cursor: pointer;
    border-bottom: 1px solid rgba(0, 66, 116, .2);
    align-items: center;
}
.dest_img {
    width: 30px;
    height: 30px;
    margin: 0 10px;
}
.dest_img img {
    width: 100%;
    border-radius: 50%;
}
.dest p {
    font-family: 'Inter';
    font-weight: 300;

}
.turismHeader_content {
    width: 90%;
    height: 100px;
    background-color: white;
    display: flex;
    position: absolute;
    bottom: -70px;
    justify-content: space-between;
    border-radius: 30px;
    box-shadow: 0 5px 4px rgba(0, 0, 0, 0.25);
}
.content {
    width: 25%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}
.content_img {
    width: 80px;
    height: 80px;
}
.content_img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
}
.content_text {
    width: 60%;
}
.content_text h3 {
    width: 100%;
    font-family: 'Inter';
    font-weight: 400;
    font-size: 20px;
}

.dest:hover {
    background-color: rgba(0, 66, 116, .1);
    transition: all 0.3s ease-out;
}

@media screen and (max-width: 1250px) {
    .turismHeader-info_text h1 {
        font-size: 70px;
        margin-right: 60px;
        line-height: 80px
    }
    .content_text h3 {
        font-size: 16px;
    }
}
@media screen and (max-width: 1100px) {
    .turismHeader_info {
        flex-direction: column;
        justify-content: center;
    }
    .turismHeader-info_input {
     width: 80%;   
    }
    .turismHeader-info_text {
        width: 80%;
        text-align: center;
        margin-top: 50px;
    }
    .turismHeader-info_text h1 {
        font-size: 50px;
    }
    .turismHeader_content {
        width: 100%;
        height: 170px;
        border-radius: 40px;
    }
    .content {
        flex-direction: column;
        text-align: center;
    }
    .content_text {
        width: 90%;
        height: 50px;
    }
}
@media screen and (max-width: 620px) {
    .turismHeader-info_text h1 {
        line-height: 60px
    }
    .content_text h3 {
        font-size: 14px;
    }
}
@media screen and (max-width: 500px) {
    .turismHeader-info_input {
        width: 90%;
    }
    .turismHeader-info_text {
        width: 90%;
    }
    .turismHeader-info_text h1 {
        margin-right: 0;
    }
    .results {
        width: 420px;
    }
    .turismHeader_content {
        height: 180px;
    }
    .content_text {
        height: 70px;
    }
    .content_text {
        height: 40px;
    }
    .content_text h3 {
        font-size: 12px;
    }
}