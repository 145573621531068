.alliesmHeader {
    min-height: 840px;
    display: flex;
    justify-content: center;
    align-items: center;
    
}
.allies-header-contain{
    width: 100%;
    height: 100%;
}
.container-header-allies-info{
    position: absolute;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center
}

.allies-bg-header{
    width: 100%;
    height: 840px;
    background-color: rgba(0, 0, 0, .3);
    position: absolute;
    top: 0;
}

.allies-header-contain img {
    width: 100%;
    height: 840px;
    object-fit: cover;
}

.container-header-allies-info{
    position: absolute;
    display: flex;
    flex-direction: column;
    gap: 60px;
    top: 30%;
    left: 60%;
}

.turismHeader-info_text-label{
    width: 300px;
}

.turismHeader-info_text-label p {
    text-align: justify;
    letter-spacing: 2px;
    color: white;
}


.header_button-container span{
    display: block;
    margin-top: 6px;
}
.header_button-container a{
    color: black;
    background-color: white;
    text-align: center;
    border-radius: 30px;
    cursor: pointer;
    padding: 10px;
}

.title-header-allies h1 {
    letter-spacing: 4px;
    font-size: 50px;
    color: white;
}

@media screen and (max-width:1100px) {
    .container-header-allies-info{
        left: 50%;
    }
}

@media screen and (max-width:830px) {
    .container-header-allies-info{
        left: 30%;
    }
}

@media screen and (max-width:592px) {
    .container-header-allies-info{
        left: 30%;
    }
    .title-header-allies h1{
        font-size: 35px;
    }
    .turismHeader-info_text-label p{
        font-size: 12px;
        letter-spacing: 1px;
    }
    .turismHeader-info_text-label{
        width: 200px;
    }
}