.videsdk.initianRoomComponent {
  background-color: "red";
  /* /var(--black); */
  height: 100%;
  width: 100%;
}

/* ------- joinScreen init ------- */
.videsdk.joinScreen {
  background: var(--bluePrimary);
  width: 100%;
  height: 720px;
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  overflow: hidden;
}

.videsdk.joinScreen .form {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: space-between;
  align-items: start;
  border-radius: 16px;
}

.joinScreenImage {
  width: 100%;
  height: 100%;
  position: relative; /* Asegura que los elementos posicionados dentro de este contenedor se ajusten correctamente */
}

.joinScreenImage::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 50%;
  background: linear-gradient(to bottom, var(--bluePrimary) 0%, transparent 100%);
  z-index: 1; /* Coloca el degradado por encima de la imagen */
}

.joinScreenImage img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 0; /* Asegura que la imagen esté por debajo del degradado */
}


.meetingPeopleTeam.initial {
  background-color: var(--transparentModal);
  width: 100%;
  padding: 10px;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: fit-content;
  height: fit-content;
  border-radius: 10px;
  opacity: 1;
  transition: all 0.3s ease;
  z-index: 9999;
  pointer-events: all;
}

.meetingPeopleTeam.finally {
  /* background-color: var(--transparentModal); */
  width: 100%;
  padding: 20px;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: all 0.3s ease;
  z-index: 9999;
  pointer-events: none;
}

.meetingPeopleTeamContent.initial {
  background-color: var(--bluePrimary);
  padding: 20px;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  position: relative;
  transform: translateY(0px);
  opacity: 1;
  transition: all 0.3s ease;
  border: 2px solid white;

}

.meetingPeopleTeamContent.finally {
  background-color: var(--bluePrimary);
  padding: 20px;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  position: relative;
  transform: translateY(-30px);
  opacity: 0;
  transition: all 0.3s ease;
}

.meetingPeopleTeamContentClose {
  position: absolute;
  top: 10px;
  right: 10px;
  color: var(--white);
  font-size: 20px;
  cursor: pointer;
}

.meetingNow {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.meetingNow h3 {
  color: var(--white);
  font-size: 18px;
  font-weight: 600;
}

.videsdk.joinScreen .form .card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  .cardTitle {
    color: var(--white);
    font-size: 38px;
    font-weight: 600;
    font-family: "Montserrat", sans-serif;
    margin-top: 40px;
    margin-bottom: 10px;
    text-align: center;
  }

  .cardText {
    color: var(--greyPrimary);
    font-size: 18px;
    font-family: "Montserrat", sans-serif;
    margin-bottom: 20px;
    text-align: center;
  }

  .containerJoinInput {
    display: flex;
    gap: 20px;
    justify-content: center;
    align-items: center;
    width: 50%;

    .inputJoin {
      width: 100%;
      height: 42px;
      border-radius: 6px;
      padding: 13px 17px;
      text-align: center;
      color: var(--white);
      font-size: 14px;
      font-family: "Montserrat", sans-serif;
      background-color: var(--bluePrimary);
      border: 1px solid var(--white);

      .btnJoin {
        cursor: pointer;
        border-radius: 6px;
        align-items: center;
        justify-content: center;
        display: flex;
        width: 100%;
        height: 42px;
      }
    }

    .inputJoin::placeholder {
      color: var(--white);
    }
  }
}

/* .videsdk.joinScreen .form .card .cardTitle {
  color: var(--white);
  font-size: 22px;
  font-weight: bolder;
  font-family: "Montserrat", sans-serif;
  margin-bottom: 20px;
} */

.videsdk.joinScreen .form .btn {
  cursor: pointer;
  border-radius: 6px;
  align-items: center;
  justify-content: center;
  display: flex;
}

.videsdk.joinScreen .info {
  width: 50%;
  overflow: hidden;
}

.videsdk.joinScreen .info img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.videsdk.joinScreen .form .card input {
  width: 100%;
  height: 40px;
  border-radius: 12px;
  border: none;
  padding: 5px 10px;
  text-align: center;
  color: var(--black);
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
}

.joinScreenDisableButtonOn {
  padding: 10px;
  border: 1px solid var(--greyPrimary);
  opacity: 0.5;
  width: 100%;
  border-radius: 5px;
  pointer-events: none;
  transition: all 0.3s ease;
  height: 100%;
}

.joinScreenCreate {
  padding: 10px;
  border: 1px solid var(--greyPrimary);
  width: 50%;
  border-radius: 5px;
  cursor: pointer;
  background-color: var(--blueTertiary);
  height: 100%;
}

.joinScreenDisableButtonOff {
  padding: 10px;
  border: 1px solid var(--greyPrimary);
  opacity: 1;
  width: 100%;
  border-radius: 5px;
  cursor: pointer;
  pointer-events: all;
  background-color: var(--blueTertiary);
  transition: all 0.3s ease;
  height: 100%;
}

.onlyText {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-top: 10px;
  text-align: center;
  color: var(--white);
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
}

/* ------- joinScreen end ------- */

/* ------- meetingView init ------- */
.meetingView {
  /* background-color: var(--bluePrimary); */
  background-color: var(--blueLight);
  display: flex;
  height: 100% !important;
  align-items: center;
  flex-direction: column;
  padding: 40px;
}

.meetingView h3 {
  text-align: center;
  color: var(--white);
  font-size: 22px;
  font-weight: bolder;
  font-family: "Montserrat", sans-serif;
  margin-bottom: 20px;
}

.notRoom {
  text-align: center;
  color: var(--white);
  font-size: 14px;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
  margin-bottom: 20px;
  text-transform: uppercase;
  /* background-color: var(); */
  background-color: red;
  padding: 10px 20px;
}

.meetingView .btn {
  cursor: pointer !important;
  width: fit-content !important;
  min-width: 300px !important;
  border-radius: 12px;
  transition: all ease 200ms;
}

.meetingView .btn:hover {
  transform: translateY(-4px);
  transition: all ease 200ms;
  -webkit-box-shadow: 10px 10px 29px -13px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 29px -13px rgba(0, 0, 0, 0.75);
  box-shadow: 10px 10px 29px -13px rgba(0, 0, 0, 0.75);
}
.allCenter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  gap: 10px;
}

.allCenter p {
  margin-top: 40px;
}
/* ------- meetingView end ------- */

.controlsContainer {
  width: 100% !important;
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.controlsContainer .controlBtn {
  background-color: var(--white);
  border: solid 1px var(--bluePrimary);
  padding: 5px 10px;
  border-radius: 8px;
  cursor: pointer;
  transition: all ease 200ms;
}

.controlsContainer .controlBtn:hover {
  transition: all ease 200ms;
  -webkit-box-shadow: 10px 10px 29px -13px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 29px -13px rgba(0, 0, 0, 0.75);
  box-shadow: 10px 10px 29px -13px rgba(0, 0, 0, 0.75);
  transform: translateY(-4px);
}

.controlsContainer .controlBtnText {
  color: var(--bluePrimary) !important;
}

.participantContainer {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  gap: 20px;
  overflow: scroll;
  flex-direction: column;
}

.participant {
  background-color: rgba(0, 0, 0, 0.1);
  margin: 10px;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  height: 300px;
  width: 300px;
}

.participant .participantInfo {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.1);
  width: 100%;
  padding: 10px;
  color: var(--white);
  justify-content: space-around;
  display: flex;
  flex-direction: row;
}

.participant .participantInfo.up {
  top: 0px;
}
.participant .participantInfo.down {
  bottom: 0px;
}

.participantAvatar {
  height: 150px;
  width: 150px;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 75px;
  margin-top: 50px;
  margin-bottom: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 1px var(--white);
}

.participantAvatarText {
  text-align: center;
  color: var(--white);
  font-size: 40px;
  font-weight: bolder;
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
}

.errorMeeting {
  color: var(--redPrimary);
  font-size: 14px;
  font-weight: 600;
  padding: 10px 20px;
  margin-top: 10px;
  border-radius: 8px;
}

.closeButtonMeeting {
  background-color: var(--redPrimary);
  border-radius: 8px;
  color: var(--white);
  padding: 10px 20px;
  cursor: pointer;
}


@media screen and (max-width: 600px) {
  .containerJoinInput {
    display: flex;
    flex-direction: column;
  }

  .joinScreenCreate {
    width: 100%;
  }
  .meetingNow h3 {
   
    font-size: 13px;
  }
}