.notificationsSection {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 40px;
}

.notifications {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
}

.notifications h3 {
  padding-bottom: 5px;
  border-bottom: 2px solid var(--bluePrimary);
  color: var(--bluePrimary);
  font-size: 18px;
}

.notificationsItems {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.notificationsItem {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.notificationsIcon {
  width: 30px;
  height: 30px;
  min-width: 30px;
  max-width: 30px;
  display: grid;
  place-items: center;
  border-radius: 8px;
}

.notificationsIcon.read {
  background-color: var(--greyPrimary);
}

.notificationsContent {
  display: flex;
  align-items: center;
  gap: 10px;
}

.notificationsText {
  color: var(--bluePrimary);
  font-size: 14px;
  font-weight: 600;
}

.notificationsText p {
  font-weight: 400;
  color: var(--blackGray);
}

.notificationsTime {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  font-size: 14px;
  color: var(--bluePrimary);
  font-weight: 600;
  text-align: end;
}

.notificationsTime p {
  font-weight: 400;
}

.filters {
  width: 30%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.filters h3 {
  padding-bottom: 5px;
  border-bottom: 2px solid var(--bluePrimary);
  color: var(--bluePrimary);
  font-size: 18px;
}

.filterButtons {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}

.buttonFilter {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 14px;
  color: var(--blackGray);
  cursor: pointer;
  padding: 10px;
  border-radius: 8px;
  width: 100%;
  font-weight: 400;
}

.buttonFilter.activeFilter {
  background-color: var(--blueWhiteTableThead);
}

.buttonFilterDelete {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 14px;
  font-weight: 600;
  color: var(--bluePrimarys);
  background-color: var(--blueWhiteTableThead);
  cursor: pointer;
  padding: 10px;
  border-radius: 8px;
  width: 100%;
}

.noItems {
  height: 100%;
  display: grid;
  place-items: center;
  gap: 30px;
  color: var(--bluePrimary);
  font-size: 18px;
  font-weight: 500;
}

.message {
  display: flex;
  align-items: center;
  gap: 10px;
}

.buy {
  background-color: var(--bluePrimary);
  border-radius: 6px;
  color: var(--white);
  padding: 10px 20px;
  transition: all 0.3s ease;
  font-size: 14px;
}

.buy:hover {
  background-color: var(--blueTertiary);
}

.dateInput {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 8px;
  font-size: 16px;
  width: 100%;
  max-width: 200px;
  margin-top: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.dateInput:focus {
  border-color: var(--bluePrimary);
  outline: none;
}

@media screen and (max-width: 550px) {
  .notificationsItem {
    flex-direction: column;
    align-items: flex-start;
  }
}


@media screen and (max-width: 1000px) {
  .notificationsSection {
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    gap: 40px;
  }

  .filters {
    width: 100%;
  }

  .filterButtons {
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    overflow-x: scroll;
  }

  .buttonFilter {
    text-wrap: nowrap;
  }

  .buttonFilterDelete {
    text-wrap: nowrap;
  }
} 
.custom-scrollbar {
  scrollbar-width: none !important; /* Firefox */
  overflow-y: scroll;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 0px !important; /* Safari y Chrome */
  background: transparent !important;
}