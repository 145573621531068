.contain-Paginado-specialist{
    display: flex;
    justify-content: center;
    gap: 4px;
    align-items: center;
    margin: 4rem auto;
}

.contain-Paginado-specialist button{
    padding: 7px;
    border-radius: 6px;
    border: 2px solid #004274;
    text-align: center;
    cursor: pointer;
    color: #004274;
    transition: .5s ease-in-out;
}

.contain-Paginado-specialist button:hover{
    background-color: #004274;
    color: white;
    border-color: white;
}