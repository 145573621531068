.hotelDateCard {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 10px 10px 29px -13px var(--shawdowPrimary);
  border: 1px solid var(--greyPrimary);
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  top: 65px;
  left: 0;
  z-index: 99999;
}
.hotelDateCard_ {
  width: 100%;
  padding: 10px;
  gap: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 20;
}
.hotelDateCard_button {
  width: 90%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
}
.hotelDateCard_button button {
  border: none;
  padding: 1rem;
  font-size: 16px;
  background-color: var(--bluePrimary);
  color: var(--white);
  font-weight: 600;
  cursor: pointer;
  border-radius: 8px;
  transition: 0.3s ease-in-out;
}
.hotelDateCard .rdrMonth{
    width: 100% !important;
  }

@media screen and (max-width: 800px) {
  .rdrCalendarWrapper,
  .rdrDateRangeWrapper {
    width: 100%;
  }
  .hotelDateCard{
    width:100%;
  }
  
}
