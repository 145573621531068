body.modal-open {
  overflow: hidden;
}

.modalContainer {
  position: fixed;
  background-color: #021624b0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 1;
  transition: all 0.2s ease;
  padding: 20px;
}

.modalContainer.close {
  position: fixed;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  pointer-events: none;
}

.cardContainer {
  background-color: white;
  max-width: 880px;
  padding: 20px 25px;
  border: 1px solid #d9d9d9;
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
  overflow-y: scroll;
  border-radius: 12px;
  position: relative;
  opacity: 1;
  transition: all 0.3s ease;
  transform: translateY(0px);
  z-index: 99999;
}

.cardContainer.close {
  opacity: 0;
  transform: translateY(-100px);
}

.modalContainerShow {
  transform: translateY(0%);
  transition: all 0.6s;
  opacity: 1;
}

.modalContainerHidden {
  transform: translateY(100%);
  transition: all 0.3s;
  opacity: 0;
}

.modalContainerShowUp {
  transform: translateY(0%);
  transition: all 0.6s;
  opacity: 1;
}

.modalContainerHiddenUp {
  transform: translateY(-100%);
  transition: all 0.3s;
  opacity: 0;
}

.modalContainerShowDown {
  transform: translateY(0%);
  transition: all 0.6s;
  opacity: 1;
}

.modalContainerHiddenDown {
  transform: translateY(100%);
  transition: all 0.3s;
  opacity: 0;
}

.modalContainerShowRight {
  transform: translateX(0%);
  transition: all 0.6s;
  opacity: 1;
}

.modalContainerHiddenRight {
  transform: translateX(100vw);
  transition: all 0.3s;
  opacity: 0;
}

.modalContainerShowLeft {
  transform: translateX(0%);
  transition: all 0.6s;
  opacity: 1;
}

.modalContainerHiddenLeft {
  transform: translateX(-100vw);
  transition: all 0.3s;
  opacity: 0;
}

.modalContainer .modalContainerBtn {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

@media screen and (max-width: 800px) {
  .modalContainer .cardContainer {
    width: 95% !important;
    transition: all ease 200ms;
  }
}
