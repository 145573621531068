.detail-pay-hotel{ 
  display: flex;
  flex-direction: column;
  gap: .6rem;
}

.detail-pay-hotel h2{
    color: var(--bluePrimary);
    font-size: 18px;
}


.row-detail-pay{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.row-detail-pay-normal{
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 300;
    font-size: 14px;
}

.row-discount-pay{
    color: #4CA391;
    font-weight: 300;
}

.row-total-pay{
    color: var(--bluePrimary);
    font-weight: 500;
    font-size: 18px;
}

.line-separation{
    width: 100%;
    height: 2px;
    background-color: var(--bluePrimary);
    margin: 1rem 0;
}

.row-aditional-services{
    display: flex;
    flex-direction: column;
    gap: .3rem;
}
.row-aditional-services p{
    color: var(--bluePrimary);
    font-weight: bold;
}

.row-aditional-services span{
    font-weight: 300;
    font-size: 13px;
}


.row-secure-hotel section{
    display: flex;
    flex-direction: column;
    gap: .3rem;
}

.row-secure-hotel section p{
    color: var(--bluePrimary);
    font-weight: bold;
}

.row-secure-hotel section span{
    font-weight: 300;
    font-size: 13px;
}