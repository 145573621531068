.formMoreInfo {
    width: 100%;
    height: 1000px;
    display: flex;
}
.formMoreInfo_img {
    width: 50%;
    height: 100%;
    position: relative;
}
.formMoreInfo_img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.formMoreInfo_form {
    width: 60%;
    height: 100%;
    background-color: #004274;
    display: flex;
    justify-content: center;
    align-items: center;
}
.formMoreInfo_form_ {
    background-color: white;
    width: 70%;
    height: 80%;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.formMoreInfo_form_title {
    width: 80%;
    margin-bottom: 10px;
}
.formMoreInfo_form_title h2 {
    font-family: 'Inter';
    font-size: 35px;
    font-weight: 200;
    line-height: 40px;
}
.formMoreInfo_form_title h2 span {
    font-size: 50px;
    display: block;
    font-weight: 800;
}
.formMoreInfo-form_form {
    width: 80%;
}
.formMoreInfo-form_form h3 {
    font-family: 'Inter';
    font-size: 14px;
    margin: 5px 0 -5px 5px;
    font-weight: 600;
}
.formMoreInfo-form_input {
    width: 100%;
    height: 40px;
    border: 1px solid #0042745e;
    border-radius: 10px;
    margin: 10px 0;
    display: flex;
    justify-content: center;
}
.errorRequired {
    margin: -5px 0 10px 20px;
    color: #d20000;
    font-family: 'Inter';
    font-size: 13px;
    font-weight: 200;
}
.formMoreInfo-form_inputMessage {
    width: 100%;
    height: 90px;
    border: 1px solid #0042745e;
    border-radius: 10px;
    margin: 10px 0 20px 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
.formMoreInfo-form_input input, .formMoreInfo-form_inputMessage textarea {
    width: 90%;
    height: 100%;
    border: none;
    background-color: rgba(0, 0, 0, 0);
    font-family: 'Inter';
}
.formMoreInfo-form_inputMessage textarea {
    width: 90%;
    height: 80%;
    border: none;
    background-color: rgba(0, 0, 0, 0);
    resize: none;
}
.formMoreInfo-form_inputSubmit {
    width: 100%;
    height: 60px;
    background-color: #004274;
    border-radius: 10px;
}
.formMoreInfo-form_inputSubmit input {
    width: 100%;
    height: 100%;
    border: none;
    background-color: rgba(0, 0, 0, 0);
    color: white;
    font-size: 20px;
    border: 10px;
    cursor: pointer;
}