.activity {
    width: 300px;
    height: 550px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0 10px;
}
.activity_img {
    width: 300px;
    height: 45%;
    pointer-events: none;
}
.activity_img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 20px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
}
.activity_text div {
    display: flex;
    align-items: center;
    margin-top: 2px;
}
.activity_text .css-b6lsxj-MuiButtonBase-root-MuiCardActionArea-root {
    height: 80px !important;
}
.activity_text h2 {
    width: 90%;
    font-family: 'Inter';
    font-weight: 300;
    font-size: 25px;
    text-transform: capitalize !important;
    text-align: center;
    margin: 0 auto;
    margin-top: 10px;
}
.activity_text p {
    font-family: 'Inter';
    font-size: 16px;
    text-transform: capitalize;
    font-weight: 200;
}
.activity-text_p {
    margin-left: 10px;
}
.activity-text_price {
    margin: 20px 10px 0 40px;
    display: flex;
    align-items: center;
}
.activity-text_price span {
    font-size: 20px;
}
.activity-text_price span:nth-child(2n+0) {
    font-size: 30px;
    font-weight: 600;
    margin-left: 20px;
    color: #004274
}
.activity_rating {
    width: 100%;
   text-align: center;
}