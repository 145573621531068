.input-data-simple{
    display: flex;
    flex-direction: column;
}

.input-data-simple input{
    height: 40px;
    border: 1px solid #D9D9D9;
    border-radius: 8px;
    padding-inline: 10px;
    font-size: 12px;
    font-weight: 400;
    font-family: "Montserrat" !important;
    transition: 0.3s;
}

.input-data-simple label{
    font-family: "Montserrat" !important;
    font-size: 12px;
    font-weight: 400;
    padding-top: 10px;
}

.input-data-date input[type="date"]::-webkit-inner-spin-button,
.input-data-date input[type="date"]::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
    margin: 0;
}

.input-data-date{
    background-color: url('../../../../../ui/assets/iconsSVG/IconCalendar4.svg') no-repeat;
}

.input-simple-validate input{
    border: 1px solid red;
}

.span-simple-error{
    color: var(--redPrimary);
    font-size: 12px;
    font-family: "Montserrat" !important;
    font-weight: 400;
    margin-top: 1px;
}