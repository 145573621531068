.cartSection {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 40px;
}

.cartItems {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.cart {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
}

.cart h3 {
  padding-bottom: 5px;
  border-bottom: 2px solid var(--bluePrimary);
  color: var(--bluePrimary);
  font-size: 18px;
}

.total {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 30%;
}

.total h3 {
  padding-bottom: 5px;
  border-bottom: 2px solid var(--bluePrimary);
  color: var(--bluePrimary);
  font-size: 18px;
}

.itemCard {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 13px;
  border: 1px solid var(--blueWhiteTableThead);
  border-radius: 10px;
  padding: 15px;
}

.itemHeader {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.itemHeaderIconText {
  display: flex;
  align-items: center;
  gap: 10px;
}

.itemHeaderIconTitle {
  display: flex;
  gap: 10px;
  align-items: center;
}

.itemImage {
  padding-left: 20px;
  border-left: 2px solid var(--bluePrimary);
  height: 100%;
  margin-left: 10px;
}

.itemImage img{
  max-height: 43px;
}

.itemDate {
  font-size: 14px;
  font-weight: 600;
  color: var(--bluePrimary);
}
.itemHeaderText {
  display: flex;
  flex-direction: column;
  gap: 2px;
  color: var(--bluePrimary);
  font-size: 14px;
  font-weight: 500;
}

.itemHeaderText h4 {
  font-size: 18px;
}

.icon {
  font-size: 18px;
  background-color: var(--bluePrimary);
  color: var(--white);
  width: 30px;
  height: 30px;
  border-radius: 6px;
  display: grid;
  place-items: center;
}

.itemDetails {
  display: flex;
  justify-content: space-between;
  gap: 50px;
  align-items: flex-end;
}

.itemBaggage {
  display: flex;
  align-items: flex-end;
  gap: 10px;
  font-size: 20px;
  color: var(--bluePrimary);
}

.luggageList {
  display: flex;
  gap: 10px;
  align-items: flex-end;
  list-style: none;
}

.detailsButton {
  font-size: 14px;
  color: var(--bluePrimary);
  text-decoration: underline;
  cursor: pointer;
}

.priceButton {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.price {
  font-size: 18px;
  font-weight: 700;
  color: var(--bluePrimary);
}

.payButton {
  background-color: var(--bluePrimary);
  border-radius: 7px;
  padding: 8px 15px;
  color: var(--white);
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
}

.checkPayButton {
  display: flex;
  gap: 10px;
  align-items: center;
}

.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.container {
  display: block;
  position: relative;
  cursor: pointer;
  font-size: 20px;
  user-select: none;
}

.checkmark {
  position: relative;
  top: 0;
  left: 0;
  height: 1.63em;
  width: 1.63em;
  background-color: var(--greyPrimary);
  transition: all 0.3s;
  border-radius: 5px;
}

.container input:checked ~ .checkmark {
  background-color: var(--blueLight);
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.container input:checked ~ .checkmark:after {
  display: block;
}

.container .checkmark:after {
  left: 0.60em;
  top: 0.40em;
  width: 0.25em;
  height: 0.5em;
  border: solid var(--white);
  border-width: 0 0.15em 0.15em 0;
  transform: rotate(45deg);
}


.totalItems {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.selectedItems {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.selectedItem {
  border: 1px solid var(--blueWhiteTableThead);
  border-radius: 8px;
  padding: 10px;
}

.selectedItem h4 {
    color: var(--bluePrimary);
}

.selectedItem p {
    font-size: 14px;
}

.selectedItems ul li {
    list-style: none;
}

.selectedItem {
    display: flex;
    flex-direction: column;
    gap: 3px;
    position: relative;
}

.priceTotal {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 10px;
    align-items: flex-start;
    color: var(--bluePrimary);
    padding-top: 20px;
    border-top: 2px solid var(--bluePrimary);
}

.selectedItemsUl {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.priceItem {
    font-size: 14px;

}

.removeItemButton {
    position: absolute;
    top: 5px;
    right: 5px;
    color: var(--bluePrimary);
    cursor: pointer;
}


.removeAllButton {
    background-color: var(--blueWhiteTableThead);
    color: var(--bluePrimary);
    padding: 10px;
    cursor: pointer;
    border-radius: 8px;
    font-weight: 500;
}


.noItems {
    display: grid;
    place-items: center;
    gap: 20px;
}

.message {
    display: flex;
    align-items: center;
    gap: 10px;
    font-weight: 500;
    color: var(--bluePrimary);
}

.buy {
    background-color: var(--bluePrimary);
    padding: 10px 20px;
    border-radius: 8px;
    color: var(--white);
    text-decoration: none;
}


@media screen and (max-width: 1000px) {
  .cartSection {
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    gap: 40px;
  }

  .total {
    width: 100%;
  }
}

@media screen and (max-width: 675px) {
  .itemHeader {
    flex-direction: column;
    align-items: flex-start;
  }

  .itemHeaderIconText {
    width: 100%;
    justify-content: space-between;
  }
}

 @media screen and (max-width: 550px) {
  .itemHeaderIconText {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
  }

  .itemHeaderIconTitle {
    width: 100%;
  }

  .itemImage {
    border: none;
    padding: 0px;
  }

  .itemDetails {
    flex-direction: column;
    align-items: flex-start;
  }
 }
 .deleteItem{
  background-color: #D9D9D9;
  height: 35px;
  width: 35px;
  border-radius: 8px;
  padding: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
 }
 .deleteItem svg{
  color: #004274;
  font-size: 21px;
 }