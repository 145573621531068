.contain-detail-pay{
    background-color: white;
    border: 1px solid rgb(215, 215, 215);
    border-radius: 8px;
    margin-top: 1rem;
}

.content-layout-detail-pay{
    display: flex;
    justify-content: space-between;
    padding: 1rem;
}

.column-description-pay{
    width: 60%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.contain-detail-pay p{
    font-size: 14px;
}

.column-price-pay{
    width: 38%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    text-align: end;
}

.letter-green{
    color: #8FE3C6;
}

.line-separate{
    width: 100%;
    height: 1px;
    background-color: rgb(200, 200, 200);
}