.content-best-experiences {
  width: 90%;
  max-width: 1280px;
  margin: 3rem auto;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.content-best-experiences .header-experiences {
  display: flex;
  flex-direction: column;
}
.content-best-experiences .header-experiences h2 {
  text-transform: uppercase;
  color: var(--blueLight);
  font-size: 36px;
  font-weight: 900;
}

.content-best-experiences .header-experiences p {
  font-size: 12px;
}

.header-experiences2 h2{
  text-transform: uppercase;
  color: var(--blueLight);
  font-size: 24px;
  font-weight: 700;
}

.content-img-best-experience .modal-top {
  position: absolute;
  background-color: var(--bluePrimary);
  color: white;
  font-weight: 700;
  padding: 0.5rem;
  right: 0;
  top: 65%;
  border-bottom-left-radius: 15px;
  border-top-left-radius: 15px;
}
.content-best-experiences .card img {
  width: 100%;
  object-fit: cover;
  border-radius: 6px;
}

.content-best-experiences .card h3 {
  font-size: 15px !important;
}

.content-img-best-experience {
  position: relative;
  width: 300px;
}

@media screen and (max-width: 1100px) {
  .content-best-experiences {
    width: 95%;
  }
}

@media screen and (max-width: 700px) {
}

.slider-experience {
  width: 100%;
}

.slider-experience .slick-prev:before,
.slider-experience .slick-next:before {
  color: var(--white) !important;
  font-size: 40px !important;
}

.slider-experience .slick-next {
  right: 50px !important;
}

.slider-experience .slick-prev {
  left: 20px !important;
}

.slider-experience .slick-track {
  margin: 1rem 0;
}

.slider-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: var(--white);
  border-radius: 50%;
  cursor: pointer;
  z-index: 9999;
}

.slider-button.prev {
  left: -25px;
  transition: all 0.3s ease;
}

.slider-button.next {
  right: -25px;
  transition: all 0.3s ease;
}
