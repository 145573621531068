

.content-detail-buy h2{
    color: rgb(50, 50, 50);
    margin-bottom: 1rem;
    margin-top: 1rem;
}

.p-fligth-buy{
    color: rgb(50, 50, 50);
    font-size: 13px;
}

.contain-round-trip-buy-pack{
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    gap: .5rem;
}

.contain-round-trip-buy-pack p{
    font-weight: 600;
}

.content-depar-arr{
    display: flex;
    margin-top: 1rem;
    justify-content: space-around;
}


.content-depar-arr section p{
    color: rgb(50, 50, 50);
}

.content-depar-arr section h3,.date-departure-buy{
    color: rgb(50, 50, 50);
}