body.modal-open {
  overflow: hidden;
}
.modalWishToIncludeContainer {
  position: fixed;
  background-color: #021624b0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 1;
  transition: all 0.2s ease;
  padding: 30px;
}

.modalWishToIncludeContainer.close {
  position: fixed;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  pointer-events: none;
}

.modalWishToIncludeCard {
  display: flex;
  flex-direction: column;
  gap: 15px;
  background-color: white;
  width: 100%;
  max-width: 680px;
  max-height: 600px;
  overflow-y: scroll;
  border-radius: 12px;
  padding: 20px;
  padding-bottom: 0;
  position: relative;
  opacity: 1;
  transition: all 0.3s ease;
  transform: translateY(0px);
  z-index: 99999;
}

.modalWishToIncludeCard.close {
  background-color: white;
  width: 100%;
  width: 100%;
  max-width: 680px;
  max-height: 600px;
  overflow-y: scroll;
  border-radius: 10px;
  padding: 20px;
  padding-bottom: 0;
  position: relative;
  opacity: 0;
  transition: all 0.3s ease;
  transform: translateY(-100px);
}

.modalWishToIncludeCenter {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
  padding-bottom: 20px;
}

.modalWishToIncludeCardClose {
  position: absolute;
  right: 7px;
  top: 7px;
  cursor: pointer;
  z-index: 999999;
  background: #fff;
  padding: 5px;
}

.modalWishToIncludeCardHead {
  width: 100%;
  justify-content: center;
  display: flex;
}

.modalWishToIncludeCardHeadIconWrap {
  height: 140px;
  width: 140px;
}

.modalWishToIncludeCardHeadIcon {
  width: 100%;
  height: 100%;
}

.modalWishToIncludeCardBody {
  width: 100%;
  display: block;
}

.modalWishToIncludeCardBodyTitle {
  text-align: center;
  font-weight: bolder;
  font-size: 26px;
  color: #104172;
  font-family: "Montserrat", sans-serif;
}

.modalWishToIncludeCardBodysubTitle {
  text-align: center;
  font-weight: bolder;
  font-size: 18px;
  color: #104172;
  font-family: "Montserrat", sans-serif;
}

.modalWishToIncludeCardFoot {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
  gap: 10px;
}

.modalWishToIncludeCardFootBtn {
  width: 60%;
  color: white;
  background-color: #104172;
  border-radius: 8px;
  padding: 10px 20px;
  transition: all 0.3s ease;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
}

.modalWishToIncludeCardFootBtn:hover {
  background-color: #165797;
}

.modalWishToIncludeCardFootBtnText {
  color: #ffffff;
  text-align: center;
  line-height: 20px;
  font-weight: bolder;
  font-size: 14px;

  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
}
