.hotelRoomsCard {
    position: absolute;
    background-color: white;
    border-radius: 10px;
    top: 65px;
    left: 0;
    z-index: 2;
    border: 1px solid var(--greyPrimary);
    box-shadow: 10px 10px 29px -13px rgba(0, 0, 0, 0.141);
}

.hotelRoomsCardScroll1 {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
}

.hotelRoomsCardScroll2 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.hotelRoomsCardScroll3{
    border: 1.5px solid var(--greyPrimary);
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.hotelRoomsCard_ {
    padding: 0 10px;
    display: flex;
    justify-content: center
}
.hotelRoomsCard_addRoom {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0;
    gap: 10px;
}
.hotelRoomsCard_addRoom p {
    text-decoration: underline;
    font-family: 'Inter';
    font-weight: 600;
    color: var(--bluePrimary);
    cursor: pointer;
    font-size: 16px;
    transition: .1s ease-out;
}
.hotelRoomsCard_addRoomButton {
    width: 100%;
    padding: 12px 15px;
    border-radius: 10px;
    border: none;
    background-color: var(--bluePrimary);
    color: white;
    font-size: 'Inter';
    font-size: 16px;
    cursor: pointer;
}


.hotelRoomsCard_addRoom p:hover {
    color: rgba(0, 66, 116, .8);
}