.container-all-passengers-tourism{
    padding-inline: 110px;
}

.container-header-package-tourism{
    height: 102px;
    padding: 20px;
    background-color: #004274;
    box-shadow: 0px 4px 4px 0px #00000040;
    border-radius: 23px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.container-header-package-tourism p{
    color: white;
    font-size: 24px;
    font-weight: 700;
    font-family: "Montserrat" !important;
}

.container-data-description-tourism{
    width: 34%;
    box-shadow: 0px 4px 4px 0px #00000040;
    border-radius: 25px;
    padding: 40px !important;
}

@media screen and (max-width: 1220px){
    .container-data-description-tourism{
        width: 100%;        
    }
}

.container-data-description-tourism p{
    color: #004274;
    font-size: 14px;
    font-weight: 700;
    font-family: "Montserrat" !important;
}

.list-package-tourism{
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
}

.btn-pay-package-tourism{
    margin-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.btn-pay-package-tourism button{
    background-color: #004274;
    color: white;
    font-family: "Montserrat" !important;
    font-size: 12px;
    font-weight: 700;
    height: 30px;
    width: 100px;
    border-radius: 8px;
    cursor: pointer;
}