.formUpdateUser {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.formUpdateUser h3 {
    color: var(--bluePrimary);
}

.flexRowContainItemForm {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.flexRowContainItemInput {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 5px;
}


.inputsFlex {
    display: flex;
    gap: 10px;
    justify-content: space-between;
}


.label {
    font-size: 12px;
    font-weight: 700;
    color: var(--blackGray);
    width: fit-content;
}

.input {
    height: 40px;
    border: 1px solid var(--greyPrimary);
    border-radius: 8px;
    overflow: hidden;
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: space-between;
    padding-right: 10px;
}

.input.error {
    border: 1px solid var(--redPrimary);
}

.input input {
    border: none;
    width: 100%;
    height: 100%;
    padding: 8px;
}


.messageInput {
    font-size: 12px;
    font-weight: 400;
    color: var(--redPrimary);
}

.submitButton {
    background-color: var(--bluePrimary);
    color: var(--white);
    pointer-events: all;
    cursor: pointer;
    font-weight: 500;
    transition: all 0.3s ease;
    padding: 10px;
    font-size: 14px;
    border-radius: 8px;
}

.submitButton:hover {
    background-color: var(--blueTertiary);
    color: var(--white);
    pointer-events: all;
    cursor: pointer;
    font-weight: 500;
}

.eyeIcon {
    cursor: pointer;
}

.responseSave {
    text-align: center;
    background-color: var(--greenPrimary);
    color: var(--white);
    font-weight: 500;
    transition: all 0.3s ease;
    padding: 10px;
    font-size: 14px;
    border-radius: 8px;
}

.success {
    background-color: var(--greenPrimary);
}

.error {
    background-color: var(--redPrimary);
}

.warning {
    background-color: var(--orangePrimary);
}

.spinner {
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}