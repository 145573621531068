.CardGaleryHeader {
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
  border-bottom: solid 1px var(--bluePrimary);
}

.CardGaleryHeader .title {
  color: var(--bluePrimary);
  font-size: 20px;
  font-weight: bolder;
  font-family: 'Montserrat', sans-serif;
}

.CardGaleryHeader .subtitle {
  color: var(--black);
  font-size: 14px;
  font-weight: 400;
  font-family: 'Montserrat', sans-serif;
}

/* -------------------- body init -------------------- */
.CardGaleryBody {
  display: flex;
  flex-direction: column;
}

.CardGaleryBodyContainer {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  justify-content: space-between;
}

.CardGaleryBigButton {
  background-color: var(--bluePrimary);
  width: 160px;
  min-width: 160px;
  max-width: 160px;
  height: 160px;
  border-radius: 20px;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  transition: all ease 200ms;
}

.CardGaleryBigButton:hover {
  transform: translateY(-4px);
  -webkit-box-shadow: 10px 10px 29px -13px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 29px -13px rgba(0, 0, 0, 0.75);
  box-shadow: 10px 10px 29px -13px rgba(0, 0, 0, 0.75);
}

.CardGaleryBigButtonText {
  color: var(--white);
  font-size: 14px;
  font-weight: bolder;
  font-family: 'Montserrat', sans-serif;
}

.CardGaleryBodyContainerForm {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-left: 15px;
  justify-content: center;
}

.CardGaleryBodyContainerFormRow {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  justify-content: space-between;
}

.CardGaleryBodyContainerFormGroup {
  width: 95%;
  display: flex;
  flex-direction: column;
}

.CardGaleryBodyContainerFormGroup p {
  color: var(--bluePrimary);
  font-size: 14px;
  font-weight: 400;
  font-family: 'Montserrat', sans-serif;
  margin-bottom: 5px;
}

.CardGaleryBodyContainerFormGroup input {
  width: 100%;
  border: none;
  border-radius: 8px;
  background-color: var(--bluePrimary);
  color: var(--white);
  height: 30px;
  padding: 5px 10px;
}

.CardGaleryBodyContainerFormGroup textarea {
  width: 90%;
  border: none;
  border-radius: 8px;
  background-color: var(--bluePrimary);
  color: var(--white);
  padding: 5px 10px;
  height: 100px;
  min-height: 100px;
  max-height: 100px;
  width: 100%;
  min-width: 100%;
  max-width: 100%;
}

/* -------------------- body end -------------------- */
.CardGaleryFooter {
  margin: 20px auto 10px auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.CardGaleryFooterBtn {
  height: 30px;
  border-radius: 12px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: var(--bluePrimary);
  cursor: pointer;
  transition: all ease 200ms;
}

.CardGaleryFooterBtn:hover {
  transform: translateY(-4px);
  transition: all ease 200ms;
  -webkit-box-shadow: 10px 10px 10px -10px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 10px -10px rgba(0, 0, 0, 0.75);
  box-shadow: 10px 10px 10px -10px rgba(0, 0, 0, 0.75);
}

.CardGaleryFooterBtn.Small {
  width: 45%;
}

.CardGaleryFooterBtn.Big {
  width: 100%;
}

.CardGaleryFooterBtnText {
  color: var(--white);
  font-size: 14px;
  font-weight: bolder;
  font-family: 'Montserrat', sans-serif;
}

.gallerySeparator {
  height: 50px;
}

.GalleryAdminButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 5px;
  border-radius: 12px;
  background-color: var(--bluePrimary);
  cursor: pointer;
  transition: all ease 200ms;
}

.GalleryAdminButton:hover {
  transform: translateY(-4px);
  -webkit-box-shadow: 10px 10px 29px -13px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 29px -13px rgba(0, 0, 0, 0.75);
  box-shadow: 10px 10px 29px -13px rgba(0, 0, 0, 0.75);
}

.GalleryAdminButtonText {
  margin-left: 5px;
  color: var(--white);
  font-size: 10px;
  font-weight: bolder;
  font-family: 'Montserrat', sans-serif;
}

/* -------------- -------------- */
.filesContainer {
  margin-top: 10px;
}

.filesContainer .fileColumn {
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
  border-bottom: solid var(--greyPrimary) 1px;
}

.filesContainer .fileRow {
  display: flex;
  flex-direction: row;
}

.filesContainer .fileRow .fileRowInfo {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 85%;
  align-items: center;
  font-size: 12px;
  font-weight: 400;
  font-family: 'Montserrat', sans-serif;
  color: var(--black);
}

.filesContainer .fileRow .fileRowInfoSize {
  width: 20%;
}

.filesContainer .fileRow .fileRowInfoName {
  padding-left: 5px;
  width: 80%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.filesContainer .fileRow .fileRowOpctions {
  padding: 2px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 15%;
}

.filesContainer .fileRow .fileRowOpctionsBtn {
  width: 30px;
  height: 30px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all ease 200ms;
}

.filesContainer .fileRow .fileRowOpctionsBtn:hover {
  scale: 1.75;
  transition: all ease 200ms;
}

.filesContainer .fileRowError {
  width: 100%;
  padding: 5px;
}

.filesContainer .fileRowErrorText {
  color: red;
  font-size: 10px;
  font-weight: bolder;
  font-family: 'Montserrat', sans-serif;
}

.messageError {
  margin-top: 20px;
  width: 100%;
  text-align: center;
  color: red;
  font-size: 12px;
  font-weight: bolder;
  font-family: 'Montserrat', sans-serif;
}

.CardGaleryBody.specialityContainer {
  display: flex;
  flex-direction: column;
  height: 300px;
  overflow: scroll;
  overflow-y: scroll;
}

.CardGaleryBody .specialityWrap {
  display: flex;
  flex-direction: row;
  padding: 5px;
  margin-bottom: 5px;
  border-bottom: solid var(--greyPrimary) 1px;
}

.CardGaleryBody .specialityName {
  margin-left: 10px;
  color: var(--black);
  font-size: 12px;
  font-weight: 400;
  font-family: 'Montserrat', sans-serif;
}

.acknowledgmentsList {
  margin-top: 5px;
  display: flex;
  flex-direction: column;
  max-height: 210px;
  overflow: scroll;
  overflow-y: scroll;
  background-color: rgba(0, 0, 0, 0.05);
  border-bottom: solid var(--greyPrimary) 1px;
  border-top: solid var(--greyPrimary) 1px;
}

.acknowledgmentsFile {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 0px 4px 8px;
  border-bottom: solid var(--greyPrimary) 1px;
}

.acknowledgmentsFileImgWrap {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  overflow: hidden;
}

.acknowledgmentsFileImg {
  width: 100%;
  height: 100%;
}

.acknowledgmentsFileName {
  margin-left: 10px;
  color: var(--black);
  font-size: 14px;
  font-weight: 400;
  font-family: 'Montserrat', sans-serif;
}

.modalCardAcknowledgments {
  display: flex;
  flex-direction: column;
  height: 80%;
  width: 80%;
  justify-content: space-between;
}

.modalCardAcknowledgmentsHead {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
}

.modalCardAcknowledgmentsHeadTitle {
  color: var(--bluePrimary);
  font-size: 20px;
  font-weight: bolder;
  font-family: 'Montserrat', sans-serif;
  margin-bottom: 15px;
  text-align: center;
}

.modalCardAcknowledgmentsHeadSubTitle {
  color: var(--black);
  font-size: 12px;
  font-weight: 400;
  font-family: 'Montserrat', sans-serif;
  text-align: center;
}

.modalCardAcknowledgmentsBody {
  display: flex;
  flex-direction: column;
}

.modalCardAcknowledgmentsBodyGroup {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
}

.modalCardAcknowledgmentsBodyGroupLabel {
  margin-left: 10px;
  color: var(--black);
  font-size: 14px;
  font-weight: 400;
  font-family: 'Montserrat', sans-serif;
}

.modalCardAcknowledgmentsBodyGroupInputWrap {
  background-color: var(--greyPrimary);
  width: 100%;
  height: 30px;
  border-radius: 15px;
  overflow: hidden;

  align-items: center;
  display: flex;
}

.modalCardAcknowledgmentsBodyGroupInput {
  border: none;
  background-color: transparent;
  margin: auto 10px;
  width: 100%;
  height: 100%;
  color: var(--black);
}

.modalCardAcknowledgmentsBodyGroupInput[type=file] {
  margin-top: 8px;
}

.modalCardAcknowledgmentsFoot {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modalCardAcknowledgmentsFootBtn {
  padding: 5px;
  border-radius: 12px;
  background-color: var(--bluePrimary);
  width: 60%;
  align-items: center;
  display: flex;
  justify-content: center;
}

.modalCardAcknowledgmentsFootBtnText {
  text-align: center;
  color: var(--white);
  font-size: 14px;
  font-weight: bolder;
  font-family: 'Montserrat', sans-serif;
}

.galleryFileFooter {
  display: flex;
  flex-direction: row;
  justify-content: center;
}