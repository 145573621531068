.openModalButton {
    background-color: var(--bluePrimary);
    border-radius: 8px;
    color: var(--white);
    width: fit-content;
    padding: 10px 25px;
    font-size: 15px;
    font-weight: 500;
    transition: all 0.3s ease;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 10px;
}

.openModalButton:hover {
    background-color: var(--blueTertiary);
}

.positionButton {
    display: flex;
    justify-content: flex-end;
    margin-top: 2rem;
}

.container_form_photos {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 20px;
}

.container_photos {

    border: 1px solid var(--grayLight);
    border-radius: 8px;
    background-color: var(--white);
}

.container_photos h3 {
    font-size: 20px;
    font-weight: 600;
    color: var(--bluePrimary); 
}

.grid_container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
    margin-top: 2rem;
}

.grid_container div {
    position: relative;
}

.grid_container img {
    width: 100%;
    height: auto;
    border-radius: 8px;
    object-fit: cover;
}

.itemFileImage {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 5px;

}

.itemFileImageContent {
    height: 100%;
    overflow: hidden;
    border-radius: 8px;
    border: 1px solid var(--greyPrimary);
}

.itemFileImageContent img {
    height: 100%;
    min-height: 400px;
    max-height: 400px; 
}
.contRecomendationsModal{
    display: flex;
    flex-direction: column;
}
.contRecomendationsModal h5{
    font-size: 18px;
    font-weight: 600;
    color: var(--bluePrimary); 
    margin-left: -10px;
    margin-bottom: -5px;
}
.contRecomendationsModal p{
    color: var(--bluePrimary); 
}
.bottomTextModal p{
    color: var(--bluePrimary); 
}

.custumFileUpload {
    display: flex;
    align-items: center;
    align-items: space-between;
    gap: 10px;
    cursor: pointer;
    justify-content: center;
    border: 2px dashed var(--greyPrimary);
    background-color: var(--blueWhiteTable);
    padding: 1rem;
    border-radius: 10px;
    box-shadow: 0px 48px 35px -48px #e8e8e8;
    color: var(--greySecundary);
    font-size: 16px !important;
}

.custumFileUpload .icon {
    display: flex;
    align-items: center;
    justify-content: center;
}

.text {
    display: flex;
    align-items: center;
    justify-content: center;

}

.text {
    font-weight: 400;
    font-size: 16px !important;
    color: var(--greyPrimary);
}

.custumFileUpload input {
    display: none;
}

.custumFileUpload.uploaded {
    border: 2px dashed var(--greenTransparent);
}

.uploadedText.uploaded {
    color: var(--greenSecundary);
}

.icon.uploaded {
    color: var(--greenSecundary);
}



@media screen and (max-width: 640px) {
    .openModalButton {
        width: 100%;
        align-items: center;
        justify-content: center;
    }

    .grid_container {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 16px;
    }

    .container_form_photos {
        overflow-y: scroll;
    }
}

.divTopModal{
    width: 100%;
    display: flex; 
    align-items: flex-start;
    justify-content: space-between;
}
.divTopModal svg{
    color: #004274;
    font-size: 30px;
    cursor: pointer;
}