.chooseUpsell {
  background-color: #104172;
  width: 100%;
  text-align: center;
}

.a-link-contact {
  color: #104172;
  text-decoration: underline;
} 

.loadConfigFlight {
  width: 20px;
  height: 20px;
  animation-name: loadConfigFlight;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  transition: all ease 200ms;
}

@keyframes loadConfigFlight {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.chooseUpsell .iconWrap {
  width: 50px;
  height: 50px;
}

.chooseUpsell .iconWrapSax {
  width: 100%;
}

.chooseUpsell .column {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
}

.chooseUpsell .row {
  display: flex;
  flex-direction: row;
}

.chooseUpsell .chooseUpsellTextBig {
  font-size: 18px;
  color: var(--white);
  font-family: "Montserrat", sans-serif;
  font-weight: bolder;
}

.chooseUpsell .chooseUpsellTextSmall {
  font-size: 10px;
  color: var(--white);
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
}

.chooseUpsell .btn {
  cursor: pointer;
}

button {
  border: none;
  padding: 0px;
  background-color: transparent;
}

.icon {
  width: 100%;
  height: 100%;
}

.upsellSectionEmpty {
  position: fixed;
  background-color: #021624b0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 1;
  transition: opacity 0.3s ease, background-color 0.3s ease;
  padding: 30px;
}

.upsellSectionEmpty.close {
  background-color: transparent;
  opacity: 0;
  pointer-events: none;
}

.upsellSectionEmptyHead {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 40px;
  border-bottom: 1px solid #104172;
  color: #104172;
}

.upsellSectionEmptyContent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 15px;
  background-color: white;
  height: fit-content;
  max-width: 800px;
  max-height: 880px;
  overflow-y: scroll;
  border-radius: 12px;
  padding-bottom: 0;
  position: relative;
  opacity: 1;
  transition: opacity 0.3s ease, transform 0.3s ease, visibility 0.2s ease;
  transform: translateY(0px);
  z-index: 99999;
}

.upsellSectionEmptyContent.close {
  opacity: 0;
  transform: translateY(-100px);
  visibility: hidden;
  z-index: -999;
}

.upsellSectionEmptyHeadLeft {
  display: flex;
  align-items: center;
  gap: 20px;
}

.upsellSectionEmptyHeadRigth {
  padding-top: 5px;
}

.upsellSectionEmptyHeadTitle {
  font-weight: bolder;
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
}

.upsellSectionEmptyBody {
  padding: 10px 30px;
  flex-direction: column;
}

.upsellSectionEmptyBodyGroup {
  display: block;
  padding: 5px 0px;
}

.upsellSectionEmptyBodyGroupVisible {
  background-color: #ffffff;
  align-items: center;
  display: flex;
}

.upsellSectionEmptyBodyGroupVisibleIcon {
  padding-top: 5px;
  /* transform: rotate(90deg); */
}

.upsellSectionEmptyBodyGroupVisibleText {
  margin-left: 10px;
  font-weight: 600;
  font-size: 16px;
  color: #104172;
  font-family: "Montserrat", sans-serif;
}

.upsellSectionEmptyBodyGroupHidden {
  border-top: 1px solid #ededed;
  background-color: #ffffff;
  margin-top: 10px;
}

.upsellSectionEmptyBodyGroupHiddenText {
  padding: 10px;
  font-size: 12px;
  line-height: 15px;
  color: #104172;
  text-align: justify;
}

.upsellSectionEmptyBodyGroupVisible:hover {
  border-radius: 15px 15px 0px 0px;
}

.upsellSectionEmptyBodyGroupButtonContinue {
  padding: 10px 0px;
  display: flex;
  justify-content: end;
}

.upsellSectionButtonContinue {
  background-color: var(--bluePrimary);
  padding: 8px 15px;
  border-radius: 8px;
  color: var(--white);
  cursor: pointer;
  transition: all 0.3s ease;
}

.upsellSectionButtonContinue:hover {
  background-color: var(--blueTertiary);
}

/*upsellSectionEmpty end */

.upsellFooterBody {
  position: fixed;
  background-color: #021624b0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 1;
  transition: opacity 0.3s ease, background-color 0.3s ease;
  padding: 20px;
}

.upsellFooterBody.close {
  background-color: transparent;
  opacity: 0;
  pointer-events: none;
}
 
.upsellContentModal {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 15px;
  background-color: white;
  height: fit-content;
  max-width: 800px;
  max-height: 650px;
  overflow-y: scroll;
  border-radius: 12px;
  padding: 20px;
  padding-bottom: 0;
  padding-right: 2px !important;
  position: relative;
  opacity: 1;
  transition: opacity 0.3s ease, transform 0.3s ease, visibility 0.2s ease;
  transform: translateY(0px);
  z-index: 99999;
  scrollbar-width: none; /* Firefox */
}

.upsellContentModal::-webkit-scrollbar {
  width: 0px; /* Safari y Chrome */
  background: transparent; /* Opcional: hace que el fondo de la barra de desplazamiento sea transparente */
}

.upsellContentModal.close {
  opacity: 0;
  transform: translateY(-100px);
  visibility: hidden;
  z-index: -999;
}

.upsellContentModalClose {
  position: absolute;
  top: 12px;
  right: 13px;
  cursor: pointer;
}

.upsellContentContinue {
  background-color: #fff;
  border-top: 1px solid #ededed;
  padding: 15px 0px;
  position: sticky;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: 20px;
}

@media screen and (max-width: 550px) {
  .upsellContentContinue {
    flex-direction: column;
  }
}

.upsellContentContinueTitle {
  width: 100%;
  border-right: 1px solid #ededed;
  display: flex;
  font-size: 12px;
  justify-content: flex-end;
  align-items: center;
  gap: 15px;
}

.upsellContentContinueTitle span {
  border: 1.5px solid #104172;
  padding: 0.5rem;
  border-radius: 5px;
  color: #104172;
  font-weight: 800;
  text-wrap: nowrap;
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
}

.upsellContentContinueButton {
  border-radius: 8px;
  background-color: #104172;
  padding: 10px 20px;
  color: #ffffff;
  cursor: pointer;
  font-weight: 600;
  font-size: 14px;
  transition: all 0.3s ease;
  user-select: none;
}

.upsellContentContinueButton:hover {
  background-color: #185897;
}

.upsellContentContinueButton.disabled {
  background-color: #104172df;
  pointer-events: none;
}

.upsellContentCards {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.upsellContentOptions {
  display: flex;
  gap: 20px;
  justify-content: center;
  flex-direction: column;
  padding-top: 15px;
}

.upsellContentOptionsTarifs {
  position: relative;
}

.upsellContentOptionsTarifsView {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #104172;
  padding: 5px 15px;
  border-radius: 10px 0px 10px 0px;
  color: #fff;
  font-size: 14px;
}

.upsellContentOptionsTarifsTrip {
  position: absolute;
  width: 100%;
  top: 0;
  opacity: 0;
  z-index: -9;
  pointer-events: none;
  transition: all 0.3s ease;
  transform: translateX(-50px);
}

.upsellContentOptionsTarifsTrip.active {
  position: relative;
  width: 100%;
  top: 0;
  z-index: 0;
  opacity: 1;
  pointer-events: all;
  transform: translateX(0px);
}

.upsellContentOptionsTarifsWay {
  width: 100%;
  position: absolute;
  z-index: -9;
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s ease;
  transform: translateX(50px);
}

.upsellContentOptionsTarifsWay.active {
  width: 100%;
  opacity: 1;
  position: relative;
  z-index: 0;
  pointer-events: all;
  transform: translateX(0px);
}

.upsellContentGoing {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.upsellContentReturn {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.upsellContentOption {
  color: #104172;
  text-align: center;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  transition: all ease 200ms;
}

.upsellContentOptionTitle {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background-color: #cbcbcb;
  color: #104172;
  width: 100%;
  padding: 10px 20px;
  transition: all ease 200ms;
  border-radius: 10px 10px 0px 0px;
}

.upsellContentOptionTitle.active {
  background-color: #104172;
  color: #ffffff;
  width: 100%;
  padding: 10px 20px;
}

.upsellFooter {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.upsellFooterPrice {
  width: 80%;
  background-color: #104172;
  padding: 10px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.upsellFooterPay {
  height: 60px;
  width: 19%;
  background-color: #104172;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all ease 200ms;
}

.upsellFooterPay:hover {
  transform: translateY(-4px);
}

.upsellFooterPay:active {
  transform: translateY(4px);
  border-radius: 20px;
}

.upsellFooterText {
  color: #ffffff;
  font-weight: bolder;
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  text-align: center;
}

/* upsell card init*/
.upsellCard {
  display: flex;
  flex-direction: column;
}

.upsellCardHead {
  position: relative;
  display: flex;
  height: 40px;
  margin-bottom: 10px;
}

.upsellCardHeadName {
  width: 50%;
  background-color: #104172;
  align-items: center;
  display: flex;
  justify-content: flex-start;
  padding: 8px;
}

.upsellCardHeadNameText {
  color: #ffffff;
  font-weight: bolder;
  font-size: 20px;
  font-family: "Montserrat", sans-serif;
}

.upsellCardHeadList {
  width: 50%;
  background-color: #ffffff;
  align-items: center;
  display: flex;
  justify-content: flex-end;
  padding: 8px;
  border: 2px solid #104172;
}

.upsellCardHeadListText {
  margin-right: 5px;
  font-weight: bolder;
  color: #104172;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  text-align: right;
}

.containerArrowCircle {
  cursor: pointer;
  background-color: #104172;
  width: 28px;
  height: 28px;
  border-radius: 14px;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  display: flex;
}

.upsellCardHeadListContaine {
  border-radius: 0 0 10px 10px;
}

.upsellCardHeadListContaine .slick-prev:before,
.upsellCardHeadListContaine .slick-next:before {
  font-size: 30px !important;
}

.upsellCardHeadListContaine .slick-next {
  right: 20px !important;
}

.go2072408551 {
  box-shadow: 3px 2px 23px -12px rgba(0, 0, 0, 0.75) !important;
}

.upsellCardHeadListContaine .slick-prev {
  left: 0px !important;
}

.upsellCardHeadListContaine .slick-track {
  margin: 1rem 0;
}

.upsellCardHeadListContaineOpt {
  width: 95% !important;
  text-align: center;
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 10px;
  border-radius: 10px;
  overflow: hidden;
  border: 2px solid #10417224;
  cursor: pointer;
}

.upsellCardHeadListContaineOptSeparation {
  padding: 10px 10px;
}

.upsellCardHeadListContaineOptSelect {
  background-color: transparent;
  height: 100%;
  font-size: 12px;
  font-weight: 700;
}

.upsellCardHeadListContaineOptSelect.active {
  background-color: #1041722b;
  padding: 10px;
  height: 100%;
}

.upsellCardHeadListContaineOptInfoFlight {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.upsellCardHeadListContaineOptPrice {
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;
}

.upsellCardHeadListContaineOptPrice span {
  font-size: 15px;
  font-weight: 600;
}

.upsellCardHeadListContaineOptPrice input {
  width: 20px;
  height: 20px;
}

.upsellCardHeadListContaineOptBags {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.upsellCardHeadListContaineOptBagsAll {
  display: flex;
  align-items: end;
  justify-content: center;
}

.upsellCardHeadListContaineOptBagsInfo {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-top: 10px;
  max-height: 100px; /* Start with max-height at 0 for the closed state */
  transition: max-height 0.5s ease; /* Animate only the max-height */
  overflow: hidden; /* Hide content that overflows */
}

.upsellCardHeadListContaineOptBagsInfo.active {
  max-height: 1000px; /* Set a large max-height for the expanded state */
}

.upsellCardHeadListContaineOptBagsInfoItem {
  display: flex;
  gap: 10px;
  align-items: center;
}

.upsellCardHeadListContaineOptBagsInfoItem p {
  font-weight: 500;
  font-size: 12px;
  text-align: start;
  text-transform: capitalize;
}

.upsellCardHeadListContaineOptTitle {
  background-color: #1041722b;
  padding: 20px 10px;
  color: #104172;
  border: 2px solid transparent;
  display: flex;
  flex-direction: column;
  gap: 4px;
  transition: all 0.3s ease;
}

.upsellCardHeadListContaineOptTitle.active {
  background-color: #104172;
  padding: 20px 10px;
  color: #fff;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.upsellCardHeadListContaineOptTitle p {
  font-size: 14px;
}

.upsellCardHeadListContaineOpt.selected {
  border: 2px solid #104172;
}

.upsellCardBodyColumn {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.upsellCardBodySection {
  width: 100%;
  display: flex;
}

.upsellCardBodySectionText {
  margin-bottom: 10px;
  color: #104172;
  font-weight: bolder;
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  text-align: center;
}

.upsellCardBodySectionContainer {
  display: flex;
  justify-content: space-around;
  width: 100%;
  gap: 12px;
  padding: 20px 0px;
}

.upsellCardBodySectionContainerBag {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.upsellCardBodySectionContainerBagContainer {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
}

.upsellCardBodySectionContainerBagContainerIcon {
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  align-content: center;
  display: flex;
}

.upsellCardBodySectionContainerBagText {
  font-size: 12px;
  text-align: center;
}

.upsellCardBodySectionContainerList {
  width: 100%;
  padding: 5px;
  margin-top: 15px;
  justify-content: space-between;
}

.upsellCardBodySectionContainerListGroup {
  float: left;
  width: 50%;
  display: flex;
  margin-bottom: 10px;
  align-content: center;
  align-items: center;
}

.upsellCardBodySectionContainerListGroupIcon {
  width: 15px;
  height: 15px;
  margin-right: 5px;
}

.upsellCardBodySectionContainerListGroupText {
  color: #000000;
  font-weight: 400;
  font-size: 12px;
  font-family: "Montserrat", sans-serif;
}

/* upsell card end*/

.showMoreDetail {
  padding: 10px;
}

.showMoreDetailText {
  cursor: pointer;
  font-weight: 500;
  font-size: 15px;
  font-family: "Montserrat", sans-serif;
  color: #ffffff;
  text-align: center;
  text-decoration: underline #ffffff;
  background-color: #104172;
  padding: 10px;
}

/* modal init*/
body.modal-open {
  overflow: hidden;
}

.upsellModal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 9999;
  align-items: center;
  justify-content: center;
  align-content: center;
}

.upsellModalCard2 {
  width: 50vw;
  height: 90vh;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  background-color: #ffffff;
  border-radius: 25px;
  /* padding: 10px 20px; */
}

.upsellModalCard {
  border-top: 1px solid #ededed;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.upsellModalClose {
  width: 25px;
  height: 25px;
  position: absolute;
  top: 0px;
  right: -30px;
  justify-content: center;
  align-items: center;
  align-content: center;
  cursor: pointer;
}

.modalSection {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 5px;
}

.modalSectionTitle {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.modalSectionTitleText {
  font-weight: bolder;
  font-size: 17px;
  font-family: "Montserrat", sans-serif;
  color: #104172;
  width: 100%;
}

.modalSectionSubTitle {
  width: 100%;
  font-weight: 500;
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
  color: #000000;
}

.modalSectionBody {
  overflow: hidden;
  width: 100%;
  padding: 5px 0px;
  justify-content: space-between;
}

.serviceGroup {
  float: left;
  width: 50%;
  display: flex;
  margin-bottom: 2px;
  align-content: center;
  align-items: center;
}

.serviceGroupIcon {
  width: 20px;
  height: 20px;
  margin-right: 5px;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.serviceGroopDescription {
  font-weight: 400;
  font-size: 12px;
  font-family: "Montserrat", sans-serif;
  color: #000000;
}

/* modal end*/

@media screen and (max-width: 600px) {
  .chooseUpsell {
    margin-top: 0px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }

  .chooseUpsell .iconWrap {
    display: none;
  }

  .chooseUpsell .chooseUpsellTextBig {
    font-size: 12px;
    font-weight: 400;
    padding-right: 10px;
  }

  .chooseUpsell .chooseUpsellTextSmall {
    display: none;
  }

  .chooseUpsell .btn.iconWrapSax {
    margin-top: -5px;
    width: 40px;
    height: 40px;
    padding: 0;
  }

  .chooseUpsell .column {
    margin-left: 0px;
  }

  .upsellFooterBody .row {
    flex-direction: column;
  }

  .upsellFooterBody .row .upsellCard {
    width: 100% !important;
  }

  .upsellFooterBody .row .upsellCard .upsellCardBody {
    margin-top: -10px;
    margin-bottom: 20px;
  }

  .upsellFooterBody .upsellFooter {
    flex-direction: column;
  }

  .upsellFooterBody .upsellFooter .upsellFooterPrice {
    width: 100%;
  }

  .upsellFooterBody .upsellFooter .upsellFooterPay {
    width: 60%;
    margin: 10px auto 0px auto;
    border-radius: 12px;
    height: 50px;
  }

  .upsellFooterBody .upsellFooter .upsellFooterPay .upsellFooterText {
    margin-top: -2px;
  }

  .upsellModal .upsellModalCard2 {
    width: 90vw;
    height: 95vh;
  }

  .upsellModal .upsellModalCard2 .upsellModalClose {
    background-color: var(--bluePrimary);
    width: 30px;
    height: 30px;
    padding: 2.5px;
    border-radius: 15px;
    top: -2px;
    right: -2px;
    z-index: 999;
  }

  .upsellModal .upsellModalCard {
    width: 85vw;
    height: 92.5vh;
  }

  .upsellModal .upsellModalCard .modalSection {
    padding: 10px 10px;
  }

  .upsellModal .upsellModalCard .modalSection .modalSectionBody .serviceGroup {
    width: 100%;
    margin-top: 2px;
  }
}

.btnConfigFlight {
  color: white;
  background-color: #104172;
  border-radius: 8px;
  padding: 10px 20px;
  transition: all 0.3s ease;
  cursor: pointer;
  font-size: 14px;
  font-weight: bolder;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btnConfigFlight:hover {
  background-color: #195fa6;
}

@media screen and (max-width: 600px) {
  .btnConfigFlight { 
    color: white;
    background-color: #104172; 
    padding: 10px 20px;
    transition: all 0.3s ease;
    cursor: pointer;
    margin-right: 1rem !important;
  }

  .legend-bags-tips {
    flex-direction: column;
    align-items: start;
  }
}
