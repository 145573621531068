.pqrsDoubleTitleContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 20px 0px;
  margin-top: 50px;
}

.pqrsDoubleTitleContainer .pqrsDoubleTitleContainerSimpleText {
  font-size: 30px;
  color: var(--bluePrimary);
  font-weight: 400;
  font-family: "Montserrat", sans-serif;
}

.pqrsDoubleTitleContainer .pqrsDoubleTitleContainerBigText {
  font-size: 24px;
  color: var(--bluePrimary);
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
  text-align: start;
}
