.container_campoSearch_ {
  width: 100%;
  padding: 50px 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  background-color: #004274;
}

.contain-header-buscador-hotel {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  gap: 15px;
  width: 100%;
}

.contain-header-buscador-hotel h3 {
  color: #004274;
  font-size: 15px;
  padding-bottom: 5px;
}

.contain-campos {
  background-color: #fff;
  width: 95%;
  max-width: 1280px;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 20px;
  border-radius: 20px;
}

.contain-campos-hotel {
  background-color: #fff;
  width: 95%;
  max-width: 1280px;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 20px;
  border-radius: 20px;
}

.layout-all {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.layout-responsive {
  width: 100%;
  display: flex;
  gap: 10px;
}

.layout-campo {
  width: 100%;
  height: 100%;
  padding: 0 7px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  border: 1px #d9d9d9 solid;
  cursor: pointer;
  transition: 0.3s ease-in-out;
  position: relative;
}

.layout-campo-nationaly {
  width: 100%;
  padding: 10px 7px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  border: 1px #d9d9d9 solid;
  cursor: pointer;
  transition: 0.3s ease-in-out;
  position: relative;
}

.destiny-hotel {
  width: 100%;
  display: flex;
  flex-direction: column;
  white-space: nowrap;
}

.contain_date {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
}

.destiny-hotel p {
  font-family: "Inter";
  font-weight: 500;
  font-size: 15px;
  color: var(--bluePrimary);
}

.contain-finish {
  width: 100%;
  display: flex;
  gap: 10px;
}

.contain_rooms {
  width: 100%;
  display: flex;
  align-items: center;
}

.contain_rooms_ {
  display: flex;
  align-items: center;
  gap: 10px;
}

.contain_rooms_ p {
  font-size: 14px;
  color: #004274;
}

.layout-campo-v {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  border: 1px #004274 solid;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}

.layout-campo-s {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: 1px #d9d9d9 solid;
  padding: 0 10px;
  cursor: pointer;
  height: 100%;
}

.contain-nacionality {
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: space-between;
}

.dropdown-nacionality {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  border-radius: 10px;
}

.dropdown-nacionality input {
  border: none;
  width: 100%;
}

.input-label-nationality {
  background-color: #fff;
  padding: 2px 10px;
  height: 100%;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.dropdown-nacionality ul {
  margin-top: 7px;
  max-height: 200px;
  list-style: none;
  font-weight: 700;
  overflow-y: auto;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  box-shadow: 10px 10px 29px -13px rgba(0, 0, 0, 0.141);
}

.dropdown-nacionality ul li {
  padding: 0.5rem;
  font-weight: 400;
  font-size: 14px;
  transition: all 0.3s ease;
  cursor: pointer;
}

.dropdown-nacionality ul li:hover {
  background-color: #00000014;
}

.error-hotel-search {
  width: 100%;
  color: #ff5151;
  display: flex;
  gap: 5px;
  align-items: center;
}

.error-hotel-search p {
  color: #ff5151;
  font-weight: 500;
  font-size: 14px;
}

@media screen and (max-width: 1100px) {
  .container_campoSearch {
    width: 50%;
  }
}

@media screen and (max-width: 800px) {
  .container_campoSearch {
    width: 80%;
    height: 420px;
  }

  .container_campoSearch_ {
    width: 100%;
  }

  .dropdown-nacionality ul li,
  .destiny-hotel p {
    font-size: 12px;
  }

  .contain_rooms_ p {
    font-size: 14px;
  }

  .layout-all {
    flex-direction: column;
  }

  .layout-responsive {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .layout-campo {
    padding: 10px;
  }

  .layout-campo-s {
    padding: 3px 10px;
  }
}




.scrolledHotels {
  box-shadow: 0px 30px 30px rgb(0 0 0 / 2%);
  position: sticky;
  top: 0;
  z-index: 9999;
  border-bottom: 1px solid #d9d9d9;
  transition: all 0.3s ease;
}

.searchMenuHotelScrolledMaxWidth {
  margin-bottom: 30px;
  margin-top: 10px;
} 


.searchMenuHotelScrolled {
  width: 100%;
  transition: all 0.3s ease;
  background-color: #fff;
}
.searchMenuHotelScrolled.active {
  width: 100%;
  box-shadow: 0px 30px 30px rgb(0 0 0 / 2%);
  position: sticky;
  top: 0;
  z-index: 9999;
  border-bottom: 1px solid #d9d9d9;
  padding: 15px 0px 15px 0px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.searchMenuHotelScrolled.hidden {
  width: 100%;
  box-shadow: 0px 30px 30px rgb(0 0 0 / 2%);
  position: sticky;
  top: -130px;
  z-index: 9999;
  border-bottom: 1px solid #d9d9d9;
  padding: 15px 0px 15px 0px;
}

.searchMesearchMenuHotelScrolled {
  width: 95%;
  max-width: 1280px;
  margin: 2rem auto;
  transition: all 0.3s ease;
}

.buttonArrowSearchScrolled {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  rotate: 180deg;
  transition: all 0.3s ease;
  cursor: pointer;
  color: #104172;
}

.buttonArrowSearchScrolled.active {
  rotate: 0deg;
}



.btn-search-hotel-horizontal {
  width: fit-content;
  min-width: fit-content;
  cursor: pointer;
  background-color: var(--bluePrimary);
  font-family: inherit;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 2px 15px;
  line-height: 2.5em;
  position: relative;
  overflow: hidden;
  border: 2px solid var(--bluePrimary);
  transition: color 0.5s;
  z-index: 1;
  font-size: 18px;
  border-radius: 9px;
  font-weight: 600;
  color: white;
}

.btn-search-hotel-horizontal:before {
  content: "";
  position: absolute;
  z-index: -1;
  background: white;
  color: var(--bluePrimary);
  height: 550px;
  width: 1500px;
  border-radius: 50%;
}

.btn-search-hotel-horizontal:hover {
  color: var(--bluePrimary);
}

.btn-search-hotel-horizontal:before {
  top: 100%;
  left: 100%;
  transition: all 0.7s;
}

.btn-search-hotel-horizontal:hover:before {
  top: -100px;
  left: -200px;
}

.btn-search-hotel-horizontal:active:before {
  background: white;
  transition: background 0s;
}


@media screen and (max-width: 550px) {
  .contain-finish {
    flex-direction: column;
  }

  .btn-search-hotel-horizontal {
    width: 100%;
    justify-content: center;
    padding: 6px 12px;
  }
}




@media screen and (max-width: 900px) {
  .searchMenuHotelScrolled.hidden {
    top: -140px;
  }
}

@media screen and (max-width: 881px) {
  .searchMenuHotelScrolled.hidden {
    top: -250px;
  }
}


@media screen and (max-width: 550px) {
  .searchMenuHotelScrolled.hidden {
    top: -395px;
  }
}