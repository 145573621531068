.benefit-value-container{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display:flex;
    justify-content: flex-start; /* Modificado */
    align-items: center;
    flex-direction: column;
    z-index: 9999;
    overflow: auto;
    padding: 20px;
}

.view-value-benefit{
    background-color: white;
    padding: 50px;
    width: 90%;
    height: auto;
    margin: 20px 0;
}

.benefit-value{
    display: flex;
    justify-content: space-between;
}

#benefit-container-benefit{
    text-align: left;
    width: 70%;
    border-right: 1px solid #0072B9;
}
#benefit-container-value{
    width: 30%;
    border-left: 1px solid #0072B9;
    padding-left: 50px;
}

#plan-benefit p, #plan-value p{
    margin-block: 5px;
    font-size: 12px !important;
    font-family: 'Montserrat' !important;
}

.title-benefit-value p {
    font-size: 24px;
    font-weight: 700;
    color: #0072B9;
    font-family: 'Montserrat' !important;
    margin-bottom: 20px;
}

#plan-value {
    text-align: left;
    width: 30%;
    border-left: 1px solid #0072B9;
    padding-left: 50px;
    display: flex;
    align-items: center;
}

.btn-close-benefit{
    display: flex;
    justify-content: center;
    margin-top: 100px;
}

.btn-close-benefit button{
    background-color: #0072B9;
    color: white;
    font-family: "Montserrat" !important;
    height: 32px;
    border-radius: 8px;
    width: 20%;
    font-size: 12px;
    font-weight: 900;
    cursor: pointer;
}

#plan-benefit {
    width: 70%;
    border-right: 1px solid #0072B9;
    padding-right: 20px;
}

@media screen and (max-width: 900px) {
    #plan-value{
        width: 50%;
    }
    #plan-benefit{
        width: 50%;
    }

    #benefit-container-benefit{
        width: 50%;
    }
    #benefit-container-value{
        width: 50%;
    }

    .btn-close-benefit button{
        width: 70%;
    }
    
}

@media screen and (max-width: 470px) {
    #plan-value{
        width: 100%;
        border-left: none;
        padding-left: 0px;
        font-weight: 700;
    }

    #plan-benefit{
        width: 100%;
        border-right: none;
    }

    #benefit-container-benefit{
        width: 100%;
        border-right: none;
    }

    #benefit-container-value{
        width: 100%;
        border-left: none;
        padding-left: 0px;
    }
    
    .benefit-value{
        display: block;
    }
}