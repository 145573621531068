.cartItemsItem {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.cartItemsItem h3 {
  font-size: 18px;
  font-weight: 600;
  color: var(--bluePrimary);
  padding-bottom: 8px;
  border-bottom: 2px solid var(--bluePrimary);
}

.cartItemsItemOne {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.itemFlight {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 13px;
  border: 1px solid var(--blueWhiteTableThead);
  border-radius: 10px;
  padding: 15px;
}

.itemHeader {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.itemHeaderIconText {
  display: flex;
  align-items: center;
  gap: 10px;
}

.icon {
  font-size: 18px;
  background-color: var(--bluePrimary);
  color: var(--white);
  min-width: 30px;
  min-height: 30px;
  border-radius: 6px;
  display: grid;
  place-items: center;
}

.itemHeaderText {
  display: flex;
  flex-direction: column;
  gap: 2px;
  color: var(--bluePrimary);
  font-size: 14px;
  font-weight: 500;
}

.itemHeaderText h4 {
  font-size: 18px;
}

.itemDetailsFlights {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
}

.itemDetailsFlights1 {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: 20px;
  height: 100%;
  border-right: 2px solid var(--bluePrimary);
  padding-right: 30px;
}

.itemDetailsFlightsColumn1 {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  gap: 10px;
  width: 100%;
}

.typeFlight {
  display: flex;
  flex-direction: column;
  color: var(--bluePrimary);
  font-weight: 700;
}

.typeFlight strong {
  font-weight: 500;
}

.itemDetailsFlightsColumn2 {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  height: 100%;
}

.airlineLogo {
  width: 70px;
  height: 20px;
}

.airlineLogo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.itemDetailsFlightsColumn1Footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
}

.itemDetailsFlightsColumn1FooterPlace b {
  color: var(--bluePrimary);
}

.itemDetailsFlights2 {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: 20px;
  height: 100%;
  padding-left: 30px;
}

.itemDetailsFlights2Any {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  height: 100%;
  padding-left: 30px;
  color: var(--bluePrimary);
}

.totalPrice {
  background-color: var(--bluePrimary);
  padding: 10px;
  color: var(--white);
  display: flex;
  justify-content: flex-start;
  border-radius: 8px;
}

.totalPriceText {
  display: flex;
  flex-direction: column;
  gap: 3px;
  font-size: 14px;
}

.totalPriceText span {
  font-weight: 700;
  font-size: 18px;
}

.luggageIcon {
  display: flex;
  gap: 10px;
  color: var(--bluePrimary);
  font-size: 18px;
  align-items: center;
  font-weight: 500;
}

@media screen and (max-width: 550px) {
  .itemDetailsFlights {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 10px;
    gap: 20px;
  }

  .itemDetailsFlights2 {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    gap: 20px;
    height: 100%;
    padding-left: 0px;
  }

  .itemDetailsFlights1 {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    gap: 20px;
    height: 100%;
    border: none;
    padding-right: 0px;
  }
}