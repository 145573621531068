.modalContainerLogin {
  background-color: var(--transparentModal);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  display: grid;
  place-items: center;
  opacity: 1;
  pointer-events: all;
  transition: all 0.3s ease;
  padding: 20px;
}

.modalContainerLogin.close {
  background-color: var(--transparentModal);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  display: grid;
  place-items: center;
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s ease;
  padding: 20px;
}

.modalContent {
  background-color: var(--white);
  color: var(--bluePrimary);
  border-radius: var(--roundedPrimary);
  position: relative;
  display: grid;
  place-items: center;
  padding: 20px;
  box-shadow: var(--shawdowPrimary);
  width: 100%;
  min-width: 200px;
  max-width: 490px;
  opacity: 1;
  transform: translateY(0px);
  pointer-events: all;
  transition: all 0.3s ease;
}

.modalContent.close {
  background-color: var(--white);
  color: var(--bluePrimary);
  border-radius: var(--roundedPrimary);
  position: relative;
  display: grid;
  place-items: center;
  padding: 20px;
  box-shadow: var(--shawdowPrimary);
  width: 100%;
  min-width: 400px;
  max-width: 490px;
  opacity: 0;
  transform: translateY(-30px);
  pointer-events: none;
  transition: all 0.3s ease;
}

.modalButtonClose {
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
}

.userRegisterInputContainer {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  gap: 5px;
}

.flexRowContainerItem {
  width: 100%;
}

.flexRowContainer {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  width: 100%;
}

.flexRowContainItemInput {
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.modalFormLogin {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.modalFormLogin h3 {
  font-size: 24px;
  font-weight: 700;
  color: var(--bluePrimary);
}

.formLabelInputs {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.labelInput {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 3px;
}

.label {
  font-size: 12px;
  font-weight: 700;
  color: var(--blackGray);
}

.input {
  height: 40px;
  border: 1px solid var(--greyPrimary);
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: space-between;
  padding-right: 10px;
}

.input.error {
  border: 1px solid var(--redPrimary);
}

.input input {
  border: none;
  width: 100%;
  height: 100%;
  padding: 8px;
}

.eyePassword {
  cursor: pointer;
}

.messageInput {
  font-size: 12px;
  font-weight: 400;
  color: var(--redPrimary);
}

.buttonsActions {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.passwordLinks {
  display: flex;
  justify-content: space-between;
  gap: 25px;
  align-items: center;
  font-size: 14px;
  color: var(--bluePrimary);
}

.rememberPassword {
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 5px;
}

.rememberPassword label {
  cursor: pointer;
}

.linkRecovery {
  font-size: 12px;
  position: relative;
  display: inline-block;
  color: var(--bluePrimary);
}

.linkRecovery::after {
  content: "";
  position: absolute;
  width: 0;
  height: 1px; /* altura del underline */
  background-color: var(--bluePrimary); /* color del underline */
  bottom: 0;
  left: 0;
  transition: width 0.5s ease-in-out; /* tiempo de transición */
}

.linkRecovery:hover::after {
  width: 100%;
}

.formBtnSend {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
}

.formBtnSend.disabled {
  background-color: var(--bluePrimary);
  opacity: 0.4;
  color: var(--white);
  pointer-events: none;
  font-weight: 500;
}
.formBtnSend.formBtnSendAvaliable {
  background-color: var(--bluePrimary);
  color: var(--white);
  pointer-events: all;
  cursor: pointer;
  font-weight: 500;
  transition: all 0.3s ease;
}

.formBtnSend.formBtnSendAvaliable:hover {
  background-color: var(--blueTertiary);
  color: var(--white);
  pointer-events: all;
  cursor: pointer;
  font-weight: 500;
}

@keyframes spinLoginModal {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.spinLoginModal {
  color: var(--white);
  animation: spinLoginModal 1s linear infinite;
}

.separationOr {
  display: flex;
  align-items: center;
  gap: 10px;
}

.register {
  border: 1px solid var(--bluePrimary);
  padding: 10px;
  color: var(--bluePrimary);
  border-radius: 5px;
  font-weight: 500;
  cursor: pointer;
}

.modalContainerLogin {
  background-color: var(--transparentModal);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  display: grid;
  place-items: center;
  opacity: 1;
  pointer-events: all;
  transition: all 0.3s ease;
  padding: 20px;
}

.modalContainerLogin.close {
  background-color: var(--transparentModal);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  display: grid;
  place-items: center;
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s ease;
  padding: 20px;
}

.modalContent {
  background-color: var(--white);
  color: var(--bluePrimary);
  border-radius: var(--roundedPrimary);
  position: relative;
  display: grid;
  place-items: center;
  padding: 20px;
  box-shadow: var(--shawdowPrimary);
  width: 100%;
  min-width: 200px;
  max-width: 490px;
  opacity: 1;
  transform: translateY(0px);
  pointer-events: all;
  transition: all 0.3s ease;
}

.modalContent.close {
  background-color: var(--white);
  color: var(--bluePrimary);
  border-radius: var(--roundedPrimary);
  position: relative;
  display: grid;
  place-items: center;
  padding: 20px;
  box-shadow: var(--shawdowPrimary);
  width: 100%;
  min-width: 400px;
  max-width: 490px;
  opacity: 0;
  transform: translateY(-30px);
  pointer-events: none;
  transition: all 0.3s ease;
}

.modalButtonClose {
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
}

.userRegisterInputContainer {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  gap: 5px;
}

.flexRowContainerItem {
  width: 100%;
}

.flexRowContainer {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  width: 100%;
}

.flexRowContainItemInput {
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.modalFormLogin {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.modalFormLogin h3 {
  font-size: 24px;
  font-weight: 700;
  color: var(--bluePrimary);
}

.formLabelInputs {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.labelInput {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 3px;
}

.label {
  font-size: 12px;
  font-weight: 700;
  color: var(--blackGray);
}

.input {
  height: 40px;
  border: 1px solid var(--greyPrimary);
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: space-between;
  padding-right: 10px;
}

.input.error {
  border: 1px solid var(--redPrimary);
}

.input input {
  border: none;
  width: 100%;
  height: 100%;
  padding: 8px;
}

.eyePassword {
  cursor: pointer;
}

.messageInput {
  font-size: 12px;
  font-weight: 400;
  color: var(--redPrimary);
}

.buttonsActions {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.passwordLinks {
  display: flex;
  justify-content: space-between;
  gap: 25px;
  align-items: center;
  font-size: 14px;
  color: var(--bluePrimary);
}

.rememberPassword {
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 5px;
}

.rememberPassword label {
  cursor: pointer;
}

.linkRecovery {
  font-size: 12px;
  position: relative;
  display: inline-block;
  color: var(--bluePrimary);
}

.linkRecovery::after {
  content: "";
  position: absolute;
  width: 0;
  height: 1px; /* altura del underline */
  background-color: var(--bluePrimary); /* color del underline */
  bottom: 0;
  left: 0;
  transition: width 0.5s ease-in-out; /* tiempo de transición */
}

.linkRecovery:hover::after {
  width: 100%;
}

.formBtnSend {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
}

.formBtnSend.disabled {
  background-color: var(--bluePrimary);
  opacity: 0.4;
  color: var(--white);
  pointer-events: none;
  font-weight: 500;
}
.formBtnSend.formBtnSendAvaliable {
  background-color: var(--bluePrimary);
  color: var(--white);
  pointer-events: all;
  cursor: pointer;
  font-weight: 500;
  transition: all 0.3s ease;
}

.formBtnSend.formBtnSendAvaliable:hover {
  background-color: var(--blueTertiary);
  color: var(--white);
  pointer-events: all;
  cursor: pointer;
  font-weight: 500;
}

@keyframes spinLoginModal {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.spinLoginModal {
  color: var(--white);
  animation: spinLoginModal 1s linear infinite;
}

.separationOr {
  display: flex;
  align-items: center;
  gap: 10px;
}

.register {
  border: 1px solid var(--bluePrimary);
  padding: 10px;
  color: var(--bluePrimary);
  border-radius: 5px;
  font-weight: 500;
  cursor: pointer;
}

.userRegister {
  display: flex;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
  background-color: var(--greyPrimary);
}

.userRegister .userRegisterContainer {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
  position: relative;
  overflow: hidden;
  -webkit-box-shadow: var(--shawdowPrimary);
  -moz-box-shadow: var(--shawdowPrimary);
  box-shadow: var(--shawdowPrimary);
}

.flexRowContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2px;
  width: 100%;
}
.dobleInputContainer{ 
  display: flex;
  gap: 10px;
  width: 100%;
  justify-content: space-between;
}

.flexRowContainerItem {
  width: 100%;
  display: flex;
  gap: 10px;
  justify-content: space-between;
}

.flexRowContainItemInput {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
}

/* ----------- section right init ----------- */
.userRegister .sectionRight {
  transition: all ease 200ms;
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: var(--bluePrimary);
  position: relative;
}

.userRegister .sectionRightTextContainerHeader {
  padding: 20px 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  cursor: pointer;
  position: relative;
  z-index: 2;
}

.userRegister .sectionRightTextContainerHeader a {
  text-decoration: none;
}

.userRegister .goToFromScreen {
  padding: 5px;
  cursor: pointer;
  transition: all ease 200ms;
  border-color: var(--white);
}

.userRegister .goToFromScreen:hover {
  border-bottom: var(--white) 1px solid;
}

.userRegister .goToFromScreenText {
  color: var(--white);
  font-size: 14px;
  font-weight: 400;
  font-family: "Montserrat", sans-serif;
}

.userRegister .sectionRightTextContainer {
  padding: 20px 25px;
  height: 100%;
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 2;
  justify-content: flex-end;
}

.userRegister .sectionRightTextContainerText {
  color: var(--white);
  font-size: 24px;
  font-weight: bolder;
  text-align: center;
}

.userRegister .sectionRight .background {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.2;
  z-index: 1;
}

/* ----------- section right end ----------- */
/* ----------- section left init ----------- */

.userRegister .sectionLeft {
  transition: all ease 200ms;
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--white);
  padding: 20px;
  position: relative;
}

.links-web {
  display: flex;
  gap: 10px;
  font-size: 14px;
  font-weight: 400;
  color: var(--greyPrimary);
}

.links-web-mobile {
  display: none;
  gap: 10px;
  font-size: 14px;
  font-weight: 400;
  color: var(--greyPrimary);
}

.item-between {
  display: none;
}

.sectionLeftRegister {
  width: 80%;
}

.userRegister .userRegisterFormContainer.paddingLogin {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
}

.userRegister .userRegisterFormContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
}

.userRegister .userRegisterFormContainerHead {
  display: flex;
  flex-direction: column;
}

.userRegister .userRegisterFormTitle {
  color: var(--bluePrimary);
  font-size: 20px;
  font-weight: bolder;
  font-family: "Montserrat", sans-serif;
  margin-bottom: 5px;
}

.userRegister .userRegisterFormSubTitle {
  color: var(--black);
  font-size: 12px;
  font-weight: 400;
  font-family: "Montserrat", sans-serif;
  text-align: center;
}

.userRegister .userRegisterFormContainerBody {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-bottom: 5px;
}

.userRegister .userRegisterInputContainer {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  gap: 5px;
}

.userRegister .userRegisterInputErrorLabel {
  color: var(--redPrimary);
  font-size: 12px;
  font-weight: 400;
  font-family: "Montserrat", sans-serif;
  margin: 0px 0px 5px 5px;
}

.userRegister .userRegisterInputLabel {
  color: var(--black);
  font-size: 12px;
  font-weight: 600;
}

.userRegister .userRegisterInputLabel b {
  margin-left: 5px;
  color: var(--redPrimary);
}

.userRegister .userRegisterInput {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 40px;
  border: 1px solid var(--greyPrimary);
  border-radius: 8px;
  overflow: hidden;
  padding: 0 0 0 10px;
}

.userRegister .userRegisterInputError {
  border: solid var(--redPrimary) 1px;
}

.userRegisterInput select {
  outline: none;
}

.userRegister .userRegisterInputIcon {
  width: 10%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.userRegister .userRegisterInputInput {
  width: 90%;
  height: 100%;
  border: none;
  background-color: transparent !important;
}

.userRegister .userRegisterInputShowPassword {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0px;
  width: 40px;
  cursor: pointer;
}

.userRegister .containerPassword {
  border-radius: 12px;
  padding: 10px;
  background: var(--greyPrimary);
}

.userRegister .containerPasswordRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 5px;
}

.userRegister .containerPasswordRowIcon {
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
}

.userRegister .containerPasswordRowText {
  font-size: 10px;
  font-weight: bold;
  font-family: "Montserrat", sans-serif;
}

.userRegister .userRegisterFormContainerFoot {
  margin-top: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.userRegister .formBtnSend {
  transition: all ease 200ms;
  border-radius: 8px;
  padding: 10px;
  margin: auto;
  align-items: center;
  display: flex;
  justify-content: center;
}

.userRegister .formBtnSend.small {
  width: 30%;
}

.userRegister .formBtnSend.medium {
  width: 65%;
}

.userRegister .formBtnSend.big {
  width: 100%;
}

.userRegister .formBtnSendDisable {
  background-color: var(--greyPrimary);
  cursor: not-allowed;
}

.userRegister .formBtnSendAvaliable {
  background-color: var(--bluePrimary);
  cursor: pointer;
}

.userRegister .formBtnSendText {
  color: var(--white);
  font-size: 14px;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
  text-align: center;
}

.userRegister .formRadio {
  margin-top: 10px;
}

.userRegister .formRadioBody {
  margin-top: 5px;
}

.userRegister .formAvatar {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: space-between;
  margin-top: 20px;
}

.userRegister .formAvatarContainer {
  position: relative;
}

.userRegister .formAvatarContainerWrap {
  width: 80px;
  height: 80px;
  overflow: hidden;
  border-radius: 40px;
  cursor: pointer;
  transition: all ease 200ms;
}

.userRegister .formAvatarContainerWrap:hover {
  -webkit-box-shadow: var(--shawdowPrimary);
  -moz-box-shadow: var(--shawdowPrimary);
  box-shadow: var(--shawdowPrimary);
  transform: translateY(-4px);
}

.userRegister .formAvatarContainerWrap.formAvatarSelected {
  border: solid var(--greenPrimary) 2px;
}

.userRegister .formAvatarContainerIcon {
  width: 30px;
  height: 30px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--greenPrimary);
  position: absolute;
  right: 0px;
  bottom: 0px;
}

.userRegister .formAvatarImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
/* ----------- section left end ----------- */
/* ----------- modal init ----------- */
.userRegister .userRegisterModal {
  background-color: var(--transparentModal);
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 99;
  margin: -20px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.userRegister .userRegisterModalShow {
  transition: all ease 500ms;
  transform: translateY(0%);
}

.userRegister .userRegisterModalHidden {
  transition: all ease 200ms;
  transform: translateY(100%);
}

.userRegister .userRegisterModalContainer {
  background-color: var(--white);
  width: 90%;
  height: 90%;
  border-radius: 12px;
  overflow: hidden;
  overflow-y: scroll;
  -webkit-box-shadow: var(--shawdowPrimary);
  -moz-box-shadow: var(--shawdowPrimary);
  box-shadow: var(--shawdowPrimary);
}

.userRegister .userRegisterModalBtn {
  width: 35px;
  height: 35px;
  background-color: var(--white);
  position: absolute;
  top: 5px;
  right: 5px;
  border-radius: 16px;
  align-items: center;
  justify-content: center;
  display: flex;
  -webkit-box-shadow: var(--shawdowPrimary);
  -moz-box-shadow: var(--shawdowPrimary);
  box-shadow: var(--shawdowPrimary);
  cursor: pointer;
  transition: all ease 200ms;
}

.userRegister .userRegisterModalBtn:hover {
  transform: translateY(-2px);
  scale: 1.1;
}
/* ----------- modal end ----------- */
/* ----------- phones init ----------- */
.userRegister .formPhone {
  display: flex;
  flex-direction: column;
}

.userRegister .formAddrees {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.userRegister .formPhoneLabelBtn {
  width: 100%;
  display: flex;
  margin-bottom: -8px;
}

.userRegister .formPhoneLabel {
  color: var(--bluePrimary);
  font-size: 12px;
  font-weight: 400;
  font-family: "Montserrat", sans-serif;
  margin-left: 5px;
  margin-bottom: 5px;
  border-bottom: solid var(bluePrimary) 1px;
  padding-bottom: 5px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.userRegister .formPhoneLabelBtn .formPhoneLabel {
  cursor: pointer;
}

.userRegister .formPhoneForm {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.userRegister .formPhoneForm input {
  border: none;
  background: var(--greyPrimary);
  border-radius: 8px;
  padding: 5px;
}

.userRegister .formPhoneFormInputOne {
  width: 25%;
}

.userRegister .formPhoneFormInputTwo {
  width: 54%;
}

.userRegister .formPhoneFormBtn {
  width: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.userRegister .formPhoneList {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
}

.userRegister .formPhoneListGroup {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border: var(--greyPrimary) solid 1px;
  align-items: center;
  justify-content: center;
  padding: 5px 0px 5px 10px;
  margin-bottom: 2px;
  border-radius: 8px;
}

.userRegister .formPhoneListGroupInfo {
  width: 80%;
  display: flex;
  flex-direction: row;
}

.userRegister .formAddresListGroupInfo {
  width: 80%;
  display: flex;
  flex-direction: column;
}

.userRegister .formPhoneListGroupActions {
  border-left: solid var(--greySecundary) 1px;
  width: 20%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.userRegister .formPhoneListGroupActionsBtn {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.userRegister .formAddresListGroupInfo p {
  color: var(--white);
  font-size: 14px;
  font-weight: 400;
  font-family: "Montserrat", sans-serif;
}




.remember-link-recovery {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  width: 100%;
  font-size: 14px;
  padding: 5px 0px;
}

.remember-password {
  display: flex;
  align-items: center;
  gap: 5px; /* Espacio entre el checkbox y el label */
  font-family: Arial, sans-serif;
  font-size: 14px;
  color: var(--blackGray);
}

.remember-password input[type="checkbox"] {
  width: 14px;
  height: 14px;
  cursor: pointer;
}

.remember-password label {
  cursor: pointer;
}

.separation-or {
  display: flex;
  justify-content: space-between;
  gap: 8px;
  align-items: center;
  padding: 10px 0;
}

.separation-line {
  width: 100%;
  height: 1px;
  background-color: var(--greyPrimary);
}

.button-register {
  background-color: var(--white);
  border: 1px solid var(--bluePrimary);
  color: var(--bluePrimary);
  padding: 10px;
  text-align: center;
  border-radius: 8px;
  font-weight: 600;
  font-size: 14px;
}

.passwordRequirementsContentMessage {
  position: relative;
}
.passwordRequirements.open {
  position: absolute;
  top: -150px;
  z-index: 999;
  left: -380px;
  padding: 10px;
  background-color: var(--white);
  border: 1px solid var(--greyPrimary);
  border-radius: 5px;
  box-shadow: var(--shawdowPrimary);
  font-size: 14px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  opacity: 1;
  transform: translateX(0px);
  pointer-events: all;
  transition: all 0.3s ease;
}

.passwordRequirements.close {
  position: absolute;
  top: -130px;
  z-index: 999;
  left: -330px;
  padding: 10px;
  background-color: var(--white);
  border: 1px solid var(--greyPrimary);
  border-radius: 5px;
  box-shadow: var(--shawdowPrimary);
  font-size: 14px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  opacity: 0;
  transform: translateX(10px);
  pointer-events: none;
  transition: all 0.3s ease;
}

.passwordRequirements h4 {
  font-size: 14px;
  font-weight: 600;
  color: var(--bluePrimary);
}

.passwordRequirements ul li {
  display: flex;
  gap: 3px;
  align-items: center;
  font-size: 12px;
}

.arrowRight {
  position: absolute;
  top: 14px;
  right: -8px;
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-left: 8px solid var(--greyPrimary);
  z-index: 999;
}

.arrowRight::after {
  content: "";
  position: absolute;
  top: -7px;
  right: 1px;
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-left: 8px solid var(--white);
}



/* ----------- phones end ----------- */
@media screen and (max-width: 1100px) {
  .userRegister .sectionLeft {
    width: 50%;
  }
  .userRegister .sectionRight {
    width: 50%;
  }
}

@media screen and (max-width: 860px) {
  .userRegister .sectionLeft {
    width: 100%;
    position: absolute;
    z-index: 999;
    background-color: transparent !important;
  }

  .userRegister .userRegisterInput {
    background-color: var(--white);
  }

  .links-web {
    display: none !important;
    gap: 10px;
    font-size: 14px;
    font-weight: 400;
    color: var(--greyPrimary);
  }
  
  .links-web-mobile {
    margin-top: 20px;
    display: flex;
    gap: 10px;
    font-size: 14px;
    font-weight: 400;
    color: var(--greyPrimary);
  }

  .item-between {
    display: flex;
  }

  .arrowRight {
    position: absolute;
    top: 142px !important;
    left: 10px !important;
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid var(--greyPrimary);
    z-index: 999;
  }
  
  .arrowRight::after {
    content: "";
    position: absolute;
    top: -9px !important;
    left: -8px !important;
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid var(--white);
  }
  

  .userRegister .userRegisterFormContainer {
    padding: 20px;
    border-radius: 20px;
  }

  .passwordRequirements.open {
    top: -330px !important;
    left: 0px !important;
  }

  .userRegister .sectionRight {
    width: 100%;
  }

  .userRegister .sectionRightTextContainerHeader {
    display: none;
  }

  .userRegister .sectionRightTextContainer {
    display: none;
  }

  .userRegister .formRadioBody div div {
    background-color: var(--white) !important;
    padding: 2px 10px;
    border-radius: 5px;
  }

  .userRegister .formPhoneListGroup {
    background: var(--white);
    border: none;
  }

  .userRegister .formPhoneForm input {
    background: var(--white);
  }

  .userRegister .formPhoneFormBtn {
    background: var(--white);
    border-radius: 8px;
  }
}


@media screen and (max-width: 450px) {
  .passwordRequirements.open {
    top: -315px !important;
    left: 0px !important;
    text-wrap: nowrap;
    width: 100%;
    overflow-x: scroll;
  }
}

.sectionLeftRegister {
  width: 100%;
  max-width: 450px;
  padding-bottom: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.react-tel-input .form-control {
  width: 100% !important;
}