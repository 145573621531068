/* ----------------- NUEVO DISEÑO DE PAQUETES ----------------- */

.content-packages-new {
  max-width: 1280px;
  width: 90%;
  margin: 2rem auto;
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.content-packages-center {
  display: flex;
  gap: 1rem;
  max-width: 1280px;
}

.content-packages-new aside {
  width: 100%;
  position: relative;
  min-width: 308px;
  border-radius: 16px;
  height: 100%;
}

.content-packages-new aside .img-pack-aside {
  width: 100%;
  height: 100%;
  border-radius: 20px;
  object-fit: cover;
}

.content-packages-new aside .info {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 1.8rem;
}

.content-packages-new aside .info .letter {
  padding: 1rem 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.content-packages-new aside .info .letter h2 {
  color: white;
  font-size: 36px;
  margin: 0;
  font-weight: 900;
  text-transform: uppercase;
}

.content-packages-new aside .info .letter h2 span {
  font-size: 29px;
  display: block;
  margin: 0;
}

.content-packages-new aside .info .letter p {
  color: white;
  font-size: 12px;
  margin-top: 1rem;
}

.content-packages-new article {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1.4rem;
  height: 100%;
}

.content-packages-new article .pack-img {
  width: 100%;
  height: 550px;
  object-fit: cover;
  border-radius: 20px;
}

.content-packages-new article .content-btn-pack {
  display: flex;
  align-items: center;

  justify-content: space-between;
  gap: 1rem;
}

.content-packages-new article .content-btn-pack button {
  height: 60px;
  width: 100%;
  border-radius: 12px;
  border: none;
  position: relative;
  background-color: #004274;
  /* background-image: url("../../../assets/images/mapBtn.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover; */
  cursor: pointer;
  transition: .5s ease;
}


.content-packages-new article .content-btn-pack button div {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  padding: 0 1rem;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  background-color: #104172CC;

}

.content-packages-new article .content-btn-pack button div {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  padding: 0 1rem;
  align-items: center;
  border-radius: 12px;
  background-color: #104172cc;
}

.content-packages-new article .content-btn-pack button div span {
  color: white;
  font-weight: 900;
  font-size: 24px; 
  text-align: center;
}


.content-packages-colum-1 {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  max-height: 780px;
}

.content-packages-colum-2 {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2.2rem;
}

.content-packages-new-change {
  color: #004274;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.content-packages-header {
  display: flex;
  flex-direction: column;
  gap: .5rem;
}

.content-packages-header h1 {
  font-weight: 900;
  font-size: 36px;
}

.content-packages-header p {
  font-size: 12px;
}

.content-packages-new-button-div {
  background-color: #104172CC;
  border-radius: 12px;
}

.content-packages-new-button {
  color: #fff;
  height: 60px;
  width: 100%;
  border-radius: 12px;
  border: none;
  position: relative;
  background-image: url("../../../assets/images/mapBtn.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
  transition: .5s ease;
}

.content-packages-new-button .content-packages-new-button button div {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  padding: 0 1rem;
  align-items: center;
  border-radius: 12px;
  background-color: #104172CC;
}
.content-packages-new article .search-packages-turisticos .ctn-inputs {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1.5rem;
  gap: 0.5rem;
}
.search-packages-turisticos{ 
  margin-bottom: .3rem !important;
}
.content-packages-new article .search-packages-turisticos {
  display: flex;
  flex-direction: column;
  padding: .7rem 1.5rem;
  box-shadow: 0px 4px 4px 0px #00000040;
  border-radius: 26px;
  width: 100%;
}

.content-packages-new article .search-packages-turisticos h2 {
  font-size: 36px;
  text-transform: uppercase;
  font-weight: 900;
  color: #004274;
}

.content-packages-new article .search-packages-turisticos p {
  font-size: 12px;
  margin-bottom: 11px;
}  
 

.content-packs-new-2 {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  
}

.content-packages-new article .search-packages-turisticos .ctn-inputs button {
  height: 46px;
  padding: 8px;
  width: 13%;
  min-width: 104px;
  color: white;
  border-radius: 8px;
  font-size: 12px;
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 2px;
  justify-content: center;
  font-weight: 700;
  background-color: #004274;
}

@media screen and (max-width:1000px) {
  .content-packages-new article .search-packages-turisticos .ctn-inputs {
    flex-wrap: wrap;
  }
  .content-packages-colum-1 {
    max-height: none;
  }

  .content-packages-new article .search-packages-turisticos .ctn-inputs input {
    width: 100%;
  }

  .content-packages-new article .search-packages-turisticos .ctn-inputs .content-select {
    width: 60%;
  }

  .content-packages-new article .search-packages-turisticos .ctn-inputs button {
    width: 38%;
  }
}
.content-packs-new-2 {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
}

.content-packs-new {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  margin: 2rem 0;
}

.content-packs-new .card-packages-new {
  background-color: white;
  border-radius: 20px;
  box-shadow: 0px 4px 4px 0px #00000040;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1rem;
  width: 300px;
  min-width: 200px;
  overflow: hidden;
}

.content-packs-new-2 .card-packages-new {
  background-color: white;
  border-radius: 20px;
  width: fit-content;
  box-shadow: 0px 4px 4px 0px #00000040;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 32%;
  height: 430px;
}

.content-packs-new .card-packages-new img {
  width: 100%;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  min-width: 150px;
  object-fit: cover;
  height: 300px;
}

.content-packs-new-2 .card-packages-new img {
  width: 100%;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  object-fit: cover;
  min-height: 200px;
  height: 300px;
}

.content-packs-new .card-packages-new .description {
  padding: 0 .4rem;
  display: flex;
  flex-direction: column;
}

.content-packs-new-2 .card-packages-new .description {
  padding: 0 .4rem;
  display: flex;
  flex-direction: column;
}

.content-packs-new .card-packages-new .description h2 {
  color: #004274;
  font-size: 16px;
  font-weight: 700;
  text-wrap: nowrap;
  overflow: auto;
  text-transform: uppercase;
}

.content-packs-new-2 .card-packages-new .description h2 {
  color: #004274;
  font-size: 16px;
  font-weight: 700;
  text-wrap: nowrap;
  text-transform: uppercase;
  overflow: auto;
}

.content-packs-new .card-packages-new .description span {
  color: black;
  margin-top: .6rem;
  font-weight: 700;
  font-size: 12px;
}

.content-packs-new-2 .card-packages-new .description span {
  color: black;
  margin-top: .6rem;
  font-weight: 700;
  font-size: 12px;
}

.content-packs-new .card-packages-new .description p {
  font-size: 12px;
}

.content-packs-new-2 .card-packages-new .description p {
  font-size: 12px;
}

.content-packs-new .card-packages-new .price {
  margin-bottom: 1rem;
  padding: 0 1rem 0 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.content-packs-new-2 .card-packages-new .price {
  margin-bottom: 1rem;
  padding: 0 1rem 0 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.content-packs-new .card-packages-new .price .back-price {
  background-color: #104172;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  color: white;
  font-size: 13px;
  padding: 13px;
  font-weight: 700;
}

.content-packs-new-2 .card-packages-new .price .back-price {
  background-color: #104172;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  color: white;
  padding: 13px;
  font-size: 13px;
  font-weight: bold;
}

.content-packs-new .card-packages-new .price .see-more {
  color: #104172;
  font-size: 12px;
  font-weight: 700;
  cursor: pointer;
}

.content-packs-new .card-packages-new .price .see-more:hover,.content-packs-new-2 .card-packages-new .price .see-more:hover {
  text-decoration: underline;
}

.content-packs-new-2 .card-packages-new .price .see-more {
  color: #104172;
  font-size: 12px;
  font-weight: 700;
  cursor: pointer;
}

@media screen and (max-width:1250px){
  .content-packs-new-2 .card-packages-new {
    width: 48%;
  }
}

 

@media screen and (max-width:850px) {
  .content-packages-new {
    flex-direction: column;
  }

  .content-packages-colum-1 {
    width: 100%;
  }

  .content-packages-colum-2{
    max-height: none !important;
  }

  .content-packages-new aside {
    display: none;
  }

  .content-packages-new article {
    width: 100%;
  } 
}

@media screen and (max-width:650px) {
  .content-packages-new article .content-btn-pack button div span {
    color: white;
    font-weight: 900;
    font-size: 18px;
  }

  .content-packages-header h1 {
    font-size: 27px;
    text-align: center;
  }
  .content-packs-new .card-packages-new {
    width: 100%;
  }
}

@media screen and (max-width: 450px) {
  .content-packages-new article .content-btn-pack {
    flex-direction: column;
    gap: 1rem;
  }

  .content-packages-new article .content-btn-pack button {
    width: 100%;
  }

  .content-packages-new article .search-packages-turisticos h2 {
    font-size: 24px;
  }

  .content-packs-new {
    justify-content: center;
  }
}

/* ----------------- NUEVO DISEÑO DE PAQUETES ----------------- */

.unselect {
  padding: 0.8rem;
  border: 1px #004274 solid;
  border-radius: 20px;
  margin-bottom: 10px;
  color: #004274;
  transition: 0.5s ease-in-out;
  cursor: pointer;
}

.select {
  padding: 0.8rem;
  border: 1px #004274 solid;
  border-radius: 20px;
  margin-bottom: 10px;
  color: white;
  background-color: #004274;
  transition: 0.5s ease-in-out;
  cursor: pointer;
}

.reserva-p div svg {
  margin: 0 4px;
}

/* .reserva-p div:hover{
    background-color: #004274;
    color: white;
} */

.reserva-p div p {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.campo-radio {
  margin-top: 1rem;
  display: flex;
  gap: 1rem;
}

.campo-radio div {
  display: flex;
  align-items: center;
  gap: 10px;
  color: #004274;
}

.campo-radio div input[type="radio" i] {
  border: #004274;
}

.campo-radio div input[type="radio" i]:checked {
  background-color: #004274 !important;
}

.contain-Roundtrip {
  display: flex;
  flex-wrap: wrap;
  margin: 1rem 0;
}

.input-ca {
  display: flex;
  align-items: center;
  padding: 8px;
  border-radius: 15px;
  background-color: transparent;
  color: #004274;
  font-size: 13px;
  border: 1px #004274 solid;
  justify-content: space-between;
  flex-wrap: wrap;
}

.disabled {
  opacity: 0.5;
  /* reduce la opacidad para indicar que está deshabilitado */
  pointer-events: none;
  /* desactiva los eventos del mouse */
}

.campo-double {
  margin-top: 1rem;
  display: flex;
}

.title-o {
  color: rgb(121, 121, 121);
  font-size: 12px;
}

.city-select-p {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 15px;
  flex-wrap: wrap;
}

.city-select-p svg {
  margin-left: 4px;
}

.layout-two-t {
  width: 70%;
}

.origin-city,
.destiny-city {
  padding: 10px;
  border: 1px #004274 solid;
  border-radius: 20px;
  color: #004274;
  width: 100%;
}

.origin-city-parent {
  width: 100%;
  position: relative;
  cursor: pointer;
}

.btn-search-p {
  margin: 1rem 0;
}

.btn-search-p button {
  border: 1px rgb(129, 129, 129) solid;
  width: 100%;
  padding: 1rem;
  border-radius: 20px;
  color: #004274;
  background-color: transparent;
  transition: 0.5s ease-in-out;
  cursor: pointer;
}

.circular-pro {
  position: absolute;
  margin-right: 15px;
  right: 30px;
}

.btn-search-p button:hover {
  background-color: #004274;
  color: white;
}

.up-img-p {
  z-index: 1;
  width: 100%;
}

.contain-buscador-packages {
  margin: 2rem auto;
  width: 80%;
  padding: 1rem;
  display: flex;
  justify-content: space-around;
}

.contain-packages-all {
  width: 80%;
  margin: 2rem auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.layout-packages {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.before-pag,
.after-pag {
  padding: 5px;
  border-radius: 20px;
  background-color: #004274;
  color: white;
}

.current-pag {
  padding: 5px;
  border-radius: 20px;
  background-color: #002d50;
  color: white;
}

.card-packages img {
  width: 260px;
  height: 240px;
  border-radius: 20px;
  object-fit: cover;
}

.card-packages h3 {
  max-width: 220px;
  color: #004274;
}

.all-cities {
  max-height: 200px;
  overflow: scroll;
  width: 100%;
  margin-top: 0.5rem;
}

.all-cities ul {
  list-style: none;
}

.all-cities li {
  padding: 0.5rem;
  transition: 0.5s ease-in-out;
  color: var(--bluePrimary);
}

.all-cities li:hover {
  background-color: var(--bluePrimary);
  color: white;
  transform: scale(1.04);
}

.card-p-description {
  font-size: 13px;
  color: #004274;
}

.card-price-p {
  font-size: 25px;
  font-weight: 900;
  margin-top: 8px;
  color: #004274;
}

.contain-carousel-p {
  width: 100%;
  display: none;
}

.date-p {
  font-size: 15px;
}

.dropdown-cityO-packages {
  position: absolute;
  width: 100%;
  top: 60px;
  padding: 1rem 2rem;
  background-color: white;
  -webkit-box-shadow: 0px 1px 29px -2px rgba(0, 0, 0, 0.39);
  -moz-box-shadow: 0px 1px 29px -2px rgba(0, 0, 0, 0.39);
  box-shadow: 0px 1px 29px -2px rgba(0, 0, 0, 0.39);
  z-index: 9;
  display: flex;
  justify-content: end;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
}

.dropdown-cityO-packages p {
  width: 100%;
  border: none;
  border-radius: 10px;
  padding: 0.6rem;
  color: rgb(135, 135, 135);
  -webkit-box-shadow: 5px 7px 23px 0px rgba(0, 0, 0, 0.32);
  -moz-box-shadow: 5px 7px 23px 0px rgba(0, 0, 0, 0.32);
  box-shadow: 5px 7px 23px 0px rgba(0, 0, 0, 0.32);
  transition: 0.5 ease-in-out;
}

.dropdown-cityD-packages {
  position: absolute;
  width: 100%;
  top: 60px;
  padding: 1rem 2rem;
  background-color: white;
  -webkit-box-shadow: 0px 1px 29px -2px rgba(0, 0, 0, 0.39);
  -moz-box-shadow: 0px 1px 29px -2px rgba(0, 0, 0, 0.39);
  box-shadow: 0px 1px 29px -2px rgba(0, 0, 0, 0.39);
  z-index: 9;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}

.dropdown-cityD-packages input {
  width: 100%;
  border: none;
  border-radius: 25px;
  padding: 0.6rem;
  -webkit-box-shadow: 5px 7px 23px 0px rgba(0, 0, 0, 0.32);
  -moz-box-shadow: 5px 7px 23px 0px rgba(0, 0, 0, 0.32);
  box-shadow: 5px 7px 23px 0px rgba(0, 0, 0, 0.32);
  transition: 0.5 ease-in-out;
}

.layout-one-t {
  width: 30%;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: white;
  gap: 1rem;
  z-index: 9999;
  border-radius: 19px;
  box-shadow: 0px 7px 8px 0px rgba(0, 0, 0, 0.2);
  margin-right: 2rem;
}

@media screen and (max-width: 1100px) {

  .contain-packages-all,
  .contain-buscador-packages {
    width: 99%;
  }
}

/* @media screen and (max-width:860px) {

    .contain-carousel-p {
        display: block;
    }

    .contain-paginado,
    .layout-packages {
        display: none;
    }

} */

@media screen and (max-width: 800px) {
  .campo-double {
    gap: 1rem;
  }

  .origin-city-parent,
  .destiny-city-parent {
    width: 100%;
  }

  .city-select-p {
    gap: 5px;
    font-size: 13px;
  }

  .date-p {
    font-size: 13px;
    font-weight: 700;
  }

  .contain-buscador-packages {
    flex-direction: column;
  }

  .layout-one-t,
  .layout-two-t {
    width: 100%;
  }
}

@media screen and (max-width: 600px) {
  .reserva-p div p {
    gap: 5px;
  }
}

.input-filter-packages {
  width: 100%;
  border: 1px solid #004274;
  border-radius: 20px;
  padding-inline: 10px;
  height: 32px;
  font-family: "Montserrat" !important;
  background: url("../assets/svg/Search.svg") no-repeat;
  background-position: right 10px center;
  height: 55px;
  margin-block: 5px;
}

.select-filter-packages {
  margin-block: 5px;
  cursor: pointer;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 100%;
  border: 1px solid #004274;
  border-radius: 20px;
  padding-inline: 10px;
  height: 32px;
  font-family: "Montserrat" !important;
  background: url("../assets/svg/ArrowDown.svg") no-repeat;
  background-position: right 10px center;
  height: 55px;
}

.filter-packages {
  width: 100%;
  border: 1px solid #004274;
  border-radius: 20px;
  padding-inline: 10px;
  height: 32px;
  font-family: "Montserrat" !important;
  background: url("../assets/svg/Search.svg") no-repeat;
  background-position: right 10px center;
  height: 55px;
}

.section-image {
  width: 500px;
  height: 500px;
  border-radius: 9px;
  object-fit: contain;
}

.image-selected {
  width: 100%;
  height: 480px;
  border-radius: 9px;
  object-fit: cover;
}

.thumbnails-images-packages {
  margin: 0;
}

.carousel-images-packages {
  display: flex;
  padding-inline: 110px;
  justify-content: space-around;
  margin-block: 50px;
}

.main-image-packages {
  display: flex;
  justify-content: center;
  width: 55%;
}

.content-buscador-packages {
  display: flex;
  align-items: center;
  margin-block: 50px;
}

.slick-prev:before,
.slick-next:before {
  color: black !important;
}

.slick-prev {
  left: 100px !important;
  z-index: 10000;
}

.slick-next {
  right: 100px !important;
}


.content-slider-images-packages {
  position: relative;
  margin: 0 auto;
  overflow: hidden;
}

.slide-container-packages {
  display: flex;
  transition: transform 0.5s ease; /* Animación suave al cambiar de slide */
}

.slide-packages {
  flex: 0 0 auto; /* Cada slide ocupa el ancho determinado por el contenido (la imagen) */
  margin-right: 10px; /* Espacio entre slides */
  transition: opacity 0.8s ease; /* Animación de opacidad */
}

.slide-packages.active {
  opacity: 1; /* Slide activo completamente visible */
}

.buttonArrowSliderPackagesLeft,
.buttonArrowSliderPackagesRight {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: #fff;
  color: #002d50;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3px;
  font-size: 1.5rem;
  cursor: pointer;
  z-index: 999;
  border-radius: 20%;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.174);
}

.buttonArrowSliderPackagesLeft {
  left: 10px; /* Posición del botón izquierdo */
}

.buttonArrowSliderPackagesRight {
  right: 15px; /* Posición del botón derecho */
}


@media screen and (max-width: 1387px) {
  .content-packs-new .card-packages-new {
    background-color: white;
    border-radius: 20px;
    box-shadow: 0px 4px 4px 0px #00000040;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1rem;
    width: 400px;
    min-width: 200px;
    overflow: hidden;
  }
}

@media screen and (max-width: 1368px) {
  .content-packs-new .card-packages-new {
    background-color: white;
    border-radius: 20px;
    box-shadow: 0px 4px 4px 0px #00000040;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1rem;
    width: 360px;
    min-width: 200px;
    overflow: hidden;
  }
}

@media screen and (max-width: 1235px) {
  .content-packs-new .card-packages-new {
    background-color: white;
    border-radius: 20px;
    box-shadow: 0px 4px 4px 0px #00000040;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1rem;
    width: 350px;
    min-width: 200px;
    overflow: hidden;
  }
}


@media screen and (max-width: 1203px) {
  .content-packs-new .card-packages-new {
    background-color: white;
    border-radius: 20px;
    box-shadow: 0px 4px 4px 0px #00000040;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1rem;
    width: 330px;
    min-width: 200px;
    overflow: hidden;
  }
}

@media screen and (max-width: 1136px) {
  .content-packs-new .card-packages-new {
    background-color: white;
    border-radius: 20px;
    box-shadow: 0px 4px 4px 0px #00000040;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1rem;
    width: 310px;
    min-width: 200px;
    overflow: hidden;
  }
}

@media screen and (max-width: 1069px) {
  .content-packs-new .card-packages-new {
    background-color: white;
    border-radius: 20px;
    box-shadow: 0px 4px 4px 0px #00000040;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1rem;
    width: 290px;
    min-width: 200px;
    overflow: hidden;
  }
}

@media screen and (max-width: 1002px) {
  .content-packs-new .card-packages-new {
    background-color: white;
    border-radius: 20px;
    box-shadow: 0px 4px 4px 0px #00000040;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1rem;
    width: 270px;
    min-width: 200px;
    overflow: hidden;
  }
}

@media screen and (max-width: 935px) {
  .content-packs-new .card-packages-new {
    background-color: white;
    border-radius: 20px;
    box-shadow: 0px 4px 4px 0px #00000040;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1rem;
    width: 250px;
    min-width: 200px;
    overflow: hidden;
  }
}

@media screen and (max-width: 868px) {
  .content-packs-new .card-packages-new {
    background-color: white;
    border-radius: 20px;
    box-shadow: 0px 4px 4px 0px #00000040;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1rem;
    width: 230px;
    min-width: 200px;
    overflow: hidden;
  }
}

@media screen and (max-width: 801px) {
  .content-packs-new .card-packages-new {
    background-color: white;
    border-radius: 20px;
    box-shadow: 0px 4px 4px 0px #00000040;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1rem;
    width: 320px;
    min-width: 200px;
    overflow: hidden;
  }
}

@media screen and (max-width: 734px) {
  .content-packs-new .card-packages-new {
    background-color: white;
    border-radius: 20px;
    box-shadow: 0px 4px 4px 0px #00000040;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1rem;
    width: 300px;
    min-width: 200px;
    overflow: hidden;
  }
}

@media screen and (max-width: 685px) {
  .content-packs-new .card-packages-new {
    background-color: white;
    border-radius: 20px;
    box-shadow: 0px 4px 4px 0px #00000040;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1rem;
    width: 285px;
    min-width: 200px;
    overflow: hidden;
  }
}

@media screen and (max-width: 652px) {
  .content-packs-new .card-packages-new {
    background-color: white;
    border-radius: 20px;
    box-shadow: 0px 4px 4px 0px #00000040;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1rem;
    width: 270px;
    min-width: 200px;
    overflow: hidden;
  }
}

@media screen and (max-width: 620px) {
  .content-packs-new .card-packages-new {
    background-color: white;
    border-radius: 20px;
    box-shadow: 0px 4px 4px 0px #00000040;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1rem;
    width: 250px;
    min-width: 200px;
    overflow: hidden;
  }
}

@media screen and (max-width: 574px) {
  .content-packs-new .card-packages-new {
    background-color: white;
    border-radius: 20px;
    box-shadow: 0px 4px 4px 0px #00000040;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1rem;
    width: 100%;
    min-width: 200px;
    overflow: hidden;
  }
}