.content-select-procedures-main {
    width: 95%;
    max-width: 1280px;
    margin: 4rem auto;
    display: flex;
    gap: 30px;
    justify-content: space-between;
}


.content-select-proceduresT {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.title-select-procedures {
    font-size: 25px;
    color: var(--bluePrimary);
}

.contain-procedures-popular h3 {
    font-size: 20px;
    color: var(--bluePrimary);
}

.content-procedures-popular {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
  }

.content-procedures-popular span:nth-child(1),
.content-procedures-popular span:nth-child(2),
.content-procedures-popular span:nth-child(3),
.content-procedures-popular span:nth-child(4) {
    width: 24%;
    padding: 1rem 2rem;
    border: 1px solid rgb(213, 213, 213);
    color: rgb(169, 169, 169);
    border-radius: 6px;
    font-size: 17px;
    font-weight: 600;
    min-width: 250px;
    text-align: center;
    margin-top: 1rem;
    transition: .5s ease-in-out;
}

.content-procedures-popular span:hover {
    cursor: pointer;
    border-color: var(--bluePrimary);
    color: var(--bluePrimary);
}

.contain-camp-select-procedures label {
    color: var(--bluePrimary);
    font-size: 20px;
}

.contain-camp-select-procedures .camp-select-procedures {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    border-radius: 6px;
    border: 1px solid rgb(213, 213, 213);
    color: rgb(100, 100, 100);
    margin-top: 1rem;
    width: 60%;
    cursor: pointer;
    margin-bottom: 1rem;
}


.modal-procedures-select {
    position: absolute;
    top: 60px;
    border: 1px solid #ddd;
    left: 0;
    background-color: white;
    border-radius: 10px;
    
    height: 200px;
    z-index: 9;
    width: 100%;
    overflow: scroll;
  }
  
  .modal-procedures-select ul {
    list-style: none;
  }
  
  .modal-procedures-select ul li {
    transition: 0.2s ease-in-out;
    padding: 1rem;
  }
  
  .modal-procedures-select ul li:hover {
    background-color: #f3f3f3;
    cursor: pointer;
  }


@media screen and (max-width:1100px) {
    .content-select-procedures {
        width: 95%;
    }
}


@media screen and (max-width:500px) {
    .contain-camp-select-procedures div {
        width: 100%;
    }

    .content-procedures-popular span:nth-child(1),
    .content-procedures-popular span:nth-child(2),
    .content-procedures-popular span:nth-child(3),
    .content-procedures-popular span:nth-child(4) {
        width: 100%;
    }

    .contain-camp-select-procedures .camp-select-procedures{
        width:100%
    }
}