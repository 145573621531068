.contain-flow {
    display: flex;
    flex-wrap: wrap;
}

.ind-flow {
    padding: 1rem;
    border-radius: 20px;
    -webkit-box-shadow: 12px 11px 40px -3px rgba(0, 0, 0, 0.21);
    -moz-box-shadow: 12px 11px 40px -3px rgba(0, 0, 0, 0.21);
    box-shadow: 12px 11px 40px -3px rgba(0, 0, 0, 0.21);
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 30%;
    flex-grow: 1;
    flex-basis: 200px;
}

.ind-flow h2 {
    margin-bottom: 1rem;
    color: var(--bluePrimary);
    font-size: 14px;
}

.ind-flow h2 span {
    font-weight: 600;
    font-size: 40px;
}

.ind-flow h3 {
    color: var(--bluePrimary);
    font-size: 15px;
}

.ind-flow p {
    color: var(--bluePrimary);
    font-size: 13px;
}

.hotel-flow {
    margin-top: 1rem;
}

.btn-ind-flow {
    background-color: var(--bluePrimary);
    width: 100%;
    color: white;
    transition: .5s ease-in-out;
    font-weight: 600;
    cursor: pointer;
    padding: .5rem;
    border-radius: 15px;
    font-size: 16px;
    margin-top: .5rem;
}

.btn-ind-flow:hover {
    transform: scale(1.02);

}

@media screen and (max-width:600px) {
    .ind-flow{
        padding: .5rem;
    }

    .ind-flow h2{
        margin-top: .3rem;
    }

    .hotel-flow{
        margin-top: .3rem;
    }
}