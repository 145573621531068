.grid_choferQuestion {
    position: absolute;
    left: 120px;
    top: 330px;
    background-color: #004274;
    border-radius: 20px;
    box-shadow: -4px 4px 4px rgba(0, 0, 0, 0.25);
    width: 80% !important;
    max-height: 530px;
    overflow: hidden;
    overflow-y: auto;
}
.css-vj1n65-MuiGrid-root {
    margin: 15px 10px !important;
}

/*.Arrows {
    width: 100%;
    margin-bottom: 10px;
}

@media screen and (max-width: 1000px) {
    .grid_doctorsQuestion {
        width: 100% !important;
        top: 280px;
        left: 16px;
    }
}
@media screen and (max-width: 700px) {
    .grid_doctorsQuestion {
        top: 300px;
    }
}*/