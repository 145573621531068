.myMovements {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.myMovements h1 {
    font-size: 24px;
    font-weight: 600;
    color: var(--bluePrimary);
    padding-bottom: 3px;
    border-bottom: 2px solid var(--bluePrimary);
}

.movementItems {
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.movementItem {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    align-items: center;
}

.details {
    font-weight: 600;
    font-size: 14px;
    color: var(--bluePrimary);
}

.details p {
    font-weight: 400;
    color: var(--blackGray);
}

.amount {
    font-size: 14px;
    color: var(--bluePrimary);
}

.noItems {
    height: 100%;
    display: grid;
    place-items: center;
    gap: 30px;
    color: var(--bluePrimary);
    font-size: 18px;
    font-weight: 500;
}

.message {
    display: flex;
    align-items: center;
    gap: 10px;
}

.buy {
    background-color: var(--bluePrimary);
    border-radius: 6px;
    color: var(--white);
    padding: 10px 20px;
    transition: all 0.3s ease;
    font-size: 14px;
}

.buy:hover {
    background-color: var(--blueTertiary);
}

@media screen and (max-width: 550px) {
    .movementItem {
        display: flex;
        justify-content: space-between;
        gap: 10px;
        align-items: flex-start;
        flex-direction: column;
    }
}