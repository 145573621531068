.section-contain-new-wellezy {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contain-new-wellezy {
  width: 100%;
  max-width: 1280px;
  height: 100%;
  margin: 5rem auto;
  padding: 0 20px;
}

.contain-new-wellezy h3 {
  color: var(--bluePrimary);
  font-size: 25px;
}

.content-btn-news-see {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.content-btn-news-see a {
  padding: 0.5rem;
  color: var(--white);
  background-color: var(--bluePrimary);
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  font-size: 12px;
}

@media screen and (max-width: 1100px) {
  .contain-new-wellezy {
    width: 99%;
  }
}

.contain-carousel-news h4 {
  color: var(--blackGray);
  font-size: 16px;
  max-height: 50px;
  overflow: scroll;
  height: 50px;
  display: flex;
  align-items: flex-start;
}

.subtitle-find-wellezy {
  font-weight: 300;
  margin-bottom: 1rem;
}

.date-new-wellezy {
  color: var(--blackGray);
  font-weight: 600;
  font-size: 16px;
  padding: 10px 20px;
}

.text-new-wellezy {
  font-size: 14px;
  font-weight: 300;
  color: var(--blackGray);
  text-align: justify;
  padding: 10px 20px;
}

.contain-new {
  width: 330px;
  height: 450px;
  transition: 0.5s ease-in-out;
  border: 1px solid var(--blackGray);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: all 0.3s ease;
}

.contain-new:hover {
  box-shadow: var(--shawdowPrimary);
}

.btn-new-wellezy {
  border-radius: 50px;
  color: #333;
  padding: 8px 20px;
  border: 1px solid #3333;
  width: 200px;
  margin: 15px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: all 0.3s ease;
}

.btn-new-wellezy:hover {
  background-color: var(--greyPrimary);
}

.contain-carousel-news img {
  width: 100%;
  min-height: 250px;
  max-height: 250px;
  object-fit: cover;
  border-radius: 15px 15px 0 0;
}

.contain-carousel-news {
  display: flex;
  gap: 20px;
  align-items: center;
}

.contain-carousel-news .grid_cardHomeTurism {
  margin: 0 !important;
}

@media screen and (max-width: 400px) {
  .contain-new {
    width: 220px;
  }
  .contain-new img {
    height: 200px;
  }
}

.SliderContainerFullAll {
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0rem 6px;
}

.SliderContainerFull {
  width: 100%;
}

.SliderContainer {
  position: relative;
  width: 100%;
  overflow: hidden;
  padding: 0px 10px;
  max-width: 1280px;
}

.SliderTrack {
  display: flex;
  gap: 20px;
}

.SlideImage {
  width: 100%;
  max-height: 300px;
  min-height: 300px;
}

.SlideImage img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 20px 20px 0 0;
}

.contentNewSliderNews {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px 20px 25px 20px;
  gap: 20px;
}

.contentNewSliderNewsDescription {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
}

.contentNewSliderNews a {
  display: flex;
  align-items: center;
  gap: 5px;
  border: 1px solid var(--greyPrimary);
  padding: 10px 20px;
  width: fit-content;
  border-radius: 50px;
  color: var(--bluePrimary);
  transition: all 0.3s ease;
}

.contentNewSliderNews a:hover {
  background-color: var(--greyPrimary);
}

@media screen and (max-width: 375px) {
  .Slide {
    width: 100%;
    min-width: 270px;
    max-width: 270px;
  }
}

@media screen and (max-width: 450px) {
  .Slide {
    width: 100%;
    min-width: 350px;
    max-width: 350px;
  }
}

@media screen and (max-width: 500px) {
  .SliderContainerFull {
    width: 95%;
  }
}

.carousel-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 95%;
  margin: 0 auto;
  max-width: 1280px;
}

.carousel-wrapper {
  overflow: hidden;
  margin-left: 5px;
}

.carousel-content {
  display: flex;
  gap: 10px; /* Espacio entre tarjetas */
  transition: transform 0.3s ease-in-out;
}

.carousel-card {
  flex: 0 0 calc(100% / 3 - 10px);
  box-sizing: border-box;
}

.Slide {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
  border: 1px solid var(--greyPrimary);
  border-radius: 20px;
}

.contentNewSliderNews {
  padding: 10px;
}

.contentNewSliderNewsDescription {
  margin: 10px 0;
}

.carousel-button-new {
  background-color: var(--bluePrimary);
  color: var(--white);
  border: none;
  width: 40px;
  min-width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 2;
  border-radius: 50%;
  font-size: 28px;
}

.carousel-button-new:disabled {
  background-color: var(--blueTransparent);
  cursor: not-allowed;
}

@media (max-width: 950px) {
  .carousel-card {
    flex: 0 0 calc(100% / 2 - 10px); /* 2 tarjetas por vista */
  }

  .Slide {
    min-width: 100%;
    max-width: 100%;
  }
}

@media (max-width: 600px) {
  .carousel-card {
    flex: 0 0 calc(100% - 10px); /* 1 tarjeta por vista */
  }

  .carousel-container {
    padding-left: 10px;
  }

  .Slide {
    min-width: 100%;
    max-width: 100%;
  }
}
