.containerJoinPX {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}



.meetingViewPX {
  margin: auto;
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px 20px;
  border-radius: 20px;
  -webkit-box-shadow: 0px 10px 14px -7px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 10px 14px -7px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 10px 14px -7px rgba(0, 0, 0, 0.75);
  width: 50%;
}


.meetingViewPX .meetCodeLabelPX {
  color: var(--black);
  text-align: center;
  font-size: 14px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  margin-bottom: -5px;
}

.meetingViewPX .meetCodeLabelBigPX {
  color: var(--black);
  text-align: center;
  font-size: 20px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  margin-bottom: 10px;
}


.meetingViewPX .meetCodeValuePX {
  width: 50%;
  background-color: var(--greyPrimary);
  padding: 10px 20px;
  margin-top: 10px;
  border-radius: 12px;
  color: var(--black);
  text-align: center;
  font-size: 22px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 800;
}



.meetingViewPX .btnJoinPX {
  margin-top: 40px;
  background-color: #50AE42;
  width: 100px;
  height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  animation: pulse 2s infinite;
  cursor: pointer;
  transition: all ease 200ms;
}

.meetingViewPX .btnJoinPX:hover {
  transition: all ease 200ms;
  animation: pulse 0.5s infinite !important;
}

.meetingViewPX .ripplePX {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 50%;
  transform: scale(1);
  animation: ripple 1s linear;
  pointer-events: none;
}



.meetingViewPX .btnJoinPX .btnJoinTextPX {
  margin-top: 5px;
  color: var(--white);
  text-align: center;
  font-size: 16px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 800;
}


.meetingViewPX .btnBackPX {
  /* border: solid 1px var(--blueLight); */
  background-color: var(--white);
  padding: 5px 10px;
  border-radius: 12px;
  cursor: pointer;
  margin-top: 40px;
  transition: all ease 200ms;
}

.meetingViewPX .btnBackPX:hover {
  transition: all ease 200ms;
  transform: translateY(-4px);
  -webkit-box-shadow: 0px 10px 14px -7px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 10px 14px -7px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 10px 14px -7px rgba(0, 0, 0, 0.75);
}

.meetingViewPX .btnBackPX .btnBackTextPX {
  color: var(--blueLight);
  text-transform: capitalize;
}


@keyframes pulse {

  0%,
  100% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }
}

@keyframes ripple {
  0% {
    transform: scale(1);
    opacity: 0.4;
  }

  100% {
    transform: scale(4);
    opacity: 0;
  }
}