.searchInput {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 40px;
    border: 1px solid var(--greyPrimary);
    border-radius: 8px;
    position: relative;
}

.searchInput input {
    width: 100%;
    outline: none;
    border: none;
    border-radius: 8px;
}

.searchInput.searchInputError {
    border: solid var(--redPrimary) 1px;
}

.clearButton {
    cursor: pointer;
    font-size: 14px;
    display: grid;
    place-items: center;
    position: absolute;
    right: 10px;
}

.dropdownMenu {
    position: absolute;
    margin-top: 4px;
    border-radius: 8px;
    top: 100%;
    left: 0;
    width: 100%;
    border: 1px solid var(--greyPrimary);
    background-color: var(--white);
    max-height: 200px;
    overflow-y: auto;
    z-index: 1000;
}

.dropdownOption {
    padding: 8px;
    cursor: pointer;
}

.dropdownOption:hover {
    background-color: var(--blueWhiteTable);
}