.contain-all-clinics {
  display: grid;
  grid-gap: 15px;
  width: 100%;
  margin: auto;
  grid-template-columns: repeat(4, 1fr);
  max-width: 1280px;
  padding-bottom: 20px;
}

.card-clinics {
  display: flex;
  flex-direction: column;
  max-width: 250px;
  cursor: pointer;
}

.card-clinics img {
  height: 220px;
  width: 250px;
  object-fit: cover;
  border-radius: 10px;
}

.card-clinic-info {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.layout-clinics-a {
  display: flex;
  justify-content: space-between;
  margin-top: 0.5rem;
}

.layout-clinics-a div:first-child {
  width: 80%;
}

.layout-clinics-a div:last-child span {
  font-size: 13px;
  font-weight: 700;
  display: flex;
  align-items: normal;
  gap: 2px;
}

.layout-clinics-a div:first-child h3 {
  font-size: 16px;
  height: 25px;
  overflow: scroll;
}

.layout-clinics-a div:first-child p {
  font-size: 11px;
  color: rgb(108, 108, 108);
}

.layout-clinics-a div:last-child {
  width: fit-content;
}

.layout-clinics-b {
  display: flex;
  justify-content: space-between;
}

.layout-clinics-b span {
  color: #004274;
  font-weight: 700;
  font-size: 14px;
  cursor: pointer;
}

.layout-clinics-b p {
  height: 25px;
  overflow: scroll;
  width: 60%;
}
