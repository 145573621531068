body.modal-open {
    overflow: hidden;
}

.sideBarContainer{
    background-color: rgba(0, 0,0, 0.5);
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: 9999999999;
    transition: all ease 200ms;
}

.sideBarSection{
    background-color: #104172;
    height: 100%;
    transition: all ease 200ms;
    max-width: 100vw !important;
}

.sideBarSection-off-right{
    transform: translateX(100%);transition: all 0.3s;
}

.sideBarSection-on-right{
    transform: translateX(0%);transition: all 0.6s;
}

.sideBarSection-off-left{
    transform: translateX(-100%);transition: all 0.6s;
}

.sideBarSection-on-left{
    transform: translateX(0%);transition: all 0.3s; 
}

.sideBarSectionHead{
    background-color: rgba(0, 0,0, 0.2);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 12px;
    position: relative;
}
.sideBarLogo{
    height: 45px;
}

.sideBarLogoImg{
    width: 100%;
    height: 100%;
}

.sideBarSectionHeadBtnClose{
    position: absolute;
    top:22px;
    right: 10px;
    cursor: pointer;
}

.sideBarSectionBody{
    height: 90vh;
    overflow-y: scroll;
}

