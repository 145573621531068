.modal-gallery {
  position: fixed;
  background-color: #021624b0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 1;
  transition: all 0.2s ease;
  padding: 30px;
}

.modal-content-gallery {
  padding: 20px;
  border-radius: 5px;
  overflow: auto;
}

@media screen and (max-width: 700px) {
  .each-slide-effect > div {
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    background-position: center;
    height: 350px;
    border-radius: 10px;
    padding: 1rem;
  }
  .react-slideshow-container {
    width: 90%;
  }

  .modal-content-gallery {
    padding: 0;
  }
}

.modal-gallery-hotel {
  position: fixed;
  background-color: #021624b0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 1;
  transition: all 0.2s ease;
  padding: 30px;
}

.modal-gallery-hotel.close {
  position: fixed;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  pointer-events: none;
}

.modal-content-gallery-hotel {
  display: flex;
  flex-direction: column;
  gap: 15px;
  background-color: white;
  width: 100%;
  max-width: 880px;
  max-height: 600px;
  overflow-y: scroll;
  border-radius: 12px;
  padding-bottom: 0;
  position: relative;
  opacity: 1;
  transition: all 0.3s ease;
  transform: translateY(0px);
  z-index: 99999;
}

.modal-content-gallery-hotel.close {
  background-color: white;
  width: 100%;
  width: 100%;
  max-width: 880px;
  max-height: 600px;
  border-radius: 10px;
  padding-bottom: 0;
  overflow-y: scroll;
  position: relative;
  opacity: 0;
  transition: all 0.3s ease;
  transform: translateY(-100px);
}

.slider-hotel-detail {
  position: relative;
  overflow: hidden;
}

.slider-hotel-wrapper-detail {
  display: flex;
  transition: margin-left 0.5s ease-in-out;
  width: 100%;
}

.slide-hotel-detail {
  min-width: 100%;
  box-sizing: border-box;
}

.slide-hotel-detail-img {
  width: 100%;
  height: 100%;
  max-height: 600px;
  object-fit: cover;
}

.left-hotel-arrow,
.right-hotel-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  border: none;
  color: white;
  font-size: 30px;
  cursor: pointer;
  z-index: 1;
}

.left-hotel-arrow {
  left: 10px;
}

.right-hotel-arrow {
  right: 10px;
}
