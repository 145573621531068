/* ******* BagsContainer ******* */
.componentChild {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.nameFlightsBags {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
  height: 100%;
}

.componentChildIda {
  position: absolute;
  top: 0;
  right: 0;
  background-color: #104172;
  color: white;
  padding: 5px 3px 8px 10px;
  width: 80px;
  text-align: center;
  border-radius: 0px 0px 0px 25px;
  z-index: 99;
  font-size: 14px;
}
.componentChildIda {
  position: absolute;
  top: 0;
  right: 0;
  background-color: #104172;
  color: white;
  padding: 5px 3px 8px 10px;
  width: 80px;
  text-align: center;
  border-radius: 0px 0px 0px 25px;
  z-index: 99;
  font-size: 14px;
}

@media (max-width: 500px) {
  .componentChildIda {
      width: 100%;
      border-radius: 0px;
      text-align: start;
      padding: 4px;
      font-size: 11px;
      padding-left: 9px;
      text-transform: uppercase;
      position: relative;
  }
}

.topContainerFlightInfo{
  display: flex !important;
  justify-content: space-between !important; 
  width: 100%; 
  align-items: center; 
  padding-right: 10px;
  padding-left: 47px; 
  margin-bottom: -1.5rem;
}
.containerFlightInfo{
  display: flex;
  gap: 20px;
  width: 100%; 
}

.flightContainerOptionSelectReponsive {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
}
@media (max-width: 500px) {
  .flightContainerOptionSelectReponsive {
      gap: 0px !important;
  }
}
.flightContainerOptionSelect {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  border-bottom: 1px solid #d9d9d9;
  position: relative;
  padding-right: 20px;
}

.componentChild .componentChildContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  border-top: 2px solid #104172;
}

/* ******* ContainerChecked ******* */
.componentChild .ContainerChecked {
  display: flex;
  flex-direction: column;
  padding: 10px 20px 10px 40px;
  position: relative;
  width: 100%;
  height: 100%;
}

@media (max-width: 500px) {
  .componentChild .ContainerChecked {
      padding: 0 !important;
  }
}

.componentChild .ContainerCheckedUp {
  display: flex;
  align-items: center;
}

.componentChild .checkActive {
  width: 16px;
  height: 16px;
  border: 1px solid #104172;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: absolute;
  top: 10px;
  left: 10px;
}
@media (max-width: 500px) {
  .componentChild .checkActive {
      width: 26px;
      height: 26px;
  }
  .componentChild .checkInactive {
    width: 16px !important;
    height: 16px !important; 
  }
}
.componentChild .checkInactive {
  width: 8px;
  height: 8px;
  background-color: #104172;
  border-radius: 6px;
}

.componentChild .ContainerCheckedLogo {
  height: 50px;
  width: 80px;
}

.componentChild .ContainerCheckedLogoFile {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.imgTopResponsiveAirline{
  object-fit: cover;
}
.componentChild .ContainerCheckedName {
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 400;
  color: #000000;
  font-family: "Montserrat", sans-serif;
  padding: 5px 0;
}

/* ******* BagsContainer ******* */
.componentChild .bagsContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 1110px) {
  .componentChild .bagsContainer {
    display: none;
  }
}

.separationBorderFlight {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
}
.separationBorderFlightLine {
  width: 95%;
  background: linear-gradient(to right, transparent, #1041726f, transparent);
  height: 1px;
}

.componentChild .bagsContainerIcon {
  justify-content: center;
  align-items: center;
  display: flex;
  overflow: hidden;
}
.bagsContainerIcon svg {
  width: 50px;
  height: 50px;
}

@media (max-width: 500px) {
  .bagsContainerIcon svg {
      width: 50px;
      height: 50px;
  }
}
/* resolucion para ifon 12 pro */
@media screen and (max-width: 600px) {
  .componentChild {
    width: 100%;
  }

  .fiveColumnsContainerChild {
    width: 100%;
    flex-direction: column;
  }
}

.fiveButtonArrow {
  cursor: pointer;
}

.fiveColumnsContainerChild .fiveColumnsPrimaryColumn {
  width: 100%;
}

.fiveColumnsContainerChild .fiveColumnsPrimaryColumnRowTriple {
  margin-top: 10px;
  width: 100%;
}

@media (max-width: 500px) {
  .fiveColumnsContainerChild .fiveColumnsPrimaryColumnRowTriple {
      margin-top: 5px;
  }
}

.fiveColumnsContainerChild .package {
  margin-top: 10px;
  width: 100%;
  justify-content: center;
}

.fiveColumnsContainerChild .package .bagsContainer {
  justify-content: space-around;
}

.rowFiveColumnsTitle .titleColPackage {
  display: none;
}

.fiveColumnsContainerTitle .fiveColumnsColumnsEmpty {
  display: none;
}

.fiveColumnsContainerTitle .packageTitle {
  display: none;
  background-color: green;
}

/* 5Columns rowFiveColumnsTitle

    5Columns fiveColumnsContainerTitle

    fiveColumnsPrimaryColumn */

.fiveColumnsTextBig {
  display: flex;
  text-align: start;
  flex-direction: column;
  gap: 10px;
  font-size: 12px;
  max-width: 220px;
}

.fiveColumnsTextBigRight {
  display: flex;
  text-align: end;
  flex-direction: column;
  gap: 10px;
  font-size: 12px;
  max-width: 220px;
}

.fiveColumnsHour {
  font-size: 15px;
  font-weight: 600;
  color: #104172;
  font-family: "Montserrat", sans-serif;
}
@media (max-width: 500px) {
  .none500 {
      display: none !important;
  }
}
.fade-in {
  opacity: 0;
  animation: fadeIn 2s forwards;
}

.fade-out {
  opacity: 1;
  animation: fadeOut 2s forwards;
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  to {
    opacity: 0;
  }
}

.FlightInfoContentModal {
  position: fixed;
  background-color: #021624b0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 1;
  transition: all 0.2s ease;
  padding: 30px;
}

.FlightInfoContentModal.close {
  position: fixed;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  pointer-events: none;
}

.content-render-scales {
  max-height: 520px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.FlightInfoContent {
  display: flex;
  flex-direction: column;
  gap: 15px;
  background-color: white;
  width: 100%;
  max-width: 680px;
  border-radius: 12px;
  padding: 20px;
  position: relative;
  opacity: 1;
  transition: all 0.3s ease;
  transform: translateY(0px);
  z-index: 99999;
}

.FlightInfoContent.close {
  background-color: white;
  width: 100%;
  width: 100%;
  max-width: 680px;
  border-radius: 10px;
  padding: 20px;
  position: relative;
  opacity: 0;
  transition: all 0.3s ease;
  transform: translateY(-100px);
}

.FlightInfoButtonClose {
  position: absolute;
  right: 7px;
  top: 7px;
  cursor: pointer;
  z-index: 999999;
  background: #fff;
  padding: 5px;
}

.flightContainerOptionSelectModal {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  border: 1px solid #d9d9d9;
  border-radius: 12px;
  padding: 20px;
}

.componentChildIdaModal {
  position: absolute;
  left: 0;
  top: 0;
  background-color: #104172;
  color: white;
  padding: 3px 8px;
  width: 80px;
  text-align: center;
  border-radius: 12px 0px;
  z-index: 999;
  font-size: 14px;
}

.componentChildContainerModal {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
}

.nameFlightsBagsModal {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
  width: 100%;
  height: 100%;
}

.ContainerCheckedModal {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  position: relative;
  width: 100%;
  height: 100%;
}

.ContainerCheckedNameModal {
  text-transform: uppercase;
  font-size: 15px;
  font-weight: 700;
  color: #104172;
  font-family: "Montserrat", sans-serif;
  padding: 5px 0;
}

.ContainerCheckedUp {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.componentBagsContainerModal {
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 20px;
  border-radius: 12px;
  border: 1px solid #d9d9d9;
}

.componentBagsContainerSectionModal {
  height: 100%;
  display: flex;
  gap: 15px;
}

.componentBagsContainerModal p {
  font-size: 13px;
  font-weight: 500;
  color: #1d1d1d90;
}

.componentBagsContainerSeparationModal {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 20px 0px 0px;
  border-right: 1px solid #d9d9d9;
  font-size: 13px;
}

.multi-background {
  height: 1px;
  width: 100%;
  background: linear-gradient(to right, transparent, #104172, transparent);
}

.componentChildIdaButtonViewMore {
  background-color: #104172;
  border-radius: 50px;
  padding: 5px 18px;
  cursor: pointer;
  width: fit-content;
  color: #fff;
  margin: 0 auto;
  margin-top: 8px;
  margin-bottom: 8px;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  gap: 8px;
}

.componentChildIdaButtonViewMore:hover {
  background-color: #185897;
}

/* styles.css */

@keyframes slideDown {
  from {
    max-height: 0;
    opacity: 0;
  }
  to {
    max-height: 500px;
    opacity: 1;
  }
}

@keyframes slideUp {
  from {
    max-height: 500px;
    opacity: 1;
  }
  to {
    max-height: 0;
    opacity: 0;
  }
}

.flightContainerOptionSelect {
  overflow: hidden;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
}

.flightContainerOptionSelect.show {
  animation-name: slideDown;
  animation-duration: 0.5s;
}

.flightContainerOptionSelect.hide {
  animation-name: slideUp;
  animation-duration: 0.5s;
}

@media screen and (max-width: 500px) {
  .flightContainerOptionSelectReponsive {
    width: 100%;
    display: flex; 
    flex-direction: column;
    gap: 10px;
  }

  .fiveColumnsContainerChild {
    padding-left: 30px;
    padding-right: 5px;
    padding-bottom: 0px;
  }

  .componentChild .bagsContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    align-items: center;
    padding-bottom: 30px;
    align-items: center; 
    padding-bottom: 30px;
  }

  .fiveButtonArrow {
    position: absolute;
    bottom: 10px;
    right: 13px;
  }

  .flightContainerOptionSelect {
    padding: 0;
  }

  .FlightInfoContentModal {
    padding: 30px 20px;
  }

  .flightContainerOptionSelectModal {
    height: 100%;
  }

  .componentBagsContainerModal {
    height: 40%;
  }

  .modal-content {
    height: 100%;
    justify-content: space-between;
  }

  .componentChildContainerModal {
    height: 100%;
  }

  .content-render-scales {
    height: 100%;
  }

  .FlightInfoContent {
    display: flex;
    flex-direction: column;
    gap: 15px;
    background-color: white;
    width: 100%; 
    overflow-y: scroll;
    border-radius: 12px;
    padding: 20px;
    position: relative;
    opacity: 1;
    transition: all 0.3s ease;
    transform: translateY(0px);
    z-index: 99999;
  }

  .FlightInfoContent.close {
    background-color: white;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    border-radius: 12px;
    padding: 20px;
    position: relative;
    opacity: 0;
    transition: all 0.3s ease;
    transform: translateY(-100px);
  }
}

@keyframes skeleton-loading {
  0% {
    background-position: 100% 0;
  }
  100% {
    background-position: -100% 0;
  }
}

.img-fligths-skeleton {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  object-fit: cover;
  background: linear-gradient(to left, #ffffff 25%, #f0f0f0 50%, #ffffff 75%);
  background-size: 600% 100%;
  animation: skeleton-loading 2s infinite linear;
  display: flex;
  justify-content: center;
  align-items: center;
}
