.carousel-wrapper {
  position: relative;
  overflow: hidden;
  width: 100%;
}

.carousel-track {
  display: flex;
  transition: transform 0.5s ease-in-out;
}

.carousel-slide {
  min-width: 100%;
  box-sizing: border-box;
}

.carousel-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  z-index: 1;
}

.carousel-button.prev {
  left: 10px;
}

.carousel-button.next {
  right: 10px;
}

/* Mobile specific styling */
@media (max-width: 600px) {
  .carousel-slide {
    min-width: 100%;
  }
}


.hotelDateCard {
  width: 485px;
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 10px 10px 29px -13px var(--shawdowPrimary);
  border: 1px solid var(--greyPrimary);
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  top: 65px;
  left: 0;
  z-index: 999;
}
.rdrCalendarWrapper {
  width: 100%;
}
.hotelDateCard_ {
  width: 100%;
  padding: 10px;
  gap: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 20;
}
.hotelDateCard_button {
  width: 90%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
}
.hotelDateCard_button button {
  border: none;
  padding: 1rem;
  font-size: 16px;
  background-color: var(--bluePrimary);
  color: var(--white);
  font-weight: 600;
  cursor: pointer;
  border-radius: 8px;
  transition: 0.3s ease-in-out;
}

@media screen and (max-width: 800px) {
  .rdrCalendarWrapper,
  .rdrDateRangeWrapper {
    width: 100%;
  }
  .hotelDateCard {
    width: 100%;
  }
}
