#payment_example_div {
  width: 100%;
  padding: 10px 0px;
}

#tokenize_example {
  display: flex;
  justify-content: center;
}

#pg_js_sdk_content {
  width: 50% !important;
  margin-bottom: 20px;
}

#response {
  display: none;
}

#retry_btn {
  display: none;
}

#payment_example_div>* {
  margin: 10 auto;
}

.tok_btn:hover {
  cursor: pointer;
}

.tok_btn:disabled,
#tok_btn[disabled] {
  opacity: .65;
  cursor: not-allowed;
}

.tok_btn {
  transition: all ease 200ms;
  background-color: var(--bluePrimary);
  border-radius: 4px;
  padding: 5px;
  min-width: 140px;
  width: 50%;
  margin: auto;
  display: flex;
  color: var(--white);
  font-size: 17px;
  font-weight: bold;
  line-height: 34px;
  justify-content: center;
  align-items: center;
}

.content-info-initial{
margin: 0 auto;
width: 95%;
padding: 1rem;
}

.camp-card-pay{
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.camp-card-pay label {
  color: rgb(69, 69, 69);
  font-size: 13px;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-weight: 600;
}

.camp-card-pay input {
  padding: .7rem;
  border: 1px solid rgb(142, 142, 142);
  border-radius: 3px;
  transition: .4s ease-in-out;
}


.camp-card-pay input:focus{
  border-color: var(--bluePrimary);
  border-width: 2px;
  transform: scale(1.04);
}

.content-info-initial .btn-pay{
  transition: all ease 200ms;
  background-color: var(--bluePrimary);
  border-radius: 4px;
  padding: 1rem 1rem;
  width: 100%;
  color: var(--white);
  font-size: 17px;
  font-weight: bold;
  margin-top: 1rem;
  text-align: center;
}

.content-info-initial .btn-pay:hover{
  cursor: pointer;
}

.title-enter-your-method{
  margin-block: 20px ;
  font-size: 18px;
  font-weight: 700;
  font-family: "Montserrat" !important;
  color: #104172;
  text-align: center;
}