.container-plan{
    text-align: center;
    width: 400px;
    height: 204px;
    box-shadow: 0px 4px 4px 0px #00000040;
    border-radius: 23px;
}

@media screen and (max-width: 1250px) {
    .container-plan{
        width: 350px
    }
}

@media screen and (max-width: 1199px) {
    .container-plan{
        width: 500px
    }
}

@media screen and (max-width: 1050px) {
    .container-plan{
        width: 400px
    }
}

@media screen and (max-width: 900px) {
    .container-plan{
        width: 600px;
    }
}

@media screen and (max-width: 650px) {
    .container-plan{
        width: 500px;
    }
}

@media screen and (max-width: 550px) {
    .container-plan{
        width: 400px;
    }
}

@media screen and (max-width: 400px) {
    .container-plan{
        width: 100%;
    }
}

.plan-name{
    background-color: #0072B9;
    height: 60px;
    border-radius: 23px 23px 0px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
}

.plan-name p{
    font-size: 24px;
    font-weight: 700;
    font-family: var(--font-family-form-plan) !important;
}

.price-passengers{
    display: flex;
    justify-content: center;
    color: #0072B9;
    margin-top: 25px;
}

.p-price-passengers{
    font-size: 24px !important;
    font-weight: 700 !important;
    line-height: 1;
}

.price-passengers p{
    font-size: 12px;
    font-weight: 400;
    margin-inline: 5px;
    font-family: "Montserrat" !important;
    display: flex;
    align-items: end;
}

.price-total p{
    color: black;
    font-size: 12px;
    font-weight: 400;
    font-family: "Montserrat" !important;
    margin-top: 5px;
}

.btn-buy-benefit{
    margin-top: 20px;
    width: 100%;
    display: flex;
    justify-content: center;
}

.btn-buy-benefit button:hover{
    box-shadow: 0px 4px 4px 0px #00000040;
    transition: 0.3s;
}

.btn-buy-benefit button:active{
    box-shadow: none;
    transition: 0.3s;
}

.btn-buy-benefit button{
    cursor: pointer;
    height: 33px;
    font-family: "Montserrat" !important;
    font-weight: 700;
    font-size: 12px;
    transition: 0.3s;
}

.btn-benefit{
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    width: 45%;
    color: #0072B9;
    margin-right: 10px;
}

.btn-buy{
    background-color: #0072B9;
    border-radius: 5px;
    color: white;
    width: 30%;
    margin-left: 10px;
}
