.contain-search-rent-car{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 4px 4px 0px #00000040;
    padding: 2rem;
    border-radius: 19px;
    justify-content: center;
}

.header-rent-car-title{
    display: flex;
    justify-content: space-between;
    color: #004274;
}

@media screen and (max-width: 800px) {
    .header-rent-car-title{
        justify-content: start;
    }
}

.content-form-search-rent-car{
    width: 100%;
    margin-top: 3rem;
    display: flex;
    flex-direction: column;
}

.camp-rent-car{
    display: flex;
    flex-direction: column;
    margin-block: 20px;
}

.camp-rent-car h2{
    font-size: 20px;
    font-weight: 700;
}

.camp-rent-car label{
    color: var(--bluePrimary);
}

.btn-search-rent-car{
    background-color: #004274;
    height: 32px;
    border-radius: 8px;
    margin-block: 2rem;
}

.input-search-rent-car{
    margin-top: 5px;
    display: flex;
    border: 1px solid #D9D9D9;
    padding-inline: 10px;
    border-radius: 8px;
    align-items: center;
    transition: .5s ease-in-out;
    cursor: pointer;
    appearance: none;
    height: 32px;
    padding-right: 25px;
    background: url('../assets/svg/IconArrowDown.svg') no-repeat;
    background-position: right 10px center;
}

.input-search-rent-car-data-picker{
    margin-top: 5px;
    display: flex;
    border: 1px solid #D9D9D9;
    padding-inline: 10px;
    border-radius: 8px;
    align-items: center;
    transition: .5s ease-in-out;
    cursor: pointer;
    height: 32px;
    padding-inline: 10px;
    align-items: center;
}

.input-search-rent-car-data-picker > div{
    margin-top: 0px !important;
    display: flex;
    align-items: center;
}


.content-selected-airport-rent{
    margin-top: 5px;
    display: flex;
    justify-content: space-between;
}

.content-selected-airport-rent > div{
    width: 49%;
}

.content-selected-airport-rent p{
    font-size: 12px !important;
    font-weight: 400 !important;
}

.selected-rent-car-airport{
    width: 50%;
    align-items: center;
    display: flex;
    justify-content: start;
}

.selected-rent-car-airport p{
    font-size: 13px;
}

.selected-rent-car-airport div {
    box-sizing: border-box;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    border: 1px solid #004274;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-right: 5px;
}

.selected-rent-car-airport span {
    width: 9px;
    height: 9px;
    border-radius: 50%;
}


.content-date-rent-car{
    display: flex;
    gap: 2%;

}

.input-date-rent{
    width: 49%;
}