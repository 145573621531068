.flight-segment-date {
  background-color: var(--blueWhiteTable);
  color: var(--bluePrimary);
  padding: 10px;
  border-radius: 9px;
  display: flex;
  justify-content: space-between;
  position: relative;
  z-index: 99;
}

.flight-segment {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 20px;
}

.flight-segment-details {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.flight-segment-details-image {
  width: 100px;
  height: 50px;
  padding: 10px;
}

.flight-segment-details-image img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.flight-times, .flight-duration {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}

.flight-times span, .flight-duration span {
  margin: 5px 0;
}




.flight-times .info{
    padding-inline: 15px;
    
}

.flight-times .info span{
    font-size: 14px;
    font-weight: 300;
}


.flight-times .info b{
    font-size: 14px;
    color: #1a3e72;
}

.flight-times .info.divider{
    border-color: var(--bluePrimary);
    border-width: 1px;
    border-right: solid;
}

.flight-times .info.divider:nth-child(2){
  display: flex;
  flex-direction: column;
  text-align: center;
}

.flight-times .info.divider:nth-child(3){
  border-color: var(--bluePrimary);
  border-width: 1px;
  border-left: solid;
  border-right: none;
}