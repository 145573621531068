.purchaseTimer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--blueWhiteTable);
    padding: 20px 50px;
    width: fit-content;
    border-radius: 8px;
}
@media (max-width: 500px) {
    .purchaseTimer {
        padding: 0px 0px;
    }
}
.purchaseTimer.expiredTime {
    background-color: var(--redSecondaryTransparent);
    border: none;
}

.timeLeft {
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: center;
    font-size: 18px;
    font-weight: 500;
    color: var(--bluePrimary);
}

.timeLeft span {
    font-size: 30px;
    font-weight: bold;
}

.expired {
    color: var(--redPrimary);
    font-weight: 500;
    font-size: 18px;
}

.alertMessage {
    font-size: 18px;
    color: var(--redPrimary);
    text-align: center;
    font-weight: 500;
}

.modalAlertTime {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    text-align: center;
}

.modalAlertTime h2 {
    font-size: 24px;
    font-weight: 700;
    color: var(--bluePrimary);
}

.modalAlertTime p {
    font-size: 16px;
    font-weight: 500;
    color: var(--bluePrimary);
}

.loadContainer {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    color: var(--bluePrimary);
}

.load {
    animation: load 1s linear infinite;
}

@keyframes load {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}