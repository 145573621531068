.container-detail-hotel {
    width: 95%;
    max-width: 1280px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.detail-hotel {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.contain-detail-hotel-reserve {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 20px;
    margin-bottom: 40px;
}

.contain-title-hotel-column {
    display: flex;
    gap: 10px;
    align-items: center;
}

.stars-div {
    display: flex;
    gap: 2px;
}

.contain-title-hotel {
    width: 100%;
    margin: 1rem auto;
    font-size: 20px;
    color: #004274;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.contain-title-hotel h1 {
    font-size: 28px;
}

.contain-title-hotel p {
    font-size: 18px;
    color: rgb(116, 116, 116);
}

.contain-detail-hotell {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    margin: 0 0 2rem 0;
}

.contain-img-principal-hotel {
    width: 100%;
    height: 100%;
    height: 400px;
    display: flex;
    justify-content: space-between;
    gap: 10px;
    cursor: pointer;
    margin-bottom: 15px;
}

.section-img-big {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    gap: 10px;
}

.section-img-1 {
    width: 50%;
    height: 100%;
    border-radius: 10px;
    overflow: hidden;
}

.section-img-2 {
    width: 50%;
    height: 100%;
    border-radius: 10px;
    overflow: hidden;
}

.section-img-layout-for {
    width: 35%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.section-img-layout-1 {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    overflow: hidden;
}

.section-img-layout-2 {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    overflow: hidden;
}

.image-hotel-detail {
    width: 100%;
    height: 100%;
    object-fit: cover;
}


@media screen and (max-width:1100px) {

    .contain-title-hotel,
    .detail-hotel-route,
    .contain-img-principal-hotel {
        width: 99%;
    }
}

@media screen and (max-width:700px) {
    .contain-title-hotel h1 {
        font-size: 20px;
    }

    .contain-title-hotel p {
        font-size: 12px;
    }
}


@media screen and (max-width:600px) {

    .contain-img-principal-hotel {
        height: 100%;
        flex-direction: column;
    }

    .section-img-big,
    .section-img-layout-for {
        width: 100%;
    }

    .section-img-1 {
        width: 50%;
        height: auto;
        border-radius: 10px;
        overflow: hidden;
    }

    .section-img-2 {
        width: 50%;
        height: auto;
        border-radius: 10px;
        overflow: hidden;
    }

    .section-img-layout-1 {
        width: 100%;
        height: auto;
        border-radius: 10px;
        overflow: hidden;
    }

    .section-img-layout-2 {
        width: 100%;
        height: auto;
        border-radius: 10px;
        overflow: hidden;
    }
}

@media screen and (max-width: 500px) {
    .contain-title-hotel-column {
        display: flex;
        flex-direction: column;
        gap: 20px;
        align-items: start;
    }
}