.content-option-tourism{
    width: 90%;
    max-width: 700px;
    margin: 0 auto;
    background-color: white;
    padding: 2rem 2rem;
    border-radius: 10px;
    z-index: 9999;
    box-shadow: 0px 0px 23px 1px rgba(0,0,0,1);
    position: relative;
}

.content-option-tourism h2{
    text-align: center;
    font-weight: 700;
    font-size: 24px;
    margin-bottom: 1rem;
    color: #17AEE6;
}

.content-option-tourism p{
    font-size: 14px;
    line-height: 20px;
    text-align: justify;
    color: #00000091;
    letter-spacing: .7px;
}

.css-79ws1d-MuiModal-root {
    display: flex;
    align-items: center;
    z-index: 9999 !important;
}