.CityQuestion {
    width: 100%;
    margin-top: -20px;
    position: absolute;
    top: 290px;
    left: 0px;
    display: flex;
    justify-content: center;
}
.backgroundImg img {
    width: 560px;
    border-radius: 20px;
    box-shadow: -4px 4px 4px rgba(0, 0, 0, 0.25);
    position: relative;
}
.li_cities {
    background-color: #004274;
    width: 125px;
    height: 70px;
    border-radius: 20px;
    padding: 2px 5px;
    list-style: none;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    transition: all 300ms cubic-bezier(.23, 1, 0.32, 1);
    touch-action: manipulation;
    will-change: transform;
}
.li_cities button {
    all: unset;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
}
.li_cities:disabled {
    pointer-events: none;
  }
  
.li_cities:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    transform: translateY(-2px);
}
  
.li_cities:active {
    box-shadow: none;
    transform: translateY(0);
}
.li_cities img {
    width: 55px;
}
.li_cities h5 {
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 700;
    margin: 2px 0;
    z-index: 0;
    
}
.li_cities p {
    font-size: 12px;
    font-weight: 700;
    opacity: .6;
    z-index: 1;
}
.li_cities_barranquilla {
    position: absolute;
    top: 0px;
    left: 430px;
}
.li_cities_bogota {
    position: absolute;
    top: 285px;
    left: 495px;
}
.li_cities_bogota .circle {
    left: 30px;
}
.li_cities_cali {
    position: absolute;
    top: 340px;
    left: 353px;
}
.li_cities_medellin {
    position: absolute;
    top: 210px;
    left: 395px;
}
.circle {
    width: 25px;
    height: 35px;
    background-color: #004274;
    border-radius: 100%;
    position: absolute;
    top: 45px;
}

@media screen and (max-width: 1000px) {
    .CityQuestion {
        top: 280px;
    }
    .li_cities_barranquilla {
        position: absolute;
        top: 0px;
        left: 280px;
    }
    .li_cities_bogota {
        position: absolute;
        top: 285px;
        left: 350px;
    }
    .li_cities_cali {
        position: absolute;
        top: 340px;
        left: 205px;
    }
    .li_cities_medellin {
        position: absolute;
        top: 210px;
        left: 245px;
    }
}
@media screen and (max-width: 700px) {
    .CityQuestion {
        top: 270px;
    }
    .li_cities_barranquilla {
        position: absolute;
        top: 0px;
        left: 180px;
    }
    .li_cities_bogota {
        position: absolute;
        top: 285px;
        left: 240px;
    }
    .li_cities_cali {
        position: absolute;
        top: 340px;
        left: 105px;
    }
    .li_cities_medellin {
        position: absolute;
        top: 210px;
        left: 145px;
    }
}