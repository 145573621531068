.homeContainer {
  background-color: #ffffff;
}

.homeSectionDoubleCardWrap .active-slide {
    width: 50% !important;
    transition: all 0.9s ease;
}

.homeSectionDoubleCardWrap .inactive-slide {
    width: 50% !important;
    transition: all 0.9s ease;
  }

.turismSaludCards .active-slide {
    width: 50% !important;
    transition: all 0.9s ease;
}

.turismSaludCards .inactive-slide {
    width: 25% !important;
    transition: all 0.9s ease;

}

.slick-track {
    display: flex !important;
}

.homeFlightSection {
  width: 80%;
  margin: -50px auto 20px auto;
  position: relative;
}

.columnContainer {
  margin-left: 120px;
  margin-top: 20px;
  width: 50%;
  background-color: #fedac0;
  padding: 10px;
}

.homeSectionDoubleCardWrap {
    width: 95%;
    max-width: 1280px;
    margin: 0 auto;
}

.homeSectionDoubleCardWrap .slick-prev:before,
.homeSectionDoubleCardWrap .slick-next:before {
    color: rgb(255, 255, 255) !important;
    font-size: 40px !important;
}


.homeSectionDoubleCardWrap .slick-next {
    right: 50px !important;
}

.homeSectionDoubleCardWrap .slick-prev {
    left: 20px !important;
}



.homeSectionDoubleCard {
  width: 95%;
  max-width: 1280px;
  margin: 0 auto;
  display: flex;
  gap: 1rem;
  justify-content: space-between;
}

.homeSectionCarrousel {
    width: 100%;
    justify-content: center;
    align-items: center;
}

.homeSectionCarrouselTitle {
  text-align: center;
  color: #104172;
  font-size: 28px;
  font-weight: bolder;
  font-family: "Montserrat", sans-serif;
}

.needInfo {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  margin: 45px 0;
}

.needInfoContain {
    width: 95%;
    max-width: 1280px;
    background-color: #ffffff;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    align-items: center;
    padding: 20px 30px;
    color: #104172;
    margin: 20px 0;
  }

  .needInfoContainLeft {
    flex-direction: column;
  }

  .needInfoContent {
    align-items: center;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
}

.turismSaludContain {
    width: 95%;
    max-width: 1280px;
    margin: 2rem auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 40px;
}

.turismSaludTitle {
    width: 100%;
    border-radius: 20px;
    padding: 5px 15px;
    text-align: center;
    font-size: 28px;
    font-weight: bold;
    color: #00AFE8;
}

.turismSaludInfo {
    width: 100%;
    display: flex;
    gap: 20px;
}

.cardTurismSaludNow {
    width: 100%;
    height: 30rem;
    background-color: #00AFE8;
    transition: all 0.5s ease;
    position: relative;
    border-radius: 2rem;
    overflow: hidden;
}

.cardTurismSaludNowImgActive {
    position: absolute;
    right: 0;
    width: 75%;
    height: 100%;
    transition: all 0.5s ease;
    clip-path: polygon(0 0, 100% 0%, 100% 100%, 24% 100%);
}

.cardTurismSaludNowImgActive img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.cardTurismSaludContentActive {
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 3rem;
    padding: 1rem;
    padding-right: 0;
    transition: all 0.5s ease;
    color: #ffffff;
    z-index: 999;
    width: 50%;
    padding-bottom: 60px;
    margin-bottom: 6rem;
}

.cardTurismSaludNowTitleActive {
    padding-left: 45px;
    font-size: 2.5rem;
    font-weight: 800;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.cardTurismSaludHeader {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-left: 45px;
    padding-bottom: 50px;
    width: 380px;
}


.cardTurismSaludHeader p{
    font-size: 16px;
    font-weight: 300;
    color: white;
}

.cardTurismSaludHeader h3{
    font-size: 24px;
    font-weight: 700;
    color: white;
}

.cardTurismSaludNowDescriptionActive {
    font-size: 1rem;
    font-weight: 200;
}

.cardTurismSaludNowSemiTitleActive {
    font-size: 1.4rem;
    font-weight: 600;
}



.cardTurismSaludNowButton {
    width: 60%;
    background-color: #104172;
    color: #ffffff;
    border-radius: 0 3rem 3rem 0;
    font-weight: 800;
    transition: all 0.5s ease;
    overflow: hidden;
    padding: 0 25px 0 15px;
}


.cardTurismSaludNowButtonContain {
    width: 100%;
    height: 80px;
    position: absolute;
    bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 999;
}


.cardTurismSaludNowButtonLink {
    display: flex;
    color: #ffffff;
    font-weight: 800;
    cursor: pointer;
    transition: all 0.5s ease;
    padding-right: 30px;
}

.cardTurismSaludNowClipBorder {
    width: fit-content;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    font-weight: 800;
    cursor: pointer;
    transition: all 0.5s ease;
    font-size: 22px;
}


.cardTurismSaludNowClipBorder {
    position: relative;
    display: inline-block;
  }
  
  .cardTurismSaludNowClipBorder::after {
    content: "";
    position: absolute;
    width: 0;
    height: 2px; /* altura del underline */
    background-color: #fff; /* color del underline */
    bottom: 0;
    left: 0;
    transition: width 0.5s ease-in-out; /* tiempo de transición */
  }
  
  .cardTurismSaludNowClipBorder:hover::after {
    width: 100%;
  }

.turismSaludCards {
    width: 100%;
    /* display: flex;
    gap: 10px; */
}

.turismSaludCards .slick-prev:before,
.turismSaludCards .slick-next:before {
    color: rgb(255, 255, 255) !important;
    font-size: 40px !important;
}


.turismSaludCards .slick-next {
    right: 50px !important;
}

.turismSaludCards .slick-prev {
    left: 20px !important;
}

.turismSaludCards .slick-track {
    margin: 0rem 0;
}

.turismSaludListButtons {
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 10px;
    list-style: none;
    max-height: 30rem;
    padding: 0 .5rem;
    overflow: auto;
    padding-bottom: 10px;
    scrollbar-width: none;
}

.turismSaludListButtons::-webkit-scrollbar {
    width: 0px;
    background: transparent;
}

.turismSaludButtonLink {
    width: 100%;
    display: flex;
    color: #104172;
    font-weight: 700;
    font-size: 14px;
    transition: all 0.5s ease;
    display: flex;
    flex-direction: column;
    gap: 5px;
    box-shadow: 0px 4px 4px 0px #00000040;
    padding: 15px 20px;
    border-radius: 20px;
}

.turismSaludButtonLink p {
    font-size: 12px;
    font-weight: 400;
}



.ofertsTurismSalud {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px 10px;
    background-color: #104172;
    transition: all 0.5s ease;
}

.ofertsTurismSaludActive {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px 10px;
    background-color: #00AFE8;
    transition: all 0.5s ease;
}

.ofertsTurismSaludContain {
    max-width: 1280px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    height: 100%;
    padding: 0 20px;
}

.ofertsTurismSaludButtons {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    padding: 20px 0;
    width: 20%;
}

.ofertsTurismSaludButtons img {
    width: 250px;
    height: 160px;
}

.ofertsTurismSaludDescription {
    padding-top: 20px;
    color: #FFFFFF;
    text-align: center;
}


.ofertsTurismSaludButton1 {
    background-color: #ffffff;
    color: #104172;
    padding: 22px;
    border-radius: 10px;
    font-weight: bold;
    font-size: 20px;
    text-align: start;
    transition: all 0.3s ease;
    border-radius: 10px;
    position: relative;
    display: flex;
    gap: 20px;
    align-items: center;
    cursor: pointer;
    width: 13rem;
    transition: all 0.5s ease;
}

.ofertsTurismSaludButton1Active {
    background-color: #104172;
    color: #ffffff;
    padding: 22px;
    border-radius: 10px;
    font-weight: bold;
    font-size: 20px;
    text-align: start;
    transition: all 0.3s ease;
    border-radius: 10px;
    position: relative;
    display: flex;
    gap: 20px;
    align-items: center;
    cursor: pointer;
    width: 13rem;
    transition: all 0.5s ease;
}

.ofertsTurismSaludButton2 {
    background-color: #00AFE8;
    color: #ffffff;
    padding: 22px;
    border-radius: 10px;
    font-weight: bold;
    font-size: 20px;
    text-align: start;
    transition: all 0.3s ease;
    border-radius: 10px;
    position: relative;
    display: flex;
    gap: 20px;
    align-items: center;
    cursor: pointer;
    width: 13rem;
    transition: all 0.5s ease;
}

.ofertsTurismSaludButton2Active {
    background-color: #ffffff;
    color: #00AFE8;
    padding: 22px;
    border-radius: 10px;
    font-weight: bold;
    font-size: 20px;
    text-align: start;
    transition: all 0.3s ease;
    border-radius: 10px;
    position: relative;
    display: flex;
    gap: 20px;
    align-items: center;
    cursor: pointer;
    width: 13rem;
    transition: all 0.5s ease;
}



.ofertsTurismSaludCircle1 {
    width: 30px;
    height: 30px;
    background-color: #FFFFFF;
    border: solid 4px #104172;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #104172;
    font-size: 40px;
    font-weight: 800;
}

.ofertsTurismSaludCircle1Active {
    background-color: #00AFE8;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 40px;
    font-weight: 800;
}



.ofertsTurismSaludCircle2 {
    width: 30px;
    height: 30px;
    background-color: #0d2e5e !important;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #104172;
    font-size: 40px;
    font-weight: 800;
}

.ofertsTurismSaludCircle2Active {
    border: solid 4px #00AFE8;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #104172;
    font-size: 40px;
    font-weight: 800;
}

.ofertsTurismSaludArrow1 {
    width: 100px;
    height: 100px;
    background-color: #104172;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    font-size: 40px;
    font-weight: 800;
    position: absolute;
    right: -82px;
    bottom: -13px;
    clip-path: polygon(0 0, 0 51%, 0 100%, 0% 100%, 0% 0%);
    transition: all 0.5s ease;
}

.ofertsTurismSaludArrow1Active {
    width: 100px;
    height: 100px;
    background-color: #ffffff;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    font-size: 40px;
    font-weight: 800;
    position: absolute;
    right: -84px;
    bottom: -13px;
    clip-path: polygon(0 0, 41% 51%, 0 100%, 0% 100%, 0% 0%);
    transition: all 0.5s ease;
}

.ofertsTurismSaludArrow2Active {
    width: 100px;
    height: 100px;
    background-color: #FFFFFF;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    font-size: 40px;
    font-weight: 800;
    position: absolute;
    right: -84px;
    bottom: -13px;
    clip-path: polygon(0 0, 41% 51%, 0 100%, 0% 100%, 0% 0%);
    transition: all 0.5s ease;
}

.ofertsTurismSaludArrow2 {
    width: 100px;
    height: 100px;
    background-color: #00AFE8;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    font-size: 40px;
    font-weight: 800;
    position: absolute;
    right: -82px;
    bottom: -13px;
    clip-path: polygon(0 0, 0 51%, 0 100%, 0% 100%, 0% 0%);
    transition: all 0.5s ease;
}


.ofertsTurismSaludCards {
    display: flex;
    gap: 20px;
    width: 100%;
    max-width: 60rem;
    height: 33rem;
    overflow: hidden;
    overflow-x: scroll;
    padding-bottom: 30px;
}

.ofertsTurismSaludCard {
    width: 100%;
    min-width: 305px;
    background-color: #ffffff;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
}

.ofertsTurismSaludCard img {
    width: 100%;
    height: 100%;
    max-height: 300px;
    object-fit: cover;
    border-radius: 20px 20px 0 0;
}

.ofertsTurismSaludCardContent {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.ofertsTurismSaludCardText {
    padding: 15px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.ofertsTurismSaludCardButtons {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.ofertsTurismSaludCardButton {
    background-color: #104172;
    color: #ffffff;
    padding: 13px;
    border-radius: 0 20px 20px 0;
    font-weight: bold;
    cursor: pointer;
    transition: all 0.3s ease;

}



.ofertsTurismSaludCardButton {
    position: relative;
    display: inline-block;
  }
  
  .ofertsTurismSaludCardButton::after {
    content: "";
    position: absolute;
    width: 0;
    height: 2px; /* altura del underline */
    background-color: #333; /* color del underline */
    bottom: 0;
    left: 0;
    transition: width 0.5s ease-in-out; /* tiempo de transición */
  }
  
  .ofertsTurismSaludCardButton:hover::after {
    width: 100%;
  }





@media screen and (max-width: 1300px) {
    .ofertsTurismSaludContain {
        max-width: 1280px;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        gap: 20px;
        height: 100%;
        padding: 0 20px;

    }

    .ofertsTurismSaludCards {
        display: flex;
        gap: 20px;
        position: relative;
        top: 0px;
        overflow: hidden;
    }
    .ofertsTurismSaludButtons{
        width: 100%;
    }
}

@media screen and (max-width: 1100px) {
    .homeFlightSection {
      width: 99%;
    }
  
    .homeSectionDoubleCard {
      width: 99%;
    }
  
    .homeSectionDoubleCardWrap .active-slide {
      width: 45% !important;
    }
  
    .homeSectionDoubleCardWrap .inactive-slide {
      width: 45% !important;
    }
  
    .turismSaludCards .active-slide {
      width: 45% !important;
    }
  
    .turismSaludCards .inactive-slide {
      width: 45% !important;
    }
  }

@media screen and (max-width: 950px) {
    .homeSectionDoubleCard {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        gap: 1rem;
    }
     .turismSaludInfo {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    .turismSaludListButtons {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        list-style: none;
        max-height: none;
    }

}

@media screen and (max-width: 700px) {
   

    .homeSectionDoubleCardWrap .active-slide {
        width: 100% !important;
      }
    
      .homeSectionDoubleCardWrap .inactive-slide {
        width: 100% !important;
      }
    
      .turismSaludCards .active-slide {
        width: 100% !important;
      }
    
      .turismSaludCards .inactive-slide {
        width: 100% !important;
      }


    .needInfoContain {
        width: 100%;
        max-width: 1280px;
        background-color: #ffffff;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 20px;
        align-items: center;
        padding: 20px 30px;
        color: #104172;
        margin: 20px 0;
    }

    .needInfoContainLeft {
        flex-direction: column;
    }

    .needInfoContent {
        align-items: center;
        justify-content: center;
        text-align: center;
    }

}




@media screen and (max-width:500px) {

    .turismSaludTitle {
        padding: 0;
        color: #00AFE8;
        background-color: #fff;
    }

    .homeSectionDoubleCardWrap .slick-next {
        right: 20px !important;
    }

    .homeSectionDoubleCardWrap .slick-prev {
        left: 0px !important;
    }



    .turismSaludCards .slick-next {
        right: 20px !important;
    }

    .turismSaludCards .slick-prev {
        left: 0px !important;
    }

    .cardTurismSaludNowTitleActive {
        font-size: 1.8rem;
    }

    .cardTurismSaludNowSemiTitleActive {
        font-size: 18px !important;
    }

    .cardTurismSaludNowClipBorder {
        font-size: 18px !important;
        text-wrap: nowrap;
    }

    .cardTurismSaludNowDescriptionActive {
        font-size: 14px !important;
    }

    .cardTurismSaludContentActive {
        padding: 0;
    }

    .cardTurismSaludHeader {
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding-left: 25px;
        padding-bottom: 0px;
        width: 380px;
    }

    .cardTurismSaludNowButtonContain {
        align-items: flex-end;
    }

    .ofertsTurismSaludContain {
        padding: 0px;
    }
}
