.best-offer-label {
  position: absolute;
  top: -90px;
  left: -16px;
  border-radius: 15px;
  height: 100%;
  width: 103%;
  background-image: url(..//../assets//best-offert.png);
  background-repeat: no-repeat;
  background-size: cover;
  font-size: 18px;
  font-weight: 600;
  color: var(--white);

}
.stars-hotel-container{
  display: flex;
  gap: 2px;
}
.best-offer-label-content {
  width: 100%;
  padding: 15px;
  padding-top: 25px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.best-offer-label h4 {
  font-size: 20px;
}

.best-offer-label p {
  font-weight: 500;
  font-size: 16px;
}

.contain-all-results-hotels-aiop {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  border-bottom: 2px solid var(--bluePrimary);
  margin-bottom: 9rem;
  padding-bottom: 4rem;
}

.contain-all-results-hotels-aiop h3{
  font-size: 20px;
  color: var(--bluePrimary);
  font-weight: bold;
  margin-bottom: 6rem;
}

.card-result-hotel {
  position: relative;
  width: 100%;
  display: flex;
  height: 100%;
  max-height: 400px;
  position: relative;
  border-radius: 10px;
  background-color: var(--white);
  border: 1px solid var(--greyPrimary);
}

.contain-porcentage-desc {
  margin-top: 5px;
  background-color: var(--bluePrimary);
  width: 90px;
  padding: 5px 5px 5px 15px;
  color: var(--white);
  clip-path: polygon(100% 0%, 75% 50%, 100% 100%, 0 100%, 0% 50%, 0 0);
  font-weight: 700;
  border-radius: 5px 0 0 5px;
}

.contain-img-hotel-result {
  width: 100%;
  max-width: 290px;
  min-width: 250px;
  position: relative;
  overflow: hidden;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.img-hotel-result {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@keyframes skeleton-loading {
  0% {
    background-position: 100% 0;
  }

  100% {
    background-position: -100% 0;
  }
}

.img-hotel-skeleton {
  width: 100%;
  height: 100%;
  min-height: 250px;
  object-fit: cover;
  background: linear-gradient(to left, var(--white) 25%, var(--greyPrimary) 50%, var(--white) 75%);
  background-size: 600% 100%;
  animation: skeleton-loading 2s infinite linear;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contain-info-result-hotels {
  width: 100%;
  display: flex;
  padding: 20px;
  background-color: var(--white);
  z-index: 99;
  border-radius: 0px 10px 10px 0px;
}

.contain-info-hotel-card {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
}

.contain-price-hotel-card {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  text-align: right;
}

.contain-price-hotel-card div ul {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.ellisisp-text {
  white-space: nowrap;
  /* Evita el salto de línea */
  overflow: hidden;
  /* Oculta el contenido que desborda */
  text-overflow: ellipsis;
}

.contain-price-hotel-card div ul li span {
  font-weight: bold;
  color: var(--bluePrimary);
  font-size: 13px;
}

.contain-price-hotel-card div ul li {
  color: var(--greySecundary);
}

.short-description-hotel {
  font-size: 12px;
  margin-top: 0.7rem;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  /* Limita a 4 líneas de texto */
  -webkit-box-orient: vertical;
  overflow: hidden;
  color: var(--blackGray);
}

.dir-hotel-card {
  margin-bottom: 1rem;
  color: var(--greySecundary);
  font-size: 12px;
}

.star-hotel-card {
  display: flex;
  align-items: center;
  gap: 6px;
}

.star-hotel-card ul {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.star-hotel-card ul li {
  color: var(--greySecundary);
}

.star-hotel-card ul li span {
  color: var(--bluePrimary);
  font-weight: 700;
  font-size: 13px;
}

.card-hotel-habitacion {
  margin-top: 2rem;
}

.card-hotel-habitacion p {
  display: flex;
  align-items: center;
  gap: 6px;
  font-size: 14px;
  color: var(--greySecundary);
}

.service-room-include {
  position: absolute;
  left: 0;
  top: 0;
  font-size: 12px;
  font-weight: 700;
  background-color: var(--bluePrimary);
  padding: 5px;
  color: var(--white);
  border-bottom-right-radius: 8px;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  gap: 5px;
  width: 100%;
  font-size: 10px;
  max-width: fit-content;
}

.price-normal-hotel-card {
  color: var(--greySecundary);
  text-decoration: line-through;
  font-size: 20px;
}

.price-descount-hotel-card {
  color: var(--bluePrimary);
  font-weight: 700;
  font-size: 25px;
  white-space: nowrap;
}

.taza-impuestos {
  color: var(--greySecundary);
  font-size: 12px;
  margin-top: 1rem;
  padding: 0 6px;
}

.dias-duracion.dias-duracion2 {
  color: var(--bluePrimary);
  font-size: 14px !important; 
  padding: 0 6px;
}

@media (max-width: 500px) {
  .dias-duracion {
      display: none;
  }
}
.dias-duracion2 {
  display: none;
}

@media (max-width: 500px) {
  .dias-duracion2 {
      display: block;
  }
}
.what-include {
  width: 100%;
  color: var(--bluePrimary);
  font-size: 14px;
}

.detail-button {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.button-detail-habitaciones {
  width: 100%;
  padding: 13px;
  background-color: var(--bluePrimary);
  border-radius: 5px;
  color: var(--white);
  font-weight: bold;
  text-align: center;
  cursor: pointer;
  transition: 0.3s ease;
}

.button-detail-habitaciones:hover {
  background-color: var(--blueTertiary);
}

@media screen and (max-width: 1000px) {
  .best-offer-label {
    position: absolute;
    top: -80px;
    left: -16px;
    border-radius: 15px;
    height: 100%;
    width: 103%;
    background-image: url(..//../assets//best-offert.png);
    background-repeat: no-repeat;
    background-size: cover;
    font-size: 18px;
    font-weight: 600;
    color: var(--white);
  
  }
  
  .best-offer-label-content {
    width: 100%;
    padding: 15px;
    padding-top: 20px;
    display: flex;
    align-items: center;
    gap: 10px;
  }
}

@media screen and (max-width: 340px) {

  .contain-info-result-hotels {
    padding: 1rem;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }
}

@media screen and (max-width: 500px) {
  .contain-info-result-hotels {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
  }

  .contain-price-hotel-card {
    justify-content: flex-start;
    align-items: start;
    text-align: start;
  }
}

@media screen and (max-width: 770px) {
  .card-result-hotel {
    flex-direction: column;
    height: 100%;
    max-height: 100%;
    overflow: visible;
  }

  .contain-img-hotel-result {
    width: 100%;
    max-width: 100%;
    max-height: 400px;
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;
    border-bottom-left-radius: 0px !important;
  }

  .service-room-include {
    width: 100%;
    font-size: 10px;
    max-width: fit-content;
  }

  .best-offer-label {
    position: absolute;
    top: -80px;
    left: -10px;
    border-radius: 15px;
    height: 100%;
    width: 102.5%;
    background-image: url(..//../assets//best-offert.png);
    background-repeat: no-repeat;
    background-size: contain;
    font-size: 18px;
    font-weight: 600;
    color: var(--white);
  
  }
  
  .best-offer-label-content {
    width: 100%;
    padding: 15px;
    padding-top: 20px;
    display: flex;
    align-items: center;
    gap: 10px;
  }
}

@media screen and (max-width: 630px) {
  .best-offer-label {
    position: absolute;
    top: -95px;
    left: 0px;
    border-radius: 15px;
    height: 100%;
    width: 100%;
    background-image: url(..//../assets//best-offert.png);
    background-repeat: no-repeat;
    background-size: contain;
    font-size: 18px;
    font-weight: 600;
    color: var(--white);
  
  }
  
  .best-offer-label-content {
    width: 100%;
    padding: 15px;
    padding-top: 20px;
    display: flex;
    align-items: center;
    gap: 10px;
  }
}

@media screen and (max-width: 450px) {
  .best-offer-label {
    position: absolute;
    top: -95px;
    left: 0px;
    border-radius: 15px;
    height: 100%;
    width: 100%;
    background-image: url(..//../assets//best-offert.png);
    background-repeat: no-repeat;
    background-size: contain;
    font-size: 18px;
    font-weight: 600;
    color: var(--white);
  
  }
  
  .best-offer-label-content {
    width: 100%;
    padding: 15px;
    padding-top: 20px;
    display: flex;
    align-items: center;
    gap: 10px;
  }
}

@media screen and (max-width: 850px) {
  .contain-porcentage-desc {
    z-index: 999;
  }

  .contain-img-hotel-result {
    width: 100%;
    overflow: hidden;
    border-top-left-radius: 10px;
    border-top-right-radius: 0px;
    border-bottom-left-radius: 10px;
  }

  .img-hotel-result {
    width: 100%;
  }

  .contain-info-result-hotels {
    padding: 1rem;
    width: 100%;
    display: flex;
    justify-content: space-around;
  }
}

@media screen and (max-width: 800px) {
  .contain-info-hotel-card h2 {
    font-size: 20px;
  }

  .contain-price-hotel-card {
    padding-top: 0;
    width: 100%;
  }

  .contain-all-results-hotels {
    width: 100%;
  }

  .price-descount-hotel-card {
    font-size: 16px;
  }

  .taza-impuestos {
    font-size: 10px;
  }
}

.fade-in {
  animation: fadeIn 0.5s forwards;
}

.fade-out {
  animation: fadeOut 0.5s forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}


