.accordionDetailFormHotel {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 20px;
    cursor: pointer;
    color: var(--bluePrimary);
    border-bottom: 1px solid var(--greyPrimary);
  }
  
  .formPersonsPayFlight .campFormPersonHotel .calendarDateFormPassenger {
    position: absolute;
    top: 60px;
    left: 0px;
    border-radius: 10px;
    padding: 1rem;
    background-color: white;
    width: fit-content;
    border: 1px solid #d9d9d9;
    max-height: 600px;
    overflow-y: scroll;
    border-radius: 12px;
    z-index: 2;
    opacity: 1;
    transition: all .5s ease;
  }
  
  .formPersonsPayFlight .campFormPersonHotel .calendarDateFormPassengerClose{
    position: absolute;
    opacity: 0;
    z-index: -2;
    transition: all .5s ease;
  }

  .formHeader {
    display: flex;
    align-items: center;
    gap: 15px;
    width: 100%;
  }

  .formTitle {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 3px;
  }

  .formTitle h3 {
    font-size: 18px;
  }
  
  .formTitle p {
    font-weight: 400;
    font-size: 14px;
  }
  
  .circleIconAccordion {
    border-radius: 50%;
    padding: 0.2rem;
    border: solid 2px white;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .formPersonsPayFlight {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 1rem;
    position: relative;
    z-index: auto;
    transition: all 0.3s ease;
    height: auto;
  }
  
  .formPersonsPayFlightClose {
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: -1;
    height: 0px;
  }
  
  .formPersonsPayFlightClose > div {
    display: none;
    flex-direction: column;
    position: relative;
    z-index: -1;
  }
  
  .formPersonsPayFlight .campFormPersonHotel select {
    background-color: transparent;
    position: relative;
    width: 100%;
    padding: 0.8rem 1rem;
    border-radius: 8px;
    border: solid 2px #d9d9d9;
  }
  
  .formPersonsPayFlight .campFormPersonHotel {
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
    position: relative;
  }
  
  .formPersonsPayFlight .campFormPersonHotel .modalCountries {
    position: absolute;
    background-color: white;
    border-radius: 5px;
    top: 50px;
    -webkit-box-shadow: 0px 10px 15px -10px rgba(0, 0, 0, 0.26);
    -moz-box-shadow: 0px 10px 15px -10px rgba(0, 0, 0, 0.26);
    box-shadow: 0px 10px 15px -10px rgba(0, 0, 0, 0.26);
    z-index: 9;
  }
  
  .formPersonsPayFlight .campFormPersonHotel .modalCountries h4 {
    background-color: var(--bluePrimary);
    color: white;
    text-align: left;
    padding: 0.6rem;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    font-size: 12px;
  }
  
  .formPersonsPayFlight .campFormPersonHotel .modalCountries ul {
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    color: var(--bluePrimary);
    max-height: 100px;
    overflow: scroll;
  }
  
  .formPersonsPayFlight .campFormPersonHotel .modalCountries ul li {
    font-size: 12px;
    color: var(--bluePrimary);
    transition: 0.3s ease;
    padding: 0.5rem;
  }
  
  .formPersonsPayFlight .campFormPersonHotel .modalCountries ul li:hover {
    background-color: #f0f0f0;
    cursor: pointer;
  }
  
  .formPersonsPayFlight .campFormPersonHotel p {
    font-size: 10px;
  
    display: flex;
    align-items: center;
  }
  
  .formPersonsPayFlight .campFormPersonHotelDoc {
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
  }
  
  .formPersonsPayFlight .campFormPersonHotelDoc label {
    color: var(--bluePrimary);
    font-size: 13px;
    font-weight: bold;
  }
  
  .formPersonsPayFlight .campFormPersonHotelDoc p {
    font-size: 10px;
  
    display: flex;
    align-items: center;
  }
  
  .containInputsSelecteds {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
  }
  
  .formPersonsPayFlight .campFormPersonHotelDoc .containInputsSelecteds input {
    width: 100%;
    padding: 0.8rem 1rem;
    border-radius: 8px;
    border: solid 1px #d9d9d9;
  }
  
  .formPersonsPayFlight .campFormPersonHotelDoc .containInputsSelecteds .selectTypeDoc {
    background-color: transparent;
    position: relative;
    width: 29%;
    padding: 0.8rem 1rem;
    border-radius: 8px;
    border: solid 1px #d9d9d9;
    height: 44px;
  }
  
  .formPersonsPayHotel .campFormPersonHotelDoc .containInputsSelecteds .selectTypeDoc .modalSelectTypeDoc {
    width: 290px;
    position: absolute;
    top: 48px;
    left: 0px;
    background-color: white;
    padding: 15px;
    border-radius: 8px;
    border: 1px solid #d9d9d9;
  }
  
  .formPersonsPayHotel .campFormPersonHotelDoc .containInputsSelecteds .selectTypeDoc .modalSelectTypeDoc ul {
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  
  .formPersonsPayHotel .campFormPersonHotelDoc .containInputsSelecteds .selectTypeDoc .modalSelectTypeDoc ul li {
    user-select: none;
    color: #676767;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .formPersonsPayHotel .campFormPersonHotelDoc .containInputsSelecteds .selectTypeDoc .modalSelectTypeDoc ul li:hover {
    color: #3c3c3c;
  }
  
  .formPersonsPayFlight .campFormPersonHotelDoc .containInputsSelecteds .selectTypeDoc .selectOnclickModalType {
    align-items: center;
    display: flex;
    width: 100%;
    justify-content: space-between;
    cursor: pointer;
    height: 100%;
  }
  
  .error-birth input {
    border-color: #ffb7b7 !important;
  }
  
  .formPersonsPayFlight .campFormPersonHotelDoc .containInputsSelecteds .selectTypeDoc .selectOnclickModalType span {
    font-size: 11px;
    font-weight: 400;
    color: #333;
  }
  
  .formPersonsPayFlight .campFormPersonHotel label {
    color: var(--bluePrimary);
    font-size: 12px;
    font-weight: bold;
  }
  
  .formPersonsPayFlight .campFormPersonHotel input {
    padding: 0.8rem 1rem;
    border-radius: 8px;
    border: solid 1px #d9d9d9;
  }
  
  .formPersonsPayFlight .campFormPersonHotel p {
    font-size: 10px;
  
    display: flex;
    align-items: center;
  }
  
  .btnSaveInfoPassenger {
    margin-top: 10px;
    width: fit-content;
    background-color: #004274;
    padding: 0.6rem 3rem;
    border-radius: 5px;
    font-weight: 400;
    font-size: 14px;
    cursor: pointer;
    transition: 0.3s ease;
    color: #fff;
  }
  
  .btnSaveInfoPassenger:hover {
    background-color: #024d87;
  }
  
  .errHotelFormPerson {
    width: 100%;
    background-color: rgb(218, 115, 115);
    padding: 0.8rem;
    border-radius: 5px;
    color: #333;
    border: 1px solid #ff00001d;
    font-weight: 400;
    font-size: 14px;
    text-align: center;
  }
  
  .selectTypeDoc {
    position: relative;
  }
  
  .modalSelectTypeDocFlight {
    width: 290px;
    position: absolute;
    top: 48px;
    left: 0px;
    background-color: white;
    padding: 15px;
    border-radius: 8px;
    border: 1px solid #d9d9d9;
    z-index: 10;
  }
  
  .modalSelectTypeDocFlight ul {
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  
  .modalSelectTypeDocFlight li {
    user-select: none;
    color: #676767;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .modalSelectTypeDocFlight li:hover {
    color: #3c3c3c;
  }