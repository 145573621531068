.flightDetailFilter {
  width: 20%;
}
.cardContainerShadow {
  border-radius: 25px;
  background-color: white;
  display: block;
  flex-direction: column;
  box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.5) !important;
  margin-bottom: 30px;
  padding: 15px;
}
.cardContainerBorder {
  padding: 10px;
  border-radius: 8px;
  background-color: #ffffff;
  border: 1px solid #676465;
  margin: 8px 0px;
}
.row {
  align-items: center;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
}
.column {
  display: block;
  justify-content: space-between;
}
.textTitleBig {
  font-size: 20px;
  font-weight: bold;
  color: #104172;
  font-family: "Montserrat", sans-serif;
}
.textTitle {
  font-size: 16px;
  font-weight: bold;
  color: #104172;
  font-family: "Montserrat", sans-serif;
}
.textSimpleBlue {
  font-size: 16px;
  color: #104172;
  font-family: "Montserrat", sans-serif;
}
.textSimpleGray {
  font-size: 16px;
  color: #676465;
  font-family: "Montserrat", sans-serif;
}

.textSmallBlue {
  font-size: 11px;
  font-weight: 400;
  color: #104172;
  font-family: "Montserrat", sans-serif;
}
.textSmallGray {
  font-size: 11px;
  font-weight: 400;
  color: #676465;
  font-family: "Montserrat", sans-serif;
}

.btnSearch {
  display: flex;
  height: 40px;
  justify-content: center;
  align-items: center;
}

.optionWrapper {
  margin-top: 8px;
  background-color: #ededed;
}

.optionList {
  cursor: pointer;
  padding: 5px;
}

.optionList:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.containerValue {
  border-radius: 5px;
  overflow: hidden;
  min-width: 40px;
  height: "100%";
  max-width: 80px;
  padding: 5px;
  border: 1px solid #676465;
}

.displaycolumn {
  display: block;
  overflow: hidden;
}
.displayRow {
  display: flex;
  justify-content: space-between;
  overflow: hidden;
}
.displayWrap {
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
}

.optionCheck {
  cursor: pointer;
  margin-right: 20px;
  display: flex;
  padding-bottom: 10px;
}

/*range */
.inputRange {
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.slider {
  position: relative;
  width: 100%;
}

.slider__track,
.slider__range,
.slider__left-value,
.slider__right-value {
  position: absolute;
}

.slider__track,
.slider__range {
  border-radius: 3px;
  height: 3px;
}

.slider__track {
  background-color: #688fab;
  width: 100%;
  z-index: 1;
}

.slider__range {
  background-color: #9fe5e1;
  z-index: 2;
}

.slider__left-value,
.slider__right-value {
  color: #104172;
  font-size: 12px;
  margin-top: 20px;
  font-family: "Montserrat", sans-serif;
}

.slider__left-value {
  left: 6px;
}

_right-value {
  right: -4px;
}

/* Removing the default appearance */
.thumb,
.thumb::-webkit-slider-thumb {
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
}

.thumb {
  pointer-events: none;
  position: absolute;
  height: 0;
  width: 200px;
  outline: none;
}

.thumb--left {
  z-index: 3;
}

.thumb--right {
  z-index: 4;
}

/* For Chrome browsers */
.thumb::-webkit-slider-thumb {
  background-color: #104172;
  border: none;
  border-radius: 50%;
  box-shadow: 0 0 1px 1px #104172;
  cursor: pointer;
  height: 18px;
  width: 18px;
  margin-top: 4px;
  pointer-events: all;
  position: relative;
}

/* For Firefox browsers */
.thumb::-moz-range-thumb {
  background-color: #f1f5f7;
  border: none;
  border-radius: 50%;
  box-shadow: 0 0 1px 1px #ced4da;
  cursor: pointer;
  height: 18px;
  width: 18px;
  margin-top: 4px;
  pointer-events: all;
  position: relative;
}
