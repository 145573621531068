.containerFlight .cardFather {
  overflow: hidden;
  background-color: #FFFFFF;
  border: 1px solid #D9D9D9;
  display: flex;
  border-radius: 10px;
}

.bestOfferFlight {
  width: fit-content;
  background-color: var(--blueTertiary);
  padding: 5px 15px;
  border-radius: 8px;
  color: var(--white);
  margin: 10px 0px 20px 30px;
  font-weight: 600;
}
@media (max-width: 500px) {
  .bestOfferFlight {
      width: 100%;
      border-radius: 0;
      margin: 0 !important;
  }
}
.cardFather .column {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.cardFather .row {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.containerFlightsSelects {
  display: flex;
  width: 100%;
}

@media screen and (max-width: 600px) {
  .containerFlightsSelects {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
}

.containerFlightsSelectsColumns {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.sectionConfigFlight {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  gap: 20px;
}



@media screen and (max-width: 600px) {
  .sectionConfigFlight {
    display: flex; 
    align-items: center;
    justify-content: space-between;
    padding: 20px 0px;
    gap: 20px;
    border-top: 1px solid #D9D9D9;
    border-bottom: 1px solid #D9D9D9;
  }
}


.btnConfigFlight {
  color: white;
  background-color: #104172;
  border-radius: 8px;
  padding: 10px 20px;
  transition: all 0.3s ease;
  cursor: pointer;
  font-size: 14px;
  font-weight: bolder;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btnConfigFlight:hover {
  background-color: #195fa6;
}