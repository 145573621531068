.collapsibleContain {
    display: flex; 
    gap: 20px;
    align-items: flex-start;  
    width: 100%;
    padding-top: 20px;
    margin-bottom: 20px;
    border-top: 2px solid var(--bluePrimary);
    cursor: pointer;
}

.collapsibleContain.noneBorder {
    border-top: 0px solid;
    padding: 0px;
    margin-bottom: 5px;
}

.collapsibleHeader {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.collapsibleHeader h4 {
    font-size: 18px;
    font-weight: 600;
    color: var(--bluePrimary);
}

.collapsibleHeader p {
    font-size: 15px;
    font-weight: 500;
    color: var(--bluePrimary);
}

.collapsible {
    margin-bottom: 16px;
}

.buttonArrowOriginal {
    display: grid;
    place-items: center;
    cursor: pointer;
    color: var(--bluePrimary);
    transition: all 0.3s ease;
}

.buttonArrowOriginal.buttonArrowOpenOriginal {
    transform: rotate(90deg);
}

.content {
    max-height: 0; 
    transition: max-height 0.4s ease-in-out, opacity 0.4s ease-in-out;
    opacity: 0;
}

.open {
    max-height: 100%;
    /* Ajusta según el contenido */
    opacity: 1;
}