.contain-buttons-filters-pharmacy {
    max-width: 1280px;
    width: 100%;
    margin: 3rem auto;
    display: flex;
    justify-content: space-between;
}

.find-name-pharmacy {
    -webkit-box-shadow: 8px 10px 35px -24px rgba(0,0,0,0.75);
    -moz-box-shadow: 8px 10px 35px -24px rgba(0,0,0,0.75);
    box-shadow: 8px 10px 35px -24px rgba(0,0,0,0.75);
    width: 60%;
    border-radius: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    cursor: pointer;
    position: relative;
}

.layout-find-pharmacy-b-title{
    font-weight: 700;
    color: #004274;
}

.layout-find-pharmacy-b-p{
    font-weight: 200;
    color: #004274;
}

.find-peronalizer-pharmacy {
    width: 30%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    -webkit-box-shadow: 8px 10px 35px -24px rgba(0,0,0,0.75);
    -moz-box-shadow: 8px 10px 35px -24px rgba(0,0,0,0.75);
    box-shadow: 8px 10px 35px -24px rgba(0,0,0,0.75);
    border-radius: 20px;
    cursor: pointer;
    position: relative;
}



.p-pharmacy {
    height: fit-content !important;
}

.lyt-pharmacy {
    align-items: center;
}

.pharmacy-price {
    font-size: 12px !important;

}

.img-pharmacy {
    -webkit-box-shadow: 0px 0px 5px -3px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 5px -3px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 5px -3px rgba(0, 0, 0, 0.75);
}

@media screen and (max-width:1100px){
    .contain-buttons-filters-pharmacy{
        width: 90%;
    }
}

@media screen and (max-width:1000px) {
    .contain-buttons-filters-pharmacy{
        flex-direction: column;
        gap: 1rem;
    }

    .find-name-pharmacy, .find-peronalizer-pharmacy{
        width: 100%;
    }
}