.container {
    padding-left: 3px;
    display: flex;
    flex-direction: row;   
    gap: 25px;
    margin-block: 20px;
    align-items: center;
    justify-content: space-between;
    overflow-x: scroll;
    width: 100%;
    transform: skew(15deg);
    scrollbar-width: none;
}

.container::-webkit-scrollbar {
    width: 0px;
    background: transparent;
}