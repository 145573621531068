.content-form-passenger{
    padding: 1rem;
    background-color: white;
    border-radius: 8px;
    border: 1px solid rgb(215, 215, 215);
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.content-form-passenger h2{
    font-weight: 600;
    color: rgb(50, 50, 50);
}