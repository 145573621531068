.cotainedFamilies-selected {
    position: relative;
}

.bodyScrollFamilies {
    width: 100%;
    max-height: 390px;
    border-radius: 20px;
    background-color: rgb(255, 255, 255);
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 35%) !important;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
}

.bodyScrollFamilies-item {
    padding-top: 5px;
    padding-bottom: 5px;
    overflow: hidden;
    overflow-y: auto;
    cursor: pointer;
    width: 100%;
    text-align: center;
}

.bodyScrollFamilies-item:hover {
    background-color: #EDEDED;
    width: 100%;
}