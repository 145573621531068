.layout_specialist {
    display: flex;
    justify-content: space-between;
    width: 95%;
    max-width: 1280px;
    margin: 0rem auto;
    margin-top: 2rem;
    gap: 1rem;
}

.layout_specialist_step {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 1280px;
    margin: 2rem 0;
    gap: 1rem;
}

.filters {
    width: 200px;
    min-width: 160px;
    max-width: 200px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    transition: all .5s ease;
}

.results {
    width: 100%;
}

.filterBtn {
    display: none;
}

.filterBtn button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    background-color: var(--bluePrimary);
    color: var(--white);
    padding: 13px 20px;
    border-radius: 8px;
    cursor: pointer;
    text-align: center;
    font-weight: 600;
    font-size: 16px;
    transition: all 0.3s ease;
}

@media screen and (max-width:1000px) {
    .layout_specialist {
        flex-direction: column;
        gap: 1rem;
    }

    .filters {
        display: none;
    }

    .filterBtn {
        width: 100%;
        display: block;
        transition: all 0.5s ease;
    }

    .filterBtn button {
        width: 100%;
    }
}

@media screen and (max-width: 1000px) {

    .layout_specialist_step {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
        max-width: 1280px;
        margin: 2rem 0;
        gap: 1rem;
    }
}









.contain_filters{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.contain_filters h2{
    font-size: 16px;
    font-weight: 700;
    color: var(--bluePrimary);
}

.contain_filters .line{
    height: 1px;
    width:100%;
    background-color: var(--greyPrimary);
}


.contain_filters .filters{
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: .6rem;
    border-bottom: 1px solid var(--greyPrimary);
    padding-bottom: .6rem;
}

.contain_filters .filters span{
    font-size: 12px;
    color: var(--bluePrimary);
}

.contain_filters .filters select{
    border-radius: 6px;
    border: 1px solid var(--greyPrimary);
    padding: .5rem;
    font-size: 12px;
    appearance: none;
    background: url(https://cdn-icons-png.freepik.com/512/10017/10017916.png);
    background-repeat: no-repeat;
    background-size: contain;
    cursor: pointer;
    background-position: right;
}
.contentFilter {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.inputClose {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
    border: 1px solid var(--greyPrimary);
    border-radius: 8px;
    padding: .5rem;
    font-size: 12px;
}

.inputClose input {
    width: 100%;
    border: none;
    outline: none;
}
.closeButton {
    cursor: pointer;
    display: grid;
    place-items: center;
}
.buttonSearch {
    background-color: var(--bluePrimary);
    color: var(--white);
    padding: 8px;
    border-radius: 8px;
    font-size: 14px;
    cursor: pointer;
    transition: all 0.3s ease;
}

.buttonSearch:hover {
    background-color: var(--blueTertiary);
}