

.borderChekout{ 
    box-shadow: -4px 4px 4px rgba(0, 0, 0, 0.25) !important;
    border-radius: 20px;
    width: 80em;
}
.containerChekout_{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 11% 0px 11% 0px;
}
.borderChekoutStep{
    box-shadow: -4px 4px 4px rgba(0, 0, 0, 0.25) !important;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}
.tittleChekout{
    display: flex;
    justify-content: flex-start;
    margin: 20px;
}
.containerStep{
    margin-left: 10px;
}

.buttonsChekout{
    display: flex;
    justify-content: flex-end;
    margin: 0px 0px 16px 0px;
}
.buttonsChekout .nextButton{
    background-color:#004274;
    color: white;
    font-weight: 600;
}


@media screen and (max-width: 1450px) {
    .borderChekout{ 
        width: 60em;
    }

}

@media screen and (max-width: 1100px) {
    .borderChekout{ 
        width: 50em;
    }
    .itemsSteps{
  
        display: grid !important;
       grid-template-columns: 25% 8.33% 25% 8.33% 25% 8.33%  !important;
    justify-content: center !important;
      

    }
    .itemStepContent{
   
     margin-bottom: 10px;
    }
}

@media screen and (max-width: 900px) {
    .borderChekout{ 
        width: 40em;
    }
}

@media screen and (max-width: 700px) {
    .borderChekout{ 
        width: 30em;
    }
}

@media screen and (max-width: 500px) {
    .borderChekout{ 
        width: auto;
    }
    .itemsStepsLittle{

        display: grid !important;
       grid-template-columns: 30% 20% 30% 20%  !important;
    justify-content: center !important;
    }
}


