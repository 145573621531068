.flight-searchDestiny_input input {
  border: none;
  font-size: 13px;
  color: var(--bluePrimary);
  text-transform: capitalize;
  width: 100%;
  font-weight: 500;
}

.flight-searchDestiny_input input::placeholder {
  font-weight: 400;
}

.flight-search-cities-list {
  background-color: rgb(255, 255, 255);
  position: absolute;
  z-index: 9999;
  top: 50px; 
  overflow-y: scroll;
  left: 0px;
  width: 100%;
  -webkit-box-shadow: 0px 8px 11px -3px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0px 8px 11px -3px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 8px 11px -3px rgba(0, 0, 0, 0.5);
  scrollbar-width: none;
}

.flight-search-cities-list::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}

.header-list-cities {
  background-color: rgb(177, 177, 177);
  padding: 0.5rem;
}

.header-list-cities p {
  font-size: 13px;
}

.content-result-cities-flight {
  max-height: 205px;
  overflow: scroll;
  display: flex;
  flex-direction: column;
  scrollbar-width: none;
}

.content-result-cities-flight::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}

.cityData-result .city-data {
  width: 100%;
}

.sub-airports-city {
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
}

.sub-airports-city div {
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: 0.5s ease;
}

.sub-airports-city div:hover {
  transform: scale(1.03);
  background-color: rgb(216, 216, 216);
  padding: 0.1rem;
  border-radius: 5px;
}

.sub-airports-city div img {
  width: 15px;
}

.sub-airports-city div p {
  font-size: 12px;
  color: rgb(65, 65, 65);
}

.cityData-result .city-data .city-data-text {
  display: flex;
  flex-direction: column;
  padding: 0.6rem 0.5rem;
  transition: 0.5s ease-in-out;
  cursor: pointer;
  gap: 0.3rem;
}

.cityData-result .city-data .city-data-text:hover {
  background-color: rgb(216, 216, 216);
}

.cityData-result .city-data .city-data-text .city-country p {
  font-size: 11px;
  color: var(--bluePrimary);
  height: 13px;
  font-weight: bold;
  text-transform: capitalize;
  overflow-x: hidden;
  scrollbar-width: none;
}

.cityData-result .city-data .city-data-text .city-country p::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}

.cityData-result .city-data .city-data-text .city-iata p {
  font-size: 10px;
  font-weight: bold;
  text-transform: capitalize;
}

.cityData-result .city-data .city-data-text .city-country {
  width: 80%;
}

.cityData-result .city-data .city-data-text .city-iata {
  width: 20%;
}
