.carousel-type-pack{
    background-color: var(--bluePrimary);
    width: 100%;
    margin: 15rem 0;
}

.carousel-type-pack .content-width{
    padding: 2rem 1rem;
    width: 100%;
    max-width: 1280px;
    margin: 0 auto;
    display: flex; 
    align-items: center;
    gap: 1rem;
    justify-content: space-between;
    flex-wrap: nowrap;
}


.carousel-type-pack .content-width .letters-carrousel-type h2{
    font-weight: 900;
    font-size: 48px;
    display: flex;
    color: white;
    flex-direction: column;
    text-transform: uppercase;
}

.carousel-type-pack .content-width .letters-carrousel-type{
    display: flex;
    flex-direction: column;
    gap: 3rem;
}


.carousel-type-pack .content-width .letters-carrousel-type img{
    margin-left: -100px;
}

.layout-type-packages-result{
display: flex;
flex-direction: column;
justify-content: space-between;
height: 100%;
}


@media screen and (max-width:1320px) {
    .carousel-type-pack .content-width{
        flex-direction: column;
    }

    .carousel-type-pack .content-width .letters-carrousel-type {
        flex-direction: column-reverse;
        align-items: center;
        gap: 1rem;
    }
    .carousel-type-pack .content-width .letters-carrousel-type img{
        margin-left: 0px;
        width: 150px;
    }

    .carousel-type-pack .content-width .letters-carrousel-type h2{
        flex-direction: row;
        align-items: center;
        gap: 10px;
    }
}

@media screen and (max-width:700px) {
    .carousel-type-pack .content-width .letters-carrousel-type h2{
        flex-direction: column;
        align-items: center;
        gap: 10px;
    }
}



@media screen and (max-width:600px) {
    .pagination-packages{
        left: 15%  !important;
    }
}
