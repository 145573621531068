.autocomplete-origin-terrawind{
    margin-top: 10px;
    font-size: 12px;
    font-weight: 400;
    font-family: "Montserrat" !important;
    background: url("../../../../ui/assets/iconsSVG/ArrowDown2.svg") no-repeat;
    background-position: right 10px center;
}

.select-origin-container > div > div > div{
    height: 32px;
}


.select-origin-container input{
    margin-top: 10px;
    font-size: 12px;
    font-weight: 400;
    font-family: "Montserrat" !important;
    transform: translateY(-60%);
}

.list-origin{
    margin-top: 10px;
    font-size: 12px;
    font-weight: 400;
    font-family: "Montserrat" !important;
}

.arrow-down-origin svg{
    transform: translate(300%, -150%);
}

.input-validate-origin > div > div{
    border: 1px solid red;
}

.loading-modal-terrawind-plan{
    background-color: white;
    width: 300px;
    height: 200px;
    border-radius: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 4px 4px 0px #00000040;
}