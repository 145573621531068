.flightSelectedDetail {
    width: 100%;
    max-width: 1280px;
    display: flex;
    gap: 2rem;
    margin: 0 auto;
    justify-content: space-between;
    min-height: 70vh;
}

@media screen and (max-width: 1350px) {
  .flightSelectedDetail {
    flex-direction: column;
    width: 95%;
  }
}

@media screen and (max-width: 1100px) {
    .flightSelectedDetail {
      flex-direction: column;
    }
}