.bigContainer.myTravels .profileScreen_ContainerSideCenter {
  width: 68% !important;
}

.bigContainer.myTravels .profileScreen_ContainerSideRight {
  width: 30% !important;
}


/* ------------------- init ------------------- */

.glocalCardTravel {
  -webkit-box-shadow: 10px 10px 29px -13px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 29px -13px rgba(0, 0, 0, 0.75);
  box-shadow: 10px 10px 29px -13px rgba(0, 0, 0, 0.75);
  overflow: hidden;
  border-radius: 12px;
  background-color: var(--white);
  margin-bottom: 30px;
}


.glocalCardTravel .cardContainer {
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* background-color: pink; */
}

.glocalCardTravel .cardContainer .cardContainerBanner {
  overflow: hidden;
  border-radius: 10px;
  width: 75%;
  height: auto;
  max-height: 220px;
  margin: 0px auto;
}

.glocalCardTravel .cardContainer .cardContainerBanner img {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover
}


.glocalCardTravel .cardContainer .cardContainerInfo {
  width: 100%;
  margin-left: 10px;
}

.glocalCardTravel .cardContainer .cardContainerInfo.travel {
  width: 100%;
}

.glocalCardTravel .cardContainerInfo .CMT_header {
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
}

.glocalCardTravel .cardContainerInfo .CMT_header .CMT_headerNumber {
  display: flex;
  justify-content: end;
  flex-direction: row;
  align-items: end;
}

.glocalCardTravel .cardContainerInfo .CMT_header .superTitleUpper {
  color: var(--bluePrimary);
  font-size: 28px;
  font-weight: bolder;
  font-family: 'Montserrat', sans-serif;
}

.glocalCardTravel .cardContainerInfo .CMT_header .superTitleLower {
  color: var(--bluePrimary);
  font-size: 20px;
  font-family: 'Montserrat', sans-serif;
  font-weight: bolder;
  margin-bottom: 2px;
}

.glocalCardTravel .cardContainerInfo .CMT_header .title {
  color: var(--bluePrimary);
  font-size: 20px;
  font-weight: bolder;
  font-family: 'Montserrat', sans-serif;
}

.glocalCardTravel .cardContainerInfo .CMT_header .subtitle {
  color: var(--black);
  font-size: 14px;
  font-family: 'Montserrat', sans-serif;
}

.glocalCardTravel .cardContainerInfo .CMT_header .boldface {
  color: var(--black);
  font-size: 14px;
  font-weight: bolder;
  font-family: 'Montserrat', sans-serif;
}

.glocalCardTravel .cardContainerInfo .CMT_header .CMT_header_link{
  color: var(--black);
  font-size: 12px;
  font-weight: bolder;
  font-family: 'Montserrat', sans-serif;
  cursor: pointer;
  width: fit-content;
  padding: 5px;
  transition: all ease 200ms;
}

.CMT_header_link:hover{
  background-color: var(--bluePrimary);
  color: var(--white) !important;
  border-radius: 12px;
  transition: all ease 200ms;
}

.glocalCardTravel .cardContainerInfo .CMT_body {
  /* */
  width: 100%;
}

.glocalCardTravel .cardContainerInfo .CMT_body .CMT_description {
  font-size: 10px;
  font-weight: 400;
  font-family: 'Montserrat', sans-serif;
  margin-top: 10px;
  margin-bottom: 10px; 
}

.glocalCardTravel .cardContainerInfo .CMT_footer {
  width: 100%;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.glocalCardTravel .cardContainerInfo .CMT_footer .CMT_btn {
  background-color: var(--bluePrimary);
  width: fit-content;
  min-width: 180px;
  padding: 5px 20px;
  border-radius: 12px;
  cursor: pointer;
  transition: all ease 200ms;
}

.glocalCardTravel .cardContainerInfo .CMT_footer .CMT_btn:hover {
  transform: translateY(-4px);
  transition: all ease 200ms;
  -webkit-box-shadow: 0px 10px 10px -5px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 10px 10px -5px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 10px 10px -5px rgba(0, 0, 0, 0.75);
}

.glocalCardTravel .cardContainerInfo .CMT_footer .CMT_btn:active {
  scale: 0.95;
  transition: all ease 200ms;
}

.CMT_bodyColumn {
  display: flex;
  flex-direction: column;
  /* background: pink; */
  padding: 5px;
}

.glocalCardTravel .cardContainerInfo .CMT_footer .CMT_btnText {
  color: var(--white);
  font-family: 'Montserrat', sans-serif;
  font-size: 12px;
  font-weight: 400;
  text-align: center;
}

.ComponentColumnWrapper {
  display: flex;
  flex-direction: row;
  background-color: var(--white);
  justify-content: space-between;
  width: 100%
}


.ComponentColumn {
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  padding: 5px 0px;
}

.ComponentColumn .ComponentColumnLabel {
  font-size: 12px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  color: var(--black);
  text-align: center;
}

.ComponentColumn .ComponentColumnValue {
  font-size: 12px;
  font-family: 'Montserrat', sans-serif;
  font-weight: bolder;
  color: var(--bluePrimary);
  text-align: center;
}


.onlyTravelRowJoin {
  width: 100%;
  display: flex;
  flex-direction: row;
}
.onlyTravelRowJoinIcon{
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.onlyTravelRow {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}



.containerAirlineImg {
  width: 100%;
  height: 30px;
  margin-bottom: 10px;
  margin-left: 10px;
}

.containerAirlineImgLogo {
  object-fit: contain;
  width: auto;
  height: 100%;
}

.componentDetailFlight {
  background-color: var(--bluePrimary);
  width: 100%;
  border-radius: 12px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;
  padding: 5px 20px;
  align-items: center;
}

.componentDetailFlightInfo {
  color: var(--white);
  font-family: 'Montserrat', sans-serif;
  font-size: 12px;
}

.componentDetailFlightInfoType {
  font-weight: 400;
}

.componentDetailFlightInfoDate {
  font-weight: bolder;
}

.componentDetailFlightBags {
  display: flex;
  flex-direction: row;
}



.ComponentRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 2px;
}

.ComponentRowIcon {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
}

.ComponentRowText {
  color: var(--black);
  font-family: 'Montserrat', sans-serif;
  font-size: 12px;
  font-weight: 400;
}