.hotelSearchCard {
    z-index: 999;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.hotelSearchCard_ {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    border-radius: 10px 10px 0px 0px;
}
.hotelSearchCard_input {
    width: 100%;
    padding: 10px;
    padding-bottom: 0px;
    display: flex;
    flex-direction: column;
    gap: 2px;
    background-color: var(--white);
}
.hotelSearchCard_input input {
    width: 100%;
    border: none;
    font-family: 'Inter';
    font-size: 16px;
    text-transform: capitalize;
}
.hotelSearchCard_elements {
    margin-top: 5px;
    box-shadow: 10px 10px 29px -13px rgba(0, 0, 0, 0.141);
    background-color: var(--white);
    width: 100%;
    overflow: hidden;
    border-radius: 10px;
    border: 1px solid #D9D9D9;
}
.hotelSearchCard_element {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: relative;
}
.hotelSearchCard_element button {
    width: 100%;
    height: 100%;
    border: none;
    background-color: rgba(0, 0, 0, 0);
    font-family: 'Inter';
    color: black;
    font-family: 14px;
    font-weight: 400;
    cursor: pointer;
    text-align: left;
    padding: 0px 10px;
    transition: all .3s ease;
}
.hotelSearchCard_element:hover {
    background-color: rgba(0, 66, 116, .1);
}







.hotelSearchNew {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 0px 7px;
    width: 100%;
    height: 100%;
    background-color: var(--white);
    border-radius: 10px;
}

.hotelSearchNew2 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 10px;
    padding-top: 2px;
    padding-bottom: 2px;
    width: 100%;
    background-color: var(--white);
    border-radius: 10px;
    height: 100%;
    gap: 2px;
}

.hotelSearchNew input {
    width: 100%;
    border: none;
    font-family: 'Inter';
    font-size: 14px;
    text-transform: capitalize;
}

.hotelSearchNew2 input {
    width: 100%;
    border: none;
    font-family: 'Inter';
    font-size: 16px;
    text-transform: capitalize;
}