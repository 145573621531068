.anyQuestionsContainer {
  width: 100%;
  background-color: #ededed;
  padding: 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.anyQuestions {
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;
  padding: 20px;
}

.anyQuestionsTitle {
  color: #104172;
  text-align: center;
  font-size: 22px;
  font-weight: bolder;
  font-family: "Montserrat", sans-serif;
}

.anyQuestionsOptionsList {
  padding: 0px 10%;
  display: flex;
  justify-content: space-between;
}

.anyQuestionsOption {
  position: relative;
  width: 80px;
  border-radius: 12px;
  align-items: center;
  justify-content: start;
  display: flex;
  flex-direction: column;
}

.anyQuestionsOptionIcon {
  width: 70px;
  height: 70px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.anyQuestionsOptionText {
  margin-top: 5px;
  color: #000000;
  text-align: center;
  font-size: 10px;
  font-weight: 400;
  font-family: "Montserrat", sans-serif;
}

/* Styles.css */

.carousel-inner-max {
  max-width: 800px;
  overflow: hidden;
  width: 100%;
}

.carousel-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  max-width: 100%; /* Ajusta según sea necesario */
}

.carousel-inner {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  width: 100%;
  transition: transform 0.5s ease;
}

.carousel-item-footer {
  flex: 0 0 auto;
  width: 25%;
  box-sizing: border-box;
}

@keyframes slide {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

/* Animación solo para dispositivos móviles */
@media screen and (max-width: 600px) {
  .carousel-inner {
    animation: slide 10s linear infinite;
    width: calc(
      100% * 2
    ); /* Asegura que haya suficiente ancho para la animación */
  }
}

/* Layout de flex para dispositivos de escritorio */
@media screen and (min-width: 601px) {
  .carousel-inner {
    animation: none; /* Desactiva la animación en dispositivos de escritorio */
    width: 100%;
  }

  .carousel-item-footer {
    width: auto;
  }
}

@media screen and (max-width: 1100px) {
  .anyQuestions {
    width: 99%;
  }

  .anyQuestionsOptionsList {
    margin: auto;
    width: 80%;
    padding: 0;
  }
}

@media screen and (max-width: 600px) {
  .anyQuestionsOptionsList {
    margin: auto;
    width: 100%;
    padding: 0 10px;
  }
}

@media screen and (max-width: 500px) {

  .anyQuestionsOptionsList {
    flex-wrap: wrap;
  }


    .anyQuestionsOptionsList {
        display: flex;
        gap: 50px;
        overflow-x: scroll;
    }

  .anyQuestionsOptionsList {
    flex-wrap: wrap;
  }

}
