.tours_main {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.main_tours {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  width: 95%;
  max-width: 1280px;
  margin: 0 auto;
}

.attraction_tour {
  width: 900px;
  height: 675px;
  background-color: var(--bluePrimary);
  border-radius: 20px;
  position: relative;
}

.attraction_tour img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 20px;
  opacity: 0.6;
}

.attraction_tour_header {
  display: flex;
  flex-direction: column;
  gap: 1px;
  position: absolute;
  top: 20px;
  left: 20px;
  color: var(--white);
  z-index: 200;
}

.attraction_tour_header h2 {
  font-size: 24px;
  font-weight: 700;
}

.attraction_tour_button {
  background-color: var(--bluePrimary);
  padding: 20px;
  text-align: center;
  color: var(--white);
  position: absolute;
  bottom: 30px;
  left: 0;
  width: 100%;
  font-size: 18px;
  font-weight: 700;
}

.carrousel_search {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.carrousel_search_image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.main_tours_form {
  padding: 20px;
  background-color: var(--white);
  border-radius: 15px;
  box-shadow: var(--shawdowPrimary);
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.main_tours_form_header h2 {
  font-size: 36px;
  font-weight: 800;
  color: var(--bluePrimary);
}

.main_tours_form_header p {
  font-size: 12px;
  color: var(--greySecundary);
}

.main_tours_inputs {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: space-between;
}

.main_tours_inputs input {
  width: 100%;
  border: 1px solid var(--greyPrimary);
  padding: 10px;
  border-radius: 8px;
  height: 100%;
  font-size: 12px;
}

.main_tours_inputs select {
  width: 100%;
  border: 1px solid var(--greyPrimary);
  padding: 10px;
  border-radius: 8px;
  height: 100%;
  font-size: 12px;
}

.main_tours_button_search {
  background-color: var(--bluePrimary);
  padding: 10px 20px;
  color: var(--white);
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 3px;
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
}


@media screen and (max-width: 800px) {
  .main_tours {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 10px;
    width: 95%;
    max-width: 1280px;
    margin: 0 auto;
  }

  .attraction_tour {
    width: 100%;
    height: 200px;
  }
}