.doctors_content {
    width: 95%;
    height: 1800px;
    display: flex;
    margin-top: -140px;
}
.doctors_list {
    width: 80%;
    height: 400px;
    margin: 0 !important;
}
.doctors_list a {
    text-decoration: none;
}
.doctors_filter {
    width: 20%;
    background-color: #004274;
    border-radius: 20px;
    color: white;
    padding: 30px 20px;
    box-shadow: -4px 4px 4px rgba(0, 0, 0, 0.25) !important;
}
.doctorsFilter_input {
    width: 100%;
    text-align: center;
}
.doctorsFilter_input input {
    width: 100%;
    height: 60px;
    border: none;
    border-radius: 10px;
    text-align: center;
}
.doctors_filter h2 {
    font-family: 'Inter';
    font-weight: 700;
    font-size: 30px;
    margin-top: 30px;
}
.MuiListItemText-secondary {
    color: white !important;
    font-family: 'Inter' !important;
    font-size: 16px !important;
    font-weight: 300 !important;
}
.doctorsList_icon {
    width: 100%;
    display: flex;
    margin-left: 20px;
}
.css-10r5to9-MuiCardActions-root {
    padding: 3px !important;
}
.doctorsList_heart {
    padding: 0 !important;
}
.doctorsList-filter_buttons {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border: none;
    cursor: pointer;
    text-align: start;
    background-color: rgba(255, 255, 255, 0);
}
.collapseList_ListItemButton {
    padding-left: 0!important;
    padding-right: 0 !important;
}
.MuiListItemText-primary {
    font-family: 'Inter' !important;
    font-size: 20px !important;
    font-weight: 500 !important;
}
@media screen and (max-width: 1470px) {
    .doctors_content {
        height: 2300px;
    }
}
@media screen and (max-width: 1300px) {
    .doctorsList_icon {
        margin-left: 0;
    }
}
@media screen and (max-width: 1115px) {
    .doctors_content {
        height: 3200px;
    }
}
@media screen and (max-width: 1000px) {
    .doctors_filter {
        width: 30%;
    }
    .doctorsList_icon {
        margin-left: 0;
    }
    .doctors_list {
        width: 70%;
    }
}
@media screen and (max-width: 863px) {
    .doctors_content {
        flex-direction: column;
        height: 3800px;
    }
    .doctors_filter {
        width: 95%;
        margin-bottom: 20px;
    }
    .doctorsList_icon {
        margin-left: 20px;
    }
}
@media screen and (max-width: 580px) {
    .doctors_content {
        width: 100%;
        margin-top: -200px;
        height: 3350px;
    }
    .doctors_filter {
        width: 100%;
        padding: 0;
    }
    .doctors-filter_content {
        margin: 30px 20px;
    }
}