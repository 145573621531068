.content-detail-buy-hotel {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-top: 40px;
  border-top: 1px solid var(--greyPrimary);
}

.content-detail-buy-hotel h2 {
  color: var(--bluePrimary);
  font-size: 18px;
}

.content-detail-buy-hotel h3 {
  color: var(--bluePrimary);
  font-size: 14px;
}

.content-check-group {
  display: flex;
  gap: 1rem;
  justify-content: start;
  align-items: center;
  padding: 15px 0;
  border-top: 1px solid var(--greyPrimary);
  border-bottom: 1px solid var(--greyPrimary);
}

.check-in-detail {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.content-check-group .check-in-detail p {
  color: var(--bluePrimary);
  font-weight: bold;
  font-size: 12px;
}

.content-check-group .check-in-detail span {
  font-size: 12px;
}

.content-hab-total-paxes {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.content-hab-total-paxes p {
  font-size: 14px;
  font-weight: 300;
}

.content-hab-total-paxes span {
  display: flex;
  align-items: center;
  gap: 10px;
}

.content-hab-total-paxes-item {
  display: flex;
  align-items: center;
  gap: 10px;
}

.alert-cancelled-hotel {
    font-size: 14px;
    background-color: var(--blueTertiary);
    padding: 13px;
    color: var(--white);
    border-radius: 8px;
    font-weight: 300;
}