.participant {
  display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    justify-content: space-between;
    background: var(--blueTertiary);
    padding-bottom: 20px;
    box-shadow: var(--shawdowPrimary);
    
    width: 420px;
  
    border-radius: 10px;
    overflow: hidden;
}

.participantInfo {
  color: var(--white);
  font-size: 18px;
  font-weight: 600;
  padding: 0 20px;
}

.participantAvatar {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--greyPrimary);
  border-radius: 50%;
  width: 150px;
  height: 150px;
  margin: 16px 0;
  font-size: 64px;
  color: #5f6368;
}

.participantAvatarText {
  font-weight: bold;
}

audio {
  display: none; /* Hidden by default since it plays audio only */
}


@media screen and (max-width: 600px){
  .participantInfo p {
    font-size: 12px;
  }
  .participantAvatarText {
    font-size: 30px;
  }
  .participantAvatar {
    
    padding: 1rem;
    width: 50%;
   height: fit-content;
  }
  .participant{
    gap: 0px;
    width: 90%;
  }
}