*{
    box-sizing: border-box;
}
.flightsHeaderFind-c {
    width: 100%;
    display: flex;
    justify-content: center;
}

.flightsHeaderFind_content-c {
    width: 90%;
    background-color: white;
    border-radius: 30px;
    box-shadow: -4px 4px 4px rgba(0, 0, 0, 0.25) !important;
    display: flex;
    padding: 1rem;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.flightsHeaderFind_content_c {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.flightsHeaderFind_top-c {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.flightsHeaderFind_logo-c {
    display: flex;
    align-items: center;
    justify-content: center;
}

.flightsHeaderFind_logo-c h3 {
    font-family: 'Inter';
    font-size: 25px;
    color: #004274;
    font-weight: 600;
}

.flightsHeaderFind_checkBox-c {
    display: flex;
    flex-direction: column;
}

.flightsHeaderFind_checkBoxOne-c {
    display: flex;
    align-items: center;
    margin: 0 20px;
}

.flightsHeaderFind_checkBoxOne-c p {
    font-family: 'Inter';
    font-size: 18px;
    color: #004274;
    margin-left: 5px;
    font-weight: 200;
}


.flightsHeaderFind_bottom_c {
    width: 100%;
    display: flex;
    gap: 5%;
    flex-wrap: wrap;
    position: relative;
}

.flightsHeaderFind_buttonSearch-c {
    width: 100%;
    height: 60px;
    border: 1px solid #004274;
    border-radius: 15px;
    margin-top: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.flightsHeaderFind_buttonSearchTitle-c {
    font-family: 'Inter';
    font-size: 16px;
    font-weight: 200;
}

.flightsHeaderFind_buttonSearchDescription-c {
    font-family: 'Inter';
    font-size: 16px;
    font-weight: 600;
    color: #004274
}

.flight_searchCitiesOrigin-c,
.flight_searchCitiesDestinity-c {
    width: 100%;
    max-height: 390px;
    border-radius: 15px;
    background-color: white;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.35) !important;
}

.flight_dateFind-c {
    width: 47.5%;
    height: 60px;
    margin-top: 1rem;
    border: 1px solid #004274;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}


/* ESTILOS QUE ESTABAN EN LINEA MUDADOS */

.airplane-c {
    margin-right: 5px;
}

.flightsHeaderFind_buttonSearch_div-c {
    width: 90%;
    height: 70%;
    display: flex;
    justify-Content: space-between;
    align-Items: center;
}

.flightsHeaderFind_buttonSearch_div_div-c {
    width: 82%;
    height: 100%;
    display: flex;
    flex-Direction: column;
    justify-Content: space-between;

}

.flightsHeaderFind_buttonSearch_div_div-bro-c {
    width: 17%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.flight_dateFind_div-c {
    width: 90%;
    height: 70%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.flight_dateFind_div_div-c {
    width: 82%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.flight_dateFind_div_div_bro-c {
    width: 17%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.flight_dateFindNone_div-c {
    width: 90%;
    height: 70%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.flight_dateFindNone_div_div-c {
    width: 82%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.flight_dateFindNone_div_div_bro-c {
    width: 17%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.flight_passengersFind_div-c{
    width: 90%; height: 70%; display: flex; justify-content: space-between; align-items: center;
}

.flight_passengersFind_div_div-c {
    width: 82%; 
    height: 100%; 
    display: flex; 
    flex-direction: column; 
    justify-content: space-between;
}

.flight_passengersFind_div_div_bro-c{
    width: 17%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}





/* selected flight_dateNone */
.flight_dateFindNone-c {
    width: 17%;
    height: 60px;
    border: 1px solid #004274;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: .3;
}

.flight_passengersFind-c {
    width: 100%;
    height: 60px;
    border: 1px solid #004274;
    border-radius: 15px;
    display: flex;
    margin-top: 1rem;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: relative;
}

.flight_searcherFind-c {
    width: 60px;
    height: 60px;
    border-radius: 15px;
    margin-top: 1rem;
    border: 1px solid #004274;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: white;
    position: relative !important;
}

.flightsHeaderFind_buttonPassenger-c {
    font-family: 'Inter';
    font-size: 16px;
    font-weight: 600;
    color: #004274;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}





@media screen and (max-width: 1140px) {
    .flightsHeaderFind_content-c {
        width: 100%;
    }
}

@media screen and (max-width: 1100px) {
    .flightsHeaderFind_content-c {
        width: 99%;
    }
}

@media screen and (max-width: 920px) {
    .flightsHeaderFind_content-c {
        width: 100%;
        
    }

    .flightsHeaderFind_bottom_c {
        width: 100%;
        display: flex;
        flex-direction: column;
        flex-flow: wrap;
        padding: 10px 0;
    }

    /* .flightsHeaderFind_buttonSearch-c,
    .flight_dateFind-c,
    .flight_dateFindNone-c,
    .flight_passengersFind-c,
    .flight_searcherFind-c {
        width: 100%;
        height: 70px;
        margin: 8px 0;
    } */
}

@media screen and (max-width:840px) {
    .flightsHeaderFind_top-c {
        flex-wrap: wrap;
        gap: 1rem;
    }
}

@media screen and (max-width: 720px) {
    
}

@media screen and (max-width: 580px) {
    .flightsHeaderFind_logo-c h3 {
        font-family: 'Inter';
        font-size: 20px;
    }
}




