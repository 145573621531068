.turismActivities {
    width: 70%;
    margin: 0 auto;
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.turismActivities h1 {
    font-family: 'Inter';
    font-size: 30px;
    font-weight: 300;
    text-transform: capitalize;
    text-align: center;
}
.turismActivities_cards {
    width: 100%;
    display: flex;
    margin-top: 20px;
}
.turismActivities_slider {
    display: flex;
}
.turismActivities_slider a {
    color: black;
}

@media screen and (max-width: 1600px) {
    .turismActivities {
        width: 80%;
    }
}
@media screen and (max-width: 1250px) {
    .turismActivities {
      width: 90%;
    }
}
@media screen and (max-width: 1100px) {
    .turismActivities {
      width: 100%;
    }
}