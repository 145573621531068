.bestOfferHab {
  background-color: var(--greenOffert);
  margin-top: 10px;
  padding: 10px 20px;
  width: fit-content;
  border-radius: 0px 20px 20px 0px;
  color: var(--white);
  font-weight: 700;
  display: flex;
  align-items: center;
  gap: 10px;
}

.contain-hab-detail {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.contain-hab-detail-item {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.contain-hab-detail-item h3 {
  display: flex;
  gap: 10px;
  align-items: center;
  margin-bottom: 10px;
}

.content-services-modal-hab {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.content-services-modal-hab h3 {
  text-align: start;
  font-size: 18px;
  color: #1e5b98;
  font-weight: 600;
  padding-bottom: 10px;
  border-bottom: 1px solid #e0e5ff;

}

.list-change-services-hab {
  list-style: none;
  color: var(--bluePrimary);
  font-weight: bold;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  gap: 8px;
  width: 100%;
}

.list-change-services-hab li {
  width: 100%;
  display: flex;
  gap: 10px;
  align-items: center;
  transition: all 0.3s ease;
  cursor: pointer;
  padding: 10px;
  border-radius: 10px;
  color: #1e5b98;
  font-size: 14px;
  font-weight: 500;
}

.list-change-services-hab li:hover {
  background-color: #eaf1ff;
}

.contain-all-hab {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: start;
  gap: 10px;
  gap: 10px;
}

.content-info-hab-content {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 25px;
}

.content-info-hab-content.paddingTop {
  padding-top: 0px;
}

.content-info-hab {
  width: 100%;
  height: 100%;
  background-color: #f8fbff;
  border: 1px solid transparent;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  transition: all 0.3s ease;
  cursor: pointer;
  position: relative;
}

.content-info-hab-icon {
  position: absolute;
  top: 10px;
  right: 10px;
}

.content-info-hab:hover {
  width: 100%;
  height: 100%;
  background-color: #eaf1ff;
  border: 1px solid #e0e5ff;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.content-info-hab.active {
  width: 100%;
  height: 100%;
  background-color: #eaf1ff;
  border: 1px solid #e0e5ff;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.content-info-hab h2 {
  color: var(--bluePrimary);
  font-weight: 500;
  font-size: 18px;
}

.content-info-hab p {
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 15px;
  color: #616161;
}

.card-hab-detail {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  transition: 0.5s ease-in-out;
}

.content-selected-hab {
  width: 30%;
  border-left: 3px var(--bluePrimary) solid;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.contain-price-info-hab {
  width: 50%;
  height: fit-content;
  background-color: #f8fbff;
  border: 1px solid #e0e5ff;
  justify-content: space-between;
  border-radius: 10px;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  position: relative;
  margin-top: 42px;
}

.contain-price-info-hab ul {
  list-style: none;
  margin-top: 1rem;
}

.contain-price-info-hab ul li {
  display: flex;
  align-items: flex-start;
  gap: 4px;
  margin-bottom: 1rem;
}

.contain-price-info-hab-button {
  width: fit-content;
  height: fit-content;
  padding: 0.5rem 1rem;
  border: 1px solid var(--bluePrimary);
  border-radius: 7px;
  color: var(--bluePrimary);
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
}

.contain-price-info-hab-button.disabled {
  opacity: 0.3;
  cursor:not-allowed;
}

.contain-price-info-hab-button:hover {
  background-color: var(--bluePrimary);
  color: #fff;
}

.what-include {
  font-size: 14px;
  cursor: pointer;
  color: var(--bluePrimary);
}

.price-Hab-select {
  color: var(--bluePrimary);
  font-size: 20px;
  font-weight: 700;
  text-align: start;
}

.unselected-hab {
  text-align: center;
  font-size: 18px;
  color: var(--bluePrimary);
  font-weight: 500;
}

.alertList {
  position: absolute;
  top: 30;
  left: 30px;
  right: 30px;
  background-color: rgb(255, 209, 209);
  color: #563e3e;
  padding: 1rem;
  border-radius: 7px;
  text-align: center;
}

@media screen and (max-width: 700px) {
  .contain-hab-detail {
    width: 100%;
    flex-direction: column;
  }

  .contain-price-info-hab {
    width: 100% !important;
    height: fit-content;
  }
}

@media screen and (max-width: 1100px) {
  .contain-all-hab {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 10px;
  }

  .contain-price-info-hab {
    width: 80%;
    height: fit-content;
  }
}

@media screen and (max-width: 600px) {
  .contain-hab-detail {
    flex-direction: column;
  }

  .unselected-hab {
    font-size: 15px;
  }

  .contain-all-hab {
    width: 100%;
  }

  .contain-price-info-hab {
    width: 100%;
  }

  .content-info-hab h2 {
    font-size: 16px;
    margin-bottom: 0.4rem;
  }
  .content-info-hab p {
    font-size: 13px;
  }

  .content-selected-hab span {
    font-size: 13px;
  }
  .contain-price-info-hab {
    gap: 0.5rem;
  }

  .contain-price-info-hab span {
    font-size: 13px;
  }
  .contain-price-info-hab p {
    font-size: 13px;
  }

  .contain-price-info-hab button {
    font-size: 16px;
    padding: 0.4rem;
  }
  .contain-price-info-hab ul li {
    font-size: 13px;
    margin-bottom: 0.2rem;
  }
  .content-info-hab h3 {
    font-size: 16px;
  }

  .what-include {
    margin-top: 0.4rem;
  }
  .price-Hab-select {
    font-size: 18px !important;
  }
  .content-selected-hab {
    border: none;
  }
}

.input-select-hab {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  cursor: pointer;
}
