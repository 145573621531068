.main-content-result-hotel {
    width: 95%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    gap: 20px;
    margin-bottom: 20px;
}

.content-search-hotels {
    width: 100%;
}

.contain-results-hotels {
    width: 100%;
    max-width: 1280px;
    display: flex;
    flex-direction: column;
    margin: auto;
}

.contain-route-hotel-result {
    width: 100%;
}

.contain-buttons-mobile-hotels {
    width: 90%;
    margin: 2rem auto;
    position: relative;
    display: none;
    justify-content: space-between;
}

.contain-buttons-mobile-hotels button {
    display: flex;
    align-items: center;
    border: none;
    padding: .5rem;
    border-radius: 10px;
    gap: 3px;
    cursor: pointer;
    -webkit-box-shadow: 0px 0px 16px -5px rgba(0, 0, 0, 0.65);
    -moz-box-shadow: 0px 0px 16px -5px rgba(0, 0, 0, 0.65);
    box-shadow: 0px 0px 16px -5px rgba(0, 0, 0, 0.65);
}

.main-content-resulta-filter-hotels-not-results {
    width: 100%;
    height: 250px;
    display: grid;
    place-items: center;
}

.noItemsHotels {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    color: var(--bluePrimary);
    font-size: 18px;
    font-weight: 500;
}

.messageNoItemsHotels {
    display: flex;
    align-items: center;
    gap: 10px;
}

.buyNoItemsHotels {
    background-color: var(--bluePrimary);
    border-radius: 6px;
    color: var(--white);
    padding: 10px 20px;
    transition: all 0.3s ease;
    font-size: 14px;
}

.buyNoItemsHotels:hover {
    background-color: var(--blueTertiary);
}

@media screen and (max-width:800px) {
    .contain-buttons-mobile-hotels {
        display: flex;
    }
}