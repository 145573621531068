
.cardHomeTurism {
    width: 265px;
    height: 410px;
    font-family: 'Inter';
    text-align: center;
    box-shadow: none !important;
}
.cardHomeTurismImg {
    width: 265px !important;
    height: 214px;
    filter: brightness(60%);
    pointer-events: none;
}
.cardHomeTurism_media {
    position: relative;
}
.cardHomeTurism_heart {
    position: absolute;
    top: 0;
    right: 0;
}
.cardHomeTurism_title {
    font-size: 22px;
    font-weight: 600;
    display: block;
    color: #004274 !important;
    text-align: start !important;
    text-transform: capitalize;
}
.cardHomeTurism_ActionArea {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}
.cardHomeTurism_CardAction {
    width: 100%;
}
.cardHomeTurism_ActionArea .css-46bh2p-MuiCardContent-root {
    padding: 0 !important;
} 

.css-1t6e9jv-MuiCardActions-root {
    padding: 0 !important;
}
.cardHomeTurism_description {
    font-size: 13px !important;
    text-align: justify !important;
    font-weight: 200 !important;
    color: rgb(88, 88, 88) !important;
    text-transform: capitalize;
}
.cardHomeTurism_IconButton {
    padding: 0 !important;
    margin: 0 !important;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.cardHomeTurism_IconButton .number-rating{
    color: #004274;
    font-size: 12px;
}

.text-image-hotels {
    position: relative;
}


.text-image-hotels p {
    position: absolute;
    bottom: 10px;
    color: white;
    right: 10px;
    text-align: end;
}

.text-image-hotels span {
    display: block;
}



@media screen and (max-width: 500px) {
    /* .cardHomeTurism {
        width: 190px !important;
    }
    .cardHomeTurismImg {
        height: 190px;
    } */
    .cardHomeTurism_title {
        font-size: 16px;
    }
    .cardHomeTurism_description {
        font-size: 14px !important;
    }
}