.container-payment-card{
    display: flex;
    justify-content: space-between;
    max-width: 1280px;
    margin: 3rem auto;
    width: 95%;
    margin-block: 50px;
}

.credit-cards{
    width: 59%;
}

@media screen and  (max-width: 1200px) {
    .container-payment-card{
        flex-direction: column-reverse;
    }

    .credit-cards{
        width: 100%;
        margin-top: 20px;
    }
}

.info-terrawind{
    width: 39%;
}

.title-method-pay{
    margin-block: 15px;
    display: flex;
    justify-content: center;
}

.title-method-pay p{
    width: 100%;
    font-size: 18px;
    font-weight: 700;
    font-family: "Montserrat" !important;
    color: #104172;
}

.text-description{
    margin-block: 15px;
    display: flex;
    justify-content: center;
}

.text-description p{
    width: 100%;
    font-weight: 300;
    font-size: 11px;
    font-family: "Montserrat" !important;
}

.btn-confirm-buy{
    height: 20%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.btn-confirm-buy button{
    background-color: #104172;
    width: 65%;
    color: white;
    height: 44px;
    border-radius: 13px;
    margin-block: 15px;
    cursor: pointer;
}

.installments-payment{
    margin-block: 10px;

}

.installments-payment p{
    font-weight: 300;
    font-size: 11px;
    font-family: "Montserrat" !important;
}

.validate-credit-card{
    color: red;
    font-size: 11px;
    margin-block: 10px ;
    font-family: "Montserrat" !important;
}

.btn-confirm-buy button:hover{
    box-shadow: 0px 4px 4px 0px #00000040;
    transition: 0.3s;
}

.container-payment-person{
    margin-block: 20px;
}

.row-data-payment-person{
    display: flex;
    justify-content: space-between;
}

.row-data-payment-person > div{
    margin-top: 20px;
    width: 30%;
}

.row-data-payment-person-second{
    display: flex;
    justify-content: space-between;
}

.row-data-payment-person-second > div{
    width: 30%;
    margin-top: 20px;
}

@media screen and (max-width: 900px){
    .row-data-payment-person{
        flex-direction: column;
    }

    .row-data-payment-person > div{
        width: 100%;
    }

    .row-data-payment-person-second{
        flex-direction: column;
    }

    .row-data-payment-person-second > div{
        width: 100%;
    }
    
}