.CI {
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.CI.headerCI {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
  justify-items: center;
}

.CI.titleCI {
  color: var(--bluePrimary);
  font-size: 16px;
  font-weight: bolder;
  font-family: 'Montserrat', sans-serif;
}

.CI.toEdit {
  cursor: pointer;
  color: var(--bluePrimary);
  font-size: 12px;
  font-weight: 400;
  font-family: 'Montserrat', sans-serif;
  line-height: 20px;
}

.CI.inputGroup {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.CI.inputLabel {
  margin-bottom: 5px;
  color: var(--bluePrimary);
  font-size: 12px;
  font-weight: 400;
  font-family: 'Montserrat', sans-serif;
  display: flex;
  flex-direction: row;
}

.CI.inputLabel > b{
  color: red;
  font-size: 14px;
  margin-right: 5px;
}

.CI.containerInput {
  width: 100%;
  position: relative;
  display: flex;
}

.CI.input {
  background-color: var(--bluePrimary);
  width: 100%;
  border: none;
  border-radius: 8px;
  height: 30px;
  color: var(--white);
  padding: 5px;
  transition: all ease 200ms;
}

.CI.input::placeholder {
  color: var(--white);
  opacity: 0.6;
}

.CI.inputEditing {
  background-color: var(--greyPrimary) !important;
  color: var(--black) !important;
  transition: all ease 200ms;
}

.CI.textarea {
  background-color: var(--bluePrimary);
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  border: none;
  border-radius: 8px;
  color: var(--white);
  padding: 5px;
  min-height: 30px;
  max-height: 200px;
  transition: all ease 200ms;
}

.CI.textarea::placeholder {
  color: var(--white);
  opacity: 0.6;
}

.CI.footerCI {
  margin-top: -5px;
  padding-top: 5px;
  padding-bottom: 10px;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.CI.outlineBtnCI {
  width: 45%;
  height: 30px;
  border-radius: 8px;
  background-color: var(--white);
  justify-content: center;
  align-items: center;
  border: solid 1px var(--bluePrimary);
  cursor: pointer;
  transition: all ease 200ms;
}

.CI.outlineTextCI {
  color: var(--bluePrimary);
  font-size: 14px;
  font-weight: bolder;
  font-family: 'Montserrat', sans-serif;
}

.CI.fillBtnCI {
  width: 45%;
  height: 30px;
  border-radius: 8px;
  background-color: var(--bluePrimary);
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all ease 200ms;
}


.CI.fillBtnCIDisable {
  background-color: var(--greyPrimary) !important;
}

.CI.fillTextCI {
  color: var(--white);
  font-size: 14px;
  font-weight: bolder;
  font-family: 'Montserrat', sans-serif;
}

.CI.outlineBtnCI:hover {
  transform: translateY(-4px);
  transition: all ease 200ms;
  -webkit-box-shadow: 10px 10px 10px -10px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 10px -10px rgba(0, 0, 0, 0.75);
  box-shadow: 10px 10px 10px -10px rgba(0, 0, 0, 0.75);
}

.CI.fillBtnCI:hover {
  transform: translateY(-4px);
  transition: all ease 200ms;
  -webkit-box-shadow: 10px 10px 10px -10px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 10px -10px rgba(0, 0, 0, 0.75);
  box-shadow: 10px 10px 10px -10px rgba(0, 0, 0, 0.75);
}
.CI.modalContainerCI{
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* .CI.modalIcon{}/ */

.CI.modalText{
  color: var(--bluePrimary);
  font-size: 22px;
  font-weight: bolder;
  font-family: 'Montserrat', sans-serif;
  text-align: center;
}