.contain-aside-hotel-result.close {
  width: 200px;
  min-width: 160px;
  max-width: 200px;
  height: 100%;
  max-width: 300px;
  height: fit-content;
  z-index: 1;
  transition: all 0.3s ease;
  display: flex;
}

.contain-aside-hotel-result.stuck {
  padding-top: 30px;
}

@media screen and (max-width: 1000px) {
  .modalButtonFilterHotel {
    display: block !important;
  }

  .contain-aside-hotel-result {
    width: 100%;
    height: 100%;
    max-width: 100%;
    min-height: 100%;
    position: fixed;
    background-color: #021624b0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 1;
    transition: all 0.2s ease;
    padding: 30px;
  }

  .contain-aside-hotel-result.close {
    width: 100%;
    height: 100%;
    max-width: 100%;
    min-height: 100%;
    position: fixed;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    pointer-events: none;
  }
}

.modalButtonFilterHotel {
  display: none;
  align-items: center;
  justify-content: center;
  gap: 8px;
  background-color: var(--bluePrimary);
  color: var(--white);
  padding: 13px 20px;
  border-radius: 8px;
  cursor: pointer;
  text-align: center;
  font-weight: 600;
  font-size: 16px;
  transition: all 0.3s ease;
}