.pqrsFormContactUs {
  width: 50%;
  display: flex;
  flex-direction: column;
  transition: all ease 200ms;
}

.pqrsFormContactUs .pqrsFormContactUsTitle{
  color: var(--bluePrimary);
  text-align: left;
  font-family: 'Montserrat', sans-serif;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 20px ;
}

.pqrsFormContactUs .pqrsFormContactUsSubtitle{
  color: var(--black);
  text-align: left;
  font-family: 'Montserrat', sans-serif;
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 15px;
  margin-top: -5px;
}

.pqrsFormContactUs .pqrsFormContactUsRow{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.pqrsFormContactUs .pqrsFormContactUsGroup{
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.pqrsFormContactUs .pqrsFormContactUsGroup .pqrsFormContactUsGroupLabel{
  color: var(--bluePrimary);
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 5px;
  margin-left: 5px;
}

.pqrsFormContactUs .pqrsFormContactUsGroup .pqrsFormContactUsGroupWrapper{
  background-color: var(--white);
  width: 100%;
  border: solid 1px var(--bluePrimary);
  height: 25px;
  border-radius: 8px;
}
.pqrsFormContactUs .pqrsFormContactUsGroup .pqrsFormContactUsGroupWrapper .pqrsFormContactUsGroupInput{
  background-color: transparent;
  border: none;
  width: 100%;
  height: 100%;
  padding: 0px 10px;
}

.pqrsFormContactUs .pqrsFormContactUsGroup .pqrsFormContactUsGroupTextarea{
  background-color: var(--white);
  width: 100%;
  border: solid 1px var(--bluePrimary);
  border-radius: 8px;
  padding: 0px 10px;
}

.pqrsFormContactUs .pqrsFormContactUsBtn{
  background-color: var(--bluePrimary);
  width: 60%;
  padding: 10px;
  border-radius: 12px;
  margin: auto;
  align-items: center;
  cursor: pointer;
  transition: all ease 200ms;
}

.pqrsFormContactUs .pqrsFormContactUsBtn:hover{
  transition: all ease 200ms;
  transform: translateY(-4px);
  -webkit-box-shadow: 0px 5px 5px 3px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0px 5px 5px 3px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 5px 5px 3px rgba(0, 0, 0, 0.25);
}

.pqrsFormContactUs .pqrsFormContactUsBtn .pqrsFormContactUsBtnText{
  color: var(--white);
  text-align: center;
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  font-weight: bolder;
}

.pqrsFormContactUs .pqrsFormContactUsRow .pqrsFormContactUsGroup{
  width: 45% !important;
}


iframe{
  width: 100% !important;
}

.pqrdContactYoutube {
  padding: 15px 30px;
  border-radius: 10px;
  box-shadow: var(--shawdowPrimary);
  background-color: var(--white);
  margin-top: 20px;
}

.pqrdContactYoutubeContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.pqrdContactYoutubeImageText {
  display: flex;
  gap: 20px;
  align-items: center;
}

.pqrdContactYoutubeImage {
  width: 65px;
  height: 65px;
}

.pqrdContactYoutubeText h3 {
  font-size: 24px;
  color: var(--bluePrimary);
}

.pqrdContactYoutubeText p {
  font-size: 14px;
  color: var(--blackGray);
}

.pqrdContactYoutubeButton {
  cursor: pointer;
  background-color: var(--bluePrimary);
  font-family: inherit;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 2px 15px;
  line-height: 2.5em;
  position: relative;
  overflow: hidden;
  border: 2px solid var(--bluePrimary);
  transition: color 0.5s;
  z-index: 1;
  font-size: 18px;
  border-radius: 9px;
  font-weight: 600;
  color:var(--white);
}

.pqrdContactYoutubeButton:before {
  content: "";
  position: absolute;
  z-index: -1;
  background: var(--white);
  color: var(--bluePrimary);
  height: 550px;
  width: 1500px;
  border-radius: 50%;
}

.pqrdContactYoutubeButton:hover {
  color: var(--bluePrimary);
}

.pqrdContactYoutubeButton:before {
  top: 100%;
  left: 100%;
  transition: all 0.7s;
}

.pqrdContactYoutubeButton:hover:before {
  top: -100px;
  left: -200px;
}

.pqrdContactYoutubeButton:active:before {
  background: var(--white);
  transition: background 0s;
}

@media screen and (max-width: 860px) {
  .pqrsFormContactUs {
    width: 100%;
    transition: all ease 200ms;
  }

  .pqrsFormContactUs .pqrsFormContactUsRow{
    flex-direction: column;
  }

  .pqrsFormContactUs .pqrsFormContactUsRow .pqrsFormContactUsGroup{
    width: 100% !important;
  }
}

