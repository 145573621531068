.flight-searchCities_input {
    margin-top: 20px;
    margin-bottom: 10px;
    z-index: 2;
    display: grid;
    grid-template-columns: 80% 10%;
    gap: 20px;
}
.flight-searchCities_cities {
    max-height: 320px;
    overflow: hidden;
    overflow-y: auto;
}
.flight-searchCities_input svg{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}
.flight-searchCities_input input {
    width: 200px;
    height: 30px;
    border: 2px solid rgba(0, 0, 0, 0.3);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.35) !important;
    border-radius: 20px;
    font-family: 'Inter';
    font-size: 16px;
    padding: 10px 30px;
    text-transform: capitalize;
}
textarea:focus, input:focus{
    outline: none;
}
.cityData_result {
    position: relative;
}
.cityData {
    width: 300px;
    height: 50px;
    display: flex !important;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.cityData_img {
    width: 35px;
    height: 35px;
    border-radius: 10px;
}
.cityData_img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
}
.cityData_text {
    width: 250px;
    display: flex;
    align-items: center;
    margin-left: 10px;
    justify-content: space-between;
}
.cityData_cityCountry p {
    font-family: 'Inter';
    font-size: 16px;
    color: #000000;
    text-transform: capitalize;
}
.cityData_abr p {
    font-family: 'Inter';
    font-size: 16px;
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.40);
}

.cityData_result:hover {
    background-color: rgba(0, 66, 116, .1);
    transition: all 0.3s ease-out;
    border-radius: 10px;
}