/*pifs =  passengerInformationFormScreen*/
.pifs {
  width: 80%;
  margin: 0px auto;
  position: relative;
  overflow: scroll;
  padding: 0px 10px 80px 10px;
}

.subtitles-pass{
  color: var(--bluePrimary);
  width: 100%;
  margin: 1rem 0;
}

.letters-new{
  width: 100%;
  margin: .4rem auto;
  color: var(--bluePrimary);
}

.pifs .loadingScreen{
  width: 100%;
  height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pifs .pifsStepsList {
  margin-top: 40px;
  width: 100%;
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
}

.pifs .pifsStep {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  padding: 10px 15px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 1px;
  /* min-width: 33%; */
}

.pifs .pifsStep:hover {
  transform: translateY(-4px);
}

.pifs .pifsStepOn {
  transition: all ease 200ms;
  background-color: var(--white);
  border-top: solid 1px var(--greyPrimary);
  border-left: solid 1px var(--greyPrimary);
  border-right: solid 1px var(--greyPrimary);
}


.fade-in {
  opacity: 0;
  animation: fadeIn 1s forwards;
}

.fade-out {
  opacity: 1;
  animation: fadeOut 2s forwards;
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  to {
    opacity: 0;
  }
}

.addNumbersFlights {
  cursor: pointer;
  width: fit-content;
  text-align: start;
}

.camp-form-person-flight {
  display: flex;
  justify-content: space-between;
  position: relative;
}

.camp-form-person-flight-input {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
}

.camp-form-person-flight-input input {
  padding: 0.7rem;
    border-radius: 5px;
    border: solid 1px var(--greyPrimary);
}

.camp-form-person-flight-input label {
  color: var(--bluePrimary);
    font-size: 12px;
    font-weight: bold;
}

.content-button-reservation-flight button {
  position: absolute;
  right: 15px;
  bottom: 8px;
  cursor: pointer;
  color: var(--bluePrimary);
}

.pifs .pifsStepOff {
  transition: all ease 200ms;
  background-color: var(--greyPrimary);
  border: solid 1px var(--greyPrimary);
}

.pifs .pifsStepText {
  font-size: 14px;
  font-family: 'Montserrat', sans-serif;
}

.pifs .pifsStepTextOn {
  font-weight: 600;
  transition: all ease 200ms;
  color: var(--bluePrimary);
}

.pifs .pifsStepTextOff {
  font-weight: 400;
  transition: all ease 200ms;
  color: var(--greySecundary);
}

.pifs .pifsStepContainer {
  overflow:scroll;
  overflow-y: scroll;
  padding: 20px;
  background-color: var(--white);
  border-radius: 8px;
  -webkit-box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.75);
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
}

.pifs .finalBtn {
  background-color: var(--bluePrimary);
  width: fit-content;
  padding: 10px 25px;
  border-radius: 8px;
  margin: 20px auto;
  position: relative;
  transition: all ease 200ms;
  cursor: pointer;
}

.pifs .finalBtn:hover {
  transform: translateY(-4px);
}

.pifs .finalBtnText {
  color: var(--white);
  font-size: 16px;
  font-weight: 600;
  font-family: 'Montserrat', sans-serif;
}

/* ------- header stepd init ------- */
.pifs .pifsStepContainerHeader {
  display: flex;
  flex-direction: row;
  justify-content: center;
  overflow-x: scroll;
  justify-content: flex-start;
  padding-bottom: 10px;
  padding-top: 5px;
  margin-top: -5px;
}

.pifs .numberAndLine {
  display: flex;
  flex-direction: row;
}

.pifs .pifsStepContainerHeaderSeparator {
  margin: 15px 5px 14px 5px;
  height: 1px;
  width: 20px;
  background-color: var(--bluePrimary);
}

.pifs .headerStep {
  background-color: var(--white);
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 30px;
  border-radius: 15px;
  padding: 0px 2px;
  border: solid 1px var(--greyPrimary);
  transition: all ease 200ms;
  cursor: pointer;
  min-width: 110px;
}

.pifs .headerStep:hover {
  transform: translateY(-4px);
}

.headerStepFocus {
  -webkit-box-shadow: 0px 5px 10px -5px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 5px 10px -5px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 5px 10px -5px rgba(0, 0, 0, 0.75);
}

.pifs .headerStepNumber {
  width: 24px;
  height: 24px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
}

.pifs .headerStepNumberOn {
  background-color: var(--bluePrimary);
}

.pifs .headerStepNumberOff {
  background-color: var(--greyPrimary);
}

.pifs .headerStepNumberText {
  color: var(--white);
  font-weight: bolder;
  font-size: 14px;
  font-family: 'Montserrat', sans-serif;
}

.pifs .headerStepName {
  margin-right: 5px;
  color: var(--black);
  font-weight: 600;
  font-size: 14px;
  font-family: 'Montserrat', sans-serif;
}

/* ------- header stepd end ------- */

.pifs .pifsFormHead {
  margin-bottom: 40px;
  width: 100%;
  margin-top: 10px;
}

.pifs .pifsFormHeadTitle {
  color: var(--black);
  font-weight: bolder;
  font-size: 20px;
  font-family: 'Montserrat', sans-serif;
}

.pifs .pifsFormHeadSubTitle {
  margin-top: 10px;
  color: var(--black);
  font-weight: 400;
  font-size: 14px;
  font-family: 'Montserrat', sans-serif;
}

.pifs .pifsFormBody {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1.5rem;
  justify-content: space-around;
}

/* ----  input init */
.pifs .pifsFormBodyInputs {
  width: 30%;
  position: relative;
}

.pifs .pifsFormBodyInputsStepsContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.pifs .pifsFormBodyInputsTitle {
  color: var(--black);
  font-weight: 400;
  font-size: 12px;
  font-family: 'Montserrat', sans-serif;
}

.pifs .pifsFormBodyInputsTitle b {
  color: red;
}

.pifs .pifsFormBodyInputsLabel {
  color: var(--greySecundary);
  font-weight: 400;
  font-size: 12px;
  font-family: 'Montserrat', sans-serif;
  margin-left: 5px;
  margin-bottom: -10px;
  background-color: var(--white);
  position: relative;
  width: fit-content;
  padding: 2px 5px;
}


.pifs .pifsFormBodyInputsLabelContainerCenter {
  border: 1px solid var(--greyPrimary);
  border-radius: 5px;
  width: 100%;
  padding: 5px;
}


.pifs .pifsFormBodyInputsLabelContainer {
  border: 1px solid var(--greyPrimary);
  border-radius: 5px;
  width: 80%;
  padding: 5px;
}

.pifs .pifsFormBodyInputsLabelContainerInput {
  border: none;
  width: 100%;
  font-weight: 400;
  font-size: 14px;
  font-family: 'Montserrat', sans-serif;
}

.pifs .pifsFormBodyInputsLabelContainerInput::placeholder {
  color: var(--greyPrimary);
}

.pifs .pifsFormBodyInputsSelectList {
  position: absolute;
  z-index: 9;
  background-color: var(--white);
  width: 100%;
  padding: 5px;
  -webkit-box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.75);
  border-radius: 8px;
}

.pifs .pifsFormBodyInputsSelectListOpt {
  padding: 5px;
  border-bottom: 1px solid var(--greyPrimary);
  cursor: pointer;
}

.pifs .pifsFormBodyInputsSelectListOpt:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.pifs .pifsFormBodyInputsSelectListOptText {
  margin-top: 5px;
  color: var(--bluePrimary);
  font-weight: 600;
  font-size: 12px;
  font-family: 'Montserrat', sans-serif;
}

.pifs .pifsFormBodyInputsError {
  margin-top: 5px;
  color: red;
  font-weight: 400;
  font-size: 12px;
  font-family: 'Montserrat', sans-serif;
  margin-left: 15px;
}

/* ----  input end */

/* ---- form foot init */
.pifs .pifsFormFoot {
  margin-top: 20px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}


.pifs .pifsFormFootBtnCenter {
  padding: 0px 15px;
  margin: 10px;
  height: 30px;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all ease 200ms;
  cursor: pointer;
}


.pifs .pifsFormFootBtn {
  padding: 0px 15px;
  margin: 10px;
  height: 30px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  transition: all ease 200ms;
  cursor: pointer;
}

.pifs .pifsFormFootBtnOn {
  background-color: var(--bluePrimary);
}

.pifs .pifsFormFootBtnOff {
  background-color: var(--greyPrimary);
}

.pifs .pifsFormFootBtnText {
  color: var(--white);
}

.pifs .pifsFormFootBtn:hover {
  transform: translateY(-2px);
}

.pifs .pifsFormFootBtn:active {
  transform: translateY(2px);
}

.pifs .pifsModal {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pifs .pifsModalText {
  color: var(--black);
  font-weight: 400;
  font-size: 16px;
  font-family: 'Montserrat', sans-serif;
}

.pifs .pifsModalCode {
  color: var(--bluePrimary);
  font-weight: bold;
  font-size: 22px;
  font-family: 'Montserrat', sans-serif;
}

.pifs .pifsModalBtn {
  margin: 20px auto;
  background-color: var(--bluePrimary);
  padding: 10px 20px;
  border-radius: 8px;
  cursor: pointer;
  transition: all ease 200ms;
}

.pifs .pifsModalBtn:hover {
  transform: translateY(-4px);
}

.pifs .pifsModalBtn:active {
  transform: translateY(4px);
}

.pifs .pifsModalBtnText {
  color: var(--white);
  font-weight: 400;
  font-size: 14px;
  font-family: 'Montserrat', sans-serif;
}

/* ---- form foot end */

.pifs .sectionError{
  background-color: var(--redPrimary);
  align-items: center;
  padding: 20px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  width: fit-content;
  margin: auto;
}
.pifs .sectionErrorTitle{
  text-align: center;
  color: var(--white);
  font-weight: bolder;
  font-size: 16px;
  font-family: 'Montserrat', sans-serif;
}
.pifs .sectionErrorDescription{
  margin-top: 10px;
  text-align: center;
  color: var(--white);
  font-weight: 400;
  font-size: 14px;
  font-family: 'Montserrat', sans-serif;
}


/* ------------------------ tablet init ------------------------ */
.pifs .tabletContainer{
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  margin-bottom: 20px;
}

.pifs .tabletContainerSection{
  display: flex;
  flex-direction: column;
  width: 60%;
  margin: auto;
}
.pifs .tabletContainerSectionName{
  color: var(--bluePrimary);
  font-weight: bolder;
  font-size: 18px;
  font-family: 'Montserrat', sans-serif;
  margin-bottom: 5px;
  margin-top: 20px;
}

.pifs .tabletContainerRow{
  display: flex;
  flex-direction: row;
  margin-bottom: 2px;
}

.pifs .tabletContainerRowKey{
  text-align: right;
  width: 20%;
  padding: 5px;
  border: solid 1px var(--bluePrimary);
  color: var(--black);
  font-weight: 400;
  font-size: 14px;
  font-family: 'Montserrat', sans-serif;
}

.pifs .tabletContainerRowValue{
  text-align: left;
  width: 80%;
  padding: 5px;
  border: solid 1px var(--bluePrimary);
  color: var(--black);
  font-weight: 400;
  font-size: 14px;
  font-family: 'Montserrat', sans-serif;
  margin-left: -1px;
}

/* ------------------------ tablet end ------------------------ */



.TabletPassengersContainer{
  padding: 10px;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}


.TabletPassengersContainerBtnContainer{
  display: flex;
  flex-direction: row;
  width: 50%;
  margin: auto;
  justify-content: space-between;
}

.TabletPassengersContainerBtn{
  background-color: var(--bluePrimary);
  padding: 10px 20px;
  width: fit-content;
  border-radius: 8px;
}

.TabletPassengersContainerBtnClocet{
  background-color: var(--redPrimary);
  padding: 10px 20px;
  width: fit-content;
  border-radius: 8px;
}



.TabletPassengersContainerBtnText{
  color: var(--white);
  font-weight: 400;
  font-size: 14px;
  font-family: 'Montserrat', sans-serif;
}


/* 
.inputContainer{
  width: 45%;
  background-color: pink;
  margin-bottom: 10px;
}

.flightSelectedDetail {
  margin-top: 30px; 
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 1600px) {
  .flightSelectedDetail {
  width: 90%;
  }
}
@media screen and (max-width: 1100px) {
  .flightSelectedDetail {
    flex-direction: column;
    width: 95%;
  }
  .flightSelectedDetail {
      margin-top: 10px;
      justify-content:center
  }
} */


.contain-hotel-pay-title {
  display: flex;
  flex-direction: column;
  gap: 3px;
  width: 100%;
  text-align: start;
  color: var(--bluePrimary);
  padding-bottom: 20px;
  border-bottom: 1px solid var(--greyPrimary);
}

.contain-hotel-pay-title h2 {
  font-size: 24px;
}

.contain-hotel-pay-title p {
  font-size: 16px;
}

@media screen and (max-width: 600px) {
  .pifs .pifsFormBody{
    flex-direction: column;
    justify-content: space-around;
  }
  .pifs .pifsFormBodyInputs{
    width: 100%;
  }

  .pifs .modalContainer .modalContainerShow .cardContainer{
    width: 90% !important;
  }

  .TabletPassengersContainerBtnContainer{
    width: 100%;
    margin-top: -20px;
    margin-bottom: -20px;
  }

  .TabletPassengersContainer .tabletPassengers .tabletPassengersBody .tabletPassengersPassenger .tabletPassengersPassengerL{
    width: 70%;
  }




  .TabletPassengersContainer .tabletPassengers .tabletPassengersBody .tabletPassengersPassenger .tabletPassengersPassengerL .tabletPassengersGroup{
    flex-direction: column;
  }

  .TabletPassengersContainer .tabletPassengers .tabletPassengersBody .tabletPassengersPassenger .tabletPassengersPassengerL .tabletPassengersGroup .tabletPassengersGroupKey{
    width: 100%;
    text-align: left;
    padding-left: 10px;
  }

  .TabletPassengersContainer .tabletPassengers .tabletPassengersBody .tabletPassengersPassenger .tabletPassengersPassengerR{
    width: 30%;
  }

}