.connect-miles{
    background-color: var(--bluePrimary);
    padding: 2rem 1rem;
    width: 100%;
    border-radius: 15px;
    display: flex;
    justify-content: space-between;
    margin: 2rem 0;
}

.connect-miles p{
    overflow-y: scroll;
    color: var(--white);
    font-weight: 300;
    font-size: 14px;
}

.btn-pay-hotel{
    width: 100%;
    border: none;
    background-color: var(--bluePrimary);
    padding: 1rem;
    color:var(--white);
    border-radius: 15px;
    font-weight: bold;
    font-size: 17px;
    cursor: pointer;
    transition: .5s ease-in-out;
}

.btn-pay-hotel:hover{
    transform: scale(1.03);
}

.content-state-pay{
    display: flex;
    padding: 1rem;
    align-items: center;
    background-color: var(--greenPrimary);
    border-radius: 8px;
    color: var(--white);
}

.content-state-pay h3{
    margin-right: 5px;
    font-size: 18px;
}

.content-state-pay p{
    display: flex;
    gap: 6px;
}

.content-state-pay p div{
    height: 20px;
    width: 20px;
    background-color: var(--greenPrimary);
    border-radius: 50%;
}


@media screen and (max-width:500px) {
    .connect-miles p{
        font-size: 13px;
        text-align: center;
        width: 100%;
    }
    
    .connect-miles {
        flex-direction: column;
        gap: 10px;
        width: 95% !important;
    }
}