.content-tell-yourselfT{
    width: 95%;
    margin: 30px auto;
  max-width: 1280px;
    margin-bottom: 30px;
}

.content-tell-yourselfT h2{
    font-size: 24px;
    color: var(--bluePrimary);
    margin-bottom: 2rem;
}

@media screen and (max-width:1100px) {
    .content-tell-yourself{
        width: 95%;
    }
}