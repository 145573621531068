.content-phone-packages{
    padding: 1rem;
    background-color: white;
    border-radius: 8px;
    border: 1px solid rgb(215, 215, 215);
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.content-phone-packages h2 {
    color: rgb(50, 50, 50);
    margin-bottom: 1rem;
    margin-top: 1rem;
}