.grid_tourQuestion {
    position: absolute;
    left: 160px;
    top: 300px;
    background-color: #004274;
    border-radius: 20px;
    box-shadow: -4px 4px 4px rgba(0, 0, 0, 0.25);
    width: 75% !important;
    max-height: 500px;
    overflow: hidden;
    overflow-y: auto;
    z-index: 1;
    padding-top: 20px;
}
.grid_CardQuestionTour {
    padding: 10px 15px !important;
}
.div_arrows {
    width: 100%;
    margin-bottom: 10px;
}

@media screen and (max-width: 1000px) {
    .grid_tourQuestion {
        width: 100% !important;
        top: 290px;
        left: 32px;
        max-height: 550px;
    }
}
@media screen and (max-width: 700px) {
    .grid_tourQuestion {
        top: 280px;
    }
}