.userAccount {
  position: relative;
}

.buttonUserAccount {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.buttonUserAccount img {
  width: 45px;
  height: 45px;
  display: flex;
  justify-content: center;
  cursor: pointer;
  border-radius: 50%;
}

.dropdownMenu.open {
  position: absolute;
  top: 57px;
  right: 0;
  background-color: var(--white);
  border: 1px solid var(--greyPrimary);
  border-radius: 5px;
  box-shadow: var(--shawdowPrimary);
  z-index: 999;
  min-width: 150px;
  max-width: 250px;
  transform: translateY(0px);
  opacity: 1;
  transition: all 0.2s ease-out;
  pointer-events: all;
}

.dropdownMenu.close {
  position: absolute;
  top: 57px;
  right: 0;
  background-color: var(--white);
  border: 1px solid var(--greyPrimary);
  border-radius: 5px;
  box-shadow: var(--shawdowPrimary);
  z-index: 999;
  min-width: 150px;
  max-width: 250px;
  transform: translateY(-10px);
  opacity: 0;
  transition: all 0.2s ease;
  pointer-events: none;
}

.dropdownMenu ul li a {
  color: var(--bluePrimary);
}

.arrowUp {
  position: absolute;
  top: -8px;
  right: 14px;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid var(--greyPrimary);
  border-bottom-color: var(--greyPrimary);
}

.arrowUp::after {
  content: "";
  position: absolute;
  top: 1.2px;
  left: -8px;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid var(--white);
}

.dropdownMenu ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  overflow: hidden;
  border-radius: 5px;
}

.dropdownMenu li {
  font-size: 14px;
  color: var(--bluePrimary);

  cursor: pointer;
  transition: background-color 0.3s;
}

.dropdownMenu li a {
  display: block;
  width: 100%;
  height: 100%;
  padding: 10px;
}

.dropdownMenu li a:focus {
  display: block;
  width: 100%;
  height: 100%;
  padding: 10px;
  color: var(--bluePrimary);
}

.dropdownMenu li:hover {
  background-color: var(--blueWhiteTableThead);
}

.userAccountActive {
  font-size: 14px;
  font-weight: 600;
  color: var(--bluePrimary);
  padding: 10px;
  border-bottom: 1px solid var(--greyPrimary);
  overflow: hidden;
  text-overflow: ellipsis; 
  white-space: nowrap;    
  width: 100%; 
  max-width: 200px;
}

.userAccountActiveHello {
  font-weight: 400;
}
