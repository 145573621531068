.accordion-detail-form-hotel {
  background-color: var(--bluePrimary);
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  cursor: pointer;
  color: white;
  padding: 0.8rem 1.4rem;
}

.accordion-detail-form-hotel div {
  width: 95%;
}

.accordion-detail-form-hotel div p {
  font-weight: 300;
  width: 95%;
  font-size: 13px;
  color: rgb(239, 238, 238);
}

.circle-icon-accordion {
  border-radius: 50%;
  padding: 0.2rem;
  border: solid 2px white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-persons-pay-hotel {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1rem;
  overflow-y: scroll;
  height: 0px;
  transition: all 0.3s ease;
}

.form-persons-pay-hotel.active {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1rem;
  height: 100%;
}

.form-persons-pay-hotel .camp-form-person-hotel {
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
}

.form-persons-pay-hotel .camp-form-person-hotel-doc {
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
}

.form-persons-pay-hotel .camp-form-person-hotel-doc label {
  color: var(--bluePrimary);
  font-size: 13px;
  font-weight: bold;
}

.form-persons-pay-hotel .camp-form-person-hotel-doc .contain-inputs-selecteds {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.form-persons-pay-hotel
  .camp-form-person-hotel-doc
  .contain-inputs-selecteds
  input {
  width: 100%;
  padding: 0.8rem 1rem;
  border-radius: 8px;
  border: solid 1px #d9d9d9;
}

.form-persons-pay-hotel
  .camp-form-person-hotel-doc
  .contain-inputs-selecteds
  .select-type-doc {
  background-color: transparent;
  position: relative;
  width: 100%;
  padding: 0.8rem 1rem;
  border-radius: 8px;
  border: solid 1px #d9d9d9;
}

.form-persons-pay-hotel
  .camp-form-person-hotel-doc
  .contain-inputs-selecteds
  .select-type-doc
  .modal-select-type-doc {
  width: 290px;
  position: absolute;
  top: 48px;
  left: 0px;
  background-color: white;
  padding: 15px;
  border-radius: 8px;
  border: 1px solid #d9d9d9;
}

.form-persons-pay-hotel
  .camp-form-person-hotel-doc
  .contain-inputs-selecteds
  .select-type-doc
  .modal-select-type-doc
  ul {
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.form-persons-pay-hotel
  .camp-form-person-hotel-doc
  .contain-inputs-selecteds
  .select-type-doc
  .modal-select-type-doc
  ul
  li {
  user-select: none;
  color: #676767;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
  transition: all 0.3s ease;
}

.form-persons-pay-hotel
  .camp-form-person-hotel-doc
  .contain-inputs-selecteds
  .select-type-doc
  .modal-select-type-doc
  ul
  li:hover {
  color: #3c3c3c;
}

.form-persons-pay-hotel
  .camp-form-person-hotel-doc
  .contain-inputs-selecteds
  .select-type-doc
  .select-onclick-modal-type {
    align-items: center;
    display: flex;
    width: 100%;
    justify-content: space-between;
    cursor: pointer;
    height: 100%;
}

.form-persons-pay-flight
  .camp-form-person-hotel-doc
  .contain-inputs-selecteds
  .select-type-doc
  .select-onclick-modal-type
  span {
  font-size: 11px;
  font-weight: 400;
  color: #333;
}

.form-persons-pay-hotel .camp-form-person-hotel label {
  color: var(--bluePrimary);
  font-size: 13px;
  font-weight: bold;
}

.form-persons-pay-hotel .camp-form-person-hotel input {
  padding: 0.8rem 1rem;
  border-radius: 8px;
  border: solid 1px #d9d9d9;
}

.btn-save-info-passenger {
  margin-top: 10px;
  width: fit-content;
  background-color: #004274;
  padding: 0.6rem 3rem;
  border-radius: 5px;
  font-weight: 400;
  font-size: 14px;
  cursor: pointer;
  transition: 0.3s ease;
  color: #fff;
}

.btn-save-info-passenger:hover {
  background-color: #024d87;
}

.err-hotel-form-person-pay {
  width: 100%;
  background-color: rgb(218, 115, 115);
  padding: 0.8rem;
  border-radius: 5px;
  color: #333;
  border: 1px solid #ff00001d;
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  color: #fff;
}

.typeTitle h2 {
  font-size: 18px;
  font-weight: 500;
}

.typeTitle P {
  font-size: 14px !important;
  font-weight: 400 !important;
}

.input-error {
  border: 1px solid rgb(255 141 141) !important;
}
