.footerContainer {
  width: 100%;
  background-color: #104172;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.footerContainerLogos {
  align-items: center;
  display: flex;
  width: 100%;
  max-width: 1280px;
  height: 140px;
  margin: 0 auto;
  padding: 0 1rem;
  justify-content: space-between;
}

.footerContainerLogosGroup {
  display: flex;
  width: 30%;
}

.footerContainerLogosGroupLeft {
  display: flex;
  align-items: center;
  justify-content: left;
}

.footerContainerLogosGroupCenter {
  display: flex;
  flex-direction: column;
}

.footerContainerLogosGroupCenterText {
  text-align: center;
  color: #ffffff;
  font-size: 14px;
  font-weight: bolder;
  font-family: "Montserrat", sans-serif;
  margin-bottom: 10px;
}

.footerContainerLogosGroupCenterRow {
  display: flex;
  align-items: center;
  justify-content: center;
}

.footerContainerLogosGroupRight {
  display: flex;
  align-items: center;
  justify-content: right;
}

.footerContainerLogosGroupRightTextContainer {
  display: flex;
  flex-direction: column;
  padding-left: 20px;
  margin-left: 10px;
  align-self: center;
  border-left: solid 2px #ffffff;
}

.footerContainerLogosGroupRightText {
  text-align: left;
  color: #ffffff;
  font-size: 18px;
  font-weight: 400;
  font-family: "Montserrat", sans-serif;
}

.footerLogoContainer {
  width: 80px;
  margin: 0 10px;
}

.footerContainerLogosGroup .small {
  width: 70px;
}

.footerContainerLogosGroup .big {
  width: 120px;
  cursor: pointer;
}

.footerContainerLogosGroup .big:hover .footerLogo {
  transform: translateY(-4px);
  scale: 1.1;
}

.footerContainerLogosGroup .big:active .footerLogo {
  transform: translateY(4px);
  scale: 0.99;
  filter: grayscale(5);
}

.footerLogo {
  transition: all ease 200ms;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.footerContainerInfo {
  padding: 20px 10px;
  background: #ededed;
  width: 100%;
  margin: 0 auto;
  align-items: center;
  display: flex;
}

.footerContainerInfoText {
  text-align: left;
  color: #000000;
  font-size: 12px;
  font-weight: 400;
  font-family: "Montserrat", sans-serif;
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
}

@media screen and (max-width: 1100px) {
  .footerContainerLogos {
    width: 95%;
  }
  .footerContainerInfoText {
    width: 95%;
  }
}

@media screen and (max-width: 700px) {
  .footerContainerLogos {
    flex-direction: column;
    height: auto;
  }
  .footerContainerLogosGroup {
    width: 80%;
    margin: 20px auto;
    justify-content: center;
  }
}

@media screen and (max-width: 400px) {
  .footerContainerLogosGroupCenterRow {
    flex-direction: column;
  }

  
.footerContainerInfoText {
   width: 100%;
  }
}
/*
azul claro #17AEE6
amarillo #FFD902
azul #104172
gris #EDEDED
*/
