.flightsAlliesSlider {
    width: 100%;
    height: 240px;
    z-index: 2;
    margin: 3rem 0;
    display: flex;
    justify-content: flex-end;
    
}
.flightsAlliesSlider_ {
    width: 100%;
    display: flex;
    z-index: 2;
    position: relative;
}
.flightsHeaderSlide_cards_ {
    display: flex;
    cursor: pointer;
}
.flightsAlliesSlider_date {
    position: relative;
    display: flex;
    justify-content: center;
}
.flightsAlliesSlider_img {
    width: 230px;
    height: 230px;
    border-radius: 20px;
    margin-left: 30px;
}
.flightsAlliesSlider_img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 20px;
    box-shadow: -4px 4px 4px rgba(0, 0, 0, 0.35) !important;
}
.flightsAlliesSlider_title {
    position: relative;
    left: 45px;
    bottom: 55px;
    width: 80%;
}
.flightsAlliesSlider_title h2 {
    font-family: 'Inter';
    font-size: 15px;
    text-transform: uppercase;
    color: #004274;
}

@media screen and (max-width: 900px) {
    .flightsAlliesSlider_ {
        width: 100%;
    }
}