.contain_filters{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.contain_filters h2{
    font-size: 16px;
    font-weight: 700;
    color: var(--bluePrimary);
}

.contain_filters .line{
    height: 1px;
    width:100%;
    background-color: var(--greyPrimary);
}


.contain_filters .filters{
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: .6rem;
    border-bottom: 1px solid var(--greyPrimary);
    padding-bottom: .6rem;
}

.contain_filters .filters span{
    font-size: 12px;
    color: var(--bluePrimary);
}

.contain_filters .filters select{
    border-radius: 6px;
    border: 1px solid var(--greyPrimary);
    padding: .5rem;
    font-size: 12px;
    appearance: none;
    background: url(https://cdn-icons-png.freepik.com/512/10017/10017916.png);
    background-repeat: no-repeat;
    background-size: contain;
    cursor: pointer;
    background-position: right;
}

.contentFilter {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.inputClose {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
    border: 1px solid var(--greyPrimary);
    border-radius: 8px;
    padding: .5rem;
    font-size: 12px;
}

.inputClose input {
    width: 100%;
    border: none;
    outline: none;
}

.buttonSearch {
    background-color: var(--bluePrimary);
    color: var(--white);
    padding: 8px;
    border-radius: 8px;
    font-size: 14px;
    cursor: pointer;
    transition: all 0.3s ease;
}

.buttonSearch:hover {
    background-color: var(--blueTertiary);
}

.closeButton {
    cursor: pointer;
    display: grid;
    place-items: center;
}