.sectionPriceDinamicContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 15px;
  max-width: 200px;
  width: 100%;
  height: 100%;
  border-left: 1px solid #d9d9d9;
}

.searchFlightsContainerHeaderInfo {
  width: 100%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.sectionPriceDinamicContainerConfig {
  width: 200px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.searchFlightsContainerInfo {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  font-size: 14px;
}

.searchFlightsInfoTitle {
  width: 50%;
}

.searchFlightsInfo {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.searchFlightsContainerHeaderOptionInfo {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 0 10px;
}

.sectionPriceDinamic {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.sectionPriceDinamicPrice {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 15px;
}

.textBig {
  color: #104172;
    font-weight: bolder;
    font-size: 22px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    gap: 3px;
}

.textMediun {
  display: flex;
  flex-direction: column;
  
  text-align: right;
  color: #104172;
  font-weight: 500;
  font-family: "Montserrat", sans-serif;
}

.textSmall {
  text-align: right;
  font-size: 12px;
  display: flex;
  flex-direction: column;
}

.textSmallWeight {
  width: 100%;
  font-weight: bolder;
  color: #104172;
}

.btn {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #104172;
  color: white;
  width: 100%;
  padding: 12px;
  text-align: center;
  font-weight: bolder;
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
  transition: all 0.3s ease;
}

.btn:hover {
  background-color: #185897;
}

.btnText {
  text-align: center;
  color: #ffffff;
  font-weight: bolder;
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
}

@media screen and (max-width: 600px) {
  .sectionPriceDinamicContainer {
    width: 100%;
    max-width: 100%;
    position: relative;
    justify-content: center;
    align-items: center;
    padding: 0px;
  }

  .sectionPriceDinamicPrice {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    padding: 15px;
  }

  .sectionPriceDinamic {
    margin-left: 0%;
    margin: auto;
  }

  .sectionPriceDinamic .textBig {
    text-align: center;
    font-size: 30px;
  }

  .sectionPriceDinamic .textMediun {
    text-align: center;
    font-size: 16px;
  }

  .sectionPriceDinamic .textSmall {
    padding-left: 0%;
    text-align: center;
    font-size: 12px;
  }
}
 
.priceResponsive {
  display: none;
  padding: 17px;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 600px) {
  .priceResponsive {
      display: flex !important;
  }
}
@media (max-width: 600px) {
  .none600 {
      display: none !important;
  }
}
.priceResponsive h3{
  font-size: 15px;
  color: #104172;
}
.priceResponsive p{
  font-size: 19px;
  color: #104172;
  font-weight: 650;
}
.priceResponsive button{
  background-color: #104172;
  padding: 7px 46px;
  border-radius: 23px;
  color: white;
  text-align: center;
  font-size: 18px;
  font-weight: 700;
}