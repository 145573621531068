.packages-section {
  width: 100%;
  margin: 3rem auto;
  max-width: 1280px;
}

.pagination-packages {
  margin: 3rem auto;
}

.layout-section-packages {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 2rem 0;
}

.layout-section-packages section {
  position: relative;
  width: 49.5%;
  min-width: 150px;
}

.css-yuzg60-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
  background: rgb(16, 65, 114, 1) !important;
  color: white !important;
  font-family: "Montserrat" !important;
  font-weight: 700 !important;
  font-size: 20px !important;
  width: 38px !important;
  height: 38px !important;
  border-radius: 50px !important;
}

.css-yuzg60-MuiButtonBase-root-MuiPaginationItem-root {
  font-family: "Montserrat" !important;
  font-weight: 700 !important;
  font-size: 20px !important;
  background-color: rgb(217, 217, 217);
  width: 38px !important;
  height: 38px !important;
  border-radius: 50px !important;
}

.css-wjh20t-MuiPagination-ul {
  height: 40px;
}

.title-packages-section {
  color: var(--bluePrimary);
  text-transform: uppercase;
  font-size: 36px;
  font-weight: 900;
}

.p-pack {
  font-size: 12px;
  margin-bottom: 1rem;
}

.contain-letters {
  position: absolute;
  width: 100%;
  top: 0;
  padding: 0.5rem;
  height: 100%;
  display: flex;
  align-items: end;
}

.contain-letters h2 {
  color: white;
  font-weight: 700;
  font-size: 35px;
}

.layout-section-packages img {
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 1100px) {
  .packages-section {
    width: 85%;
  }
}

@media screen and (max-width: 700px) {
  .contain-letters h2 {
    font-size: 25px;
  }

  .layout-section-packages {
    flex-direction: column;
    gap: 1rem;
  }

  .layout-section-packages section {
    width: 100%;
  }
}

.banner-bottom-package {
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
}

.banner-bottom-package img {
  width: 100%;
  border-radius: 13px;
}

.filtersPackages {
  width: 200px;
  min-width: 160px;
  max-width: 200px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.topFilterPackages {
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: .6rem;
  border-bottom: 1px solid var(--greyPrimary);
  padding-bottom: .6rem;
}

.topFilterPackages p {
  font-size: 16px;
  font-weight: 700;
  color: var(--bluePrimary);
}

@media (max-width: 1001px) {
  .filtersPackages {
    display: none;
  }
}

@media (min-width: 1000px) {
  .filtersPackages {
    display: flex;

  }
}

.buttonFiltersPackages {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  background-color: var(--bluePrimary);
  color: var(--white);
  padding: 13px 20px;
  border-radius: 8px;
  cursor: pointer;
  text-align: center;
  font-weight: 600;
  font-size: 16px;
  width: 100%;
}

.buttonFiltersPackages {
  display: none;
}

@media (max-width: 1000px) {
  .buttonFiltersPackages {
    display: flex;
  }
}

.containerPackages {
  display: flex;
  gap: 15px;
}

@media (max-width: 1000px) {
  .containerPackages {
    flex-direction: column;
  }
}

.filterDatePackages h3 {
  color: var(--bluePrimary);
  font-size: 16px;
  font-weight: 700;
  margin-bottom: .5rem;
}

.filterDatePackages p {
  color: var(--bluePrimary);
  font-size: 12px;
  margin-bottom: .4rem;
}

.filterDatePackages {
  padding-top: .6rem;
}

.filterDatePackages {
  border-bottom: 1px solid var(--greyPrimary);
  padding-bottom: .6rem;
}

.filterDatePackagesSelect select {
  border-radius: 6px;
  border: 1px solid var(--greyPrimary);
  padding: .5rem;
  font-size: 12px;
  appearance: none;
  background: url(https://cdn-icons-png.freepik.com/512/10017/10017916.png);
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
  background-position: right;
  width: 100%;
}

.searchButtonPackages {
  background-color: var(--bluePrimary);
  color: var(--white);
  padding: 8px;
  border-radius: 8px;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.3s ease;
  text-align: center;
  margin-top: 1rem;
}
.dialogFilterPackages{
  display: flex;
  flex-direction: column;
  width: 100%;
}
.topFilterPackagesDialog{
  width: 100%;
  justify-content: space-between;
  display: flex;
  gap: .6rem;
  border-bottom: 1px solid var(--greyPrimary);
  padding-bottom: .6rem;
  margin-bottom: 1.5rem;
}
.topFilterPackagesDialog p{
  font-size: 16px;
  font-weight: 700;
  color: var(--bluePrimary);
}
.topFilterPackagesDialog svg{
  color: var(--bluePrimary);
}