.destinatio-age{
    width: 100%;
    margin: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-block: 10px;
}

.add-age-container{
    width: 100%;
}

.input-age-container{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.input-label-age-container{
    width: 39%;
}

.container-add-age{
    width: 28%;
    display: flex;
    align-items: center;
}

.container-age{
    width: 68%;
    display: flex;
    align-items: center;
    border: 1px solid #D9D9D9;
    border-radius: 8px;
    height: 32px;
    font-weight: 400;
    font-size: 12px;
    margin-top: 10px;
}

 
.input-age{
    width: 55% !important;
    border-radius: 8px 0px 0px 8px !important;
    border-right: none !important;
}

.button-add{
    border-radius: 0px 8px 8px 0px !important;
    width: 45%;
    cursor: pointer;
    font-weight: 400;
    font-size: 12px;
    background-color: #0072B9;
    color: white;
    font-family: 'Montserrat' !important;
    height: 32px;
    margin-top: 10px;
}

.icon-button{
    font-size: 12px;
    font-weight: 400;
    color: white;
    background-color: #0072B9;
    text-align: center;
    width: 15%;
    border-radius: 30px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-inline: 5px ;
    border: 1px solid #0072B9;
}

.icon-button span{
    display: flex;
    justify-content: center;
    align-items: center;
}

.input-disable{
    background-color: #eeeeee;
}

.button-disable{
    margin-top: 10px;
    background-color: #0072b980;
    width: 45%;
    font-weight: 400;
    font-size: 12px;
    color: white;
    font-family: 'Montserrat' !important;
    border-radius: 0px 8px 8px 0px;
    height: 32px;
}