
.contained-items-bodyAndFooterCards{
    display: grid;
    grid-template-rows: auto auto;
    gap: 20px;
}
.items-bodyAndFooterCards{
    display: grid;
    grid-template-columns: 70% 30%;
    height: 40px;
    gap: 10px;
}
.buyFee{
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-family:'Inter' ;
    font-size: 20px;
    text-transform: capitalize;
}
.info-FooterTotalprice{
    display: grid;
    grid-template-columns: 65% 35%;
}
.itemFooter{
    background-color: #104172;
}
.totalTittle-FooterCards{
    display: flex;
    justify-content: start;
    align-items: center;
    color: white;
    font-family:'Inter' ;
    font-size: 20px;
    text-transform: capitalize;
}
.totalTittle-FooterCards p {
    margin-left: 10px;
}
.selectedCardsSegments{
    display: grid;
    grid-template-columns: auto auto;
    gap: 20px;
}
.detailSelectedSegments{  
margin: 0px 0px 10px 10px;
text-decoration: underline;
color: #4884C4;
cursor: pointer;
 }
 .details-tittle{
    display: flex;
    justify-content: center;
 }
.selectPlan-addLuggage_img {
    height: 35px;
}
.selectPlan-addLuggage_img img {
    height: 100%;
    object-fit: cover;
}
.planType{
    background-color: white !important;
   display: grid;
   grid-template-columns: 50% 50%;
}
.planType p { color: #104172 !important;}
.cardFamilies{
    margin: 30px 0% 0% 0%;
    display: grid;
    grid-template-rows: 10% 90%;
    height: 550px;
    background-color: #ffffff;
    width: 100%; 
}
.tittleBody{
    border: 1px solid #104172;
    background-color: #104172;
    height: 100%;
    margin: 0px 0px 0px 0px;
    display: flex;
    align-items: center;
    color: white;
}
.tittleBody-OneyWay{
    background-color: #104172;
    height: 100%;
    margin: 0px 0px 0px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    width: 100%;
}
.tittle-cardFamilies{
    background-color: #EDEDED;
    margin-top: 10px;
    display: grid;
    grid-template-rows: 94% 6%;
}
.textSaveFamilies{
    display: flex;
    justify-content: center;
    color: #4884C4;
    height: 100%;
    margin-top: 12px;
    cursor: pointer;
}
.buttons-tittleBody{
    display: grid;
    grid-template-columns: 60% 40%;
}
.ItemReady_img{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 25px ;
}
.ItemReady_img img{
  height: 100%;
  object-fit: cover;
}

.contained-AddServices{
    display: grid;
    grid-template-columns: 50% 50%;
}
.containedLuggage{
    display: grid;
    grid-template-columns:33% 33% 33% ;
}
.containedLuggage-center{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-family: 'Montserrat', sans-serif; font-size: 14px;
}
.tittle-bodyCards-infoFamilies{
  display: flex;
  justify-content: center;
  align-items: center;
    color: #104172;
    font-family:'Inter' ;
    font-size: 16px;
    font-weight: 600;

}
.bodycard-containedBody-tittleAndInfoFamilies{
    display: grid;
    grid-template-rows: 20% 80%;
}
.contained-warningFamilies{
    margin-top: 15px;
    display: grid;
    grid-template-rows: 50% 50%;
}
.warningFamilies{
    display: grid;
    grid-template-columns: 20% 80%;
    height: 35px;
}
.texFamilies-Strong{
    font-family:'Inter' ;
    color: white;
    font-weight:600;
    font-size: 20px;
    text-transform: capitalize;
}
.texFamilies-Strong-two{
    font-family:'Inter' ;
    font-weight:600;
    font-size: 14px;
    text-transform: capitalize;
}
.warning-Item{
    font-family: 'Montserrat', sans-serif;
}
