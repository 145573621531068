.contain-motive-consultation{
    width: 100%;
}

.contain-motive-consultation h2{
    font-size: 24px;
    color: var(--bluePrimary);
}

.contain-select-motive{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 2rem;
}

.camp-checkbox-motive{
    display: flex;
    align-items: center;
    gap: 6px;
}

.camp-checkbox-motive label{
    color: rgb(97, 97, 97);
    font-weight: 300;
    font-size: 18px;
}

.contain-describe-consultation{
    margin-top: 3rem;
    
}

.contain-describe-consultation h3{
    font-size: 24px;
    color: var(--bluePrimary);
}

.contain-describe-consultation textarea{
    width: 100%;
    min-height: 200px;
    font-size: 16px;
    margin-top: 8px;
    padding: 15px;
    border-radius: 5px;
    border-color: rgb(172, 172, 172);
    color: rgb(74, 74, 74);
}

@media screen and (max-width:1100px) {
    .contain-motive-consultation{
        width: 95%;
    }
   
}


@media screen and (max-width:500px) {
    .contain-motive-consultation h2{
        font-size: 20px;
    }
    .contain-describe-consultation h3{
        font-size: 17px;
        color: var(--bluePrimary);
    }
    .contain-describe-consultation textarea{
        font-size: 13px;
    }
    .camp-checkbox-motive label{
        font-size: 16px;
    }
}