.documentationContainer h1 {
    font-size: 2em;
    margin-bottom: 20px;
  }
  
  .documentationContainer section h2 {
    font-size: 1.5em;
    margin-bottom: 10px;
  }
  
  .documentationContainer section p {
    font-size: 1em;
    margin-bottom: 20px;
  }