:root {
  --font-family-form-plan: "Montserrat";
}

.form-plan-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 2;
}

.form-plan {
  width: 30%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  box-shadow: 0px 4px 4px 0px #00000040;
  border-radius: 0px 0px 24.5px 25.5px;
  padding: 20px;
  background-color: white;
  z-index: 2;
}

.form-header-container {
  background-color: #0072b9;
  color: white;
  width: 30%;
  height: 62px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 25.5px 25.5px 0px 0px;
  border: 1px solid #d9d9d9;
  padding-inline: 20px;
}

.form-header-container p {
  font-size: 16px;
  font-weight: 900;
  font-family: var(--font-family-form-plan) !important;
}

.input {
  width: 95%;
}

.input-style {
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  width: 100%;
  height: 32px;
  background-position: right 10px center;
  padding-inline: 10px;
  margin-top: 10px;
  font-family: var(--font-family-form-plan) !important;
  font-weight: 400;
}

.container-select-place {
  width: 59%;
}

.container-select-date {
  width: 39%;
}

label {
  font-size: 12px;
  font-weight: 400;
  font-family: var(--font-family-form-plan) !important;
}

.email-multi-trip {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.input-multi-trip {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  width: 19%;
}

.label-multi-trip {
  margin-top: -31px;
}

.input-email-terrawind {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  width: 79%;
}

.input-email-terrawind input {
  width: 100%;
  height: 32px;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  padding-inline: 10px;
  margin-top: 10px;
}

.button-quote {
  background-color: #0072b9;
  border: #0072b9;
  color: white;
  font-weight: 400;
  font-size: 12px;
  font-family: "Montserrat" !important;
  width: 30%;
  cursor: pointer;
}

.quote-now {
  display: flex;
  justify-content: center;
  width: 100%;
}

.text-country {
  font-size: 12px;
  font-weight: 400;
  margin-block: 30px;
  line-height: 15px;
}

.link-country {
  font-size: 12px;
  font-weight: 700;
  color: #0072b9;
  text-decoration: none;
}

.div-price {
  border: none;
  border-top: 1px solid #0072b9;
  color: #0072b9;
  background-color: #0072b9;
  margin-top: 10px;
  margin-bottom: 35px;
}

@media screen and (max-width: 1000px) {
  .place-date-day {
    flex-direction: column;
  }

  .input {
    width: 100%;
  }

  .destinatio-age {
    flex-direction: column;
  }
}

.validate-p {
  color: red;
  font-family: "Montserrat" !important;
  font-size: 10px;
  font-weight: 400;
  margin-bottom: 10px;
  margin-left: 5px;
}

.input-validate {
  border: 1px solid red !important;
}

@media screen and (max-width: 1800px) {
  .form-plan {
    width: 35%;
  }

  .form-header-container {
    width: 35%;
  }
}

@media screen and (max-width: 1507px) {
  .form-plan {
    width: 40%;
  }

  .form-header-container {
    width: 40%;
  }
}

@media screen and (max-width: 1360px) {
  .form-plan {
    width: 45%;
  }

  .form-header-container {
    width: 45%;
  }
}

@media screen and (max-width: 1200px) {
  .form-plan {
    width: 50%;
  }

  .form-header-container {
    width: 50%;
  }
}

@media screen and (max-width: 1000px) {
  .container-select-place {
    width: 100%;
  }

  .container-select-date {
    width: 100%;
  }
}

@media screen and (max-width: 850px) {
  .form-plan {
    width: 60%;
  }

  .form-header-container {
    width: 60%;
  }
}

@media screen and (max-width: 700px) {
  .form-plan {
    width: 70%;
  }

  .form-header-container {
    width: 70%;
  }
}

@media screen and (max-width: 600px) {
  .form-plan {
    width: 80%;
  }

  .form-header-container {
    width: 80%;
  }
}

@media screen and (max-width: 500px) {
  .form-plan {
    width: 90%;
  }

  .form-header-container {
    width: 90%;
  }
}
