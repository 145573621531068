.homeTurism {
  font-family: "Inter";
  text-align: center;
  margin: 32px auto;
  width: 70%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.homeTurism a {
  color: black;
}
.div_homeTurism {
  display: flex;
  align-items: center;
}

.homeTurism h1 {
  font-size: 30px;
  font-weight: 400;
}

.homeTurism .homeTurism_titleLink {
  width: 120px;
  height: 40px;
}

.slider_container {
  display: flex;
  gap: 2rem;
  align-items: center;
  width: 100%;
  height: 100%;
}

.slider_ {
  display: flex;
  justify-content: space-between !important;
  height: 100%;
  width: 100%;
}

.sliderNews_ {
  padding: 30px;
  display: flex;
  gap: 30px;
  align-items: start;
  justify-content: space-between !important;
  height: 100%;
  width: 100%;
}

.Grid_homeTurism {
  justify-content: center;
}
.homeTurism_title {
  display: block !important;
}
.grid_cardHomeTurism {
  margin: 10px 10px !important;
}

.control-carousel {
  height: 20px;
  background-color: #004274;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  color: white;
}

.control-carousel p {
  user-select: none;
}

/*Hovers*/

.homeTurism_titleLink h1:hover {
  text-decoration-line: underline;
}

@media screen and (max-width: 1600px) {
  .homeTurism {
    width: 80%;
  }
}

@media screen and (max-width: 1250px) {
  .homeTurism {
    width: 90%;
  }
}

@media screen and (max-width: 1100px) {
  .homeTurism {
    width: 100% !important;
  }
}

@media screen and (max-width: 500px) {
  .homeTurism {
    margin-bottom: 0;
  }
 
  /* .cardHomeTurism_media {
    width: 100%;
    height: 200px;
  }
  .cardHomeTurism_media img {
    width: 100% !important;
    height: 100% !important;
  } */
  .grid_cardHomeTurism {
    margin: 10px 10px !important;
  }
}
