.slider-content {
  display: flex;
  justify-content: space-between;
  position: relative;
  max-width: 59rem;
  width: 100%;
  gap: 15px;
  transition: transform 0.8s ease;
}

.slider-container {
  position: relative;
  width: 100%;
  max-width: 59rem;
  margin: auto;
  overflow: hidden;
}

.slider-wrapper {
  display: flex;
}

@keyframes skeleton-loading {
  0% {
    background-position: 100% 0;
  }

  100% {
    background-position: -100% 0;
  }
}

.img-home-skeleton {
  width: 100%;
  height: 100%;
  min-width: 280px;
  min-height: 360px;
  max-height: 360px;
  object-fit: cover;
  background: linear-gradient(to left, #ffffff 25%, #f0f0f0 50%, #ffffff 75%);
  background-size: 600% 100%;
  animation: skeleton-loading 2s infinite linear;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
}

.skeleton-home-img {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
}

.content-home-skeleton {
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 100px;
  padding: 10px;
}

.item-home-skeleton {
  width: 100%;
  height: 100%;
  min-width: 20px;
  max-height: 20px;
  object-fit: cover;
  background: linear-gradient(to left, #ffffff 25%, #f0f0f0 50%, #ffffff 75%);
  background-size: 600% 100%;
  animation: skeleton-loading 2s infinite linear;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
}

.slider-offerst-ca-not-items {
  color: var(--white);
  width: 100%;
  text-align: center;
}

.slider-offerst-ca {
  width: 80%;
}


.slider-offerst-ca .slick-prev:before,
.slider-offerst-ca .slick-next:before {
  color: rgb(255, 255, 255) !important;
  font-size: 40px !important;
}


.slider-offerst-ca .slick-next {
  right: 50px !important;
}

.slider-offerst-ca .slick-prev {
  left: 20px !important;
}

.slider-offerst-ca .slick-track {
  margin: 1rem 0;
}

.slider-card {
  background-color: #ffffff;
  box-sizing: border-box;
  border-radius: 20px;
  width: 96%;
}

.slider-card img {
  width: 100%;
  height: 100%;
  max-height: 320px;
  object-fit: cover;
  border-radius: 20px 20px 0 0;
}


.slider-cardLowCost {
  background-color: #ffffff;
  box-sizing: border-box;
  border-radius: 20px;
  width: 96%;
}

.slider-cardLowCost img {
  width: 100%;
  height: 100%;
  max-height: 320px;
  object-fit: cover;
  border-radius: 20px 20px 0 0;
}

.image-container {
  position: relative;
}

.icon-container {
  position: absolute;
  top: 0px;
  left: 36px;
  background: linear-gradient(to bottom, #00afe8 50%, transparent 50%);
  z-index: 1;
  width: 50px;
  height: 100px; 
}

.important-icon {
  color: #00afe8;
  font-size: 85px;
  transform: rotate(270deg);
  width: auto;
  margin-left: -17.5px;
}

@media (min-width: 791px) {
  .important-icon {
    font-size: 86px;
    margin-left: -18.1px;
  }
}

.star-icon-container {
  position: absolute;
  top: 30%; 
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}

.star-icon {
  color: white; 
  font-size: 40px; 
}

@media screen and (max-width: 500px) {
  .slider-cardLowCost {
    width: 100% !important;
    max-width: none !important;
  }
}


.icon-container-tourist {
  position: absolute;
  top: 0px;
  left: 36px;
  background: linear-gradient(to bottom, #004274 50%, transparent 50%);
  z-index: 1;
  width: 50px;
  height: 100px; 
}
.important-icon-tourist {
  color: #004274;
  font-size: 85px;
  transform: rotate(270deg);
  width: auto;
  margin-left: -17.5px;
}

@media (min-width: 791px) {
  .important-icon-tourist {
    font-size: 86px;
    margin-left: -18.1px;
  }
}

.star-icon-container-tourist {
  position: absolute;
  top: 30%; 
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}

.star-icon-tourist {
  color: white; 
  font-size: 40px; 
}

@media screen and (max-width: 500px) {
  .slider-cardLowCost-tourist {
    width: 100% !important;
    max-width: none !important;
  }
}

.slider-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: rgba(255, 255, 255, 0.838);
  border-radius: 50%;
  cursor: pointer;
  z-index: 9999;
}

.slider-button.prev {
  left: -25px;
  transition: all 0.3s ease;
}



.slider-button.next {
  right: -25px;
  transition: all 0.3s ease;
}



.ofertsTurismSaludCardContent {
  display: flex;
  flex-direction: column;
  gap: 10px
}

.ofertsTurismSaludCardText {
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow: hidden;
}

.ofertsTurismSaludCardText p {
  font-size: 12px;
}

.ofertsTurismSaludCardButtons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 20px;
  margin-bottom: 30px;
}


.ofertsTurismSaludCardTitle {
  font-size: 16px;
  font-weight: 700;
  text-wrap: nowrap;
  text-transform: uppercase;
}

.ofertsTurismSaludCardButtonn {
  background-color: #104172;
  color: #ffffff;
  padding: 13px;
  border-radius: 0 20px 20px 0;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
}

.ofertsLink {
  position: relative;
  display: inline-block;
}

.ofertsLink::after {
  content: "";
  position: absolute;
  width: 0;
  height: 1px;
  /* altura del underline */
  background-color: #104172;
  /* color del underline */
  bottom: 0;
  left: 0;
  transition: width 0.5s ease-in-out;
  /* tiempo de transición */
}

.ofertsLink:hover::after {
  width: 100%;
}


@media screen and (max-width:1300px) {
  .slider-offerst-ca {
    width: 100%;
  }
}

@media screen and (max-width:500px) {
  .slider-card {
    width: 100% !important;
    max-width: none !important;
  }

  .slider-button.prev {
    left: -5px;
  }

  .slider-button.next {
    right: 0px;
    transition: all 0.3s ease;
  }

}

.mobile-slider .slick-slide {
  padding: 0 8px;
  /* Ajusta el valor del gap según lo que necesites */
}