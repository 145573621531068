.ModalChange {
    position: fixed;
    background-color: #021624b0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 1;
    transition: all 0.2s ease;
    padding: 30px;
  }
  
  .ModalChange.close {
    position: fixed;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    pointer-events: none;
  }
  
  .ModalChangeContent {
    display: flex;
    flex-direction: column;
    gap: 15px;
    background-color: white;
    width: 100%;
    max-width: 680px;
    border-radius: 12px;
    padding: 20px;
    position: relative;
    opacity: 1;
    transition: all 0.3s ease;
    transform: translateY(0px);
    z-index: 99999;
  }
  
  .ModalChangeContent.close {
    background-color: white;
    width: 100%;
    width: 100%;
    max-width: 680px;
    border-radius: 10px;
    padding: 20px;
    position: relative;
    opacity: 0;
    transition: all 0.3s ease;
    transform: translateY(-100px);
  }
  
  .ModalChangeCloseButton {
    position: absolute;
    right: 7px;
    top: 7px;
    cursor: pointer;
    z-index: 999999;
    background: #fff;
    padding: 5px;
  }