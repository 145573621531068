.searchFlightsContainerSticky {
  background-color: #ffffff;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
  height: 100%;
  border-radius: 20px;
  margin: 2rem auto;
  transition: all 0.3s ease;
}

.searchFlightsContainerSticky.padding {
  padding: 20px;
}

/* ESTILOS DE LOS INPUTS EN LA PAGE DE FLIGTHS */
.input-destiny-origin-fligth {
  width: 100%;
  border: #d9d9d9 solid 1px;
  border-radius: 10px;
  padding: 0.4rem;
  margin-bottom: 0.5rem;
}

.input-destiny-origin-fligth .content-inputs {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.input-destiny-origin-fligth .content-inputs div .label-destiny {
  font-size: 14px;
  font-weight: 300;
}

.input-destiny-origin-fligth .content-inputs div .select-destiny-input {
  color: var(--bluePrimary);
  font-size: 12px;
  font-weight: bold;
}
.inputs-triple-flight {
  border: #d9d9d9 solid 1px;
  border-radius: 10px;
  padding: 0.4rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  cursor: pointer;
  position: relative;
}

.inputs-triple-flight .contain-label-date {
  display: flex;
  flex-direction: column;
}

.inputs-triple-flight .contain-label-date .label-destiny {
  font-size: 14px;
  font-weight: 300;
}

.inputs-triple-flight .contain-label-date .select-destiny-input {
  color: var(--bluePrimary);
  font-size: 12px;
  font-weight: bold;
  height: 15px;
  overflow: hidden;
}

.content-icons-passengers {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 0.3rem;
  width: 100%;
}

.content-icons-passengers section {
  width: 33.33%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.button-search-flight {
  background-color: var(--bluePrimary);
  transition: all ease 200ms;
  border: solid 0.5px #a8a9ad;
  cursor: pointer;
  display: flex;
  padding: 10px;
  border-radius: 20px;
  height: 50px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.display-button-search {
  display: flex;
  gap: 0.5rem;
}

.searchFlightsContainerHeader {
  display: flex;
  width: 100%;
  gap: 50px;
}

.searchFlightsContainerHeaderFlexcolumn {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
}

.searchFlightsRow {
  display: flex;
  flex-direction: row;
  gap: 5px;
}

.searchFlightsColumn {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.searchFlightsContainerHeaderOption {
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-right: 25px;
  gap: 10px;
}

.searchFlightsContainerHeaderOptionTextBig {
  margin-left: 5px;
  font-size: 14px;
  color: #004274;
  font-weight: bold;
  font-family: "Montserrat", sans-serif;
}

.searchFlightsContainerHeaderOptionTextSmall {
  margin-left: 5px;
  font-size: 14px;
  color: #004274;
  font-weight: 400;
  font-family: "Montserrat", sans-serif;
}

.searchFlightsContainerBody {
  margin-top: 10px;
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  transition: all ease 200ms;
}

.searchFlightsContainerBodyVertical {
  display: block;
  justify-content: space-between;
}

.searchFlightsContainerBodyBorder {
  transition: all ease 200ms;
  background-color: #ffffff;
  border: solid 0.5px #a8a9ad;
  cursor: pointer;
  display: flex;
  padding: 10px;
  border-radius: 10px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: relative;
}

.searchFlightsContainerBodyOptionTexts {
  display: block;
  width: 100%;
}

.searchFlightsContainerRowBeta {
  gap: 0.5rem;
}

.searchFlightsContainerBodyOptionTextBig {
  font-size: 14px;
  height: 16px;
  overflow: hidden;
  font-weight: bolder;
  color: #004274;
}

.searchFlightsContainerBodyOptionTextSmall {
  font-size: 12px;
  font-weight: 400;
  color: #000000;
}

#width_A {
  position: relative;
  flex-grow: 1;
  flex-basis: 200px;
  transition: all ease 200ms;
}

.dateRangeOnly div {
  width: 100%;
}

#width_B {
  width: 100%;
  margin-bottom: 10px;
  position: relative;
}

#width_C {
  flex-grow: 1;
  flex-basis: 55px;
  transition: all ease 200ms;
}

#width_D {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: auto;
  transition: all ease 200ms;
}

#width_E {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.searchFlightsContainerRowAlfa {
  margin-bottom: 10px;
  justify-content: space-between;
  position: relative;
}

.searchFlightsContainerRowBeta {
  width: 100%;
  flex-wrap: wrap;
  transition: all ease 200ms;
}

.searchFlightsContainerPlace {
  width: 350px;
  max-height: 390px;
  border-radius: 20px;
  background-color: #ffffff;
  position: absolute;
  z-index: 999;
  top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.35) !important;
}

.searchFlightsContainerPass {
  width: 90%;
  max-height: 600px;
  border-radius: 20px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.35) !important;
}

.searchFlightsContainerDate {
  width: 100%;
}

/* estilos del search fligth horizontal home */

.contain-btn-camps-flight {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.content-all-camps-search {
  width: 100%;
  display: flex;
  gap: 0.5rem;
}

/* .btn-search-flight-horizontal {
  background-color: var(--bluePrimary);
  padding: 0.5rem;
  padding-right: 1rem;
  border-radius: 8px;
  display: flex;
  gap: 0.5rem;
  color: #ffffff;
  align-items: center;
  font-size: 18px;
  font-weight: 700;
  cursor: pointer;
}

.btn-search-flight-horizontal svg {
  width: 40px !important;
  height: 40px !important;
} */

.btn-search-flight-horizontal {
  cursor: pointer;
  background-color: var(--bluePrimary);
  font-family: inherit;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 2px 15px;
  line-height: 2.5em;
  position: relative;
  overflow: hidden;
  border: 2px solid var(--bluePrimary);
  transition: color 0.5s;
  z-index: 1;
  font-size: 18px;
  border-radius: 9px;
  font-weight: 600;
  color: white;
}

.btn-search-flight-horizontal:before {
  content: "";
  position: absolute;
  z-index: -1;
  background: white;
  color: var(--bluePrimary);
  height: 550px;
  width: 1500px;
  border-radius: 50%;
}

.btn-search-flight-horizontal:hover {
  color: var(--bluePrimary);
}

.btn-search-flight-horizontal:before {
  top: 100%;
  left: 100%;
  transition: all 0.7s;
}

.btn-search-flight-horizontal:hover:before {
  top: -100px;
  left: -200px;
}

.btn-search-flight-horizontal:active:before {
  background: white;
  transition: background 0s;
}

.input-destiny-origin {
  border: #d9d9d9 solid 1px;
  border-radius: 10px;
  padding: 0.4rem;
  width: 50%;
  cursor: pointer;
  height: 100%;
}

.input-destiny-origin .content-inputs {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.content-inputs-destiny-dual {
  width: 100%;
  display: flex;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
}

.input-destiny-origin .content-inputs div .label-destiny {
  font-size: 14px;
  font-weight: 300;
}

.input-destiny-origin .content-inputs div .select-destiny-input {
  color: var(--bluePrimary);
  font-size: 12px;
  font-weight: bold;
}

.contend-inputs-date-passenger {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  position: relative;
}

.content-inputs .first-section {
  position: relative;
  width: 100%;
}

.content-inputs svg:nth-child(2) {
  width: 20% !important;
}

.content-inputs .value-origin-destiny {
  color: var(--bluePrimary);
  font-weight: 500;
  font-size: 14px;
  height: 19px;
  width: 100%;
  overflow: hidden;
}
.contend-inputs-date-passenger .inputs-triple {
  border: var(--greyPrimary) solid 1px;
  border-radius: 10px;
  padding: 0.4rem;
  width: 100%;
  display: flex;
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  position: relative;
}

.contend-inputs-date-passenger .inputs-triple .contain-label-date {
  display: flex;
  flex-direction: column;
}

.contend-inputs-date-passenger
  .inputs-triple
  .contain-label-date
  .label-destiny {
  font-size: 14px;
  font-weight: 300;
}

.label-destiny-message {
  color: #ff3f3f;
  font-weight: 500;
}

.contend-inputs-date-passenger
  .inputs-triple
  .contain-label-date
  .select-destiny-input {
  color: var(--bluePrimary);
  font-size: 12px;
  font-weight: bold;
}

@media screen and (max-width: 550px) {
  .content-inputs-destiny-dual {
   flex-direction: column;
  }

  .input-destiny-origin {
    width: 100%;
  }

  .contend-inputs-date-passenger {
    flex-direction: column;
  }
}

@media screen and (max-width: 900px) {
  .btn-search-flight-horizontal {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media screen and (max-width: 1400px) {
  .btn-search-flight-horizontal svg {
    width: 35px !important;
    height: 35px !important;
  }
}

@media screen and (max-width: 860px) {
  .searchFlightsContainerBody {
    flex-wrap: wrap;
    /* gap: 1rem; */
  }

  .searchFlightsRow {
    margin: 5px 0px;
  }

  .searchFlightsContainerRowBeta {
    width: 100%;
  }

  #width_C {
    width: 100%;
  }
}

@media screen and (max-width: 600px) {
  .searchFlightsContainerRowAlfa {
    flex-direction: column;
    gap: 0.7rem;
  }

  /* cambio de estuctura del home responsive */
  .contain-btn-camps-flight {
    flex-direction: column;
    gap: 0.5rem;
  }

  .content-all-camps-search {
    width: 100%;
  }

  .btn-search-flight-horizontal {
    width: 100%;
  }
}

@media screen and (max-width: 410px) {
  .display-button-search {
    flex-direction: column;
  }
  #width_D {
    width: 100%;
  }

  /* font size the home desing flight */
  .contend-inputs-date-passenger
    .inputs-triple
    .contain-label-date
    .label-destiny,
  .input-destiny-origin .content-inputs div .label-destiny {
    font-size: 10px;
  }

  .contend-inputs-date-passenger
    .inputs-triple
    .contain-label-date
    .select-destiny-input,
  .input-destiny-origin .content-inputs div .select-destiny-input {
    font-size: 9px;
  }
}

@media screen and (max-width: 900px) {
  .contain-btn-camps-flight {
    display: flex;
    flex-wrap: wrap;
    justify-content: end;
    align-items: end;
    gap: 30px;
  }

  .content-all-camps-search {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
  }

  .searchFlightsContainerHeader {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    gap: 10px;
  }
}
