.layoutMax {
  width: 95%;
  max-width: 1280px;
  margin: 0 auto;
  margin-bottom: 40px;
}

.layout {
  width: 100%;
  max-width: 1280px;
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: space-between;
}

.divcheck input {
  appearance: none;
  border: 1px solid #d9d9d9;
  border-radius: 50% !important;
  width: 100%;
  padding: 0 !important;
}

.form {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 2rem;

  label {
    font-weight: 500;
    font-size: 16px;
    color: var(--bluePrimary);
  }

  input {
    appearance: none;
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    width: 100%;
    padding: 0.4rem;
  }

  /* select {
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    width: 100%;
    padding: 0.4rem;
    appearance: none;
    background-image: url("https://static.thenounproject.com/png/1590826-200.png");
    background-size: contain;
    background-position: right;
    background-clip: content-box;
    background-repeat: no-repeat;
  } */ 
}

.formTitle {
  display: flex;
  flex-direction: column;
  gap: 2px;
  border-bottom: 2px solid var(--bluePrimary);
  padding-bottom: 20px;
}

.formTitle p {
  font-size: 16px;
  font-weight: 400;
  color: var(--bluePrimary);
}

.formTitle legend {
  font-size: 24px;
  font-weight: 700;
  color: var(--bluePrimary);
}

.formTitleItem h4 {
  font-size: 18px;
  font-weight: 600;
  color: var(--bluePrimary);
}

.formTitleItem p {
  font-size: 15px;
  font-weight: 400;
  color: var(--bluePrimary);
}

.formSeparationInfo {
  display: flex;
  justify-content: space-between;
  gap: 5rem;
}

.formSeparationItem {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}



.formTitleItemAnyDescription {
  padding-top: 30px;
  font-size: 15px;
  font-weight: 400;
  color: var(--bluePrimary);
}

.formItemsCheckboxes {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.formItemsData {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.form .camp-three {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  width: 100%;
}

.form .camp-three .left {
  width: 60%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  position: relative;
}

.selectedDate {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  align-items: center;
  appearance: none;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  width: 100%;
  padding: 0.4rem;
  font-size: 14px;
  cursor: pointer;
}

.form .camp-three .center {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.form .camp-three .right {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.form .camp-two {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 100%;
}

.form .camp-two .center {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  position: relative;
}

.form .camp-two .right {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  position: relative;
}

.camp-two label {
  font-size: 16px;
}

.camp-three label {
  font-size: 16px;
}

.form .camp-one {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
}

.form .camp-two-check {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.form .camp-two-check-column {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
}

.camp-two-check-column p span {
  font-weight: 600;
  font-size: 15px;
}

.camp-two-check-column p {
  font-weight: 400;
  font-size: 15px;
  color: #004274;
}

.camp-short {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.camp-middle {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 48%;
}



.camp-two-check-middle {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.camp-two-check-middle label {
  font-weight: 400;
  color: var(--bluePrimary);
}

.checkboxCont {
  display: flex;
  align-items: center;
  gap: .7rem;
}

.previewDate {
  width: 100%;
}

.contPrev {
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 2rem;

  h2 {
    color: var(--bluePrimary);
    font-size: 12px;
    font-weight: 700;
  }
}

.contContinue {
  width: fit-content;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.title {
  display: flex;
  align-items: center;
  gap: 0.5rem;

  span {
    background-color: var(--bluePrimary);
    padding: 0.2rem;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  div {
    display: flex;
    flex-direction: column;
  }

  h3 {
    font-size: 12px;
    font-weight: 700;
    color: var(--bluePrimary);
  }

  p {
    font-size: 12px;
  }
}

.contPrev .description {
  font-size: 12px;
}

.contPrev .listData {
  display: flex;
  align-items: center;
  justify-content: space-between;

  article {
    width: 32%;
    display: flex;
    flex-direction: column;

    span {
      font-size: 12px;
    }

    p {
      font-size: 14px;
      font-weight: 700;
      color: var(--bluePrimary);
    }
  }
}

.contNote {
  background-color: var(--blueWhiteTable);
  padding: 1rem;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 5px;

  span {
    font-size: 16px;
    font-weight: 700;
    color: var(--bluePrimary);
    margin-bottom: 0.5rem;
  }

  p {
    font-size: 14px;
  }
}

.btnContinue {
  width: 100%;
  background-color: var(--bluePrimary);
  border-radius: 8px;
  color: var(--white);
  padding: 10px 25px;
  font-size: 16px;
  font-weight: 500;
  transition: all 0.3s ease;
  cursor: pointer;
  gap: 10px;
  text-align: center;
}

.btnContinue:hover {
  background-color: var(--blueTertiary);
}

.checkTerms {
  display: flex;
  align-items: center;
  gap: 0.5rem;

  input {
    appearance: none;
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    width: 100%;
    padding: 0.4rem;
  }

  input[type="checkbox"] {
    width: 20px;
    height: 20px;
    border-radius: 2px;
  }

  input:checked {
    background-image: url("https://www.shutterstock.com/image-vector/check-mark-icon-color-editable-600nw-2474013721.jpg");
    background-position: center;
    background-size: contain;
  }

  label {
    font-size: 12px;
    color: var(--bluePrimary);

    span {
      color: var(--blueLight);
      cursor: pointer;
      text-decoration: underline;
    }
  }
}

.obligatory {
  border-color: var(--redPrimary) !important;
}

.container_photos {
  background-color: var(--blueWhiteTable);
  padding: 1rem;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  p {
    color: var(--bluePrimary);
    font-weight: 700;
  }
}

.grid_container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  max-width: 600px;
  margin: auto;

  img {
    width: 100%;
    aspect-ratio: 1 / 1;
    /* Mantiene una proporción cuadrada */
    object-fit: cover;
    /* Ajusta la imagen al contenedor */
    height: auto;
    border-radius: 5px;
    border: 2px solid var(--bluePrimary);
  }
}


@media screen and (max-width: 900px) {
  .layout {
    flex-direction: column;
  }
}

@media screen and (max-width: 500px) {
  .contPrev .listData {
    article {
      span {
        font-size: 12px;
      }

      p {
        font-size: 10px;
      }
    }
  }
}

.itemSectionAll {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

@media (max-width: 1350px) {
  .itemSectionAll {
    overflow: scroll;
  }
}


.containerInputPhone {
  display: flex;
  gap: 10px;
  align-items: center;
}

.inputPrefix {
  width: 15%;
}

.inputPhone {
  width: 85%;
}

.divOptions {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 1.2rem;
}

.divOptions span {
  color: var(--bluePrimary);
}


@media screen and (max-width: 1024px) {
  .formSeparationInfo {
    gap: 2rem;
  }
}

@media screen and (max-width: 768px) {
  .formSeparationInfo {
    flex-direction: column;
    gap: 10px;
  }

  .contContinue {
    width: 100%;
  }
}

.buttonArrow {
  display: grid;
  place-items: center;
  cursor: pointer;
  color: var(--bluePrimary);
  transition: all 0.3s ease;
}

.buttonArrow.buttonArrowOpen {
  transform: rotate(90deg);
  transition: all 0.3s ease;
}
.mainDialogStd{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2.1rem;
  width: 100%;
}
.titleDialogStd{
  text-align: center;
  width: 100%;
  margin-top: 1.5rem;

}
.titleDialogStd h4{
  font-size: 1rem !important;
  line-height: 1.5rem !important;
  font-weight: 700;
  color: var(--bluePrimary);
  width: 70%;
  margin: 0 auto;
}
.contentDialogStd{
  text-align: center;
  width: 100%; 
}
.contentDialogStd p{
  font-size: 1rem !important;
  line-height: 1.5rem !important;
  font-weight: 500;
  color: black;
  width: 70%;
  margin: 0 auto;
}
.buttonsDialogStd{
  display: flex;
  gap: 1.5rem;
  margin-bottom: 1.5rem;
}
.cancelDialogStd{

}