.price-packages-s {
  margin-left: auto;
}

.detail-package {
  padding-inline: 110px;
  margin-bottom: 50px;
}

.container-price-description {
  display: flex;
  justify-content: space-between;
}

.colum-description {
  width: 64%;
  text-align: left;
}

.title-main-package {
  font-size: 36px !important;
}

.titles-packages {
  color: #004274;
  font-weight: 900;
  font-size: 20px;
  font-family: "Montserrat" !important;
}

.paragraphs-packages {
  margin-block: 30px;
}

.paragraphs-packages {
  font-size: 15px;
  font-weight: 400;
  font-family: "Montserrat" !important;

}

.colum-price {
  width: 34%;
}

.container-price {
  text-align: left;
  box-shadow: 0px 4px 4px 0px #00000040;
  border-radius: 27px;
  padding: 40px;
}

.price-title p {
  color: #004274;
  font-weight: 900;
  font-family: "Montserrat" !important;
}

.p-price-title {
  font-size: 20px;
}

.p-price-money {
  font-size: 36px;
}

@media screen and (max-width: 1200px) {
  .p-price-money {
    font-size: 30px;
  }
}

@media screen and (max-width: 1100px) {
  .p-price-money {
    font-size: 25px;
  }
}

@media screen and (max-width: 1050px) {
  .p-price-money {
    font-size: 36px;
  }
}

@media screen and (max-width: 550px) {
  .p-price-money {
    font-size: 30px;
  }
}

@media screen and (max-width: 480px) {
  .p-price-money {
    font-size: 25px;
  }
}


.p-description {
  margin-top: 25px;
}

.p-description p {
  font-size: 12px;
  font-weight: 400;
  font-family: "Montserrat" !important;
}

.p-days-package {
  margin-block: 10px;
}

.p-days-package p {
  color: #004274;
  font-size: 20px;
  font-family: "Montserrat" !important;
  font-weight: 700;
}

.btn-calender-package {
  margin-top: 30px;
}

.btn-days-package {
  width: 100%;
  border: 1px solid #D9D9D9;
  height: 45px;
  border-radius: 8px;
  font-size: 15px;
  font-weight: 700;
  color: #004274;
  cursor: pointer;
}

.btn-days-package div {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.btn-people-package {
  margin-block: 30px;
  padding-inline: 10px;
}

.btn-people-package div {
  display: flex;
  justify-content: left;
  align-items: center;
}

.btn-people-package p {
  margin-inline: 10px;
}

.container-loading-packages {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 700px;
}

@media screen and (max-width: 1050px) {
  .container-price-description {
    flex-direction: column-reverse;
  }

  .colum-description {
    width: 100%;
    margin-top: 30px;
  }

  .colum-price {
    width: 100%;
  }
}

.p-destination-package {
  color: #004274;
  font-family: "Montserrat" !important;
  font-weight: 500;
}

.title-search-package {
  margin-bottom: 50px;
}

.title-search-package h2 {
  font-size: 36px;
  font-weight: 900;
  font-family: "Montserrat" !important;
  color: #004274;
}

.description-search-package {
  text-align: justify;
  margin-block: 50px;
}

.description-search-package p {
  font-size: 14px;
  font-weight: 400;
  font-family: "Montserrat" !important;
  line-height: 1.5;
}

.formMedicalPackage {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.topReservePackage {
  color: #104172;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: .5rem;
}

.topReservePackage svg {
  font-size: 31px;
  cursor: pointer;
}

.topReservePackage p {
  font-size: 19px;
  font-weight: 600;
}

.InputDivPackage {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 1.1rem;
}

.InputDivPackage p {
  color: #104172;
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 5px;
}

.InputDivPackage input {
  width: 100%;
  border: 1px solid #d9d9d9;
  padding: 0.8rem 1rem;
  border-radius: 8px;
}

.divCheckPay {
  margin-top: 1rem;
  width: 100%;
}

.divCheckPay button {
  background-color: #104172;
  color: white;
  width: 100%;
  font-weight: 500;
  font-size: 18px;
  padding: 0.8rem;
  border-radius: 8px;
  cursor: pointer;
  gap: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}



.detailPayFlight h3 {
  color: var(--bluePrimary);
  font-size: 18px;
}

.methodPayContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 95%;
  max-width: 1280px;
  margin: 0 auto;
  margin-top: 20px;
  padding-bottom: 40px;
}

.methodPayContainer p {
  color: var(--bluePrimary);
}

.methodPayHeader {
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding-bottom: 10px;
  border-bottom: 2px solid var(--bluePrimary);
}

.methodPayHeader h3 {
  font-size: 24px;
  font-weight: 600;
  color: var(--bluePrimary);
}

.methodPayHeader p {
  font-size: 14px;
  font-weight: 400;
  color: var(--bluePrimary);
}

.methodPayContainInfo {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.methodPayImageContain {
  position: sticky;
  top: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.methodPayContainInfoContain {
  border: 1px solid var(--blueWhiteTableThead);
  width: 100%;
  border-radius: 8px;
  color: var(--bluePrimary);
}

.contentStatePay {
  display: flex;
  gap: 20px;
  padding: 1rem;
  align-items: center;
  background-color: var(--greySecundary);
  border-radius: 8px 8px 0px 0px;
  color: var(--white);
}

.contentStatePay h3 {
  font-size: 18px;
  display: flex;
  align-items: center;
}

.contentStatePay p {
  display: flex;
  gap: 6px;
  color: var(--white);
}

.contentStatePay p div {
  height: 20px;
  width: 20px;
  background-color: var(--greenPrimary);
  border-radius: 50%;
  color: var(--white);
}

.contentStatePayMessage {
  color: var(--white);
  display: flex;

  gap: 20px;
}

.contentStatePayMessage p {
  color: var(--white);
  padding-top: 3px;
}

.methodPayContainInfoContainPadding {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  color: var(--bluePrimary);
  padding: 20px;
}

.detailPayFlightHeader {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 30px;
}

.detailPayFlightHeaderImage {
  width: 100px;
  height: 100px;
  padding: 3px;
  border-radius: 8px;
  box-shadow: var(--shawdowPrimary);
}

.detailPayFlightHeaderImage img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.methodPayContainInfoContainPadding h3 {
  font-weight: 600;
  font-size: 20px;
  padding-bottom: 8px;
}

.methodValuesContaint {
  display: flex;
  flex-direction: column;
  gap: 20px;
  border-top: 1px solid var(--blueWhiteTableThead);
  padding-top: 20px;
}

.methodValuesItem {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  align-items: center;
}

.methodValuesItem span {
  font-weight: 600;
}

.methodValuesItemTotal {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  align-items: center;
  border-top: 1px solid var(--blueWhiteTableThead);
  padding-top: 20px;
}

.methodValuesItemTotal span {
  font-weight: 600;
}

.securityPay {
  border: 1px solid var(--blueWhiteTableThead);
  padding: 20px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 20px;
  color: var(--bluePrimary);
}

.APPROVED {
  background-color: var(--greenOffert);
}

.PENDING {
  background-color: var(--orangePrimary);
}

.REJECTED {
  background-color: var(--redSecondary);
}

.methodPayContain {
  display: flex;
  justify-content: space-between;
  gap: 50px;
  margin-bottom: 30px;
}

@media (max-width: 700px) {
  .methodPayContain {
      flex-direction: column;
      justify-content: flex-start; /* Remove justify-content: space-between */
      gap: 0; /* Remove gap */
  }
}

.itemDetailsFlights {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
  border-top: 1px solid var(--blueWhiteTableThead);
  padding-top: 20px;
  margin-top: 20px;
}

.itemDetailsFlights1 {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: 20px;
  height: 100%;
  border-right: 2px solid var(--bluePrimary);
  padding-right: 30px;
}

.methodPayContainQuestions {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.detailPayFlightInfo {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.detailPayFlightInfo h2 {
  font-size: 24px;
  color: var(--bluePrimary);
}

.detailPayFlightName {
  display: flex;
  gap: 5px;
  align-items: center;
  color: var(--bluePrimary);
}

.detailPayFlightName p {
  font-size: 18px;
}

.itemDetailsFlights2 {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: 20px;
  height: 100%;
  padding-left: 30px;
}

.itemDetailsFlightsColumn1 {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  gap: 10px;
  width: 100%;
}

.typeFlight {
  display: flex;
  flex-direction: column;
  color: var(--bluePrimary);
  font-weight: 700;
}

.typeFlight strong {
  font-weight: 500;
}

@media screen and (max-width: 550px) {
  .itemDetailsFlights {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 10px;
    gap: 20px;
  }

  .itemDetailsFlights2 {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    gap: 20px;
    height: 100%;
    padding-left: 0px;
  }

  .itemDetailsFlights1 {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    gap: 20px;
    height: 100%;
    border: none;
    padding-right: 0px;
  }
}

.itemDetailsFlightsColumn1 {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  gap: 10px;
  width: 100%;
}

.typeFlight {
  display: flex;
  flex-direction: column;
  color: var(--bluePrimary);
  font-weight: 700;
}

.typeFlight strong {
  font-weight: 500;
}

.btn-reserve-hotel-modal-prereserve {
  width: fit-content;
  min-width: fit-content;
  cursor: pointer;
  background-color: var(--bluePrimary);
  font-family: inherit;
  display: flex;
  align-items: center;
  padding: 0px 15px;
  line-height: 2.5em;
  position: relative;
  overflow: hidden;
  border: 2px solid var(--bluePrimary);
  transition: color 0.5s;
  z-index: 1;
  font-size: var(--sizeTextPrimary);
  border-radius: var(--roundedPrimary);
  font-weight: 600;
  color: var(--white);
}

.btn-reserve-hotel-modal-prereserve:before {
  content: "";
  position: absolute;
  z-index: -1;
  background: var(--white);
  color: var(--bluePrimary);
  height: 550px;
  width: 1500px;
  border-radius: 50%;
}

.btn-reserve-hotel-modal-prereserve:hover {
  color: var(--bluePrimary);
}

.btn-reserve-hotel-modal-prereserve:before {
  top: 100%;
  left: 100%;
  transition: all 0.7s;
}

.btn-reserve-hotel-modal-prereserve:hover:before {
  top: -100px;
  left: -200px;
}

.btn-reserve-hotel-modal-prereserve:active:before {
  background: white;
  transition: background 0s;
}

.form-package-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 2;
}

.modal-header-package {
  background-color: #004274;
  color: white;
  width: 100%;
  max-width: 600px;
  height: 62px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 25px 25px 0px 0px;
}

.modal-header-package p {
  font-size: 16px;
  font-weight: 900;
  font-family: var(--font-family-form-plan) !important;
}

.modal-form-package {
  background-color: white;
  border-radius: 0px 0px 25px 25px;
  width: 100%;
  max-width: 600px;
  padding-inline: 50px;
  padding-block: 30px;
  box-shadow: 0px 4px 4px 0px #00000040;
}

.container-form-flex-package {
  display: flex;
  padding-top: 10px;
  padding-bottom: 5px;
  padding-left: 5px;
  justify-content: space-between;
  align-items: center;
  align-items: start;
}

.container-form-flex-package>div {
  width: 48%;
}

.container-form-flex-package>div>p {
  font-size: 12px;
  font-weight: 400;
  font-family: var(--font-family-form-plan) !important;
}

.container-form-flex-package>div>input {
  width: 100%;
  height: 32px;
  border: 1px solid #D9D9D9;
  border-radius: 8px;
  padding-inline: 10px;
  font-family: var(--font-family-form-plan) !important;
}

.button-container-package {
  display: flex;
  justify-content: center;
}

.button-container-package button {
  background-color: #004274;
  color: white;
  font-family: "Montserrat" !important;
  font-size: 12px;
  font-weight: 700;
  height: 30px;
  border-radius: 8px;
  width: 30%;
  cursor: pointer;
}

.validate-package {
  border: 1px solid red !important;
}

.span-error-package {
  color: red;
  font-size: 10px;
  font-weight: 400;
  font-family: var(--font-family-form-plan) !important;
  margin-top: 5px;
}

.input-see-data-passenger {
  display: flex;
}

.input-see-data-passenger label {
  width: 20px;
  height: 20px;
  border-radius: 3px;
  border: 1px solid #D9D9D9;
  background-color: white;
  transition: 0.5s;
  margin-right: 10px;
}

.input-see-data-passenger input[type="checkbox"] {
  display: none;
  /* Oculta el checkbox predeterminado */
}

.input-see-data-passenger input[type="checkbox"]+label {
  width: 20px;
  height: 20px;
  border: 1px solid #D9D9D9;
  /* Agrega más estilos para el checkbox no seleccionado aquí */
}

.input-see-data-passenger input[type="checkbox"]:checked+label {
  border: 1px solid #D9D9D9;
  background-color: #004274;
  transition: 0.5s;
}

.term-conditions {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 1150px) {
  .container-form-flex-package>div {
    width: 100%;
    margin-top: 15px;
  }

  .container-form-flex-package {
    display: block;
    margin-bottom: 0px;
  }

  .input-see-data-passenger {
    margin-top: 15px;
  }
}



.formTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 2px;
  border-bottom: 2px solid var(--bluePrimary);
  padding-bottom: 20px;
}

.formTitle p {
  font-size: 16px;
  font-weight: 400;
  color: var(--bluePrimary);
}

.formTitle legend {
  font-size: 24px;
  font-weight: 700;
  color: var(--bluePrimary);
}

.formTitle svg {
  cursor: pointer;
  font-size: 24px;
  color: var(--bluePrimary);
}

.formSeparationInfo {
  display: flex;
  justify-content: space-between;
  gap: 4rem;
  width: 100%;
  margin-top: 2rem;
}

@media screen and (max-width: 1024px) {
  .formSeparationInfo {
    gap: 2rem;
  }
}

@media screen and (max-width: 768px) {
  .formSeparationInfo {
    flex-direction: column;
    gap: 10px;
  }

  .contContinue {
    width: 100%;
  }
}

.formSeparationItem {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.formTitleItem h4 {
  font-size: 18px;
  font-weight: 600;
  color: var(--bluePrimary);
}

.formTitleItem p {
  font-size: 15px;
  font-weight: 400;
  color: var(--bluePrimary);
}

.formItemsData {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.camp-two-check-column {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
}

.camp-two-check-column p span {
  font-weight: 600;
  font-size: 15px;
}

.camp-two-check-column p {
  font-weight: 400;
  font-size: 15px;
  color: #004274;
}

.formTitleItemAnyDescription {
  padding-top: 30px;
  font-size: 15px;
  font-weight: 400;
  color: var(--bluePrimary);
}

.camp-one {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
}

.camp-three .right {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.camp-two .right {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  position: relative;
}

.camp-three {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  width: 100%;
}

.camp-three .left {
  width: 60%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  position: relative;
}

.camp-three .center {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.camp-three .right {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.camp-three label {
  font-size: 16px;
}

.camp-three input {
  appearance: none;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  width: 100%;
  padding: 0.4rem;
}

.formItemsCheckboxes {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.divOptions {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 1.2rem;
}

.divOptions span {
  color: var(--bluePrimary);
}

.checkboxCont {
  display: flex;
  align-items: center;
  gap: .7rem;
}

.checkboxContColumn {
  display: flex !important;
  flex-direction: column !important;
  gap: 0.8rem !important;
  align-items: flex-start !important;
  margin-top: 1.2rem !important;
}

.checkboxContColumn label {
  display: flex;
  gap: .8rem;
}

.divcheck input {
  appearance: none;
  border: 1px solid #d9d9d9;
  border-radius: 50% !important;
  width: 100%;
  padding: 0 !important;
}

.camp-two label {
  font-size: 16px;
}

.camp-two-check-middle label {
  font-weight: 400;
  color: var(--bluePrimary);
}

.checkboxCont label {
  font-weight: 500;
  font-size: 16px;
  color: var(--bluePrimary);
}

.camp-middle {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 48%;
}

.camp-middle input {
  appearance: none;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  width: 100%;
  padding: 0.4rem;
}

.obligatory {
  border-color: var(--redPrimary) !important;
}

.previewDate {
  width: 100%;
  border-top: 2px solid var(--bluePrimary);
  padding-top: 20px;
  margin-top: 20px;
}

.contPrev {
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 2rem;

  h2 {
    color: var(--bluePrimary);
    font-size: 12px;
    font-weight: 700;
  }
}

.contPrev .description {
  font-size: 12px;
}

.contPrev .listData {
  display: flex;
  align-items: center;
  justify-content: space-between;

  article {
    width: 32%;
    display: flex;
    flex-direction: column;

    span {
      font-size: 12px;
    }

    p {
      font-size: 14px;
      font-weight: 700;
      color: var(--bluePrimary);
    }
  }
}

@media screen and (max-width: 500px) {
  .contPrev .listData {
    article {
      span {
        font-size: 12px;
      }

      p {
        font-size: 10px;
      }
    }
  }
}

.topButtonOpenCollaps {
  display: flex;
  gap: 10px;
  align-items: center;
  cursor: pointer;
  color: var(--bluePrimary);
  margin-bottom: 15px;
}

.topButtonOpenCollaps h3 {
  font-size: 18px;
  font-weight: 600;
  color: var(--bluePrimary);
}

.arrowDown {
  transform: rotate(90deg);
  transition: transform 0.3s;
}

.formItemText {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-top: 10px;
}

.formItemText label {
  font-weight: 500;
  font-size: 16px !important;
  color: var(--bluePrimary);
}

.formItemText input {
  appearance: none;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  width: 100%;
  padding: 0.4rem;
}

.checkboxContNew {
  display: flex;
  align-items: center;
  gap: .7rem;
}

.checkboxContNew,
.labelYesOrNoForm,
.formItemSelect h4 {
  font-weight: 500;
  font-size: 16px !important;
  color: var(--bluePrimary);
}

.checkboxContNew label p {
  font-size: 16px !important;
  font-weight: 400;
  margin-left: 5px;
  color: var(--bluePrimary);
}

.checkboxContNew input {
  appearance: none;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  width: 100%;
  cursor: pointer;
  padding: 0.4rem;
}

.checkboxContNew input[type="checkbox"] {
  width: 20px;
  height: 20px;
  border-radius: 2px;
}

.checkboxContNew input:checked {
  background-image: url("https://www.shutterstock.com/image-vector/check-mark-icon-color-editable-600nw-2474013721.jpg");
  background-position: center;
  background-size: contain;
}

.checksDivForm {
  display: flex;
  flex-direction: column;
  gap: 0.7rem;
}

.labelYesOrNoForm {
  display: flex;
  gap: 1rem;
  align-items: center;
  margin-top: 12px;
}

.labelYesOrNoForm label {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.labelYesOrNoForm p {
  font-size: 14px;
  font-weight: 400;
  color: var(--bluePrimary);
}

.formItemSelect {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.formItemSelect select {
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  width: 100%;
  padding: 0.4rem;
  appearance: none;
  background-image: url("https://static.thenounproject.com/png/1590826-200.png");
  background-size: contain;
  background-position: right;
  background-clip: content-box;
  background-repeat: no-repeat;
}
.formItemSelect h4 span{
  color: red;
  margin-left: 3px;
}

.fotosFormBottom{
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
}
.fotosFormBottom h3{
  font-size: 18px;
  font-weight: 600;
  color: var(--bluePrimary);
}
.fotosFormBottom p{
  font-size: 15px;
  font-weight: 400;
  color: var(--bluePrimary);
  margin-bottom: 14px;
}
.fotosFormBottom button{
  background-color: var(--bluePrimary);
  border-radius: 8px;
  color: var(--white);
  width: fit-content;
  padding: 10px 25px;
  font-size: 15px;
  font-weight: 500;
  transition: all 0.3s ease;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
}
.fotosFormBottom button:hover{
  background-color: var(--blueTertiary);
}

.bottomFormNote{
  background-color: var(--blueWhiteTable);
  padding: 1rem;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-top: 1rem;
  width: 85%;
}
.bottomFormNote h4{
  font-size: 16px;
  font-weight: 700;
  color: var(--bluePrimary);
  margin-bottom: 0.5rem;
}
.bottomFormNote p{
  font-size: 14px;
  color: black;
}
.checkTerms {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-top: 1.2rem;

  input {
    appearance: none;
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    width: 100%;
    padding: 0.4rem;
  }

  input[type="checkbox"] {
    width: 20px;
    height: 20px;
    border-radius: 2px;
  }

  input:checked {
    background-image: url("https://www.shutterstock.com/image-vector/check-mark-icon-color-editable-600nw-2474013721.jpg");
    background-position: center;
    background-size: contain;
  }

  label {
    font-size: 12px;
    color: var(--bluePrimary);

    span {
      color: var(--blueLight);
      cursor: pointer;
      text-decoration: underline;
    }
  } 
}
.buttonCont {
  width: 100%;
  background-color: var(--bluePrimary);
  border-radius: 8px;
  color: var(--white);
  padding: 10px 25px;
  font-size: 16px;
  font-weight: 500;
  transition: all 0.3s ease;
  cursor: pointer;
  gap: 10px;
  margin-top: 1rem;
  text-align: center;
  width: 85%;
}

.buttonCont:hover {
  background-color: var(--blueTertiary);
}


.container_form_photos {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
}

@media screen and (max-width: 640px) {
  .openModalButton {
      width: 100%;
      align-items: center;
      justify-content: center;
  }

  .grid_container {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      gap: 16px;
  }

  .container_form_photos {
      overflow-y: scroll;
  }
}

.container_photos {

  border: 1px solid var(--grayLight);
  border-radius: 8px;
  background-color: var(--white);
}

.container_photos h3 {
  font-size: 20px;
  font-weight: 600;
  color: var(--bluePrimary); 
}

.divTopModal{
  width: 100%;
  display: flex; 
  align-items: flex-start;
  justify-content: space-between;
}
.divTopModal svg{
  color: #004274;
  font-size: 30px;
  cursor: pointer;
}
.contRecomendationsModal{
  display: flex;
  flex-direction: column;
}
.contRecomendationsModal h5{
  font-size: 18px;
  font-weight: 600;
  color: var(--bluePrimary); 
  margin-left: -10px;
  margin-bottom: -5px;
}
.contRecomendationsModal p{
  color: var(--bluePrimary); 
}

.grid_container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
  margin-top: 2rem;
}

.grid_container div {
  position: relative;
}

.grid_container img {
  width: 100%;
  height: auto;
  border-radius: 8px;
  object-fit: cover;
}

.itemFileImage {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 5px;

}

.itemFileImageContent {
  height: 100%;
  overflow: hidden;
  border-radius: 8px;
  border: 1px solid var(--greyPrimary);
}

.itemFileImageContent img {
  height: 100%;
  min-height: 400px;
  max-height: 400px; 
}

.custumFileUploadDialog input {
  display: none;
}

.custumFileUploadDialog.uploaded {
  border: 2px dashed var(--greenTransparent);
}

.uploadedText.uploaded {
  color: var(--greenSecundary);
}

.icon.uploaded {
  color: var(--greenSecundary);
}
.custumFileUploadDialog {
  display: flex; 
  gap: 10px;
  cursor: pointer; 
  justify-content: center;
  border: 2px dashed var(--greyPrimary);
  background-color: var(--blueWhiteTable);
  padding: 1rem;
  border-radius: 10px;
  box-shadow: 0px 48px 35px -48px #e8e8e8;
  color: var(--greySecundary);
  font-size: 16px !important;
} 

.openModalButton {
  background-color: var(--bluePrimary);
  border-radius: 8px;
  color: var(--white);
  width: fit-content;
  padding: 10px 25px;
  font-size: 15px;
  font-weight: 500;
  transition: all 0.3s ease;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
}

.openModalButton:hover {
  background-color: var(--blueTertiary);
}
.bottomTextModal p{
  color: var(--bluePrimary); 
} 
.positionButton {
  display: flex;
  justify-content: flex-end;
  margin-top: 2rem;
}

.inputSonsForm input{
  appearance: none;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  width: 100%;
  padding: 0.4rem;
}

.titleQuantityChild{
  font-weight: 400;
  color: var(--bluePrimary);
  font-size: 16px !important;
}