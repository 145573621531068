:root {
    --azul: #3b82f6;
    --blanco: #ffffff;
    --gris-claro: #f5f5f5;
    --gris: #94a3b8;
    --gris-oscuro: #64748b;
    --negro: #000;
}

.doctorDetail {
    display: flex;
    margin-top: 40px;
    width: 90%;
    justify-content: center;
    font-family: 'Inter';
}
.doctorDetailLeft {
    width: 43%;
    margin: 0 auto;
}
.doctorDetailLeft p {
    font-size: 14px;
    font-weight: 300;
}
.doctorDetailLeft_img {
    width: 100%;
    height: 700px;
    
}
.doctorDetailLeft_img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 6px;
}
.doctorDetailLeft_text_results {
    margin: 20px 0;
}
.doctorDetailLeft_text_arrows {
    position: absolute;
    right: 0;
    bottom: 0;
    display: flex;
}
.doctorDetailLeft_text_arrow {
    background-color: #00AFE8;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    margin: 0 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25)
}
.doctorDetailLeft-results_imgs {
    display: flex;
    width: 100%;
}
.doctorDetailLeft-results_imgs_ {
    position: relative;
    display: flex;
    align-items: center;
    margin: 0 10px;
}
.doctorDetailLeft-results_imgs_ h3 {
    font-family: 'Oswald';
    font-size: 20px;
    color: white;
    position: absolute;
    top: 10px;
    left: 20px;
}
.doctorDetailLeft-results_img {
    width: 175px;
    height: 190px;
}
.doctorDetailLeft-results_img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    pointer-events: none;
}
.doctorDetailLeft-results-img_arrows {
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.doctorDetailLeft-results_slider {
    display: flex;
    width: 100%;
}
.middleLine {
    width: 3px;
    height: 1000px;
    background-color: rgba(0, 0, 0, .1);
    margin: 0 auto
}
.doctorDetailRight {
    width: 43%;
    margin: 80px auto;
}
.doctorDetailRight_title {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.doctorDetailRight_text {
    min-height: 300px;
}
.doctorDetailRight h1 {
    font-size: 40px;
    font-weight: 800;
}
.doctorDetailRight_heart {
    margin-right: 20px !important;
    padding: 5px !important;
}
.doctorDetailRight p {
    font-size: 16px;
    font-weight: 300;
}
.doctorDetailRight h3 {
    font-size: 20px;
    font-weight: 700;
}
.doctorDetailRight_description {
    margin: 40px 0;
}
.doctorDetailRight_experience {
    margin-bottom: 20px;
}
.doctorDetailRight_exclusiveTechniques {
    margin-bottom: 20px;
}
.doctorDetailRight_exclusiveTechniques_ {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}
.doctorDetailRight_exclusiveTechniques_img {
    width: 80px;
    height: 80px;
}
.doctorDetailRight_exclusiveTechniques_img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.css-jue3ft-MuiRating-root {
    font-size: 40px !important;
}
.doctorDetailRight_assessment {
    display: flex;
    align-items: center;
    margin-bottom: 40px;
    text-decoration: underline;
}
.doctorDetailRight_assessment p {
    margin-left: 50px;
}
.doctorDetailRight_button {
    text-align: center;
    margin-bottom: 40px;
}
.doctorDetailRight_button button {
    font-family: 'Oswald';
    color: white;
    text-transform: uppercase;
    font-size: 20px;
    border: none;
    background-color: #00AFE8;
    padding: 20px 30px;
    border-radius: 20px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25)
}
.doctorDetailRight_comments {
    margin-top: 70px;
}
.doctorDetailRight_comments h3 {
    margin-bottom: 15px;
}
.doctorDetailRight_comments h4 {
    font-weight: 500;
}
.doctorDetailRight_comments p {
    font-size: 14px;
    font-weight: 200;
}
.doctorDetailRight_comments_ {
    border-bottom: 3px solid rgba(0, 0, 0, .1);
    margin-bottom: 15px;
}
.doctorDetailRight_comments_one {
    position: relative;
    margin-bottom: 20px;
}
.doctorDetailRight_comments_arrow {
    background-color: #00AFE8;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 0;
    right: 0;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25)
}
@media screen and (max-width: 800px) {
    .doctorDetail {
        width: 100%;
    }
    .doctorDetailLeft_img {
        height: 500px;
    }
    .doctorDetailRight h1 {
        font-size: 30px;
    }
    .doctorDetailRight_exclusiveTechniques_img {
        width: 50px;
        height: 50px;
    }
}
@media screen and (max-width: 700px) {
    .doctorDetailRight {
        width: 46%;
    }
    .doctorDetailLeft {
        width: 46%;
    }
}
@media screen and (max-width: 700px) {
    .doctorDetail {
        flex-direction: column;
        margin-top: 0;
    }
    .doctorDetailLeft {
        width: 90%;
    }
    .doctorDetailLeft_img {
        height: 300px;
    }
    .doctorDetailLeft_img img {
        object-fit: contain;
    }
    .doctorDetailLeft-results_img {
        width: 140px;
        height: 170px;
    }
    .middleLine {
        display: none;
    }
    .doctorDetailRight {
        width: 85%;
        margin: 10px auto;
    }
    .doctorDetailRight_description {
        margin: 10px 0;
    }
    .doctorDetailRight_assessment {
        margin-bottom: 20px;
    }
    .doctorDetailRight_button {
        margin-bottom: 20px;
    }
}
.fijar {
    overflow: hidden;
    min-height: 100vh;
}
.sombra {
    box-shadow: 0px 9px 10px -10px rgba(0, 0, 0, 0.51);
    -webkit-box-shadow: 0px 9px 10px -10px rgba(0, 0, 0, 0.51);
    -moz-box-shadow: 0px 9px 10px -10px rgba(0, 0, 0, 0.51);
    background-color: var(--blanco);
    padding: 4rem;
    border-radius: 1.2rem;
}


.formulario {
    width: 90%;
    margin: 0 auto;
    padding: 10rem 0;
}
@media (min-width: 768px) {
    .formulario {
        padding: 5rem 0;
        width: 60rem;
    }
}
.formulario legend {
    font-size: 2.6rem;
    text-align: center;
    display: block;
    text-transform: uppercase;
    color: var(--blanco);
    margin-bottom: 2rem;
    padding-bottom: 1rem;
    border-bottom: 2px solid var(--azul);
}
.formulario .campo {
    display: grid;
    margin-bottom: 2rem;
}

.formulario label {
    color: var(--azul);
    font-size: 1.8rem;
    text-align: center;
    margin-bottom: 2rem;
}



.formulario input[type="text"],
.formulario input[type="number"] {
    background-color: var(--gris-claro);
    border-radius: 1rem;
    padding: 1rem;
    border: none;
    flex: 1;
    font-size: 1.6rem;
}

.formulario input[type="submit"] {
    background-color: #1048a4;
    border: none;
    padding: 1rem;
    text-align: center;
    margin-top: 2rem;
    color: var(--blanco);
    font-weight: 900;
    text-transform: uppercase;
    font-size: 1.9rem;
    width: 100%;
    transition: background-color 300ms ease;
}
@media (min-width: 768px) {
    .formulario input[type="submit"] {
        display: block;
        padding: 1rem 5rem;
    }
}
.formulario input[type="submit"]:hover {
    background-color: #042a67;
    cursor: pointer;
}

.formulario select {
    flex: 1;
    padding: 1rem;
    border: none;
    border-radius: 1rem;
    text-align: center;
    background-color: var(--gris-claro);
}

.alerta {
    padding: 2rem 5rem;
    max-width: 60rem;
    margin: 2rem auto 2rem auto;
    font-weight: 900;
    text-transform: uppercase;
    font-size: 1.8rem;
    text-align: center;
}
.alerta.error {
    background-color: var(--blanco);
    border-left: 5px solid #b91c1c;
    color: #b91c1c;
}


.formulario input[type="text"].nuevo-presupuesto, 
.formulario input[type="number"].nuevo-presupuesto {
    font-size: 2.8rem;
    text-align: center;
}

.modal-new .cerrar-modal img {
    width: 100%;
    cursor: pointer;
}
@media  (max-width: 500px) {
    .formulario input[type="text"].nuevo-presupuesto,
    .formulario input[type="number"].nuevo-presupuesto {
        font-size: 2rem;
        
    }
    .formulario input[type="submit"] {
        background-color: #1048a4;
        border: none;
        padding: 1rem;
        text-align: center;
        margin-top: 2rem;
        color: var(--blanco);
        font-weight: 900;
        text-transform: uppercase;
        font-size: 1rem;
        width: 100%;
        transition: background-color 300ms ease;
    }
    .formulario input[type="text"],
.formulario input[type="number"] {
    background-color: var(--gris-claro);
    border-radius: 1rem;
    padding: 1rem;
    border: none;
    flex: 1;
    font-size: 1rem;
}
.modal-new .cerrar-modal img {
    width: 80%;
    cursor: pointer;
}
}




.modal-new {
    position: fixed;
    background-color: rgb(0 0 0 / 0.92);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999;
    padding-top:3rem ;
}

.modal-new .cerrar-modal {
    position: absolute;
    right: 3rem;
    top: 7rem;
    width: 3rem;
    height: 3rem;
    z-index: 999;
}


.modal-new .formulario {
    max-width: 80rem;

    width: 90vw;
    transition-property: all;
    transition-duration: 300ms;
    transition-timing-function: ease-in;
    opacity: 0;
    height: 70vh;
    overflow: scroll;
}
.modal-new label {
    color: var(--blanco);
}
body .modal-new {
    overflow: hidden;
}

.modal-new .formulario.animar {
    position: relative;
    opacity: 1;
    width: 90%;
}

.modal-new .formulario.cerrar {
    opacity: 0;
}

.modal-new label {
    text-align: left;
}



@media(min-width:500px){

   
    .modal-new .formulario {
        max-width: 50rem;
        width: 90vw;
        transition-property: all;
        transition-duration: 300ms;
        transition-timing-function: ease-in;
        opacity: 0;
    }
}