.containerFlightFilters {
    width: 200px;
    min-width: 160px;
    max-width: 200px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.containerFlightFiltersModal {
    position: fixed;
    background-color: #021624b0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 1;
    transition: all 0.3s ease;
    padding: 30px;
}

.containerFlightFiltersModal.close {
    position: fixed;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    pointer-events: none;
}

.containerFlightFiltersContentModal {
    display: flex;
    flex-direction: column;
    gap: 15px;
    background-color: white;
    width: 100%;
    max-width: 680px;
    max-height: 600px;
    overflow-y: scroll;
    border-radius: 12px;
    padding: 20px;
    padding-bottom: 0;
    position: relative;
    opacity: 1;
    transition: all 0.3s ease;
    transform: translateY(0px);
    z-index: 99999;
}

.containerFlightFiltersContentModal.close {
    background-color: white;
    width: 100%;
    width: 100%;
    max-width: 680px;
    max-height: 600px;
    overflow-y: scroll;
    border-radius: 10px;
    padding: 20px;
    padding-bottom: 0;
    position: relative;
    opacity: 0;
    transition: all 0.3s ease;
    transform: translateY(-100px);
}

.containerFlightFiltersContent {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.containerFlightFiltersCloseModal {
    position: absolute;
    top: 12px;
    right: 13px;
    cursor: pointer;
}


.containerFlightFilters h3 {
    font-size: 18px;
    font-weight: 700;
    color: #104172;
}

.containerFlightFiltersOptions {
    display: flex;
    flex-direction: column;
    gap: 10px;
    font-size: 13px;
}

.containerFlightFiltersSelectMonth {
    border: 1px solid #dedede;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
    padding: 8px;
    border-radius: 8px;
    cursor: pointer;
}

.containerFlightFiltersSelectContent {
    color: #104172;
    font-weight: 600;
}

.containerFlightFiltersSelectContent p {
    color: #333;
    font-weight: 400;
}

.containerFlightFiltersOptionFlight {
    padding: 20px 0px;
    border-top: 1px solid #dedede;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.containerFlightFiltersOptionFlight h3 {
    font-size: 18px;
    font-weight: 700;
    color: #104172;
}

.radio-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.radio-container label {
    font-size: 10px;
}

.radio-container span {
    font-size: 12px;
}

.radio-container label p {
    cursor: pointer;
}

.radio-container label p:hover {
    text-decoration: underline;
}

.optionRadioButton {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    gap: 5px;
}

.radio {
    position: absolute;
    left: 2px;
    top: 2px;
    border-radius: 50%;
    width: 16px;
    height: 16px;
}

.optionRadioButton .hidden:checked~.radio {
    background-color: #104172;
    border: 2px solid #fff;
}

.inputNumber {
    text-align: center;
    width: 30px;
}

.containerFlightFiltersOptionFlightTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
}



.containerFlightFiltersOptionFlightRadioButtons {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.containerFlightFiltersOptionFlightRadioButtonsMoney {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 10px;
}


.modalButtonFilter {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    background-color: var(--bluePrimary);
    color: var(--white);
    padding: 13px 20px;
    border-radius: 8px;
    cursor: pointer;
    text-align: center;
    font-weight: 600;
    font-size: 16px;
    transition: all 0.3s ease;
}

.modalButtonFilter:hover {
    background-color: #185897;
}

.rangeRadioFilter {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.buttonCleanFilters {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    background-color: #104172;
    color: white;
    padding: 13px 20px;
    border-radius: 8px;
    cursor: pointer;
    text-align: center;
    font-weight: 600;
    font-size: 16px;
    transition: all 0.3s ease;
}

.rangeRadioFilter .css-1aznpnh-MuiSlider-root {
    width: 90% !important;
    margin: 0 auto 1rem auto !important;
}

/* .rangeRadioFilter .css-1eoe787-MuiSlider-markLabel,
.rangeRadioFilter .css-yafthl-MuiSlider-markLabel {
  font-size: 10px !important;
  font-weight: 600;
  color: var(--bluePrimary) !important;
}

.rangeRadioFilter .css-eg0mwd-MuiSlider-thumb {
  background-color: var(--bluePrimary) !important;
  border-color: var(--bluePrimary) !important;
  width: 19px !important;
  height: 19px !important;
}

.rangeRadioFilter .css-1gv0vcd-MuiSlider-track {
  background-color: var(--bluePrimary) !important;
  border-color: transparent !important;
} */


/* .rangeRadioFilter .css-1eoe787-MuiSlider-markLabel[data-index="0"] {
  left: 15% !important;
}

.rangeRadioFilter .css-1eoe787-MuiSlider-markLabel[data-index="1"] {
  left: 85% !important;
}

.rangeRadioFilter .css-yafthl-MuiSlider-markLabel[data-index="0"] {
  left: 25% !important;
}

.rangeRadioFilter .css-yafthl-MuiSlider-markLabel[data-index="1"] {
  left: 85% !important;
} */


@media screen and (max-width:770px) {
    /* .rangeRadioFilter .css-1eoe787-MuiSlider-markLabel[data-index="0"] {
    left: 4% !important;
  }

  .rangeRadioFilter .css-1eoe787-MuiSlider-markLabel[data-index="1"] {
    left: 96% !important;
  }

  .rangeRadioFilter .css-yafthl-MuiSlider-markLabel[data-index="0"] {
    left: 4% !important;
  }

  .rangeRadioFilter .css-yafthl-MuiSlider-markLabel[data-index="1"] {
    left: 96% !important;
  } */

    .rangeRadioFilter .css-1aznpnh-MuiSlider-root {
        width: 98% !important;
    }
}


@media screen and (max-width:500px) {
    /* .rangeRadioFilter .css-1eoe787-MuiSlider-markLabel[data-index="0"] {
    left: 10% !important;
  }

  .rangeRadioFilter .css-1eoe787-MuiSlider-markLabel[data-index="1"] {
    left: 90% !important;
  }

  .rangeRadioFilter .css-yafthl-MuiSlider-markLabel[data-index="0"] {
    left: 10% !important;
  }

  .rangeRadioFilter .css-yafthl-MuiSlider-markLabel[data-index="1"] {
    left: 90% !important;
  } */

}