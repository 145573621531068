.content-secures{
    width: 50%;
    display: flex;
    gap: 1%;
    height: fit-content;
    flex-wrap: wrap;
}

.sura-content{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    border-radius: 20px;
    padding: 1rem;
    box-shadow: 0px 4px 4px 0px #00000040;
    width: 32%;
    min-height: 160px;
    cursor: pointer;
}

.sura-secure{
    width: 60%;
}

.content-secure2{
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 4px 4px 0px #00000040;
    border-radius: 20px;
    min-height: 160px;
    cursor: pointer;
}

.content-secure2 Link{
    width: 100%;
    height: 100%;
}

.content-secure2 img{
    width: 60%;
    
}

.content-mok{
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    box-shadow: 0px 3px 10px -1px rgba(0,0,0,0.1);
    width: 32%;
    min-height: 160px;
}

.content-mok img{
    width: 40%;
}



@media screen and (max-width:500px) {
    .content-secures{
        width: 100%;
        flex-direction: column;
        gap: 1rem;
    }
    .content-mok, .content-secure2, .sura-content{
        width: 100%;
    }
}

.hover-card{
    transition: 0.5s;
}

.hover-card:hover{
    background-color: rgb(249, 247, 247);
    transition: 0.5s;
    box-shadow: 0px 3px 10px -1px rgba(0,0,0,0.1);
}

.hover-card:active{
    box-shadow: none;
    transition: 0.3s;
}