.procedureCategoryC_header {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}
.procedureCategoryC-header_img {
    width: 100%;
    height: 700px;
}
.backGroudCategoryImg {
    width: 100%;
    height: 700px;
    background-color: rgba(0, 0, 0, .3);
    position: absolute;
    top: 0;
}
.procedureCategoryC-header_img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.procedureCategoryC-header_text {
    position: absolute;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.procedureCategoryC-header_text h1 {
    font-size: 100px;
    font-family: 'Inter';
    color: white;
    font-weight: 500;
    text-transform: capitalize;
}
.procedureCategoryC-header_text p {
    width: 70%;
    font-size: 25px;
    font-family: 'Inter';
    color: white;
    font-weight: 200;
    margin: 40px 0;
}
.procedureCategoryC-header_button a {
    width: 180px;
    height: 70px;
    background-color: white;
    color: #004274;
    border-radius: 20px;
    border: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: 'Inter';
    font-size: 20px;
    font-weight: 500;
    text-transform: uppercase;
    cursor: pointer;
}
.procedureCategoryC-header_button a span {
    margin: -8px 0 -10px 0;
}

/*Main*/
.procedureCategoryC_main {
    margin-top: 40px;
    width: 100%;
    display: flex;
    justify-content: center;
}
.procedureCategoryC_main_ {
    width: 70%;
}
.procedureCategoryC_main_ p {
    font-size: 25px;
    font-family: 'Inter';
    font-weight: 200;
    text-align: center;
}

.procedureCategoryC-main_elements {
    margin-top: 30px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.category_img {
    width: 300px;
    height: 400px;
    margin: 5px;
    position: relative;
    display: flex;
    justify-content: center;
    border-radius: 20px;
}
.backgroundImgCategory {
    width: 100%;
    height: 400px;
    background-color: rgba(0, 0, 0, .2);
    position: absolute;
    top: 0;
    border-radius: 20px;
}
.category_img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 20px;
}
.category-img_text {
    width: 100%;
    position: absolute;
    bottom: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.category-img-text_button {
    width: 60%;
    height: 50px;
    background-color: white;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.category-img_text h3 {
    font-family: 'Oswald';
    font-size: 25px;
    font-weight: 400;
    text-transform: uppercase;
    color: white;
    margin-bottom: 10px;
}
.category-img-text_button {
    width: 60%;
    height: 60px;
    background-color: white;
    margin: 10px auto;
    border-radius: 20px;
}
.category-img-text_button a {
    font-family: 'Inter';
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
    color: #004274;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
}

@media screen and (max-width: 1600px) {
    .procedureCategoryC_main_ {
        width: 80%;
    }
}
@media screen and (max-width: 1250px) {
    .procedureCategoryC_main_ {
      width: 90%;
    }
}
@media screen and (max-width: 1100px) {
    .procedureCategoryC_main_{
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .procedureCategoryC_main_ p {
        width: 95%;
    }
}
@media screen and (max-width: 800px) {
    .procedureCategoryC-header_text h1 {
        font-size: 80px;
    }
    .procedureCategoryC-header_text p {
        margin: 20px 0 40px 0;
    }
}
@media screen and (max-width: 500px) {
    .category_img {
        width: 90%;
    }
}

