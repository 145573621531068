.contain-detail-recovery-house {
    width: 90%;
    margin: 2rem auto;
    display: flex;
    justify-content: space-between;
}

.contain-img-detail-recovery {
    width: 58%;
}

.img-detail-recovery {
    width: 100%;
    height: 450px;
    border-radius: 10px;
    object-fit: cover;
}



.contain-info-detail-recovery {
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1rem;
    padding: 0.9rem;
    border-radius: 10px;
    -webkit-box-shadow: -6px 0px 11px -4px rgba(0,0,0,0.16);
    -moz-box-shadow: -6px 0px 11px -4px rgba(0,0,0,0.16);
    box-shadow: -6px 0px 11px -4px rgba(0,0,0,0.16);
}

.first-section-detail {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    max-height: 308px;
    overflow: scroll;
}

.first-section-detail h1 {
    color: var(--bluePrimary);
}

.contain-info-detail-recovery p {
    color: var(--bluePrimary);
    font-weight: 700;
}

.contain-info-detail-recovery p span {
    font-weight: 400;
}

.contain-info-detail-recovery button {
    width: 100%;
    background-color: var(--bluePrimary);
    border-radius: 8px;
    color: white;
    font-size: 18px;
    padding: 0.6rem;
    font-weight: 700;
    cursor: pointer;
    transition: .5s ease-in-out;
}

.contain-info-detail-recovery button:hover {
 
    transform: scale(1.03);
}

@media screen and (max-width:1100px) {
    .contain-detail-recovery-house{
        width: 95%;
    }
}

@media screen and (max-width:730px) {
    .contain-detail-recovery-house{
        width: 95%;
        flex-direction: column;
        gap: 1rem;
    }
    .contain-img-detail-recovery{
        width: 100%;
    }
    .contain-info-detail-recovery{
        width: 100%;
    }
    .contain-info-detail-recovery {
        -webkit-box-shadow: 0px 0px 11px -4px rgba(0,0,0,0.16);
        -moz-box-shadow: 0px 0px 11px -4px rgba(0,0,0,0.16);
        box-shadow: 0px 0px 11px -4px rgba(0,0,0,0.16);
    }

}