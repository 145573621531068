.cont_dropdown_forms {
    width: 100%;
    box-shadow: var(--shawdowPrimary);
    padding: 1rem 1rem;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    p {
        font-weight: 600;
        color: var(--bluePrimary);
    }
}

.formTitleItem {
    margin-top: 30px; 
    padding-top: 20px;
    border-top: 1px solid var(--blueTransparent);
}

.formTitleItem h4 {
    font-size: 18px;
    font-weight: 600;
    color: var(--bluePrimary);
}

.formTitleItem p {
    font-size: 15px;
    font-weight: 400;
    color: var(--bluePrimary);
}

.containFormTitleItemImages {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.formTitleItemImages h4 {
    font-size: 18px;
    font-weight: 600;
    color: var(--bluePrimary);
}

.formTitleItemImages p {
    font-size: 15px;
    font-weight: 400;
    color: var(--bluePrimary);
}


.camps_proce {
    display: flex;
    justify-content: space-between;
    gap: 5rem;

    label {
        font-weight: 500;
        font-size: 16px !important;
        color: var(--bluePrimary);
    }

    input[type="text"] {
        appearance: none;
        border: 1px solid #d9d9d9;
        border-radius: 5px;
        width: 100%;
        padding: 0.4rem;
    }

 /*   select {
        border: 1px solid #d9d9d9;
        border-radius: 5px;
        width: 100%;
        padding: 0.4rem;
        appearance: none;
        background-image: url("https://static.thenounproject.com/png/1590826-200.png");
        background-size: contain;
        background-position: right;
        background-clip: content-box;
        background-repeat: no-repeat;
    }*/

}
.selectStdClass{
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    width: 100%;
    padding: 0.4rem;
    appearance: none;
    background-image: url("https://static.thenounproject.com/png/1590826-200.png");
    background-size: contain;
    background-position: right;
    background-clip: content-box;
    background-repeat: no-repeat;
}
.right {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.5rem; 
}

.right {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    position: relative;
}
  
.checkboxCont2 input {
    appearance: none;
    border: 1px solid #d9d9d9;
    border-radius: 50% !important;
    padding: 0 !important;
}

.checkboxCont3 input {
    appearance: none;
    border: 1px solid #d9d9d9;
    border-radius: 50% !important;
    padding: 0 !important;
}

.camp {
    display: flex;
    flex-direction: column;
    gap: 1rem;


    div label {
        font-size: 16px !important;
        font-weight: 400;
    }
}

.leftColumn,
.rightColumn {
    flex: 1;
    padding-top: .5rem;
    /* Cada columna ocupa el 50% del contenedor */
}

.camp {
    margin-bottom: 20px;
    /* Espacio entre cada campo */
}

.checkboxCont {
    display: flex;
    align-items: center;
    gap: .7rem;
}

.checkboxCont input {
    appearance: none;
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    width: 100%;
    cursor: pointer;
    padding: 0.4rem;
}

.checkboxCont input[type="checkbox"] {
    width: 20px;
    height: 20px;
    border-radius: 2px;
}

.checkboxCont input:checked {
    background-image: url("https://www.shutterstock.com/image-vector/check-mark-icon-color-editable-600nw-2474013721.jpg");
    background-position: center;
    background-size: contain;
}

.checkboxCont2 {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.checkboxCont3 {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: .9rem;
}

.divOptions {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 1.6rem;
}

.divOptions span {
    color: var(--bluePrimary);
}

.checksDiv {
    display: flex;
    flex-direction: column;
    margin-top: -10px;
    gap: 5px;
}

.divOptions2 {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 1rem;
}

.divOptions2 span {
    color: var(--bluePrimary);
}


@media screen and (max-width: 1024px) {
    .camps_proce {
        gap: 2rem;
    }
}

@media screen and (max-width: 768px) {
    .camps_proce {
        flex-direction: column;
        gap: 10px;
    }
}