.p-traveler-tourism-package{
    font-size: 14px !important;
    font-weight: 700 !important;
    color: #004274 !important;
    font-family: "Montserrat" !important;
}

.input-gender-tourism-package{
    display: flex;
    flex-direction: column;
}

.input-gender-tourism-package label{
    margin-bottom: 10px;
}

.input-gender-tourism-package select{
    cursor: pointer;
    appearance: none;
    background: url('./svg/ArrowDown.svg') no-repeat;
    background-position: right 10px center;
    width: 100%;
    height: 32px;
    border: 1px solid #D9D9D9;
    border-radius: 8px;
    padding: 5px;
    font-size: 12px;
    font-weight: 400;
}

.data-picker-voucher-tourism-package{
    border: 1px solid #D9D9D9;
    width: 100%;
    height: 32px;
    border-radius: 8px;
    text-align: left;
    cursor: pointer;
    padding-inline: 10px;
    background: url('./svg/IconCalendar.svg') no-repeat;
    background-position: right 10px center;
}

.package-tourism-passenger{
    margin-top: 30px;
}