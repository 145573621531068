.header-taxi-car {
  display: flex;
  margin: 2rem auto;
  width: 100%;
  max-width: 1280px;
  justify-content: space-between;
}

.container-transport-search {
  width: 100%;
}
.dropdown-ciudad.shadow {
  overflow: scroll;
  scrollbar-width: none; /* Firefox */
}

.dropdown-ciudad.shadow::-webkit-scrollbar {
  width: 0px; /* Safari y Chrome */
  background: transparent;
}
.homePrimarySectionSearchTransfers {
  background-color: var(--white);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  border-bottom: 1px solid transparent;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  transition: all 0.3s ease;
  position: relative;
  top: 0;
  border-radius: 20px;
}

.homePrimarySectionSearchTransfersHidden {
  background-color: var(--white);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  transition: all 0.3s ease;
  border-bottom: 1px solid transparent;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  top: -170px !important;
  padding: 15px 0;
  border-radius: 20px;
}

.scrolledTransfers {
  box-shadow: 0px 30px 30px rgb(0 0 0 / 2%);
  position: sticky;
  top: 0;
  z-index: 9999;
  border-bottom: 1px solid var(--greyPrimary);
  transition: all 0.3s ease;
}

.browseHomeColumnSearchTransfers {
  width: 100%;
  transition: all 0.3s ease;
  position: relative;
  top: 0;
  display: flex;
  flex-direction: column;
}

.browseHomeColumnSearchTransfers.close {
  width: 100%;
  position: relative;
}

.buttonArrowSearchScrolledTransfers {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  rotate: 180deg;
  transition: all 0.3s ease;
  cursor: pointer;
  color: var(--bluePrimary);
}

.buttonArrowSearchScrolledTransfers.active {
  rotate: 0deg;
}

.react-datepicker-popper {
  width: 95vw;
  max-width: max-content;
}

.content-banner-taxicar img {
  width: 100%;
  height: 100%;
  border-radius: 22px;
  object-fit: cover;
}

.contain-contact-t {
  width: 100%;
  max-width: 1280px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  border-radius: 25px;
  box-shadow: 0px 4px 4px 0px var(--shawdowPrimary);
}

.section-contact-t {
  width: 95%;
  max-width: 1280px;
  margin: 4rem auto;
}

.contain-contact-t a {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.content-banner-t {
  width: 80%;
  height: 100%;
  border-radius: 22px;
  overflow: hidden;
  position: relative;
}

.content-banner-t img {
  width: 100%;
  border-radius: 22px;
  object-fit: cover;
  object-position: top;
}

.text-contact-t {
  margin-left: 1rem;
}

.text-contact-t h3 {
  margin-bottom: 1rem;
  font-size: 30px;
  color: var(--bluePrimary);
}

.text-contact-t p {
  color: rgb(113, 113, 113);
}

.content-radius-btns-transport {
  display: flex;
  gap: 1.5rem;
  align-items: center;
}

.content-radius-btns-transport .contain-radius-btn {
  display: flex;
  flex-direction: row-reverse;
  gap: 0.5rem;
  align-items: center;
}

.content-radius-btns-transport .contain-radius-btn label {
  font-size: 12px;
  color: var(--bluePrimary);
}

.contain-buscador-transport {
  margin: 2rem auto;
  width: 80%;
  height: 900px;
  padding: 1rem;
  box-shadow: 11px 10px 26px 0px rgba(0, 0, 0, 0.28);
  border-radius: 15px;
  display: flex;
  justify-content: space-around;
  overflow: hidden;
}

.container-search-rentcar {
  box-shadow: 0px 4px 4px 0px var(--shawdowPrimary);
  border-radius: 19px;
  padding: 2rem;
}

.shadow {
  box-shadow: 11px 10px 26px 0px rgba(0, 0, 0, 0.28);
}

.layout-one-t {
  width: 100%;
  padding: 2rem !important;
  height: 100%;
}

.form-contain-inputs {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.form-active-transport {
  flex-wrap: wrap;
}

.contain-form form .content-column-a {
  width: 100%;
  display: flex;
  gap: 10px;
  justify-content: space-between;
  row-gap: 0.5rem;
}

.modul-pass-transport {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  width: 100%;
}

.modul-pass-transport h2 {
  color: var(--bluePrimary);
  font-size: 18px;
}

.modul-pass-transport .passengers-cont {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.modul-pass-transport .passengers-cont h4 {
  color: var(--bluePrimary);
  font-size: 16px;
}

.modul-pass-transport .passengers-cont .control-pass {
  display: flex;
  justify-content: space-between;
}

.modul-pass-transport .passengers-cont .control-pass .btn-res {
  color: var(--white);
  padding: 0.5rem;
  text-align: center;
  background-color: var(--bluePrimary);
  border-bottom-left-radius: 8px;
  border-top-left-radius: 8px;
  font-size: 20px;
  font-weight: 700;
  width: 25%;
  cursor: pointer;
}

.modul-pass-transport .passengers-cont .control-pass div {
  border: 1px solid var(--bluePrimary);
  width: 50%;
  border-left: none;
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: none;
}

.modul-pass-transport .passengers-cont .control-pass .btn-sum {
  color: var(--white);
  padding: 0.5rem;
  text-align: center;
  font-size: 20px;
  font-weight: 700;
  background-color: var(--bluePrimary);
  border-bottom-right-radius: 8px;
  border-top-right-radius: 8px;
  width: 25%;
  cursor: pointer;
}

.contain-form form .content-column-a .react-datepicker-wrapper {
  margin-top: 0;
}

.campo-t {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  box-sizing: border-box;
}

.input-pass {
  height: 100%;
  display: flex;
  justify-content: space-between;
  width: 100%;
  overflow: hidden;
  align-items: center;
  border: 1px solid var(--greyPrimary);
  position: relative;
  padding: 0px 7px;
  border-radius: 10px;
}

.input-pass label {
  font-size: 14px;
  font-weight: 700;
}

.input-pass .content-pass {
  font-size: 14px;
  color: var(--bluePrimary);
  font-weight: 500;
  text-wrap: nowrap;
}

.input-dropdown {
  border: none;
  width: 90%;
  -webkit-box-shadow: 0px 0px 29px -15px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 29px -15px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 29px -15px rgba(0, 0, 0, 0.75);
}

.dropdown-ciudad {
  background-color: rgb(255, 255, 255);
  position: absolute;
  z-index: 9;
  top: 60px;
  max-height: 200px;
  overflow-y: scroll;
  left: 0px;
  width: 100%;
  box-shadow: 1px 8px 20px -14px rgba(0, 0, 0, 0.5);
  border-radius: 6px;
}

.dropdown-ciudad .header-list {
  background-color: var(--bluePrimary);
  color: var(--white);
  padding: 0.3rem;
  font-size: 14px;
  font-weight: 600;
}

.contend-input-loader {
  position: relative;
  display: flex;
  width: 97%;
}

.city-result {
  max-height: 200px;
  overflow-y: scroll;
  width: 100%;
  margin-top: 0.4rem;
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  scrollbar-width: none;
}

.city-result::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}

.city-result li {
  padding: 0.5rem;
  cursor: pointer;
  font-size: 12px;
  font-weight: 600;
  transition: 0.3s ease-in;
}

.city-result li:hover {
  background-color: var(--greyPrimary);
}

.campo-t label,
.campo-two div label,
.campo-three div label {
  margin-bottom: 6px;
  text-wrap: nowrap;
  font-weight: 300;
  color: var(--bluePrimary);
  font-size: 14px;
}

/* .campo-t select,
.campo-t input,
.campo-three div select {
    width: 100%;
    border: none;
    border-radius: 15px;
    padding: 10px;
    background-color: rgb(222, 222, 222);
    color: rgb(78, 78, 78);
} */

.campo-two {
  display: flex;
  justify-content: space-between;
}

.bbb {
  display: flex;
  flex-direction: column;
  width: 45%;
  position: relative;
}

.campo-two input {
  width: 100%;
  border: none;
  border-radius: 10px;
  padding: 10px;
  background-color: rgb(222, 222, 222);
  color: rgb(78, 78, 78);
}

.campo-three {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
}

.campo-three div {
  width: 30%;
  display: flex;
  flex-direction: column;
}

.campo-three div label {
  text-align: center;
  font-weight: 700;
  font-size: 12px;
}

.campo-three div label span {
  font-size: 12px;
  color: rgb(167, 167, 167);
}




.contain-btn-t {
  cursor: pointer;
  background-color: var(--bluePrimary);
  font-family: inherit;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 2.5px 15px;
  line-height: 2.5em;
  position: relative;
  overflow: hidden;
  border: 2px solid var(--bluePrimary);
  transition: color 0.5s;
  z-index: 1;
  font-size: 18px;
  border-radius: 9px;
  font-weight: 600;
  color: var(--white);
}

.contain-btn-t:before {
  content: "";
  position: absolute;
  z-index: -1;
  background: var(--white);
  color: var(--bluePrimary);
  height: 550px;
  width: 1500px;
  border-radius: 50%;
}

.contain-btn-t:hover {
  color: var(--bluePrimary);
}

.contain-btn-t:before {
  top: 100%;
  left: 100%;
  transition: all 0.7s;
}

.contain-btn-t:hover:before {
  top: -100px;
  left: -200px;
}

.contain-btn-t:active:before {
  background: var(--white);
  transition: background 0s;
}







.img-viaja {
  margin: 2rem 0;
  width: 130%;
}

.check-title {
  display: flex;
  align-items: center;
  margin-bottom: 0.3rem;
  flex-wrap: wrap;
  gap: 1rem;
}

.check-title img {
  width: 30px;
}

.check-title h4 {
  font-size: 16px;
  color: rgb(0, 66, 116);
}

.check-title p {
  font-size: 8.5px;
  color: rgb(0, 66, 116);
  font-family: "Montserrat" !important;
  font-weight: 700;
}

.layout-two-t {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 40%;
}

.fd-img {
  position: absolute;
  z-index: 2;
  width: 90%;
}

.up-img {
  z-index: 6;
  height: 914px;
  width: fit-content;
}

.btn-passengers {
  display: flex;
  flex-direction: row !important;
  height: 32px !important;
  width: 100% !important;
  justify-content: space-around;
  align-items: center;
  border-radius: 6px;
  border: solid 1px var(--greyPrimary);
}

.btn-passengers button {
  border-radius: 10px;
  padding: 7px;
  width: 30px;
  font-size: 16px;
  font-weight: 700;
  border: none;
  color: var(--bluePrimary);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-passengers span {
  font-weight: 400;
  font-size: 12px;
  margin: 0 2px;
}

.calendar-t-l {
  position: absolute;
  top: 80px;
  left: 0;
  z-index: 7;
}

.calendar-t-r {
  position: absolute;
  top: 80px;
  right: 0;
  z-index: 7;
}

.input-cat-date {
  cursor: pointer;
  display: flex;
  align-items: center;
  color: var(--bluePrimary);
  font-weight: 700;
  overflow: hidden;
  justify-content: space-between;
  width: 100%;
}

.input-cat {
  cursor: pointer;
  appearance: none;
  background: url("./Assets/svg/IconArrowDown.svg") no-repeat;
  background-position: right 10px top 0px;
  display: flex;
  align-items: center;
  color: rgb(78, 78, 78);
  font-size: 14px;
  font-weight: 500;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
}

.input-cat p {
  color: var(--bluePrimary);
  font-weight: 700;
}

.disabled {
  opacity: 0.5;
  /* reduce la opacidad para indicar que está deshabilitado */
  pointer-events: none;
  /* desactiva los eventos del mouse */
}

.error-form {
  width: 100% !important;
  text-align: center;
  border-radius: 10px;
  color: var(--redPrimary);
  font-weight: 700;
  display: flex;
}

.error-form p {
  color: var(--redPrimary);
  font-weight: 500;
  font-size: 14px;
}

.flex-campo-t {
  width: 100%;
  display: flex;
  gap: 10px;
}

.flex-campo-x {
  width: 50%;
}

.content-date-transport {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}

.content-date-transport span {
  color: var(--bluePrimary);
  font-size: 14px;
  display: flex;
  align-items: center;
  font-weight: 700;
}


.ModalDateTransport {
  position: absolute;
  top: 50px;
  background-color: var(--white);
  border-radius: 10px;
  padding: 1rem;
  box-shadow: 0px 0px 13px -3px rgba(0, 0, 0, 0.75);
  z-index: 99999;
  display: flex;
  align-items: flex-start;
}


.ModalDateTransport .content-hours-date {
  display: flex;
  flex-direction: column;
  gap: .4rem;
  padding-top: 10px;
}

.ModalDateTransport .content-hours-date h3 {
  font-weight: 700;
  font-size: 13px;
  color: var(--bluePrimary);
}


.ModalDateTransport .content-hours-date ul {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-height: 230px;
  overflow: auto;
  list-style: none;
}


.ModalDateTransport .content-hours-date ul li {
  font-size: 14px;
  cursor: pointer;
  padding: .3rem;
  transition: all .5s ease;
}


.ModalDateTransport .content-hours-date ul .selected{
  color: var(--white);
  background-color: var(--bluePrimary);
  border-radius: 5px;
}


.ModalDateTransport .content-hours-date ul li:hover {
  color: var(--white);
  background-color: var(--bluePrimary);
  border-radius: 5px;
}


@media screen and (max-width: 1100px) {

  .contain-contact-t,
  .contain-buscador-transport {
    width: 99%;
  }
}

@media screen and (max-width: 1050px) {
  .ModalDateTransport{
    flex-direction: column;
    align-items: center;
    left: -100px;
    max-width: 400px;
  }

  .ModalDateTransport .content-hours-date{
    flex-direction: row;
    align-items: center;
    width: 100%;
  }

  .ModalDateTransport .content-hours-date ul{
   width: 100%;
    flex-direction: row;
  }
}


@media screen and (max-width: 920px) {
  .content-banner-taxicar {
    width: 58% !important;
  }

  .ModalDateTransport{
    flex-direction: column;
    align-items: center;
    left: 0;
    width: 100%;
    max-width: 400px;
  }

  .content-banner-t {
    width: 50%;
  }
}

@media screen and (max-width: 830px) {
  .content-banner-t {
    width: 100%;
  }
}

@media screen and (max-width: 800px) {
  .header-taxi-car {
    flex-direction: column;
    gap: 1rem;
  }

  .container-search-taxicar {
    width: 100% !important;
  }

  .content-banner-taxicar {
    width: 100% !important;
  }

  .content-banner-t {
    width: 100%;
  }
}

@media screen and (max-width: 750px) {
  .campo-t {
    display: flex;
    flex-direction: column;
    position: relative;
    flex: 1 1 calc(33% - 2.5%);
  }

  .contain-form form {
    flex-direction: column;
    gap: 0.5rem;
  }
}

@media screen and (max-width: 600px) {
  .campo-two {
    flex-direction: column;
    gap: 7px;
  }

  .campo-two div {
    width: 100%;
  }

  .contain-buscador-transport {
    flex-direction: column;
    height: auto;
  }

  .layout-one-t,
  .layout-two-t {
    width: 100%;
  }

  .img-viaja {
    width: 100%;
  }

  .contain-contact-t :first-child img {
    width: 120px;
  }

  .text-contact-t h3 {
    margin-bottom: 1rem;
    font-size: 25px;
    color: var(--bluePrimary);
  }

  .text-contact-t p {
    color: rgb(113, 113, 113);
    font-size: 12px;
  }

  .up-img {
    width: 100%;
    height: auto;
  }
}

@media screen and (max-width: 900px) {
  .form-contain-inputs {
    flex-direction: column;
    align-items: end;
  }

  .contain-form form .content-column-a {
    flex-direction: column;
  }

  .flex-campo-x {
    width: 100%;
  }
}

@media screen and (max-width: 410px) {
  .content-radius-btns-transport {
    width: 100%;
    flex-wrap: wrap;
    align-items: start;
    gap: 10px;
  }
}


@media screen and (max-width: 500px) {
  .flex-campo-t {
    flex-direction: column;
  }

  .contain-btn-t {
    width: 100%;
  }

  .content-column-b {
    width: 100%;
  }
}

@media screen and (max-width: 1300px) {
  .homePrimarySectionSearchTransfersHidden {
    background-color: var(--white);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    transition: all 0.3s ease;
    border-bottom: 1px solid transparent;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    top: -220px !important;
    padding: 15px 0;
  }
}

@media screen and (max-width: 751px) {
  .homePrimarySectionSearchTransfersHidden {
    background-color: var(--white);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    transition: all 0.3s ease;
    border-bottom: 1px solid transparent;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    top: -290px !important;
    padding: 15px 0;
  }
}

@media screen and (max-width: 441px) {
  .homePrimarySectionSearchTransfersHidden {
    background-color: var(--white);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    transition: all 0.3s ease;
    border-bottom: 1px solid transparent;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    top: -310px !important;
    padding: 15px 0;
  }
}

@media screen and (max-width: 418px) {
  .homePrimarySectionSearchTransfersHidden {
    background-color: var(--white);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    transition: all 0.3s ease;
    border-bottom: 1px solid transparent;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    top: -370px !important;
    padding: 15px 0;
  }
}

@media screen and (max-width: 373px) {
  .homePrimarySectionSearchTransfersHidden {
    background-color: var(--white);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    transition: all 0.3s ease;
    border-bottom: 1px solid transparent;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    top: -425px !important;
    padding: 15px 0;
  }
}









.hotels-destacs {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.hotels-destacs h2 {
  color: var(--bluePrimary);
  font-size: 24px;
  font-weight: 700;
}

.places-destacs p {
  font-size: 14px;
}

.cards-trans-destacs {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.card-tran-destac {
  height: 100%;
  display: flex;
  flex-direction: column;
  border: 1px solid var(--greyPrimary);
  border-radius: 15px;
  box-shadow: 0px 4px 4px 0px var(--shawdowPrimary);
}

.card-tran-img {
  width: 100%;
  height: 230px;
}

.card-tran-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 15px 15px 0 0;
}

.card-content-destac {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
  border-radius: 10px;
  padding: 15px;
}

.card-content-destac h3 {
  color: var(--bluePrimary);
  font-size: 18px;
}

.card-content-destac p {
  font-size: 12px;
}

.card-content-info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: 10px;
}

.card-content-info p {
  color: var(--bluePrimary);
}

.card-content-info h4 {
  font-size: 18px;
  color: var(--bluePrimary);
  font-size: 500;
}

.card-content-info h4 span {
  font-size: 14px;
  color: var(--bluePrimary);
  font-weight: 400;
  text-decoration: line-through;
}


.scrolledTrans {
  box-shadow: 0px 30px 30px rgb(0 0 0 / 2%);
  position: sticky;
  top: 0;
  z-index: 9999;
  border-bottom: 1px solid var(--greyPrimary);
  transition: all 0.3s ease;
}

.searchMenuTransScrolled {
  width: 100%;
  transition: all 0.3s ease;
  background-color: var(--white);
}

.searchMenuTransScrolled.active {
  width: 100%;
  box-shadow: 0px 30px 30px rgb(0 0 0 / 2%);
  position: sticky;
  top: 0;
  z-index: 9999;
  border-bottom: 1px solid var(--greyPrimary);
  padding: 15px 0px 15px 0px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.searchMenuTransScrolled.hidden {
  width: 100%;
  box-shadow: 0px 30px 30px rgb(0 0 0 / 2%);
  position: sticky;
  top: -120px;
  z-index: 9999;
  border-bottom: 1px solid var(--greyPrimary);
  padding: 15px 0px 15px 0px;
}

.searchMesearchMenuTransScrolled {
  width: 95%;
  max-width: 1280px;
  margin: 2rem auto;
  transition: all 0.3s ease;
}

@media screen and (max-width: 900px) {
  .searchMenuTransScrolled.hidden {
    top: -290px;
  }
}

@media screen and (max-width: 780px) {
  .searchMenuTransScrolled.hidden {
    top: -330px;
  }
}

@media screen and (max-width: 500px) {
  .searchMenuTransScrolled.hidden {
    top: -450px;
  }
}

@media screen and (max-width: 400px) {
  .searchMenuTransScrolled.hidden {
    top: -460px;
  }
}

@media screen and (max-width: 333px) {
  .searchMenuTransScrolled.hidden {
    top: -464px;
  }
}

@media screen and (max-width: 331px) {
  .searchMenuTransScrolled.hidden {
    top: -485px;
  }
}

@media screen and (max-width: 252px) {
  .searchMenuTransScrolled.hidden {
    top: -505px;
  }
}

@media screen and (max-width: 197px) {
  .searchMenuTransScrolled.hidden {
    top: -535px;
  }
}

@media screen and (max-width: 888px) {
  .check-title {
    gap: 1rem !important;
  }
}

@media screen and (max-width: 500px) {
  .contain-form form .content-column-a {
    flex-direction: column;
  }
}

@media screen and (max-width: 500px) {
  .campo-t {
    width: 100%;
  }


}

@media screen and (max-width: 600px) {
  .contain-btn-t {
    width: 100%;
    justify-content: center;
  }
}